import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPossibleSalesChannels } from 'redux/features/SalesChannels/getPossibleSalesChannels';

const useAllPossibleSalesChannels = () => {
    const dispatch = useDispatch();
    const { loading, data, success, error } = useSelector(
        (state) => state.getPossibleSalesChannels
    );
    useEffect(() => {
        if (!data[0] && !success) {
            dispatch(getPossibleSalesChannels());
        }
    }, []);
    return [loading, data, error];
};

export default useAllPossibleSalesChannels;
