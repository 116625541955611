import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const reportsApi = createApi({
    reducerPath: 'reports',
    baseQuery,
    tagTypes: ['dashboard_reports'],
    endpoints: (builder) => ({
        getDashboardReports: builder.query({
            method: 'get',
            query: (params) => {
                return {
                    url: `/reports/dashboardV2`,
                    method: 'get',
                    params: params,
                };
            },
            providesTags: ['dashboard_reports'],
        }),
        getOrderReports: builder.query({
            method: 'get',
            query: (params) => {
                return {
                    url: `/reports/ordersV2`,
                    method: 'get',
                    params: params,
                };
            },
            providesTags: ['order_reports'],
        }),
        getPendingArrivalsReports: builder.query({
            method: 'get',
            query: (params) => {
                return {
                    url: `/reports/pendingArrivalsV2`,
                    method: 'get',
                    params: params,
                };
            },
            providesTags: ['pending_arrivals_reports'],
        }),
        getDeliveriesReports: builder.query({
            method: 'get',
            query: (params) => {
                return {
                    url: `/reports/deliveriesV2`,
                    method: 'get',
                    params: params,
                };
            },
            providesTags: ['deliveries_reports'],
        }),
        getManifestsReports: builder.query({
            method: 'get',
            query: (params) => {
                return {
                    url: `/reports/manifests`,
                    method: 'get',
                    params: params,
                };
            },
            providesTags: ['manifests_reports'],
        }),
        sendShops: builder.query({
            method: 'get',
            query: (params) => {
                return {
                    url: `/reports/dashboard_grafics`,
                    method: 'get',
                    params: params,
                };
            },
            providesTags: ['dashboard_grafics'],
        }),
    }),
});

export const {
    useGetDashboardReportsQuery,
    useGetOrderReportsQuery,
    useGetPendingArrivalsReportsQuery,
    useGetDeliveriesReportsQuery,
    useGetManifestsReportsQuery,
    useSendShopsQuery,
} = reportsApi;
