import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import toast from 'react-hot-toast';
import AndesClient from 'services/AndesClient';
import { getAllSalesChannel } from './getAllSalesChannel';

export const editSalesChannel = createAsyncThunk('salesChannel/edit', async (data, state) => {
    try {
        const editSalesChannelForItem = async (payload) => {
            const promise = AndesClient.put(`/settings/sales-channels/${payload._id}`, payload);
            toast.promise(promise, {
                loading: <b>Modificando canales de venta...</b>,
                success: <b>Canal de venta modificado con éxito.</b>,
                error: <b>Ocurrió un error.</b>,
            });

            const result = await promise;

            if (result.status === 200) {
                state.dispatch(getAllSalesChannel());
            }

            return result.data;
        };

        if (Array.isArray(data)) {
            for await (const item of data) {
                await editSalesChannelForItem(item);
            }
        } else if (typeof data === 'object') {
            await editSalesChannelForItem(data);
        }
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const editSalesChannelSlice = createSlice({
    name: 'editSalesChannel',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [editSalesChannel.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [editSalesChannel.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
                success: true,
            };
        },
        [editSalesChannel.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
                success: false,
            };
        },
    },
});

export default editSalesChannelSlice.reducer;
