import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

// Obtenemos todas las ultimas 50 fulfillments
export const fetchInProgressfulfillments = createAsyncThunk(
    'fulfillments/in_progress',
    async (queryobj = {}, state) => {
        try {
            console.log(queryobj.origin);

            if (Array.isArray(queryobj.origin)) {
                if (queryobj.origin.length === 0) {
                    queryobj.origin = undefined;
                } else {
                    queryobj.origin = queryobj.origin.join(',');
                }
            }

            if (queryobj.delivery_method) {
                if (queryobj.delivery_method.length > 0) {
                    queryobj.delivery_method = queryobj.delivery_method.join(',');
                } else {
                    delete queryobj.delivery_method;
                }
            }
            if (queryobj.courier) {
                queryobj.courier.length == 0
                    ? (queryobj.courier = undefined)
                    : (queryobj.courier = queryobj.courier.join(','));
            }
            if (Array.isArray(queryobj.status)) {
                if (queryobj.status.length > 0) {
                    queryobj.status = queryobj.status.join(',');
                } else {
                    queryobj.status = 'open,paused,ready_for_deliver,partially_processed,cancelled';
                }
            }
            console.log(queryobj);

            const response = await AndesClient.get('/fulfillments', {
                params: {
                    // status: 'open, sac, paused, in_progress, partially_fulfilled',
                    status: 'open,paused,ready_for_deliver,partially_processed,cancelled',
                    ...queryobj,
                    limit: 50,
                },
            });
            return response.data;
        } catch (error) {
            console.log('mecai ', error.message);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const inProgressFulfillmentSlice = createSlice({
    name: 'fulfillments/in_progress',
    initialState: {
        loading: false,
        success: false,
        data: {
            pagination: {},
            docs: [],
        },
        error: null,
    },
    reducers: {},
    extraReducers: {
        [fetchInProgressfulfillments.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [fetchInProgressfulfillments.fulfilled]: (state, { payload }) => {
            const { docs, ...otherData } = payload;
            return {
                ...state,
                loading: false,
                success: true,
                data: {
                    pagination: otherData,
                    docs,
                },
                error: null,
            };
        },
        [fetchInProgressfulfillments.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default inProgressFulfillmentSlice.reducer;
