import qs from 'qs';
import { useGetDeliveriesReportsQuery } from 'redux/features/Reports';
import MetricCard from 'components/V2/Metrics/MetricCard';
import { ReactComponent as TruckIcon } from 'assets/truck-outline-sidebar.svg';
import { ReactComponent as ShoppingBag } from 'assets/shopping-bag.svg';
import { ReactComponent as DeliveredIcon } from 'assets/trolley-svgrepo-com.svg';
import { MetricsContainer } from '../styles';
import { useMemo } from 'react';

const DeliveriesMetrics = ({ selectedDateRange, selectedLocation, dataPickupsPreferences }) => {
    const {
        data = {},
        isLoading,
        isFetching,
    } = useGetDeliveriesReportsQuery(
        qs.stringify({
            ...selectedDateRange,
            origin: selectedLocation,
        }),
        {
            pollingInterval: 1800000,
        }
    );

    const metrics = useMemo(() => {
        const infoMetrics = [
            {
                name: 'Pendientes de entrega al courier',
                type: 'pending_courier_pickup',
                quantity: data?.total_pending_courier_pickup ?? 0,
                measurement: '',
                tooltipTitle:
                    'Pedidos que están pendientes de entrega al courier, para la tienda seleccionada en el plazo especificado.',
                icon: <TruckIcon width={24} height={24} color="#2D3D76" />,
                color: '#7998A7',
            },
        ];

        if (dataPickupsPreferences?.delivery_method_pickup) {
            infoMetrics.push({
                name: 'Pendientes con retiro en tienda',
                type: 'pending_pickup',
                quantity: data?.total_pending_customer_pickup ?? 0,
                measurement: '',
                tooltipTitle:
                    'Pedidos que están pendientes de retiro, para la tienda seleccionada en el plazo especificado.',
                icon: <ShoppingBag width={24} height={24} color="#2D3D76" />,
                color: '#B00303',
            });
        }

        infoMetrics.push({
            name: 'Entregados al cliente',
            type: 'delivered',
            quantity: data?.total_delivered ?? 0,
            measurement: '',
            tooltipTitle:
                'Pedidos que han sido entregados al cliente independientemente del tipo de entrega, para la tienda seleccionada en el plazo especificado.',
            icon: <DeliveredIcon width={24} height={24} color="#2D3D76" />,
            color: '#037FB9',
        });

        return infoMetrics;
    }, [data, dataPickupsPreferences]);

    return (
        <MetricsContainer>
            {metrics.map((metric) => (
                <MetricCard
                    key={metric.name}
                    color={metric.color}
                    name={metric.name}
                    tooltipTitle={metric.tooltipTitle}
                    metricType={metric.type}
                    quantity={metric.quantity}
                    measurement={metric.measurement}
                    icon={metric.icon}
                    loading={isLoading || isFetching}
                />
            ))}
        </MetricsContainer>
    );
};

export default DeliveriesMetrics;
