import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const incidentsApi = createApi({
    reducerPath: 'incidents',
    baseQuery,
    tagTypes: [],
    keepUnusedDataFor: 30,
    endpoints: (builder) => ({
        createIncident: builder.mutation({
            query: (body) => {
                return {
                    url: `/incidents-v2/manual-incidences`,
                    method: 'POST',
                    body: body,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando incidencia...</b>,
                    success: <b>Incidencia creada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
    }),
});

export const { useCreateIncidentMutation } = incidentsApi;
