import { Button } from 'antd';
import styled from 'styled-components';

export const OptionButton = styled(Button)`
    flex: 1;
    height: 'auto';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    line-height: 1.1rem;
    border-radius: 0.25rem;
    border: 2px solid rgb(242, 113, 39) !important;
    svg {
        width: 30px;
        height: 30px;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
    }
    ${({ isActive }) =>
        isActive
            ? `
            background-color: rgb(242, 113, 39) !important;
            color: #ffffff !important;
            `
            : `
            background-color: #ffffff !important;
            color: rgb(242, 113, 39) !important;
    `}

    :hover {
        background-color: rgb(242, 113, 39) !important;
        color: #ffffff !important;
    }
`;
