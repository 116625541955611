import { Form, Select, Checkbox, Space } from 'antd';
import { isObjectEmpty } from 'helpers/filterObjectByCustom';
import { useState, useEffect } from 'react';
import { Label } from '../../styled';

const AssociatedPackages = ({
    ordersSelected = [],
    setSelectedPackageCodes = [],
    setIsButtonDisabled,
}) => {
    const [selectedPackageIds, setSelectedPackageIds] = useState([]);

    useEffect(() => {
        const isNothingSelected = selectedPackageIds.length === 0;
        setIsButtonDisabled(isNothingSelected);
    }, [selectedPackageIds, setIsButtonDisabled]);

    return (
        <div style={{ backgroundColor: '#F5F5F5', alignItems: 'center' }}>
            {ordersSelected?.map((os) => {
                if (isObjectEmpty(os)) return null;

                return (
                    <div
                        key={os._id}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            padding: '6px',
                            gap: '10px',
                        }}
                    >
                        <Form.Item
                            label={
                                <>
                                    <Label color="#536D8F">Código de bulto del paquete </Label>
                                    {String.fromCharCode(160)}
                                    <Label color="#536D8F" fontWeight={500}>
                                        (Pedido {os.name})
                                    </Label>
                                </>
                            }
                            name={['incidence_package', os._id]}
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, seleccione códigos de bulto.',
                                },
                            ]}
                            style={{ marginTop: 3 }}
                        >
                            <Select
                                mode="multiple"
                                allowClear
                                style={{
                                    width: '100%',
                                    marginTop: 5,
                                }}
                                placeholder="Selecciona el o los códigos de bultos"
                                onChange={(selectedIds, selectedOptions) => {
                                    const codes = selectedOptions.map(
                                        (option) => option?.props['custom-label']
                                    );

                                    setSelectedPackageIds(selectedIds);
                                    setSelectedPackageCodes((prevCodes) => {
                                        const uniqueCodes = codes.filter(
                                            (code) => !prevCodes.includes(code)
                                        );
                                        return [...prevCodes, ...uniqueCodes];
                                    });
                                }}
                                value={selectedPackageIds}
                                optionLabelProp="custom-label"
                            >
                                {os?.packages[0] &&
                                    os.packages.map((p) => (
                                        <Select.Option
                                            key={p?._id}
                                            value={p?._id}
                                            custom-label={p?.code}
                                        >
                                            <Space>
                                                <Checkbox
                                                    checked={selectedPackageIds.includes(p?._id)}
                                                />

                                                {p?.code}
                                            </Space>
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </div>
                );
            })}
        </div>
    );
};

export default AssociatedPackages;
