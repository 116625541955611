import { Tabs } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding};
`;

const CustomTabs = ({ children, margin = 0, padding = 0, ...props }) => {
    return (
        <Container margin={margin} padding={padding}>
            <Tabs {...props}>{children}</Tabs>
        </Container>
    );
};

export default CustomTabs;
