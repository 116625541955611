import { createSlice } from '@reduxjs/toolkit';
import { pendingArrivalsFilters as prepFilters } from 'helpers/constants/filters/pendingArrivals';

const pendingArrivals = createSlice({
    name: 'pendingArrivals',
    initialState: {
        filters: [],
        valueOptionByField: {
            delivery_method: [...prepFilters.values.delivery_method],
            pickup_store_status: [...prepFilters.values.pickup_store_status],
            'consolidation.status': [...prepFilters.values['consolidation.status']],
            'preparationcd_transfer_pickupstore.status': [
                ...prepFilters.values['preparationcd_transfer_pickupstore.status'],
            ],
        },
    },
    reducers: {
        addFilter: (state, { payload }) => {
            state.filters = [...state.filters, payload];
        },
        removeFilter: (state, { payload }) => {
            state.filters = state.filters?.filter((el) => el?.id !== payload);
            if (state.filters.length > 0) {
                state.filters[0].andOr = '';
            }
        },
        updateFilter: (state, { payload }) => {
            const indexToUpdate = state.filters.findIndex((filter) => filter.id === payload.id);
            state.filters[indexToUpdate] = payload;
        },
        overrideFilters: (state, { payload }) => {
            state.filters = payload;
        },
        setValueOptionByField: (state, { payload }) => {
            state.valueOptionByField = {
                ...state.valueOptionByField,
                ...payload,
            };
        },
        clearFilters: (state) => {
            state.filters = [];
        },
    },
});

export const {
    addFilter,
    removeFilter,
    updateFilter,
    overrideFilters,
    setValueOptionByField,
    clearFilters,
} = pendingArrivals.actions;
export default pendingArrivals.reducer;
