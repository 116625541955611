import Flex from 'components/Utils/Flex';
import { renderFulFillmentTagSLA } from 'helpers/renderTags';

const FulfillmentStatusWithSLA = ({ row }) => {
    return (
        <Flex direction="row" alignItems="center" columnGap="10px">
            {renderFulFillmentTagSLA({
                status: row?.fulfillment_status ?? '',
                createdAt: row?.createdAt ?? '',
                promised_date: row?.promised_date ?? '',
                delivery_date: row?.delivery_date ?? '',
            })}
        </Flex>
    );
};

export default FulfillmentStatusWithSLA;
