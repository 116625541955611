import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getDataByAddress = createAsyncThunk('getDataByAddress/getOne', async (data, state) => {
    try {
        /* Reserva+Nacional+Magallanes+766+Chillan */
        const address = data?.address;
        const apikey = 'AIzaSyAy0noPlGcCRazDRAywTGI_VJHumuxo15U';

        const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apikey}`
        );
        return response.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getDataByAddressSlice = createSlice({
    name: 'getDataByAddress',
    initialState: {
        loading: false,
        data: {},
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [getDataByAddress.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getDataByAddress.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
                success: true,
            };
        },
        [getDataByAddress.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
                success: false,
            };
        },
    },
});
export default getDataByAddressSlice.reducer;
