import { Button, ConfigProvider, Tooltip } from 'antd';
import AntdTable from 'components/AntdTable';
import { useMemo } from 'react';
import { ReactComponent as StockHistory } from 'assets/stock-history.svg';
import Flex from 'components/Utils/Flex';
import StockInputs from './StockInpus';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import TableEmpty from 'components/Table/Empty';

const StockTable = ({ data, dataLoading, setRenderView, setSku, setProductId }) => {
    const tableColumns = useMemo(
        () => [
            {
                title: 'Código',
                dataIndex: ['sku'],
                align: 'left',
                key: ['sku'],
                render: (value) => (
                    <b>
                        <u>{value}</u>
                    </b>
                ),
                sorter: (a, b) => String(a?.sku).localeCompare(b?.sku),
            },
            {
                title: 'Producto',
                dataIndex: 'title',
                align: 'left',
                key: 'title',
                sorter: (a, b) => String(a?.title).localeCompare(b?.title),
            },
            {
                align: 'left',
                title: () => {
                    return (
                        <Flex direction="row" alignItems="center" columnGap="6px">
                            <span>Administrar inventario</span>
                            <Tooltip
                                title="Puedes disminuir o agregar más stock al producto"
                                color="#536D8F"
                            >
                                <AiOutlineInfoCircle size="16" color="#2D3D76" />
                            </Tooltip>
                        </Flex>
                    );
                },
                render: (row) => {
                    return (
                        <StockInputs
                            product_id={row?.id}
                            location_id={row.location_id}
                            quantity={row?.quantity ?? 0}
                        />
                    );
                },
            },
            {
                title: 'Historial',
                width: '70px',
                render: (row) => (
                    <Flex alignItems="center" justifyContent="center">
                        <Button ghost style={{ border: 'none' }}>
                            <StockHistory
                                onClick={() => {
                                    setSku(row?.sku);
                                    setProductId(row?.id);
                                    setRenderView('stock_history');
                                }}
                            />
                        </Button>
                    </Flex>
                ),
            },
        ],
        [data]
    );
    const tableData = useMemo(() => Array.from(data ?? []), [data]);

    return (
        <ConfigProvider renderEmpty={TableEmpty}>
            <AntdTable
                styleV2
                removeHeaderBackground
                dataSource={tableData}
                columns={tableColumns}
                loading={dataLoading}
                rowKey="id"
            />
        </ConfigProvider>
    );
};

export default StockTable;
