import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const changeBlurEmail = createAsyncThunk('users/changeBlurEmail', async (data, state) => {
    try {
        await AndesClient.post(`/users/verifyIfExists`, data);
        return state.fulfillWithValue(true);
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const changeBlurEmailSlice = createSlice({
    name: 'changeBlurEmail',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {
        clearEmailError: () => {
            return {
                loading: false,
                success: false,
                error: null,
            };
        },
    },
    extraReducers: {
        [changeBlurEmail.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [changeBlurEmail.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                error: 'No hay errores',
                loading: false,
            };
        },
        [changeBlurEmail.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});
export const { clearEmailError } = changeBlurEmailSlice.actions;
export default changeBlurEmailSlice.reducer;
