import styled from 'styled-components';

export const Text = styled.span`
    display: inline-block;
    color: #536d8f;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.8;
    padding-left: ${({ paddingLeft }) => paddingLeft ?? '4px'};
    padding-bottom: ${({ paddingBottom }) => paddingBottom ?? '0px'};
`;

export const BoxMoreOptions = styled.div`
    width: 100%;
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #cacaca;
    border-radius: 5px;
`;
