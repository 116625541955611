import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getFulfillmentById } from './getOne';
import { fulfillmentsApi } from './index';

const initialStateDeliverFulfillment = {
    loading: false,
    success: false,
    data: {},
    error: null,
};

export const deliverFulfillment = createAsyncThunk('fulfillments/deliver', async (data, state) => {
    try {
        const { order_id, fulfillment_id } = data;

        const promise = AndesClient.post(
            `/fulfillments/orders/${order_id}/fulfillments/${fulfillment_id}/deliver`
        );

        toast.promise(promise, {
            loading: <b>Entregando fulfillment...</b>,
            success: <b>Fulfillment entregado con éxito</b>,
            error: <b>Ocurrió un error al entregar el fulfillment.</b>,
        });

        const result = await promise;
        if (result.status === 200) {
            state.dispatch(getFulfillmentById(fulfillment_id));
            state.dispatch(
                fulfillmentsApi.endpoints.getSuccessFulfillments.initiate(undefined, {
                    forceRefetch: true,
                })
            );
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const deliverFulfillmentSlice = createSlice({
    name: 'deliverFulfillment',
    initialState: initialStateDeliverFulfillment,
    reducers: {
        reInitDeliverFulfillment: () => initialStateDeliverFulfillment,
    },
    extraReducers: {
        [deliverFulfillment.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [deliverFulfillment.fulfilled]: (state, action) => {
            return {
                ...state,
                success: true,
                data: action.payload,
                loading: false,
            };
        },
        [deliverFulfillment.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});
export const { reInitDeliverFulfillment } = deliverFulfillmentSlice.actions;
export default deliverFulfillmentSlice.reducer;
