import { Skeleton } from 'antd';
import CheckboxStyled from 'components/Utils/CheckboxStyled';
import React from 'react';
import { Fragment } from 'react';
import styled from 'styled-components';

const NotificationItem = React.lazy(() => import('./NotificationItem'));

const NotificationsStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

const Notifications = ({
    data = [],
    isLoading,
    showCheckbox,
    showDate,
    showDot,
    handleCheckboxChange = () => {},
    setNotificationsIds = () => {},
    notificationsIds = [],
    showSelectAll,
}) => {
    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        if (checked) {
            setNotificationsIds(data.map((notification) => notification._id));
        } else {
            setNotificationsIds([]);
        }
    };

    return (
        <NotificationsStyled>
            {showSelectAll && data.length > 0 && (
                <Fragment>
                    <CheckboxStyled
                        style={{
                            marginRight: '1.5rem',
                            fontWeight: 'bold',
                        }}
                        onChange={handleSelectAll}
                        checked={notificationsIds.length === data.length}
                    >
                        <span>Marcar todas</span>
                    </CheckboxStyled>
                    <br />
                </Fragment>
            )}
            {isLoading && <Skeleton />}
            {data.map((notification, index) => (
                <React.Suspense key={index} fallback={<Skeleton paragraph={{ rows: 1 }} />}>
                    <NotificationItem
                        item={notification}
                        key={index}
                        showCheckbox={showCheckbox}
                        showDate={showDate}
                        showDot={showDot}
                        handleCheckboxChange={handleCheckboxChange}
                        notificationsIds={notificationsIds}
                    />
                </React.Suspense>
            ))}
        </NotificationsStyled>
    );
};

export default Notifications;
