import { isML } from 'helpers/isML';
import { crossdockingFilters } from './filters/crossdocking';
import { deliveriesFilters } from './filters/deliveries';
import { manifestsFilters } from './filters/manifests';
import { pendingArrivalsFilters } from './filters/pendingArrivals';
import { preparationsFilters } from './filters/preparations';

export const conditions = [
    {
        value: 'is',
        label: 'Es',
    },
    {
        value: 'is_not',
        label: 'No es',
    },
    {
        value: 'includes',
        label: 'Incluye',
    },
    {
        value: 'not_include',
        label: 'No incluye',
    },
];

export const andOrOptions = [
    {
        label: 'Y',
        value: 'and',
    },
    {
        label: 'O',
        value: 'or',
    },
];

const ordersFieldValues = [
    {
        label: 'Canal de venta',
        value: 'sales_channel',
    },
    {
        label: 'Cliente',
        value: 'customer',
    },
    {
        label: 'Dirección de entrega',
        value: 'shipping_address',
    },
    {
        label: 'Documento de identidad',
        value: 'document',
    },
    {
        label: 'Estado de fulfillment',
        value: 'fulfillment_status',
    },
    {
        label: 'Folio',
        value: 'folio::search_references',
    },
    {
        label: 'Fulfillment',
        value: 'fulfillments',
    },
    {
        label: 'ID Ecommerce',
        value: 'remote_order_id',
    },
    {
        label: 'Código de Bulto',
        value: 'package_code::search_references',
    },
    {
        label: 'Operador logístico',
        value: 'assigned_courier',
    },
    {
        label: 'Plazo límite (h)',
        value: 'promised_date',
    },
    {
        label: 'Promesa de entrega',
        value: 'custom_promised_date',
    },
    {
        label: 'Horario de entrega',
        value: 'promised_date_range',
    },
    {
        label: 'Tienda de destino',
        value: 'locations.destination',
    },
    {
        label: 'Tipo de entrega',
        value: 'delivery_method',
    },
    {
        label: 'Total',
        value: 'total_price',
    },
    {
        label: 'Unidades',
        value: 'line_items',
    },
];

const userLogged = JSON.parse(localStorage.getItem('userLogged'));
const isMascotasLatinas = isML(userLogged?.merchant_id?._id);

const filterFilters = (filters) => {
    return Array.from(filters ?? []).filter((filter) => {
        if (
            [
                'consolidation.consolidation_location_id',
                'preparationcd_transfer_pickupstore',
            ].includes(filter?.value)
        ) {
            return false;
        }
        if (isMascotasLatinas) {
            return !['promised_date'].includes(filter?.value);
        } else {
            return !['custom_promised_date', 'promised_date_range'].includes(filter?.value);
        }
    });
};

export const fieldsValues = {
    orders: filterFilters(ordersFieldValues),
    preparations: filterFilters(preparationsFilters.fields),
    pendingArrivals: filterFilters(pendingArrivalsFilters.fields),
    crossdocking: filterFilters(crossdockingFilters.fields),
    deliveries: filterFilters(deliveriesFilters.fields),
    manifests: filterFilters(manifestsFilters.fields),
};
