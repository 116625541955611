import { Button, Modal, Select } from 'antd';
import { Input, InputWrapper } from './styles';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

const allowedKeyCodes = ['Enter', 'NumpadEnter'];

const ModalBody = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 15px;

    .div1 {
        grid-area: 1 / 1 / 2 / 6;
    }
    .div2 {
        grid-area: 2 / 1 / 3 / 6;
    }
    .div3 {
        grid-area: 3 / 1 / 4 / 6;
    }
`;

const ModalSelectStyled = styled(Select)`
    width: 100%;
    border: 1.5px solid #7998a7;
    border-radius: 3px;
`;

export const InputCode = ({ handleScanCode, value = '', disabled, fulfillmentsManifest }) => {
    const inputRef = useRef(null);
    const [code, setCode] = useState('');
    const [openModalSamePackageCode, setOpenModalSamePackageCode] = useState(false);
    const [fulfillmentsWithSamePackageCode, setFulfillmentsWithSamePackageCode] = useState([]);
    const [selectedFulfillment, setSelectedFulfillment] = useState('');

    const sendCode = () => {
        const manifestWithDuplicatedCodes = {};

        fulfillmentsManifest?.forEach((fulfillment) => {
            fulfillment?.packages?.forEach((pckg) => {
                const code = pckg?.code;
                const id = fulfillment?._id;
                const name = fulfillment?.name;

                if (code) {
                    if (!manifestWithDuplicatedCodes[code]) {
                        manifestWithDuplicatedCodes[code] = [{ id, name }];
                    } else {
                        manifestWithDuplicatedCodes[code].push({ id, name });
                    }
                }
            });
        });

        const codeToCheck = code.trim();
        setFulfillmentsWithSamePackageCode(manifestWithDuplicatedCodes[codeToCheck]);

        if (
            codeToCheck in manifestWithDuplicatedCodes &&
            manifestWithDuplicatedCodes[codeToCheck].length > 1
        ) {
            setOpenModalSamePackageCode(true);
            console.log(`El código ${codeToCheck} se repite en más de dos fulfillments.`);
        } else {
            const fulfillmentWithCode = fulfillmentsManifest.find((fulfillment) =>
                fulfillment.packages?.some((p) => p.code === code)
            );
            if (disabled) {
                0;
                return;
            }
            code && handleScanCode({ code, fulfillmentId: fulfillmentWithCode?._id ?? '' });
            inputRef?.current?.focus();
        }
    };

    const handleConfirmSamePackageCode = () => {
        setOpenModalSamePackageCode(false);

        if (disabled) {
            0;
            return;
        }
        code && handleScanCode({ code, fulfillmentId: selectedFulfillment });
        inputRef?.current?.focus();

        setSelectedFulfillment('');
    };

    useEffect(() => {
        setCode(value);
    }, [value]);

    return (
        <>
            <InputWrapper>
                <Input
                    ref={inputRef}
                    autoFocus
                    value={code}
                    onKeyDown={(event) => allowedKeyCodes.includes(event.code) && sendCode()}
                    onChange={(e) => setCode(e.target.value)}
                    placeholder="Ingresar código"
                    style={{ height: '40px' }}
                />
                <Button
                    type="secondary"
                    onClick={sendCode}
                    style={{
                        borderTopLeftRadius: '0px',
                        borderBottomLeftRadius: '0px',
                    }}
                >
                    Ingresar
                </Button>
            </InputWrapper>
            <Modal
                open={openModalSamePackageCode}
                title="Mismo código de bulto"
                onCancel={() => setOpenModalSamePackageCode(false)}
                destroyOnClose={true}
                onOk={handleConfirmSamePackageCode}
            >
                <ModalBody>
                    <span className="div1">
                        Se ha detectado la existencia del mismo código de bulto para más de un
                        fulfillment.
                    </span>

                    <span className="div2">
                        Por favor especifique a cual pedido pertenece el código de bulto escaneado:
                    </span>

                    <div className="div3">
                        <ModalSelectStyled
                            placeholder="Seleccionar pedido"
                            value={selectedFulfillment}
                            onChange={(e) => setSelectedFulfillment(e)}
                        >
                            {fulfillmentsWithSamePackageCode?.map((fulfillment) => (
                                <Select.Option key={fulfillment?.id} value={fulfillment?.id}>
                                    {fulfillment?.name}
                                </Select.Option>
                            ))}
                        </ModalSelectStyled>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
};
