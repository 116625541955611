import styled from 'styled-components';
import { Select } from 'antd';

export const SelectStyled = styled(Select)`
    font-size: 11.9px;
    /* height: 40px !important; */
    .ant-select-selection-placeholder {
        color: #6c808f;
    }
    .ant-select-arrow {
        // margin-top: -12px;
        // right: 15px;
        color: #f27127;
    }
    .ant-select-selection-item-content {
        font-size: 11.9px;
    }
`;
