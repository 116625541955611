import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import { getGeneralByMerchant } from './getGeneralByMerchant';
import toast from 'react-hot-toast';

export const editGeneral = createAsyncThunk('general/edit', async (data, state) => {
    try {
        const res = await AndesClient.put(`settings/generals/${data?._id}`, data);
        if (res.status === 200) {
            toast.success('Configuración general modificada con éxito.');
            localStorage.removeItem('generalSettings');
            state.dispatch(getGeneralByMerchant());
        }
        return res.data;
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const updateGeneralSlice = createSlice({
    name: 'updateGeneral',
    initialState: {
        loading: false,
        data: {},
        error: null,
    },
    reducers: {},
    extraReducers: {
        [editGeneral.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [editGeneral.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [editGeneral.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default updateGeneralSlice.reducer;
