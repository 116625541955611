import { Checkbox, Select } from 'antd';
import { removeAccents } from 'helpers/removeAccents';
import useAllShops from 'hooks/useAllShops';
import { FiltersContent, SearchSelectStyled } from './styles';
import { MdOutlinePlace } from 'react-icons/md';
import { useEffect } from 'react';
import { RangePicker } from 'components/RangePicker';

export const FiltersDashboard = ({ setShopSelected, setDateCalendar, shopSelected }) => {
    const [, dataShops] = useAllShops();

    useEffect(() => {
        if ((shopSelected && shopSelected?.includes('all')) || shopSelected === '') {
            setShopSelected(['all']);
        }
    }, []);

    const handleChangeShop = (e) => {
        setShopSelected(e);
    };

    const isCheckboxChecked = (value) => {
        if (shopSelected.includes(value)) {
            return true;
        }
        return false;
    };

    const handleChangeAllStores = (e) => {
        if (e.target.checked == true) {
            setShopSelected('all');
        }
    };
    return (
        <FiltersContent>
            <b>Filtrar datos por:</b>

            {/* ------------------------------------------------------------------------------------------------ */}

            <RangePicker setDateCalendar={setDateCalendar} initialTypeDate="current_month" />
            <SearchSelectStyled
                placeholder="Seleccionar tienda"
                style={{ minWidth: '150px' }}
                suffixIcon={<MdOutlinePlace size={26} />}
                maxTagCount="responsive"
                optionLabelProp="title"
                onChange={handleChangeShop}
                mode="multiple"
                value={shopSelected}
                showArrow
                type="location"
            >
                <Select.Option key="all" value="all" title="Todas las tiendas">
                    <Checkbox
                        value="all"
                        style={{ marginRight: '10px' }}
                        checked={isCheckboxChecked('all')}
                        onChange={handleChangeAllStores}
                    />
                    <label>Todas las tiendas</label>
                </Select.Option>
                {Array.from(dataShops ?? [])
                    ?.filter((shop) => shop.is_enabled)
                    ?.sort((a, b) =>
                        removeAccents(a.name.toUpperCase()) > removeAccents(b.name.toUpperCase())
                            ? 1
                            : removeAccents(a.name.toUpperCase()) <
                              removeAccents(b.name.toUpperCase())
                            ? -1
                            : 0
                    )
                    .map((store) => (
                        <Select.Option
                            key={store._id}
                            value={store._id}
                            title={store.name}
                            disabled={shopSelected?.includes('all') ? true : false}
                        >
                            <Checkbox
                                style={{ marginRight: '10px' }}
                                value={store._id}
                                checked={isCheckboxChecked(store._id)}
                                disabled={shopSelected?.includes('all') ? true : false}
                            />
                            <label>{store.name}</label>
                        </Select.Option>
                    ))}
            </SearchSelectStyled>
        </FiltersContent>
    );
};
