import { Button } from '@andescommercecloud/beflow-design-system';
import { ExclamationCircleOutlined, MinusCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';
import { ReactComponent as CopyIcon } from 'assets/new-copy-icon.svg';
import Modal from 'components/Modal';
import CheckboxStyled from 'components/Utils/CheckboxStyled';
import Flex from 'components/Utils/Flex';
import FormStyled from 'components/Utils/Form';
import { SelectMultiple } from 'components/V2/SelectMultiple';
import { regionesYComunas } from 'data/regions';
import timeZone from 'data/timeZone.json';
import dayjs from 'helpers/dayjs';
import { removeAccents } from 'helpers/removeAccents';
import useAllCouriers from 'hooks/useAllCouriers';
import useAllIntegrations from 'hooks/useAllIntegrations';
import useAllPossibleSalesChannels from 'hooks/useAllPossibleSalesChannels';
import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';
import useGetGenerals from 'hooks/useGetGenerals';
import moment from 'moment';
import { FormDivider, Title } from 'pages/Settings/styles';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineUpload } from 'react-icons/ai';
import { BsPlusLg } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetStoresOnlyPickingMutation } from 'redux/features/SalesChannels';
import { createSalesChannel } from 'redux/features/SalesChannels/createSalesChannel';
import { editSalesChannel } from 'redux/features/SalesChannels/editSalesChannel';
import { closeModal, openModal } from 'redux/features/Utils';
import Upload from '../../../../components/Buttons/UploadButton';
import useAllShops from '../../../../hooks/useAllShops';
import { useGetActivitiesQuery } from '../../../../redux/features/Activities';
import { LocationByCountryForm } from '../Shops2/LocationByCountryForm';
import { ActionCancelledOrderForm } from './ActionCancelledOrder';
import AliasCourier from './AliasCourier';
import { ClassificationProductTypeForm } from './ClassificationProductTypeForm';
import StockUnifiedForm from './StockUnifiedForm';
import { centryLabels } from './constants';

const namespaces = [
    {
        label: 'Producto',
        key: 'line_item',
    },
];

const salesChannelsKeys = [
    {
        sales_channel: 'Multivende',
        namespace: 'line_item',
        key: 'ProductVersion.internalCode',
        label: 'ProductVersion > internalCode',
    },
    {
        sales_channel: 'Multivende',
        namespace: 'line_item',
        key: 'ProductVersion.code',
        label: 'ProductVersion > code',
    },
    {
        sales_channel: 'ESB',
        namespace: 'line_item',
        key: 'refId',
        label: 'refId',
    },
    {
        sales_channel: 'ESB',
        namespace: 'line_item',
        key: 'ean',
        label: 'ean',
    },
    {
        sales_channel: 'VTEX',
        namespace: 'line_item',
        key: 'refId',
        label: 'refId',
    },
    {
        sales_channel: 'VTEX',
        namespace: 'line_item',
        key: 'ean',
        label: 'ean',
    },
];

const omsKeys = [
    {
        namespace: 'line_item',
        label: 'Atributo > Ean',
        key: 'ean',
    },
    {
        namespace: 'line_item',
        label: 'Atributo > Sku',
        key: 'sku',
    },
    {
        namespace: 'line_item',
        label: 'Atributo > Remote variant id',
        key: 'remote_variant_id',
    },
];

const SalesChannelMappingList = () => {
    return (
        <Form.Item>
            <Form.List initialValue={[]} name="mappings">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Row gutter={8} key={key}>
                                <Col span={5}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'namespace']}
                                        rules={[
                                            { required: true, message: 'Se requiere el espacio' },
                                        ]}
                                        label="Entidad"
                                    >
                                        <Select placeholder="Selecciona un espacio">
                                            {namespaces.map((namespace) => (
                                                <Select.Option
                                                    placeholder="Llave"
                                                    value={namespace.key}
                                                    key={namespace.key}
                                                >
                                                    {namespace.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) =>
                                            prevValues?.mappings[name]?.namespace !==
                                            currentValues?.mappings[name]?.namespace
                                        }
                                    >
                                        {({ getFieldValue }) => {
                                            const namespaceSelected = getFieldValue([
                                                'mappings',
                                                name,
                                                'namespace',
                                            ]);

                                            return (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'oms_key']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Se requiere la llave',
                                                        },
                                                    ]}
                                                    label="Campo en OMS"
                                                >
                                                    <Select
                                                        disabled={!namespaceSelected}
                                                        placeholder={
                                                            !namespaceSelected
                                                                ? 'Selecciona un espacio'
                                                                : 'Selecciona un campo en oms'
                                                        }
                                                    >
                                                        {omsKeys
                                                            .filter(
                                                                (k) =>
                                                                    k.namespace ===
                                                                    namespaceSelected
                                                            )
                                                            .map((omsKey) => (
                                                                <Select.Option
                                                                    placeholder="Llave"
                                                                    value={omsKey.key}
                                                                    key={omsKey.key}
                                                                >
                                                                    {omsKey.label}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            );
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(prevValues, currentValues) =>
                                            prevValues?.platform !== currentValues?.platform ||
                                            prevValues?.mappings[name]?.namespace !==
                                                currentValues?.mappings[name]?.namespace
                                        }
                                    >
                                        {({ getFieldValue }) => {
                                            const platformSelected = getFieldValue('platform');
                                            const namespaceSelected = getFieldValue([
                                                'mappings',
                                                name,
                                                'namespace',
                                            ]);
                                            return (
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'sales_channel_key']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Se requiere el valor',
                                                        },
                                                    ]}
                                                    label="Campo en canal de venta"
                                                    style={{ flex: '1' }}
                                                >
                                                    <Select
                                                        disabled={
                                                            !platformSelected || !namespaceSelected
                                                        }
                                                        placeholder="Selecciona un campo del canal de venta"
                                                    >
                                                        {salesChannelsKeys
                                                            .filter(
                                                                (s) =>
                                                                    s.sales_channel ===
                                                                        platformSelected &&
                                                                    s.namespace ===
                                                                        namespaceSelected
                                                            )
                                                            .map((salesChannelsKey) => (
                                                                <Select.Option
                                                                    placeholder="Llave"
                                                                    value={salesChannelsKey.key}
                                                                    key={salesChannelsKey.key}
                                                                >
                                                                    {salesChannelsKey.label}
                                                                </Select.Option>
                                                            ))}
                                                    </Select>
                                                </Form.Item>
                                            );
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Form.Item style={{ marginTop: '26px' }}>
                                        <Button
                                            variation="secondary"
                                            style={{
                                                backgroundColor: '#2D3D76',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                height: '42px',
                                                width: '100%',
                                                border: '1px solid #036896',
                                                color: '#ffffff',
                                            }}
                                            icon={<MinusCircleOutlined />}
                                            onClick={() => remove(name)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        ))}
                        <Form.Item>
                            <Button
                                variation="secondary"
                                onClick={() => add()}
                                icon={<BsPlusLg />}
                                iconPosition="right"
                                block
                                dashed
                            >
                                Agregar mapeo de campos
                            </Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form.Item>
    );
};

const SalesChannelsForm = ({ initialValues, formName, formAction, setAMostrar, setViewEdit }) => {
    const isCreateForm = formAction === 'new';
    const taxableShippingRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();
    const [haveVirtualLocations, setHaveVirtualLocations] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [loadingLogo, setLoadingLogo] = useState(false);
    const [isEnabledBtn, setIsEnabledBtn] = useState(true);
    const [disabledTaxShipping, setDisabledTaxShipping] = useState(false);
    const [showSkipTransferCheckbox, setShowSkipTransferCheckbox] = useState(false);
    const [changeStatusVirtualLocationField, setChangeStatusVirtualLocationField] = useState(false);
    const [changeStatusPrimaryPhysicalLocationField, setChangeStatusPrimaryPhysicalLocationField] =
        useState(false);
    const [
        changeStatusSecondaryPhysicalLocationField,
        setChangeStatusSecondaryPhysicalLocationField,
    ] = useState(false);
    const [isSapIntegrations, setIsSapIntegrations] = useState(false);
    const [virtualLocationsHasChanged, setVirtualLocationsHasChanged] = useState(false);

    const [, isAdmin] = useGetCurrentPermissions();
    const [getStoresPickup] = useGetStoresOnlyPickingMutation();
    const activities = useGetActivitiesQuery();
    const [, dataGenerals] = useGetGenerals();
    const [tiendasConRetiro, setTiendasConRetiro] = useState([]);
    const [dataShopsLoading, dataShops] = useAllShops();
    const [, dataIntegrations] = useAllIntegrations();
    const [, possibleSalesChannels] = useAllPossibleSalesChannels();
    const [, couriers] = useAllCouriers();

    const [form] = Form.useForm();
    const haveVirtualLocation = Form.useWatch('have_virtual_location', form);
    const virtualLocationSelected = Form.useWatch('virtual_location', form);
    const primaryPhysicalLocationSelected = Form.useWatch('primary_physical_location', form);
    const secondaryPhysicalLocationSelected = Form.useWatch('secondary_physical_location', form);
    const platformSelected = Form.useWatch('platform', form);
    const consolidationEnabled = Form.useWatch(['fulfillment_consolidation', 'enabled'], form);
    const activeDirectionColombia = useGetValueFeatureFlag('direction_colombia');

    useEffect(() => {
        if (!taxableShippingRef?.current || !dataGenerals) return;

        if (['peru', 'chile'].includes(dataGenerals?.country)) {
            setDisabledTaxShipping(true);
            taxableShippingRef.current.disabled = true;
            taxableShippingRef.current.checked = true;
            form.setFieldsValue({
                billing_data: {
                    taxable_shipping: true,
                },
            });
        } else {
            taxableShippingRef.current.disabled = false;
            setDisabledTaxShipping(false);
        }
    }, [taxableShippingRef, dataGenerals]);

    useEffect(() => {
        if (!virtualLocationSelected) {
            return;
        }

        if (formAction === 'edit') {
            let openValidationModal = false;

            if (
                (primaryPhysicalLocationSelected !== secondaryPhysicalLocationSelected &&
                    secondaryPhysicalLocationSelected !== virtualLocationSelected &&
                    virtualLocationSelected !== primaryPhysicalLocationSelected) ||
                secondaryPhysicalLocationSelected !== virtualLocationSelected
            ) {
                setChangeStatusVirtualLocationField(false);
                setChangeStatusPrimaryPhysicalLocationField(false);
                setChangeStatusSecondaryPhysicalLocationField(false);
                setShowSkipTransferCheckbox(false);
            }

            if ([primaryPhysicalLocationSelected].includes(virtualLocationSelected)) {
                setChangeStatusVirtualLocationField('error');
                setChangeStatusPrimaryPhysicalLocationField('error');
                setShowSkipTransferCheckbox(true);
                openValidationModal = true;
            }

            if ([secondaryPhysicalLocationSelected].includes(virtualLocationSelected)) {
                setChangeStatusVirtualLocationField('error');
                setChangeStatusSecondaryPhysicalLocationField('error');
                setShowSkipTransferCheckbox(true);
                openValidationModal = true;
            }

            if (virtualLocationSelected === primaryPhysicalLocationSelected) {
                setChangeStatusVirtualLocationField('error');
                setChangeStatusPrimaryPhysicalLocationField('error');
            }

            if (virtualLocationSelected === secondaryPhysicalLocationSelected) {
                setChangeStatusVirtualLocationField('error');
                setChangeStatusSecondaryPhysicalLocationField('error');
            }

            // if (
            //     virtualLocationSelected &&
            //     primaryPhysicalLocationSelected == secondaryPhysicalLocationSelected
            // ) {
            //     setChangeStatusVirtualLocationField('error');
            //     setChangeStatusPrimaryPhysicalLocationField('error');
            //     setChangeStatusSecondaryPhysicalLocationField('error');
            //     setShowSkipTransferCheckbox(true);
            // }

            if (
                [
                    initialValues?.primary_physical_location?._id,
                    initialValues?.secondary_physical_location?._id,
                ].includes(virtualLocationSelected) &&
                !virtualLocationsHasChanged
            ) {
                openValidationModal = false;
            }

            if (openValidationModal) {
                dispatch(openModal('toggle_validate_virtualLocations'));
            }
        } else {
            if (
                (primaryPhysicalLocationSelected !== secondaryPhysicalLocationSelected &&
                    secondaryPhysicalLocationSelected !== virtualLocationSelected &&
                    virtualLocationSelected !== primaryPhysicalLocationSelected) ||
                secondaryPhysicalLocationSelected !== virtualLocationSelected
            ) {
                setChangeStatusVirtualLocationField(false);
                setChangeStatusPrimaryPhysicalLocationField(false);
                setChangeStatusSecondaryPhysicalLocationField(false);
                setShowSkipTransferCheckbox(false);
            }

            if ([primaryPhysicalLocationSelected].includes(virtualLocationSelected)) {
                setChangeStatusVirtualLocationField('error');
                setChangeStatusPrimaryPhysicalLocationField('error');
                dispatch(openModal('toggle_validate_virtualLocations'));
            }

            if ([secondaryPhysicalLocationSelected].includes(virtualLocationSelected)) {
                setChangeStatusVirtualLocationField('error');
                setChangeStatusSecondaryPhysicalLocationField('error');
                dispatch(openModal('toggle_validate_virtualLocations'));
            }
        }
    }, [
        virtualLocationSelected,
        primaryPhysicalLocationSelected,
        secondaryPhysicalLocationSelected,
        formAction,
    ]);

    const handleClose = () => {
        if (isCreateForm) return history.push('/settings/channels');

        setAMostrar('');
        setViewEdit((prev) => !prev);
    };

    const getStorePickup = async () => {
        const { data } = await getStoresPickup();

        if (!data) return;
        setTiendasConRetiro(data.filter((d) => d?.has_fulfillment === true));
    };

    useEffect(() => {
        getStorePickup();

        return () => {
            form.resetFields();
            setHaveVirtualLocations(false);
        };
    }, []);

    const handleSubmit = (values) => {
        const logo = fileList.length ? fileList[0].url : null;
        const hasBankDataForReturnMoney = isSapIntegrations
            ? values.has_bank_data_for_return_money
            : false;

        if (isCreateForm) {
            dispatch(
                createSalesChannel({
                    ...values,
                    billing_data: {
                        ...(values.billing_data && { ...values.billing_data }),
                        ...(logo && { logo_url: logo }),
                    },
                    // has_transit_warehouse: checkAcceptReturn,
                    has_bank_data_for_return_money: hasBankDataForReturnMoney,
                    // transit_warehouse: transitWarehouse,
                    // allow_upstream_state_notification: allowUpStreamStateNotification,
                })
            );
            setAMostrar('');
            setViewEdit((prev) => !prev);
        }
        if (!isCreateForm) {
            dispatch(
                editSalesChannel({
                    _id: initialValues._id,
                    is_enabled: isEnabledBtn,
                    ...values,
                    billing_data: {
                        ...(initialValues?.billing_data || {}),
                        ...(values?.billing_data || {}),
                        ...(logo && { logo_url: logo }),
                    },
                    has_bank_data_for_return_money: hasBankDataForReturnMoney,
                    // has_transit_warehouse: checkAcceptReturn,
                    // transit_warehouse: transitWarehouse,
                    // allow_upstream_state_notification: allowUpStreamStateNotification,
                })
            );
            setAMostrar('');
            setViewEdit((prev) => !prev);
        }
        setFileList([]);
        form.resetFields();
    };

    useEffect(() => {
        if (!initialValues?._id) return;

        form.setFieldsValue({
            ...initialValues,
            virtual_location: initialValues?.virtual_location?._id ?? null,
            primary_physical_location: initialValues?.primary_physical_location?._id ?? null,
            secondary_physical_location: initialValues?.secondary_physical_location?._id ?? null,
            integration_pos_erp: initialValues?.integration_pos_erp?._id ?? null,
            integration_dte: initialValues?.integration_dte?._id ?? null,
            order_report: initialValues?.order_report ?? null,
            order_report_status: initialValues?.order_report_status ?? false,
            classification_product_type: initialValues?.classification_product_type
                ? {
                      ...initialValues?.classification_product_type,
                      type_operation:
                          initialValues?.classification_product_type?.type_operation ??
                          'type_product_and_sku',
                  }
                : null,
            // allow_cancel_in_status: initialValues?.allow_cancel_in_status?._id ?? null,
            init_date: moment(initialValues?.init_date),
        });

        setIsSapIntegrations(
            ['multiple', 'sapb1'].includes(initialValues?.integration_pos_erp?.company)
        );
        setHaveVirtualLocations(initialValues.have_virtual_location);
        setIsEnabledBtn(initialValues.is_enabled);
    }, [initialValues]);

    const handleCancelButtonModal = () => {
        if (virtualLocationSelected === primaryPhysicalLocationSelected) {
            form.setFieldsValue({
                primary_physical_location: null,
            });
        }

        if (virtualLocationSelected === secondaryPhysicalLocationSelected) {
            form.setFieldsValue({
                secondary_physical_location: null,
            });
        }
        setChangeStatusVirtualLocationField(false);
        setChangeStatusPrimaryPhysicalLocationField(false);
        setChangeStatusSecondaryPhysicalLocationField(false);
        dispatch(closeModal('toggle_validate_virtualLocations'));
    };

    const handleOkButtonModal = () => {
        setShowSkipTransferCheckbox(true);
        dispatch(closeModal('toggle_validate_virtualLocations'));
    };

    const handleCheckChange = (checked) => {
        setHaveVirtualLocations(checked);
        setShowSkipTransferCheckbox(false);
        setChangeStatusVirtualLocationField(false);
        setChangeStatusPrimaryPhysicalLocationField(false);
        setChangeStatusSecondaryPhysicalLocationField(false);
        form.setFieldsValue({
            virtual_location: null,
            primary_physical_location: null,
            secondary_physical_location: null,
        });
    };

    const handleChangeIntegrations = (integrationId) => {
        const integrations = dataIntegrations.find(
            (integration) => integration._id === integrationId
        );

        const isSapIntegration = ['multiple', 'sapb1'].includes(integrations?.company);

        setIsSapIntegrations(isSapIntegration);
    };

    const errorTooltip =
        'Has seleccionado la misma Tienda/Bodega para tienda virtual y CD físico, esto producirá errores al intentar generar integraciones de transferencia de stock.';

    const formattedLocations = useMemo(
        () =>
            dataShops
                ?.filter((activa) => activa.is_enabled === true)
                ?.sort((a, b) =>
                    removeAccents(a.name.toUpperCase()) > removeAccents(b.name.toUpperCase())
                        ? 1
                        : removeAccents(a.name.toUpperCase()) < removeAccents(b.name.toUpperCase())
                        ? -1
                        : 0
                ),
        [dataShops]
    );

    return (
        <div>
            <Title style={{ fontSize: 16, fontWeight: 'bold', marginTop: 8 }}>
                {isCreateForm ? 'Crear canal de venta' : 'Editar canal de venta'}
            </Title>
            <FormStyled
                className="mt-8"
                form={form}
                name={formName}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Modal
                    title={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <ExclamationCircleOutlined style={{ color: 'red' }} />
                            <span style={{ marginLeft: '8px' }}>Selección misma Tienda/Bodega</span>
                        </div>
                    }
                    modalName={'toggle_validate_virtualLocations'}
                    maskClosable={false}
                    closable={true}
                    cancelText="Cancelar"
                    okText="Continuar"
                    onCancel={handleCancelButtonModal}
                    onOk={handleOkButtonModal}
                >
                    {' '}
                    <p>
                        Has seleccionado la <b>misma</b> Tienda/Bodega para tienda virtual y CD
                        físico, esto producirá errores al intentar generar integraciones de
                        transferencia de stock.
                    </p>{' '}
                    <p style={{ fontWeight: 'bold', paddingTop: '30px' }}>¿Desea continuar?</p>
                </Modal>

                <div>
                    <div className="col-span-5">
                        <Title>Información general</Title>
                        {initialValues?._id && (
                            <div>
                                <div>
                                    <b>Channel ID</b>
                                </div>

                                <Typography.Text
                                    copyable={{
                                        icon: <CopyIcon />,
                                        text: initialValues._id,
                                        iconStyle: {
                                            color: '#2D3D76',
                                        },
                                    }}
                                    style={{
                                        weight: 'bold',
                                        color: '#1F6373',
                                    }}
                                >
                                    <u style={{ color: '#037FB9' }}>{initialValues._id}</u>
                                </Typography.Text>
                                <br />
                                <br />
                            </div>
                        )}

                        <Form.Item
                            name="alias"
                            initialValue=""
                            label="Nombre del canal de venta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido',
                                },
                            ]}
                        >
                            <Input placeholder="Ingrese el nombre de canal de venta" />
                        </Form.Item>
                        <Form.Item
                            name="init_date"
                            initialValue=""
                            style={{ maxWidth: 350 }}
                            label="Fecha y hora de inicio de descarga de venta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido',
                                },
                            ]}
                        >
                            <DatePicker showTime format="DD-MM-YYYY HH:mm" />
                        </Form.Item>

                        <Form.Item name="have_virtual_location" valuePropName="checked">
                            <CheckboxStyled
                                checked={haveVirtualLocations}
                                onChange={(e) => handleCheckChange(e.target.checked)}
                            >
                                Asociar bodega virtual al canal de venta
                            </CheckboxStyled>
                        </Form.Item>

                        {haveVirtualLocation && (
                            <div
                                style={{
                                    width: '100%',
                                    backgroundColor: '#F5F5F5',
                                    padding: '10px',
                                    border: '1px solid #cacaca',
                                    borderRadius: '5px',
                                }}
                            >
                                <Form.Item
                                    name="virtual_location"
                                    label="Tienda Virtual"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Este campo es requerido',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Seleccione una tienda virtual"
                                        showSearch
                                        optionFilterProp="children"
                                        status={changeStatusVirtualLocationField}
                                        onChange={() => {
                                            setVirtualLocationsHasChanged(true);
                                        }}
                                        suffixIcon={
                                            changeStatusVirtualLocationField === 'error' && (
                                                <WarningOutlined
                                                    title={errorTooltip}
                                                    style={{ color: 'red' }}
                                                />
                                            )
                                        }
                                    >
                                        {dataShops &&
                                            dataShops
                                                ?.filter((activa) => activa.is_enabled === true)
                                                ?.sort((a, b) =>
                                                    removeAccents(a.name.toUpperCase()) >
                                                    removeAccents(b.name.toUpperCase())
                                                        ? 1
                                                        : removeAccents(a.name.toUpperCase()) <
                                                          removeAccents(b.name.toUpperCase())
                                                        ? -1
                                                        : 0
                                                )
                                                .map((shop) => (
                                                    <Select.Option key={shop._id} value={shop._id}>
                                                        {shop.name}
                                                    </Select.Option>
                                                ))}
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="primary_physical_location"
                                    label="CD Físico primario"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Este campo es requerido',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Seleccione un CD físico"
                                        showSearch
                                        optionFilterProp="children"
                                        status={changeStatusPrimaryPhysicalLocationField}
                                        onChange={() => {
                                            setVirtualLocationsHasChanged(true);
                                        }}
                                        suffixIcon={
                                            changeStatusPrimaryPhysicalLocationField ===
                                                'error' && (
                                                <WarningOutlined
                                                    title={errorTooltip}
                                                    style={{ color: 'red' }}
                                                />
                                            )
                                        }
                                    >
                                        {dataShops &&
                                            dataShops
                                                ?.filter((activa) => activa.is_enabled === true)
                                                ?.sort((a, b) =>
                                                    removeAccents(a.name.toUpperCase()) >
                                                    removeAccents(b.name.toUpperCase())
                                                        ? 1
                                                        : removeAccents(a.name.toUpperCase()) <
                                                          removeAccents(b.name.toUpperCase())
                                                        ? -1
                                                        : 0
                                                )
                                                .map((shop) => (
                                                    <Select.Option key={shop._id} value={shop._id}>
                                                        {shop.name}
                                                    </Select.Option>
                                                ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="secondary_physical_location"
                                    label="CD Físico secundario (opcional)"
                                >
                                    <Select
                                        placeholder="Seleccione un CD físico"
                                        showSearch
                                        optionFilterProp="children"
                                        status={changeStatusSecondaryPhysicalLocationField}
                                        onChange={() => {
                                            setVirtualLocationsHasChanged(true);
                                        }}
                                        suffixIcon={
                                            changeStatusSecondaryPhysicalLocationField ===
                                                'error' && (
                                                <WarningOutlined
                                                    title={errorTooltip}
                                                    style={{ color: 'red' }}
                                                />
                                            )
                                        }
                                    >
                                        {dataShops &&
                                            dataShops
                                                ?.filter((activa) => activa.is_enabled === true)
                                                ?.sort((a, b) =>
                                                    removeAccents(a.name.toUpperCase()) >
                                                    removeAccents(b.name.toUpperCase())
                                                        ? 1
                                                        : removeAccents(a.name.toUpperCase()) <
                                                          removeAccents(b.name.toUpperCase())
                                                        ? -1
                                                        : 0
                                                )
                                                .map((shop) => (
                                                    <Select.Option key={shop._id} value={shop._id}>
                                                        {shop.name}
                                                    </Select.Option>
                                                ))}
                                    </Select>
                                </Form.Item>

                                {showSkipTransferCheckbox && (
                                    <Form.Item
                                        name="skip_transfer_movement"
                                        valuePropName="checked"
                                    >
                                        <CheckboxStyled>
                                            Omitir movimiento de transferencia
                                        </CheckboxStyled>
                                    </Form.Item>
                                )}
                            </div>
                        )}

                        <br />
                        <Form.Item
                            name="platform"
                            label="Plataforma del canal de venta"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido',
                                },
                            ]}
                        >
                            <Select placeholder="Seleccione">
                                {possibleSalesChannels.map((platform) => (
                                    <Select.Option key={platform} value={platform}>
                                        {platform === 'salesforce_esb'
                                            ? 'Salesforce ESB'
                                            : platform}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.platform !== currentValues.platform
                            }
                        >
                            {({ getFieldValue }) => {
                                if (['VTEX', 'ESB'].includes(getFieldValue('platform'))) {
                                    return (
                                        <Form.Item name="amount_treatment" valuePropName="checked">
                                            <CheckboxStyled>
                                                ¿Realizar Tratamiento de montos decimales?
                                            </CheckboxStyled>
                                        </Form.Item>
                                    );
                                }
                            }}
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.platform !== currentValues.platform
                            }
                        >
                            {({ getFieldValue }) => {
                                if (['VTEX'].includes(getFieldValue('platform'))) {
                                    return (
                                        <Form.Item
                                            name={['stock_config', 'get_skuid_with_refid']}
                                            valuePropName="checked"
                                        >
                                            <CheckboxStyled>
                                                ¿Obtener SKU ID utilizando REF ID en actualizaciones
                                                de stock?
                                            </CheckboxStyled>
                                        </Form.Item>
                                    );
                                }
                                if (['Centry'].includes(getFieldValue('platform'))) {
                                    return (
                                        <Form.Item
                                            name={['stock_config', 'use_variant_warehouse']}
                                            valuePropName="checked"
                                        >
                                            <CheckboxStyled>
                                                ¿Usar VariantWarehouse en actualizaciones de stock?
                                            </CheckboxStyled>
                                        </Form.Item>
                                    );
                                }
                            }}
                        </Form.Item>

                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="integration"
                                    label="Integración asociada"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Este campo es requerido',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Seleccione">
                                        {dataIntegrations &&
                                            dataIntegrations
                                                .filter(
                                                    (integration) =>
                                                        integration.entity === 'salesChannels'
                                                )
                                                .map((integration) => (
                                                    <Select.Option
                                                        key={integration._id}
                                                        value={integration._id}
                                                    >
                                                        {integration.api_name}
                                                    </Select.Option>
                                                ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="timezone"
                                    label="Zona horaria"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Este campo es requerido',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Seleccione" showSearch={true}>
                                        {timeZone.map((tz, index) => (
                                            <Select.Option key={index} value={tz.value}>
                                                {tz.value} ({dayjs().tz(tz.value).format('Z')})
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name="pickup_fulfillment_in_cd" valuePropName="checked">
                            <CheckboxStyled>
                                Activar pedido con retiro en tienda preparado en CD/Warehouse
                            </CheckboxStyled>
                        </Form.Item>
                        <Form.Item name="order_without_dte_cannot_prepared" valuePropName="checked">
                            <CheckboxStyled>
                                Habilitar requerimiento de existencia de DTE para efectuar
                                preparación de pedido/fulfillment
                            </CheckboxStyled>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.pickup_fulfillment_in_cd !==
                                currentValues.pickup_fulfillment_in_cd
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('pickup_fulfillment_in_cd') && (
                                    <Form.Item
                                        className="ant-select-label-secondary"
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#F5F5F5',
                                            padding: '10px',
                                            border: '1px solid #cacaca',
                                            borderRadius: '5px',
                                        }}
                                        name="pickup_cd_location"
                                        label="CD/Warehouse que preparará pedidos con retiro"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Seleccione">
                                            {Array.from(tiendasConRetiro ?? []).map((shop) => (
                                                <Select.Option key={shop._id} value={shop._id}>
                                                    {shop.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )
                            }
                        </Form.Item>

                        {['Shopify', 'salesforce_esb'].includes(platformSelected) && (
                            <Fragment>
                                <FormDivider />
                                <Title>Consolidación</Title>

                                <Form.Item
                                    name={['fulfillment_consolidation', 'enabled']}
                                    valuePropName="checked"
                                >
                                    <CheckboxStyled>
                                        Activar consolidación para pedidos con uno o múltiples
                                        fulfillments
                                    </CheckboxStyled>
                                </Form.Item>

                                {consolidationEnabled && (
                                    <Fragment>
                                        {['Shopify'].includes(platformSelected) && (
                                            <Form.Item
                                                name={['fulfillment_consolidation', 'location_id']}
                                                label="Seleccione la tienda/bodega que efectuará la consolidación"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Este campo es requerido',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Seleccione la tienda/bodega"
                                                    loading={dataShopsLoading}
                                                >
                                                    {formattedLocations?.map((shop) => (
                                                        <Select.Option
                                                            key={shop._id}
                                                            value={shop._id}
                                                        >
                                                            {shop.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        )}

                                        <Form.Item
                                            name={['fulfillment_consolidation', 'courier_id']}
                                            label="Seleccione el courier que efectuará la consolidación"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Este campo es requerido',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Seleccione el courier"
                                                loading={dataShopsLoading}
                                            >
                                                {couriers?.map((courier) => (
                                                    <Select.Option
                                                        key={courier._id}
                                                        value={courier._id}
                                                    >
                                                        {courier?.public_name ??
                                                            courier?.courier_name}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}

                        <FormDivider />
                        <Title>Integraciones</Title>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    name="integration_pos_erp"
                                    label="Integración POS/ERP"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Este campo es requerido',
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={handleChangeIntegrations}
                                        placeholder="Seleccione"
                                    >
                                        {dataIntegrations &&
                                            dataIntegrations
                                                .filter((integration) =>
                                                    ['pos', 'erp', 'multiple'].includes(
                                                        integration.entity
                                                    )
                                                )
                                                .map((integration) => (
                                                    <Select.Option
                                                        key={integration._id}
                                                        value={integration._id}
                                                    >
                                                        <span>{integration.api_name}</span>
                                                    </Select.Option>
                                                ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="integration_dte"
                                    label="Integración de emisión de documentos electrónicos"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Este campo es requerido',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Seleccione">
                                        {dataIntegrations &&
                                            dataIntegrations
                                                .filter(
                                                    (integration) =>
                                                        integration.entity ===
                                                        'issuer_electronic_document'
                                                )
                                                .map((integration) => (
                                                    <Select.Option
                                                        key={integration._id}
                                                        value={integration._id}
                                                    >
                                                        {integration.api_name}
                                                    </Select.Option>
                                                ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="integration_reversso"
                                    label="Integración de reversso"
                                >
                                    <Select placeholder="Seleccione" allowClear>
                                        {dataIntegrations &&
                                            dataIntegrations
                                                .filter(
                                                    (integration) =>
                                                        integration.entity === 'reversso'
                                                )
                                                .map((integration) => (
                                                    <Select.Option
                                                        key={integration._id}
                                                        value={integration._id}
                                                    >
                                                        {integration.api_name}
                                                    </Select.Option>
                                                ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item name="order_report_status" valuePropName="checked">
                            <CheckboxStyled>Integración WMS</CheckboxStyled>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.order_report_status !== currentValues.order_report_status
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('order_report_status') && (
                                    <Form.Item
                                        style={{
                                            width: '100%',
                                            backgroundColor: '#F5F5F5',
                                            padding: '10px',
                                            border: '1px solid #cacaca',
                                            borderRadius: '5px',
                                        }}
                                        name="order_report"
                                        className="ant-select-label-secondary"
                                        label="Integración de reporte a WMS"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Este campo es requerido',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Seleccione">
                                            {dataIntegrations &&
                                                dataIntegrations
                                                    .filter(
                                                        (integration) =>
                                                            integration.entity === 'order_report'
                                                    )
                                                    .map((integration) => (
                                                        <Select.Option
                                                            key={integration._id}
                                                            value={integration._id}
                                                        >
                                                            {integration.api_name}
                                                        </Select.Option>
                                                    ))}
                                        </Select>
                                    </Form.Item>
                                )
                            }
                        </Form.Item>

                        <Form.Item name="allow_automatic_order_download" valuePropName="checked">
                            <CheckboxStyled>
                                Habilitar descarga de venta desde integración
                            </CheckboxStyled>
                        </Form.Item>

                        {isSapIntegrations && isAdmin && (
                            <div>
                                <FormDivider />
                                <Title>Notas de crédito</Title>
                                <Form.Item
                                    name="has_bank_data_for_return_money"
                                    valuePropName="checked"
                                >
                                    <CheckboxStyled>
                                        Habilitar formulario para suministro de datos bancarios para
                                        devolución de dinero en caso de ocurrencia de nota de
                                        crédito
                                    </CheckboxStyled>
                                </Form.Item>
                            </div>
                        )}

                        <FormDivider />
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.platform !== currentValues.platform
                            }
                        >
                            {({ getFieldValue }) =>
                                ['Multivende'].includes(getFieldValue('platform')) && (
                                    <Form.Item
                                        name="multivende_labels"
                                        label="Obtener etiquetas de operador logistico con estos origenes"
                                    >
                                        <Select mode="multiple">
                                            <Select.Option value="linio">Linio</Select.Option>
                                            <Select.Option value="dafiti">Dafiti</Select.Option>
                                            <Select.Option value="ripley">Ripley</Select.Option>
                                            <Select.Option value="parís">París</Select.Option>
                                            <Select.Option value="mercadolibre">
                                                MercadoLibre
                                            </Select.Option>
                                            <Select.Option value="fcom">
                                                Falabella {`(fcom)`}
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.platform !== currentValues.platform
                            }
                        >
                            {({ getFieldValue }) =>
                                ['Centry'].includes(getFieldValue('platform')) && (
                                    <Form.Item
                                        name="centry_labels"
                                        label="Obtener etiquetas de operador logístico con estos orígenes"
                                    >
                                        <Select mode="multiple">
                                            {centryLabels?.map((label) => (
                                                <Select.Option key={label.code} value={label.code}>
                                                    {label.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                )
                            }
                        </Form.Item>

                        <StockUnifiedForm form={form} locations={dataShops} />

                        <FormDivider />
                        <ActionCancelledOrderForm form={form} dataShops={dataShops ?? []} />

                        <FormDivider />
                        <Title>Mapeo de campos</Title>
                        <SalesChannelMappingList />
                    </div>

                    <FormDivider />

                    <div className="col-span-5">
                        <Title>Envío</Title>

                        <Form.Item
                            name="default_delivery_method"
                            label="Tipo de entrega por defecto"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido',
                                },
                            ]}
                            help="Se puede mapear en la seccion de mapeos en configuraciones"
                        >
                            <Select placeholder="Seleccione" style={{ maxWidth: 700 }}>
                                <Select.Option value="shipping">Despacho</Select.Option>
                                <Select.Option value="pickup">Retiro</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{ marginTop: '2rem' }}
                            name="default_courier"
                            label="Operador logistico por defecto"
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido',
                                },
                            ]}
                            help="Se puede mapear en la seccion de mapeos en configuraciones"
                        >
                            <Select style={{ maxWidth: 700 }} placeholder="Seleccione">
                                {Array.from(couriers ?? []).map((courier) => (
                                    <Select.Option key={courier._id} value={courier._id}>
                                        {courier?.public_name ?? courier?.courier_name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name={['shipping_item', 'is_required']}
                            style={{ marginTop: 20 }}
                            valuePropName="checked"
                        >
                            <CheckboxStyled>¿Crear envío como producto?</CheckboxStyled>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.shipping_item?.is_required !==
                                currentValues.shipping_item?.is_required
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue(['shipping_item', 'is_required']) === true && (
                                    <Fragment>
                                        <Form.Item
                                            style={{
                                                width: '100%',
                                                backgroundColor: '#F5F5F5',
                                                padding: '10px',
                                                border: '1px solid #cacaca',
                                                borderRadius: '5px',
                                            }}
                                            name={['shipping_item', 'sku']}
                                            label="SKU"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Este campo es requerido',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="SKU" style={{ maxWidth: 700 }} />
                                        </Form.Item>
                                    </Fragment>
                                )
                            }
                        </Form.Item>

                        <AliasCourier form={form} />

                        {platformSelected === 'salesforce_esb' && (
                            <Fragment>
                                <FormDivider />
                                <Title>Filtros del webhook</Title>

                                <Form.Item
                                    name={['settings', 'enabled_filter_location']}
                                    valuePropName="checked"
                                >
                                    <CheckboxStyled>
                                        Activar filtro de selección de tiendas
                                    </CheckboxStyled>
                                </Form.Item>
                                <Form.Item
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.settings?.enabled_filter_location !==
                                        currentValues.settings?.enabled_filter_location
                                    }
                                >
                                    {({ getFieldValue }) => {
                                        const show = getFieldValue([
                                            'settings',
                                            'enabled_filter_location',
                                        ]);

                                        if (!show) return;

                                        return (
                                            <Form.Item
                                                name={['settings', 'filter_locations']}
                                                label=""
                                                className="ant-select-label-secondary"
                                                style={{
                                                    width: '100%',
                                                    backgroundColor: '#F5F5F5',
                                                    padding: '10px',
                                                    border: '1px solid #cacaca',
                                                    borderRadius: '5px',
                                                }}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Es necesario al menos una tienda',
                                                    },
                                                ]}
                                            >
                                                <SelectMultiple
                                                    placeholder="Seleccione"
                                                    options={dataShops}
                                                    optionTitleKey="name"
                                                    optionValueKey="_id"
                                                    value={
                                                        initialValues?.settings?.filter_locations ??
                                                        []
                                                    }
                                                    style={{ maxWidth: 700 }}
                                                />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Fragment>
                        )}

                        <FormDivider />
                        <Title>Datos de facturación</Title>

                        <Form.Item
                            name={'billing_address_is_shipping_address'}
                            valuePropName="checked"
                        >
                            <CheckboxStyled>
                                Al recepcionar orden copiar datos de dirección de envío a dirección
                                de facturación{' '}
                            </CheckboxStyled>
                        </Form.Item>

                        <Form.Item name="use_general_billing_data" valuePropName="checked">
                            <CheckboxStyled>
                                Usar los datos de facturación de configuración general
                            </CheckboxStyled>
                        </Form.Item>

                        <Form.Item
                            style={{ marginTop: '1rem' }}
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.use_general_billing_data !==
                                currentValues.use_general_billing_data
                            }
                        >
                            {({ getFieldValue }) => {
                                const show = !getFieldValue('use_general_billing_data');
                                if (show) {
                                    return (
                                        <Fragment>
                                            <Form.Item
                                                name={['billing_data', 'business_name']}
                                                label="Razón Social"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Se necesita una razón social',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Ingrese la razón social" />
                                            </Form.Item>
                                            <Form.Item
                                                name={['billing_data', 'document']}
                                                label="DNI/Rut/Documento"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Se necesita una razón social',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Ingrese el documento de la empresa" />
                                            </Form.Item>
                                            <Form.Item
                                                name={['billing_data', 'activities']}
                                                label="Giro"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Se necesita una razón social',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Seleccione el giro"
                                                    loading={
                                                        activities.isLoading ||
                                                        activities.isFetching
                                                    }
                                                >
                                                    {Array.from(activities?.data ?? []).map(
                                                        (activity, index) => (
                                                            <Select.Option
                                                                key={index}
                                                                value={activity.description}
                                                            >
                                                                {activity.description}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                name={['billing_data', 'branch_office_sii']}
                                                label="Sucursal SII"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Se necesita una razón social',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Ingrese la sucursal de SII" />
                                            </Form.Item>
                                            <Form.Item
                                                name={['billing_data', 'ticket_footer']}
                                                label="Pie de boleta"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Se necesita un pie de boleta',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Ingrese el pie de boleta" />
                                            </Form.Item>

                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={['billing_data', 'address']}
                                                        label="Direccion de facturación"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Se necesita una direccion',
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Ingrese la sucursal de SII" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={32}>
                                                {activeDirectionColombia ? (
                                                    <LocationByCountryForm
                                                        form={form}
                                                        valueCountry={dataGenerals?.country}
                                                        nameProvince={['billing_data', 'province']}
                                                        nameCity={['billing_data', 'city']}
                                                        nameNeighborhood={[
                                                            'billing_data',
                                                            'neighborhood',
                                                        ]}
                                                    />
                                                ) : (
                                                    <>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                name={['billing_data', 'province']}
                                                                label="Región"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            'Se necesita una región',
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    placeholder={
                                                                        'Seleccione región'
                                                                    }
                                                                    onChange={() => {
                                                                        form.setFieldsValue({
                                                                            billing_data: {
                                                                                city: undefined,
                                                                            },
                                                                        });
                                                                    }}
                                                                >
                                                                    {regionesYComunas.map(
                                                                        (r, i) => (
                                                                            <Select.Option
                                                                                key={i}
                                                                                value={
                                                                                    r.NombreRegion
                                                                                }
                                                                            >
                                                                                {r.NombreRegion}
                                                                            </Select.Option>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                noStyle
                                                                shouldUpdate={(
                                                                    prevValues,
                                                                    currentValues
                                                                ) =>
                                                                    prevValues['billing_data']
                                                                        ?.province !==
                                                                    currentValues['billing_data']
                                                                        ?.province
                                                                }
                                                            >
                                                                {({ getFieldValue }) => {
                                                                    const provinceSelected =
                                                                        getFieldValue([
                                                                            'billing_data',
                                                                            'province',
                                                                        ]);
                                                                    return (
                                                                        <Form.Item
                                                                            name={[
                                                                                'billing_data',
                                                                                'city',
                                                                            ]}
                                                                            label="Comuna"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message:
                                                                                        'Se necesita una comuna',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                placeholder={
                                                                                    'Seleccione comuna'
                                                                                }
                                                                                disabled={
                                                                                    !provinceSelected
                                                                                }
                                                                            >
                                                                                {regionesYComunas
                                                                                    .filter(
                                                                                        (c) =>
                                                                                            c.NombreRegion ===
                                                                                                provinceSelected &&
                                                                                            c?.comunas
                                                                                    )[0]
                                                                                    ?.comunas?.sort(
                                                                                        (a, b) =>
                                                                                            a - b
                                                                                    )
                                                                                    .map((r, i) => (
                                                                                        <Select.Option
                                                                                            key={i}
                                                                                            value={
                                                                                                r
                                                                                            }
                                                                                        >
                                                                                            {r}
                                                                                        </Select.Option>
                                                                                    ))}
                                                                            </Select>
                                                                        </Form.Item>
                                                                    );
                                                                }}
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                )}
                                            </Row>

                                            <Form.Item
                                                name={['billing_data', 'taxable_shipping']}
                                                valuePropName="checked"
                                            >
                                                <CheckboxStyled
                                                    ref={taxableShippingRef}
                                                    disabled={disabledTaxShipping}
                                                >
                                                    Envío tributario
                                                </CheckboxStyled>
                                            </Form.Item>

                                            {initialValues?.billing_data?.logo_url && (
                                                <Form.Item>
                                                    <img
                                                        src={initialValues?.billing_data?.logo_url}
                                                        alt="Logo actual"
                                                        style={{
                                                            maxWidth: '160px',
                                                            maxHeight: '80px',
                                                        }}
                                                    />
                                                </Form.Item>
                                            )}

                                            <Form.Item style={{ marginTop: '24px' }}>
                                                <>
                                                    <Upload
                                                        fileList={fileList}
                                                        setFileList={setFileList}
                                                        setLoadingLogo={setLoadingLogo}
                                                    >
                                                        {/* <UploadButton type="button">Subir logo</UploadButton> */}
                                                        <Button
                                                            variation="secondary"
                                                            iconPosition="right"
                                                            icon={
                                                                <AiOutlineUpload
                                                                    style={{
                                                                        width: '24px',
                                                                        height: '24px',
                                                                    }}
                                                                />
                                                            }
                                                        >
                                                            <b>Subir logo</b>
                                                        </Button>
                                                    </Upload>
                                                    <span
                                                        className="text-xs"
                                                        style={{ color: '#536D8F' }}
                                                    >
                                                        Formato: PNG/JPG/JPEG Tamaño: 160px ancho
                                                        80px altura
                                                    </span>
                                                </>
                                            </Form.Item>

                                            <Form.Item
                                                style={{ marginTop: '24px' }}
                                                name={['billing_data', 'tax']}
                                                label={
                                                    <span style={{ fontWeight: 500 }}>
                                                        Ingresar IVA asociado
                                                    </span>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Se necesita un tax',
                                                    },
                                                ]}
                                            >
                                                <InputNumber
                                                    min={0}
                                                    max={99}
                                                    addonAfter={
                                                        <span
                                                            style={{
                                                                color: '#2D3D76',
                                                                fontWeight: 500,
                                                            }}
                                                        >
                                                            %
                                                        </span>
                                                    }
                                                    maxLength={2}
                                                    style={{
                                                        width: '100px',
                                                        border: '1px #7998A7',
                                                    }}
                                                    placeholder="19"
                                                />
                                            </Form.Item>
                                        </Fragment>
                                    );
                                }
                            }}
                        </Form.Item>

                        <Divider />

                        <ClassificationProductTypeForm form={form} />
                    </div>
                </div>
                <FormDivider />
                <Flex direction="row" gap="10px" justifyContent="flex-end">
                    <Button onClick={handleClose} variation="secondary">
                        Cancelar
                    </Button>
                    <Button htmlType="submit" disabled={loadingLogo}>
                        {isCreateForm ? 'Crear canal' : 'Guardar cambios'}
                    </Button>
                </Flex>
            </FormStyled>
        </div>
    );
};

export default SalesChannelsForm;
