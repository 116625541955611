import { labelsForAllModules } from './constants/labelsForAllModules';

export const getFieldLabel = (preferences, field) => {
    const fieldSelected = labelsForAllModules?.[preferences]?.[field]?.fields?.find(
        (fieldValue) => fieldValue.value === field
    );

    return fieldSelected?.label || field;
};

export const getConditionLabel = (condition) => {
    const conditionSelected = labelsForAllModules.conditions.find(
        (conditionValue) => conditionValue.value === condition
    );
    return conditionSelected?.label?.toLowerCase() || '';
};

export const getValueLabel = (preferences, field, condition, value, valueOptionByField) => {
    if (labelsForAllModules[preferences][field]) {
        labelsForAllModules[preferences][field].values = valueOptionByField[field];
    }

    if (condition === 'is' || condition === 'is_not') {
        const labelValue = labelsForAllModules?.[preferences]?.[field]?.values?.find(
            (option) => option.value === value
        );
        return labelValue?.label ?? value;
    }

    // Para caso incluye o no incluye con multiselect
    if (Array.isArray(value)) {
        return value
            .map((value) => {
                const labelValue = labelsForAllModules?.[preferences]?.[field]?.values?.find(
                    (option) => option.value === value
                );
                return labelValue?.label ?? value;
            })
            .join(', ');
    }

    return value;
};
