import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const courierAddressMappingsApi = createApi({
    reducerPath: 'courierAddressMappings',
    baseQuery,
    tagTypes: ['courierAddressMappings'],
    endpoints: (builder) => ({
        getCourierAddressMappings: builder.query({
            method: 'get',
            query: () => `/helpers/getAddressMappings`,
            providesTags: ['courierAddressMappings'],
        }),
    }),
});

export const { useGetCourierAddressMappingsQuery } = courierAddressMappingsApi;
