import { Menu } from 'antd';
import { CSVLink } from 'react-csv';
import ActionsButton from 'components/Buttons/ActionsButton';
import dayjs from 'dayjs';
import Modal from 'components/Modal';
import { useMakeResolveErrorMutation } from 'redux/features/Errors';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal, openModal } from 'redux/features/Utils';

const Actions = ({ className, selectedRows = [], setSelectedRows, data = [] }) => {
    const [makeResolve, makeResolveState] = useMakeResolveErrorMutation();

    const dispatch = useDispatch();

    const [selectedRowStatus, setSelectedRowStatus] = useState('');

    const handleMakeResolve = async (selectedRows) => {
        await makeResolve({ _id: selectedRows });
        setSelectedRows([]);
        dispatch(closeModal('toggle_make_resolve_rows_confirmation'));
    };

    const handleOpenModal = () => {
        dispatch(openModal('toggle_make_resolve_rows_confirmation'));
    };

    const prepareDataForExport = (row) => {
        setSelectedRowStatus(row?.status);
        return {
            'ID Objetivo': row?.extra_data?.order_name ?? '',
            Descripcion: row?.description,
            'Fecha del error': dayjs(row?.createdAt).format('DD/MM/YYYY HH:mm'),
            'Fecha ultimo intento':
                row?.updatedAt === row?.createdAt
                    ? '-'
                    : dayjs(row?.updatedAt).format('DD/MM/YYYY HH:mm'),
            Estado: row?.status,
            Integración: row?.integration_name,
            Intentos: row?.attempts,
            'Error JSON': JSON.stringify(row?.log?.other?.error_object),
        };
    };

    const exportedData = useMemo(() => {
        const csvDataFiltered = data
            .filter((row) => selectedRows.includes(row._id))
            .map(prepareDataForExport);
        const headers = csvDataFiltered.length > 0 ? Object.keys(csvDataFiltered[0]) : [];
        const csvData = [
            headers,
            ...csvDataFiltered.map((item) => headers.map((key) => item[key])),
        ];
        return csvData;
    }, [selectedRows, data]);

    const menu = (
        <Menu>
            <Menu.Item disabled={makeResolveState.isLoading || selectedRows.length === 0}>
                <CSVLink data={exportedData} filename={`ERRORS.csv`}>
                    {selectedRows?.length === 1
                        ? `Exportar CSV (${selectedRows.length})`
                        : `Exportar CSV (${selectedRows.length})`}
                </CSVLink>
            </Menu.Item>

            {selectedRowStatus !== 'resolved' ? (
                <Menu.Item
                    onClick={handleOpenModal}
                    disabled={
                        makeResolveState.isLoading ||
                        selectedRows.length === 0 ||
                        selectedRowStatus === 'resolved'
                    }
                >
                    {selectedRows?.length === 1
                        ? `Marcar como solucionado (${selectedRows.length})`
                        : `Marcar como solucionados (${selectedRows.length})`}
                </Menu.Item>
            ) : null}
        </Menu>
    );

    const modalResolveTitle = `Marcar como solucionados`;
    const modalResolveTitleIfOne = `Marcar como solucionado`;

    return (
        <>
            <ActionsButton
                dropdownProps={{ className, overlay: menu, disabled: selectedRows.length === 0 }}
                buttonText={`Acciones`}
            />

            <Modal
                title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{selectedRows.length > 1 ? modalResolveTitle : modalResolveTitleIfOne}</span>
                    </div>
                }
                modalName={'toggle_make_resolve_rows_confirmation'}
                maskClosable={true}
                closable={true}
                cancelText="Cancelar"
                okText="Continuar"
                onOk={() => handleMakeResolve(selectedRows)}
            >
                {selectedRows.length > 1 ? (
                    <p>
                        {' '}
                        Va a marcar (<strong>{selectedRows.length}</strong>) errores como <strong>solucionados</strong>.
                    </p>
                ) : (
                    <p>
                        {' '}
                        Va a marcar (<strong>{selectedRows.length}</strong>) error como <strong>solucionado</strong>.
                    </p>
                )}{' '}
                <p style={{ fontWeight: 'bold', paddingTop: '30px' }}>¿Desea continuar?</p>
            </Modal>
        </>
    );
};

export default Actions;
