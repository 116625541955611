import { createSlice } from '@reduxjs/toolkit';
import { preparationsFilters as prepFilters } from 'helpers/constants/filters/preparations';

const preparationsFilters = createSlice({
    name: 'preparationsFilters',
    initialState: {
        filters: [],
        valueOptionByField: {
            status: [...prepFilters.values.status],
            delivery_method: [...prepFilters.values.delivery_method],
        },
    },
    reducers: {
        addFilter: (state, { payload }) => {
            state.filters = [...state.filters, payload];
        },
        removeFilter: (state, { payload }) => {
            state.filters = state.filters?.filter((el) => el?.id !== payload);
            if (state.filters.length > 0) {
                state.filters[0].andOr = '';
            }
        },
        updateFilter: (state, { payload }) => {
            const indexToUpdate = state.filters.findIndex((filter) => filter.id === payload.id);
            state.filters[indexToUpdate] = payload;
        },
        overrideFilters: (state, { payload }) => {
            state.filters = payload;
        },
        setValueOptionByField: (state, { payload }) => {
            state.valueOptionByField = {
                ...state.valueOptionByField,
                ...payload,
            };
        },
        clearFilters: (state) => {
            state.filters = [];
        },
    },
});

export const {
    addFilter,
    removeFilter,
    updateFilter,
    overrideFilters,
    setValueOptionByField,
    clearFilters,
} = preparationsFilters.actions;
export default preparationsFilters.reducer;
