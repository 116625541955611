import { useEffect, useState } from 'react';
import { changeAllOF } from 'redux/features/Orders/filtersOrders';
import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';
import { useDispatch, useSelector } from 'react-redux';

const useGetStartOrdersByPermissions = () => {
    const dispatch = useDispatch();
    const [permissions] = useGetCurrentPermissions();
    const { fulfillment_status } = useSelector((state) => state.filtersOrders);
    const [changed, setChanged] = useState(false);
    useEffect(() => {
        if (permissions[0]) {
            if (permissions.includes('sac')) {
                dispatch(
                    changeAllOF({
                        fulfillment_status: [...fulfillment_status, 'sac'],
                    })
                );
            }
            if (fulfillment_status) {
                setChanged(true);
            }
        }
    }, [permissions]);

    return [changed];
};

export default useGetStartOrdersByPermissions;
