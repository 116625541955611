import styled, { css } from 'styled-components';
const Tag = styled.span`
    background: #f5f5f5;
    border: 1px solid #41515e;
    border-radius: 10px;
    padding: 4px 5px;
    color: #41515e;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;

    line-height: 14px;

    ${({ type }) => {
        if (type === 'warning') {
            return css`
                background: #f9e107;
                border: 1px solid #e3cd06;
                font-weight: 600;
            `;
        }
        if (type === 'closed') {
            return css`
                background: #1d71b5;
                border: 1px solid #2952bf;
                font-weight: 600;
                color: #fff !important;
            `;
        }
        if (type === 'success') {
            return css`
                background: #06e306;
                border: 1px solid #06e306;
                font-weight: 600;
            `;
        }
        if (type === 'preparation_tags') {
            return css`
                background: none;
                border: 1px solid #2d3d76;
                font-weight: 600;
                color: #2d3d76 !important;
            `;
        }
    }};
`;

const PageTagWithLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 6px;
    font-size: 12px;

    span {
        color: #41515e;
        font-weight: 500;
        margin-right: 4px;
    }

    &:last-child {
        margin-right: 0;
    }
`;

const PageTag = ({ children, type, label }) => {
    return (
        <PageTagWithLabel>
            {label && <span>{label}</span>}
            <Tag type={type}>{children}</Tag>
        </PageTagWithLabel>
    );
};

export default PageTag;
