import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getAllProfiles = createAsyncThunk('profiles/getAll', async (_, state) => {
    try {
        const res = await AndesClient.get(`/profiles`);
        return res.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getAllProfilesSlice = createSlice({
    name: 'getProfiles',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {
        addProfileToStore: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                data: [...state.data, payload],
            };
        },
        deleteProfileFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.filter(({ _id }) => _id !== payload);
            return {
                ...state,
                data: [...newArrayData],
            };
        },
        updateProfileFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.map((profile) => {
                if (profile._id === payload._id) {
                    return {
                        ...profile,
                        ...payload,
                    };
                } else {
                    return profile;
                }
            });
            return {
                ...state,
                data: [...newArrayData],
            };
        },
    },
    extraReducers: {
        [getAllProfiles.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getAllProfiles.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: [...action.payload],
                success: true,
            };
        },
        [getAllProfiles.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});
export const { addProfileToStore, deleteProfileFromStore, updateProfileFromStore } =
    getAllProfilesSlice.actions;
export default getAllProfilesSlice.reducer;
