import { Fragment, useEffect, useMemo, useState } from 'react';
import Flex from 'components/Utils/Flex';
import AntdTable from 'components/AntdTable';
import { TableCard } from 'components/AntdTable/styles';
import NormalButton from 'components/Buttons/NormalButton';
import Tag from 'components/Tag';
import { useDispatch } from 'react-redux';
import { updatePackage } from 'redux/features/Packages/updatePackage';
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import FormModal from 'components/Modal/FormModal';
import OpenPackageForm from './OpenPackageForm';
import ToggleModalButton from 'components/Modal/ToggleModalButton';
import PackageProductsTable from 'components/PackageProductsTable';
import { PackageDetailsItem, PackageDetailsRow, PackageDetailsTitle } from './styles';
import Actions from './Actions';
import { ReactComponent as VectorBultos } from 'assets/vector-bultos.svg';
import { Button, Popconfirm, Tooltip } from 'antd';
import { ReactComponent as TrashIcon } from '../../../../assets/trash-icon.svg';
import { deletePackage } from '../../../../redux/features/Packages/deletePackage';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { ReactComponent as NewPackage } from 'assets/new-package.svg';

const PackageDetails = ({
    fulfillment = {},
    isTotallyPicking,
    blockPreparation,
    isOpenBtnDisabled,
    setIsOpenBtnDisabled,
}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isExpanded, setIsExpanded] = useState(true);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [openedPackages, setOpenedPackages] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        const findOpenPackage = fulfillment?.packages.find((pk) => pk.status === 'open') || {};
        setOpenedPackages(findOpenPackage);

        if (findOpenPackage) {
            if (findOpenPackage?._id) {
                setIsOpenBtnDisabled(true);
                setExpandedRowKeys([findOpenPackage._id]);
            } else {
                setIsOpenBtnDisabled(false);
            }
        } else {
            setIsOpenBtnDisabled(false);
        }

        if (!fulfillment?.packages[0]) {
            setIsOpenBtnDisabled(false);
        }

        return () => {
            setIsOpenBtnDisabled(false);
            setOpenedPackages({});
        };
    }, [fulfillment]);

    const handleClosePackages = () => {
        if (openedPackages?._id) {
            dispatch(
                updatePackage({
                    _id: openedPackages._id,
                    order_id: fulfillment.order._id,
                    fulfillment_id: fulfillment._id,
                    status: 'pending_for_processing',
                })
            );
        }
    };

    const renderPackageStatus = (status) => {
        if (status === 'open') {
            return <Tag color="success">Abierto</Tag>;
        }
        if (status === 'in_progress') {
            return <Tag color="warning">En curso</Tag>;
        }
        if (status === 'closed' || status === 'pending_for_processing') {
            return <Tag color="danger">Cerrado</Tag>;
        }
        return <Tag>{status}</Tag>;
    };

    const renderItemsInPackage = (line_items) => {
        const itemsInPackage = line_items.reduce((acc, ele) => {
            acc += ele.quantity;
            return acc;
        }, 0);
        return itemsInPackage;
    };

    const tableData = useMemo(() => [...Array.from(fulfillment.packages)], [fulfillment]);

    console.log({
        blockPreparation,
        isTotallyPicking,
        isOpenBtnDisabled,
        status: fulfillment.status,
    });

    const tableColumns = useMemo(
        () => [
            {
                title: () => {
                    return (
                        <div className="grid grid-cols-2">
                            <Actions selectedPackages={selectedRows} fulfillment={fulfillment} />

                            <Flex direction="row" columnGap="10px" justifyContent="end">
                                <ToggleModalButton
                                    modalName="new_package_modal"
                                    buttonText="Crear bulto"
                                    type="primary"
                                    disabled={
                                        blockPreparation ||
                                        isTotallyPicking ||
                                        isOpenBtnDisabled ||
                                        [
                                            'paused',
                                            'success',
                                            'cancelled',
                                            'delivered',
                                            'delivered_to_courier',
                                        ].includes(fulfillment.status)
                                    }
                                />
                                <NormalButton
                                    type="primary"
                                    ghost
                                    buttonText="Cerrar bulto abierto"
                                    disabled={!isOpenBtnDisabled}
                                    onClick={() => {
                                        handleClosePackages();
                                    }}
                                />
                            </Flex>
                        </div>
                    );
                },
                render: (_, row) => {
                    return (
                        <>
                            <Flex direction="column">
                                <PackageDetailsRow>
                                    <Flex flex="1" direction="column" rowGap="0.5rem">
                                        <PackageDetailsItem>
                                            <PackageDetailsTitle bold width="100px">
                                                Estado Packing
                                            </PackageDetailsTitle>
                                            {row.status === 'open' && row.line_items.length > 0
                                                ? renderPackageStatus('in_progress')
                                                : renderPackageStatus(row.status)}
                                        </PackageDetailsItem>
                                        <PackageDetailsItem>
                                            <PackageDetailsTitle width="100px">
                                                <div className="flex gap-2">
                                                    <span>Productos</span>
                                                    {renderItemsInPackage(row.line_items)}
                                                </div>
                                            </PackageDetailsTitle>
                                        </PackageDetailsItem>
                                    </Flex>
                                    <Flex flex="1" direction="column" rowGap="0.5rem">
                                        <PackageDetailsItem>
                                            <PackageDetailsTitle width="100px">
                                                Código Bulto
                                            </PackageDetailsTitle>
                                            <Tag borderColor="#2D3D76">{row.code}</Tag>
                                        </PackageDetailsItem>
                                        <PackageDetailsItem>
                                            <PackageDetailsTitle width="100px">
                                                Tipo Bulto
                                            </PackageDetailsTitle>
                                            {row?.type?.name ?? row?.type ?? '-'}
                                        </PackageDetailsItem>
                                    </Flex>

                                    <Popconfirm
                                        placement="topRight"
                                        title="Confirmar eliminación de bulto"
                                        icon={
                                            <Tooltip
                                                title="Al confirmar, este bulto se eliminará"
                                                placement="top"
                                            >
                                                <QuestionCircleOutlined
                                                    style={{ color: '#e74c3c' }}
                                                />
                                            </Tooltip>
                                        }
                                        okText="Confirmar"
                                        cancelText="Cancelar"
                                        okButtonProps={{
                                            danger: true,
                                        }}
                                        onConfirm={() =>
                                            dispatch(
                                                deletePackage({
                                                    package_id: row._id,
                                                    fulfillment_id: row.fulfillment_id,
                                                })
                                            )
                                        }
                                    >
                                        <Button
                                            icon={<TrashIcon />}
                                            type="text"
                                            size="small"
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </Popconfirm>
                                </PackageDetailsRow>
                                <Flex
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    padding="10px"
                                >
                                    <div style={{ cursor: 'pointer' }}>
                                        <Tag
                                            style={{
                                                color: '#2D3D76',
                                                border: '1.5px solid #1F6373',
                                            }}
                                        >
                                            {isExpanded ? 'Ver menos' : 'Ver más'}
                                            <ChevronDown
                                                style={
                                                    isExpanded
                                                        ? {
                                                              transform: 'rotate(180deg)',
                                                              transition: 'ease-in .2s',
                                                          }
                                                        : {
                                                              transform: 'rotate(0deg)',
                                                              transition: 'ease-in .2s',
                                                          }
                                                }
                                            />
                                        </Tag>
                                    </div>
                                </Flex>
                            </Flex>
                        </>
                    );
                },
            },
        ],
        [
            fulfillment,
            selectedRows,
            isExpanded,
            isOpenBtnDisabled,
            isTotallyPicking,
            blockPreparation,
        ]
    );

    const onTableRowExpand = (expanded, record) => {
        setIsExpanded(expanded);
        if (expanded) {
            setExpandedRowKeys([...expandedRowKeys, record._id]);
        } else {
            setExpandedRowKeys(expandedRowKeys.filter((key) => key !== record._id));
        }
    };

    let locale = {
        emptyText: (
            <div className="mx-10">
                <span className="text-left" style={{ color: '#41515E' }}>
                    <h1 className="mt-3" style={{ color: '#2D3D76' }}>
                        No hay bultos creados
                    </h1>
                    <br />
                    <p className="mr-5 text-xs">
                        1. Debe crear un bulto con el botón “Crear bulto”.
                    </p>
                    <p className="text-xs">
                        2. A continuación seleccionar desde la columna derecha, los códigos que
                        quiere asociar al bulto.
                    </p>
                    <p className="text-xs">
                        3. Puede cerrar un bulto una vez que ya tenga productos asociados a un
                        bulto.
                    </p>
                    <p className="mb-14 text-xs">
                        4. Una vez que asocie todos los códigos, podrá enviar los bultos a entregas
                        en el botón superior derecho “Enviar a entregas”.
                    </p>
                </span>
            </div>
        ),
    };

    const title = (
        <span className="flex gap-2">
            <VectorBultos />
            <div>{`Cantidad de bultos (${fulfillment.packages.length})`}</div>
        </span>
    );

    const titleModal = (
        <span className="flex gap-2">
            <NewPackage />
            <div style={{ color: '#2D3D76' }}>{`Crear bulto`}</div>
        </span>
    );
    return (
        <div>
            <FormModal
                formComponent={
                    <OpenPackageForm order={fulfillment.order} fulfillment={fulfillment} />
                }
                formProps={{
                    formName: 'new_package_form',
                    formAction: 'new',
                    initialValues: {},
                }}
                modalProps={{
                    modalName: 'new_package_modal',
                    title: titleModal,
                    width: 600,
                    okText: 'Crear',
                    okButtonProps: {
                        type: 'primary',
                    },
                    cancelButtonProps: {
                        type: 'primary',
                        ghost: true,
                    },
                    destroyOnClose: true,
                }}
            />
            <TableCard
                bodyStyle={{ paddingRight: '15px' }}
                headStyle={{ paddingLeft: '15px' }}
                className="preparation__package-details"
                title={title}
                borderColor="#7998A7"
            >
                <AntdTable
                    rowKey="_id"
                    style={{ paddingLeft: '10px' }}
                    removeHeaderBackground
                    disableRowColor
                    locale={locale}
                    columns={tableColumns}
                    dataSource={tableData}
                    expandedRowKeys={expandedRowKeys}
                    onExpand={onTableRowExpand}
                    expandable={{
                        expandIcon: () => null,
                        expandRowByClick: true,
                        expandedRowRender: (row) => {
                            return (
                                <>
                                    <Fragment>
                                        {row.line_items.length === 0 && (
                                            <p className="ml-1 mb-1 block">Bulto sin productos</p>
                                        )}
                                        {row.line_items.length > 0 && (
                                            <PackageProductsTable pkg={row} />
                                        )}
                                    </Fragment>
                                </>
                            );
                        },
                        rowExpandable: (row) => row._id !== 'Not Expandable',
                        expandIconColumnIndex: -1,
                    }}
                    rowSelection={{
                        selectedRowKeys: selectedRows,
                        onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys),
                    }}
                />
            </TableCard>
        </div>
    );
};

export default PackageDetails;
