import { CSVLink } from 'react-csv';
import { useState } from 'react';
import toast from 'react-hot-toast';

const DownloadOrderCSV = ({ orderData, isAdmin }) => {
    const [csvData, setCsvData] = useState([]);

    const generateCSV = (event, done) => {
        console.log({ event, done });
        // para nota de crédito
        let creditNote = {};
        orderData.logs.forEach((item) => {
            // DocNum --> es numero de NC
            // DocRef --> es Doc referencia: Boleta
            if (item.action === 'DTE_CREDIT_NOTE_CREATED') {
                const { DocNum, DocRef, order_id } = item.other;

                const nc = { DocNum, DocRef, order_id };
                creditNote = nc;
            } else if (item.action === 'DTE_CREDIT_NOTE_CREATED_FAILED') {
                const {
                    order_id,
                    error_object: {
                        response_data: { status, descripcion },
                    },
                } = item.other;

                const nc = { status, descripcion, order_id };
                creditNote = nc;
            }
        });

        const remoteOrderId = orderData.remote_order_id;
        const orderId = creditNote.order_id;
        const numCreditNote = creditNote.DocNum;
        const numBoletaAsociada = creditNote.DocRef;
        const failStatus = creditNote.status;
        const failDescription = creditNote.descripcion;

        const obsTextCreditNoteSuccess = `Orden asociada a orden cancelada con remote_order_id: ${remoteOrderId} y order_id: ${orderId}. Nota de crédito: ${numCreditNote}, asociada a boleta: ${numBoletaAsociada}.`;

        const obsTextCreditNoteFailed = `Creación de nota de crédito fallida con status: ${failStatus} y descripción: ${failDescription}, para order_id: ${orderId}.`;

        const obsText = creditNote.hasOwnProperty('DocNum')
            ? obsTextCreditNoteSuccess
            : obsTextCreditNoteFailed;

        // para operador logístico
        const idOpLogisticoIndex = orderData.assigned_courier.metafields.findIndex((item) => {
            return item.key === 'carrier_no';
        });
        const idOpLogistico = orderData.assigned_courier.metafields[idOpLogisticoIndex]?.value;
        const opLogTipoServicio = orderData?.shipping_service?.name ?? orderData?.shipping_service;

        // para fecha pedido
        const originalDate = orderData.original_order.updatedAt.split('T')[0].split('-').reverse();
        originalDate[2] = originalDate[2].slice(-2);
        const fechaPedido = originalDate.join('-');

        const pos_store_no =
            orderData?.location_id?.metafields?.find((meta) => meta.key === 'pos_store_no')
                ?.value ?? '';

        if (!pos_store_no) {
            const errorText =
                'No existe el metafield equivalente (pos_store_no en la tienda) para carga de orden posterior, a través de módulo de carga CSV.';
            done(false);
            return toast.error(errorText);
        }

        let parseDeliveryMethod = orderData?.original_order?.delivery_method ?? '';

        if (parseDeliveryMethod === 'shipping') {
            parseDeliveryMethod = 'despacho';
        }

        if (parseDeliveryMethod === 'pickup') {
            parseDeliveryMethod = 'retiro';
        }

        let orderNum = orderData.remote_order_id;

        if (orderNum.includes('-R')) {
            const textSplitted = orderNum.split('-R');

            let textRepNum;
            if (textSplitted[1]) {
                textRepNum = parseInt(textSplitted[1], 10) + 1;
            } else {
                textRepNum = 1;
            }

            orderNum = `${textSplitted[0]}-R${textRepNum}`;
        } else {
            orderNum += '-R1';
        }

        // crea csv
        const newCsv = Array.from(orderData?.original_order?.line_items ?? []).map((line_item) => {
            const tempItem = {
                NUMERO_PEDIDO_ORIGINAL: orderNum,
                FECHA_PEDIDO: fechaPedido,
                DNI_ENVIO: orderData?.original_order?.customer?.document ?? '',
                NOMBRE_ENVIO: orderData?.original_order?.customer?.first_name ?? '',
                APELLIDO_1_ENVIO: orderData?.original_order?.customer?.last_name ?? '',
                DOMICILIO_ENVIO: orderData?.original_order?.shipping_address?.address1 ?? '',
                POBLACION_ENVIO: orderData?.original_order?.shipping_address?.city ?? '',
                PROVINCIA_ENVIO: orderData?.original_order?.shipping_address?.province ?? '',
                CODIGO_POSTAL_ENVIO:
                    orderData?.original_order?.shipping_address?.province_code ?? '',
                TELEFONO_MOVIL_ENVIO: orderData?.original_order?.customer.phone ?? '',
                EMAIL_ENVIO: orderData?.original_order?.customer?.email ?? '',
                GASTOS_ENVIO: orderData?.original_order?.total_shipping ?? '',
                OBSERVACIONES: obsText ?? '',
                TALLA: '',
                IMPORTE: line_item?.unit_price ?? '',
                CANTIDAD: line_item?.quantity ?? '',
                SKU: line_item?.sku ?? '',
                EAN: line_item?.ean ?? '',
                ID_TIENDA: pos_store_no ?? '',
                METODO_ENTREGA: parseDeliveryMethod,
                ID_OP_LOGISTICO: idOpLogistico ?? '',
                OP_LOG_TIPO_SERVICIO: opLogTipoServicio ?? '',
                FECHA_COMPROMISO: orderData?.original_order?.promised_date ?? '',
            };
            return tempItem;
        });

        setCsvData(newCsv ?? []);
        done(true);
    };

    return (
        <CSVLink
            data={csvData}
            filename={`order-${orderData?._id ?? ''}.csv`}
            // className="w-full my-2 flex justify-center"
            target="_blank"
            asyncOnClick={true}
            onClick={generateCSV}
            disabled={!isAdmin}
        >
            Exportar pedido CSV
        </CSVLink>
    );
};

export default DownloadOrderCSV;
