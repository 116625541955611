import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateUserFromStore } from './getAllUsers';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getPreparersByCustom } from './getPreparersByCustom';

export const editUser = createAsyncThunk('user/edit', async (data, state) => {
    try {
        const { ...otherValues } = data;
        const res = await toast.promise(AndesClient.put(`/users/update`, otherValues), {
            loading: 'Guardando cambios...',
            success: 'Usuario actualizado',
        })

        if (res.status === 200) {
            state.dispatch(updateUserFromStore(data));
            state.dispatch(getPreparersByCustom());
        }
        return res.data;
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const editUserSlice = createSlice({
    name: 'editUser',
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    reducers: {},
    extraReducers: {
        [editUser.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [editUser.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [editUser.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default editUserSlice.reducer;
