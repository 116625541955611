import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import AndesClient from 'services/AndesClient';
import { toggleDrawer } from '../Utils';
import { getOrderById } from './getOrderById';

export const removeShippingPrice = createAsyncThunk(
    'orders/removeShippingPrice',
    async (data, state) => {
        try {
            const promise = AndesClient.put(`/orders/removeShippingPrice`, {
                ...data,
            });
            const states = state.getState();

            toast.promise(promise, {
                loading: <b>Actualizando costo de envío...</b>,
                success: <b>Costo de envío actualizado con éxito.</b>,
                error: <b>Ocurrió un error al actualizar el costo de envío.</b>,
            });

            const result = await promise;

            if (result.status === 200) {
                state.dispatch(getOrderById(data.order_id));
                // Cerramos el drawer de preparacion global
                if (states.utils?.drawer['global-actions-order-drawer']) {
                    state.dispatch(toggleDrawer('global-actions-order-drawer'));
                }
            }

            return state.fulfillWithValue(true);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const removeShippingPriceSlice = createSlice({
    name: 'removeShippingPrice',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [removeShippingPrice.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [removeShippingPrice.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [removeShippingPrice.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default removeShippingPriceSlice.reducer;
