import { useState, useEffect, useMemo } from 'react';
import { Divider, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetUserPreferencesQuery } from 'redux/features/Users';
import {
    useLazyGetSuccessFulfillmentsAllIdsQuery,
    useLazyGetSuccessFulfillmentsQuery,
    useValidationDeliveryFulfillmentsMutation,
} from 'redux/features/Fulfillments';
import {
    removeFilter,
    clearFilters,
    overrideFilters,
    setValueOptionByField,
} from 'redux/features/Deliveries/deliveriesFilters';
import { setDeliveriesFilters } from 'redux/features/Utils';
import useCurrentUser from 'hooks/useCurrentUser';
import useAllCouriers from 'hooks/useAllCouriers';
import useAllSalesChannel from 'hooks/useAllSalesChannel';
import useAllShops from 'hooks/useAllShops';
import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Flex from 'components/Utils/Flex';
import CollapsibleContainer from 'components/V2/CollapsibleContainer';
import LocationPicker from 'components/V2/LocationPicker';
import { getDateFilterToSend } from 'components/V2/DatePicker/utils';
import DeliveriesMetrics from 'components/V2/Metrics/DeliveriesMetrics';
import ViewTabs from 'components/V2/ViewTabs';
import DeliveriesPackageScanInput from 'components/V2/SearchInput/DeliveriesScanInput';
import SearchInput from 'components/V2/SearchInput';
import FilterButton from 'components/V2/Buttons/FilterButton';
import ColumnsButton from 'components/V2/Buttons/ColumnsButton';
import ShowQuantityItems from 'components/V2/ShowQuantityItems';
import InfoFilter from 'components/V2/Filters/InfoFilter';
import { removeAccents } from 'helpers/removeAccents';
import DraggableTable from './Table/DraggableTable';
import DeliveriesActions from './Actions';
import { defaultColumnsView, INITIAL_PAGE_SIZE } from './config';
import store from '../../redux';
import ScannedPackageStatus from './components/ScannedPackageStatus';
import PreparatorButton from './components/PreparatorButton';
import useGetPickupPreference from 'hooks/useGetPickupPreference';
import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import { DrawerPreProcess } from './Actions/DrawerPreProcess';
import toast from 'react-hot-toast';
import { promisedDateRanges } from 'data/promised_date_ranges';
import { RangePicker } from 'components/RangePicker';
import { useLocation } from 'react-router-dom';
import DeliveryConfigModal from './dialogs/DeliveryConfigModal';
import { deliveriesRegionOptions } from 'helpers/constants/filters/deliveries/values';

const DeliveriesV2 = () => {
    const PREFERENCES = 'deliveries';
    const [expandedKeys, setExpandedKeys] = useState([]);
    // const [disabledProcess, setDisabledProcess] = useState(false);

    // Redux queries
    const {
        data: userPreferences,
        isLoading: userLoading,
        isFetching: userFetching,
    } = useGetUserPreferencesQuery();
    const [getDeliveries, { data, isFetching, isLoading }] = useLazyGetSuccessFulfillmentsQuery();
    const [getDeliveriesAllIds, { data: allIds }] = useLazyGetSuccessFulfillmentsAllIdsQuery();
    const [validationDeliveryFulfillments] = useValidationDeliveryFulfillmentsMutation();

    const params = useLocation();
    // Redux state and get data from local storage
    const { filters, valueOptionByField } = useSelector((state) => state.deliveriesFilters);
    const [massiveFulfillmentDelivery, setMassiveFulfillmentDelivery] = useState([]);
    const [fulfillmentToDelivery, setFulfillmentToDelivery] = useState([]);
    const { userData } = useCurrentUser();
    const [salesChannelsLoading, salesChannels] = useAllSalesChannel();
    const [locationsLoading, locations] = useAllShops();
    const [couriersLoading, couriers] = useAllCouriers();
    const [, dataPickupsPreferences] = useGetPickupPreference();
    const [, , dataPreparations] = useGetPreparationPreference();
    const [loadingDeliveryPreferences, deliveryPreferences] = useGetPickupPreference();
    const buttonType = useMemo(() => filters.find((f) => f.field === 'delivery_method')?.value);
    const userDataLocalStorage = JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;
    const objectDateToSend = getDateFilterToSend(
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter,
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter_custom
    );

    const [isOpenDeliveryConfigModal, setIsOpenDeliveryConfigModal] = useState(false);

    const dispatch = useDispatch();

    const [selectedTab, setSelectedTab] = useState('all');
    const [scanMode, setScanMode] = useState();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedFulfillments, setSelectedFulfillments] = useState([]);
    const [tabColumns, setTabColumns] = useState(defaultColumnsView);
    const [tablePageSize, setTablePageSize] = useState(INITIAL_PAGE_SIZE);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDateRange, setSelectedDateRange] = useState({
        fromDate: objectDateToSend?.start ?? dayjs().format('YYYY-MM-DD'),
        toDate: objectDateToSend?.end ?? dayjs().format('YYYY-MM-DD'),
    });
    const [selectedLocation, setSelectedLocation] = useState([
        userData?.store_warehouse_id || 'all',
    ]);
    const [fulfillmentUrlParams, setFulfillmentUrlParams] = useState({});

    const loading =
        isLoading ||
        isFetching ||
        userLoading ||
        userFetching ||
        salesChannelsLoading ||
        locationsLoading ||
        couriersLoading ||
        loadingDeliveryPreferences;

    const handlePreparationsRefetch = (forceRefetch) => {
        const utils = store.getState().utils;
        const filters = utils.deliveriesFilters;
        getDeliveriesAllIds(filters, !forceRefetch);
        getDeliveries(filters, !forceRefetch);
    };

    const { isShowPreparer, isDisableScan } = useMemo(() => {
        const { preparer_default_assignment } = dataPreparations?.preparations_preferences || {};
        const {
            type_automatic_assignment_manager_fulfillment:
                type_automatic_assignment_manager_fulfillment_shipping,
        } = dataPickupsPreferences?.delivery_options?.shipping || {};
        const {
            type_automatic_assignment_manager_fulfillment:
                type_automatic_assignment_manager_fulfillment_pickup,
        } = dataPickupsPreferences?.delivery_options?.pickup || {};

        const isShowPreparer =
            preparer_default_assignment &&
            !!(
                type_automatic_assignment_manager_fulfillment_shipping ||
                type_automatic_assignment_manager_fulfillment_pickup
            );

        const isDisableScan =
            preparer_default_assignment &&
            !!(
                type_automatic_assignment_manager_fulfillment_shipping ===
                    'not_delivery_validation' ||
                type_automatic_assignment_manager_fulfillment_pickup === 'not_delivery_validation'
            );

        return { isShowPreparer, isDisableScan };
    }, [dataPreparations, dataPickupsPreferences]);

    // Funcion para actualizar el estado de la tab seleccionada a traves de los hijos
    const handleChangeTab = (tabValue) => {
        const selectedTabIndex = userPreferences?.preferences[PREFERENCES]?.tabs?.findIndex(
            (tab) => tab.value === tabValue
        );
        setSelectedTab(tabValue);
        setSelectedTabIndex(
            selectedTabIndex === -1
                ? userPreferences?.preferences[PREFERENCES]?.tabs?.length
                : selectedTabIndex
        );
        const filtersToOverride = structuredClone(
            userPreferences?.preferences[PREFERENCES]?.tabs[selectedTabIndex]?.filters ?? []
        );
        filtersToOverride.forEach((filter) => {
            filter.compromiseValues = valueOptionByField[filter.field];
        });
        dispatch(overrideFilters(filtersToOverride));
        // Despachamos los filtros para que se apliquen
        dispatch(
            setDeliveriesFilters({
                filters: [
                    ...(filtersToOverride?.map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })) ?? []),
                ],
                page: 1,
            })
        );

        handlePreparationsRefetch();
    };

    const handleChangeDateRange = (updatedDateRange) => {
        setSelectedDateRange(updatedDateRange);

        dispatch(
            setDeliveriesFilters({
                date_range: [updatedDateRange?.fromDate, updatedDateRange?.toDate],
                page: 1,
            })
        );
        handlePreparationsRefetch();
    };

    const handleChangeLocations = (updatedLocation) => {
        setSelectedLocation(updatedLocation);

        dispatch(
            setDeliveriesFilters({
                origin: updatedLocation,
                page: 1,
            })
        );
        handlePreparationsRefetch();
    };

    const handleChangeTabColumns = (updatedColumns) => {
        setTabColumns(updatedColumns);
    };

    const handleChangePageSize = (sizePage) => {
        setTablePageSize(sizePage);
    };

    const handleChangeColumns = (columns) => {
        setTabColumns(columns);
    };

    const handleDeleteFilter = (filterId) => {
        if (fulfillmentUrlParams?.fulfillment_id) {
            setFulfillmentUrlParams({});
        }
        dispatch(removeFilter(filterId));
        dispatch(
            setDeliveriesFilters({
                filters: filters
                    ?.filter((filter) => filter.id !== filterId)
                    .map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })),
                page: 1,
            })
        );
        handlePreparationsRefetch(true);
    };

    const handleInsertFulfillments = (fulfillments = [], excludeFulfillments = []) => {
        setFulfillmentToDelivery((state) => {
            const fulfillmentNotExists = state.filter(
                (fulfillment) => !fulfillments.find((f) => f._id === fulfillment._id)
            );

            const allFulfillments = [...fulfillmentNotExists, ...Array.from(fulfillments ?? [])];

            return allFulfillments.filter(
                (fulfillment) => !excludeFulfillments.find((f) => f._id === fulfillment._id)
            );
        });
    };

    useEffect(() => {
        const tab = userPreferences?.preferences[PREFERENCES]?.tabs?.find(
            (tab) => tab.value === selectedTab
        );

        if (!tab || tab?.typeOfTab === 'basic') {
            setTabColumns(defaultColumnsView);
            return;
        }

        setTabColumns(tab.columns);
    }, [selectedTab, selectedTabIndex]);

    useEffect(() => {
        if (!deliveryPreferences?._id) return;

        if (deliveryPreferences?.disable_shipping_filter) {
            setIsOpenDeliveryConfigModal(true);
        }

        const shippingScanMode =
            deliveryPreferences?.delivery_options?.shipping?.enable_scan_masivo;
        const pickupScanMode = deliveryPreferences?.delivery_options?.pickup?.enable_scan_masivo;

        if (isDisableScan) return;

        if (shippingScanMode && pickupScanMode && selectedTab === 'all') {
            setScanMode(true);
        }
        if (selectedTab === 'pending_delivery' && shippingScanMode) {
            setScanMode(true);
        }
        if (selectedTab === 'pending_pickup' && pickupScanMode) {
            setScanMode(true);
        }

        if (['deliveries_delivered_to_courier', 'deliveries_delivered'].includes(selectedTab)) {
            setScanMode(false);
        }
    }, [deliveryPreferences, selectedTab, isDisableScan]);

    // Para actualizar con el punto
    const forceRefetchWithButton = (event) => {
        const key = event?.key;
        if (key === '.') {
            handlePreparationsRefetch(true);
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', forceRefetchWithButton);

        const cacheMassiveFulfillmentDelivery = localStorage.getItem('massiveFulfillmentDelivery');
        if (cacheMassiveFulfillmentDelivery) {
            const fulfillments = JSON.parse(cacheMassiveFulfillmentDelivery);
            setMassiveFulfillmentDelivery(() => {
                const ids = fulfillments.map((item) => item._id);
                setSelectedRows(ids);
                setExpandedKeys(ids);
                handleInsertFulfillments(fulfillments);
                return fulfillments;
            });

            setScanMode(true);
        }

        return () => {
            document.removeEventListener('keypress', forceRefetchWithButton, true);
            dispatch(clearFilters());
            dispatch(setDeliveriesFilters({}));
        };
    }, []);

    useEffect(() => {
        if (scanMode) {
            localStorage.setItem(
                'massiveFulfillmentDelivery',
                JSON.stringify(massiveFulfillmentDelivery)
            );
        } else {
            if (!massiveFulfillmentDelivery.length) return;

            localStorage.removeItem('massiveFulfillmentDelivery');
            handleSelectRows(selectedRows, true, selectedFulfillments);
            setExpandedKeys([]);
            handleInsertFulfillments(
                selectedFulfillments,
                massiveFulfillmentDelivery.filter(
                    (fulfillment) => !selectedRows.includes(fulfillment._id)
                )
            );
        }
    }, [massiveFulfillmentDelivery, scanMode]);

    // Efecto para obtener las opciones de los filtros segun el campo
    useEffect(() => {
        if (loading) return;

        const destination_stores = Array.from(locations ?? [])
            .filter((location) => location?.is_enabled && location?.location_type !== 'virtual')
            .map((location) => ({
                label: location?.name,
                value: location?._id,
            }))
            .sort((a, b) => String(a.label).localeCompare(String(b.label)));

        const fieldValuesObj = {
            sales_channel: salesChannels.map((sc) => ({
                label: sc?.alias ?? sc?.integration?.api_name,
                value: sc?._id,
            })),
            origin: [{ label: 'Todas', value: 'all' }].concat(
                Array.from(locations ?? [])
                    .filter((l) => l.is_enabled)
                    ?.sort((a, b) =>
                        removeAccents(a.name.toUpperCase()).localeCompare(
                            removeAccents(b.name.toUpperCase())
                        )
                    )
                    .map((location) => ({
                        label: location?.name || '',
                        value: location._id,
                    }))
            ),
            courier: couriers.map((courier) => ({
                label: courier?.public_name ?? courier?.courier_name ?? '',
                value: courier?._id,
            })),
            promised_date_range: promisedDateRanges,
            'destination.province': deliveriesRegionOptions,
            ['locations.destination']: destination_stores,
        };
        dispatch(setValueOptionByField(fieldValuesObj));
    }, [loading]);

    useEffect(() => {
        if (!isLoading && data?.docs && !scanMode) {
            setMassiveFulfillmentDelivery(data.docs);
        }
    }, [data?.docs, isLoading, scanMode, isFetching]);

    const handleChangeScanModel = (scan) => {
        if (scan && massiveFulfillmentDelivery?.length) {
            const firstData = fulfillmentToDelivery?.[0];
            const findDifferentDeliveryMethod = massiveFulfillmentDelivery.find(
                (fulfillment) =>
                    selectedRows.includes(fulfillment._id) &&
                    (firstData ? fulfillment?.delivery_method !== firstData?.delivery_method : true)
            );

            if (
                !!findDifferentDeliveryMethod &&
                (dataPickupsPreferences?.delivery_method_pickup ||
                    dataPickupsPreferences?.delivery_method_shipping) &&
                dataPickupsPreferences?.delivery_options?.pickup?.enable_two_step_delivery !==
                    dataPickupsPreferences?.delivery_options?.shipping?.enable_two_step_delivery
            ) {
                setMassiveFulfillmentDelivery((state) => {
                    const fulfillments = [
                        ...state.filter(
                            (fulfillment) =>
                                selectedRows.includes(fulfillment._id) &&
                                fulfillment?.delivery_method === firstData?.delivery_method &&
                                !fulfillmentToDelivery.find((f) => f._id === fulfillment._id)
                        ),
                        ...fulfillmentToDelivery,
                    ];

                    const selectedRowsValues = fulfillments.map((fulfillment) => fulfillment._id);

                    setExpandedKeys((state) =>
                        state.filter((id) => selectedRowsValues.includes(id))
                    );
                    setSelectedRows(selectedRowsValues);
                    handleInsertFulfillments(fulfillments);

                    return fulfillments;
                });

                setScanMode(scan);

                return;
            }

            setMassiveFulfillmentDelivery((state) => {
                const fulfillments = [
                    ...state.filter(
                        (fulfillment) =>
                            selectedRows.includes(fulfillment._id) &&
                            !fulfillmentToDelivery.find((f) => f._id === fulfillment._id)
                    ),
                    ...fulfillmentToDelivery,
                ];
                const selectedRowsValues = fulfillments.map((fulfillment) => fulfillment._id);

                setSelectedRows(selectedRowsValues);
                handleInsertFulfillments(fulfillments);

                return fulfillments;
            });
        } else if (scan) {
            setMassiveFulfillmentDelivery([]);
        } else {
            setTimeout(() => handlePreparationsRefetch(true), 1000);
        }

        setScanMode(scan);
    };

    const searchFulfillmentByUrlParams = (fulfillmentId, remoteOrderId) => {
        const state = store.getState();
        const utils = state.utils;
        const filters = utils.deliveriesFilters;
        setFulfillmentUrlParams({
            fulfillment_id: fulfillmentId,
            remote_order_id: remoteOrderId,
        });
        getDeliveriesAllIds(
            {
                ...filters,
                origin: null,
                searchTerm: remoteOrderId,
                page: 1,
                date_range: [],
            },
            false,
            false
        );
        getDeliveries(
            {
                ...filters,
                origin: null,
                searchTerm: remoteOrderId,
                page: 1,
                date_range: [],
            },
            false
        ).then((response) => {
            const fulfillment = response?.data?.docs?.[0];
            const filter = {
                andOr: '',
                condition: 'is',
                field: 'name',
                value: fulfillment?.name ?? '',
                consolidation: false,
                isRemovable: true,
                id: 'fulfillment_url_params',
                compromiseValues: [],
            };
            dispatch(overrideFilters([filter]));
        });
    };

    const handleOnInit = () => {
        const searchParams = new URLSearchParams(params.search);
        const fulfillmentId = searchParams.get('fulfillment_id');
        const remoteOrderId = searchParams.get('remote_order_id');
        if (fulfillmentId && remoteOrderId) {
            searchFulfillmentByUrlParams(fulfillmentId, remoteOrderId);
            return;
        }
        if (scanMode) return;
        handlePreparationsRefetch();
    };

    const handleSelectRows = (selectedRows, all, selectedRowRecords) => {
        const firstDataSelected = all
            ? massiveFulfillmentDelivery?.find((fulfillment) => fulfillment._id === selectedRows[0])
            : fulfillmentToDelivery?.[0];
        const twoStepDelivery =
            deliveryPreferences?.delivery_options?.shipping?.enable_two_step_delivery;
        const twoStepPickup =
            deliveryPreferences?.delivery_options?.pickup?.enable_two_step_delivery;

        setSelectedRows(selectedRows);
        setSelectedFulfillments(selectedRowRecords);

        if (selectedRows?.length < 1) {
            handleInsertFulfillments([], massiveFulfillmentDelivery);
            return;
        }

        const diffDeliveryMethod = massiveFulfillmentDelivery.find(
            (fulfillment) =>
                selectedRows.includes(fulfillment._id) &&
                firstDataSelected?.delivery_method !== fulfillment?.delivery_method
        );

        handleInsertFulfillments(
            massiveFulfillmentDelivery.filter(
                (fulfillment) =>
                    selectedRows.includes(fulfillment._id) &&
                    (twoStepDelivery === twoStepPickup || !firstDataSelected
                        ? true
                        : firstDataSelected?.delivery_method === fulfillment?.delivery_method)
            ),
            massiveFulfillmentDelivery.filter(
                (fulfillment) => !selectedRows.includes(fulfillment._id)
            )
        );

        if (twoStepDelivery === twoStepPickup || (firstDataSelected ? !diffDeliveryMethod : true))
            return;

        toast.error(
            <b>
                No es posible combinar en una misma entrega: el procesamiento de pedidos que no
                comparten la misma configuración de entrega. (Entrega en 1 paso ó en 2 pasos)
            </b>
        );
    };

    useEffect(() => {
        handleOnInit();
    }, []);

    const validationDeliveryFulfillmentsAll = async (ids) => {
        const response = await validationDeliveryFulfillments({ ids });

        if (!response?.data?.length) return;

        handleSelectRows(
            response.data.map((fulfillment) => fulfillment._id),
            true
        );
        handleInsertFulfillments(
            response.data.filter((fulfillment) => fulfillment.validation),
            response.data.filter((fulfillment) => !fulfillment.validation)
        );
    };

    const handleSubmitDeliveryConfigModal = (values) => {
        const utils = store.getState().utils;
        const filters = utils.deliveriesFilters;

        const newFilters = [];
        if (values.delivery_method === 'shipping') {
            newFilters.push(
                ...[
                    {
                        andOr: 'and',
                        condition: 'is',
                        field: 'delivery_method',
                        value: values?.delivery_method,
                    },
                    {
                        andOr: 'and',
                        condition: 'is',
                        field: 'courier',
                        value: values?.courier,
                    },
                    {
                        andOr: 'and',
                        condition: values?.province === 'OTHER' ? 'not_include' : 'is',
                        field: 'destination.province',
                        value:
                            values?.province === 'OTHER'
                                ? ['REGION METROPOLITANA']
                                : values?.province,
                    },
                ]
            );
        } else {
            newFilters.push(
                ...[
                    {
                        andOr: 'and',
                        condition: 'is',
                        field: 'delivery_method',
                        value: values?.delivery_method,
                    },
                ]
            );
        }

        dispatch(overrideFilters([...filters.filters, ...newFilters]));

        getDeliveries(
            {
                ...filters,
                filters: [...filters.filters, ...newFilters],
            },
            false
        );
        setIsOpenDeliveryConfigModal(false);
    };

    const filtersToShow = useMemo(() => {
        return filters.filter((filter) => filter.field);
    }, [filters]);

    return (
        <Container extraTitle="Entregas">
            <PageHeader title="Entregas" withMarginBottom={false} />
            <p style={{ margin: '0.5rem 0 0.2rem' }}>Filtrar métricas y tabla por:</p>
            <Flex direction="row" columnGap="1rem" paddingBottom="2rem">
                <RangePicker loading={loading} setDateCalendar={handleChangeDateRange} />
                <LocationPicker
                    handleChangeLocations={handleChangeLocations}
                    loading={loading}
                    disabled={scanMode}
                />
            </Flex>
            <DeliveryConfigModal
                isOpen={isOpenDeliveryConfigModal}
                onClose={() => setIsOpenDeliveryConfigModal(false)}
                onSubmit={handleSubmitDeliveryConfigModal}
            />
            {/* Container con fechas, ubicaciones y Métricas */}
            <Flex direction="column">
                <CollapsibleContainer>
                    <DeliveriesMetrics
                        dataPickupsPreferences={dataPickupsPreferences}
                        selectedDateRange={selectedDateRange}
                        selectedLocation={selectedLocation}
                    />
                </CollapsibleContainer>
            </Flex>

            {/* Vistas personalizadas */}
            <ViewTabs
                userPreferences={userPreferences}
                selectedTab={selectedTab}
                handleChangeTab={handleChangeTab}
                preferences={PREFERENCES}
                dataPickupsPreferences={dataPickupsPreferences}
            />

            {/* Searchbar y botones de acciones */}
            <Flex
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                paddingTop="1rem"
                marginBottom="1rem"
                padding="1rem 0px 2rem"
            >
                <Flex className="mt-4" direction="column" rowGap="0.5rem">
                    <Flex direction="row" gap="1rem" alignItems="center">
                        <Switch onChange={handleChangeScanModel} checked={scanMode} />
                        <span style={{ fontSize: '14px' }}>
                            Modo scan {scanMode ? 'habilitado' : 'deshabilitado'}
                        </span>
                    </Flex>

                    {scanMode ? (
                        <DeliveriesPackageScanInput
                            refetch={handlePreparationsRefetch}
                            dispatchersType={PREFERENCES}
                            disabled={loading}
                            scanMode={scanMode}
                            setExpandedKeys={setExpandedKeys}
                            deliveryPreferences={deliveryPreferences}
                            expandedKeys={expandedKeys}
                            dataList={massiveFulfillmentDelivery}
                            updateList={setMassiveFulfillmentDelivery}
                            handleSelectRows={setSelectedRows}
                            dataPreparations={dataPreparations}
                            handleInsertFulfillments={handleInsertFulfillments}
                        />
                    ) : (
                        <SearchInput
                            value={searchTerm ?? undefined}
                            onChange={(e) => {
                                setSearchTerm(e?.target?.value);
                            }}
                            refetch={handlePreparationsRefetch}
                            dispatchersType={PREFERENCES}
                            disabled={loading}
                            scanMode={scanMode}
                            setExpandedKeys={setExpandedKeys}
                        />
                    )}
                </Flex>
                <Flex gap="1rem">
                    {isShowPreparer && (
                        <PreparatorButton
                            dispatchersType={PREFERENCES}
                            refetch={handlePreparationsRefetch}
                            location={selectedLocation}
                        />
                    )}
                    <FilterButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        refetch={handlePreparationsRefetch}
                        data={data}
                        modalName="deliveries-filter-modal"
                        formName="deliveries-filter-form"
                        filters={filters}
                        valueOptionByField={valueOptionByField}
                        dispatchersType={PREFERENCES}
                    />
                    <ColumnsButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        userFetching={userFetching}
                        tabColumns={tabColumns}
                        filters={filters}
                        handleChangeTabColumns={handleChangeTabColumns}
                        formName="deliveries-column-form"
                        modalName="deliveries-column-modal"
                        dispatchersType={PREFERENCES}
                    />
                    <ShowQuantityItems
                        tablePageSize={tablePageSize}
                        handleChangePageSize={handleChangePageSize}
                        disabled={loading}
                        dispatchersType={PREFERENCES}
                        refetch={handlePreparationsRefetch}
                    />
                    <DeliveriesActions
                        disabled={loading || selectedRows.length < 1}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        data={
                            scanMode
                                ? { docs: massiveFulfillmentDelivery }
                                : { docs: selectedFulfillments }
                        }
                        dataPickupsPreferences={dataPickupsPreferences}
                        dataPreparations={dataPreparations}
                        refetch={handlePreparationsRefetch}
                    />
                </Flex>
            </Flex>

            {/* Filtros seleccionados */}
            <Flex justifyContent="space-between" marginBottom="1rem">
                <Flex direction="row" alignItems="center" gap="0.75rem">
                    <span>Filtros: </span>
                    {filtersToShow.length === 0 ? (
                        <InfoFilter loading={loading} />
                    ) : (
                        filtersToShow?.map((filter) => (
                            <InfoFilter
                                key={filter.id}
                                filter={filter}
                                onDelete={handleDeleteFilter}
                                loading={loading}
                                showDeleteIcon
                                preferences={PREFERENCES}
                                valueOptionByField={valueOptionByField}
                            />
                        ))
                    )}
                </Flex>
                <DrawerPreProcess
                    selectedRows={selectedRows}
                    buttonType={buttonType}
                    isScan={scanMode}
                    deliveryPreferences={deliveryPreferences}
                    handleSelectRows={handleSelectRows}
                    dataPickupsPreferences={dataPickupsPreferences}
                    dataPreparations={dataPreparations}
                    updateList={setMassiveFulfillmentDelivery}
                    fulfillmentToDelivery={fulfillmentToDelivery}
                />
            </Flex>

            <Flex
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="0.5rem"
            >
                <Flex direction="row" alignItems="center">
                    {selectedRows.length === 0 ? (
                        <b style={{ display: 'block' }} className="text-sm">
                            No tienes pedidos seleccionados
                        </b>
                    ) : (
                        <b style={{ display: 'block' }} className="text-sm">
                            En esta vista tienes seleccionado{' '}
                            <strong>
                                {selectedRows.length} pedido(s) de{' '}
                                {scanMode ? massiveFulfillmentDelivery?.length : data?.totalDocs}
                            </strong>{' '}
                            {filtersToShow.length > 0 && '(Aplican filtros)'}
                        </b>
                    )}
                    <Divider type="vertical" style={{ marginBottom: '25px' }} />
                    {data?.totalDocs > 0 && data?.totalPages > 1 && !scanMode && (
                        <b
                            className="text-sm"
                            role="button"
                            onClick={() => {
                                if (selectedRows.length === data?.totalDocs) {
                                    handleSelectRows([], true);
                                    setFulfillmentToDelivery([]);
                                } else {
                                    validationDeliveryFulfillmentsAll(allIds?.ids ?? []);
                                }
                            }}
                            style={{ display: 'block' }}
                        >
                            <u>
                                {data?.totalDocs === selectedRows.length
                                    ? 'Deseleccionar todos'
                                    : 'Seleccionar todos'}{' '}
                                ({data?.totalDocs})
                            </u>
                        </b>
                    )}
                </Flex>

                {(!!fulfillmentToDelivery.length || scanMode) && (
                    <ScannedPackageStatus
                        isScan={scanMode}
                        fulfillmentToDelivery={fulfillmentToDelivery}
                        dataPickupsPreferences={dataPickupsPreferences}
                        dataPreparations={dataPreparations}
                        deliveryPreferences={deliveryPreferences}
                    />
                )}
            </Flex>

            {/* Table */}
            <DraggableTable
                fulfillmentSelected={fulfillmentUrlParams.fulfillment_id}
                data={data}
                loading={loading}
                userPreferences={userPreferences}
                selectedTab={selectedTab}
                refetch={handlePreparationsRefetch}
                selectedRows={selectedRows}
                handleSelectRows={handleSelectRows}
                tabColumns={tabColumns}
                handleChangeColumns={handleChangeColumns}
                preferences={PREFERENCES}
                scanMode={scanMode}
                expandedKeys={expandedKeys}
                setExpandedKeys={setExpandedKeys}
                dataPickupsPreferences={dataPickupsPreferences}
                dataPreparations={dataPreparations}
                dataList={massiveFulfillmentDelivery}
                updateList={setMassiveFulfillmentDelivery}
                handleInsertFulfillments={handleInsertFulfillments}
            />
        </Container>
    );
};

export default DeliveriesV2;
