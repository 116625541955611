import { createSlice } from '@reduxjs/toolkit';

const lineItemsCancel = createSlice({
    name: 'lineItemsCancel',
    initialState: { lineItemIds: [] },
    reducers: {
        selectedLineItemIds: (state, { payload }) => {
            return { ...state, lineItemIds: payload };
        },
        haveIncidences: (state, { payload }) => {
            return { ...state, isIncidence: payload };
        },
        clearLineItemIds: (state) => {
            return { ...state, lineItemIds: [] };
        },
    },
});
export const { selectedLineItemIds, haveIncidences, clearLineItemIds } = lineItemsCancel.actions;
export default lineItemsCancel.reducer;
