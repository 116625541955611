import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import styled from 'styled-components';

const DurationWrapper = styled.div`
    display: flex;

    h1 {
        margin-right: 2px;
    }
`;

const SubText = styled.span`
    font-size: 11px;
    font-weight: 300;
    color: #7998a7;
    margin-top: 0.4rem;
    margin-right: 0.5rem;
`;

dayjs.extend(duration);

const FormatDuration = ({ minutes }) => {
    let TextComponent = (
        <DurationWrapper>
            <>
                <h1>0</h1>
                <SubText>Min</SubText>
            </>
        </DurationWrapper>
    );

    if (!minutes) return TextComponent;

    const dur = dayjs.duration(minutes, 'minutes');

    const days = dur.days();
    const hours = dur.hours();
    const mins = dur.minutes();

    TextComponent = (
        <DurationWrapper>
            {days > 0 && (
                <>
                    <h1>{String(days).padStart(2, '0')}</h1>
                    <SubText>Días</SubText>
                </>
            )}

            {hours > 0 && (
                <>
                    <h1>{String(hours).padStart(2, '0')}</h1>
                    <SubText>Hrs</SubText>
                </>
            )}

            {mins > 0 && (
                <>
                    <h1>{String(mins).padStart(2, '0')}</h1>
                    <SubText>Min</SubText>
                </>
            )}
        </DurationWrapper>
    );

    return TextComponent;
};

export default FormatDuration;
