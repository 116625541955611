import { Checkbox } from 'antd';
import styled from 'styled-components';

const CheckboxGroup = Checkbox.Group;

export default styled(CheckboxGroup)`
    display: flex;
    flex-direction: column;
    gap: 6px;

    .ant-checkbox-wrapper-disabled {
        opacity: 0.6;
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0 !important;
    }

    .ant-checkbox-input {
        width: 16px !important;
        height: 16px !important;
    }

    span {
        color: #142c4a;
        font-size: 14px;
    }

    .ant-checkbox-inner {
        border: 2px solid #2d3d76 !important;
        border-radius: 2px;

        &:after {
            transform: rotate(45deg) scale(0.96) translate(-55%, -50%);
        }
    }
`;
