import { createSlice } from '@reduxjs/toolkit';

const initialStateDetailsManualIncidenceOrderDrawer = {
    orderData: {},
};

const solveOrderData = createSlice({
    name: 'detailsManualIncidenceOrderDrawer',
    initialState: initialStateDetailsManualIncidenceOrderDrawer,
    reducers: {
        changeDetailsManualIncidenceOrderDrawerData: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        changeDetailsManualIncidenceOrderDrawerField: (state, { payload }) => {
            return {
                ...state,
                orderData: payload[0],
                fulfillmentData: payload[1] || null,
            };
        },
        reInitDetailsManualIncidenceOrderDrawerData: (state) => {
            return {
                ...state,
                ...initialStateDetailsManualIncidenceOrderDrawer,
            };
        },
    },
});
export const {
    changeDetailsManualIncidenceOrderDrawerData,
    changeDetailsManualIncidenceOrderDrawerField,
    reInitDetailsManualIncidenceOrderDrawerData,
} = solveOrderData.actions;
export default solveOrderData.reducer;
