import { Modal } from 'antd';
import { TitleModalCancelOrder } from './styles';
import LocationPicker from 'components/V2/LocationPicker';
import { useState } from 'react';

export const ModalCancelOrder = ({
    isOpen,
    onOk,
    onCancel,
    orderData,
    cancelationRequireTransferLocation,
}) => {
    const [locationSelected, setLocationSelected] = useState(null);

    return (
        <div>
            <Modal
                title={
                    <TitleModalCancelOrder>
                        {orderData?.fulfillment_status === 'delivered' ? 'Devolver' : 'Cancelar'}{' '}
                        pedido
                    </TitleModalCancelOrder>
                }
                open={isOpen}
                onOk={() =>
                    onOk({
                        ...(cancelationRequireTransferLocation && {
                            transfer_location_id: locationSelected,
                        }),
                    })
                }
                okText="Confirmar"
                onCancel={onCancel}
                okButtonProps={{
                    disabled:
                        cancelationRequireTransferLocation &&
                        (!locationSelected || typeof locationSelected !== 'string'),
                }}
                width={575}
            >
                <div className="text-xs" style={{ color: '#142C4A', fontWeight: 400 }}>
                    {cancelationRequireTransferLocation && (
                        <div
                            style={{
                                marginBottom: '20px',
                            }}
                        >
                            <p
                                style={{
                                    marginBottom: '10px',
                                }}
                            >
                                Seleccione Tienda/bodega para destino de transferencia (Prism/SAP)
                            </p>

                            <LocationPicker
                                style={{ marginTop: '20px' }}
                                isGenericLocationPicker={true}
                                isSingleSelection={true}
                                handleChangeLocations={setLocationSelected}
                            />
                        </div>
                    )}
                    Si {orderData?.fulfillment_status === 'delivered' ? 'devolver' : 'cancelas'} el
                    pedido este quedará inactivo. <b> ¿Deseas confirmar esta acción?</b>
                </div>
            </Modal>
        </div>
    );
};
