import styled from 'styled-components';

export const TitleModalSameShippingService = styled.div`
    font-size: 16px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    color: #2d3d76;

    .icon-modal-different-shipping-service {
        font-size: 22px;
        color: #e79b09;
        font-weight: 600;
    }
`;
