import styled, { css } from 'styled-components';

export const PageLayout = styled.div`
    display: grid;
    grid-template-columns: 3fr 4fr;
    /* gap: 0.3rem; */
    grid-auto-rows: auto;

    .tcard {
        height: 100%;
        overflow-y: auto;
    }

    .products-with-incidences-title {
        color: #2d3d76;
        font-size: 14px;
        font-weight: 500;
        margin: 0 !important;
    }

    .order__change-store-header {
        grid-column: span 2;
        margin-bottom: 1rem;

        .tcard {
            padding: 0rem 2rem;
        }
    }

    .order__change-store-products {
        grid-row: span 3;
        grid-column: span 1;

        .tcard {
            background-color: #f5f5f5;
            padding: 0rem 1rem;
            border: none !important;
            border-radius: 0% !important;
            box-shadow: none !important;
        }

        .ant-table {
            background-color: #f5f5f5;
        }

        .ant-table-cell {
            background-color: #f5f5f5;
        }
    }

    .order__change-store-incidence {
        grid-row: span 3;
        grid-column: span 1;

        .tcard {
            background-color: #fff;
            padding: 0rem 2rem;
            border: 1px solid #dce5ec !important;
            border-radius: 0% !important;
            box-shadow: none !important;
        }
    }

    .order-page__header {
        grid-row: 1;
        grid-column: span 2;
        margin-bottom: 2rem;

        @media (max-width: 1080px) {
            grid-row: auto;
            grid-column: auto;
        }
    }

    .order__change-store-footer {
        grid-column: span 2;

        .tcard {
            border: none !important;
            border-radius: 0% !important;
            box-shadow: none !important;
            margin: 0px;
            /* padding: 0rem 2rem; */
        }
    }

    .item-incidence-count-text {
        display: flex;
        font-size: 11px;
    }
`;

export const IncidenceSelectorStyled = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
`;

export const IncidenceCard = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #bacbd9;
    border-radius: 5px;
    flex: 1;
    line-height: 0.9rem;
    text-align: center;
    padding: 1rem 0.5rem 1rem 0.5rem;
    height: 13rem;
    justify-content: space-between;
    flex-direction: column;

    b {
        display: block;
        font-size: 1rem;
        margin-bottom: 1rem;
        color: #2d3d76;
    }

    small {
        display: block;
        margin-bottom: 1rem;
        color: #1f2937;
    }

    div {
        display: flex;
        justify-content: center;
    }
`;

export const ProductsHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;

    b {
        color: #142c4a;
        font-size: 12px;
        font-weight: 400;
    }

    .product-header-title {
        color: #037fb9;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0.1rem;
    }

    .product-header-counter {
        color: #142c4a;
        font-size: 12px;
        font-weight: 400;
    }

    .product-header-delivery-method {
        color: #2d3d76;
        font-size: 11px;
        font-weight: 400;
    }
`;

export const ProductImage = styled.div`
    margin-top: 0.5rem;
    width: 37px;
    height: 37px;
    border: 1px solid black;
`;

export const ProductDescription = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;

    span {
        color: #036896;
        font-weight: 400;
        font-size: 11px;
    }

    p,
    b {
        max-width: 80%;
        font-weight: 400;
        font-size: 12px;
        display: block;
        margin: 0px;
        color: #536d8f;
        margin-bottom: 0.1rem;
    }

    small {
        color: ${({ theme }) => theme?.font?.error ?? '#E33512'};
    }
`;
export const ProductRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

export const ProductRowRight = styled.div`
    display: block;
    margin-left: auto;
    color: #142c4a;
    font-weight: 400;
    font-size: 12px;
    text-align: right;

    p {
        white-space: nowrap;
    }

    span {
        display: block;
    }
`;

export const WarehousesOptionsSelector = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 2rem;
`;
export const WarehousesOption = styled.div`
    flex: 1;
    border: 2px solid #9ebcc0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    line-height: 0.9rem;

    svg {
        margin-bottom: 0.5rem;
        font-size: 32px;
        opacity: 0.8;
    }

    b {
        display: block;
        font-size: 0.9rem;
        text-align: center;
    }

    user-select: none;
    ${(props) =>
        props.isSelected &&
        css`
            background: #036896;
            border: 1px solid #037fb9;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

            svg,
            b {
                color: white;
            }
        `}
`;

export const WarehousesSelectedInfo = styled.div`
    width: 100%;
    border: 1px solid #dce5ec;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-self: center;
    gap: 0.3rem;
`;

export const LocationRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const LocationTitle = styled.div`
    display: flex;
    flex-direction: column;

    b {
        display: block;
        color: #2D3D76;
        text-transform: uppercase;
        font-weight: 500 !important;
        letter-spacing: 0.6px;
        margin-bottom: 0.25rem;
    }
`;

export const LocationDescription = styled.div`
    display: flex;
    flex-direction: column;

    .availability__label {
        color: #00ba76;
    }

    .address__label {
        color: #2d3d76;
        text-transform: uppercase;
        font-weight: 400 !important;
        font-size: 12px;
    }
`;

export const ResolvedItemsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const ResolvedItems = styled.div`
    border: 1px solid #dce5ec;
    border-radius: 5px 5px 0px 0px;
    padding: 1rem;
`;
