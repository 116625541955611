import ErrorsTable from 'pages/Errors/components/ErrorsTable';
import { useGetErrorsQuery } from 'redux/features/Errors';

export const IntegrationsErrors = ({ data }) => {
    const { data: errorsData, isLoading } = useGetErrorsQuery(
        { order_id: data?._id },
        {
            pollingInterval: 180000,
            skip: !data?._id,
        }
    );

    const loadingErrors = isLoading;
    return (
        <ErrorsTable
            name='integration-errors-detail-order'
            loading={loadingErrors}
            data={errorsData}
        />
    );
};
