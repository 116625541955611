import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import { toggleModal } from '../Utils';
import { getPreparationPreferenceByMerchant } from './getPreparationPreferenceByMerchant';
import toast from 'react-hot-toast';

export const editPreparationPreference = createAsyncThunk(
    'preparationPreference/edit',
    async (data, state) => {
        try {
            const states = state.getState();

            const promise = AndesClient.put(`/settings/preparation/${data?._id}`, data);

            toast.promise(promise, {
                loading: <b>Modificando configuración de preferencia de preparación...</b>,
                error: <b>Ocurrió al modificar la configuración de preferencia de preparación.</b>,
                success: <b>Configuración de preferencia de entrega modificada con éxito.</b>,
            });

            const res = await promise;

            if (res.status === 200) {
                if (states.utils?.modal['edit-sla-levels-modal']) {
                    state.dispatch(toggleModal('edit-sla-levels-modal'));
                }
                localStorage.removeItem('preparationSettings');
                state.dispatch(getPreparationPreferenceByMerchant());
            }
            return res.data;
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const updatePreparationPreferenceSlice = createSlice({
    name: 'updatePreparationPreference',
    initialState: {
        loading: false,
        data: {},
        error: null,
    },
    reducers: {},
    extraReducers: {
        [editPreparationPreference.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [editPreparationPreference.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [editPreparationPreference.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default updatePreparationPreferenceSlice.reducer;
