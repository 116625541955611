import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getPickupPreferenceByMerchant } from './getPickupPreferenceByMerchant';

export const createPickupPreference = createAsyncThunk(
    'pickupPreference/create',
    async (data, state) => {
        try {
            const res = await AndesClient.post(`/settings/pickup`, data);
            if (res.status === 200) {
                toast.success('Configuracion de preferencia de entrega creada con éxito.');
                state.dispatch(getPickupPreferenceByMerchant());
            }
            return state.fulfillWithValue(true);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const createPickupPreferenceSlice = createSlice({
    name: 'createPickupPreference',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createPickupPreference.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createPickupPreference.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createPickupPreference.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createPickupPreferenceSlice.reducer;
