import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import { getGeneralByMerchant } from './getGeneralByMerchant';
import toast from 'react-hot-toast';

export const createGeneral = createAsyncThunk('general/create', async (data, state) => {
    try {
        const res = await AndesClient.post(`settings/generals`, data);
        if (res.status === 200) {
            toast.success('Configuración general creada con éxito.');
            localStorage.removeItem('generalSettings');
            state.dispatch(getGeneralByMerchant());
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const createGeneralSlice = createSlice({
    name: 'createGeneral',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createGeneral.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createGeneral.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createGeneral.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createGeneralSlice.reducer;
