export const manifestsMethodOptions = [
    {
        label: 'Retiro',
        value: 'pickup',
    },
    { label: 'Retiro con consolidación', value: 'pickup_consolidation' },
    {
        label: 'Despacho',
        value: 'shipping',
    },
    { label: 'Despacho con consolidación', value: 'shipping_consolidation' },
    { label: 'Retiro en punto de tercero', value: 'pickup_point' },
    // {
    //     label: 'Nro de bultos',
    //     value: 'package_length',
    // },
];

export const manifestsStatusOptions = [
    {
        label: 'Pendiente',
        value: 'pending',
    },
    {
        label: 'Entregado',
        value: 'delivered',
    },
    // {
    //     label: 'Nro de bultos',
    //     value: 'package_length',
    // },
];
