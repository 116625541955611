import { Form, Space } from 'antd';
import { Radio } from 'components/Form';

export const PurposeAnnulment = () => {
    return (
        <div>
            <div
                className="text-xs"
                style={{ color: '#142C4A', fontWeight: 500, paddingBottom: '.5rem' }}
            >
                Por favor seleccionar el propósito de anulación
            </div>
            <Form.Item
                style={{ margin: '0 0 14px' }}
                name="purpose_annulment"
                valuePropName="value"
            >
                <Radio.Group name="purpose_annulment">
                    <Space direction="vertical">
                        <Radio value="refund_money">
                            <span style={{ color: '#142C4A', fontSize: '12px', fontWeight: 500 }}>
                                Devolución del dinero
                            </span>
                        </Radio>
                        <Radio value="product_change">
                            <span style={{ color: '#142C4A', fontSize: '12px', fontWeight: 500 }}>
                                Cambio de producto
                            </span>
                        </Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </div>
    );
};
