import { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import FormModal from 'components/Modal/FormModal';
import { toggleModal } from 'redux/features/Utils';
import { ReactComponent as ColumnsIcon } from 'assets/columns.svg';
import ColumnsForm from 'components/V2/Forms/ColumnsForm';
import SecondaryButton from 'components/V2/Buttons/SecondaryButton';

function ColumnsButton({
    userPreferences,
    selectedTab,
    selectedTabIndex,
    loading,
    userFetching,
    tabColumns,
    handleChangeTabColumns,
    formName,
    modalName,
    dispatchersType,
    filters = [],
}) {
    const [hasModalOpenRender, setHasModalOpenRender] = useState({});
    const [tabNumber, setTabNumber] = useState(0);
    const buttonRef = useRef();
    const dispatch = useDispatch();

    const memoizedColumns = useMemo(() => {
        return structuredClone(tabColumns);
    }, [selectedTab, hasModalOpenRender]);

    // Efecto para actualizar el numero de columnas activas segun la tab seleccionada
    useEffect(() => {
        const current = tabColumns?.filter((column) => column.status).length;
        setTabNumber(current);
    }, [userPreferences, loading, userFetching, selectedTab, selectedTabIndex, tabColumns]);

    const handleOpenModal = () => {
        dispatch(toggleModal(modalName));
        setHasModalOpenRender({});
        buttonRef.current.blur();
    };

    const handleCancelModal = () => {
        dispatch(toggleModal(modalName));
        handleChangeTabColumns(memoizedColumns);
    };

    return (
        <>
            <FormModal
                formComponent={<ColumnsForm />}
                formProps={{
                    formName: formName,
                    modalName: modalName,
                    userPreferences,
                    selectedTab,
                    tabColumns,
                    handleChangeTabColumns,
                    dispatchersType,
                    filters,
                }}
                modalProps={{
                    modalName: modalName,
                    title: <h1>Columnas</h1>,
                    width: 500,
                    footer: null,
                    onCancel: handleCancelModal,
                }}
            />
            <SecondaryButton
                ref={buttonRef}
                loading={loading}
                disabled={loading}
                onClick={handleOpenModal}
                icon={<ColumnsIcon />}
                iconPosition="right"
            >
                Columnas {loading || !tabNumber ? '' : `(${tabNumber})`}
            </SecondaryButton>
        </>
    );
}

export default ColumnsButton;
