import styled from 'styled-components';

export const ContainerTitleIncidence = styled.div`
    display: flex;
    background-color: #d6f3fb;
    color: #22505e;
    border-radius: 4px;
    justify-content: center;
    width: max-content;
    padding: 0.5rem;
    gap: 4px;
    margin: 0 auto 1rem;
    text-align: center;
`;

export const Title = styled.span`
    color: ${({ color }) => color ?? '#142c4a'};
    font-weight: 500;
`;

export const Description = styled.span`
    color: #536d8f;
    font-size: 10px;
`;
