import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const orderReportsApi = createApi({
    reducerPath: 'orders_report',
    baseQuery,
    tagTypes: ['orders_report'],
    endpoints: (builder) => ({
        sendOrdersReports: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/reports/orders_report`,
                    method: 'post',
                    body: data.info,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Generando reporte...</b>,
                    success: <b>Reporte enviado a {data.email}</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            providesTags: ['orders_report'],
        }),
        sendOrdersReportsV2: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/ordersv2/reports/document`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Generando reporte...</b>,
                    success: <b>Reporte enviado a {data.email}</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            providesTags: ['orders_report'],
        }),
    }),
});

export const { useSendOrdersReportsMutation, useSendOrdersReportsV2Mutation } = orderReportsApi;
