import styled from 'styled-components';

export const SkeletonContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 1rem;
    grid-row-gap: 0px;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
`;

export const SkeletonDiv = styled.div`
    &.div1 {
        grid-area: 1 / 1 / 2 / 6;
    }
    &.div2 {
        grid-area: 2 / 1 / 5 / 4;
    }
    &.div3 {
        grid-area: 2 / 4 / 5 / 6;
    }
    &.div4 {
        grid-area: 5 / 1 / 6 / 4;
    }
`;
