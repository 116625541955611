import styled from 'styled-components';

export const SaveButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
`;

export const SpeakerButtonWrapper = styled.div`
    button {
        margin-top: 1rem;
        height: 42px;
    }

    &:hover {
        svg {
            fill: rgb(255, 255, 255);
        }

        ${(props) =>
            props.disabled &&
            `
            svg {
                fill: #9EBCC0;
            }
        `}
    }

    svg {
        width: 20px;
        height: 20px;
        fill: rgb(45, 61, 118);

        ${(props) =>
            props.disabled &&
            `
            fill: #9EBCC0;
        `}
    }
`;

export const SelectSoundWrapper = styled.span`
    display: flex;

    ${(props) =>
        props.disabled &&
        `
            .ant-form-item-control-input {
        svg {
            fill: #9EBCC0;
        }
    }
        `}
`;
