import styled from 'styled-components';

export const Typography = styled.p`
    font-size: ${({ fontSize }) => fontSize ?? '16px'};
    font-weight: ${({ fontWeight }) => fontWeight ?? '700'};
    color: ${({ color }) => (color === 'primary.01' ? '#142C4A' : '#2D3D76')};
    margin-bottom: 0px;
`;

export const Chip = styled.div`
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    padding: 3px 5px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #037FB9;
    background: #037FB9;
`;
