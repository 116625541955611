import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

// Obtenemos todas las ultimas 50 fulfillments
export const fetchAllfulfillments = createAsyncThunk(
    'fulfillments/getAll',
    async (queryobj = {}, state) => {
        try {
            if (queryobj.origin) {
                queryobj.origin = queryobj.origin.join(',');
            }

            const response = await AndesClient.get('/fulfillments', {
                params: {
                    ...queryobj,
                    limit: 50,
                },
            });
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const fulfillmentsSlice = createSlice({
    name: 'fulfillments',
    initialState: {
        loading: false,
        success: false,
        data: {},
        error: null,
    },
    reducers: {},
    extraReducers: {
        [fetchAllfulfillments.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [fetchAllfulfillments.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: true,
                data: payload,
                error: null,
            };
        },
        [fetchAllfulfillments.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default fulfillmentsSlice.reducer;
