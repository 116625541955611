import { Form, Modal, Input } from 'antd';
import FormStyled from 'components/Utils/Form';
import getLabel from 'helpers/labels';
import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useUpdateOrderCustomerMutation } from 'redux/features/Orders/updateOrderCustomer';
import styled from 'styled-components';

const SubTitle = styled.span`
    color: #2d3d76;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 20px;
        color: inherit;
        padding: 0;
        // position: relative;
        // top: 40px;
    }
`;

export const ClientContact = ({ orderId, data, refetch }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateOrderCustomer, updateOrderCustomerState] = useUpdateOrderCustomerMutation();
    const [customer, setCustomer] = useState([]);
    const [customerData, setCustomerData] = useState({});

    const [form] = Form.useForm();

    useEffect(() => {
        setCustomer(data?.customer);
    }, [data]);

    const handleEditClick = () => {
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        const customerUpdateData = form.getFieldsValue();

        updateOrderCustomer({
            order_id: orderId,
            customer: customerUpdateData,
        });

        setIsModalVisible(false);
    };

    useEffect(() => {
        setCustomerData(customer);

        if (updateOrderCustomerState.isSuccess) {
            refetch();
            setCustomerData(updateOrderCustomerState.originalArgs.customer);
        }
    }, [updateOrderCustomerState, refetch, customer]);

    return (
        <div>
            <SubTitle>
                <span>Información de contacto</span>
                <button type="button" onClick={handleEditClick}>
                    <FiEdit />
                </button>
            </SubTitle>
            <Modal
                title="Editar información de contacto"
                okText="Guardar cambios"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleModalClose}
                okButtonProps={{
                    style: {
                        backgroundColor: '#F27127',
                        color: 'white',
                        border: '1px solid #E79B09 ',
                    },
                }}
                cancelButtonProps={{
                    style: {
                        backgroundColor: 'none',
                        color: '#2D3D76',
                        border: '1px solid #2D3D76 ',
                    },
                }}
            >
                <FormStyled
                    name="customerContactForm"
                    layout="vertical"
                    initialValues={customerData}
                    form={form}
                >
                    {customerData &&
                        Object.keys(customerData).map((key) => {
                            const customerFieldsToShow = [
                                'first_name',
                                'last_name',
                                'document',
                                'email',
                                'phone',
                            ];
                            let showItem = 'none';

                            if (customerFieldsToShow.includes(key)) {
                                showItem = 'inline';
                            }

                            const label = getLabel(key);

                            return (
                                <Form.Item
                                    style={{ display: showItem }}
                                    key={key}
                                    name={key}
                                    label={label?.label ?? key}
                                >
                                    <Input placeholder={label?.placeholder ?? ''} />
                                </Form.Item>
                            );
                        })}
                </FormStyled>
            </Modal>
        </div>
    );
};
