import { Button } from '@andescommercecloud/beflow-design-system';
import styled, { css } from 'styled-components';

export const HeaderCollapsible = styled.div`
    border: 1px solid #e4e8ec;
`;

export const HeaderBody = styled.div`
    display: grid;
    grid-template-columns: 0.2fr 0.9fr 0.9fr 0.8fr 0.5fr 1fr;
    grid-template-rows: 1fr 0.9fr 0.5fr;
    grid-column-gap: 15px;
    grid-row-gap: 10px;
    // align-items: center;

    padding: 1rem;
    overflow: hidden;
    transition: height 200ms;

    ${({ $showMore }) =>
        !$showMore &&
        css`
            height: 70px;
            .preparation-page__header-date_order,
            .preparation-page__header-delivery_method,
            .preparation-page__header-courier,
            .preparation-page__header-order_detail,
            .preparation-page__header-responsible {
                display: none;
            }
        `}

    .preparation-page__header-back-button {
        grid-row: 1;
        grid-column: 1;
    }

    .preparation-page__header-title {
        font-size: 24px;
        grid-row: 1;
        grid-column: 2;
    }

    .preparation-page__header-term_limit {
        grid-row: 1;
        grid-column: 3;
    }
    .preparation-page__header-status {
        grid-row: 1;
        grid-column: 4 / span 5;
    }
    .preparation-page__header-date_order {
        grid-row: 2;
        grid-column: 2;
    }
    .preparation-page__header-delivery_method {
        grid-row: 2;
        grid-column: 3;
    }
    .preparation-page__header-courier {
        grid-row: 2;
        grid-column: 4 / span 5;
    }
    .preparation-page__header-order_detail {
        grid-row: 3;
        grid-column: 2;
        font-weight: 500;
        font-size: 14px;
        color: #2d3d76;
        display: flex;
        align-items: center;
    }
    .preparation-page__header-complete_fulfillment {
        grid-row: 1 / span 4;
        grid-column: 6;
    }
    .preparation-page__header-responsible {
        grid-row: 3;
        grid-column: 3 / span 4;
        align-items: center;
    }
`;

export const HeaderFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: #f5f5f5;
    color: #2d3d76;
    height: 36px;
    transition: 0.5s;
    transform: rotate(0deg);

    svg {
        font-size: 1rem;
        ${({ $showMore }) =>
            $showMore &&
            css`
                transform: rotate(180deg);
            `}
    }
    u {
        font-size: 12px;
    }
`;

export const BackButton = styled.button`
    background-color: white;
    border: 1px solid #2d3d76;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 36px;

    svg {
        font-size: 1.6rem;
    }
`;

export const HeaderTitle = styled.div`
    color: #2d3d76;
    font-weight: 700;
    font-size: 24px;
`;

export const HeaderLabelTitle = styled.div`
    color: #2d3d76;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-right: 6px;
`;
export const HeaderLabelBody = styled.div`
    color: #2d3d76;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    flex-direction: row;
`;
export const HeaderLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    // align-items: center;
    padding-top: 5px;

    & .ant-typography-copy {
        top: -4px;
    }
`;

export const TagStatusContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;

    .icon-fulfillment-status {
        margin-top: 1px;
    }
    .fulfillment-status {
    }
`;

export const HeaderHomeStyle = styled.div`
    font-size: 16px;
    color: #7998a7;
    margin-bottom: 30px;
`;

export const ButtonRemoveList = styled(Button)`
    background-color: transparent !important;
    border: none !important;
    font-weight: 500;
    font-size: 12px;
    color: #2d3d76;
    text-decoration: underline;
    // display: flex;
    // align-items: center;

    :hover {
        color: #2d3d76 !important;
    }
`;
