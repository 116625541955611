import styled from 'styled-components';
import { InputNumber } from 'antd';

export const InputNumberStyled = styled(InputNumber)`
    .ant-input-number-handler-wrap {
        opacity: 1;
        width: 40px;
        display: flex;
        flex-direction: column;
        background-color: #dce5ec;
        .ant-input-number-handler {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .ant-input-number-handler-up-inner,
            .ant-input-number-handler-down-inner {
                font-size: 12px !important;
                margin-left: auto;
                margin-right: auto;
                left: 0;
                right: 0;
                text-align: center;
                svg {
                    fill: #1f6373;
                    stroke: #1f6373;
                }
            }
        }
    }
    .ant-input-number-input-wrap,
    .ant-input-number-input {
        height: 100%;
    }
    /* .ant-input-number {
        width: 80px;
        height: 40px;
        .ant-input-number-handler-wrap {
            opacity: 1;
            width: 40px;
            display: flex;
            flex-direction: column;
            background-color: #dce5ec;
            .ant-input-number-handler {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .ant-input-number-handler-up-inner,
                .ant-input-number-handler-down-inner {
                    font-size: 12px !important;
                    margin-left: auto;
                    margin-right: auto;
                    left: 0;
                    right: 0;
                    text-align: center;
                    svg {
                        fill: #1f6373;
                        stroke: #1f6373;
                    }
                }
            }
        }
        .ant-input-number-input-wrap,
        .ant-input-number-input {
            height: 100%;
        }
    } */
    .ant-input-number-handler-up:hover { 
        height: 50% !important;
    }
    .ant-input-number-handler-wrap:hover .ant-input-number-handler {
        height: 50% !important;

    }
`;
