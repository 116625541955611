import Flex from 'components/Utils/Flex';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import AntdTable from 'components/AntdTable';
// import useAllShops from 'hooks/useAllShops';
import { setChangeStoreValues } from 'redux/features/ChangeStore';
import { LocationDescription, LocationRow, LocationTitle } from '../styles';
import Tag from 'components/Tag';
import { Dot } from 'components/Utils/Dot';
import SearchInput from 'components/SearchInput';
import { Form, Radio, Space } from 'antd';
import { Button, Typography } from '@andescommercecloud/beflow-design-system';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { FLOWS } from './constants';
import { useLocation } from '../hooks/useLocation';

const renderDeliveryMethod = {
    all: 'Despacho/Retiro',
    shipping: 'Despacho',
    pickup: 'Retiro',
};

const WarehousesList = ({
    order,
    handleBackStep,
    currentStepForComeback,
    fulfillmentId,
    lineItems,
}) => {
    const [searchTerms, setSearchTerms] = useState('');
    const changeStoreData = useSelector((state) => state.changeStore);

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const fulfillmentSelected = useMemo(() => {
        const logistic = !fulfillmentId
            ? order?.logistics_info?.[0]
            : order?.logistics_info?.find((f) => String(f._id) === String(fulfillmentId));
        const logisticSelected = {
            ...logistic,
            origin: {
                _id: order?.location_id,
                id: order?.location_id,
                name: order?.location_id?.name,
            },
        };
        if (!fulfillmentId) return order?.fulfillments?.[0] ?? logisticSelected;
        return (
            order?.fulfillments?.find((f) => String(f._id) === String(fulfillmentId)) ??
            logisticSelected
        );
    }, [fulfillmentId, order]);

    const { locations, data, loadingLocations, loadingShops, dataShops } = useLocation({
        lineItems,
        fulfillmentId,
        order,
        fulfillmentSelected,
        searchTerms,
    });

    const columns = useMemo(() => {
        return Array.from([
            {
                render: (row) => (
                    <LocationRow>
                        <LocationTitle>
                            {changeStoreData?.warehouseType !== 'all' ? (
                                <Flex direction="column">
                                    <Form.Item name="delivery_method" style={{ margin: 0 }}>
                                        <Radio.Group
                                            onChange={(e) =>
                                                handleSelectLocation(row, e.target.value)
                                            }
                                        >
                                            <Radio
                                                value={`${
                                                    row._id ??
                                                    row?.location_id ??
                                                    row?.oms_location_id
                                                }_${
                                                    changeStoreData?.delivery_method ??
                                                    changeStoreData?.warehouseType
                                                }`}
                                            >
                                                {(row?.supply_store || row?.supply_available) &&
                                                !changeStoreData?.warehouseType &&
                                                fulfillmentSelected?.consolidation
                                                    ?.is_consolidation_fulfillment
                                                    ? row?.name
                                                    : row.supply_available?.name ??
                                                      row.name ??
                                                      row.location_name}{' '}
                                                {(row?.supply_store || row?.supply_available) &&
                                                    (!!changeStoreData?.warehouseType ||
                                                        !fulfillmentSelected?.consolidation
                                                            ?.is_consolidation_fulfillment) && (
                                                        <Typography
                                                            variant="captionRegular"
                                                            style={{ color: '#B00303' }}
                                                        >
                                                            (con stock de:{' '}
                                                            {row.supply_available?.name
                                                                ? row.name ?? row.location_name
                                                                : row.supply_store?.name}
                                                            )
                                                        </Typography>
                                                    )}
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Flex>
                            ) : (
                                <b>
                                    {row?.supply_available?.name ?? row.name ?? row.location_name}{' '}
                                    {(row?.supply_store || row?.supply_available) && (
                                        <Typography
                                            variant="captionRegular"
                                            style={{ color: '#B00303' }}
                                        >
                                            (con stock de:{' '}
                                            {row.supply_available?.name
                                                ? row.name ?? row.location_name
                                                : row.supply_store?.name}
                                            )
                                        </Typography>
                                    )}
                                </b>
                            )}
                            <span
                                style={{
                                    marginLeft: changeStoreData?.warehouseType !== 'all' ? 16 : 0,
                                }}
                            >
                                {changeStoreData?.delivery_method ||
                                changeStoreData?.warehouseType ? (
                                    <Tag color="info">
                                        {
                                            renderDeliveryMethod[
                                                changeStoreData.warehouseType === 'all'
                                                    ? 'all'
                                                    : changeStoreData?.delivery_method ??
                                                      changeStoreData?.warehouseType
                                            ]
                                        }
                                    </Tag>
                                ) : (
                                    row.functions?.map((func) => (
                                        <Tag key={func._id} color="info">
                                            {renderDeliveryMethod[func]}
                                        </Tag>
                                    ))
                                )}
                            </span>
                        </LocationTitle>
                        <LocationDescription>
                            <Flex
                                direction="row"
                                alignItems="center"
                                columnGap="0.4rem"
                                justifyContent="flex-end"
                            >
                                <b className="availability__label">
                                    Todos los productos disponibles
                                </b>
                                <Dot color="#00BA76" size="12px" />
                            </Flex>
                            <Flex
                                direction="row"
                                alignItems="center"
                                columnGap="0.4rem"
                                justifyContent="flex-end"
                            >
                                <b className="address__label">
                                    {row.address1}, {row.address2}, {row.province_name}
                                </b>
                            </Flex>
                        </LocationDescription>
                    </LocationRow>
                ),
            },
        ]);
    }, [changeStoreData.warehouseType, locations, location, changeStoreData.delivery_method]);

    const handleCreateChangeStore = () => {
        const changeStoreObj = {
            location: changeStoreData.location,
            line_items: changeStoreData.line_items,
            delivery_method: changeStoreData.delivery_method,
            location_name: changeStoreData.location_name,
        };

        dispatch(
            setChangeStoreValues({
                step: 'change-confirmation',
                changeStoreList: [changeStoreObj],
            })
        );
    };

    const findFlow = (location, deliveryMethod) => {
        if (
            changeStoreData?.warehouseType &&
            location.supply_store !== fulfillmentSelected?.consolidation?.preparation_location_id &&
            fulfillmentSelected?.consolidation?.preparation_location_id
        )
            return FLOWS.ConsolidationToPickupOrShippingWithCd;
        if (
            !changeStoreData?.warehouseType &&
            location.supply_available !==
                fulfillmentSelected?.consolidation?.preparation_location_id &&
            fulfillmentSelected?.consolidation?.preparation_location_id
        )
            return FLOWS.ConsolidationWarehouseAvailable;
        if (
            (location.supply_store || location?.supply_available) &&
            deliveryMethod === 'pickup' &&
            !fulfillmentSelected?.preparationcd_transfer_pickupstore?.cd_location
        ) {
            return FLOWS.NormalToPreparationCdTransferPickupStore;
        }
        if (
            (location.supply_store || location?.supply_available) &&
            deliveryMethod === 'shipping' &&
            !fulfillmentSelected?.consolidation?.preparation_location_id
        ) {
            return FLOWS.NormalToConsolidation;
        }

        return undefined;
    };

    const handleSelectLocation = (location, delivery_method = '') => {
        const flow = findFlow(location, delivery_method.split('_')[1]);

        const locationFind = dataShops?.find((s) =>
            [location?.oms_location_id, location?.location_id].includes(s._id)
        );

        const addressToPreparationCdPickupLocation =
            flow === FLOWS.NormalToPreparationCdTransferPickupStore
                ? {
                      address1: locationFind?.address1,
                      region: locationFind?.province,
                      neighborhood: locationFind?.neighborhood,
                      city: locationFind?.city,
                  }
                : {};

        dispatch(
            setChangeStoreValues({
                delivery_method: String(delivery_method).split('_')[1],
                location: location._id ?? location.location_id ?? location?.oms_location_id,
                location_name: location.name ?? location.location_name,
                location_supply: location.supply_available?.id ?? location.supply_store?._id,
                location_supply_name:
                    location.supply_available?.name ?? location.supply_store?.name,
                flow,
                shipping: addressToPreparationCdPickupLocation,
            })
        );
    };

    return (
        <Flex direction="column">
            <Typography variant="medium" color="primary.00" style={{ fontWeight: 500 }}>
                {!changeStoreData?.warehouseType && 'Bodegas disponibles'}
                <Typography style={{ fontSize: 14 }} variant="captionMedium" color="secondary.00">
                    &gt;&nbsp;Listado de tiendas / bodegas
                </Typography>
            </Typography>
            <br />

            {!changeStoreData?.warehouseType && (
                <>
                    <Typography variant="regular" color="primary.00">
                        Seleccione la nueva tienda disponible desde donde se realizará el&nbsp;
                        {renderDeliveryMethod[changeStoreData.warehouseType] !== undefined
                            ? renderDeliveryMethod[changeStoreData.warehouseType]?.toLowerCase()
                            : renderDeliveryMethod[order?.delivery_method]?.toLowerCase()}
                    </Typography>
                    <br />
                </>
            )}
            <SearchInput
                placeholder="Ingrese nombre de sucursal"
                onChange={(e) => setSearchTerms(e?.target?.value)}
                value={searchTerms}
                maxWidth="800px"
            />
            <Form form={form} name="select-store-form">
                <AntdTable
                    loading={loadingLocations || loadingShops}
                    rowKey="_id"
                    dataSource={data}
                    removeHeaderBackground
                    columns={columns}
                    disableRowColor
                    emptyText="No se encontraron tiendas con el stock disponible requerido para abastecer el pedido"
                    {...(changeStoreData?.warehouseType !== 'all'
                        ? {}
                        : {
                              expandable: {
                                  expandIcon: ({ expanded, onExpand, record }) =>
                                      expanded ? (
                                          <BsChevronUp
                                              size={22}
                                              color="#2D3D76"
                                              onClick={(e) => onExpand(record, e)}
                                          />
                                      ) : (
                                          <BsChevronDown
                                              size={22}
                                              color="#2D3D76"
                                              onClick={(e) => onExpand(record, e)}
                                          />
                                      ),
                                  expandedRowRender: (loc) => {
                                      return (
                                          <Flex direction="column">
                                              <Form.Item
                                                  name="delivery_method"
                                                  style={{ margin: 4, background: 'transparent' }}
                                              >
                                                  <Radio.Group
                                                      onChange={(e) =>
                                                          handleSelectLocation(loc, e.target.value)
                                                      }
                                                  >
                                                      <Space direction="vertical">
                                                          {loc.functions?.map((func) => {
                                                              return (
                                                                  <Radio
                                                                      key={func}
                                                                      value={`${loc._id}_${func}`}
                                                                  >
                                                                      <Tag color="info">
                                                                          {
                                                                              renderDeliveryMethod[
                                                                                  func
                                                                              ]
                                                                          }
                                                                      </Tag>
                                                                  </Radio>
                                                              );
                                                          })}
                                                      </Space>
                                                  </Radio.Group>
                                              </Form.Item>
                                          </Flex>
                                      );
                                  },
                              },
                          })}
                />
            </Form>
            <br />
            <Flex direction="row" wrap width="100%" columnGap="0.8rem" justifyContent="end">
                <Button
                    type="primary"
                    variation="secondary"
                    ghost
                    onClick={handleBackStep}
                    disabled={currentStepForComeback === 0}
                >
                    Volver
                </Button>
                <Button
                    type="primary"
                    onClick={handleCreateChangeStore}
                    disabled={!changeStoreData.location || !changeStoreData.delivery_method}
                >
                    Confirmar
                </Button>
            </Flex>
        </Flex>
    );
};

export default WarehousesList;
