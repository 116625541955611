import { isML } from 'helpers/isML';

export const FormFilters = {
    FIELD: 'field',
    CONDITION: 'condition',
    VALUE: 'value',
    AND_OR: 'and_or',
};

export const PositionFilters = {
    FIELD: 0,
    CONDITION: 1,
    VALUE: 2,
    AND_OR: 3,
};

const userLogged = JSON.parse(localStorage.getItem('userLogged'));
const isMascotasLatinas = isML(userLogged?.merchant_id?._id);

export const fieldsValues = [
    {
        label: 'Canal de venta',
        value: 'sales_channel',
    },
    {
        label: 'Cliente',
        value: 'customer',
    },
    {
        label: 'Código de bulto',
        value: 'package_number',
    },
    {
        label: 'Courier',
        value: 'assigned_courier',
    },
    {
        label: 'Dirección de entrega',
        value: 'shipping_address',
    },
    {
        label: 'Doc. de identidad',
        value: 'document',
    },
    {
        value: 'locations.destination',
        label: 'Tienda de destino',
    },
    {
        label: 'EAN',
        value: 'ean',
    },
    {
        label: 'Estado',
        value: 'fulfillment_status',
    },
    {
        label: 'Folio',
        value: 'search_references',
    },
    // {
    //     label: 'Fulfillments',
    //     value: 'fulfillments',
    // },
    {
        label: 'ID Ecommerce',
        value: 'remote_order_id',
    },
    {
        label: 'ID Producto',
        value: 'remote_variant_id',
    },
    {
        label: 'Nombre de Producto',
        value: 'line_items_product_name',
    },
    {
        label: 'Pedido',
        value: 'name',
    },
    ...(!isMascotasLatinas
        ? [
              {
                  label: 'Plazo límite (h)',
                  value: 'promised_date',
              },
          ]
        : []),
    ...(isMascotasLatinas
        ? [
              {
                  label: 'Promesa de entrega',
                  value: 'custom_promised_date',
              },
              {
                  label: 'Horario de entrega',
                  value: 'promised_date_range',
              },
          ]
        : []),
    {
        label: 'SKU',
        value: 'sku',
    },
    {
        label: 'Reference ID',
        value: 'reference_id',
    },
    // {
    //     label: 'Tienda',
    //     value: 'location_id',
    // },
    {
        label: 'Tipo de entrega',
        value: 'delivery_method',
    },
    {
        label: 'Total',
        value: 'total_price',
    },
    // {
    //     label: 'Unidades',
    //     value: 'line_items',
    // },
];

export const conditions = [
    {
        value: 'is',
        label: 'Es',
    },
    {
        value: 'is_not',
        label: 'No es',
    },
    {
        value: 'includes',
        label: 'Incluye',
    },
    {
        value: 'not_include',
        label: 'No incluye',
    },
];

export const fulfillmentStatusOptions = [
    {
        label: 'Archivado',
        value: 'archived',
    },
    {
        label: 'Pendiente',
        value: 'unfulfilled',
    },
    {
        label: 'En proceso',
        value: 'in_progress',
    },
    {
        label: 'Parcialmente preparado',
        value: 'partially_fulfilled',
    },
    {
        label: 'Listo para enviar',
        value: 'fulfilled',
    },
    {
        label: 'Entregado',
        value: 'delivered',
    },
    {
        label: 'Despachado',
        value: 'delivered_to_courier',
    },
    {
        label: 'Listo para retiro',
        value: 'received_in_pickup_location',
    },
    {
        label: 'Despachado a tienda',
        value: 'delivered_to_pickup_location',
    },
    {
        label: 'Cancelado',
        value: 'cancelled',
    },
    {
        label: 'SAC',
        value: 'sac',
    },
];

export const deliveryMethodOptions = [
    { label: 'Retiro', value: 'pickup' },
    { label: 'Retiro con consolidación', value: 'pickup_consolidation' },
    { label: 'Despacho', value: 'shipping' },
    { label: 'Despacho con consolidación', value: 'shipping_consolidation' },
    { label: 'Mixto', value: 'mixed' },
    { label: 'Retiro en punto de tercero', value: 'pickup_point' },
];

export const andOrOptions = [
    {
        label: 'Y',
        value: 'and',
    },
    {
        label: 'O',
        value: 'or',
    },
];

export const sorters = {
    desc: 'descend',
    asc: 'ascend',
};

export const getFormFilters = () => {
    return [
        {
            name: FormFilters.FIELD,
            label: 'Campo:',
            placeholder: 'Seleccionar',
            options: fieldsValues,
            colSpan: 7,
        },
        {
            name: FormFilters.CONDITION,
            label: 'Condición:',
            placeholder: 'Seleccionar',
            options: conditions,
            disabled: true,
            colSpan: 6,
        },
        {
            name: FormFilters.VALUE,
            label: 'Valor:',
            placeholder: 'Seleccionar',
            options: [],
            disabled: true,
            colSpan: 6,
        },
    ];
};
