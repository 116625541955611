import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
// import printJS from 'print-js';
import { fetchBatchFulfillments } from './getBatchFulfillments';
import { toggleModal } from '../Utils';
import { fulfillmentsApi } from './index';
import history from '../../../helpers/history';

export const batchDeliverFulfillments = createAsyncThunk(
    'fulfillments/batch/deliver',
    async (data, state) => {
        try {
            const { fulfillment_ids } = data;

            const promise = AndesClient.post(`/fulfillments/batch/deliver`, {
                fulfillment_ids,
            });

            state.dispatch(toggleModal('packages-details-drawer'));

            toast.promise(promise, {
                loading: <b>Procesando entrega de pedidos..</b>,
                success: <b>Pedidos entregados con éxito</b>,
                error: <b>Ocurrió un error al entregar el pedido.</b>,
            });

            const result = await promise;

            if (result.status === 200) {
                state.dispatch(fetchBatchFulfillments());
                state.dispatch(
                    fulfillmentsApi.endpoints.getSuccessFulfillments.initiate(undefined, {
                        forceRefetch: true,
                    })
                );
                // printJS(pdf);
                history.push('/deliveries');
            }

            return state.fulfillWithValue(true);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const batchDeliverFulfillmentsSlice = createSlice({
    name: 'batchDeliverFulfillments',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [batchDeliverFulfillments.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [batchDeliverFulfillments.fulfilled]: (state) => {
            return {
                ...state,
                success: true,
                loading: false,
            };
        },
        [batchDeliverFulfillments.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});
export default batchDeliverFulfillmentsSlice.reducer;
