import { Select } from 'antd';
import styled from 'styled-components';

export const ToolBarStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(28, 1fr);
    grid-gap: 0.5rem;

    .deliveriesSearchInput {
        grid-column: span 4 !important;
    }

    .ant-form-item {
        grid-column: span 3;
    }
`;

export const DeliveryMethodButton = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: 500;
    color: #0a6a77;
    padding: 0.5rem;
    border-bottom: 2px solid #8fb1b9;
    margin-right: 1rem;

    svg {
        margin-right: 0.5rem;
        width: 24px;
        height: 24px;
    }

    &:last-child {
        margin-right: 0rem;
    }

    &.active {
        background-color: #dce5ec;
        font-weight: 600;
    }
`;

export const ModalBody = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 15px;

    .div1 {
        grid-area: 1 / 1 / 2 / 6;
    }
    .div2 {
        grid-area: 2 / 1 / 3 / 6;
    }
    .div3 {
        grid-area: 3 / 1 / 4 / 6;
    }
`;

export const ModalSelectStyled = styled(Select)`
    width: 100%;
    border: 1.5px solid #7998a7;
    border-radius: 3px;
`;

export const TitleModal = styled.div`
    display: flex;
    text-align: left;
    column-gap: 10px;
    span {
        color: #2d3d76;
        font-size: 18px;
    }
`;
