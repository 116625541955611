import { Tag, Typography } from 'antd';
import styled from 'styled-components';

export const ManifestPageContent = styled.div`
    margin-top: 45px;
`;

export const ExtraSectionStyled = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
`;

export const TagStyled = styled(Tag)`
    border: 1px solid rgb(45, 61, 118);
    background-color: rgb(245, 245, 245);
    color: rgb(83, 109, 143);
    border-radius: 5rem;
    :last-child {
        margin-right: 0px;
    }
`;

export const TagCircle = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: ${({ bgColor }) => bgColor};
`;
export const CardTop = styled.div`
    border: 1px solid #f5f5f5;
    padding: 0.5rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const CardTable = styled.div`
    border: 1px solid #f5f5f5;
    border-radius: 0px;
    transition: 0.2s;
    margin-top: 2.5rem;
    :hover {
        border: 1px solid #7998a7;
    }
`;

export const TableHeader = styled.div`
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    background-color: #fafafa;
    padding: 1rem;
    gap: 2rem;
`;

export const InfoLabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 70px;
`;

export const InfoLabel = styled.div`
    display: flex;
    font-size: ${({ fz }) => fz || '16px'};
`;

export const InfoValue = styled.span`
    color: #2d3d76;
`;

export const InfoTitle = styled.span`
    color: #2d3d76;
    font-weight: bold;
    margin-right: 4px;
`;

export const TypographyUnderLine = styled(Typography.Text)`
    text-decoration: underline;
    color: #037fb9;
`;
