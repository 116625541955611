import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getAllPermissions = createAsyncThunk('permissions/getAll', async (state) => {
    try {
        const response = await AndesClient.get(`/permissions`);
        return response.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getAllPermissionsSlice = createSlice({
    name: 'getAllPermissions',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [getAllPermissions.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getAllPermissions.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
                success: true,
            };
        },
        [getAllPermissions.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
                success: false,
            };
        },
    },
});

export default getAllPermissionsSlice.reducer;
