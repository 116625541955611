import styled from "styled-components";
import {Link} from 'react-router-dom'
export const PageHeaderContainer = styled.div`
    width: 100%;
`

export const BackButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background: white;
    width: 40px;
    height: 40px;
    margin-right: 1rem;
    cursor: pointer;
    border: 1px solid #2d3d76;
    align-self: flex-start;
    svg {
        width: 32px;
        height: 32px;
    }
`;

export const PageTitleContainer = styled.div`
    display: flex;
    align-items: center;

`
export const PageTitleSection = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;
`

export const PageSubTitle = styled.span` 
    color: #1F6373;
`
export const ExtraSection = styled.div`
    display: flex;
    width: 100%;
`

export const BreadCrumbsContainer = styled.div`
    display: flex;
    font-size: 14px;
`

export const BreadCrumbsLink = styled(Link)`
    color: #7998A7;
    transition: .2s;
    :hover {
        color: #536D8F;
    }
    :last-child{
        color: #536D8F;
        > span {
            :last-child{
                display: none;
            }
        }
    }
`