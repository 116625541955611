import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const courierExceptionsApi = createApi({
    reducerPath: 'courier_exceptions',
    baseQuery,
    tagTypes: ['courier_exceptions'],
    endpoints: (builder) => ({
        getCourierExceptions: builder.query({
            method: 'get',
            query: () => `settings/couriers/exceptions`,
            providesTags: ['courier_exceptions'],
        }),
        createCourierException: builder.mutation({
            method: 'post',
            query: (body) => ({
                url: `settings/couriers/exceptions`,
                method: 'post',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando excepcion de operador logistico...</b>,
                    success: <b>Excepcion creada con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['courier_exceptions'],
        }),
        updateCourierException: builder.mutation({
            method: 'put',
            query: (body) => {
                const { _id, ...newValues } = body;
                return {
                    url: `settings/couriers/exceptions/${_id}`,
                    method: 'put',
                    body: newValues,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando excepcion de operador logistico...</b>,
                    success: <b>Excepcion actualizada con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['courier_exceptions'],
        }),
        deleteCourierException: builder.mutation({
            method: 'delete',
            query: (id) => ({
                url: `settings/couriers/exceptions/${id}`,
                method: 'delete',
                body: { id },
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Eliminando excepcion de operador logistico...</b>,
                    success: <b>Excepcion eliminada con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['courier_exceptions'],
        }),
        exceptionsFilters: builder.query({
            method: 'get',
            query: (body) => {
                const queryString = Object.keys(body)
                    .map((key) => `${key}=${body[key]}`)
                    .join('&');
                return {
                    url: `settings/couriers/exceptions?${queryString}`,
                    method: 'get',
                };
            },

            invalidatesTags: ['courier_exceptions'],
        }),
    }),
});

export const {
    useGetCourierExceptionsQuery,
    useCreateCourierExceptionMutation,
    useUpdateCourierExceptionMutation,
    useDeleteCourierExceptionMutation,
    useLazyExceptionsFiltersQuery,
} = courierExceptionsApi;
