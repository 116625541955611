import Flex from 'components/Utils/Flex';
import { PageActions } from '../../styles';
import { Alert, Button, /* Popconfirm, */ Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import { isObjectEmpty } from 'helpers/filterObjectByCustom';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { addToPackage } from 'redux/features/Packages/addToPackage';
import NormalButton from 'components/Buttons/NormalButton';
import { completeFulfillment } from 'redux/features/Fulfillments/completeFulfillment';

import usePreparationList from 'hooks/usePreparationList';
// import history from 'helpers/history';
import { ArchiveIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import OrderDetails from './OrderDetails';
import { ModalAddPackageCode } from './ModalAddPackageCode';
import { toggleModal } from 'redux/features/Utils';
import { Fragment } from 'react';
import ModalWithButton from 'components/ModalWithButton';

export default function PreparationToolbar(props) {
    const {
        fulfillment_id,
        fulfillment,
        blockPreparation = false,
        isOpenBtnDisabled = true,
    } = props;
    const [inputValue, setInputValue] = useState('');
    const [openModal, setOpenModal] = useState([]);
    const [productsFound, setproductsFound] = useState([]);
    const dispatch = useDispatch();
    const { flow } = useParams();

    const { handleNextPreparation, hasNext } = usePreparationList(fulfillment_id);
    const { loading } = useSelector((state) => state.addToPackage);
    const modalName = `add-package-code-modal`;

    const packages = Array.from(fulfillment.packages ?? []);

    const handleCompleteFulfillment = async () => {
        dispatch(
            completeFulfillment({
                order_id: fulfillment.order._id,
                fulfillment_id,
            })
        );
    };

    useEffect(() => {
        if (flow === 'multiple') {
            if (hasNext() && fulfillment.status === 'success') {
                handleNextPreparation();
            }
        }
    }, [fulfillment, flow]);

    const clearPackageInput = () => {
        setInputValue('');
    };

    useEffect(() => {
        // Definir si se repiten o no los sku de los productos para abrir o no el modal
        let codesToRepeat = fulfillment.line_items.map((code) => code?.sku);
        const arrayRepeat = codesToRepeat.filter(function (item, index, array) {
            return array.indexOf(item) !== index;
        });
        setOpenModal(arrayRepeat);
    }, [fulfillment]);

    const validations = () => {
        if (!inputValue) {
            toast.error('Debes escanear un producto');
            return;
        }

        if (loading) {
            toast.error('Ya se esta escaneando un producto...');
            return;
        }

        const inputText = inputValue.trim();

        let product = {};
        product =
            fulfillment.line_items.find((li) => li.line_item['ean'] === inputText) ??
            fulfillment.line_items.find((li) => li.line_item['sku'] === inputText) ??
            fulfillment.line_items.find((li) => li.line_item['remote_variant_id'] === inputText) ??
            {};

        if (!product || isObjectEmpty(product)) {
            return toast.error('Error: El código digitado no existe');
        }

        const packageOpen =
            Array.from(fulfillment.packages ?? []).find((pk) => pk.status === 'open') || {};

        if (isObjectEmpty(packageOpen)) {
            toast.error('Error: No hay bultos abiertos');
            return;
        }

        return { product, packageOpen };
    };

    const handleAddToPackage = () => {
        const datos = validations();
        if (datos?.product && datos?.packageOpen) {
            const { product, packageOpen } = datos;
            if (openModal?.length === 0 || !openModal.includes(inputValue)) {
                // Esta validación solo aplica para escanear items agregados por eso esta fuera de validations
                const total_quantity_in_packages = packages.reduce((acc, pk) => {
                    const total_in_package = pk.line_items.reduce((acc2, li) => {
                        if (li.line_item._id === product.line_item._id) {
                            acc2 += li.quantity;
                        }
                        return acc2;
                    }, 0);
                    acc += total_in_package;
                    return acc;
                }, 0);

                if (total_quantity_in_packages >= product.quantity) {
                    toast.error('Este producto ya fué escaneado completamente.');
                    return;
                }
                dispatch(
                    addToPackage({
                        package_id: packageOpen._id,
                        fulfillment_id,
                        line_item: {
                            quantity: 1,
                            id: product.line_item._id,
                        },
                    })
                );
                clearPackageInput();
            } else {
                // Validacion que sirve para items desagregados cuando los productos ya fueron escaneados
                const scanedRepeated = fulfillment?.line_items?.reduce((acc, curr) => {
                    if (curr?.line_item?.sku === inputValue) {
                        return curr?.line_item?.picking_quantity + acc;
                    }
                    return acc;
                }, 0);
                const maxRepeated = fulfillment?.line_items?.reduce((acc, curr) => {
                    if (curr?.line_item?.sku === inputValue) {
                        return curr?.quantity + acc;
                    }
                    return acc;
                }, 0);
                if (scanedRepeated >= maxRepeated) {
                    toast.error('Este producto ya fué escaneado completamente.');
                    return;
                }
                dispatch(toggleModal(modalName));
            }
        }
    };

    const totally_scanned = fulfillment.line_items.reduce((acc, li) => {
        const total_in_package = packages.reduce((acc2, pk) => {
            const total_in_package = pk.line_items.reduce((acc3, li2) => {
                if (li2.line_item?._id === li.line_item?._id) {
                    acc3 += li2.quantity;
                }
                return acc3;
            }, 0);
            return acc2 + total_in_package;
        }, 0);
        return acc + total_in_package;
    }, 0);

    const totally_quantity = fulfillment.line_items.reduce((acc, li) => {
        return acc + li.quantity;
    }, 0);

    const is_totally_scanned = totally_scanned >= totally_quantity;

    const disableButtons =
        blockPreparation ||
        loading ||
        ['paused', 'success', 'cancelled', 'delivered', 'delivered_to_courier'].includes(
            fulfillment.status
        );

    return (
        <>
            <ModalAddPackageCode
                productsFound={productsFound}
                setproductsFound={setproductsFound}
                clearPackageInput={clearPackageInput}
                fulfillment={fulfillment}
                modalName={modalName}
                inputValue={inputValue}
            />

            <OrderDetails fulfillment={fulfillment} />

            <PageActions>
                {props.loading ? (
                    <Skeleton
                        paragraph={{
                            rows: 1,
                        }}
                    />
                ) : (
                    <Flex direction="column" justifyContent="end" alignItems="end">
                        <Flex
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Flex
                                flex="1"
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <div>
                                    {isOpenBtnDisabled && (
                                        <Alert
                                            message="Sólo puedes tener 1 bulto abierto a la vez"
                                            description=""
                                            type="info"
                                            showIcon
                                        />
                                    )}
                                </div>

                                <NormalButton
                                    icon={<ArchiveIcon width="16px" height="16px" />}
                                    style={{
                                        fontWeight: 'bold !important',
                                        width: 'fit-content',

                                        fontSize: '14px',
                                    }}
                                    buttonText="Enviar a entregas"
                                    type="primary"
                                    disabled={
                                        blockPreparation ||
                                        !packages.every(
                                            (pkg) =>
                                                pkg.status === 'pending_for_processing' ||
                                                pkg.status === 'closed' ||
                                                pkg.status === 'processed'
                                        ) ||
                                        !packages ||
                                        packages.length === 0 ||
                                        !is_totally_scanned ||
                                        [
                                            'paused',
                                            'cancelled',
                                            'success',
                                            'delivered',
                                            'delivered_to_courier',
                                        ].includes(fulfillment.status)
                                    }
                                    onClick={() =>
                                        dispatch(toggleModal('send-to-deliveries-modal'))
                                    }
                                />
                            </Flex>

                            <Flex flex="1" paddingLeft="1rem">
                                <input
                                    placeholder="Copie y pegue el código"
                                    className="preparation__search-input"
                                    disabled={disableButtons}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleAddToPackage();
                                            clearPackageInput();
                                        }
                                    }}
                                    value={inputValue}
                                    style={{ height: '40px' }}
                                />

                                <Button
                                    onClick={handleAddToPackage}
                                    type="primary"
                                    disabled={disableButtons}
                                    style={{
                                        borderTopLeftRadius: '0px',
                                        borderBottomLeftRadius: '0px',
                                    }}
                                >
                                    Ingresar
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                )}
            </PageActions>
            <Fragment>
                <ModalWithButton
                    onOk={handleCompleteFulfillment}
                    modalProps={{
                        cancelText: 'Cancelar',
                        okText: 'Confirmar',
                        title: <span style={{ color: '#2D3D76' }}>Enviar bulto a entregas</span>,
                        name: 'send-to-deliveries-modal',
                    }}
                >
                    <b style={{ color: '#B00303' }}>
                        ¿Confirmas el envío de este bulto a entregas?
                    </b>
                </ModalWithButton>
            </Fragment>
        </>
    );
}
