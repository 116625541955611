import Flex from 'components/Utils/Flex';
import { renderFulFillmentTagSLA } from 'helpers/renderTags';

const FulfillmentDeadline = ({
    row,
    isDeadline,
    isLogistic = false,
    isFulfillment = false,
    logistic = {},
    fulfillment = {},
}) => {
    const promisedDate = isFulfillment
        ? fulfillment?.promised_date
        : isLogistic
        ? logistic?.promised_date
        : row?.promised_date;

    const deliveryDate = isFulfillment
        ? fulfillment?.delivery_date
        : isLogistic
        ? logistic?.delivery_date
        : row?.delivery_date;

    return (
        <Flex direction="row" alignItems="center" columnGap="10px">
            {renderFulFillmentTagSLA({
                status: row?.fulfillment_status ?? '',
                createdAt: row?.createdAt ?? '',
                promised_date: promisedDate ?? '',
                delivery_date: deliveryDate ?? '',
                showDate: true,
                showStatus: false,
                isDeadline: isDeadline ?? false,
                deadline: row?.deadline_custom ?? false,
            })}
        </Flex>
    );
};

export default FulfillmentDeadline;
