export const crossdockingFilterFields = [
    {
        label: 'ID Ecommerce',
        value: 'remote_order_id',
    },
    {
        label: 'Tipo de entrega',
        value: 'delivery_method',
    },
    {
        label: 'Cliente',
        value: 'customer',
    },
    {
        label: 'Estado de entrega',
        value: 'fulfillment_status',
    },
    {
        label: 'Courier',
        value: 'assigned_courier',
    },
    {
        label: 'Horario de entrega',
        value: 'promised_date_range',
    },
];
