//import { ReactComponent as SaveIcon } from 'assets/save-icon.svg';
//import { Button } from 'antd';

import { Button, Modal, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import TCard from 'components/TCard';
import Flex from 'components/Utils/Flex';
import { ScannedInput } from '../styles';
import Tag from 'components/Tag';
import { useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { proccesPackage } from 'redux/features/Packages/processPackage';
import ProcessDeliveryModal from './ProcessDeliveryModal';
import toast from 'react-hot-toast';
import { TitleModal } from 'pages/Deliveries/components/DeliveriesDataTable/styles';
import { ReactComponent as IconAlert } from 'assets/same-package-code.svg';
import { ModalSamePackageCode } from 'pages/Deliveries/components/DeliveriesDataTable/ModalSamePackageCode';
// import { toggleModal } from 'redux/features/Utils';

const PackagesToolbar = ({ fulfillments = [], loading }) => {
    const [inputValue, setInputValue] = useState('');
    const [codesFound, setCodesFound] = useState([]);
    const [inputIsInCodesFound, setInputIsInCodesFound] = useState(false);
    const [fulToProcess, setFulToProcess] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const dispatch = useDispatch();

    const scannedInputRef = useRef(null);

    useEffect(() => {
        const codesFound = fulfillments?.reduce((acc, curr) => {
            const codes = curr?.packages?.map((pkg) => pkg?.code);
            return [...acc, ...codes];
        }, []);
        setCodesFound(codesFound);
    }, [fulfillments, inputValue]);

    useEffect(() => {
        setInputIsInCodesFound(codesFound.filter((code) => code === inputValue).length > 1);
    }, [inputValue, inputIsInCodesFound, codesFound]);

    const handleConfirModalSamePackageCode = () => {
        let code = inputValue.trim();
        dispatch(
            proccesPackage({
                code,
                fulfillment_id: fulToProcess,
            })
        );
        setInputValue('');
        setInputIsInCodesFound(false);
    };

    const handleScan = () => {
        if (scannedInputRef.current) {
            scannedInputRef.current.focus();
        }
    };

    useEffect(() => {
        if (scannedInputRef.current) {
            scannedInputRef.current.focus();
        }
        window.addEventListener('keydown', handleScan);
        return () => {
            window.removeEventListener('keydown', handleScan);
        };
    }, []);

    const scroll = (id) => {
        const section = document.querySelector(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            section.classList.add('alerts-border');
            setTimeout(() => {
                section.classList.remove('alerts-border');
            }, 3000);
        }
    };

    const handleProcessPackage = () => {
        let code = inputValue.trim();

        const isRemoteOrderId = fulfillments.some(
            (fulfillment) => fulfillment.remote_order_id === code
        );

        setInputValue('');

        if (isRemoteOrderId) {
            const fulfillmentWithRemoteOrderId =
                fulfillments.find((fulfillment) => fulfillment.remote_order_id === code) ?? {};
            if (Array.from(fulfillmentWithRemoteOrderId?.packages ?? []).length > 1) {
                return toast.error(
                    'Ha escaneado un código de orden que posee más de un bulto pendiente de entrega. Se requiere acción manual'
                );
            } else {
                code = fulfillmentWithRemoteOrderId?.packages[0]?.code;
            }
        }

        const fulfillment =
            fulfillments.find((fulfillment) =>
                fulfillment.packages.some((pkg) => pkg.code === code)
            ) || {};

        if (!fulfillment._id) {
            return toast.error(
                'El código de bulto introducido no pertenece a ninguna de las órdenes seleccionas para entrega'
            );
        }

        if (fulfillment._id) {
            const pkg = fulfillment.packages.find((pkg) => pkg.code === code);
            if (pkg.status === 'processed') {
                return toast.error('Este codigo de bulto ya fue escaneado');
            }
        }

        const parsed_fulfillment_name = String(fulfillment?.name ?? '').replace(/#/g, '');

        scroll(`#packages-table-${parsed_fulfillment_name}`);

        if (inputIsInCodesFound) {
            setOpenModal(true);
        } else {
            dispatch(
                proccesPackage({
                    code,
                    fulfillment_id: fulfillment._id,
                })
            );
        }
    };

    const sumPackages = (fulfillments) => {
        return fulfillments.reduce((acc, ele) => {
            acc += ele.packages.length;
            return acc;
        }, 0);
    };

    const sumProcessedPackages = (fulfillments) => {
        return fulfillments.reduce((acc, ele) => {
            ele.packages.map((pkg) => {
                if (pkg.status === 'processed') {
                    acc += 1;
                }
            });
            return acc;
        }, 0);
    };

    const allPackagesProcessed = sumPackages(fulfillments) === sumProcessedPackages(fulfillments);
    const isSomeFulFillmentPartiallyProcessed = fulfillments.some(
        (fulfillment) => fulfillment.status === 'partially_processed'
    );

    const isSomeFulFillmentsAllPackagesProcessed = fulfillments.some((fulfillment) =>
        ['all_packages_processed'].includes(fulfillment.status)
    );

    return (
        <>
            <TCard
                paddingless
                customStyle={{
                    position: 'sticky',
                    top: '10px',
                    zIndex: 99,
                    boxShadow:
                        '0 15px 20px -15px rgba(0,0,0,0.1),0 10px 10px -10px rgba(0,0,0,0.1)',
                    margin: '1rem',
                }}
            >
                <Modal
                    title={
                        <TitleModal>
                            <IconAlert />
                            <span>Mismo código de bulto</span>
                        </TitleModal>
                    }
                    modalName="same-package-code-delivery-modal"
                    cancelText="Cancelar"
                    okText="Confirmar"
                    // open={inputIsInCodesFound}
                    visible={openModal}
                    onOk={() => handleConfirModalSamePackageCode()}
                    destroyOnClose={true}
                    onCancel={() => {
                        setInputValue('');
                        setOpenModal(false);
                    }}
                >
                    <ModalSamePackageCode
                        inputValue={inputValue}
                        setFulToProcess={setFulToProcess}
                        fulfillments={fulfillments}
                        name={'same-package-code-delivery-modal'}
                    />
                </Modal>
                <Flex
                    direction="row"
                    columnGap="5px"
                    padding="12px"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <ScannedInput>
                        <input
                            ref={scannedInputRef}
                            placeholder="Ingrese código de bulto"
                            onChange={(e) => setInputValue(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleProcessPackage();
                                    setInputValue('');
                                }
                            }}
                        />
                        <Button
                            onClick={handleProcessPackage}
                            style={{
                                color: '#0A6A77',
                                border: '1px solid #0A6A77',
                                fontWeight: 500,
                            }}
                        >
                            Ingresar
                        </Button>
                        <Tooltip
                            className="ml-2 cursor-pointer"
                            title={
                                'Escanea o ingresa el código de bulto a entregar y presiona Enter'
                            }
                        >
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </ScannedInput>

                    <div>
                        <Flex direction="row" columnGap="1rem" alignItems={'center'}>
                            <Flex direction="column" alignItems="center" justifyContent="center">
                                <Tag color={allPackagesProcessed ? 'success' : 'warning'}>
                                    <b style={{ fontSize: '0.9rem' }}>
                                        {sumProcessedPackages(fulfillments)}/
                                        {sumPackages(fulfillments)} Bultos procesados
                                    </b>
                                </Tag>
                                <small style={{ fontSize: '1rem' }}>
                                    Total de bultos susceptibles de entrega:{' '}
                                    {sumPackages(fulfillments)}
                                </small>
                            </Flex>
                            <ProcessDeliveryModal
                                fulfillments={fulfillments}
                                disabled={
                                    !isSomeFulFillmentsAllPackagesProcessed ||
                                    isSomeFulFillmentPartiallyProcessed
                                }
                                loading={loading}
                            />
                            <Tooltip
                                className="cursor-pointer"
                                title={
                                    'Cuando todos los bultos estén escaneados se activará este botón que procesará a entrega todos los pedidos.'
                                }
                            >
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </Flex>
                    </div>
                </Flex>
            </TCard>
        </>
    );
};

export default PackagesToolbar;
