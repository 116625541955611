import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const metafieldsApi = createApi({
    reducerPath: 'metafields',
    baseQuery,
    tagTypes: ['metafields'],
    endpoints: (builder) => ({
        getMetafields: builder.query({
            method: 'get',
            query: (target_id) => `/settings/metafields?target_id=${target_id}`,
            providesTags: ['metafields'],
        }),
        createMetafield: builder.mutation({
            method: 'post',
            query: (body) => ({
                url: `/settings/metafields`,
                method: 'post',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando metafield...</b>,
                    success: <b>Metafield creado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['metafields'],
        }),
        updateMetafield: builder.mutation({
            method: 'put',
            query: (body) => ({
                url: `/settings/metafields/${body._id}`,
                method: 'put',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando metafield...</b>,
                    success: <b>Metafield actualizado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['metafields'],
        }),
        deleteMetafield: builder.mutation({
            method: 'delete',
            query: (id) => ({
                url: `/settings/metafields/${id}`,
                method: 'delete',
                body: {},
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Eliminando metafield...</b>,
                    success: <b>Metafield eliminado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['metafields'],
        }),
    }),
});

export const {
    useGetMetafieldsQuery,
    useCreateMetafieldMutation,
    useUpdateMetafieldMutation,
    useDeleteMetafieldMutation,
} = metafieldsApi;
