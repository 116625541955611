export const crossdockingDeliveryMethodOptions = [
    {
        label: 'Retiro',
        value: 'pickup',
    },
    { label: 'Retiro con consolidación', value: 'pickup_consolidation' },
    {
        label: 'Despacho',
        value: 'shipping',
    },
    { label: 'Despacho con consolidación', value: 'shipping_consolidation' },
    { label: 'Retiro en punto de tercero', value: 'pickup_point' },
];

export const fulfillmentStatusOptions = [
    {
        label: 'Pendiente',
        value: 'unfulfilled',
    },
    {
        label: 'En proceso',
        value: 'in_progress',
    },
    {
        label: 'Parcialmente preparado',
        value: 'partially_fulfilled',
    },
    {
        label: 'Listo para enviar',
        value: 'fulfilled',
    },
    {
        label: 'Entregado',
        value: 'delivered',
    },
    {
        label: 'Despachado',
        value: 'delivered_to_courier',
    },
    {
        label: 'Cancelado',
        value: 'cancelled',
    },
    {
        label: 'SAC',
        value: 'sac',
    },
];
