import { useCallback } from 'react';
import { useGetMasterStatesQuery } from 'redux/features/MasterStates';
import i18n from 'translate/i18n';

const useMasterLabels = () => {
    const { data, isLoading } = useGetMasterStatesQuery(null);

    const getLabel = useCallback(
        (str, namespace) => {
            const master = Array.from(data?.master ?? []).find(
                (state) => state?.code_status === str
            );
            let val = str;
            if (namespace) val = `${namespace}.${val}`;
            return master?.friendly_text ?? i18n.t(val) ?? str;
        },
        [data, isLoading]
    );

    return {
        getLabel,
    };
};

export default useMasterLabels;
