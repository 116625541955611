import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Avatar, Skeleton, Tooltip } from 'antd';
import Tag from 'components/Tag';
import Flex from 'components/Utils/Flex';
import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import { Link, useHistory } from 'react-router-dom';
import { BackButtonStyled } from './styles';

export default function PageHeader({
    title,
    titleExtra,
    loading,
    subtitle,
    tags = [],
    breadcrumb = [],
    showBack,
    extra,
    subFulfillmentDefaultLabel,
    titleContainerFlexDirection = 'column',
    responsables = [],
    showResponsables,
    deliveryMethodLabel = '',
    CourierLabel = '',
    withMarginBottom = true,
}) {
    const history = useHistory();
    const [, , preparationPreferences] = useGetPreparationPreference();

    const randomColors = ['#9EBCC0', '#7998A7', '#536D8F', '#2E4053'];
    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * randomColors.length);
        return randomColors[randomIndex];
    };

    return (
        <>
            {loading && <Skeleton />}
            {!loading && (
                <div className="flex flex-row no-wrap">
                    <div>
                        {breadcrumb.length > 0 && (
                            <nav className="sm:flex mb-5" aria-label="Breadcrumb">
                                <ol className="flex items-center space-x-4">
                                    {breadcrumb.map((bread, index) => (
                                        <li key={index}>
                                            <div className="flex items-center">
                                                {index > 0 ? (
                                                    <ChevronRightIcon
                                                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                                <Link
                                                    to={bread.path}
                                                    className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                                                >
                                                    {bread.name}
                                                </Link>
                                            </div>
                                        </li>
                                    ))}
                                </ol>
                            </nav>
                        )}
                        <div
                            className={`lg:flex lg:items-center lg:justify-between ${
                                withMarginBottom ? 'mb-10' : '0'
                            }`}
                        >
                            <div className="flex-1 min-w-0">
                                <div className="flex items-center flex-row mb-2">
                                    {showBack && (
                                        <BackButtonStyled onClick={() => history.goBack()}>
                                            <ChevronLeftIcon />
                                        </BackButtonStyled>
                                    )}
                                    <div className="flex flex-col">
                                        <Flex
                                            direction={titleContainerFlexDirection}
                                            rowGap={`${
                                                titleContainerFlexDirection === 'row' ? '0' : '1rem'
                                            }`}
                                        >
                                            <div className="flex items-center mt-1">
                                                <span
                                                    className="text-gray-900"
                                                    style={{ fontSize: '26px', fontWeight: '600' }}
                                                >
                                                    {title}
                                                </span>
                                                <span
                                                    className="ml-3"
                                                    style={{ color: '#1F6373', fontSize: '12px' }}
                                                >
                                                    {subtitle}
                                                </span>
                                            </div>

                                            <div
                                                className={`flex no-wrap text-gray-900 truncate text-sm items-center ${
                                                    titleContainerFlexDirection === 'row' && 'ml-10'
                                                }`}
                                            >
                                                <b>{subFulfillmentDefaultLabel}</b>
                                                <b className="ml-1">{titleExtra && titleExtra}</b>
                                            </div>
                                        </Flex>
                                        {deliveryMethodLabel && CourierLabel && (
                                            <div className="text-xs flex no-wrap items-center mt-3">
                                                <div>
                                                    <span className="mr-1">Tipo de envío</span>
                                                    <Tag
                                                        style={{
                                                            border: '1px solid',
                                                            borderColor: '#41515E',
                                                            backgroundColor: '#F5F5F5',
                                                        }}
                                                    >
                                                        {deliveryMethodLabel ?? ''}
                                                    </Tag>
                                                </div>
                                                <div>
                                                    <span className="mr-1">Courier</span>
                                                    <Tag
                                                        style={{
                                                            border: '1px solid',
                                                            borderColor: '#41515E',
                                                            backgroundColor: '#F5F5F5',
                                                        }}
                                                    >
                                                        {CourierLabel ?? ''}
                                                    </Tag>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="mt-3 flex flex-row flex-wrap">
                                    {tags.map((tag, index) => (
                                        <div
                                            key={index}
                                            className="flex mb-2 mr-2 items-center text-sm text-gray-500"
                                        >
                                            {tag}
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div>{extra && extra}</div>
                        </div>
                    </div>
                    {showResponsables &&
                        preparationPreferences?.preparations_preferences &&
                        preparationPreferences?.preparations_preferences?.required_operator && (
                            <div className="ml-10 flex items-center justify-center">
                                <h1 className="mr-3 mt-1 text-xs">Responsables: </h1>
                                {responsables[0] ? (
                                    <Avatar.Group maxCount={3}>
                                        {responsables.map((user, index) => {
                                            const fullname = `${user.firstname ?? ''} ${
                                                user.lastname ?? ''
                                            }`;
                                            const abbreviaton_fullname = `${
                                                user.firstname?.[0] ?? ''
                                            }${user.lastname?.[0] ?? ''}`;
                                            return (
                                                <Tooltip
                                                    title={fullname}
                                                    placement="top"
                                                    key={index}
                                                >
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: getRandomColor(),
                                                        }}
                                                    >
                                                        {abbreviaton_fullname.toUpperCase()}
                                                    </Avatar>
                                                </Tooltip>
                                            );
                                        })}
                                    </Avatar.Group>
                                ) : (
                                    <Tag>
                                        <span style={{ color: 'red' }}>
                                            Responsables pendientes por asignar
                                        </span>
                                    </Tag>
                                )}
                            </div>
                        )}
                </div>
            )}
        </>
    );
}
