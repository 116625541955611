import React from 'react';
import { BiSearch } from 'react-icons/bi';
import { SearchInputWrapper, SearchIconWrapper } from './styles';

const SearchInput = React.forwardRef((props, ref /* , a */) => {
    const {
        onClickIcon = () => {},
        value = '',
        onClickIconDisabled = false,
        extraStyles = {},
    } = props;
    return (
        <SearchInputWrapper
            extraStyles={extraStyles}
            minWidth={props?.minWidth}
            maxWidth={props?.maxWidth}
        >
            <input type="search" {...props} value={value} ref={ref} />
            {/* <button onClick={props.handleClickSearchIcon && props.handleClickSearchIcon}> */}
            <SearchIconWrapper>
                <BiSearch
                    role="button"
                    onClick={() => {
                        if (!onClickIconDisabled) onClickIcon();
                    }}
                    style={{
                        opacity: onClickIconDisabled ? 0.5 : 1,
                    }}
                    disabled={onClickIconDisabled}
                />
            </SearchIconWrapper>
            {/* </button> */}
        </SearchInputWrapper>
    );
});

export default SearchInput;
