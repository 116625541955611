import { Drawer } from 'antd';
import styled from 'styled-components';

export const DrawerStyled = styled(Drawer)`
    h5,
    .ant-radio-wrapper,
    .ant-checkbox-wrapper {
        font-weight: 500;
        letter-spacing: 0.4px;
        font-size: 0.86rem;
    }
    .ant-drawer-body {
        position: relative;
    }
    .ant-drawer-left .ant-drawer-content-wrapper,
    .ant-drawer-right .ant-drawer-content-wrapper {
        height: unset;
    }

    .ant-drawer-content-wrapper {
        height: calc(100%);

        .ant-drawer-header {
            padding: 15px 15px;
        }
    }

    .ant-btn {
        font-weight: 500;
    }

    .ant-input {
        height: 40px !important;
    }

    .ant-select-selector {
        min-height: 40px;
    }

    .ant-input,
    .ant-select-selector {
        &::placeholder {
            font-size: 0.75rem;
        }
        &::disabled {
            background-color: #dce5ec;
        }
    }

    .ant-select-selection-item-content {
        line-height: 1.6rem;
    }

    .ant-select-selection-item {
        height: unset;
    }

    .ant-form-item-explain-error {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .ant-form-item {
        margin-bottom: 10px;
        width: 100%;
    }

    .ant-form-item-label {
        width: 100%;
        text-align: start;
        padding-bottom: 1px;
        label {
            font-weight: 500;
            font-size: 0.7rem !important;
            color: ${({ labelColor }) => labelColor || '#819A9F'};
        }
    }

    .ant-checkbox-wrapper {
        span {
            font-weight: 500;
            font-size: 0.7rem !important;
            color: '#819A9F' !important;
        }
    }

    .ant-input-password {
        padding: 0 11px;
    }

    .ant-typography {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .ant-drawer-title {
        font-size: 0.9rem;
        font-weight: 500;
    }

    /* DRAWER DE FILTROS */
    .filter-drawer {
        .ant-collapse-header {
            padding-left: 40px;
            font-weight: 500;
        }

        .ant-checkbox-wrapper {
            margin-left: 0 !important;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            .ant-checkbox {
                font-size: 0.78rem !important;
                border: 1px solid #1f6373;
                border-radius: 3px;
                top: 0;
            }
            span {
                font-weight: 600 !important;
            }
        }

        .item-can-be-featured {
            padding-left: 0 !important;
            .ant-collapse-header {
                padding-left: 0 !important;
                padding-bottom: 0 !important;
                color: #043540;
                font-weight: bold;
            }

            .ant-collapse-content-box {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            .ant-checkbox-group {
                margin-left: 25px;
                .ant-checkbox-wrapper {
                    margin-left: 0 !important;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;
                    .ant-checkbox {
                        font-size: 0.78rem !important;
                        border: 1px solid #1f6373;
                        border-radius: 3px;
                        top: 0;
                    }
                    span {
                        font-weight: 600 !important;
                    }
                }
            }
            .styled-checkbox {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                /* create custom checkbox appearance */
                display: inline-block;
                width: 20px;
                height: 20px;
                cursor: pointer;
                /* background-color only for content */
                background-clip: content-box;
                border: 1.5px solid #bbbbbb;
                border-radius: 50%;
                background-color: #fff;
                margin-left: 10px;
                margin-right: 10px;

                &:checked {
                    background-color: #077cdb;
                }

                &:focus {
                    outline: none !important;
                }
            }
        }
    }
    /* DRAWER DE ORDENAMIENTO */
    .sort-drawer {
        .ant-collapse-header {
            padding-left: 12px;
            font-weight: 500;
        }
        .ant-drawer-body {
            padding: 0 !important;
        }
        .ant-select-selection-item {
            display: flex;
            align-items: center;
        }
        .ant-radio-input:checked {
            background-color: #000;
        }
        .styled-checkbox {
            /* create custom checkbox appearance */
            display: inline-block;
            width: 20px;
            height: 20px;
            cursor: pointer;
            /* background-color only for content */
            background-clip: content-box;
            border: 2px solid #1f6373;
            border-radius: 50%;
            padding: 2px;
            background-color: #fff;
            margin-left: 10px;
            margin-right: 10px;
        }
        .styled-active-checkbox {
            background-color: #1f6373;
        }
        .button-asc-desc {
            border: 1px solid #dce5ec;
            border-radius: 3px;
            background-color: AHub-light-50;
            cursor: pointer;
            color: #819a9f;
            .icon-active {
                color: #043540;
            }
        }
    }
    /* DRAWER DE INCIDENCIA INDIVIDUALES ORDENES */
    .incidence-order-drawer {
        .ant-steps-item-container {
            width: 140px;
        }
        .ant-input-search-button {
            height: 40px !important;
        }
        .ant-input {
            height: unset !important;
        }
        .ant-select-selector {
            display: flex;
            align-items: center;
        }
        .ant-checkbox-wrapper {
            margin: 0;
            span {
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        .ant-checkbox-group {
            width: 100%;
        }
        /* CUSTOM STYLES */
        .styled-checkbox {
            /* create custom checkbox appearance */
            display: inline-block;
            min-width: 15px;
            min-height: 15px;
            width: 19px;
            height: 19px;
            cursor: pointer;
            /* background-color only for content */
            background-clip: content-box;
            border: 1.5px solid #000;
            border-radius: 50px;
            background-color: #fff;
            margin-left: 10px;
            margin-right: 10px;
        }
        .styled-active-checkbox {
            background-color: #077cdb;
        }
        .styled-disabled-checkbox {
            //set disabled color
            background-color: #bcbcbc;
        }
    }
    /* DRAWER DE INCIDENCIA MANUALES - PREPARACION */
    .manual-incidence-drawer {
        .ant-form-item-label {
            width: 100%;
            text-align: start;
            padding-bottom: 1px;
            label {
                font-weight: 500;
                font-size: 13px !important;
                color: #043540 !important;
            }
        }
    }
`;
/*
 */
