import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getFulfillmentById } from './getOne';
import history from 'helpers/history';
import printJS from 'print-js';
import { fulfillmentsApi } from './index';
import { toggleModal } from '../Utils';

export const completeFulfillment = createAsyncThunk(
    'fulfillments/complete',
    async (data, state) => {
        try {
            const { order_id, fulfillment_id } = data;

            const promise = AndesClient.post(
                `/fulfillments/orders/${order_id}/fulfillments/${fulfillment_id}/complete`
            );

            toast.promise(promise, {
                loading: <b>Completando fulfillment...</b>,
                success: <b>Fulfillment completado con éxito</b>,
                error: <b>Ocurrió un error al completar el fulfillment.</b>,
            });

            const result = await promise;
            let FILE = '';

            if (result.status === 200) {
                state.dispatch(getFulfillmentById(fulfillment_id));
                if (result?.data?.createdDelivery) {
                    FILE = result?.data?.createdDelivery?.label?.PDF ?? '';

                    // printJS({printable: base64, type: 'pdf', base64: true})
                    if (FILE && FILE !== '' && FILE.includes('.pdf')) {
                        const { data: ticketsBase64 } = await AndesClient.post(
                            '/fulfillments/getTicketsBase64',
                            {
                                tickets_url: FILE,
                            }
                        );

                        printJS({
                            printable: ticketsBase64,
                            type: 'pdf',
                            base64: true,
                            onPrintDialogClose: () => {
                                const { multiplePreparation } = state.getState().utils;

                                if (multiplePreparation.length === 0 || !multiplePreparation) {
                                    history.push('/fulfillments?activeTab=ready_for_deliver');
                                }
                            },
                        });
                    }

                    if (FILE.includes('.jpg') || FILE.includes('.png') || FILE.includes('.jpeg')) {
                        printJS({
                            printable: FILE,
                            type: 'image',
                            onPrintDialogClose: () => {
                                const { multiplePreparation } = state.getState().utils;
                                if (multiplePreparation.length === 0 || !multiplePreparation) {
                                    history.push('/fulfillments?activeTab=ready_for_deliver');
                                }
                            },
                        });
                    }
                }

                state.dispatch(
                    fulfillmentsApi.endpoints.getInProgressFulfillments.initiate(undefined, {
                        forceRefetch: true,
                    })
                );
                state.dispatch(
                    fulfillmentsApi.endpoints.getSuccessFulfillments.initiate(undefined, {
                        forceRefetch: true,
                    })
                );

                state.dispatch(toggleModal('send-to-deliveries-modal'));

                if (!FILE || FILE === '') {
                    const { multiplePreparation } = state.getState().utils;

                    if (multiplePreparation.length === 0 || !multiplePreparation) {
                        history.push('/fulfillments?activeTab=ready_for_deliver');
                    }
                }
            }

            return state.fulfillWithValue({
                FILE,
            });
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const completeFulfillmentSlice = createSlice({
    name: 'completeFulfillment',
    initialState: {
        loading: false,
        success: false,
        data: {},
        error: null,
    },
    reducers: {},
    extraReducers: {
        [completeFulfillment.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [completeFulfillment.fulfilled]: (state, action) => {
            return {
                ...state,
                success: true,
                data: action.payload,
                loading: false,
            };
        },
        [completeFulfillment.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default completeFulfillmentSlice.reducer;
