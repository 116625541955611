import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addUserToStore } from './getAllUsers';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getPreparersByCustom } from './getPreparersByCustom';

export const createUser = createAsyncThunk('users/create', async (data, state) => {
    try {
        const res = await toast.promise(AndesClient.post(`/users/create`, data), {
            loading: 'Creando usuario...',
            success: 'Usuario creado',
        })

        if (res.status === 200) {
            state.dispatch(addUserToStore(res.data));
            state.dispatch(getPreparersByCustom());
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const createUserSlice = createSlice({
    name: 'createUser',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createUser.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createUser.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createUser.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createUserSlice.reducer;
