import axios from 'axios';

export const downloadPdf = async (pdfUrl) => {
    const { data } = await axios.get(pdfUrl, {
        responseType: 'arraybuffer',
    });
    const d = new Uint8Array(data);
    const blob = new Blob([d], {
        type: 'application/pdf',
    });
    return URL.createObjectURL(blob);
};
