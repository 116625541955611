import { TableActions, TableStyled } from './styles';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { setSelectedRows } from 'redux/features/Utils';

const AntdTable = ({
    removeTr,
    actionsNode,
    actionsPadding,
    paginationNode,
    paginationState,
    tableName,
    multiSelect,
    disableRowColor,
    removeHeader,
    filtersDisplayed,
    checkBlue,
    setFiltersDisplayed,
    setPaginationState,
    removeHeaderBackground,
    emptyText,
    ...otherProps
}) => {
    const dispatch = useDispatch();
    const [actualSize, setActualSize] = useState(50);

    const { selectedRows } = useSelector((state) => state.utils);
    const tableSelectedRows = selectedRows[tableName];

    const rowSelection = {
        selectedRowKeys: tableSelectedRows,
        getCheckboxProps: (record) => {
            if (tableName === 'packages-table') {
                if (record.status === 'closed') {
                    return {
                        disabled: true,
                    };
                }
            }
        },
        onChange: (selectedRowKeys) =>
            dispatch(
                setSelectedRows({
                    selectedRows: selectedRowKeys,
                    tableName: tableName,
                })
            ),
    };

    useEffect(() => {
        if (paginationState) {
            setActualSize(Number(paginationState));
            setFiltersDisplayed(Number(paginationState));
        }
    }, [paginationState]);

    useEffect(() => {
        if (filtersDisplayed) {
            setActualSize(Number(filtersDisplayed));
            setPaginationState(undefined);
        }
    }, [filtersDisplayed]);

    return (
        <div style={{ maxWidth: '100%' }}>
            {actionsNode && (
                <TableActions actionsPadding={actionsPadding}>{actionsNode}</TableActions>
            )}
            <TableStyled
                pagination={{
                    defaultPageSize: 50,
                    hideOnSinglePage: true,
                    pageSize: actualSize,
                }}
                size="small"
                locale={{ emptyText }}
                removeTr={removeTr}
                removeHeader={removeHeader}
                checkBlue={checkBlue}
                removeHeaderBackground={removeHeaderBackground}
                rowClassName={(record, index) => {
                    let extraClassName = '';

                    if (tableName === 'prepare-orders-table') {
                        if (record.order_status === 'cancelled') {
                            extraClassName = 'opacity-60 line-through';
                        }
                    }

                    if (tableName === 'packages-table') {
                        if (
                            record.status === 'closed' ||
                            record.status === 'pending_for_processing'
                        ) {
                            extraClassName = 'opacity-70';
                        }
                    }

                    if (disableRowColor) {
                        return `bg-white ${extraClassName}`;
                    }

                    if (!disableRowColor) {
                        return index % 2 === 0
                            ? `bg-white ${extraClassName}`
                            : `bg-gray-50 ${extraClassName}`;
                    }
                }}
                {...(multiSelect && {
                    rowSelection,
                })}
                {...otherProps}
            />
            {paginationNode && paginationNode}
        </div>
    );
};

AntdTable.propTypes = {
    tableName: PropTypes.string,
    columns: PropTypes.array,
    dataSource: PropTypes.array,
    rowKey: PropTypes.string,
    actionsNode: PropTypes.node,
    actionsPadding: PropTypes.string,
    paginationNode: PropTypes.node,
};

export default AntdTable;
