import Select from 'components/V2/Select';
import { useGetUsersPerLocationQuery } from 'redux/features/Fulfillments';
import { useDispatch } from 'react-redux';
import { dispatchers } from 'components/V2/Filters/config';
import capitalizeStrings from 'helpers/capitalizeStrings';
import useCurrentUser from 'hooks/useCurrentUser';
import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';
import { useEffect, useMemo, useState } from 'react';

const PreparatorButton = ({ location, refetch, dispatchersType }) => {
    const dispatch = useDispatch();
    const { setFilters } = dispatchers[dispatchersType];
    const [selectedOperator, setSelectedOperator] = useState(null);
    const { userData = {} } = useCurrentUser();
    const [permissions] = useGetCurrentPermissions();
    const isPreparer = permissions?.includes('preparer');
    const hasDeliverPermission = permissions?.includes('select_responsible_and_deliver_orders');
    const isButtonDisabled = isPreparer && !hasDeliverPermission;

    const { data = [], isLoading } = useGetUsersPerLocationQuery(
        {
            origin: location,
        },
        {
            skip: isButtonDisabled,
        }
    );

    const preparers = useMemo(() => {
        const combinedData = [...data, { ...userData }];

        const uniquePreparersSet = new Set(combinedData.map((item) => item._id));

        const uniquePreparers = [...uniquePreparersSet].map((_id) =>
            combinedData.find((item) => item._id === _id)
        );

        return uniquePreparers;
    }, [data, userData]);

    useEffect(() => {
        setSelectedOperator(null);
    }, [location]);

    const handleSelectOperator = (user_id) => {
        // if (isPreparer) return;
        setSelectedOperator(user_id);
        dispatch(
            setFilters({
                assigned_operators: [user_id],
            })
        );
        refetch();
    };

    const dataPreparers = useMemo(() => {
        return preparers.filter(
            (userPerLocation) => userPerLocation.firstname && userPerLocation.lastname
        );
    }, [preparers]);

    return (
        <Select
            loading={isLoading}
            placeholder="Responsable"
            onChange={handleSelectOperator}
            value={isPreparer ? userData?._id : selectedOperator}
            disabled={isButtonDisabled}
            allowClear
            showSearch
            filterOption={(input = '', option = '') =>
                option.label.toLowerCase().includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={dataPreparers.map((userPerLocation) => {
                const capitalizedName = capitalizeStrings(
                    `${userPerLocation.firstname} ${userPerLocation.lastname}`
                );
                return {
                    value: userPerLocation._id,
                    label: capitalizedName,
                };
            })}
        />
    );
};

export default PreparatorButton;
