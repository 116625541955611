import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import AndesClient from 'services/AndesClient';

export const getFilteredOrders = createAsyncThunk('orders/findByFilters', async (name, state) => {
    const { filtersOrders, sortOrders } = state.getState();
    const { sortBy, sortValue } = sortOrders;

    try {
        const response = await AndesClient.post(`/orders/findByFilters`, {
            priority: true,
            ...(sortValue && {
                custom_sort: [sortBy, sortValue],
                priority: false,
            }),
            ...filtersOrders,
            location_id: filtersOrders?.location_id === undefined ? [] : filtersOrders?.location_id,
            name,
            ...(filtersOrders.date_range &&
                filtersOrders.date_range[0] &&
                filtersOrders.date_range[1] && {
                    date_range: [
                        moment(filtersOrders.date_range[0]).format('YYYY-MM-DD'),
                        moment(filtersOrders.date_range[1]).format('YYYY-MM-DD'),
                    ],
                }),
        });

        return response.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getFilteredOrdersSlice = createSlice({
    name: 'getFilteredOrdersSlice',
    initialState: {
        loading: false,
        data: {
            docs: [],
        },
        succes: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [getFilteredOrders.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getFilteredOrders.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                succes: true,
                error: null,
            };
        },
        [getFilteredOrders.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                succes: false,
                error: payload,
            };
        },
    },
});

export default getFilteredOrdersSlice.reducer;
