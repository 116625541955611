import styled from 'styled-components';
import { Input } from 'antd';

export default styled(Input)`
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme?.inputs?.colors?.border?.initial ?? '#DCE5EC'};
    border-radius: 3px;
    outline: none !important;
    height: 36px;

    &:active,
    &:focus {
        border-color: #2d3d76;
    }
`;

export const Password = styled(Input.Password)`
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme?.inputs?.colors?.border?.initial ?? '#DCE5EC'};
    border-radius: 3px;
    outline: none !important;
    height: 36px;

    &:active,
    &:focus {
        border-color: #2d3d76;
    }
    .ant-input {
        min-height: 0 !important;
    }
`;
