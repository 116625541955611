import { Select } from 'antd';
import styled, { css } from 'styled-components';

export const Container = styled.div`
    background-color: #fff;
    display: flex;
    border: 1px solid #e4e8ec;
    border-radius: 4px;
    flex-direction: column;
`;

export const ContainerOption = styled.div`
    height: 250px;
    width: 160px;
    padding-top: 4px;
    border-right: 1px solid #e4e8ec;
`;

export const ContainerButton = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.8rem;
    column-gap: 12px;
    border-top: 1px solid #e4e8ec;
`;

export const ActionDatePicker = styled.button`
    width: 100%;
    text-align: left;
    color: #1f2937;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    padding: 0.4rem 0.5rem;
    ${({ $fontWeight }) =>
        $fontWeight &&
        css`
            font-weight: ${$fontWeight};
        `};
    :hover {
        color: #f5f5f5;
        background: #037fb9;
    }
    ${({ $active }) =>
        $active &&
        css`
            color: #f5f5f5;
            background: #037fb9;
        `}
`;

export const SearchSelectStyled = styled(Select)`
    background-color: #ffffff;
    border: 1px solid #2d3d76;
    border-radius: 3px;
    font-weight: 500;
    height: 2.5rem;
    width: 16rem;
    ${({ display }) =>
        display &&
        css`
            display: ${display};
        `};
    .ant-select-selector,
    select {
        height: 100% !important;
        border: none !important;
        display: flex;
        align-items: center;
    }
    .ant-select-selection-item {
        font-weight: 500;
        color: #536d8f !important;
        background-color: transparent;
        border: none;
    }
    .ant-select-selection-search,
    .ant-select-selection-placeholder {
        font-size: 12px !important;
        color: #536d8f;
    }
    ${({ type }) =>
        type === 'location' &&
        `.ant-select-arrow {
            right: 4px;
            top: 48%;
        }`}
`;

export const LabelSpanStyled = styled.span`
    color: #536d8f;
    font-size: 12px;
    font-weight: 500;
    line-height: 30px;
    width: 165px;
    text-align: center;
    cursor: pointer;
`;

export const ButtonCalendar = styled.button`
    display: flex;
    align-items: center;
    border: 1px solid #2d3d76;
    background-color: #ffffff;
    padding: 0 12px;
    height: 2.5rem;
    border-radius: 3px;
    font-weight: 500;
    color: #536d8f;
    font-size: 12px;
    width: 16rem;
    justify-content: space-between;
    font-family: 'Poppins';
`;

export const ContainerHeaderCalendar = styled.div`
    background-color: #fff;
    display: flex;
    border: 1px solid #e4e8ec;
    border-radius: 4px;
    flex-direction: column;
`;

export const SelectCalendar = styled.select`
    background-color: transparent;
    border: none;
    border-radius: 0px;
    width: ${({ width }) => width};
    .ant-select-selector {
        box-shadow: none !important;
    }
    .ant-select-selection-item {
        color: #2d3d76 !important;
        font-size: 14px !important;
        font-style: normal;
    }
`;

export const LabelCalendarCustom = styled.span`
    margin-right: 1rem;
    font-size: 14px;
    color: #142c4a;
`;
