import { createSlice } from '@reduxjs/toolkit';

const filtersPreparations = createSlice({
    name: 'filtersPreparations',
    initialState: {
        delivery_method: undefined,
        status: undefined,
        courier: undefined,
        origin: undefined,
        search_by_fulfillment: '',
        featured_filters: ['delivery_method', 'status', 'courier', 'origin'],
        limit: 50,
        page: 1,
    },
    reducers: {
        changeAllFP: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        removeAllFP: () => {
            return {
                delivery_method: [],
                status: [],
                courier: [],
                origin: [],
                search_by_fulfillment: '',
                featured_filters: ['delivery_method', 'status', 'courier', 'origin'],
                limit: 50,
                page: 1,
            };
        },
        changeFeaturedFiltersFP: (state, { payload }) => {
            const { keyName, isChecked } = payload;
            const isAlreadyFeatured = state.featured_filters.includes(keyName);
            let newFeaturesArray = [];
            if (isChecked && !isAlreadyFeatured) {
                newFeaturesArray = [...state.featured_filters, keyName];
            } else {
                newFeaturesArray = state.featured_filters.filter((el) => el !== keyName);
            }
            return {
                ...state,
                featured_filters: [...newFeaturesArray],
            };
        },
    },
});
export const { changeAllFP, removeAllFP, changeFeaturedFiltersFP } = filtersPreparations.actions;
export default filtersPreparations.reducer;
