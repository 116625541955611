import { PaginationButton, PaginationContent, PaginationWrapper } from './styles';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

export const Pagination = ({ currentPage = 1, totalPage = 0, onChange }) => {
    const onClickNextPage = () => {
        const nextPage = currentPage + 1;
        if (nextPage <= totalPage) {
            onChange(nextPage);
        }
    };
    const onClickPrevPage = () => {
        const prevPage = currentPage - 1;
        if (prevPage !== 0) {
            onChange(prevPage);
        }
    };
    return (
        <PaginationWrapper>
            <PaginationButton disabled={currentPage === 1} onClick={onClickPrevPage}>
                <ChevronLeftIcon width={24} />
            </PaginationButton>
            <PaginationContent>
                {currentPage} de {totalPage}
            </PaginationContent>
            <PaginationButton disabled={currentPage === totalPage} onClick={onClickNextPage}>
                <ChevronRightIcon width={24} />
            </PaginationButton>
        </PaginationWrapper>
    );
};
