import { forwardRef } from 'react';
import { Button } from '@andescommercecloud/beflow-design-system';

const SecondaryButton = forwardRef(({ children, ...props }, ref) => {
    return (
        <Button variation="secondary" ref={ref} {...props}>
            {children}
        </Button>
    );
});

export default SecondaryButton;
