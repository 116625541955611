import { Modal } from 'antd';
import { ImagePreview, Text } from './styles';

export const ModalDeliveryInfo = ({ dataFulfillment, fulfillmentIndex, onClose, isOpen }) => {
    return (
        <Modal
            footer={null}
            open={isOpen}
            onOk={onClose}
            onCancel={onClose}
            title={
                <Text fs={16} color="#036896" fw={700}>
                    Detalle de la entrega
                </Text>
            }
        >
            <div>
                <Text color="#036896" fw={500}>
                    Fulfillment {fulfillmentIndex}:
                </Text>{' '}
                &nbsp;
                <Text color="#036896">{dataFulfillment?.name}</Text>
            </div>
            <br />
            <div>
                <Text fw={500}>Comentarios</Text> <br />
                <Text m={'10px 0px 20px 0px'} isItalic>
                    {dataFulfillment?.proof_of_delivery?.message ?? ''}
                </Text>
            </div>

            <div>
                <Text fw={500}>Foto de la entrega</Text>{' '}
            </div>

            {dataFulfillment?.proof_of_delivery?.images?.length ? (
                dataFulfillment?.proof_of_delivery?.images?.map((image, index) => (
                    <ImagePreview
                        key={index}
                        src={image ?? ''}
                        alt={`Foto-de-entrega-${dataFulfillment?.name}`}
                    />
                ))
            ) : (
                <Text m={'10px 0px 20px 0px'} isItalic>
                    Sin foto
                </Text>
            )}
            <br />
        </Modal>
    );
};
