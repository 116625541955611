import ModalWithButton from 'components/ModalWithButton';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BodyModalAddPackage } from './BodyModalAddPackage';
import { addToPackage } from 'redux/features/Packages/addToPackage';
import { toggleModal } from 'redux/features/Utils';
import toast from 'react-hot-toast';

export const ModalAddPackageCode = ({
    modalName,
    inputValue,
    fulfillment,
    clearPackageInput,
    productsFound,
    setproductsFound,
}) => {
    const dispatch = useDispatch();
    const [quantityToRepeatComponent, setQuantityToRepeatComponent] = useState([]);
    const [lineItemsToSend, setlineItemsToSend] = useState({});

    useEffect(() => {
        setproductsFound(fulfillment?.line_items?.filter((code) => code?.sku === inputValue));
    }, [fulfillment, inputValue]);

    useEffect(() => {
        // array con todos cada uno de los elementos con mismo sku que se pueden agregar al bulto . menos los que ya fueron agregados
        let newArray = [];
        productsFound?.map((p) => {
            if (p?.quantity - p?.line_item?.picking_quantity > 0)
                [...Array(Number(p?.quantity) - Number(p?.line_item?.picking_quantity))]?.forEach(
                    () => {
                        newArray?.push(p);
                    }
                );
        });
        setQuantityToRepeatComponent(newArray);
    }, [productsFound]);

    const handleSubmit = () => {
        if (Object.values(lineItemsToSend).reduce((acc, curr) => acc + curr, 0) == 0) {
            toast.error('Debe seleccionar al menos 1 item');
            return;
        }
        // limpia ids que tengan qty 0 y se parsean para tener el formato {id,quantity}
        const lineItem = Object.entries(lineItemsToSend)
            .filter((l) => l[1] !== 0)
            .map((e) => ({ id: e[0], quantity: e[1] }));

        const packageOpen = fulfillment.packages.find((pk) => pk.status === 'open') || {};

        dispatch(
            addToPackage({
                package_id: packageOpen._id,
                fulfillment_id: fulfillment?._id,
                line_item: lineItem,
            })
        );
        setlineItemsToSend({});
        dispatch(toggleModal(modalName));
        clearPackageInput();
    };

    const handleCancel = () => {
        setlineItemsToSend({});
        dispatch(toggleModal(modalName));
    };

    return (
        <div>
            <ModalWithButton
                modalProps={{
                    width: '544px',
                    name: modalName,
                    title: (
                        <span style={{ color: '#2D3D76', fontSize: '16px' }}>
                            Añadir código a bultos
                        </span>
                    ),
                    okText: 'Confirmar',
                    onCancel: handleCancel,
                    destroyOnClose: true,
                    onOk: handleSubmit,
                }}
            >
                <span>
                    Acaba de ingresar{' '}
                    {Object.values(lineItemsToSend).reduce((acc, curr) => acc + curr, 0)}/
                    {quantityToRepeatComponent?.length} códigos idénticos
                </span>
                <br />
                <span>A continuación puede agregar el otro código o quitar el actual u ambos</span>

                {quantityToRepeatComponent?.map((e, i) => (
                    <BodyModalAddPackage
                        key={i}
                        e={e}
                        initialAdded={i === 0}
                        setlineItemsToSend={setlineItemsToSend}
                        fulfillment={fulfillment}
                        inputValue={inputValue}
                    />
                ))}

                <div className="mt-4 mx-2 my-2">
                    <b style={{ fontSize: '14px', color: '#1F2937' }}>
                        ¿Desea confirmar la acción?
                    </b>
                </div>
            </ModalWithButton>
        </div>
    );
};
