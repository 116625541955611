import Tag from 'components/Tag';
import Flex from 'components/Utils/Flex';

const PickingStatus = ({ line_item_id, quantity, packages = [] }) => {
    const itemsInPackage = packages.reduce((acc, ele) => {
        const thisLineItemInPackages = ele.line_items.filter((li) => li.line_item === line_item_id);
        thisLineItemInPackages.map((li) => {
            acc += li.quantity;
        });
        return acc;
    }, 0);

    let tag = <Tag color="danger">Pendiente</Tag>;

    if (itemsInPackage >= quantity) {
        tag = <Tag color="success">Hecho</Tag>;
    }

    if (itemsInPackage > 0 && itemsInPackage < quantity) {
        tag = <Tag color="warning">En curso</Tag>;
    }

    return (
        <Flex direction="row">
            {tag}
            <span>
                {itemsInPackage}/{quantity}
            </span>
        </Flex>
    );
};

export default PickingStatus;
