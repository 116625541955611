import { ResponsablesAvatar } from '../styles';

export const AssignedOperators = ({ operator }) => {
    const randomColors = ['#9EBCC0', '#7998A7', '#536D8F', '#2E4053'];
    const getRandomColor = () => {
        const randomIndex = Math.floor(Math.random() * randomColors.length);
        return randomColors[randomIndex];
    };

    const abbreviation_full_name = `${operator.firstname?.[0] ?? ''}${
        operator.lastname?.[0] ?? ''
    }`;
    return (
        <ResponsablesAvatar className="avatar-group" backGroundColor={getRandomColor()}>
            <div
                className="avatar-group-responsable"
                data-tooltip={
                    operator.firstname + ' ' + operator.lastname
                } /* Añadir el contenido del tooltip */
            >
                {abbreviation_full_name?.toUpperCase() ?? ''}
            </div>
        </ResponsablesAvatar>
    );
};
