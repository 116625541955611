import Container from 'components/Grid/Container';

import { MenuWrapper, PageLayout, PageTitle, ViewContainer } from './styles';

// Iconos
import { ReactComponent as SettingsIcon } from 'assets/settings.svg';
import { ReactComponent as UsersIcon } from 'assets/users.svg';
import { ReactComponent as PinIcon } from 'assets/map-pin.svg';
import { ReactComponent as TruckIcon } from 'assets/truck.svg';
import { ReactComponent as OrderIcon } from 'assets/clipboard.svg';
import { ReactComponent as InboxIcon } from 'assets/inbox.svg';
import { ReactComponent as PackageIcon } from 'assets/package.svg';
import { ReactComponent as BranchIcon } from 'assets/git-branch.svg';
import { ReactComponent as Vector } from 'assets/Vector.svg';
import { ReactComponent as VectorWebHook } from 'assets/vector-web-hook.svg';
import { ReactComponent as MetafieldIcon } from 'assets/tag-multiple-outline.svg';
import { ReactComponent as MapIcon } from 'assets/map.svg';
import { ReactComponent as PromiseEngineIcon } from 'assets/promise-engine-icon.svg';

import Flex from 'components/Utils/Flex';
import TCard from 'components/TCard';
import useSettingContent from 'hooks/useSettingContent';
import React, { Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumbs } from '@andescommercecloud/beflow-design-system';
import usePermissionAdmin from 'hooks/usePermissionAdmin';
import SalesChannelsForm from './components/SalesChannels/SalesChannelsForm';
import { ReactComponent as CreditCardMarkerOutline } from 'assets/credit-card-marker-outline.svg';
import { ReactComponent as ClipboardMultiple } from 'assets/clipboard-multiple-outline.svg';
import { ViewTitle } from 'components/V2/Settings/ViewTittle';
const GeneralSettings = React.lazy(() => import('./components/General'));
const UsersSettings = React.lazy(() => import('./components/Users'));
const ShopsSettings = React.lazy(() => import('./components/Shops2'));
const CouriersSettings = React.lazy(() => import('./components/Couriers'));
const MappingsSettings = React.lazy(() => import('./components/Mappings'));
const PreparationsSettings = React.lazy(() => import('./components/Preparations'));
const PickupSettings = React.lazy(() => import('./components/PickupsPreferences'));
const MetafieldsPage = React.lazy(() => import('../Metafields'));
const IntegrationsSettings = React.lazy(() => import('./components/Integrations'));
const Orders = React.lazy(() => import('./components/Orders'));
const SalesChannels = React.lazy(() => import('./components/SalesChannels'));
const MassiveOrdersSettings = React.lazy(() => import('./components/MassiveOrders'));
const IntegrationErrorSettings = React.lazy(() => import('./components/ErrorIntegration'));
const WebhookSettings = React.lazy(() => import('./components/Webhooks'));
const PromiseEngine = React.lazy(() => import('./components/PromiseEngine'));
const moduleAllowedForAdmin = ['locations', 'users', 'massiveOrders'];

const SettingsPage = () => {
    const [{ settingsContentActiveParams }, setContentActive] = useSettingContent();
    const [contentToShow, setContentToShow] = useState({});
    const { section, view } = useParams();
    const [isAdmin, isSuperAdmin, { isLoading: isLoadingAuth }] = usePermissionAdmin();

    const menuData = [
        {
            name: 'general',
            icon: <SettingsIcon />,
            label: 'Información general',
            component: (
                <Suspense fallback={<></>}>
                    <GeneralSettings />
                </Suspense>
            ),
        },
        {
            name: 'users',
            icon: <UsersIcon />,
            label: 'Usuarios / Perfiles',
            component: (
                <Suspense fallback={<></>}>
                    <UsersSettings />
                </Suspense>
            ),
        },
        {
            name: 'locations',
            icon: <PinIcon />,
            label: 'Tiendas / Bodegas',
            component: (
                <Suspense fallback={<></>}>
                    <ShopsSettings />
                </Suspense>
            ),
        },
        {
            name: 'channels',
            icon: <CreditCardMarkerOutline />,
            label: 'Canales de venta',
            component: (
                <Suspense fallback={<></>}>
                    <SalesChannels />
                </Suspense>
            ),
        },
        {
            notShowInMenu: true,
            name: 'channels/create',
            label: 'Crear canal de venta',
            component: (
                <Suspense fallback={<></>}>
                    <TCard
                        title={
                            <ViewTitle
                                title="Canales de venta"
                                icon={<CreditCardMarkerOutline width={22} color="#2D3D76" />}
                            />
                        }
                        paddingless
                        bordered
                        squaredBorderRadius
                    >
                        <ViewContainer style={{ margin: '0 32px 32px 32px' }}>
                            <SalesChannelsForm formName="" initialValues={{}} formAction="new" />
                        </ViewContainer>
                    </TCard>
                </Suspense>
            ),
        },
        {
            name: 'couriers',
            icon: <TruckIcon />,
            label: 'Couriers',
            component: (
                <Suspense fallback={<></>}>
                    <CouriersSettings />
                </Suspense>
            ),
        },
        {
            name: 'orders',
            icon: <OrderIcon />,
            label: 'Pedidos',
            component: (
                <Suspense fallback={<></>}>
                    <Orders />
                </Suspense>
            ),
        },
        {
            name: 'massiveOrders',
            icon: <ClipboardMultiple />,
            label: 'Pedidos masivos',
            component: (
                <Suspense fallback={<></>}>
                    <MassiveOrdersSettings />
                </Suspense>
            ),
        },
        {
            name: 'fulfillments',
            icon: <InboxIcon />,
            label: 'Preferencias preparación',
            component: (
                <Suspense fallback={<></>}>
                    <PreparationsSettings />
                </Suspense>
            ),
        },
        {
            name: 'delivery',
            icon: <PackageIcon />,
            label: 'Preferencias entrega',
            component: (
                <Suspense fallback={<></>}>
                    <PickupSettings />
                </Suspense>
            ),
        },
        {
            name: 'integrations',
            icon: <BranchIcon />,
            label: 'Integraciones',
            component: (
                <Suspense fallback={<></>}>
                    <IntegrationsSettings />
                </Suspense>
            ),
        },
        {
            name: 'errorAlert',
            icon: <Vector />,
            label: 'Notificaciones de integraciones',
            component: (
                <Suspense fallback={<></>}>
                    <IntegrationErrorSettings />
                </Suspense>
            ),
        },
        {
            name: 'mapping',
            icon: <MapIcon />,
            label: 'Mapeos',
            component: (
                <Suspense fallback={<></>}>
                    <MappingsSettings />
                </Suspense>
            ),
        },
        // {
        //     name: 'plan',
        //     icon: '',
        //     label: 'PLAN',
        //     component: <PlanSettings />,
        // },
        {
            name: 'metafields',
            icon: <MetafieldIcon />,
            label: 'Metafields',
            component: (
                <Suspense fallback={<></>}>
                    <MetafieldsPage />
                </Suspense>
            ),
        },
        {
            name: 'webhooks',
            icon: <VectorWebHook />,
            label: 'Webhooks',
            component: <WebhookSettings />,
        },
        {
            name: 'promise-engine',
            icon: <PromiseEngineIcon />,
            label: 'Promise Engine',
            component: <PromiseEngine />,
        },
    ];

    useEffect(() => {
        if (!isLoadingAuth) {
            if (section || view) {
                const content = menuData.find((item) =>
                    view ? item.name === `${section}/${view}` : item.name === section
                );
                setContentToShow(content);
                setContentActive(content?.name ?? 'general', settingsContentActiveParams);
            } else {
                const menuDataFiltered = isSuperAdmin
                    ? menuData
                    : menuData.filter(
                          (item) => !isSuperAdmin && moduleAllowedForAdmin.includes(item.name)
                      );
                setContentToShow(menuDataFiltered[0]);
                setContentActive(menuDataFiltered[0].name, settingsContentActiveParams);
            }
        }
    }, [section, view, isLoadingAuth]);

    return (
        <>
            {isAdmin || isSuperAdmin ? (
                <Container extraTitle="Configuración">
                    <Breadcrumbs
                        breadcrumbs={[
                            { label: 'Configuración', link: '/settings' },
                            {
                                label: contentToShow?.label,
                                link: `/settings/${contentToShow?.name}`,
                            },
                        ]}
                    />
                    <PageTitle>Configuración</PageTitle>

                    <PageLayout>
                        <MenuWrapper>
                            <TCard paddingless bordered squaredBorderRadius>
                                <ul>
                                    {menuData.map((menu, index) => {
                                        const isModuleAllowedForAdmin =
                                            moduleAllowedForAdmin.includes(menu.name);
                                        if (!isModuleAllowedForAdmin && !isSuperAdmin) {
                                            return null;
                                        }
                                        return (
                                            !menu.notShowInMenu && (
                                                <li
                                                    key={index}
                                                    className={
                                                        menu.name === section
                                                            ? 'is-active'
                                                            : undefined
                                                    }
                                                    onClick={() => setContentActive(menu.name)}
                                                >
                                                    {menu.icon}
                                                    <span>{menu.label}</span>
                                                </li>
                                            )
                                        );
                                    })}
                                </ul>
                            </TCard>
                        </MenuWrapper>
                        <Flex direction="column" rowGap="1rem" flex="1">
                            {contentToShow && contentToShow.component}
                        </Flex>
                    </PageLayout>
                </Container>
            ) : (
                <span style={{ marginTop: '30px', marginLeft: '30px' }}>
                    Su perfil no tiene el permiso para acceder a la configuración.
                </span>
            )}
        </>
    );
};

export default SettingsPage;
