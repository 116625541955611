// import PageHeader from 'components/Layout/PageHeader';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
//import MetafieldsMenu from './components/Menu';
import { MetafieldTabButton } from './styles';
import { Tabs } from 'antd';
import TCard from 'components/TCard';
import { ViewContainer } from 'pages/Settings/styles';
import { ReactComponent as TagMultipleIcon } from 'assets/tag-multiple-icon.svg';
import { ViewTitle } from 'components/V2/Settings/ViewTittle';
import { CreateMetafieldForm } from './components/Form';
const MetafieldsTable = React.lazy(() => import('./components/MetafieldsTable'));

const categories = [
    {
        name: 'locations',
        label: 'Tiendas',
    },
    {
        name: 'integrations',
        label: 'Integraciones',
    },
    {
        name: 'saleschannels',
        label: 'Canales de ventas',
    },
    {
        name: 'couriers',
        label: 'Couriers',
    },
];

const MetafieldsPage = () => {
    const [, setActiveCategory] = useState({});
    const [selectedTargetId, setSelectedTargetId] = useState(null);
    const [metafieldToEdit, setMetafieldToEdit] = useState({});
    const params = new URLSearchParams(window.location.search);
    const { category, target_id } = useParams();

    const [showInfo, setShowInfo] = useState(false);

    const [buttonShow, setButtonShow] = useState(false);

    const handleDeleteMetafield = (data) => {
        console.log('data', data);
    };

    useEffect(() => {
        if (params.get('category')) {
            setActiveCategory(categories.find((c) => c.name === params.get('category')) || {});
        }
        if (params.get('target_id')) {
            setSelectedTargetId(params.get('target_id'));
        }
    }, [params]);

    useLayoutEffect(() => {
        if (category) {
            setActiveCategory(categories.find((c) => c.name === category) || {});
        }
    }, [category]);

    useEffect(() => {
        if (target_id) {
            setSelectedTargetId(target_id);
        }
    }, [target_id]);
    console.log({ target_id });
    return (
        <TCard
            title={<ViewTitle title="Metafield" icon={<TagMultipleIcon width={24} />} />}
            paddingless
            squaredBorderRadius
        >
            <ViewContainer>
                <Tabs>
                    {categories.map((c) => (
                        <Tabs.TabPane
                            tab={
                                <MetafieldTabButton onClick={() => setSelectedTargetId(null)}>
                                    {c.label}
                                </MetafieldTabButton>
                            }
                            key={c.name}
                        >
                            <CreateMetafieldForm
                                showInfo={showInfo}
                                setShowInfo={setShowInfo}
                                buttonShow={buttonShow}
                                setButtonShow={setButtonShow}
                                category={c}
                                setActiveCategory={setActiveCategory}
                                setSelectedTargetId={setSelectedTargetId}
                                selectedTargetId={selectedTargetId}
                                setMetafieldToEdit={setMetafieldToEdit}
                                metafieldToEdit={metafieldToEdit}
                            />
                            {selectedTargetId && (
                                <MetafieldsTable
                                    showInfo={showInfo}
                                    setShowInfo={setShowInfo}
                                    target_id={selectedTargetId}
                                    onClickDeleteMetafield={handleDeleteMetafield}
                                    onClickEditMetafield={setMetafieldToEdit}
                                />
                            )}
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </ViewContainer>
        </TCard>
    );
};

export default MetafieldsPage;
