import styled from 'styled-components';
import { Menu, Button } from 'antd';

export const PreparationStyles = styled.section`
    .ant-form-item-explain-error {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .ant-form-item-label {
        margin-bottom: 0px;
        padding: 0px;
    }
    .ant-form-item-required {
        &:before {
            content: '' !important;
            display: none !important;
        }
        font-size: 12px;
        color: #819a9f;
    }
    .ant-picker {
        height: 40px;
        width: 100%;
        border: 1px solid #bacbd9;
    }
    .ant-input {
        height: 40px;
        border: 1px solid #bacbd9;
    }
    .ant-select {
        min-height: 40px;
        .ant-select-selector {
            display: flex;
            align-items: center;
            min-height: 40px;
            .ant-select-selection-item {
                font-size: 14px;
                font-weight: 500;
            }
        }
        .ant-select-arrow {
            top: 51% !important;
        }
    }
`;

export const MenuStyled = styled(Menu)`
    letter-spacing: 0.4px;
    font-weight: 500;
`;

export const OrderWithoutLocation = styled.div`
    line-height: 1rem;
    font-size: 0.8rem !important;
    font-weight: 500;
    padding-bottom: 1rem;
`;

export const ButtonModalShippingCost = styled(Button)`
    border-radius: 3px;
    border: 1px solid #2d3d76;
    font-weight: 500;
    color: #2d3d76;
`;

export const ButtonModalShippingCostAdd = styled(Button)`
    border-radius: 3px;
    border: 1px solid #e79b09;
    background-color: #f27127;
    color: white;
    font-weight: 500;
`;

export const TitleModalShippingCost = styled.div`
    font-size: 1rem;
    font-weight: 700;
    color: #2d3d76;
`;
