import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getFulfillmentById } from './getOne';
import { fulfillmentsApi } from './index';

export const cancelFulfillment = createAsyncThunk('fulfillments/cancel', async (id, state) => {
    try {
        const promise = AndesClient.post(`/fulfillments/${id}/cancel`);

        toast.promise(promise, {
            loading: <b>Cancelando fulfillment...</b>,
            success: <b>Fulfillment cancelado con éxito</b>,
            error: <b>Ocurrió un error al cancelar el fulfillment.</b>,
        });

        const result = await promise;

        if (result.status === 200) {
            state.dispatch(getFulfillmentById(id));
            state.dispatch(
                fulfillmentsApi.endpoints.getSuccessFulfillments.initiate(undefined, {
                    forceRefetch: true,
                })
            );
        }

        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const cancelFulfillmentSlice = createSlice({
    name: 'cancelFulfillment',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    extraReducers: {
        [cancelFulfillment.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [cancelFulfillment.fulfilled]: (state) => {
            return {
                ...state,
                success: true,
                loading: false,
            };
        },
        [cancelFulfillment.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});
export default cancelFulfillmentSlice.reducer;
