import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import AndesClient from 'services/AndesClient';

const initialStateGetNewSlaDate = {
    loading: false,
    data: '',
    error: null,
    success: false,
};

export const getNewSlaDate = createAsyncThunk('getNewSlaDate/getOne', async (data, state) => {
    try {
        //const response = await AndesClient.post(`/helpers/getNewSlaDate`, data);
        const promise = AndesClient.post(`/helpers/getNewSlaDate`, data);

        toast.promise(promise, {
            loading: <b>Calculando nueva fecha de compromiso...</b>,
            success: <b>Fecha de compromiso obtenida con éxito</b>,
            error: <b>Ocurrió un error.</b>,
        });
        const result = await promise;
        return result.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getNewSlaDateSlice = createSlice({
    name: 'getNewSlaDate',
    initialState: initialStateGetNewSlaDate,
    reducers: {
        reInitGetNewSlaDate: () => initialStateGetNewSlaDate,
    },
    extraReducers: {
        [getNewSlaDate.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getNewSlaDate.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
                success: true,
            };
        },
        [getNewSlaDate.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
                success: false,
            };
        },
    },
});
export const { reInitGetNewSlaDate } = getNewSlaDateSlice.actions;
export default getNewSlaDateSlice.reducer;
