import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

const initialStateGetCourierRate = {
    loading: false,
    data: [],
    error: null,
    success: false,
};

export const getCourierRate = createAsyncThunk(
    'fulfillments/getCourierRate',
    async (data, state) => {
        try {
            const { order_id, ...body } = data;
            const res = await AndesClient.post(
                `/fulfillments/orders/${order_id}/fulfillments/getCourierRate`,
                body
            );
            if (res.status === 200) {
                toast.success('Cotización obtenida con éxito.');
            }
            return res.data;
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const getCourierRateSlice = createSlice({
    name: 'getCourierRate',
    initialState: initialStateGetCourierRate,
    reducers: {
        reInitGetCourierRate: () => {
            return initialStateGetCourierRate;
        },
        turnOffSuccessCourierRate: (state) => {
            return {
                ...state,
                success: false,
            };
        },
    },
    extraReducers: {
        [getCourierRate.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getCourierRate.fulfilled]: (state, action) => {
            return {
                ...state,
                error: false,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [getCourierRate.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});

export const { reInitGetCourierRate, turnOffSuccessCourierRate } = getCourierRateSlice.actions;
export default getCourierRateSlice.reducer;
