import { defaultColumnsView as ordersColumns } from 'pages/OrdersV2/components/Columns/defaultColumnsViewData';
import { defaultColumnsView as preparationsColumns } from 'pages/PreparationsV2/config';
import { defaultColumnsView as pendingArrivalsColumns } from 'pages/PendingArrivalsV2/config';
import { defaultColumnsView as crossdocking } from 'pages/Crossdocking/config';
import { defaultColumnsView as manifestsColumns } from 'pages/Manifests/config';
import { defaultColumnsView as deliveriesColumns } from 'pages/DeliveriesV2/config';

export const getLastNewViewIndex = (tabs) => {
    if (!tabs?.length) return '';
    const customTabs = tabs.filter((tab) => tab?.name?.includes('Nueva vista'));
    if (customTabs?.length < 1) return '';

    return `(${customTabs.length})`;
};

export const defaultColumnsView = {
    orders: ordersColumns,
    preparations: preparationsColumns,
    pendingArrivals: pendingArrivalsColumns,
    crossdocking: crossdocking,
    manifests: manifestsColumns,
    deliveries: deliveriesColumns,
};
