import { Form, Input, Modal } from 'antd';
import FormStyled from 'components/Utils/Form';
import { useState } from 'react';
import { useUpdateLineItemSkuMutation } from 'redux/features/Orders';

const FORM_INITIAL_VALUES = {
    remote_variant_id: '',
    line_item_id: '',
    order_id: '',
};

export const ModalEditSku = ({ isOpen, onClose, initialValues = FORM_INITIAL_VALUES }) => {
    const [form] = Form.useForm();
    const [updateLineItemSku, { isLoading }] = useUpdateLineItemSkuMutation();
    const [newSku, setNewSku] = useState('');

    const handleOK = () => {
        if (isLoading) return;
        const data = form.getFieldsValue();
        updateLineItemSku({
            line_item_id: initialValues?.line_item_id,
            sku: data?.remote_variant_id,
            order_id: initialValues?.order_id,
        })
            .then((response) => {
                if (!response.error) onClose();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Modal
            title="Editar SKU"
            okText="Guardar cambios"
            open={isOpen}
            onOk={handleOK}
            onCancel={onClose}
            okButtonProps={{
                disabled: newSku === initialValues?.remote_variant_id || !newSku,
            }}
        >
            <FormStyled
                name="customerContactForm"
                layout="vertical"
                initialValues={initialValues}
                form={form}
            >
                <Form.Item required name="remote_variant_id" label="SKU">
                    <Input value={newSku} onChange={(e) => setNewSku(e.target.value)} />
                </Form.Item>
            </FormStyled>
        </Modal>
    );
};
