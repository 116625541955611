import { Select } from 'antd';

const filterOption = (input, option) =>
    option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;

const AntdSelect = ({ children, ...props }) => {
    return (
        <Select {...props} filterOption={filterOption}>
            {children}
        </Select>
    );
};
export default AntdSelect;
