import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFulfillmentById } from 'redux/features/Fulfillments/getOne';

const useFulfillment = (fulfillment_id) => {
    const dispatch = useDispatch();
    const { loading, data, success, error } = useSelector((state) => state.fulfillment);
    useEffect(() => {
        if ((!data._id && !success) || data._id !== fulfillment_id) {
            dispatch(getFulfillmentById(fulfillment_id));
        }
    }, []);
    return [loading, data, error];
};

export default useFulfillment;
