import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import toast from 'react-hot-toast';
import { integrationApi } from '../Integrations';

export const JsonImporterApi = createApi({
    reducerPath: 'json_importer',
    baseQuery,
    tagTypes: [
        'services',
        'customers',
        'documents',
        'method_payments',
        'other_configurations'
    ],
    endpoints: (builder) => ({
        updateServices: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `json-importer/integrations/${integration_id}/services`,
                method: 'PUT',
                body: data,
            }),
            onCacheEntryAdded: (args, { dispatch }) => {
                dispatch(integrationApi.util.invalidateTags(['integrations']));
            },
            invalidatesTags: ['services'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando configuración de servicios</b>,
                    success: <b>Configuración actualizada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        updateCustomers: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `json-importer/integrations/${integration_id}/customers`,
                method: 'PUT',
                body: data,
            }),
            onCacheEntryAdded: (args, { dispatch }) => {
                dispatch(integrationApi.util.invalidateTags(['integrations']));
            },
            invalidatesTags: ['services'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando configuración de clientes</b>,
                    success: <b>Configuración actualizada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        updateDocuments: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `json-importer/integrations/${integration_id}/documents`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['documents'],
            onCacheEntryAdded: (args, { dispatch }) => {
                dispatch(integrationApi.util.invalidateTags(['integrations']));
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando configuración de documentos</b>,
                    success: <b>Configuración actualizada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        updatePaymentMethods: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `json-importer/integrations/${integration_id}/payment-methods`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['payment_methods'],
            onCacheEntryAdded: (args, { dispatch }) => {
                dispatch(integrationApi.util.invalidateTags(['integrations']));
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando configuración de formas de pago</b>,
                    success: <b>Configuración actualizada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        updateOtherConfigurations: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `json-importer/integrations/${integration_id}/others-configurations`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['other_configurations'],
            onCacheEntryAdded: (args, { dispatch }) => {
                dispatch(integrationApi.util.invalidateTags(['integrations']));
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando configuraciones</b>,
                    success: <b>Configuraciones actualizadas con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        updateWarehouses: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `json-importer/integrations/${integration_id}/warehouses`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['warehouses'],
            onCacheEntryAdded: (args, { dispatch }) => {
                dispatch(integrationApi.util.invalidateTags(['warehouses']));
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando warehouses</b>,
                    success: <b>warehouses actualizadas con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        checkStatusIntegration: builder.query({
            method: 'get',
            query: ({ integration_id }) => `json-importer/integrations/${integration_id}/check-status`,
        }),
    }),
});

export const {
    useUpdateServicesMutation,
    useUpdateCustomersMutation,
    useUpdateDocumentsMutation,
    useUpdatePaymentMethodsMutation,
    useUpdateOtherConfigurationsMutation,
    useUpdateWarehousesMutation,
    useCheckStatusIntegrationQuery
} = JsonImporterApi;
