import { useCallback, useEffect, useState } from 'react';

const useBrowserNotification = () => {
    const [hasBrowserNotifications, setHasBrowserNotifications] = useState(false);

    const requestPermission = useCallback(() => {
        if (!('Notification' in window)) {
            return;
        }

        if (Notification.permission === 'granted') {
            return setHasBrowserNotifications(true);
        }

        if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    return setHasBrowserNotifications(true);
                } else {
                    return setHasBrowserNotifications(false);
                }
            });
        }
    }, []);

    useEffect(() => {
        requestPermission();
    }, []);

    return { hasActive: hasBrowserNotifications };
};

export default useBrowserNotification;
