const capitalizeStrings = (a) => {
    // Create a regex that matches 'de' and 'del' words, ignoring case
    const regex = /\b(de|del)\b/gi;

    // Check if a matches the regex
    if (regex.test(a)) {
        return a.toLowerCase();
    }
    return a.charAt(0).toUpperCase() + a.slice(1).toLowerCase();
};

export default capitalizeStrings;
