import { Radio } from 'antd';
import styled, { css } from 'styled-components';

export default styled(Radio)`
    ${({ $marginLeft }) =>
        $marginLeft &&
        css`
            margin-left: ${$marginLeft};
        `};
    span {
        font-size: ${({ $size }) => ($size === 'xs' ? '12px' : `14px`)};
        font-weight: ${({ $bold }) => ($bold ? `500` : `400`)};
        color: #2d3d76;
    }

    ${({ $size }) =>
        $size === 'xs' &&
        css`
            & .ant-radio-inner {
                width: 12px;
                height: 12px;
            }
            & .ant-radio-inner::after {
                width: 12px;
                height: 12px;
                margin-top: -6px;
                margin-left: -6px;
            }
        `}
`;
