import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getOrderById } from './getOrderById';
import { toggleDrawer, toggleModal } from '../Utils';

export const updateOrderStatus = createAsyncThunk('orders/update', async (data, state) => {
    try {
        const promise = AndesClient.put(`/orders/update/status`, data);
        const states = state.getState();

        const orderOrFulfillmentText = data?.fulfillment_id ? 'fulfillment' : 'order';

        const delaText = orderOrFulfillmentText === 'fulfillment' ? 'del' : 'de la';

        toast.promise(promise, {
            loading: (
                <b>
                    Actualizando el estado {delaText} {orderOrFulfillmentText}.
                </b>
            ),
            success: (
                <b>
                    Estado {delaText} {orderOrFulfillmentText} actualizado con éxito.
                </b>
            ),
            error: (
                <b>
                    Ocurrió un error al cambiar el estado {delaText} {orderOrFulfillmentText}.
                </b>
            ),
        });

        const result = await promise;

        if (result.status === 200) {
            state.dispatch(toggleModal('order-change-status-modal'));
            state.dispatch(getOrderById(data.order_id));

            // Cerramos el modal de preparacion global
            if (states.utils?.drawer['global-actions-order-drawer']) {
                state.dispatch(toggleDrawer('global-actions-order-drawer'));
            }
        }

        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const updateOrderStatusSlice = createSlice({
    name: 'updateOrder',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [updateOrderStatus.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [updateOrderStatus.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [updateOrderStatus.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default updateOrderStatusSlice.reducer;
