import styled from 'styled-components';

export const OrderDataLayout = styled.div`
    background: #ffffff;
    margin-top: 40px;
`;

export const OrderDataSectionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border: 2px solid #dce5ec;
    padding: 14px 22px;
    margin-bottom: 20px;

    hr {
        margin: 8px 0;
    }
`;

export const Title = styled.span`
    color: #2d3d76;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 14px;
`;

export const SubTitle = styled.span`
    color: #2d3d76;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    .title_billing_address {
        margin-top: 15px;
    }
`;
export const ButtonEditContent = styled.div`
    display: flex;
    direction: row;
    justify-content: space-between;
    align-items: flex-start;
    color: #2d3d76;
    button {
        margin-top: 12px;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.3rem;
        color: inherit;
        padding: 0;
    }
`;
export const HeadingText = styled.span`
    color: #f27127;
    font-size: 14px;
    display: flex;
    gap: 4px;
    margin-bottom: 1rem;
`;

export const TagDeliveryMethodStyle = styled.span`
    .tag-delivery_method {
        margin-bottom: 1rem;
        background-color: #f5f5f5;
        border: 1px solid #2d3d76;
        color: #2d3d76;
        font-size: 10px;

        .icon-delivery_method {
            margin-left: 8px;
            font-size: 16px;
        }
    }
`;

export const Space = styled.div`
    height: ${({ $size = '10px' }) => $size};
`;

export const KeyValueWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
    b {
        display: block;
        margin-right: 6px;
        color: #2D3D76;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
    }
    p {
        display: block;
        margin-right: 6px;
        color: #536d8f;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 0;
    }
`;
export const KeyValueWrapperTags = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    margin-top: 10px;

    p {
        display: block;
        margin-right: 6px;
        color: #536d8f;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 0;
    }
`;
