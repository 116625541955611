import styled from 'styled-components';
import { AiOutlineCloseCircle } from 'react-icons/ai';

export const ClosableTagStyled = styled.div`
    border: 1px solid #2d3d76;
    min-height: 10px;
    max-height: 25px;
    background: #e4e8ec;
    font-weight: 500;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    strong {
        display: block;
        margin-right: 5px;
    }
`;

const CloseTagButton = styled.div`
    svg {
        width: 1rem;
        height: 1rem;
        margin-left: 5px;
    }
`;
const ClosableTag = ({ children, onClose = () => {}, ...otherProps }) => {
    return (
        <ClosableTagStyled {...otherProps}>
            <span>{children}</span>
            <CloseTagButton role="button" onClick={onClose}>
                <AiOutlineCloseCircle />
            </CloseTagButton>
        </ClosableTagStyled>
    );
};

export default ClosableTag;
