import ModalWithButton from 'components/ModalWithButton';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';

export const ModalLogs = ({ modalProps, buttonProps, children }) => {
    const dispatch = useDispatch();

    return (
        <ModalWithButton
            modalProps={{
                name: 'order-log-details',
                title: `Logs`,
                bodyStyle: { padding: '20px' },
                okText: 'Cerrar',
                showCancel: false,
                destroyOnClose: true,
                onOk: () => {
                    dispatch(toggleModal('order-log-details'));
                },
                cancelButtonProps: {
                    style: {
                        display: 'none',
                    },
                },
                ...modalProps,
            }}
            buttonType="link"
            buttonProps={{
                buttonText: (
                    <a>
                        <u>Ver detalles</u>
                    </a>
                ),
                ...buttonProps,
            }}
        >
            {children}
        </ModalWithButton>
    );
};