import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Flex from 'components/Utils/Flex';
import { useParams } from 'react-router-dom';
import useBatchFulfillments from 'hooks/useBatchFulfillments';
import AntdTable from 'components/AntdTable';
import { Fragment, useEffect, useMemo } from 'react';
import Tag from 'components/Tag';
import TCard from 'components/TCard';
import PackagesToolbar from './components/PackagesToolbar';
import { ReactComponent as CopyIcon } from 'assets/new-copy-icon.svg';

import PackageDetailsDrawer from './components/PackageDetailsDrawer';
import { Skeleton, Typography } from 'antd';

import { useDispatch } from 'react-redux';
import { setFulfillmentForDelivery } from 'redux/features/Utils';
import { renderFulFillmentTagSLA } from 'helpers/renderTags';

const renderPackageStatus = (status) => {
    console.log('STATUS_ACA', status);

    if (status === 'pending_for_processing' || status === 'closed') {
        return (
            <Tag style={{ backgroundColor: '#7998A7', color: 'white' }}>Despachado a tienda</Tag>
        );
    }
    if (status === 'processed') {
        return (
            <Tag style={{ backgroundColor: '#7998A7', color: 'white' }}>Despachado a tienda</Tag>
        );
    }
    if (status === 'received_pickup_location') {
        return (
            <Tag style={{ color: 'white' }} color="success">
                Escaneado
            </Tag>
        );
    }

    if (status === 'received_in_cd_location') {
        return (
            <Tag style={{ color: 'white' }} color="success">
                Escaneado
            </Tag>
        );
    }

    return <Tag color="danger">Error</Tag>;
};

const renderFulfillmentPackageStatus = (fulfillment) => {
    if (fulfillment?.preparationcd_transfer_pickupstore?.status) {
        const status = fulfillment?.preparationcd_transfer_pickupstore?.status;

        if (status === 'delivered_to_pickup_location') {
            return renderFulFillmentTagSLA({
                status: 'delivered_to_pickup_location',
                createdAt: fulfillment.order.createdAt,
                promised_date: fulfillment.order.promised_date,
                customLabel: 'Despachado a tienda',
            });
        }

        if (status === 'ready_for_deliver_to_pickup_location') {
            return renderFulFillmentTagSLA({
                status: 'ready_for_deliver_to_pickup_location',
                createdAt: fulfillment.order.createdAt,
                promised_date: fulfillment.order.promised_date,
                customLabel: 'Listo para despacho a tienda',
            });
        }

        if (status === 'partially_received_in_pickup_location') {
            return renderFulFillmentTagSLA({
                status: 'partially_received_in_pickup_location',
                createdAt: fulfillment.order.createdAt,
                promised_date: fulfillment.order.promised_date,
                customLabel: 'Recepcionado en tienda',
            });
        }
    }

    if (fulfillment?.consolidation?.status) {
        return renderFulFillmentTagSLA({
            status: fulfillment.consolidation.status,
            createdAt: fulfillment.order.createdAt,
            promised_date: fulfillment.order.promised_date,
        });
    }

    if (fulfillment.status === 'success') {
        return renderFulFillmentTagSLA({
            status: 'unfulfilled',
            createdAt: fulfillment.order.createdAt,
            promised_date: fulfillment.order.promised_date,
            customLabel: 'Pendiente',
        });
    }

    if (fulfillment.status === 'partially_processed') {
        return renderFulFillmentTagSLA({
            status: 'in_progress',
            createdAt: fulfillment.order.createdAt,
            promised_date: fulfillment.order.promised_date,
            customLabel: 'Parcialmente procesado',
        });
    }

    if (fulfillment.status === 'ready_for_deliver') {
        return renderFulFillmentTagSLA({
            status: 'fulfilled',
            createdAt: fulfillment.order.createdAt,
            promised_date: fulfillment.order.promised_date,
            customLabel:
                fulfillment.delivery_method === 'pickup'
                    ? 'Listo para entrega'
                    : 'Listo para enviar',
        });
    }

    if (fulfillment.status === 'delivered' || fulfillment.status === 'delivered_to_courier') {
        return renderFulFillmentTagSLA({
            status: 'fulfilled',
            createdAt: fulfillment.order.createdAt,
            promised_date: fulfillment.order.promised_date,
            customLabel: fulfillment.delivery_method === 'pickup' ? 'Entregado' : 'Despachado',
        });
    }

    return <Tag color="danger">Error</Tag>;
};

const ActionsNode = ({ fulfillment }) => {
    return (
        <Flex direction="row" justifyContent="space-between" alignItems="center">
            <Flex direction="row" columnGap="1rem" alignItems="center">
                {renderFulfillmentPackageStatus(fulfillment)}
                <span>-</span>
                <span>
                    <b>ID Ecommerce: </b>
                    <Typography.Text
                        copyable={{
                            icon: <CopyIcon />,
                        }}
                        style={{
                            weight: 'bold',
                            color: '#1F6373',
                        }}
                    >
                        {fulfillment?.order?.remote_order_id ?? ''}
                    </Typography.Text>
                </span>
            </Flex>

            <PackageDetailsDrawer fulfillment={fulfillment} />
        </Flex>
    );
};

const PackagesTable = ({ fulfillment }) => {
    const tableData = useMemo(
        () => [...Array.from(fulfillment.packages ?? [])],
        [fulfillment.packages]
    );

    const sumProcessedPackages = (fulfillment) => {
        return fulfillment?.packages.reduce((acc, ele) => {
            if (['received_in_cd_location', 'received_pickup_location'].includes(ele.status)) {
                acc += 1;
            }
            return acc;
        }, 0);
    };

    const tableColumns = useMemo(
        () => [
            {
                title: 'Código Bulto',
                dataIndex: 'code',
                render: (value) => {
                    return (
                        <Typography.Paragraph
                            copyable={{
                                icon: <CopyIcon />,
                            }}
                            style={{
                                marginBottom: '0px',
                                color: '#037FB9',
                                textDecoration: 'underline',
                            }}
                        >
                            {value}
                        </Typography.Paragraph>
                    );
                },
            },
            {
                title: 'Descripción',
                dataIndex: ['type', 'name'],
                key: ['type', 'name'],
            },
            {
                title: 'Productos',
                dataIndex: 'line_items',
                render: (value) => {
                    const line_items_count = value.reduce((acc, ele) => {
                        acc += ele.quantity;
                        return acc;
                    }, 0);
                    return line_items_count;
                },
            },
            {
                title: 'Tipo Entrega',
                render: () => {
                    if (fulfillment?.delivery_method === 'shipping') {
                        return 'Despacho';
                    }
                    if (fulfillment?.delivery_method === 'pickup') {
                        return 'Retiro en tienda';
                    }
                    return 'Sin datos';
                },
            },
            {
                title: 'Estado',
                dataIndex: 'status',
                render: renderPackageStatus,
            },
            {
                title: 'Tienda',
                render: () => fulfillment.origin?.name ?? <Tag>Sin Datos</Tag>,
            },
            {
                title: 'Op. Logístico',
                render: () =>
                    fulfillment.courier?.public_name ??
                    fulfillment.courier?.courier_name ?? <Tag>Sin Datos</Tag>,
            },
        ],
        [fulfillment, fulfillment.packages]
    );

    return (
        <section className="alerts-border-placeholder" id={`packages-table-${fulfillment.name}`}>
            <b
                style={{
                    fontSize: '1.5rem',
                    color: '#2D3D76',
                    display: 'block',
                    width: '100%',
                    borderBottom: '2px solid #2D3D76                    ',
                    marginBottom: '1rem',
                    fontWeight: '500',
                    letterSpacing: '0.5px',
                }}
            >
                Bulto pedido: {fulfillment?.name ?? ''} (escaneados:{' '}
                {sumProcessedPackages(fulfillment)}/{fulfillment?.packages?.length ?? 0})
            </b>
            <TCard paddingless>
                <AntdTable
                    actionsNode={<ActionsNode fulfillment={fulfillment} />}
                    rowKey="_id"
                    loading={false}
                    dataSource={tableData}
                    columns={tableColumns}
                />
            </TCard>
        </section>
    );
};

const MultipleDelivery = ({ loading, fulfillments }) => {
    return (
        <Fragment>
            <PackagesToolbar fulfillments={fulfillments} loading={loading} />
            {fulfillments.map((fulfillment, index) => (
                <PackagesTable loading={loading} fulfillment={fulfillment} key={index} />
            ))}
        </Fragment>
    );
};

const Delivery = () => {
    const dispatch = useDispatch();
    const { fulfillment_id } = useParams();

    const setSingleBatchFulfillment = () => {
        if (fulfillment_id !== 'multiple') {
            dispatch(setFulfillmentForDelivery([fulfillment_id]));
        }
    };

    useEffect(() => {
        setSingleBatchFulfillment();
    }, [fulfillment_id]);

    const [loading, data] = useBatchFulfillments();

    if (loading && !data[0]) {
        return (
            <Container>
                <Skeleton />
            </Container>
        );
    }

    return (
        <Container extraTitle={!loading ? `Entregas (${data?.length ?? 0})` : ''}>
            <PageHeader
                showBack
                title={`Pedidos seleccionados (${data?.length ?? 0})`}
                showResponsables={true}
                responsables={
                    data &&
                    data[0] &&
                    data.reduce((acc, ele) => {
                        if (ele?.assigned_operators && ele?.assigned_operators[0]) {
                            return [...acc, ...ele.assigned_operators];
                        }
                        return acc;
                    }, [])
                }
                titleExtra={
                    <Fragment>
                        {data.some((f) => f.delivery_method === 'shipping') && (
                            <Tag color="warning" key="dom">
                                Domicilio
                            </Tag>
                        )}
                        {data.some((f) => f.delivery_method === 'pickup') && (
                            <Tag color="warning" key="dom">
                                Retiro en tienda
                            </Tag>
                        )}
                    </Fragment>
                }
                breadcrumb={[
                    { name: 'Pendientes de arribo', path: '/pendingArrival' },
                    { name: `Recepción en tienda` },
                ]}
            />
            <Flex direction="column" rowGap="2rem">
                <MultipleDelivery loading={loading} fulfillments={data} />
            </Flex>
        </Container>
    );
};

export default Delivery;
