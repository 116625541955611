import { createSlice } from '@reduxjs/toolkit';

const initialStateGlobalActionsOrderDrawer = {
    header_label: '',
    option_selected: '',
};

const globalActionsOrderDrawer = createSlice({
    name: 'globalActionsOrderDrawer',
    initialState: initialStateGlobalActionsOrderDrawer,
    reducers: {
        changeGlobalActionsOrderDrawerData: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        changeGlobalActionsOrderDrawerField: (state, { payload }) => {
            return {
                ...state,
                [payload[0]]: payload[1],
            };
        },
        reInitGlobalActionsOrderDrawerData: (state) => {
            return {
                ...state,
                ...initialStateGlobalActionsOrderDrawer,
            };
        },
    },
});
export const {
    changeGlobalActionsOrderDrawerData,
    changeGlobalActionsOrderDrawerField,
    reInitGlobalActionsOrderDrawerData,
} = globalActionsOrderDrawer.actions;
export default globalActionsOrderDrawer.reducer;
