import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getDynamicTable = createAsyncThunk(
    'couriers/getDynamicTable',
    async (courierId, state) => {
        try {
            const response = await AndesClient.get(`/couriers/getDynamicTable/${courierId}`);
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const getDynamicTableSlice = createSlice({
    name: 'dynamicTable',
    initialState: {
        loading: false,
        data: [],
        succes: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [getDynamicTable.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getDynamicTable.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                succes: true,
                error: null,
            };
        },
        [getDynamicTable.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                succes: false,
                error: payload,
            };
        },
    },
});
export default getDynamicTableSlice.reducer;
