import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const getAutocompleteAddress = createAsyncThunk(
    'getAutocompleteAddress/getAll',
    async (data, state) => {
        try {
            /* Reserva+Nacional+Magallanes+766+Chillan */
            const address = data?.address;
            const apikey = 'AIzaSyAy0noPlGcCRazDRAywTGI_VJHumuxo15U';

            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${address}&types=geocode&key=${apikey}`
            );
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const getAutocompleteAddressSlice = createSlice({
    name: 'getAutocompleteAddress',
    initialState: {
        loading: false,
        data: {},
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [getAutocompleteAddress.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getAutocompleteAddress.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
                success: true,
            };
        },
        [getAutocompleteAddress.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
                success: false,
            };
        },
    },
});
export default getAutocompleteAddressSlice.reducer;
