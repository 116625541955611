import { useDispatch, useSelector } from 'react-redux';
import { getManyOrdersByCustom } from 'redux/features/Orders/getManyOrdersByCustom';
import useActionToggleDrawer from './useActionToggleDrawer';

const useGetManyOrdersByCustom = ({ search_from = '', selected_ids = [] }) => {
    const dispatch = useDispatch();
    const { data, loading } = useSelector((state) => state.getManyOrdersByCustom);

    // Cada vez que se abre el drawer, se consultan las ordenes
    const [,] = useActionToggleDrawer(
        () => {
            if (search_from === 'fulfillment') {
                dispatch(
                    getManyOrdersByCustom({
                        search_from,
                        fulfillments_ids: selected_ids,
                    })
                );
            }
            if (search_from === 'order') {
                dispatch(
                    getManyOrdersByCustom({
                        search_from,
                        orders_ids: selected_ids,
                    })
                );
            }
        },
        'manual-incidence-order-drawer',
        true
    );
    /* useEffect(() => {
        if (search_from === 'fulfillment') {
            dispatch(
                getManyOrdersByCustom({
                    search_from,
                    fulfillments_ids: selected_ids,
                })
            );
        }
        if (search_from === 'order') {
            dispatch(
                getManyOrdersByCustom({
                    search_from,
                    orders_ids: selected_ids,
                })
            );
        }
    }, [selected_ids]); */
    return [loading, data];
};

export default useGetManyOrdersByCustom;

/* const useGetManyOrdersByCustom = ({searchFrom = '', fulfillments_ids = [], orders_ids = []}) => {
    const dispatch = useDispatch();
    const { loading, data, success, error } = useSelector((state) => state.getManyOrdersByCustom);
    useEffect(() => {
        if (!data[0] && !success) {
            dispatch(getManyOrdersByCustom());
        }
    }, []);
    return [loading, data, error];
}

export default useGetManyOrdersByCustom */
