import { Row } from 'antd';
import styled from 'styled-components';

export const RowFilterStyled = styled(Row)`
    justify-content: flex-start;
    position: relative;
    padding: 0 10px;
    .delete_condition {
        display: flex;
        justify-content: center;
        padding: 0px !important;
        position: relative !important;
        top: 30px !important;
        cursor: pointer;
        max-height: 23px;
        .delete_condition_button {
            width: 20px;
        }
    }
    .disabled {
        cursor: not-allowed !important;
        .delete_condition_button {
            path {
                stroke: #9ebcc0 !important;
            }
        }
    }
`;
