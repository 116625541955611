import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

// Obtenemos todas las ultimas 50 ordenes
export const fetchAllOrders = createAsyncThunk('orders/getAll', async (queryobj = {}, state) => {
    try {
        const response = await AndesClient.get('/orders', {
            params: {
                ...queryobj,
                limit: 100,
            },
        });
        return await response.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

// Obtenemos las anteriores 50 ordenes desde una en especifico
export const fetchAllOrdersUntil = createAsyncThunk('orders/getAll/until', async (until, state) => {
    try {
        const response = await AndesClient.get(`/orders?until=${until}?limit=50`);
        return await response.data.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

// Obtenemos las siguientes 50 ordenes desde una en especifico
export const fetchAllOrdersSince = createAsyncThunk('orders/getAll/since', async (since, state) => {
    try {
        const response = await AndesClient.get(`/orders?since=${since}?limit=50`);
        return await response.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        loading: false,
        success: false,
        data: {
            orders: [],
            page: {},
        },
        error: null,
    },
    reducers: {},
    extraReducers: {
        [fetchAllOrders.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [fetchAllOrders.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: true,
                data: payload,
                error: null,
            };
        },
        [fetchAllOrders.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
        [fetchAllOrdersUntil.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [fetchAllOrdersUntil.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: true,
                data: payload,
                error: null,
            };
        },
        [fetchAllOrdersUntil.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
        [fetchAllOrdersSince.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [fetchAllOrdersSince.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: true,
                data: payload,
                error: null,
            };
        },
        [fetchAllOrdersSince.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default ordersSlice.reducer;
