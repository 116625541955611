import { Checkbox, Form, Select } from 'antd';
import { ToolBarStyled } from './styles';
import SearchInput from 'components/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import FormStyled from 'components/Utils/Form';
import { setErrorsFilters } from 'redux/features/Utils';
import { useEffect } from 'react';
import styled from 'styled-components';
import { FaFilter } from 'react-icons/fa';
import AntdSelect from 'components/Utils/AntdSelect';
import ErrorsFiltersTags from '../ErrorsFiltersTags';
import Actions from '../ErrorsTable/Actions';

const FilterButton = styled.button`
    height: 40px;
    display: flex;
    grid-column: span 1;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgb(45, 61, 118);
    border-radius: 1px;

    svg {
        fill: white;
    }
`;

const ActionsButton = styled.div`
    grid-column-end: -1;

    button {
        height: 40px;
    }
`;

const possibleErrorsStatus = [
    {
        label: 'Pendiente',
        value: 'pending',
    },
    {
        label: 'En proceso',
        value: 'in_progress',
    },
    {
        label: 'Resuelto',
        value: 'resolved',
    },
];

const possibleErrorTypes = [
    {
        label: 'DTE',
        value: 'create-document-dte',
        namespace: 'Documentos',
    },
    {
        label: 'Factura de reserva - Prism',
        value: 'create-reserve-invoice-vyv-prism',
        namespace: 'Prism',
    },
    {
        label: 'SO - Prism',
        value: 'create-sales-order-vyv-prism',
        namespace: 'Prism',
    },
    {
        label: 'Transferencia de stock - Prism',
        value: 'stock-transfer-vyv-prism',
        namespace: 'Prism',
    },
    {
        label: 'Cierre de SO - Prism',
        value: 'close-order-vyv-prism',
        namespace: 'Prism',
    },
];

const ErrorsToolbar = ({ refetch, selectedRows, setSelectedRows, data }) => {
    const { errorsFilters } = useSelector((state) => state.utils);
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const forceRefetchWithButton = (event) => {
        const keyCode = event.which ? event.which : event.keyCode;
        if (keyCode === 190 || keyCode === 110 || keyCode === 46) {
            refetch(true);
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', forceRefetchWithButton);
        return () => {
            document.removeEventListener('keypress', forceRefetchWithButton, true);
        };
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            ...errorsFilters,
        });
    }, [errorsFilters]);

    const handleRefetch = () => {
        refetch();
    };

    const handleChangeSearchInput = (value) => {
        dispatch(setErrorsFilters({ search_terms: value }));
        if (!value || value.length === 0) {
            handleRefetch();
        }
    };

    const handleFilterOnTextEnter = () => {
        handleRefetch();
    };

    const handleChangeFilter = (valueChanged, filterValues) => {
        dispatch(
            setErrorsFilters({
                ...errorsFilters,
                ...filterValues,
            })
        );

        const isAllEmpty = Object.values(filterValues).every((value) => {
            if (!value) {
                return true;
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            }
            return false;
        });

        if (isAllEmpty) {
            refetch(true);
        }
    };

    // eslint-disable-next-line no-unused-vars
    const removeFilter = (key, type, value) => {
        let newValue = undefined;
        if (type === 'array') {
            newValue = errorsFilters[key].filter((item) => item !== value);
        }

        if (type === 'string') {
            newValue = '';
        }

        if (type === 'number') {
            newValue = 0;
        }

        if (type === 'object') {
            newValue = {};
        }

        const newFilters = {
            ...errorsFilters,
            [key]: newValue,
        };

        form.setFieldsValue(newFilters);
        dispatch(setErrorsFilters(newFilters));
        refetch();
    };

    const isCheckboxChecked = (key, value) => {
        if (Array.isArray(errorsFilters[key])) {
            return errorsFilters[key].includes(value);
        }
        return false;
    };

    return (
        <FormStyled form={form} name="errors_filters" onValuesChange={handleChangeFilter}>
            <ToolBarStyled>
                <Form.Item name="search_terms" className="errorsSearchInput">
                    <SearchInput
                        placeholder="Buscar"
                        value={errorsFilters?.search_terms ?? undefined}
                        onChange={(e) => handleChangeSearchInput(e?.target?.value)}
                        onClickIcon={handleFilterOnTextEnter}
                        onKeyDown={(e) => {
                            const key = e.which || e.keyCode;
                            const ctrl = e.ctrlKey ? e.ctrlKey : key === 17;
                            if (key === 13 || (key === 86 && ctrl)) {
                                handleFilterOnTextEnter();
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item name="integration_name">
                    <AntdSelect placeholder="Nombre de integracion" allowClear>
                        <Select.Option value="Prism">Prism</Select.Option>
                        <Select.Option value="Documento">Documento</Select.Option>
                        <Select.Option value="Notification">Notificación</Select.Option>
                    </AntdSelect>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.integration_name !== currentValues.integration_name
                    }
                >
                    {({ getFieldValue }) => {
                        const integration_name = getFieldValue('integration_name');
                        return (
                            <Form.Item name="error_type">
                                <AntdSelect
                                    placeholder="Tipo de error"
                                    allowClear
                                    disabled={!integration_name}
                                >
                                    {possibleErrorTypes
                                        .filter(
                                            (errorType) => errorType.namespace === integration_name
                                        )
                                        .map((errorType) => (
                                            <Select.Option
                                                key={errorType.value}
                                                value={errorType.value}
                                            >
                                                {errorType.label}
                                            </Select.Option>
                                        ))}
                                </AntdSelect>
                            </Form.Item>
                        );
                    }}
                </Form.Item>

                <Form.Item name="status">
                    <AntdSelect
                        placeholder="Estado"
                        mode="multiple"
                        allowClear
                        maxTagCount="responsive"
                        optionLabelProp="title"
                    >
                        {possibleErrorsStatus.map((state) => (
                            <Select.Option
                                key={state.value}
                                value={state.value}
                                title={state.label}
                            >
                                <Checkbox
                                    checked={isCheckboxChecked('status', state.value)}
                                    style={{ marginRight: '10px' }}
                                />
                                {state.label}
                            </Select.Option>
                        ))}
                    </AntdSelect>
                </Form.Item>

                <FilterButton onClick={handleRefetch}>
                    <FaFilter />
                </FilterButton>

                <ActionsButton>
                    <Actions
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        data={data}
                    />
                </ActionsButton>
            </ToolBarStyled>

            <ErrorsFiltersTags errorsFilters={errorsFilters} removeFilter={removeFilter} />
        </FormStyled>
    );
};

export default ErrorsToolbar;
