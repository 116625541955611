import { IncidenceCard, IncidenceSelectorStyled } from '../styles';
import { Button, Typography } from '@andescommercecloud/beflow-design-system';
import Flex from 'components/Utils/Flex';
import { useDispatch } from 'react-redux';
import { setAndResetChangeStoreValues } from 'redux/features/ChangeStore';

const IncidenceSelector = ({ deliveryMethod, hasStore }) => {
    const dispatch = useDispatch();

    const setShowStep = (step) => {
        dispatch(
            setAndResetChangeStoreValues({
                step,
                delivery_method: step === 'warehouse-list' ? deliveryMethod : undefined,
            })
        );
    };

    return (
        <Flex direction="column">
            <Typography variant="medium" color="primary.00" style={{ fontWeight: '500' }}>
                Sucursales con despacho o retiro para realizar cambios
            </Typography>
            <br />

            <Typography variant="regular" color="primary.01">
                A continuación podrás ver disponibilidad de otras bodegas o revisar otras
                alternativas para poder resolver la incidencia
            </Typography>
            <br />
            <br />

            <IncidenceSelectorStyled>
                <IncidenceCard>
                    <b style={{ fontSize: 14 }}>Disponibilidad en otras Bodegas</b>

                    <small>
                        En esta opción podrás verificar si se puede realizar la misma configuración
                        de envío pero desde distintos orígenes.
                    </small>

                    <div>
                        <Button
                            type="primary"
                            ghost
                            onClick={() => setShowStep('warehouse-list')}
                            disabled={!hasStore}
                        >
                            <Flex
                                direction="row"
                                alignItems="center"
                                columnGap="0.5rem"
                                justifyContent="center"
                            >
                                <p style={{ margin: '0px', display: 'block' }}>
                                    Bodegas Disponibles
                                </p>
                            </Flex>
                        </Button>
                    </div>
                </IncidenceCard>

                <IncidenceCard>
                    <b style={{ fontSize: 14 }}>Soluciones alternativas</b>
                    <small>
                        Esta ruta presentará otras vías de solución, las cuales deben ser validadas
                        con el cliente.
                    </small>

                    <div>
                        <Button
                            variation="secondary"
                            onClick={() => setShowStep('warehouse-available')}
                        >
                            <Flex
                                direction="row"
                                alignItems="center"
                                columnGap="0.5rem"
                                justifyContent="center"
                            >
                                <p style={{ margin: '0px', display: 'block' }}>
                                    Soluciones alternativas
                                </p>
                            </Flex>
                        </Button>
                    </div>
                </IncidenceCard>
            </IncidenceSelectorStyled>
        </Flex>
    );
};

export default IncidenceSelector;
