import { ReactComponent as AttachedFilesIcon } from 'assets/attached-files.svg';
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg';
import { ReactComponent as TicketIcon } from 'assets/ticket-icon.svg';
import { ReactComponent as LabelIcon } from 'assets/ticket-icon-2.svg';
import { Modal } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { useGetAllOrderDocumentsQuery } from 'redux/features/Orders/getAllDocuments';
import {
    ButtonAttachedFiles,
    DownloadStyled,
    DownloadContainer,
    DownloadButton,
    DownloadCardContainer,
    ModalTitle,
} from './styles';
import { useGenerateChangeTicketMutation } from 'redux/features/Fulfillments';
import printJS from 'print-js';
import { ReactComponent as Ticket } from 'assets/ticket-outline.svg';

export const AttachedFiles = ({ data: dataOrder, preparationPreferences }) => {
    const [showModal, setShowModal] = useState(false);
    const [pdfFound, setPdfFound] = useState([]);
    const [creditNotesFound, setCreditNotesFound] = useState([]);
    const [hasTicketDte, setHasTicketDte] = useState(false);
    const [hasPdfLabels, setHasPdfLabels] = useState(false);
    const [hasCreditNotes, setHasCreditNotes] = useState(false);
    const [totalAttached, setTotalAttached] = useState(0);
    const [hasManifestDocument, setHasManifestDocument] = useState(false);
    const [manifestDocuments, setManifestDocuments] = useState();
    const { data: dataDocuments } = useGetAllOrderDocumentsQuery(dataOrder._id);

    const [generateChangeTicket] = useGenerateChangeTicketMutation();

    const handleOnClickPrintChangeTicket = async () => {
        const changeTicket = await generateChangeTicket({
            fulfillment_id: Array.from(dataOrder?.fulfillments)?.[0]?._id,
        });

        if (changeTicket?.data?.url) {
            printJS(changeTicket?.data?.url);
        }
    };

    const handleClickAttachedFiles = () => {
        setShowModal(true);
    };

    const handleDownloadAttached = (pdfUrl) => {
        window.open(pdfUrl, '_blank');
    };

    useEffect(() => {
        const pdfLabel = dataOrder?.fulfillments?.map((fulfillment) => fulfillment?.labels?.pdf);
        const filterPdf = pdfLabel?.filter((pdf) => pdf);
        setPdfFound(filterPdf);
        setCreditNotesFound(
            dataDocuments?.filter((creditNote) => creditNote?.type === 'DTE_CREDIT_NOTE')
        );
    }, [dataOrder, dataDocuments]);

    useEffect(() => {
        const manifestDocuments = dataDocuments?.filter((document) => document.type === 'MANIFEST');
        const dteTicket =
            dataDocuments?.find((doc) => ['DTE_TICKET', 'DTE_INVOICE'].includes(doc?.type))?.url ??
            '';
        const hasTicket = dteTicket !== '';
        const hasPdf = pdfFound?.length > 0;
        const hasCredit = creditNotesFound?.length > 0;

        const total =
            (hasTicket ? 1 : 0) +
            pdfFound?.length +
            creditNotesFound?.length +
            manifestDocuments?.length;

        setHasTicketDte(hasTicket);
        setHasPdfLabels(hasPdf);
        setHasCreditNotes(hasCredit);
        setTotalAttached(total);
        setManifestDocuments(manifestDocuments);
        setHasManifestDocument(!!manifestDocuments?.length);

        if (preparationPreferences?.preparations_preferences?.enable_change_ticket) {
            setTotalAttached(total + 1);
        }
    }, [pdfFound, creditNotesFound, dataDocuments, preparationPreferences]);

    const enable_change_ticket = useMemo(() => {
        return preparationPreferences?.preparations_preferences?.enable_change_ticket;
    }, []);

    return (
        <>
            <ButtonAttachedFiles
                onClick={handleClickAttachedFiles}
                $display={totalAttached === 0 || isNaN(totalAttached) ? 'none' : 'flex'}
            >
                <p>{totalAttached} Archivos adjuntos</p>
                <AttachedFilesIcon className="icon-attached-files" />
            </ButtonAttachedFiles>

            <Modal
                visible={showModal}
                title={<ModalTitle>Archivos adjuntos</ModalTitle>}
                onCancel={() => setShowModal(false)}
                footer={false}
                width="500px"
            >
                <DownloadContainer>
                    {enable_change_ticket && (
                        <DownloadCardContainer
                            $disabled={
                                Array.from(dataOrder?.fulfillments ?? []).length === 0 ||
                                !hasTicketDte
                            }
                        >
                            <DownloadStyled>
                                <Ticket
                                    className="ticket-icon"
                                    style={{ color: 'rgb(45, 61, 118)' }}
                                />
                                <b>Ticket de cambio</b>
                            </DownloadStyled>
                            <DownloadButton onClick={() => handleOnClickPrintChangeTicket()}>
                                <p>Descargar</p>
                                <DownloadIcon className="icon-download" />
                            </DownloadButton>
                        </DownloadCardContainer>
                    )}

                    {hasPdfLabels &&
                        pdfFound.map((pdfUrl, i) => {
                            return (
                                <DownloadCardContainer key={i}>
                                    <DownloadStyled>
                                        <LabelIcon className="ticket-icon" />
                                        <p>
                                            Etiqueta-0{i + 1}-
                                            {pdfUrl.slice(0, 30).split(/[.]/g).join('-')}...
                                        </p>
                                    </DownloadStyled>
                                    <DownloadButton onClick={() => handleDownloadAttached(pdfUrl)}>
                                        <p>Descargar</p>
                                        <DownloadIcon className="icon-download" />
                                    </DownloadButton>
                                </DownloadCardContainer>
                            );
                        })}
                    {hasTicketDte && (
                        <>
                            {dataDocuments?.map(
                                (doc) =>
                                    ['DTE_TICKET', 'DTE_INVOICE'].includes(doc?.type) && (
                                        <DownloadCardContainer key={doc._id}>
                                            <DownloadStyled>
                                                <TicketIcon className="ticket-icon" />
                                                <p>
                                                    Boleta-
                                                    {doc?.url?.slice(0, 30).split(/[.]/g).join('-')}
                                                    ...
                                                </p>
                                            </DownloadStyled>
                                            <DownloadButton
                                                onClick={() => handleDownloadAttached(doc?.url)}
                                            >
                                                <p>Descargar</p>
                                                <DownloadIcon className="icon-download" />
                                            </DownloadButton>
                                        </DownloadCardContainer>
                                    )
                            )}
                        </>
                    )}

                    {hasCreditNotes &&
                        creditNotesFound?.map((creditNote) => {
                            return (
                                <DownloadCardContainer key={creditNote._id}>
                                    <DownloadStyled>
                                        <TicketIcon className="ticket-icon" />
                                        <p>
                                            Nota-de-crédito-{creditNote?.raw_data?.folio}-
                                            {creditNote?.url?.slice(0, 18).split(/[.]/g).join('-')}
                                            ...
                                        </p>
                                    </DownloadStyled>
                                    <DownloadButton
                                        onClick={() => handleDownloadAttached(creditNote?.url)}
                                    >
                                        <p>Descargar</p>
                                        <DownloadIcon className="icon-download" />
                                    </DownloadButton>
                                </DownloadCardContainer>
                            );
                        })}
                    {hasManifestDocument &&
                        manifestDocuments?.map((manifest, idx) => (
                            <DownloadCardContainer key={`manifest-${idx}`}>
                                <DownloadStyled>
                                    <TicketIcon className="ticket-icon" />
                                    <p>Manifiesto-0{idx + 1}</p>
                                </DownloadStyled>
                                <DownloadButton
                                    onClick={() => handleDownloadAttached(manifest.url)}
                                >
                                    <p>Descargar</p>
                                    <DownloadIcon className="icon-download" />
                                </DownloadButton>
                            </DownloadCardContainer>
                        ))}
                </DownloadContainer>
            </Modal>
        </>
    );
};
