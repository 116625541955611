import { DeleteOutlined } from '@ant-design/icons';
import { ReactComponent as PlusCircleOutlined } from 'assets/plus-circle-outline.svg';
import { Input, Form } from 'antd';
import { useState } from 'react';
import { CommentLabel } from '../styled';

const Comment = ({ form }) => {
    const [showCommentInput, setShowCommentInput] = useState(false);

    return (
        <div className="mt-1">
            <div className="w-full flex items-center justify-between mb-1">
                <CommentLabel
                    className="underline cursor-pointer"
                    onClick={() => setShowCommentInput(true)}
                >
                    Agregar comentario <PlusCircleOutlined style={{ color: '#2D3D76' }} />
                </CommentLabel>
                {showCommentInput && (
                    <DeleteOutlined
                        className="cursor-pointer"
                        style={{ color: '#1F6373', fontSize: '17px' }}
                        onClick={() => {
                            if (!showCommentInput) return;

                            setShowCommentInput(false);
                            form.setFieldsValue({ comment: '' });
                        }}
                    />
                )}
            </div>
            {showCommentInput && (
                <Form.Item name="comment" initialValue="" rules={[{ required: false }]}>
                    <Input.TextArea />
                </Form.Item>
            )}
        </div>
    );
};

export default Comment;
