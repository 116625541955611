import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from 'redux/features/Utils';
import { DrawerStyled } from '../Utils/Drawer';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const Drawer = ({ children, drawerProps, ...otherProps }) => {
    const { name, toSubmit, toCancel, okText, cancelText, dontShowFooter } = drawerProps;
    const dispatch = useDispatch();
    const state = useSelector((state) => state.utils);
    const isActive = state.drawer[name] || false;

    const handleCancel = () => {
        if (toCancel) {
            toCancel();
        }
        dispatch(toggleDrawer(name));
    };
    const handleSubmit = () => {
        if (toSubmit) {
            toSubmit();
        }
        dispatch(toggleDrawer(name));
    };
    return (
        <DrawerStyled
            closable={false}
            onClose={() => dispatch(toggleDrawer(name))}
            visible={isActive}
            footer={
                !dontShowFooter && (
                    <div
                        style={{
                            textAlign: 'right',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            onClick={handleCancel}
                            style={{ marginRight: 8 }}
                            type="primary"
                            ghost
                        >
                            {cancelText ? cancelText : 'Cancelar'}
                        </Button>
                        <Button onClick={handleSubmit} type="primary">
                            {okText ? okText : 'Guardar'}
                        </Button>
                    </div>
                )
            }
            destroyOnClose
            {...otherProps}
            {...drawerProps}
        >
            {children}
        </DrawerStyled>
    );
};

Drawer.propTypes = {
    name: PropTypes.string,
    title: PropTypes.any,
    children: PropTypes.node,
    drawerProps: PropTypes.object,
};

export default Drawer;
