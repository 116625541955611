const accountTypes = [
    {
        value: 'Corriente',
        label: 'Corriente',
    },
    {
        value: 'Vista',
        label: 'Vista',
    },
    {
        value: 'Ahorro',
        label: 'Ahorro',
    },
    {
        value: 'Chequera electrónica',
        label: 'Chequera electrónica',
    },
];

const banks = [
    {
        value: 'Banco BICE',
        label: 'Banco BICE',
    },
    {
        value: 'Banco Consorcio',
        label: 'Banco Consorcio',
    },
    {
        value: 'Banco Corpbanca',
        label: 'Banco Corpbanca',
    },
    {
        value: 'Banco Credito e Inversiones',
        label: 'Banco Credito e Inversiones',
    },
    {
        value: 'Banco Estado',
        label: 'Banco Estado',
    },
    {
        value: 'Banco Falabella',
        label: 'Banco Falabella',
    },
    {
        value: 'Banco Internacional',
        label: 'Banco Internacional',
    },
    {
        value: 'Banco Paris',
        label: 'Banco Paris',
    },
    {
        value: 'Banco Ripley',
        label: 'Banco Ripley',
    },
    {
        value: 'Banco Santander',
        label: 'Banco Santander',
    },
    {
        value: 'Banco Security',
        label: 'Banco Security',
    },
    {
        value: 'Banco de Chile / Edwards-Citi',
        label: 'Banco de Chile / Edwards-Citi',
    },
    {
        value: 'Banco del Desarrollo',
        label: 'Banco del Desarrollo',
    },
    {
        value: 'Coopeuch',
        label: 'Coopeuch',
    },
    {
        value: 'HSBC Bank',
        label: 'HSBC Bank',
    },
    {
        value: 'Itaú',
        label: 'Itaú',
    },
    {
        value: 'Rabobank',
        label: 'Rabobank',
    },
    {
        value: 'Tenpo Prepago',
        label: 'Tenpo Prepago',
    },
    {
        value: 'Prepago Los Héroes',
        label: 'Prepago Los Héroes',
    },
    {
        value: 'Scotiabank',
        label: 'Scotiabank',
    },
    {
        value: 'Scotiabank Azul',
        label: 'Scotiabank Azul',
    },
    {
        value: 'Mercado Pago',
        label: 'Mercado Pago',
    },
];

export const bankDataChile = {
    accountTypes,
    banks,
};
