import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const salesChannelAPI = createApi({
    reducerPath: 'storesWarehouse',
    baseQuery,
    tagTypes: ['storesWarehouse'],
    endpoints: (builder) => ({
        getStoresOnlyPicking: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/stores-warehouses/getonlypicking`,
                    method: 'post',
                    body: data,
                };
            },
            invalidatesTags: ['storesWarehouse'],
        }),
    }),
});

export const {
    useGetStoresOnlyPickingMutation
} = salesChannelAPI;
