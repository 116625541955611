import { Fragment, useEffect } from 'react';
import { MetafieldFormCard } from '../../styles';
import FormStyled from 'components/Utils/Form';
import { Col, Form, Input, Row, Select } from 'antd';
import Flex from 'components/Utils/Flex';
import { useUpdateMetafieldMutation } from 'redux/features/Metafields';
import { Button } from '@andescommercecloud/beflow-design-system';

export const EditMetafieldForm = ({ dataMetafield, onCancel, onSuccess }) => {
    const [form] = Form.useForm();

    const [updateMetafield, updateMetafieldState] = useUpdateMetafieldMutation();
    console.log(dataMetafield);
    useEffect(() => {
        if (dataMetafield) {
            form.setFieldsValue({
                target_id: dataMetafield?.target_id,
                namespace: dataMetafield?.name,
            });
        }
    }, [dataMetafield]);

    useEffect(() => {
        if (dataMetafield) {
            form.setFieldsValue({
                ...dataMetafield,
            });
        }
    }, [dataMetafield]);

    const handleSubmit = (values) => {
        if (updateMetafieldState.isLoading) return;

        if (dataMetafield._id) {
            const newValues = {
                _id: dataMetafield._id,
                ...values,
            };

            updateMetafield(newValues);
        }

        form.resetFields();

        form.setFieldsValue({
            target_id: dataMetafield?.target_id,
            namespace: dataMetafield?.name,
        });
        onSuccess();
    };

    const handleClose = () => {
        form.setFieldsValue(dataMetafield);
        onCancel();
    };

    return (
        <MetafieldFormCard>
            <FormStyled
                name="metafield-form-edit"
                layout="vertical"
                form={form}
                onFinish={handleSubmit}
            >
                <Row gutter={24}>
                    <Fragment>
                        <Col lg={12} xl={6}>
                            <Form.Item
                                name="type"
                                label="Tipo"
                                rules={[{ required: true, message: 'Este campo es requerido' }]}
                            >
                                <Select placeholder="Tipo de dato">
                                    <Select.Option value="string">Texto</Select.Option>
                                    <Select.Option value="number">Numerico</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} xl={6}>
                            <Form.Item
                                name="key"
                                label="Llave"
                                rules={[{ required: true, message: 'Este campo es requerido' }]}
                            >
                                <Input placeholder="Llave del elemento" />
                            </Form.Item>
                        </Col>
                        <Col lg={12} xl={6}>
                            <Form.Item
                                name="value"
                                label="Valor"
                                rules={[{ required: true, message: 'Este campo es requerido' }]}
                            >
                                <Input placeholder="Valor del elemento" />
                            </Form.Item>
                        </Col>
                    </Fragment>
                </Row>

                <Flex justifyContent="flex-end" gap="10px">
                    <Button onClick={handleClose} variation="secondary">
                        Cancelar
                    </Button>

                    <Button
                        htmlType="submit"
                        disabled={updateMetafieldState.isLoading}
                        loading={updateMetafieldState.isLoading}
                    >
                        Guardar cambios
                    </Button>
                </Flex>
            </FormStyled>
        </MetafieldFormCard>
    );
};
