import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { fetchBatchFulfillments } from '../Fulfillments/getBatchFulfillments';

export const proccesPackage = createAsyncThunk('packages/process', async (data, state) => {
    try {
        const { code, fulfillment_id } = data;

        const promise = AndesClient.put('/packages/process', {
            code,
            fulfillment_id,
        });

        toast.promise(promise, {
            loading: <b>Procesando bulto...</b>,
            success: <b>Bulto procesado con éxito</b>,
            error: <b>Ocurrió un error.</b>,
        });

        const result = await promise;

        if (result.status === 200) {
            state.dispatch(fetchBatchFulfillments());
        }

        return await result.data;
    } catch (error) {
        return state.rejectWithValue(error.message);
    }
});

const proccesPackageSlice = createSlice({
    name: 'packages-process',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [proccesPackage.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [proccesPackage.fulfilled]: (state) => {
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };
        },
        [proccesPackage.rejected]: (state, payload) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default proccesPackageSlice.reducer;
