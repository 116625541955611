import { DocumentDuplicateIcon } from "@heroicons/react/outline";
import {  TagCircle, TagStyled, TypographyUnderLine } from "./styles";

const buildPackageStatus = (status) => {
    const defaultStatus = {
        color: "#B00303",
        text: "Por escanear"
    }
    const statusDic = {
        processed: defaultStatus,
        're_processed': {
            color: "#089460",
            text: "Escaneado"
        },
    }

    return statusDic[status] || defaultStatus
};

export const fulfillmentColumns = [
    {
        title: 'Código Bulto',
        dataIndex: 'code',
        render: (value) => {
            return (
                <TypographyUnderLine
                    copyable={{
                        format: 'text/plain',
                        text: value,
                        icon: [
                            <DocumentDuplicateIcon
                                color="#037FB9"
                                width={24}
                                key={`icon-copy-${value}`}
                            />,
                            <DocumentDuplicateIcon
                                color="#037FB9"
                                width={24}
                                key={`icon-copied-${value}`}
                            />,
                        ],
                    }}
                >
                    {value}
                </TypographyUnderLine>
            );
        },
    },
    {
        title: 'Descripción',
        dataIndex: ['type', 'name'],
        key: ['type', 'name'],
        render: (value) => value || 'N/A'
    },
    {
        title: 'Unidades solicitadas',
        dataIndex: 'line_items',
        render: (line_items) => line_items?.reduce((acc, lineItem) => acc += lineItem.quantity, 0),
    },
    {
        title: 'Tienda',
        dataIndex: ['sales_channel', 'integration', 'api_name'],
        render: (value) => value || 'N/A'
    },
    {
        title: 'Escaneado',
        dataIndex: ['status'],
        render: (value) => {
            const status = buildPackageStatus(value)
            return (
                <TagStyled>
                    <TagCircle bgColor={status.color} /> {status.text}
                </TagStyled>
            )},
    },
]