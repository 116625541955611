import { Button } from 'antd';
import Container from 'components/Grid/Container';
import styled from 'styled-components';
import { HeaderHomeStyle } from 'pages/OrderV2/components/Header/styles';
import { Link } from 'react-router-dom';
import { ReactComponent as NotFoundIcon } from 'assets/icon-order-not-found.svg';

const PageLayout = styled.div`
    text-align: center;
    padding: 2.5rem 2.5rem 2.5rem 1rem;

    h1 {
        color: #142c4a;
        font-weight: 600;
        font-size: 2.5rem;
    }

    h3 {
        color: #142c4a;
        font-weight: 500;
        font-size: 2.5rem;
        margin-top: 2rem;
    }

    .button-not-found-page-back {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    p {
        margin-top: 2rem;
        color: #000000;
    }

    .icon-not-found-page-back-orders {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const NotFoundPage = () => {
    const currentPath = window.location.pathname;
    const isOrdersPage = currentPath.includes('/orders');
    // const isFulfillmentsPage = currentPath.includes('/fulfillments');

    return (
        <Container>
            <HeaderHomeStyle>
                <Link style={{ color: '#7998a7' }} to="/">
                    Home
                </Link>{' '}
                /{' '}
                <Link style={{ color: '#7998a7' }} to={isOrdersPage ? '/orders' : '/fulfillments'}>
                    {isOrdersPage ? 'Pedidos' : 'Preparación'}
                </Link>
            </HeaderHomeStyle>
            <PageLayout>
                <h1>Oops, {isOrdersPage ? 'pedido' : 'fulfillment'} no encontrado</h1>
                <div className="icon-not-found-page-back-orders">
                    <NotFoundIcon />
                </div>
                <h3>404 error</h3>
                <div className="button-not-found-page-back">
                    <Button type="primary" href={isOrdersPage ? '/orders' : '/fulfillments'}>
                        {' '}
                        Volver a {isOrdersPage ? 'pedidos' : 'preparación'}
                    </Button>
                </div>
                <p>Si tienes dudas contacta al administrador</p>
            </PageLayout>
        </Container>
    );
};

export default NotFoundPage;
