import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getUserLogged = createAsyncThunk('users/getLogged', async (username, state) => {
    try {
        const userInLocalStorage = JSON.parse(localStorage.getItem('userLogged') || '{}');
        if (userInLocalStorage?._id) {
            console.log({ userInLocalStorage });
            return state.fulfillWithValue(JSON.parse(userInLocalStorage));
        } else {
            const res = await AndesClient.get(`/users/getUserLogged/${username}`);
            console.log({ res });
            localStorage.setItem('userLogged', JSON.stringify(res.data));
            return state.fulfillWithValue(res.data);
        }
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getUserLoggedSlice = createSlice({
    name: 'getUserLogged',
    initialState: {
        loading: false,
        success: false,
        data: JSON.parse(localStorage.getItem('userLogged') || '{}'),
        error: null,
    },
    reducers: {},
    extraReducers: {
        [getUserLogged.pending]: (state) => {
            return {
                ...state,
                success: false,
                loading: true,
            };
        },
        [getUserLogged.fulfilled]: (state, action) => {
            return {
                ...state,
                success: true,
                data: action.payload,
                loading: false,
            };
        },
        [getUserLogged.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                success: false,
                loading: false,
            };
        },
    },
});

export default getUserLoggedSlice.reducer;
