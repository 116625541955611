import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getHolidaysForMonths = createAsyncThunk(
    'getHolidaysForMonths/getOne',
    async ({ countryCode, holiday_months_range }, state) => {
        try {
            const response = await AndesClient.get(
                `/helpers/getHolidaysCustom/${countryCode}/${holiday_months_range}`
            );
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const getHolidaysForMonthsSlice = createSlice({
    name: 'getHolidaysForMonths',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {
        updateHolidaysForMonthsFromStore: () => {},
    },
    extraReducers: {
        [getHolidaysForMonths.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getHolidaysForMonths.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
                success: true,
            };
        },
        [getHolidaysForMonths.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
                success: false,
            };
        },
    },
});
export const { updateHolidaysForMonthsFromStore } = getHolidaysForMonthsSlice.actions;
export default getHolidaysForMonthsSlice.reducer;
