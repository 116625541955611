import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import CreateManualIncidenceOrderDrawer from 'components/ManualIncidences/Order/CreateIncidenceDrawer';
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import Dropdown from 'components/V2/Dropdown';
import Menu from 'components/V2/Menu';
import MenuItem from 'components/V2/Menu/MenuItem';
import history from 'helpers/history';
import { orderIncidencesTypesFromDeliveriesView } from 'helpers/renderManualIncidencesLabels';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setFulfillmentForDelivery } from 'redux/features/Utils';

const PendingArrivalsActions = ({
    selected = [],
    disabled = false,
    data: dataParaPDF,
    hasStatusNotAllowedRow,
}) => {
    const dispatch = useDispatch();

    const handleMultipleDeliveries = () => {
        const arraySeleccionados = dataParaPDF?.docs.filter((d) => selected.includes(d?._id));
        console.log(arraySeleccionados);
        const noPermitidos = arraySeleccionados?.filter((a) =>
            ['created', 'ready_for_deliver_to_pickup_location'].includes(
                a?.preparationcd_transfer_pickupstore?.status
            )
        );

        if (noPermitidos?.length > 0) {
            toast.error(
                'Ha seleccionado fulfillments donde al menos uno de ellos no puede ser recepcionado.'
            );
        } else {
            if (selected?.length > 0) {
                dispatch(setFulfillmentForDelivery(selected));
                history.push(`/pendingArrival/multiple`);
            }
        }
    };

    const menu = (
        <Menu>
            <MenuItem disabled={hasStatusNotAllowedRow} onClick={handleMultipleDeliveries}>
                Recepcionar entrega ({selected.length})
            </MenuItem>
            <CreateManualIncidenceOrderDrawer
                selectedIds={selected}
                searchFrom="fulfillment"
                incidencesTypes={orderIncidencesTypesFromDeliveriesView}
                orders={dataParaPDF}
                isMenuItem
            />
        </Menu>
    );

    return (
        <Dropdown disabled={disabled} overlay={menu}>
            <PrimaryButton icon={<ChevronDownIcon width={24} height={24} />} iconPosition="right">
                Acciones ({selected.length})
            </PrimaryButton>
        </Dropdown>
    );
};

export default PendingArrivalsActions;
