import { ReactComponent as ChevronLeftIcon } from 'assets/chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from 'assets/chevron-right.svg';
import { ReactComponent as ChevronDownIcon } from 'assets/down.svg';
import { SelectCalendar } from './styles';
import Flex from 'components/Utils/Flex';
import { getMonth, getYear, months, years } from './utils';

export const HeaderReactDatePicker = ({
    monthDate,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
}) => {
    return (
        <Flex justifyContent="space-between" alignItems="center">
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <ChevronLeftIcon color="#1F2937" />
            </button>

            <Flex>
                <SelectCalendar
                    width="120px"
                    suffixIcon={<ChevronDownIcon width={12} height={8} color="#1F2937" />}
                    value={months[getMonth(monthDate)]}
                    onChange={(e) => changeMonth(months.indexOf(e.target.value))}
                >
                    {months.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </SelectCalendar>
                <SelectCalendar
                    width="75px"
                    suffixIcon={<ChevronDownIcon width={12} height={8} color="#1F2937" />}
                    value={getYear(monthDate)}
                    onChange={(e) => changeYear(e.target.value)}
                >
                    {years.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </SelectCalendar>
            </Flex>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <ChevronRightIcon color="#1F2937" />
            </button>
        </Flex>
    );
};
