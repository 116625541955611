import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { toggleModal } from '../Utils';
import { getFulfillmentById } from '../Fulfillments/getOne';

export const createPackage = createAsyncThunk('packages/create', async (data, state) => {
    try {
        const promise = AndesClient.post('/packages/create', data);

        toast.promise(promise, {
            loading: <b>Creando bulto..</b>,
            success: <b>Bulto creado exitosamente</b>,
            error: () => {
                let errorMessage = 'Ocurrió un error.';
                // const errorCode = e?.response?.data?.message ?? '';

                // if (errorCode === 'CodeAlreadyUsed') {
                //     errorMessage = 'Este código ya existe, favor ingrese un código distinto ';
                // }
                return errorMessage;
            },
        });

        const result = await promise;

        if (result.status === 200) {
            state.dispatch(getFulfillmentById(data.fulfillment_id));
            state.dispatch(toggleModal('new_package_modal'));
        }

        return await result.data;
    } catch (error) {
        const errorCode = error?.response?.data?.message ?? '';
        return state.rejectWithValue(errorCode ?? error.message);
    }
});

const createPackageSlice = createSlice({
    name: 'packages-create',
    initialState: {
        loading: false,
        success: false,
        data: {},
        error: null,
    },
    reducers: {
        resetCreatePackageState: (state) => {
            return {
                ...state,
                loading: false,
                success: false,
                data: {},
                error: null,
            };
        },
    },
    extraReducers: {
        [createPackage.pending]: (state) => {
            return {
                ...state,
                loading: true,
                error: null,
            };
        },
        [createPackage.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: true,
                data: action.payload,
                error: null,
            };
        },
        [createPackage.rejected]: (state, payload) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});
export const { resetCreatePackageState } = createPackageSlice.actions;

export default createPackageSlice.reducer;
