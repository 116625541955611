/* eslint-disable no-undef */
import getFromEnv from 'helpers/getFromEnv';
import { Helmet } from 'react-helmet';

const SEO = ({ extraTitle = '' }) => {
    return (
        <Helmet>
            <title>
                {extraTitle && `${extraTitle} | `} {getFromEnv('REACT_APP_TITLE') ?? 'Be Flow'}
            </title>
        </Helmet>
    );
};

export default SEO;
