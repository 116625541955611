import { renderFulFillmentTagSLA } from 'helpers/renderTags';

export const TagSLA = ({ data }) => {
    return (
        <div>
            {renderFulFillmentTagSLA({
                status: data?.fulfillment_status ?? '',
                createdAt: data?.createdAt ?? '',
                promised_date: data?.promised_date ?? '',
                delivery_date: data?.delivery_date ?? '',
                showDate: true,
                showStatus: false,
                isDeadline: true,
                deadline: data?.deadline_custom ?? false,
            })}
        </div>
    );
};
