import { Skeleton, Button } from 'antd';
import ModalWithButton from 'components/ModalWithButton';
import { useDispatch } from 'react-redux';
import { getOrderDocuments } from 'redux/features/Orders/getOrderDocuments';
import { toggleDrawer } from 'redux/features/Utils';
import { toggleModal } from 'redux/features/Utils';

const OrderDocumentButton = ({
    id,
    name,
    // buttonStyle = {},
    // buttonClassName = '',
    // buttonText = '',
    buttonProps = {},
    loading,
    data,
    error,
    // buttonType = 'button',
}) => {
    const dispatch = useDispatch();
    const drawerName = 'global-actions-order-drawer';
    const modalName = 'order-document-modal';

    // const { loading, data, error } = useSelector((state) => state.orderDocuments);

    const handleCloseModal = () => {
        dispatch(toggleDrawer(drawerName));
        dispatch(toggleModal(modalName));
    };

    return (
        <ModalWithButton
            onButtonClick={() => dispatch(getOrderDocuments(id))}
            modalProps={{
                closable: false,
                width: '800px',
                bodyStyle: { height: '600px' },
                name: 'order-document-modal',
                title: `Boleta del pedido ${name}`,
                onCancel: handleCloseModal,
                footer: [
                    <Button
                        style={{
                            backgroundColor: '#F27127',
                            color: 'white',
                            border: '1px solid #E79B09 ',
                        }}
                        key="key"
                        type="primary"
                        onClick={handleCloseModal}
                    >
                        Cerrar
                    </Button>,
                ],
            }}
            // buttonType={buttonType}
            buttonProps={{
                disabled: data === null || loading,
                // style: buttonStyle /* || { height: '40px' } */,
                buttonText: 'Imprimir Boleta',
                ...buttonProps,
            }}
            buttonType="menuitem"
        >
            {loading && <Skeleton height="100%" />}
            {!loading && error && ''}
            {!loading && (
                <iframe
                    title="Documento"
                    src={data?.url}
                    style={{ width: '100%', height: '100%' }}
                    frameBorder="0"
                />
            )}
        </ModalWithButton>
    );
};

export default OrderDocumentButton;
