import { Button } from 'antd';
import styled, { css } from 'styled-components';

export const PrimaryButtonStyled = styled(Button)`
    min-width: max-content;
    min-height: max-content;
    padding: 0 2rem;
    border: 0px;
    border-radius: 3px;
    font-weight: 500;

    ${({ width }) =>
        width &&
        css`
            width: ${width};
        `}
    ${({ height }) =>
        height &&
        css`
            height: ${height};
        `}
    ${({ padding }) =>
        padding &&
        css`
            padding: ${padding};
        `}
    ${({ margin }) =>
        margin &&
        css`
            margin: ${margin};
        `}
    ${({ fullCenter }) =>
        fullCenter &&
        css`
            display: flex;
            justify-content: center;
            items-align: center;
            text-align: center;
        `}
`;
