import { useEffect } from 'react';
import useCurrentUser from './useCurrentUser';
import { API, graphqlOperation } from 'aws-amplify';
import { onCreateMessage } from 'graphql/subscriptions';
import onLogout from 'helpers/onLogout';
import { PiStorefrontLight } from 'react-icons/pi';
import { CiWarning } from 'react-icons/ci';
import Flex from 'components/Utils/Flex';
import { IoMdClose } from 'react-icons/io';
import LogoSVG from 'assets/LogoSimple.png';
import toast from 'react-hot-toast';
import useBrowserNotification from './useBrowserNotification';
import { GrUpdate } from 'react-icons/gr';
import { Button } from '@andescommercecloud/beflow-design-system';

const sounds = {
    PopSound: 'https://andes-commerce-cloud-prod-assets.s3.amazonaws.com/sounds/pop1.mp3',
    BubblePopSound:
        'https://andes-commerce-cloud-prod-assets.s3.amazonaws.com/sounds/bubble-pop.mp3',
    CartoonSound: 'https://andes-commerce-cloud-prod-assets.s3.amazonaws.com/sounds/cartoon.mp3',
    UiPositiveSound:
        'https://andes-commerce-cloud-prod-assets.s3.amazonaws.com/sounds/ui-positive.mp3',
};

const soundUrlSelector = (soundName) => {
    if (soundName === 'default') {
        return sounds.PopSound;
    } else if (soundName === 'bubble_pop') {
        return sounds.BubblePopSound;
    } else if (soundName === 'cartoon') {
        return sounds.CartoonSound;
    } else if (soundName === 'ui_positive') {
        return sounds.UiPositiveSound;
    }

    return sounds.PopSound;
};

const newVersionAvailable = () => {
    const handleUpdate = () => {
        if (window?.location?.reload) {
            window.location.reload();
        } else {
            window.location.href;
        }
    };
    toast(
        () => (
            <Flex direction="column" gap="4px">
                <Flex direction="row" justifyContent="space-between" alignItems="center">
                    <GrUpdate
                        style={{
                            alignSelf: 'flex-start',
                            width: '16px',
                            height: '16px',
                            marginRight: '6px',
                        }}
                    />
                    <b style={{ lineHeight: '17px', flex: 1 }}>Nueva actualización disponible</b>
                </Flex>
                <Button
                    onClick={handleUpdate}
                    size="small"
                    variation="link"
                    style={{ border: '0px', padding: '0px', color: 'rgb(45, 61, 118)' }}
                >
                    <b>Haz clic aquí para actualizar</b>
                </Button>
            </Flex>
        ),
        {
            position: 'top-center',
            duration: Infinity,
            style: {
                border: '2px solid rgb(45, 61, 118)',
                padding: '12px',
                boxShadow: '0 0 5px rgb(45, 61, 118)',
                fontSize: '14px',
                alignItems: 'flex-start !important',
            },
        }
    );
};

const showNotification = ({ action, title, linkTo }) => {
    if (!action || !title || !linkTo) {
        return;
    }

    let IconComponent;
    switch (action) {
        case 'NEW_PICKUP_ORDER':
            IconComponent = PiStorefrontLight;
            break;
        case 'SAC_INCIDENCE':
            IconComponent = CiWarning;
            break;
        default: // Icono por defecto
            IconComponent = PiStorefrontLight;
            break;
    }

    toast(
        (t) => (
            <Flex direction="column" gap="10px">
                <Flex direction="row" justifyContent="space-between">
                    <b style={{ lineHeight: '17px', flex: 1 }}>{title}</b>
                    <IoMdClose
                        role="button"
                        style={{
                            cursor: 'pointer',
                            height: '24px',
                            width: '24px',
                        }}
                        onClick={() => {
                            toast.dismiss(t.id);
                        }}
                    />
                </Flex>
                <u
                    role="button"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        toast.dismiss(t.id);
                        window.location = linkTo;
                    }}
                >
                    Click para ver
                </u>
            </Flex>
        ),
        {
            icon: (
                <IconComponent
                    style={{
                        alignSelf: 'flex-start',
                        width: action === 'SAC_INCIDENCE' ? '32px' : '48px',
                        height: '48px',
                        marginTop: '-2px',
                    }}
                />
            ),
            position: 'top-right',
            duration: Infinity,
            style: {
                border: '1px solid rgb(45, 61, 118)',
                padding: '8px',
                boxShadow: '0 0 5px rgb(45, 61, 118)',
                fontSize: '14px',
                alignItems: 'flex-start !important',
            },
        }
    );

    const notification = new Notification(`Beflow - ${title}`, {
        body: `Haz click para ver más detalles`,
        icon: LogoSVG,
    });

    notification.onclick = () => {
        if (linkTo) history.push(linkTo);
        notification.close();
    };
};

const appSyncListenerController = (eventData, user) => {
    const body = eventData?.value?.data?.onCreateMessage ?? {};
    const action = body?.action ?? '';

    const userDataLocalStorage = JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;

    const userSelectedSound =
        userDataLocalStorage?.orders_configuration?.alerts_notification_sound ?? 'default';
    const isSoundEnabled =
        userDataLocalStorage?.orders_configuration?.enable_alerts_notification_sound ?? false;

    const playSound = new Audio(soundUrlSelector(userSelectedSound));

    if (
        [
            'NEW_PICKUP_ORDER',
            'TOAST',
            'NEW_PICKUP_ORDER',
            'SAC_INCIDENCE',
            'NEW_VERSION_RELEASE',
        ].includes(action)
    ) {
        if (isSoundEnabled && userSelectedSound) {
            console.log('PLAY SOUND');
            playSound.play();
            playSound.addEventListener('ended', () => playSound.pause());
            playSound.removeEventListener('ended', () => playSound.pause());
        }
    }

    const currentPath = window?.location?.pathname ?? '';

    if (action === 'TOAST') toast(body?.message);
    if (action === 'REFRESH_PAGE') window.location.reload();
    if (action === 'UPDATE_LOCAL_STORAGE') onLogout();

    if (action === 'UPDATE_ORDER_PAGE') {
        const parsedMessage = JSON.parse(body?.message ?? '{}');
        if (!parsedMessage?.order_id) return;
        if (currentPath.includes('orders') && currentPath.includes(parsedMessage?.order_id)) {
            window.location.reload();
        }
    }

    if (action === 'NEW_PICKUP_ORDER') {
        const parsedMessage = JSON.parse(body?.message ?? '{}');
        if (!parsedMessage?.order_id) return;
        return showNotification({
            action,
            title: 'Se ha recibido un nuevo pedido con retiro en tienda',
            linkTo: `/orders/${parsedMessage?.order_id}`,
            user,
        });
    }

    if (action === 'SAC_INCIDENCE') {
        const parsedMessage = JSON.parse(body?.message ?? '{}');
        if (!parsedMessage?.order_id) return;
        return showNotification({
            action,
            title: 'Se ha creado una nueva incidencia',
            linkTo: `/orders/${parsedMessage?.order_id}`,
            user,
        });
    }

    if (action === 'NEW_VERSION_RELEASE') {
        return newVersionAvailable();
    }
};

const useAppSyncListener = () => {
    useBrowserNotification();
    const user = useCurrentUser();

    useEffect(() => {
        if (!user?.userData?._id || !user?.userData?.merchant_id?._id) return;
        const subscription = API.graphql(
            graphqlOperation(onCreateMessage, {
                user_id: user?.userData?._id ?? '',
                merchant_id: user?.userData?.merchant_id?._id ?? '',
            })
        ).subscribe({
            next: (eventData = {}) => appSyncListenerController(eventData, user),
        });
        return () => subscription.unsubscribe();
    }, [user]);

    useEffect(() => {
        // Instance to AudioContext
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();

        // resume AudioContext
        const resumeAudioContext = () => {
            if (audioContext.state === 'suspended') {
                audioContext.resume().then(() => {
                    console.log('AudioContext resumed');
                });
            }
        };

        // Add event listeners for user gesture
        document.addEventListener('click', resumeAudioContext);
        document.addEventListener('keydown', resumeAudioContext);

        // clean events
        return () => {
            document.removeEventListener('click', resumeAudioContext);
            document.removeEventListener('keydown', resumeAudioContext);
        };
    }, []);
};

export default useAppSyncListener;
