import { Form, Select } from 'antd';
import CheckboxStyled from 'components/Utils/CheckboxStyled';
import { SelectMultiple } from 'components/V2/SelectMultiple';
import { Title } from 'pages/Settings/styles';
import { useMemo } from 'react';
import { BoxMoreOptions } from './styles';

const orderCanceledStatusOptions = [
    { title: 'Pendiente', value: 'unfulfilled' },
    { title: 'En proceso', value: 'in_progress' },
    { title: 'Preparado', value: 'fulfilled' },
    { title: 'Despachado', value: 'delivered_to_courier' },
    { title: 'SAC', value: 'sac' },
    { title: 'Archivado', value: 'archived' },
];

const rulesSelect = [
    {
        required: true,
        message: 'Este campo es requerido',
    },
];

export const ActionCancelledOrderForm = ({ form, dataShops }) => {
    const hasTransitWarehouse = Form.useWatch('has_transit_warehouse', form);
    const hasReturnLocationId = Form.useWatch('has_return_location_id', form);

    const locations = useMemo(
        () =>
            dataShops
                .filter((d) => d?.has_devolution === true)
                .filter((activa) => activa.is_enabled === true)
                .sort((a, b) => {
                    if (a.name > b.name) return 1;
                    else if (a.name < b.name) return -1;

                    return 0;
                }),
        [dataShops]
    );

    return (
        <div>
            <Title>Cancelación de pedidos</Title>

            <Form.Item
                name="allow_cancel_in_status"
                label={
                    <span>
                        Permitir cancelación de ventas en los siguientes estados &nbsp;
                        <span style={{ color: '#536D8F' }}>(opcional)</span>
                    </span>
                }
            >
                <SelectMultiple
                    placeholder="Seleccione"
                    options={orderCanceledStatusOptions}
                    optionTitleKey="title"
                    optionValueKey="value"
                    // value={allowCancelInStatus}
                    style={{ maxWidth: 700 }}
                />
            </Form.Item>
            <br />

            <Form.Item name="has_transit_warehouse" valuePropName="checked">
                <CheckboxStyled
                    onChange={(value) =>
                        !value.target.checked && form.setFieldValue('transit_warehouse', undefined)
                    }
                >
                    Pedidos con devoluciones se gestionan con bodega de tránsito
                </CheckboxStyled>
            </Form.Item>

            {hasTransitWarehouse && (
                <BoxMoreOptions className="ant-select-label-secondary">
                    <Form.Item
                        name="transit_warehouse"
                        label="Bodega de tránsito"
                        rules={rulesSelect}
                    >
                        <Select placeholder="Seleccione">
                            {locations.map((n) => (
                                <Select.Option key={n?._id} value={n?._id}>
                                    {n?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </BoxMoreOptions>
            )}

            <Form.Item
                name="has_return_location_id"
                valuePropName="checked"
                onChange={(value) => {
                    console.log('value', value.target.checked);
                    !value.target.checked && form.setFieldValue('return_location_id', undefined);
                }}
            >
                <CheckboxStyled>
                    Pedidos con devoluciones se gestionan con bodega de devoluciones
                </CheckboxStyled>
            </Form.Item>

            {hasReturnLocationId && (
                <BoxMoreOptions className="ant-select-label-secondary">
                    <Form.Item
                        name="return_location_id"
                        label="Bodega de devoluciones"
                        rules={rulesSelect}
                    >
                        <Select placeholder="Seleccione">
                            {locations.map((n) => (
                                <Select.Option key={n?._id} value={n?._id}>
                                    {n?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </BoxMoreOptions>
            )}

            <Form.Item name="allow_upstream_state_notification" valuePropName="checked">
                <CheckboxStyled>Habilitar informe de cambios de estados</CheckboxStyled>
            </Form.Item>
        </div>
    );
};
