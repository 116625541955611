import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import { useLazyGetErrorsQuery } from 'redux/features/Errors';
import store from '../../redux';
import React, { Suspense, useEffect } from 'react';

const ErrorsTable = React.lazy(() => import('./components/ErrorsTable'));

const IndexPage = () => {
    const [getErrors, { data, isFetching, isLoading }] = useLazyGetErrorsQuery();

    console.log({ data });

    const loading = isFetching || isLoading;

    const handleRefetch = (forceRefetch) => {
        const utils = store.getState().utils;
        const filters = utils.errorsFilters;
        getErrors(filters, !forceRefetch);
    };

    useEffect(() => {
        if (!data) {
            handleRefetch();
        }
    }, []);

    return (
        <Container loading={loading} extraTitle='Dashboard'>
            <PageHeader title='Errores' />
            <Suspense fallback={<></>}>
                <ErrorsTable
                    data={data}
                    loading={loading}
                    error={null}
                    refetch={handleRefetch}
                    showFilters
                />
            </Suspense>
        </Container>
    );
};

export default IndexPage;
