import qs from 'qs';
import { useGetManifestsReportsQuery } from 'redux/features/Reports';
import MetricCard from 'components/V2/Metrics/MetricCard';
import { ReactComponent as PendingMetricsIcon } from 'assets/pending-metrics.svg';
import { ReactComponent as ShoppingBag } from 'assets/shopping-bag.svg';
import { ReactComponent as DeliveredIcon } from 'assets/trolley-svgrepo-com.svg';
import { MetricsContainer } from '../styles';
import { useMemo } from 'react';

const ManifestsMetrics = ({ selectedDateRange, selectedLocation, deliveryPreferences = {} }) => {
    // Para obtener datos para las metricas
    const metricsObject = {
        ...selectedDateRange,
        // location_id: !['', 'all'].includes(shopSelected) ? shopSelected : undefined,
        origin: selectedLocation,
    };
    const {
        data = {},
        isLoading,
        isFetching,
    } = useGetManifestsReportsQuery(qs.stringify(metricsObject), {
        pollingInterval: 1800000,
    });

    const { pickupHaveTwoStep, shippingHaveTwoStep, havePickup, haveShipping } = useMemo(() => {
        return {
            pickupHaveTwoStep:
                deliveryPreferences?.delivery_options?.pickup?.enable_two_step_delivery ?? true,
            shippingHaveTwoStep:
                deliveryPreferences?.delivery_options?.shipping?.enable_two_step_delivery ?? true,
            havePickup: deliveryPreferences?.delivery_options?.pickup?.enabled ?? true,
            haveShipping: deliveryPreferences?.delivery_options?.shipping?.enabled ?? true,
        };
    }, [deliveryPreferences]);

    const metrics = [
        {
            name: 'Manifiestos pendientes de entrega al courier',
            type: 'delivery_pending_count',
            quantity: data?.delivery_pending_count ?? 0,
            measurement: '',
            tooltipTitle:
                'Manifiestos de entrega de pedidos con despacho a domicilio, que se encuentran en estado pendiente por ser entregados al courier, para la tienda seleccionada en el plazo especificado.',
            icon: <PendingMetricsIcon width={24} height={24} color="#2D3D76" />,
            color: '#7998A7',
            show: shippingHaveTwoStep,
        },
        {
            name: 'Manifiestos pendientes de entrega con retiro en tienda',
            type: 'pickup_pending_count',
            quantity: data?.pickup_pending_count ?? 0,
            measurement: '',
            tooltipTitle:
                'Manifiestos de entrega de pedidos con retiro en tienda que se encuentran en estado pendiente de entrega, para la tienda seleccionada en el plazo especificado.',
            icon: <PendingMetricsIcon width={24} height={24} color="#2D3D76" />,
            color: '#B00303',
            show: pickupHaveTwoStep,
        },
        {
            name: 'Manifiestos entregados al courier',
            type: 'shipping_delivered_count',
            quantity: data?.shipping_delivered_count ?? 0,
            measurement: '',
            tooltipTitle:
                'Manifiestos de entrega con pedidos de despacho a domicilio, que ya fueron entregados al courier, para la tienda seleccionada en el plazo especificado.',
            icon: <DeliveredIcon width={24} height={24} color="#2D3D76" />,
            color: '#037FB9',
            show: haveShipping,
        },
        {
            name: 'Manifiestos con retiro entregados',
            type: 'pickup_delivered_count',
            quantity: data?.pickup_delivered_count ?? 0,
            measurement: '',
            tooltipTitle:
                'Manifiestos de entrega de pedidos con retiro en tienda que ya fueron entregados al cliente, para la tienda seleccionada en el plazo especificado.',
            icon: <ShoppingBag width={24} height={24} color="#2D3D76" />,
            color: '#7998A7',
            show: havePickup,
        },
    ];

    return (
        <MetricsContainer>
            {metrics
                .filter((metric) => metric.show)
                .map((metric) => (
                    <MetricCard
                        key={metric.name}
                        color={metric.color}
                        name={metric.name}
                        tooltipTitle={metric.tooltipTitle}
                        metricType={metric.type}
                        quantity={metric.quantity}
                        measurement={metric.measurement}
                        icon={metric.icon}
                        loading={isLoading || isFetching}
                    />
                ))}
        </MetricsContainer>
    );
};

export default ManifestsMetrics;
