import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const logMessagesApi = createApi({
    reducerPath: 'logMessages',
    baseQuery,
    tagTypes: ['logMessages'],
    endpoints: (builder) => ({
        getLogMessage: builder.mutation({
            method: 'get',
            query: (id) => {
                return {
                    url: `/messages/logs/${id}`,
                };
            },
            providesTags: ['logMessages'],
        }),
        createLogMessage: builder.mutation({
            query: (data) => {
                return {
                    method: 'post',
                    url: `/messages/logs`,
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Publicando comentario...</b>,
                    success: <b>Comentario publicado con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['logMessages'],
        }),
        updateLogMessage: builder.mutation({
            query: ({ id, ...body }) => {
                return {
                    method: 'PATCH',
                    url: `/messages/logs/${id}`,
                    body,
                };
            },
            onQueryStarted: async (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando comentario...</b>,
                    success: <b>Comentario actualizado con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['logMessages'],
        }),
        deleteLogMessage: builder.mutation({
            query: (id) => {
                return {
                    method: 'delete',
                    url: `/messages/logs/${id}`,
                    body: {},
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Eliminando comentario...</b>,
                    success: <b>Comentario eliminado con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['logMessages'],
        }),
    }),
});

export const {
    useGetLogMessageMutation,
    useCreateLogMessageMutation,
    useDeleteLogMessageMutation,
    useUpdateLogMessageMutation,
} = logMessagesApi;
