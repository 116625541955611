import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import AndesClient from 'services/AndesClient';
import { getAllSalesChannel } from './getAllSalesChannel';

export const createSalesChannel = createAsyncThunk('salesChannel/create', async (data, state) => {
    try {
        const promise = AndesClient.post(`/settings/sales-channels`, data);

        toast.promise(promise, {
            loading: <b>Creando canal de venta...</b>,
            success: <b>Canal de venta creado con éxito</b>,
            error: <b>Ocurrió un error.</b>,
        });

        const result = await promise;

        if (result.status === 200) {
            state.dispatch(getAllSalesChannel());
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const createSalesChannelSlice = createSlice({
    name: 'createSalesChannel',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createSalesChannel.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createSalesChannel.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createSalesChannel.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createSalesChannelSlice.reducer;
