import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getAllShops = createAsyncThunk('stores-warehouses/getAll', async (_, state) => {
    try {
        const { data } = await AndesClient.get(`/locationsV2/`);
        return Array.from(data ?? []).sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getAllShopsSlice = createSlice({
    name: 'getAllShops',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {
        addShopsToStore: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                data: [...state.data, payload],
            };
        },
        deleteShopsFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.filter(({ _id }) => _id !== payload);
            return {
                ...state,
                data: [...newArrayData],
            };
        },
        updateShopsFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.map((shop) => {
                if (shop._id === payload._id) {
                    return {
                        ...shop,
                        ...payload,
                    };
                } else {
                    return shop;
                }
            });
            return {
                ...state,
                data: [...newArrayData],
            };
        },
    },
    extraReducers: {
        [getAllShops.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getAllShops.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [getAllShops.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});
export const { addShopsToStore, deleteShopsFromStore, updateShopsFromStore } =
    getAllShopsSlice.actions;
export default getAllShopsSlice.reducer;
