import styled from 'styled-components';
import Flex from 'components/Utils/Flex';
import ClosableTag from 'components/Tag/ClosableTag';

const filterValueLabel = {
    pending: 'Pendiente',
    in_progress: ' En proceso',
    resolved: 'Resuelto',
    'create-document-dte': 'Creacion de documentos',
    'create-reserve-invoice-vyv-prism': 'Factura de reserva - Prism',
    'create-sales-order-vyv-prism': 'SO - Prism',
    'stock-transfer-vyv-prism': 'Transferencia de stock - Prism',
    'close-order-vyv-prism': 'Cierre de SO - Prism',
};

const DataFiltersTags = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
`;

const SelectedLabel = styled.span`
    display: flex;
    margin-right: 10px;
    font-size: 1rem;
`;

const ErrorsFiltersTags = ({ errorsFilters, removeFilter = () => {} }) => {
    const isAllEmpty = Object.values(errorsFilters).every((value) => {
        if (!value) {
            return true;
        }
        if (Array.isArray(value)) {
            return value.length === 0;
        }
        return false;
    });

    return (
        <Flex direction="column">
            <DataFiltersTags>
                {!isAllEmpty && <SelectedLabel> Seleccionados: {'  '} </SelectedLabel>}

                {Object.keys(errorsFilters).map((key) => {
                    const type = typeof errorsFilters[key];
                    const value = errorsFilters[key];
                    const keyLabel = filterValueLabel[key];

                    if (!value) return null;

                    if (type === 'string') {
                        let valueLabel = filterValueLabel[value] ?? value;

                        if (key === 'search_terms') {
                            valueLabel = `${value}`;
                        }

                        return (
                            <ClosableTag
                                key={key}
                                onClose={() => removeFilter(key, 'string', value)}
                            >
                                <strong>{keyLabel}</strong>
                                <span>{valueLabel === 'Notification' ? 'Notificación' : valueLabel}</span>
                            </ClosableTag>
                        );
                    }

                    if (Array.isArray(value)) {
                        return value.map((item) => {
                            let valueLabel = filterValueLabel[item];
                            return (
                                <ClosableTag
                                    key={item}
                                    onClose={() => removeFilter(key, 'array', item)}
                                >
                                    <span>{valueLabel ?? item}</span>
                                </ClosableTag>
                            );
                        });
                    }

                    if (type === 'object') {
                        if (Object.keys(value).length === 0) return null;
                        if (key === 'sort') {
                            return (
                                <ClosableTag
                                    key={key}
                                    onClose={() => removeFilter(key, 'object', value)}
                                >
                                    <Flex direction="row">
                                        <strong>Ordenando por: </strong>
                                        <span>
                                            {filterValueLabel[value.sortBy]} -{' '}
                                            {filterValueLabel[value.sortValue]}
                                        </span>
                                    </Flex>
                                </ClosableTag>
                            );
                        }
                    }
                })}
            </DataFiltersTags>
        </Flex>
    );
};

export default ErrorsFiltersTags;
