export const defaultColumnsView = [
    {
        name: 'manifest_id',
        label: 'ID de manifiesto',
        status: true,
        position: 1,
    },
    {
        name: 'fulfillments_length',
        label: 'Pedidos en entrega',
        status: true,
        position: 2,
    },
    {
        name: 'promised_date',
        label: 'Plazo límite',
        status: true,
        position: 3,
    },
    {
        name: 'status',
        label: 'Estado entrega',
        status: true,
        position: 4,
    },
    {
        name: 'createdAt',
        label: 'Listo para entregar desde',
        status: true,
        position: 5,
    },
    {
        name: 'delivered_at',
        label: 'Fecha de entrega',
        status: true,
        position: 6,
    },
    {
        name: 'courier',
        label: 'Courier',
        status: true,
        position: 7,
    },
    {
        name: 'delivery_method',
        label: 'Tipo de entrega',
        status: true,
        position: 8,
    },
    {
        name: 'documents',
        label: 'Documentos',
        status: true,
        position: 9,
    },
    {
        name: 'actions',
        label: 'Acciones',
        status: true,
        position: 10,
    },
];

export const INITIAL_PAGE_SIZE = 25;
