// import { FiBell } from 'react-icons/fi';
import {
    NotificationWrap,
    // NotificationsBox,
    // NotificationsContent,
    // NotificationsHeader,
} from './styles';
// import { useState } from 'react';
// import { GrClose } from 'react-icons/gr';
import NotificationsDrawer from 'components/Notifications/NotificationsDrawer';

const NotificationsV2 = () => {
    // const [openNotifications, setOpenNotifications] = useState(false);

    return (
        <div>
            <NotificationWrap
            // onClick={() => setOpenNotifications(!openNotifications)}
            >
                {/* <FiBell />
                <NotificationsBox $open={openNotifications} onClick={(e) => e.stopPropagation()}>
                    <NotificationsHeader>
                        <b>Notificaciones</b>
                        <GrClose onClick={() => setOpenNotifications(!openNotifications)} />
                    </NotificationsHeader>
                    <NotificationsContent></NotificationsContent>
                </NotificationsBox> */}

                <NotificationsDrawer />
            </NotificationWrap>
        </div>
    );
};

export default NotificationsV2;
