import { Modal } from 'antd';
import { AvatarChip, Text, Textarea, Typography } from './styles';
import Flex from 'components/Utils/Flex';
import { useState } from 'react';

export const ModalCreateComment = ({
    isModalCommentVisible,
    setIsModalCommentVisible,
    userData,
    onCreateComment,
    loading,
    currentLog
}) => {
    const [commentary, setCommentary] = useState('');

    const handleCreate = () => {
        setCommentary('');
        onCreateComment(commentary);
    };

    return (
        <Modal
            title={<Typography color="#2D3D76">Agregar comentario</Typography>}
            visible={isModalCommentVisible}
            okButtonProps={{
                type: 'primary',
                style: { background: '#F27127', border: 'none' },
                disabled: loading,
            }}
            onOk={handleCreate}
            okText="Agregar comentario"
            onCancel={() => setIsModalCommentVisible(false)}
        >
            <Flex direction="column">
                <Text>Estas agregando un comentario en el evento:</Text>
                <Text color="#2D3D76" italic>
                    {currentLog?.description}
                </Text>
                <Text color="#2D3D76" fontWeight="500" mt="1rem">
                    Comentario o nota:{' '}
                </Text>
                <Flex columnGap="8px">
                    <AvatarChip>
                        {userData?.firstname?.substring(0, 1)}
                        {userData?.lastname?.substring(0, 1)}
                    </AvatarChip>
                    <Textarea
                        value={commentary}
                        onChange={(e) => setCommentary(e.target.value)}
                        placeholder="Escriba aquí"
                        disabled={loading}
                    />
                </Flex>
            </Flex>
        </Modal>
    );
};
