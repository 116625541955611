import Container from 'components/Grid/Container';
import StockForm from './components/StockForm';
import { useLazyGetStockProductsQuery } from 'redux/features/Stock';
import { useState } from 'react';
import StockHistory from './components/StockHistory';
import IF from 'components/Utils/IF';
import usePermissionAdmin from 'hooks/usePermissionAdmin';
import PageHeader from 'components/Layout/PageHeader';

const StockSettings = () => {
    const [renderView, setRenderView] = useState('stock_form');
    const [sku, setSku] = useState('');
    const [productId, setProductId] = useState('');
    const [location_id, setLocationId] = useState('');
    const [isAdmin] = usePermissionAdmin();
    const [getProductsStock, { isLoading, isFetching, data }] = useLazyGetStockProductsQuery();

    const handleSearch = async (values) => {
        setSku(values.sku);
        setLocationId(values.location_id);
        await getProductsStock(values);
    };

    const showStockForm = renderView === 'stock_form';
    const showStockHistory = renderView === 'stock_history';

    if (!isAdmin) {
        return (
            <span style={{ marginTop: '30px', marginLeft: '30px' }}>
                Su perfil no tiene el permiso para acceder a la configuración.
            </span>
        );
    }

    return (
        <Container extraTitle="Gestión de Stock">
            <PageHeader title="Gestión de Stock" withMarginBottom={false} />

            <IF condition={showStockForm}>
                <StockForm
                    handleSearch={handleSearch}
                    data={data}
                    dataLoading={isLoading || isFetching}
                    setRenderView={setRenderView}
                    setSku={setSku}
                    setProductId={setProductId}
                />
            </IF>

            <IF condition={showStockHistory} preventRender>
                <StockHistory
                    sku={sku}
                    productId={productId}
                    setProductId={setProductId}
                    location_id={location_id}
                    setRenderView={setRenderView}
                />
            </IF>
        </Container>
    );
};

export default StockSettings;
