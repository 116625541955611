import { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { calculateIfAllItemsPicked } from '../utils';
import {
    useCompleteFulfillmentV2Mutation,
    useRemoveFulfillmentFromListMutation,
} from 'redux/features/Fulfillments';
import toast from 'react-hot-toast';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import Flex from 'components/Utils/Flex';
import ModalWithButton from 'components/ModalWithButton';
import NormalButton from 'components/Buttons/NormalButton';
import { ArchiveIcon } from '@heroicons/react/solid';
import { Button } from 'antd';
import { openModal, closeModal } from 'redux/features/Utils';
import styled from 'styled-components';
import history from 'helpers/history';
import { ButtonRemoveList } from './styles';
import { useLocation } from 'react-router-dom';

const MultiplePreparationArrowsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    gap: 0.3rem;
    font-size: 12px;

    svg {
        font-size: 1rem;
    }
`;

const ButtonsMultiplePreparation = styled.div`
    // border: 1px solid red;
    border: 1px solid #e4e8ec;
    border-radius: 4px;
`;

const MultiplePreparationNumbers = styled.div`
    // border: 1px solid #e4e8ec;
    color: #536d8f;
    border: none;
    border-left: 1px solid #e4e8ec;
    border-right: 1px solid #e4e8ec;
    padding: 12px 10px;
`;

const ArrowButton = styled(Button)`
    // border: 1px solid #e4e8ec;
    padding: 4px 10px !important;
    border: none;
    border-radius: ${({ right }) => (right ? '0px 4px 4px 0px' : '4px 0px 0px 4px')};
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e4e8ec;
        border: none;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
`;

export const CompleteFulfillmentButton = ({
    fulfillment,
    fulfillmentList = { fulfillments: [] },
    showMoreHeader,
}) => {
    const completePreparationButtonRef = useRef(null);
    const confirmPreparationButtonRef = useRef(null);
    const [disableCompleteButton, setDisableCompleteButton] = useState(false);
    const dispatch = useDispatch();

    const confirmModalName = `send-to-deliveries-modal-${fulfillment?._id}`;
    const idButtonModal = 'complete-fulfillment-button';

    const fulfillments = fulfillmentList?.fulfillments ?? [];

    const packages = fulfillment?.packages ?? [];
    // const blockPreparation = fulfillment.userDifferentLocation;
    const blockPreparation = false;
    const allPicked = calculateIfAllItemsPicked(fulfillment);

    const [completeFulfillment, completeFulfillmentStatus] = useCompleteFulfillmentV2Mutation();
    const [removeFromFulfillmentList] = useRemoveFulfillmentFromListMutation();

    const location = useLocation();

    const currentFulfillmentIndex = fulfillments.findIndex(
        (fulfillmentItem) => fulfillmentItem?._id === fulfillment?._id
    );

    const isTheFirstFulfillment = !fulfillmentList?._id ? true : currentFulfillmentIndex === 0;

    const isTheLastFulfillment = !fulfillmentList?._id
        ? true
        : currentFulfillmentIndex === fulfillments.length - 1;

    const goToPreviousFulfillment = () => {
        const previousFulfillment = fulfillments[currentFulfillmentIndex - 1];
        history.push(`/fulfillments/${previousFulfillment?._id}/${fulfillmentList?._id}`);
    };

    const goToNextFulfillment = () => {
        const nextFulfillment = fulfillments[currentFulfillmentIndex + 1];
        history.push(`/fulfillments/${nextFulfillment?._id}/${fulfillmentList?._id}`);
    };

    useEffect(() => {
        return () => {
            dispatch(closeModal(confirmModalName));
        };
    }, []);

    const handleCompleteFulfillment = async () => {
        try {
            if (disableCompleteButton) return;

            setDisableCompleteButton(true);

            const response = await completeFulfillment({
                order_id: fulfillment.order?._id,
                fulfillment_id: fulfillment?._id,
            });

            dispatch(closeModal(confirmModalName));
            setDisableCompleteButton(false);

            if (
                (fulfillments.length === 0 && response?.data) ||
                (isTheLastFulfillment && response?.data)
            ) {
                const filters = location?.state?.filters;
                history.push('/fulfillments?activeTab=ready_for_deliver', { filters });
            }

            if (!isTheLastFulfillment && response?.data) {
                goToNextFulfillment();
            }
        } catch (error) {
            console.error(error);
        } finally {
            setDisableCompleteButton(false);
            dispatch(closeModal(confirmModalName));
        }
    };

    const handleRemoveFromList = async () => {
        const response = await removeFromFulfillmentList({
            fulfillment_id: fulfillment?._id,
            fulfillment_list_id: fulfillmentList?._id,
        });

        const pendingFulfillments = response?.data?.fulfillments?.filter((item) =>
            ['in_progress', 'open', 'pending'].includes(item.status)
        );

        if (
            Array.from(response?.data?.fulfillments ?? []).length === 0 ||
            pendingFulfillments?.length === 0
        ) {
            toast.error('Se removieron de la lista todos los pedidos a preparar');
            return history.push(`/fulfillments?activeTab=ready_for_deliver`);
        }
        goToNextFulfillment();
    };

    const disabledButton =
        completeFulfillmentStatus.isLoading ||
        blockPreparation ||
        packages.some((pkg) => pkg.status === 'open' || pkg.status === 'processed') ||
        !packages ||
        packages.length === 0 ||
        !allPicked ||
        [
            'paused',
            'cancelled',
            'success',
            'delivered',
            'delivered_to_courier',
            'ready_for_deliver',
        ].includes(fulfillment.status) ||
        disableCompleteButton;

    useEffect(() => {
        if (allPicked && completePreparationButtonRef.current) {
            completePreparationButtonRef.current.focus();
        }
    }, [allPicked, fulfillment]);

    let lastOCRKeyValues = '';
    let openCompleteFulfillmentModalTimeout;

    const handleOpenModalCompleteFulfillmentWithEnter = async (event) => {
        lastOCRKeyValues += event?.key ?? '';
        clearTimeout(openCompleteFulfillmentModalTimeout);
        openCompleteFulfillmentModalTimeout = setTimeout(async () => {
            if (lastOCRKeyValues === 'Enter') {
                dispatch(openModal(confirmModalName));
            }
            lastOCRKeyValues = '';
        }, 250);
    };

    let lastOCRKeyValuesForConfirmButton = '';
    let completeFulfillmentTimeout;

    const handleCompleteFulfillmentWithEnter = async (event) => {
        lastOCRKeyValuesForConfirmButton += event?.key ?? '';
        clearTimeout(completeFulfillmentTimeout);
        completeFulfillmentTimeout = setTimeout(async () => {
            if (lastOCRKeyValuesForConfirmButton === 'Enter') {
                handleCompleteFulfillment();
            }
            lastOCRKeyValuesForConfirmButton = '';
        }, 250);
    };
    return (
        <Flex direction="column" rowGap="1rem">
            <Flex direction="row" /* justifyContent="flex-end" */>
                <ModalWithButton
                    modalProps={{
                        cancelText: 'Cancelar',
                        okText: 'Confirmar',
                        title: (
                            <span style={{ fontSize: '16px', color: '#2D3D76', fontWeight: '600' }}>
                                Completar preparación
                            </span>
                        ),
                        name: confirmModalName,
                        destroyOnClose: true,
                        okButtonProps: {
                            loading: completeFulfillmentStatus.isLoading,
                            disabled: disabledButton,
                            ref: confirmPreparationButtonRef,
                            autoFocus: true,
                            id: idButtonModal,
                            onClick: (e) => {
                                e.preventDefault();
                                if (e.screenX !== 0) handleCompleteFulfillment();
                            },
                            onKeyUp: (e) => {
                                e.preventDefault();
                                handleCompleteFulfillmentWithEnter(e);
                            },
                        },
                    }}
                    buttonProps={{
                        loading: completeFulfillmentStatus.isLoading,
                        disabled:
                            completeFulfillmentStatus.isLoading ||
                            blockPreparation ||
                            disableCompleteButton,
                        style: { display: 'none' },
                    }}
                >
                    <b style={{ color: '#142C4A' }}>
                        ¿Confirmas haber completado la preparación del pedido?
                    </b>
                </ModalWithButton>
                {/* <Button>Pausar pedido</Button> */}
                <NormalButton
                    ref={completePreparationButtonRef}
                    icon={<ArchiveIcon width="16px" height="16px" />}
                    iconPosition="left"
                    loading={completeFulfillmentStatus.isLoading}
                    buttonText="Completar preparación"
                    disabled={disabledButton}
                    onKeyUp={(e) => {
                        e.preventDefault();
                        handleOpenModalCompleteFulfillmentWithEnter(e);
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        if (e.screenX !== 0) dispatch(openModal(confirmModalName));
                    }}
                />
            </Flex>

            {showMoreHeader && fulfillments.length > 0 && (
                <MultiplePreparationArrowsWrapper>
                    <span>Preparando pedido: {fulfillment?.order?.remote_order_id} </span>
                    <ButtonsMultiplePreparation>
                        <Flex direction="row" alignItems="center" /* columnGap="1rem" */>
                            <ArrowButton
                                right={false}
                                // type="button"
                                disabled={isTheFirstFulfillment}
                                onClick={goToPreviousFulfillment}
                            >
                                <MdKeyboardArrowLeft style={{ fontSize: '26px' }} />
                            </ArrowButton>
                            <MultiplePreparationNumbers>
                                {currentFulfillmentIndex + 1} de {fulfillments.length}
                            </MultiplePreparationNumbers>
                            <ArrowButton
                                right={true}
                                disabled={isTheLastFulfillment}
                                onClick={goToNextFulfillment}
                            >
                                <MdKeyboardArrowRight style={{ width: '25px', height: '25px' }} />
                            </ArrowButton>
                        </Flex>
                    </ButtonsMultiplePreparation>
                    <ButtonRemoveList onClick={handleRemoveFromList}>
                        Quitar de la lista
                    </ButtonRemoveList>
                </MultiplePreparationArrowsWrapper>
            )}
        </Flex>
    );
};
