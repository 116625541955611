import Container from 'components/Grid/Container';
import { SkeletonContainer, SkeletonDiv } from './styles';
import { Skeleton } from 'antd';

export const LoadingLayout = () => {
    return (
        <Container>
            <SkeletonContainer>
                <SkeletonDiv className="div1">
                    <Skeleton active />
                </SkeletonDiv>
                <SkeletonDiv className="div2">
                    <Skeleton style={{ height: '500px' }} active />
                </SkeletonDiv>
                <SkeletonDiv className="div3">
                    <Skeleton style={{ height: '500px' }} active />
                </SkeletonDiv>
                <SkeletonDiv className="div4">
                    <Skeleton active />
                </SkeletonDiv>
            </SkeletonContainer>
        </Container>
    );
};
