import { useCallback } from 'react';
import { useGetSeparateTicketsMutation } from '../../../redux/features/Deliveries';
import { downloadPdf } from 'helpers/dowloadPdf';

export const ModalTicket = ({ urlPDF, showModal, setPdfsOk }) => {
    const [getSeparateTickets] = useGetSeparateTicketsMutation();

    const handleViewTicket = useCallback(async () => {
        if (urlPDF) {
            const { data: pdfPages } = await getSeparateTickets({ ticket_url: urlPDF });
            if (pdfPages) {
                if (pdfPages) {
                    const tickets = await Promise.all(
                        pdfPages.map((ticketUrl) => downloadPdf(ticketUrl))
                    );
                    setPdfsOk(tickets);
                    showModal();
                }
            }
        }
    }, [urlPDF]);

    return (
        <a onClick={handleViewTicket} className="ml-2">
            <b>
                <u>Ver etiqueta operador logístico</u>
            </b>
        </a>
    );
};
