import { Checkbox, Select } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';

export const SelectMultiple = ({
    placeholder,
    onChange,
    value,
    optionTitleKey,
    optionValueKey,
    optionDisabled,
    options,
    children,
    style,
}) => {
    const [optionsSelected, setOptionsSelected] = useState([]);

    const handleChange = (e) => {
        setOptionsSelected(e);
        onChange(e);
    };

    const isCheckboxChecked = (optionValue) => {
        return optionsSelected.includes(optionValue);
    };

    useEffect(() => {
        value && setOptionsSelected(value);
    }, [value]);

    return (
        <Select
            mode="tags"
            placeholder={placeholder}
            onChange={handleChange}
            maxTagCount={0}
            value={value}
            optionLabelProp="title"
            showArrow
            showSearch={false}
            style={style}
        >
            {options?.length
                ? Array.from(options ?? []).map((option, idx) => {
                      const optionTitle = option[optionTitleKey];
                      const optionValue = option[optionValueKey];
                      return (
                          <Select.Option
                              key={`${optionValue}-${idx}}`}
                              value={optionValue}
                              title={optionTitle}
                              disabled={optionDisabled && optionDisabled(option)}
                          >
                              <Checkbox
                                  style={{ marginRight: '10px' }}
                                  value={option._id}
                                  checked={isCheckboxChecked(optionValue)}
                                  disabled={optionDisabled && optionDisabled(option)}
                              />
                              <label>{optionTitle}</label>
                          </Select.Option>
                      );
                  })
                : children}
        </Select>
    );
};
