import { Button, Typography } from '@andescommercecloud/beflow-design-system';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { ReactComponent as EditIcon } from 'assets/edit-icon3.svg';
import { Checkbox } from 'components/Form';
import Flex from 'components/Utils/Flex';
import FormStyled from 'components/Utils/Form';
import regionsData from 'data/regionsData';
import dayjs from 'dayjs';
import MoneyFormat from 'helpers/MoneyFormat';
import history from 'helpers/history';
import normalizeString from 'helpers/normalizeString';
import useAllCouriers from 'hooks/useAllCouriers';
import useAllShops from 'hooks/useAllShops';
import { useEffect, useMemo, useState } from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { setChangeStoreValues } from 'redux/features/ChangeStore';
import { useGetCourierAddressMappingsQuery } from 'redux/features/CourierAddressMappings';
import { useGetShippingPriceMutation } from 'redux/features/Fulfillments';
import { useChangeOrderStoreMutation } from 'redux/features/Orders';
import { ResolvedItems, ResolvedItemsList } from '../styles';
import IncidenceSelector from './IncidenceSelector';
import { FLOWS } from './constants';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';
import { LocationByCountryForm } from 'pages/Settings/components/Shops2/LocationByCountryForm';
import useGetGenerals from 'hooks/useGetGenerals';

const renderDeliveryMethodLabel = {
    shipping: 'Despacho',
    pickup: 'Retiro',
};

const ChangeConfirmation = ({ order, handleBackStep, currentStepForComeback, fulfillmentId }) => {
    const changeStoreData = useSelector((state) => state.changeStore);
    const [loadingCouriers, couriers] = useAllCouriers();
    const addressMappings = useGetCourierAddressMappingsQuery();
    const [, dataGenerals] = useGetGenerals();
    const dispatch = useDispatch();
    const activeDirectionColombia = useGetValueFeatureFlag('direction_colombia');

    const [updateOrderStore, updateOrderStoreState] = useChangeOrderStoreMutation();
    const [getShippingPrice] = useGetShippingPriceMutation();

    const [courierSelected, setCourierSelected] = useState(null);
    const [form] = Form.useForm();

    const dateFormat = 'DD/MM/YYYY HH:mm';
    const [, dataShops] = useAllShops();

    const fulfillmentSelected = useMemo(() => {
        const logistic = !fulfillmentId
            ? order?.logistics_info?.[0]
            : order?.logistics_info?.find((f) => String(f._id) === String(fulfillmentId));
        const logisticSelected = {
            ...logistic,
            origin: {
                _id: order?.location_id,
                id: order?.location_id,
                name: order?.location_id?.name,
            },
        };
        if (!fulfillmentId) return order?.fulfillments?.[0] ?? logisticSelected;
        return (
            order?.fulfillments?.find((f) => String(f._id) === String(fulfillmentId)) ??
            logisticSelected
        );
    }, [fulfillmentId, order]);

    useEffect(() => {
        if (
            (changeStoreData?.delivery_method === 'shipping' &&
                fulfillmentSelected?.delivery_method === 'shipping' &&
                !fulfillmentSelected?.preparationcd_transfer_pickupstore) ||
            changeStoreData?.flow === FLOWS.NormalToPreparationCdTransferPickupStore
        ) {
            form.setFieldsValue({
                free_shipping: true,
                address1:
                    changeStoreData?.shipping?.address1 ??
                    fulfillmentSelected?.shipping_address?.address1 ??
                    '',
                region:
                    changeStoreData?.shipping?.region ??
                    fulfillmentSelected?.shipping_address?.province ??
                    '',
                city:
                    changeStoreData?.shipping?.city ||
                    fulfillmentSelected?.shipping_address?.city ||
                    fulfillmentSelected?.shipping_address?.neighborhood ||
                    '',
                courier: fulfillmentSelected?.assigned_courier?._id,
                courier_service:
                    changeStoreData?.shipping?.courier_service ??
                    fulfillmentSelected?.shipping_service?.name ??
                    fulfillmentSelected?.shipping_service ??
                    '',
                delivery_type: changeStoreData?.warehouseType
                    ? 'alternative_solutions'
                    : 'warehouse_available',
            });

            // for price calculation
            handleChangeForm({}, form.getFieldsValue());

            if (fulfillmentSelected?.courier?._id) {
                // form.setFieldsValue({
                //     courier_service: undefined,
                // });
                setCourierSelected(fulfillmentSelected?.courier?._id);
            }
        }
    }, []);

    const handleChangeStore = async () => {
        await updateOrderStore({
            order_id: order?._id,
            line_items: changeStoreData?.line_items ?? [],
            origin:
                changeStoreData?.flow === FLOWS.NormalToPreparationCdTransferPickupStore
                    ? changeStoreData?.location_supply
                    : changeStoreData?.location ?? '',
            courier: changeStoreData?.shipping?.courier ?? '',
            delivery_method: changeStoreData?.delivery_method ?? '',
            new_destination: {
                address1: changeStoreData?.shipping?.address1 ?? '',
                province: changeStoreData?.shipping?.region ?? '',
                neighborhood:
                    changeStoreData?.shipping?.neighborhood ??
                    changeStoreData?.shipping?.city ??
                    '',
                city: changeStoreData?.shipping?.city ?? '',
            },
            new_promise_date:
                changeStoreData?.shipping?.pickup_date ??
                changeStoreData?.shipping?.promised_date ??
                '',
            changeAllOrder: !fulfillmentId,
            location_supply:
                changeStoreData?.flow === FLOWS.NormalToPreparationCdTransferPickupStore
                    ? changeStoreData?.location
                    : changeStoreData?.location_supply,
            shipping_service: changeStoreData?.shipping?.courier_service ?? '',
            assigned_operators: changeStoreData?.assigned_operators ?? [],
            delivery_type: changeStoreData?.warehouseType
                ? 'alternative_solutions'
                : 'warehouse_available',
            flow: changeStoreData?.flow,
        })
            .unwrap()
            .then(() => history.push(`/orders/${order?._id}`))
            .catch((err) => console.error(err));
    };

    const { step } = useSelector((state) => state.changeStore);

    const handleFinishForm = (values) => {
        dispatch(
            setChangeStoreValues({
                shipping: {
                    ...values,
                    changeAllOrder: !fulfillmentId,
                    promised_date: dayjs(values.promised_date).toISOString(),
                    pickup_date: dayjs(values.pickup_date).toISOString(),
                },
                success: true,
            })
        );
        {
            !step && <IncidenceSelector deliveryMethod={deliveryMethod} />;
        }
    };

    const handleChangeForm = async (changedValues, values) => {
        // if (changedValues.city || changedValues.courier) {
        if (
            (values?.city && values?.courier) ||
            changedValues?.courier_service ||
            values?.free_shipping
        ) {
            const res = await getShippingPrice({
                city: values?.city ?? '',
                courier: values?.courier ?? '',
                location: changeStoreData?.location ?? '',
                order_id: order?._id ?? '',
                line_items: changeStoreData?.line_items ?? [],
                destination_address: values?.address1 ?? '',
                shipping_service: values?.courier_service,
            });

            const price = values?.free_shipping ? 0 : res?.data?.price || null; // no cambiar || por ??

            form.setFieldsValue({
                shipping_price: MoneyFormat(price ?? order?.total_shipping ?? 0),
            });
        }

        if (changedValues.courier) {
            form.setFieldsValue({
                courier_service: undefined,
            });

            setCourierSelected(values.courier);
        }
    };

    const disabledDate = (current) => current && current < dayjs().startOf('day');

    const pickupLocation = useMemo(() => {
        const deliveryMethodFlow = {
            shippingToPickup:
                fulfillmentSelected?.delivery_method === 'shipping' &&
                changeStoreData?.delivery_method === 'pickup',
            pickupToShipping:
                fulfillmentSelected?.delivery_method === 'pickup' &&
                changeStoreData?.delivery_method === 'shipping',
            preparationCdTransferPickupStore:
                fulfillmentSelected?.preparationcd_transfer_pickupstore?.cd_location,
            consolidation: fulfillmentSelected?.consolidation?.preparation_location_id,
            default: fulfillmentSelected?.delivery_method === changeStoreData?.delivery_method,
        };

        if (
            deliveryMethodFlow?.preparationCdTransferPickupStore ||
            changeStoreData?.flow === FLOWS.NormalToPreparationCdTransferPickupStore
        ) {
            return {
                titleFrom: 'Tienda de retiro',
                titleTo: `Tienda de ${
                    renderDeliveryMethodLabel[
                        changeStoreData?.warehouseType ? changeStoreData?.delivery_method : 'pickup'
                    ]
                }`,
                from: deliveryMethodFlow?.preparationCdTransferPickupStore
                    ? fulfillmentSelected?.preparationcd_transfer_pickupstore?.pickup_location?.name
                    : fulfillmentSelected?.origin?.name,
                to: !changeStoreData?.warehouseType
                    ? changeStoreData?.location_supply_name
                    : changeStoreData?.location_name,
            };
        }
        if (
            deliveryMethodFlow?.consolidation &&
            !fulfillmentSelected?.consolidation?.is_consolidation_fulfillment
        ) {
            return {
                titleFrom: 'Tienda de despacho',
                titleTo: `Tienda de ${
                    renderDeliveryMethodLabel[
                        changeStoreData?.warehouseType
                            ? changeStoreData?.delivery_method
                            : 'shipping'
                    ]
                }`,
                from: dataShops?.find(
                    (x) => x._id === fulfillmentSelected?.consolidation?.consolidation_location_id
                )?.name,
                to: changeStoreData?.warehouseType
                    ? changeStoreData?.location_name
                    : dataShops?.find(
                          (x) =>
                              x._id ===
                              fulfillmentSelected?.consolidation?.consolidation_location_id
                      )?.name,
            };
        }

        if (deliveryMethodFlow?.shippingToPickup || deliveryMethodFlow?.pickupToShipping) {
            return {
                titleFrom: deliveryMethodFlow?.shippingToPickup
                    ? 'Tienda de despacho'
                    : 'Tienda de retiro',
                titleTo: deliveryMethodFlow?.shippingToPickup
                    ? 'Tienda de retiro'
                    : 'Tienda de despacho',
                from: fulfillmentSelected?.origin?.name,
                to:
                    (changeStoreData?.flow === FLOWS.ConsolidationToPickupOrShippingWithCd &&
                        fulfillmentSelected?.consolidation?.is_consolidation_fulfillment) ||
                    changeStoreData?.flow === FLOWS.NormalToConsolidation
                        ? changeStoreData?.location_name
                        : changeStoreData?.location_supply_name ?? changeStoreData?.location_name,
            };
        }

        return {
            titleFrom: `tienda de ${renderDeliveryMethodLabel[changeStoreData?.delivery_method]}`,
            titleTo: `Tienda de ${renderDeliveryMethodLabel[changeStoreData?.delivery_method]}`,
            from: fulfillmentSelected?.origin?.name,
            to:
                changeStoreData?.flow === FLOWS.NormalToConsolidation &&
                !changeStoreData?.warehouseType
                    ? changeStoreData?.location_supply_name
                    : changeStoreData?.location_name,
        };
    }, [fulfillmentSelected, changeStoreData]);

    const productsWithIncidence = useMemo(() => {
        return changeStoreData?.line_items.map((li) => {
            return {
                line_item: li._id,
                quantity: li.quantity,
                total_price: li.total_price,
                sku: li.sku,
            };
        });
    }, [changeStoreData?.line_items]);

    const deliveryMethod = useMemo(() => {
        return fulfillmentSelected?.preparationcd_transfer_pickupstore?.cd_location
            ? 'pickup'
            : fulfillmentSelected?.delivery_method ?? order?.delivery_method;
    }, [
        fulfillmentSelected?.delivery_method,
        order?.delivery_method,
        fulfillmentSelected?.preparationcd_transfer_pickupstore?.cd_location,
    ]);

    return (
        <Flex direction="column" rowGap="2rem">
            <ResolvedItemsList>
                <Flex direction="row">
                    <Typography variant="medium" color="primary.01">
                        Resumen del cambio de tienda/bodega para el pedido {order.name}. (
                        {!fulfillmentId
                            ? order?.line_items?.length
                            : productsWithIncidence?.filter((x) => x.quantity > 0).length}
                        /{fulfillmentSelected?.line_items?.length ?? productsWithIncidence?.length})
                        Productos
                    </Typography>
                </Flex>
                {Array.from(changeStoreData?.changeStoreList ?? []).map((changeStore, index) => (
                    <ResolvedItems key={index}>
                        <br />
                        <Flex direction="row" marginBottom="1rem" style={{ position: 'relative' }}>
                            <Flex direction="row" width="45%">
                                <Typography variant="medium" color="secondary.01">
                                    Original
                                </Typography>
                            </Flex>
                            <HiArrowNarrowRight
                                style={{
                                    margin: '0 1rem',
                                    visibility: 'hidden',
                                }}
                            />
                            <Flex direction="row">
                                <Typography variant="medium" color="secondary.01">
                                    Actual
                                </Typography>
                            </Flex>

                            <Button
                                onClick={() => {
                                    dispatch(
                                        setChangeStoreValues({
                                            success: false,
                                        })
                                    );
                                }}
                                icon={<EditIcon className="pb-2" />}
                                style={{
                                    position: 'absolute',
                                    right: 0,
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    padding: '0 16px',
                                }}
                            />
                        </Flex>

                        <Flex direction="row">
                            <Flex direction="row" width="45%">
                                <Typography variant="regular" color="primary.01">
                                    Tipo de entrega: &nbsp;
                                    {renderDeliveryMethodLabel[deliveryMethod] ?? ''}
                                </Typography>
                            </Flex>
                            <HiArrowNarrowRight
                                style={{
                                    margin: '0 1rem',
                                    color: '#2D3D76',
                                }}
                            />
                            <Flex direction="row">
                                <Typography variant="regular" color="primary.01">
                                    Tipo de entrega: &nbsp;
                                    {renderDeliveryMethodLabel[changeStore.delivery_method] ?? ''}
                                </Typography>
                            </Flex>
                        </Flex>
                        {fulfillmentSelected?.preparationcd_transfer_pickupstore?.cd_location && (
                            <Flex direction="row">
                                <Flex direction="row" width="45%">
                                    <Typography variant="regular" color="primary.01">
                                        Tienda de preparación: &nbsp;
                                        {fulfillmentSelected?.preparationcd_transfer_pickupstore
                                            ?.cd_location?.name ?? ''}
                                    </Typography>
                                </Flex>
                                <HiArrowNarrowRight
                                    style={{
                                        margin: '0 1rem',
                                        color: '#2D3D76',
                                    }}
                                />
                                <Flex direction="row" width="45%">
                                    {changeStoreData?.location_supply && (
                                        <Typography variant="regular" color="primary.01">
                                            Tienda de preparación: &nbsp;
                                            {!changeStoreData?.warehouseType
                                                ? changeStoreData?.location_name
                                                : changeStoreData?.location_supply_name}
                                        </Typography>
                                    )}
                                </Flex>
                            </Flex>
                        )}
                        {fulfillmentSelected?.consolidation?.preparation_location_id &&
                            !(
                                fulfillmentSelected?.consolidation?.is_consolidation_fulfillment &&
                                FLOWS.ConsolidationWarehouseAvailable === changeStoreData?.flow
                            ) &&
                            [
                                FLOWS.ConsolidationToPickupOrShippingWithCd,
                                FLOWS.ConsolidationWarehouseAvailable,
                            ]?.includes(changeStoreData?.flow) && (
                                <Flex direction="row">
                                    <Flex direction="row" width="45%">
                                        <Typography variant="regular" color="primary.01">
                                            Tienda de preparación: &nbsp;
                                            {dataShops?.find(
                                                (x) =>
                                                    x._id ===
                                                    fulfillmentSelected?.consolidation
                                                        ?.preparation_location_id
                                            )?.name ?? ''}
                                        </Typography>
                                    </Flex>
                                    <HiArrowNarrowRight
                                        style={{
                                            margin: '0 1rem',
                                            color: '#2D3D76',
                                        }}
                                    />
                                    {changeStoreData?.location_supply && (
                                        <Flex direction="row" width="45%">
                                            <Typography variant="regular" color="primary.01">
                                                Tienda de preparación: &nbsp;
                                                {changeStoreData?.flow ===
                                                FLOWS.ConsolidationWarehouseAvailable
                                                    ? changeStoreData?.location_name
                                                    : changeStoreData?.location_supply_name}
                                            </Typography>
                                        </Flex>
                                    )}
                                </Flex>
                            )}
                        {changeStoreData?.location_supply &&
                            !fulfillmentSelected?.consolidation?.is_consolidation_fulfillment &&
                            !fulfillmentSelected?.consolidation?.preparation_location_id &&
                            !fulfillmentSelected?.preparationcd_transfer_pickupstore
                                ?.cd_location && (
                                <Flex direction="row">
                                    <Flex direction="row" width="45%" />
                                    <HiArrowNarrowRight
                                        style={{
                                            margin: '0 1rem',
                                            color: '#2D3D76',
                                        }}
                                    />
                                    <Flex direction="row" width="45%">
                                        <Typography variant="regular" color="primary.01">
                                            Tienda de preparación: &nbsp;
                                            {!changeStoreData?.warehouseType
                                                ? changeStoreData?.location_name
                                                : changeStoreData?.location_supply_name}
                                        </Typography>
                                    </Flex>
                                </Flex>
                            )}
                        <Flex direction="row">
                            <Flex direction="row" width="45%">
                                <Typography variant="regular" color="primary.01">
                                    {pickupLocation?.titleFrom}: &nbsp;
                                    {pickupLocation?.from ?? ''}
                                </Typography>
                            </Flex>
                            <HiArrowNarrowRight
                                style={{
                                    margin: '0 1rem',
                                    color: '#2D3D76',
                                }}
                            />
                            <Flex>
                                <Typography variant="regular" color="primary.01">
                                    {pickupLocation?.titleTo}: &nbsp;
                                    {pickupLocation?.to ?? ''}
                                </Typography>
                            </Flex>
                        </Flex>
                    </ResolvedItems>
                ))}
                {changeStoreData?.success && changeStoreData?.delivery_method === 'shipping' && (
                    <ResolvedItems>
                        <Flex direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="medium" color="secondary.01">
                                Dirección despacho
                            </Typography>
                            <Button
                                onClick={() => {
                                    dispatch(
                                        setChangeStoreValues({
                                            success: false,
                                        })
                                    );
                                }}
                                icon={<EditIcon className="pb-2" />}
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    padding: '0 16px',
                                }}
                            />
                        </Flex>
                        <br />

                        <Flex direction="row" alignItems="center">
                            <Flex direction="row" width="200px">
                                <Typography variant="regular" color="primary.01">
                                    Dirección:
                                </Typography>
                            </Flex>
                            <Flex direction="row">
                                <Typography variant="regular" color="primary.01">
                                    {changeStoreData?.shipping?.address1 ?? ''}
                                </Typography>
                            </Flex>
                        </Flex>

                        <Flex direction="row">
                            <Flex direction="row" width="200px">
                                <Typography variant="regular" color="primary.01">
                                    Courier:
                                </Typography>
                            </Flex>
                            <Flex direction="row">
                                <Typography variant="regular" color="primary.01">
                                    {couriers.find(
                                        (courier) =>
                                            courier._id === changeStoreData?.shipping?.courier
                                    )?.public_name ?? ''}
                                </Typography>
                            </Flex>
                        </Flex>

                        <Flex direction="row">
                            <Flex direction="row" width="200px">
                                <Typography variant="regular" color="primary.01">
                                    Tipo de Servicio:
                                </Typography>
                            </Flex>
                            <Flex direction="row">
                                <Typography variant="regular" color="primary.01">
                                    {changeStoreData?.shipping?.courier_service ?? ''}
                                </Typography>
                            </Flex>
                        </Flex>

                        <Flex direction="row">
                            <Flex direction="row" width="200px">
                                <Typography variant="regular" color="primary.01">
                                    Costo de envío:
                                </Typography>
                            </Flex>
                            <Flex direction="row">
                                <Typography variant="regular" color="primary.01">
                                    {changeStoreData?.shipping?.shipping_price ?? 0}
                                </Typography>
                            </Flex>
                        </Flex>

                        {/* <Flex direction="row" marginLeft="3rem">
                            <Flex direction="row" width="200px">
                                <span style={{ color: '#536D8F' }}>Fecha de compromiso</span>
                            </Flex>
                            <HiArrowNarrowRight style={{ margin: '0 1rem', color: '#2D3D76' }} />
                            <Flex direction="row">
                                <span style={{ color: '#2D3D76' }}>
                                    {changeStoreData?.shipping?.promised_date ?? ''}
                                </span>
                            </Flex>
                        </Flex> */}

                        <Flex direction="row">
                            <Flex direction="row" width="200px">
                                <Typography variant="regular" color="primary.01">
                                    Promesa de entrega
                                </Typography>
                            </Flex>
                            <Flex direction="row">
                                <Typography variant="regular" color="primary.01">
                                    {changeStoreData?.shipping?.pickup_date
                                        ? dayjs(changeStoreData?.shipping?.pickup_date).format(
                                              'DD/MM/YYYY HH:mm'
                                          )
                                        : ''}
                                </Typography>
                            </Flex>
                        </Flex>
                    </ResolvedItems>
                )}
            </ResolvedItemsList>

            {(changeStoreData?.delivery_method === 'shipping' ||
                changeStoreData?.flow === FLOWS.NormalToPreparationCdTransferPickupStore) &&
                !changeStoreData?.success && (
                    <FormStyled
                        layout="vertical"
                        form={form}
                        name="change-store-shipping"
                        onFinish={handleFinishForm}
                        onValuesChange={handleChangeForm}
                        // destroyOnClose="true"
                    >
                        <Typography variant="medium" color="secondary.01">
                            Courier
                        </Typography>
                        <p>
                            Ingrese dirección del cliente y datos del Courier que realizará el
                            despacho
                        </p>
                        <Form.Item
                            label="Dirección de entrega"
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                            name="address1"
                        >
                            <Input
                                disabled={
                                    changeStoreData?.flow ===
                                    FLOWS.NormalToPreparationCdTransferPickupStore
                                }
                            />
                        </Form.Item>
                        <Row gutter={12}>
                            {activeDirectionColombia ? (
                                <LocationByCountryForm
                                    form={form}
                                    valueCountry={dataGenerals?.country}
                                    nameProvince="region"
                                    nameCity="city"
                                />
                            ) : (
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            name="region"
                                            label="Region"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Este campo es requerido',
                                                },
                                            ]}
                                        >
                                            <Select
                                                allowClear
                                                showSearch
                                                disabled={
                                                    changeStoreData?.flow ===
                                                    FLOWS.NormalToPreparationCdTransferPickupStore
                                                }
                                                onChange={() => {
                                                    form.setFieldsValue({
                                                        city: undefined,
                                                        shipping_price: undefined,
                                                    });
                                                }}
                                            >
                                                {Array.from(regionsData?.regions ?? []).map(
                                                    (reg) => (
                                                        <Select.Option
                                                            key={reg.name}
                                                            value={normalizeString(reg.name)}
                                                        >
                                                            {reg.name}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            noStyle
                                            // shouldUpdate={(prevValues, currentValues) =>
                                            //     prevValues?.shipping_data?.region !==
                                            //     currentValues?.shipping_data?.region
                                            // }
                                            shouldUpdate={true}
                                        >
                                            {({ getFieldValue }) => {
                                                const regionSelected = getFieldValue('region');
                                                const courierAddressMapping =
                                                    addressMappings?.data?.find((mapping) =>
                                                        mapping?.possible_values?.includes(
                                                            normalizeString(regionSelected)
                                                        )
                                                    )?.parsed_value;

                                                const communes =
                                                    regionsData?.regions?.find(
                                                        (region) =>
                                                            normalizeString(region.name) ===
                                                            normalizeString(
                                                                courierAddressMapping ??
                                                                    regionSelected
                                                            )
                                                    )?.communes ?? [];

                                                return (
                                                    <Form.Item
                                                        label="Comuna"
                                                        name="city"
                                                        rules={[
                                                            {
                                                                required: true,
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            allowClear
                                                            showSearch
                                                            placeholder="Selecciona una comuna"
                                                            disabled={
                                                                changeStoreData?.flow ===
                                                                FLOWS.NormalToPreparationCdTransferPickupStore
                                                            }
                                                            // disabled={!regionSelected || !communes?.[0]}
                                                        >
                                                            {communes?.map((commune) => (
                                                                <Select.Option
                                                                    value={normalizeString(
                                                                        commune.name
                                                                    )}
                                                                    key={commune.name}
                                                                >
                                                                    {commune.name}
                                                                </Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                );
                                            }}
                                        </Form.Item>
                                    </Col>
                                </>
                            )}
                        </Row>

                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    name="courier"
                                    label="Courier/Transportista"
                                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                                >
                                    <Select loading={loadingCouriers}>
                                        {Array.from(couriers ?? []).map((courier) => (
                                            <Select.Option key={courier._id} value={courier._id}>
                                                {courier?.public_name ?? ''}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="courier_service"
                                    label="Tipo de Servicio"
                                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                                >
                                    <Select loading={loadingCouriers} disabled={!courierSelected}>
                                        {Array.from(couriers ?? [])
                                            .find((courier) => courier._id === courierSelected)
                                            ?.services?.map((service) => (
                                                <>
                                                    {service?.is_enabled && (
                                                        <Select.Option
                                                            key={service?._id}
                                                            value={service?.name ?? ''}
                                                        >
                                                            {service?.name ?? ''}
                                                        </Select.Option>
                                                    )}
                                                </>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            {/* <Col span={12}>
                            <Form.Item label="Nueva fecha de compromiso" name="promised_date">
                                <DatePicker format={dateFormat} disabled={true} />
                            </Form.Item>
                        </Col> */}
                            <Col span={12}>
                                <Form.Item label="Costo de envío" name="shipping_price">
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Nueva fecha de entrega estimada"
                                    name="pickup_date"
                                >
                                    <DatePicker
                                        disabledDate={disabledDate}
                                        showTime
                                        format={dateFormat}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    disabled
                                    name="free_shipping"
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    <Checkbox disabled>Envío gratis</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </FormStyled>
                )}

            <Flex direction="row" wrap width="100%" columnGap="0.8rem" justifyContent="end">
                <Button
                    type="primary"
                    variation="secondary"
                    ghost
                    onClick={handleBackStep}
                    disabled={currentStepForComeback === 0}
                >
                    Volver
                </Button>

                <Button
                    type="primary"
                    ghost={!changeStoreData.success}
                    disabled={updateOrderStoreState.isLoading || updateOrderStoreState.isFetching}
                    onClick={() => {
                        if (!changeStoreData.success) {
                            if (
                                changeStoreData.delivery_method === 'shipping' ||
                                changeStoreData.flow ===
                                    FLOWS.NormalToPreparationCdTransferPickupStore
                            ) {
                                form.submit();
                            } else {
                                handleChangeStore();
                                // setTimeout(() => {
                                //     history.go(0);
                                // }, 3000);
                            }
                        } else {
                            handleChangeStore();
                            // setTimeout(() => {
                            //     history.go(0);
                            // }, 3000);
                        }
                    }}
                >
                    Confirmar información
                </Button>
            </Flex>
        </Flex>
    );
};
export default ChangeConfirmation;
