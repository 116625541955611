import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import qs from 'qs';
import moment from 'moment';

export const preparationsApi = createApi({
    reducerPath: 'preparations',
    baseQuery,
    keepUnusedDataFor: 10,
    tagTypes: ['Preparations'],
    endpoints: (builder) => ({
        getPreparationsByFilters: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.preparationsFilters;
                let filters = args ? args : actuallyFilters;

                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );

                if (clearedFilters.date_range.length > 0) {
                    clearedFilters.date_range = [
                        moment(clearedFilters.date_range[0]).format('YYYY-MM-DD'),
                        moment(clearedFilters.date_range[1]).format('YYYY-MM-DD'),
                    ];
                }

                const querystring = qs.stringify(clearedFilters);

                return fetchWithBQ(`/orders/v2?${querystring}`);
            },
            providesTags: (result) => {
                return result
                    ? [...result.docs.map(({ _id }) => ({ type: 'Preparations', id: _id }))]
                    : [{ type: 'Preparations' }];
            },
        }),
    }),
});

export const { useGetPreparationsByFiltersQuery } = preparationsApi;
