import { manifestsStatusOptions, manifestsMethodOptions } from './values';
import { manifestsFilterFields } from './fields';

export const manifestsFilters = {
    fields: [...manifestsFilterFields],
    values: {
        status: [...manifestsStatusOptions],
        delivery_method: [...manifestsMethodOptions],
    },
};
