import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import PackageDetails from './components/PackageDetails';
import ScannedCodes from './components/ScannedCodes';
import { BGWhite, PageLayout } from './styles';
import { Button, Skeleton, Tag, Typography } from 'antd';
import dayjs from 'helpers/dayjs';
import { ReactComponent as CopyIcon } from 'assets/new-copy-icon.svg';

import PreparationToolbar from './components/PreparationToolbar';

import useFulfillment from 'hooks/useFulfillment';
import { useParams } from 'react-router-dom';
import { WarningOutlined } from '@ant-design/icons';
import { ReactComponent as IncidenceIcon } from 'assets/alert-rhombus.svg';

import CreateLineItemIncidenceOrderDrawer from 'components/ManualIncidences/LineItems/CreateIncidenceDrawer';
import { useEffect, useState } from 'react';
import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import PickingConfirmationModal from './components/PickingConfirmationModal';
import MultipleFlowArrows from './MultipleFlowArrows';
import FulfillmentStatusWithSLA from 'pages/Orders/components/OrdersDataTable/FulfillmentStatusWithSLA';
import Flex from 'components/Utils/Flex';
import { useDispatch } from 'react-redux';
import { cancelFulfillment } from 'redux/features/Fulfillments/cancelFulfillment';

import PreparationActions from './components/Actions';
import { Fragment } from 'react';

const Preparation = () => {
    const [showPickingConfirmation, setShowPickingConfirmation] = useState(false);
    const [, , preparationPreferences] = useGetPreparationPreference();
    const [isTotallyPicking, setIsTotallyPicking] = useState(false);
    const [isOpenBtnDisabled, setIsOpenBtnDisabled] = useState(false);

    const { fulfillment_id, flow } = useParams();
    const [loading, data] = useFulfillment(fulfillment_id);

    const blockPreparation = false;
    const differentLocation = false;

    const dispatch = useDispatch();

    const showLoading = (loading && !data._id) || (loading && data._id !== fulfillment_id);

    const line_items_formatted =
        Array.from(data.line_items).map((lineItem) => {
            const { quantity } = lineItem;
            return {
                ...lineItem.line_item,
                quantity: quantity,
            };
        }) || [];

    useEffect(() => {
        if (
            preparationPreferences?.preparations_preferences?.picking_confirmation &&
            data?.status === 'open' &&
            !differentLocation
        ) {
            setShowPickingConfirmation(true);
        }
    }, [preparationPreferences, data, differentLocation]);

    const code_for_scanning =
        preparationPreferences?.preparations_preferences?.code_for_scanning ?? 'ean';

    if (!data.line_items[0]) return '';

    return (
        <Container extraTitle={!loading ? `Preparación ${data.name}` : ''}>
            <BGWhite>
                {false && (
                    <Button onClick={() => dispatch(cancelFulfillment(fulfillment_id))}>
                        CANCEL
                    </Button>
                )}

                <CreateLineItemIncidenceOrderDrawer fulfillment={data} />

                <PickingConfirmationModal
                    order={data.order}
                    line_items={line_items_formatted}
                    showPickingConfirmation={showPickingConfirmation}
                    blockPreparation={blockPreparation}
                />

                {showLoading ? (
                    <Skeleton paragraph={{ rows: 1 }} />
                ) : (
                    <Flex direction="row" justifyContent="space-between">
                        <Flex flex={1}>
                            <PageHeader
                                withMarginBottom={false}
                                responsables={data?.assigned_operators}
                                showResponsables={true}
                                showBack
                                title={`${data.name || ''}`}
                                subFulfillmentDefaultLabel={
                                    <>
                                        <span>FECHA DE COMPROMISO: </span>
                                    </>
                                }
                                titleContainerFlexDirection={'row'}
                                deliveryMethodLabel={
                                    data?.delivery_method === 'pickup'
                                        ? 'Retirar en tienda'
                                        : 'A domicilio'
                                }
                                subFulfillmentDefaultLabel1={
                                    <div className="ml-10 font-medium text-sm"></div>
                                }
                                CourierLabel={
                                    data?.courier?.public_name ?? data?.courier?.courier_name ?? ''
                                }
                                titleExtra={
                                    <Flex direction="row" columnGap="2rem" alignItems="center">
                                        <FulfillmentStatusWithSLA
                                            row={{
                                                fulfillment_status: data?.order?.fulfillment_status,
                                                createdAt: data?.order?.createdAt,
                                                promised_date: data?.order?.promised_date,
                                            }}
                                        />
                                        {/* <RenderOperators operators={data?.assigned_operators} /> */}
                                    </Flex>
                                }
                                titleIcon={
                                    <div className="flex">
                                        {data.status === 'paused' && (
                                            <WarningOutlined
                                                style={{ color: 'red', fontSize: '1.2rem' }}
                                            />
                                        )}

                                        {data.status === 'sac' && (
                                            <div className="flex items-center">
                                                <IncidenceIcon
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '1.2rem',
                                                        marginLeft: '5px',
                                                    }}
                                                />
                                                <span style={{ fontSize: '13.5px', color: 'red' }}>
                                                    ESTADO SAC
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                }
                                subtitle={
                                    <>
                                        <span>
                                            {dayjs(data.created_at).format('DD/MM/YYYY')} - Online
                                            Store
                                        </span>
                                        <div>
                                            ID Ecommerce:{' '}
                                            <Typography.Text
                                                copyable={{
                                                    icon: <CopyIcon />,
                                                }}
                                                style={{
                                                    weight: 'bold',
                                                    color: '#1F6373',
                                                }}
                                            >
                                                {data.remote_order_id}
                                            </Typography.Text>
                                        </div>
                                    </>
                                }
                                breadcrumb={[
                                    { name: 'Preparación', path: '/fulfillments' },
                                    { name: `Preparación Pedido ${data.name}` },
                                ]}
                                extra={
                                    flow && <MultipleFlowArrows fulfillment_id={fulfillment_id} />
                                }
                                tags={[
                                    differentLocation && (
                                        <Tag color="error">
                                            No puedes preparar este pedido porque no corresponde a
                                            tu tienda/bodega asignada
                                        </Tag>
                                    ),
                                ]}
                            />
                        </Flex>

                        <PreparationActions />
                    </Flex>
                )}

                {!loading && differentLocation ? (
                    <div style={{ textAlign: 'center' }}>
                        <h1 style={{ fontSize: '40px' }}>
                            <b>401</b>
                        </h1>
                        <b>
                            No puedes ver esta preparación por qué no corresponde a tu bodega
                            asignada
                        </b>
                    </div>
                ) : (
                    <Fragment>
                        <PreparationToolbar
                            fulfillment={data}
                            loading={showLoading}
                            fulfillment_id={fulfillment_id}
                            code_for_scanning={code_for_scanning}
                            blockPreparation={blockPreparation}
                            isOpenBtnDisabled={isOpenBtnDisabled}
                        />

                        <PageLayout>
                            {showLoading ? (
                                <Skeleton paragraph={{ rows: 2 }} />
                            ) : (
                                <PackageDetails
                                    isTotallyPicking={isTotallyPicking}
                                    fulfillment={data}
                                    blockPreparation={blockPreparation}
                                    isOpenBtnDisabled={isOpenBtnDisabled}
                                    setIsOpenBtnDisabled={setIsOpenBtnDisabled}
                                />
                            )}
                            {showLoading ? (
                                <Skeleton paragraph={{ rows: 2 }} />
                            ) : (
                                <ScannedCodes
                                    setIsTotallyPicking={setIsTotallyPicking}
                                    fulfillment={data}
                                    code_for_scanning={code_for_scanning}
                                    blockPreparation={blockPreparation}
                                />
                            )}
                        </PageLayout>
                    </Fragment>
                )}
            </BGWhite>
        </Container>
    );
};

export default Preparation;
