export const modalProps = {
    okText: 'Confirmar',
    destroyOnClose: true,
    okButtonProps: {
        style: {
            backgroundColor: '#F27127',
            color: 'white',
            border: '1px solid #E79B09 ',
        },
    },
    cancelButtonProps: {
        style: {
            backgroundColor: 'none',
            color: '#2D3D76',
            border: '1px solid #2D3D76 ',
        },
    },
};
