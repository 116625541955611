export const tagsOrder = {
    INVALID_NEIGHBORHOOD: 'Vecindario inválido (comuna)',
    INVALID_CITY: 'Ciudad inválida',
    VERIFY_ITEMS_PRISM: 'Items verificados con éxito',
    ORDER_SALE_ORDER_CREATED_FAILED: 'Falló en la integración de la orden de venta',
    CREATE_SALES_NOTES_SAP_FAILED: 'Falló  en la integración de la orden de venta a SAP',
    FULFILLMENT_COMPLETED_REPORTED_FAILED: 'Falló la información de la preparación del fulfillment',
    DTE_CREDIT_NOTE_CREATED_FAILED: 'Falló la creación de la nota de crédito DTE',
    Order_without_Store: 'Pedido sin tienda asignada',
    ORDER_DOCUMENT_REPORTED_FAILED: 'Falló el reporte de un documento',
    VERIFY_ITEMS_PRISM_FAILED: 'Falló la verificación de los items',
};
