import Badge from 'components/V2/Badge';
import { useMemo } from 'react';

const ScannedPackageStatus = ({
    dataPickupsPreferences,
    dataPreparations,
    isScan,
    fulfillmentToDelivery,
    deliveryPreferences,
}) => {
    const twoStepDelivery =
        deliveryPreferences?.delivery_options?.shipping?.enable_two_step_delivery;
    const twoStepPickup = deliveryPreferences?.delivery_options?.pickup?.enable_two_step_delivery;

    const { scannedPackages, allPackagesCount } = useMemo(() => {
        const firstData = fulfillmentToDelivery?.[0];
        const fulfillmentsToDelivery = isScan
            ? fulfillmentToDelivery
            : fulfillmentToDelivery.filter((fulfillment) => {
                  if (twoStepDelivery !== twoStepPickup) {
                      return firstData?.delivery_method === fulfillment?.delivery_method;
                  }

                  return true;
              });

        const scannedPackages = (fulfillmentsToDelivery ?? []).reduce((acc, ele) => {
            const prepareInfoUser =
                dataPreparations?.preparations_preferences?.preparer_default_assignment &&
                !!(
                    (dataPickupsPreferences?.delivery_options?.shipping
                        ?.type_automatic_assignment_manager_fulfillment ===
                        'not_delivery_validation' &&
                        ele?.delivery_method === 'shipping') ||
                    (dataPickupsPreferences?.delivery_options?.pickup
                        ?.type_automatic_assignment_manager_fulfillment ===
                        'not_delivery_validation' &&
                        ele?.delivery_method === 'pickup')
                );

            if (ele?.packages?.length === 1) acc++;
            else if (prepareInfoUser) acc += ele?.packages?.length || 0;
            else acc += ele?.packages?.filter((pkg) => pkg.status === 'processed')?.length || 0;

            return acc;
        }, 0);

        const allPackagesCount = (fulfillmentsToDelivery ?? []).reduce((acc, ele) => {
            acc += (ele?.packages ?? []).length;
            return acc;
        }, 0);

        return { scannedPackages, allPackagesCount };
    }, [fulfillmentToDelivery, isScan]);

    if (!allPackagesCount) return <></>;

    return (
        <Badge
            bgColor={scannedPackages === allPackagesCount ? '#089460' : '#B00303'}
            label={`Bultos procesados ${scannedPackages}/${allPackagesCount}`}
            style={{ padding: '4px 6px' }}
        />
    );
};

export default ScannedPackageStatus;
