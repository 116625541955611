import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllShops } from './getAllShops';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const createShops = createAsyncThunk('stores-warehouses/create', async (data, state) => {
    try {
        const res = await toast.promise(AndesClient.post(`/locationsV2/create`, data), {
            loading: 'Creando tienda/bodega...',
            success: 'Tienda/Bodega creada con éxito.',
            error: (error) => `Error: ${error.response.data.message}`,
        });
        if (res.status === 200) {
            state.dispatch(getAllShops());
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const createShopsSlice = createSlice({
    name: 'createShops',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createShops.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createShops.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createShops.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createShopsSlice.reducer;
