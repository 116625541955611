import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    es: {
        translation: {
            archived: 'Archivado',
            pending_for_processing: 'Por escanear',
            selected: 'Procesado',
            processed: 'Escaneado',
            re_processed: 'Escaneado',
            in_progress: 'En proceso',
            delivered: 'Entregado',
            cancelled: 'Cancelado',
            sac: 'SAC',
            unfulfilled: 'Pendiente',
            delivered_to_courier: 'Despachado',
            ready_for_deliver: 'Listo para enviar a entregas',
            paused: 'Pausado',
            ready_for_deliver_to_cd_location: 'Listo para despachar a CD/Tienda',
            partially_fulfilled: 'Parcialmente preparado',
            partially_delivered: 'Parcialmente despachado',
            all_packages_processed: 'Todos los paquetes procesados',
            received_pickup_location: 'Recibido en tienda',
            delivered_to_cd_location: 'Despachado a CD/Tienda',
            pending_for_processing_in_pickup_location: 'Por procesar en tienda',
            success: 'Listo para procesar entrega',
            partially_processed: 'Parcialmente procesado',
            waiting_collect_manifest: 'En confirmación de entrega',
            fulfillment: {
                success: 'Listo para entregar',
                open: 'En proceso',
            },
            manifests: {
                processed: 'Por procesar',
                re_processed: 'Re-validado',
                pending_for_processing: 'Por procesar',
            },
        },
    },
};

i18n.use(initReactI18next).init({
    resources,
    lng: 'es',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
