import Form from 'antd/lib/form/Form';
import styled from 'styled-components';

const FormStyled = styled(Form)`
    h5,
    .ant-radio-wrapper,
    .ant-checkbox-wrapper {
        font-weight: 500;
        letter-spacing: 0.4px;
        font-size: 0.86rem;
    }
    .ant-select-arrow {
        display: block;
    }

    .ant-btn {
        font-weight: 500;
    }

    .ant-input,
    .ant-input-number {
        min-height: 40px;
    }

    .ant-select-arrow {
        font-size: 1rem;
    }

    .ant-input-number-group-wrapper {
        width: 100%;
        .ant-input-number-input-wrap {
            height: 100%;
            margin-top: 10px;
            .ant-input-number-input {
                height: 100%;
            }
        }
    }

    .ant-picker {
        width: 100%;
        height: 40px;
    }

    .ant-select-selector {
        min-height: 40px !important;
        display: flex;
        align-items: center;
    }

    .ant-input,
    .ant-select-selecto,
    .ant-input-number {
        border: 1px solid #2d3d76;
        &::placeholder {
            font-size: 0.75rem;
        }

        &::disabled {
            background-color: #dce5ec;
        }
    }

    .ant-select-selection-item-content {
        line-height: 1.6rem;
    }

    .ant-select-selection-item {
        height: unset;
        display: flex;
    }

    .ant-form-item-label > label:after {
        content: none !important;
        display: none !important;
    }

    .ant-form-item-explain-error {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .ant-form-item {
        margin-bottom: 10px;
    }

    .ant-form-item-label {
        padding-bottom: 4px;

        label {
            font-size: 0.8rem !important;
            color: ${({ labelColor }) => labelColor || '#1F2937'};
        }
    }

    .ant-input-password {
        padding: 0 11px;
    }

    .ant-typography {
        color: '#043540' !important;
        font-weight: bold;
        margin-bottom: 8px;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: 1px solid #2d3d76;
    }
    .jxxIxg input {
        border: 1px solid #2d3d76;
    }
    width: 100%;
`;

export default FormStyled;
