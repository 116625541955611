import styled from 'styled-components';
import { Checkbox } from 'antd';

const CheckboxStyled = styled(Checkbox)`
    .ant-checkbox .ant-checkbox-inner {
        border: 2px solid #2d3d76;
        border-radius: 3px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #2d3d76;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: #fff;
    }
    > span {
        font-weight: 400;
    }

    .ant-checkbox-disabled + span {
        color: #9ebcc0;
    }

    .ant-checkbox-disabled {
        .ant-checkbox-inner {
            border-color: #9ebcc0 !important;
            background-color: #e4e8ec;
        }
    }
`;

export default CheckboxStyled;
