import Tag from 'components/Tag';
import useMasterLabels from 'hooks/useMasterLabels';

export const OrderSacStatusTag = ({ status }) => {
    const { getLabel } = useMasterLabels();
    return <Tag>{getLabel(status)}</Tag>;
};

export const FulFillmentTag = ({ status }) => {
    const { getLabel } = useMasterLabels();

    if (status === 'delivered_to_courier') {
        return (
            <Tag color="success" centered>
                {getLabel('delivered_to_courier')}
            </Tag>
        );
    }

    if (status === 'delivered') {
        return (
            <Tag color="success" centered>
                {getLabel('delivered')}
            </Tag>
        );
    }

    if (status === 'fulfilled') {
        return (
            <Tag color="success" centered>
                {getLabel('fulfilled')}
            </Tag>
        );
    }

    if (status === 'unfulfilled' || !status) {
        return (
            <Tag color="warning" centered>
                {getLabel('unfulfilled')}
            </Tag>
        );
    }

    if (status === 'in_progress') {
        return (
            <Tag color="progress" centered>
                {getLabel('in_progress')}
            </Tag>
        );
    }

    if (status === 'partially_fulfilled') {
        return (
            <Tag color="progress" centered>
                {getLabel('partially_fulfilled')}
            </Tag>
        );
    }

    if (status === 'sac') {
        return (
            <Tag color="danger" centered>
                {getLabel('sac')}
            </Tag>
        );
    }

    return (
        <Tag color="warning" centered>
            {status}
        </Tag>
    );
};
