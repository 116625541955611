import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllShops } from 'redux/features/Shops/getAllShops';

const useAllShops = () => {
    const dispatch = useDispatch();
    const { loading, data, error, success } = useSelector((state) => state.getAllShops);

    useEffect(() => {
        if (!data?.[0] && !success && !loading) {
            dispatch(getAllShops());
        }
    }, []);

    return [loading, data, error];
};

export default useAllShops;
