import styled, { css } from 'styled-components';

const border_color = '#bacbd9';

export const TabWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 0px !important;
    ${({ withBorder }) =>
        withBorder &&
        css`
            margin-bottom: -1px !important;
        `}
`;

export const TabContent = styled.div`
    ${({ withBorder }) =>
        withBorder &&
        css`
            border: 1px solid ${border_color};
            .tcard {
                border-radius: 0px !important;
            }
        `}
`;

export const Tab = styled.div`
    color: #043540;
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.1s ease-in-out;
    margin-right: 10px;
    border: 1px solid #dce5ec;
    border-bottom: 1px solid transparent !important;
    &:last-child {
        margin-right: 0px;
    }
    ${(props) =>
        props.active ? css`
            padding: 1rem 1.2rem;
            background-color: white;
            color: #043540;
            ${({ withBorder }) => withBorder && css`
                z-index: 1;
            `}
        ` : css`
                padding: 0.8rem 1.2rem;
                background-color: #b6cbd3;
                color: #6d96a6;
            `}
`;
