import axios from 'axios';
import AndesClient from 'services/AndesClient';

const uploadFile = async ({ file }) => {
    const res = await AndesClient.post('/generals/uploadLogo', {
        fileType: file.type,
    });

    const { signedRequest, url } = res.data;

    await axios.put(signedRequest, file, {
        headers: {
            'Content-Type': file.type,
        },
    });

    return url;
};

export default uploadFile;
