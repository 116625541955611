import { Empty } from 'antd';
import Loading from 'components/Loading';
import styled from 'styled-components';
import LogoSimplePNG from 'assets/LogoSimple.png';

const TableEmptyWrapper = styled(Empty)`
    margin: 0 auto;
    padding: 2rem 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #037fb9;
    opacity: 0.7;

    .ant-empty-image {
        height: fit-content;
        margin-bottom: 1rem;
    }

    img {
        width: 32px;
        height: 32px;
    }

    span {
        b {
            display: block;
            font-weight: 500 !important;
            font-size: 18px;
            line-height: 22px;
        }

        p {
            font-size: 14px;
        }
    }
`;

const TableEmpty = ({ loading, loadingText }) => {
    return loading ? (
        <Loading loadingText={loadingText} />
    ) : (
        <TableEmptyWrapper
            image={<img src={LogoSimplePNG} />}
            description={
                <span>
                    <b>No hay datos por el momento</b>
                    <p>Selecciona alguna opción o filtro disponible</p>
                </span>
            }
        />
    );
};

export default TableEmpty;
