import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getFulfillmentById } from './getOne';
import history from 'helpers/history';
import { fulfillmentsApi } from './index';

export const pauseFulfillment = createAsyncThunk('fulfillments/pause', async (data, state) => {
    try {
        const { order_id, fulfillment_id } = data;

        const promise = AndesClient.post(
            `/fulfillments/orders/${order_id}/fulfillments/${fulfillment_id}/pause`
        );

        toast.promise(promise, {
            loading: <b>Pausando preparación...</b>,
            success: <b>Preparacion pausada con éxito</b>,
            error: <b>Ocurrió un error al pausar la preparación.</b>,
        });

        const result = await promise;

        if (result.status === 200) {
            state.dispatch(getFulfillmentById(fulfillment_id));
            state.dispatch(
                fulfillmentsApi.endpoints.getInProgressFulfillments.initiate(undefined, {
                    forceRefetch: true,
                })
            );
            history.push('/fulfillments');
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const pauseFulfillmentSlice = createSlice({
    name: 'pauseFulfillment',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [pauseFulfillment.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [pauseFulfillment.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [pauseFulfillment.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default pauseFulfillmentSlice.reducer;
