/* eslint-disable no-unused-vars */
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import Dropdown from 'components/V2/Dropdown';
import Menu from 'components/V2/Menu';
import MenuItem from 'components/V2/Menu/MenuItem';
import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import {
    useBulkConfirmBatchDeliverMutation,
    useCreateBulkBatchDeliverMutation,
    useGetManifestPdfsMutation,
    useMergeShippingNotesMutation,
} from 'redux/features/Deliveries';
import history from 'helpers/history';
import printJS from 'print-js';
import { useEffect, useMemo, useState } from 'react';
import useGetPickupPreference from 'hooks/useGetPickupPreference';
import { ModalValidateDeliveryPin } from 'pages/DeliveriesV2/components/ModalValidateDeliveryPin';

const ManifestsActions = ({
    selected = [],
    disabled = false,
    selectedTab = '',
    deliveryPreferences,
    data = [],
}) => {
    const [createBulkBatchDeliver] = useCreateBulkBatchDeliverMutation();
    const [bulkConfirmDeliver] = useBulkConfirmBatchDeliverMutation();
    const [getManifestPdfs] = useGetManifestPdfsMutation();
    const [getMergeShippingNotes, { isLoading: loadingMergeShippingNotes }] =
        useMergeShippingNotesMutation();
    const [pickupPreferencesLoading, pickupPreferences] = useGetPickupPreference();
    const [openModalValidatePinDelivery, setOpenModalValidatePinDelivery] = useState(false);
    const [fulfillmentToValidate, setFulfillmentToValidate] = useState([]);
    const [validateDeliveries, setValidateDeliveries] = useState([]);

    const manifestsData = useMemo(() => {
        return data?.filter((item) => selected?.includes(item?._id));
    }, [data, selected]);

    const manifestsPdfsLength = useMemo(() => {
        return manifestsData?.filter((item) => String(item?.manifest_url).includes('pdf'))?.length;
    }, [manifestsData]);

    const externalManifestsPdfsLength = useMemo(() => {
        return manifestsData?.filter((item) => String(item?.external_manifest_url).includes('pdf'))
            ?.length;
    }, [manifestsData]);

    const shippingNotesPdfsFilter = useMemo(() => {
        return manifestsData?.filter((item) => String(item?.shipping_note_url).includes('pdf'));
    }, [manifestsData]);

    const handleCreateBulkBatchDeliver = async () => {
        const isShipping = ['pending_delivery'].includes(selectedTab);

        const deliveryMethod = isShipping ? 'shipping' : 'pickup';

        const haveRescanning =
            deliveryPreferences?.delivery_options[deliveryMethod]?.enable_rescanning_packages;

        const manifestFulfillments = manifestsData.flatMap((manifest) => manifest.fulfillments);

        const hasDeliveryPinValidation = manifestFulfillments.some(
            (fulfillment) => fulfillment.has_delivery_pin_validation
        );

        const showValidateDeliveryPin = hasDeliveryPinValidation & !validateDeliveries.length;

        if (showValidateDeliveryPin) {
            setOpenModalValidatePinDelivery(true);
            setFulfillmentToValidate(
                manifestFulfillments.filter((fulfillment) => fulfillment.delivery_pin)
            );
            return;
        }

        const validateManifests = validateDeliveries.length
            ? manifestsData.filter((manifest) =>
                  manifest.fulfillments.some((f) => validateDeliveries.includes(f._id))
              )
            : manifestsData;
        const manifestsIds = validateManifests.map((manifest) => manifest._id);

        if (haveRescanning) {
            if (manifestsIds?.length === 1) {
                history.push(`/manifests/${manifestsIds[0]}`);
                return;
            }

            const response = await createBulkBatchDeliver({
                batch_delivers: manifestsIds,
            });

            if (response.data) {
                history.push(
                    `/manifests/${response?.data?.batch_delivers[0]}/${response?.data?._id}`
                );
            }
        } else {
            await bulkConfirmDeliver({
                batch_delivers: manifestsIds,
            });
        }

        setValidateDeliveries([]);
    };

    const handleGetManifestPdfs = async (type) => {
        const response = await getManifestPdfs({
            ids: selected,
            selectedManifestType: type,
        });

        if (response.data) {
            const url = response?.data?.[type];
            if (url) printJS(url);
        }
    };

    useEffect(() => {
        if (validateDeliveries.length) {
            handleCreateBulkBatchDeliver();
        }
    }, [validateDeliveries]);

    const menu = (
        <Menu>
            {!['delivered_to_courier', 'delivered'].includes(selectedTab) && (
                <MenuItem onClick={handleCreateBulkBatchDeliver}>
                    Procesar entregas ({selected.length})
                </MenuItem>
            )}

            <MenuItem
                onClick={() => handleGetManifestPdfs('manifest_url')}
                disabled={manifestsPdfsLength <= 0}
            >
                Imprimir manifiesto ({manifestsPdfsLength})
            </MenuItem>
            <MenuItem
                onClick={() => handleGetManifestPdfs('external_manifest_url')}
                disabled={externalManifestsPdfsLength <= 0}
            >
                Imprimir manifiesto externo ({externalManifestsPdfsLength})
            </MenuItem>
            {!pickupPreferencesLoading && pickupPreferences?.print_shipping_note_config?.enable && (
                <MenuItem
                    disabled={loadingMergeShippingNotes}
                    onClick={() => {
                        getMergeShippingNotes({
                            manifest_ids: shippingNotesPdfsFilter?.map((x) => x._id),
                        })
                            .unwrap()
                            .then((response) => {
                                console.log(response);
                                if (response?.data) {
                                    printJS({
                                        printable: response.data,
                                        type: 'pdf',
                                        base64: true,
                                    });
                                }
                            });
                    }}
                >
                    Imprimir guías de despacho / remisión ({shippingNotesPdfsFilter?.length})
                </MenuItem>
            )}
        </Menu>
    );

    return (
        <>
            <Dropdown disabled={disabled} overlay={menu}>
                <PrimaryButton
                    icon={<ChevronDownIcon width={24} height={24} />}
                    iconPosition="right"
                >
                    Acciones ({selected.length})
                </PrimaryButton>
            </Dropdown>
            <ModalValidateDeliveryPin
                onSuccess={(e) => {
                    setValidateDeliveries(e);
                    setOpenModalValidatePinDelivery(false);
                }}
                onClose={() => {
                    setValidateDeliveries([]);
                    setOpenModalValidatePinDelivery(false);
                }}
                fulfillments={fulfillmentToValidate}
                isOpen={openModalValidatePinDelivery}
            />
        </>
    );
};

export default ManifestsActions;
