import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';
import { integrationApi } from '../Integrations';

const ERROR_CODES = {
    SALES_PERSON_NOT_FOUND_FAILED_SAP: 'El código del vendedor no fue encontrado',
    DOCUMENT_NOT_FOUND_FAILED: 'Uno de los documentos no fue encontrado',
    USER_NOT_FOUND_IN_SAP: 'El usuario no fue encontrado en SAP o las credenciales son incorrectas',
};

export const sapApi = createApi({
    reducerPath: 'sap',
    baseQuery,
    tagTypes: [
        'dimensions',
        'warehouses',
        'clients',
        'sales_persons',
        'indicators',
        'sapintegration',
        'series',
        'documents',
        'card_types',
        'user_fields',
        'sap_documents',
        'oms_directions',
        'directions_fields',
        'method_payments',
        'business_partner_groups',
        'price_list',
        'currencies',
        'sales_channels_and_origins',
        'oms_method_payments',
        'credit_cards',
        'sales_tax_codes',
    ],
    endpoints: (builder) => ({
        getDimensions: builder.query({
            query: (id) => ({
                url: `sap/${id}/dimensions`,
                method: 'get',
            }),
            providesTags: ['dimensions'],
        }),
        updateDimensions: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `sap/${integration_id}/dimensions`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['dimensions'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando dimensiones SAP</b>,
                    success: () => {
                        api.dispatch(integrationApi.util.invalidateTags(['integrations']));

                        return <b>Dimensiones SAP actualizados con éxito</b>;
                    },
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        getWarehouses: builder.query({
            query: (id) => ({
                url: `sap/${id}/warehouses`,
                method: 'get',
            }),
            providesTags: ['warehouses'],
        }),
        updateWarehouse: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `sap/${integration_id}/warehouses`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['warehouses'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando sucursales SAP</b>,
                    success: () => {
                        api.dispatch(integrationApi.util.invalidateTags(['integrations']));

                        return <b>Sucursales SAP actualizados con éxito</b>;
                    },
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        getClients: builder.query({
            query: (id) => ({
                url: `sap/${id}/clients`,
                method: 'get',
            }),
            providesTags: ['clients'],
        }),
        updateClients: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `sap/${integration_id}/clients`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['clients'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando clientes SAP</b>,
                    success: () => {
                        api.dispatch(integrationApi.util.invalidateTags(['integrations']));

                        return <b>Clientes SAP actualizados con éxito</b>;
                    },
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        getSeriesByDocument: builder.mutation({
            query: ({ integrationId, documentCode }) => ({
                url: `sap/${integrationId}/series/document/${documentCode}`,
                method: 'get',
            }),
            providesTags: ['series'],
        }),
        updateSeries: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `sap/${integration_id}/series`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['series'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando series SAP</b>,
                    success: () => {
                        api.dispatch(integrationApi.util.invalidateTags(['integrations']));

                        return <b>Series SAP actualizados con éxito</b>;
                    },
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        getSalesPersons: builder.query({
            query: (id) => ({
                url: `sap/${id}/sales_persons`,
                method: 'get',
            }),
            providesTags: ['sales_persons'],
        }),
        updateSalesPersons: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `sap/${integration_id}/sales_persons`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['sales_persons', 'integrations'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando empleados SAP</b>,
                    success: () => {
                        api.dispatch(integrationApi.util.invalidateTags(['integrations']));

                        return <b>Empleados SAP actualizados con éxito</b>;
                    },
                    error: (response) => (
                        <b>
                            {ERROR_CODES[response?.error?.data?.errorCode] || 'Ocurrió un error.'}
                        </b>
                    ),
                });
            },
        }),
        getIndicators: builder.query({
            query: (id) => ({
                url: `sap/${id}/indicators`,
                method: 'get',
            }),
            providesTags: ['indicators'],
        }),
        updateIndicators: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `sap/${integration_id}/indicators`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['indicators'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando indicadores SAP</b>,
                    success: () => {
                        api.dispatch(integrationApi.util.invalidateTags(['integrations']));

                        return <b>Indicadores SAP actualizados con éxito</b>;
                    },
                    error: (response) => (
                        <b>
                            {ERROR_CODES[response?.error?.data?.errorCode] || 'Ocurrió un error.'}
                        </b>
                    ),
                });
            },
        }),
        updateDocuments: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `sap/${integration_id}/documents`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['documents'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando documentos SAP</b>,
                    success: () => {
                        api.dispatch(integrationApi.util.invalidateTags(['integrations']));

                        return <b>Documentos SAP actualizados con éxito</b>;
                    },
                    error: (response) => (
                        <b>
                            {ERROR_CODES[response?.error?.data?.errorCode] || 'Ocurrió un error.'}
                        </b>
                    ),
                });
            },
        }),
        updatePaymentMethods: builder.mutation({
            query: ({ integration_id, data }) => ({
                url: `sap/${integration_id}/method-payments`,
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['method_payments'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando documentos SAP</b>,
                    success: () => {
                        api.dispatch(integrationApi.util.invalidateTags(['integrations']));

                        return <b>Formas de pago SAP actualizadas con éxito</b>;
                    },
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        getMetadataCardTypes: builder.query({
            query: (id) => ({
                url: `sap/${id}/meta-datas/card-types`,
                method: 'get',
            }),
            providesTags: ['card_types'],
        }),
        getMetadataUserFields: builder.query({
            query: (id) => ({
                url: `sap/${id}/meta-datas/sap-fields/users`,
                method: 'get',
            }),
            providesTags: ['user_fields'],
        }),
        getMetadataSapDirectionsFields: builder.query({
            query: (id) => ({
                url: `sap/${id}/meta-datas/sap-fields/directions`,
                method: 'get',
            }),
            providesTags: ['directions_fields'],
        }),
        getMetadataDocuments: builder.query({
            query: (id) => ({
                url: `sap/${id}/meta-datas/documents`,
                method: 'get',
            }),
            providesTags: ['sap_documents'],
        }),
        getMetadataOmsFields: builder.query({
            query: (id) => ({
                url: `sap/${id}/meta-datas/oms-fields/users`,
                method: 'get',
            }),
            providesTags: ['sap_documents'],
        }),
        getMetadataOmsDirectionsFields: builder.query({
            query: (id) => ({
                url: `sap/${id}/meta-datas/oms-fields/directions`,
                method: 'get',
            }),
            providesTags: ['oms_directions'],
        }),
        getOmsPaymentMethods: builder.query({
            query: (id) => ({
                url: `sap/${id}/meta-datas/oms/method-payments`,
                method: 'get',
            }),
            providesTags: ['oms_method_payments'],
        }),
        getSalesChannelAndOrigins: builder.mutation({
            query: ({ id, params = {} }) => ({
                url: `sap/${id}/meta-datas/sales-channels-and-origins`,
                method: 'get',
                params: {
                    ...params,
                },
            }),
            providesTags: ['sales_channels_and_origins'],
        }),
        getSalesTaxCodes: builder.query({
            query: (id) => ({
                url: `sap/${id}/meta-datas/sales-tax-codes`,
                method: 'get',
            }),
            providesTags: ['sales_tax_codes'],
        }),
        getPriceList: builder.query({
            query: (id) => ({
                url: `sap/${id}/price-lists`,
                method: 'get',
            }),
            providesTags: ['price_list'],
        }),
        getCurrencies: builder.query({
            query: (id) => ({
                url: `sap/${id}/currencies`,
                method: 'get',
            }),
            providesTags: ['currencies'],
        }),
        getBusinessPartnerGroups: builder.query({
            query: (id) => ({
                url: `sap/${id}/business-partner-groups`,
                method: 'get',
            }),
            providesTags: ['business_partner_groups'],
        }),
        getCreditCards: builder.query({
            query: (id) => ({
                url: `sap/${id}/credit-cards`,
                method: 'get',
            }),
            providesTags: ['credit_cards'],
        }),
        getPaymentTermsTypes: builder.query({
            query: (id) => ({
                url: `sap/${id}/payment-terms-types`,
                method: 'get',
            }),
            providesTags: ['payment_terms_types'],
        }),
        updateSapIntegration: builder.mutation({
            query: ({ id, data }) => ({
                url: `sap/${id}`,
                method: 'put',
                body: data,
            }),
            onCacheEntryAdded: (args, { dispatch }) => {
                dispatch(integrationApi.util.invalidateTags(['integrations']));
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando integración SAP</b>,
                    success: () => {
                        api.dispatch(integrationApi.util.invalidateTags(['integrations']));

                        return <b>Integración SAP actualizada con éxito</b>;
                    },
                    error: (response) => (
                        <b>
                            {ERROR_CODES[response?.error?.data?.errorCode] || 'Ocurrió un error.'}
                        </b>
                    ),
                });
            },
        }),
    }),
});

export const {
    useGetDimensionsQuery,
    useUpdateDimensionsMutation,
    useGetWarehousesQuery,
    useUpdateWarehouseMutation,
    useGetClientsQuery,
    useUpdateClientsMutation,
    useGetSalesPersonsQuery,
    useUpdateSalesPersonsMutation,
    useUpdateSapIntegrationMutation,
    useUpdateSeriesMutation,
    useGetSeriesByDocumentMutation,
    useUpdateIndicatorsMutation,
    useUpdateDocumentsMutation,
    useGetMetadataCardTypesQuery,
    useGetMetadataUserFieldsQuery,
    useGetMetadataDocumentsQuery,
    useGetMetadataOmsFieldsQuery,
    useGetMetadataOmsDirectionsFieldsQuery,
    useGetIndicatorsQuery,
    useGetMetadataSapDirectionsFieldsQuery,
    useGetBusinessPartnerGroupsQuery,
    useGetCurrenciesQuery,
    useGetPriceListQuery,
    useGetPaymentTermsTypesQuery,
    useGetCreditCardsQuery,
    useUpdatePaymentMethodsMutation,
    useGetSalesChannelAndOriginsMutation,
    useGetOmsPaymentMethodsQuery,
    useGetSalesTaxCodesQuery,
} = sapApi;
