import { createSlice } from '@reduxjs/toolkit';

const filtersArrivalPending = createSlice({
    name: 'filtersArrivalPending',
    initialState: {
        name: undefined,
        courier: undefined,
        courier_service: undefined,
        delivery_method: undefined,
        origin: undefined,
        province: undefined,
        status: undefined,
        featured_filters: [
            'courier',
            'courier_service',
            'delivery_method',
            'origin',
            'province',
            'status',
        ],
        custom_sort: {
            sortBy: '',
            sortValue: '',
        },
        limit: 50,
        page: 1,
    },
    reducers: {
        changeAllFP: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        removeAllFP: () => {
            return {
                name: undefined,
                courier: undefined,
                courier_service: undefined,
                delivery_method: undefined,
                origin: undefined,
                province: undefined,
                featured_filters: [
                    'courier',
                    'courier_service',
                    'delivery_method',
                    'origin',
                    'province',
                    'status',
                ],
                custom_sort: {
                    sortBy: '',
                    sortValue: '',
                },
                limit: 50,
                page: 1,
            };
        },
        changeFeaturedFiltersFD: (state, { payload }) => {
            const { keyName, isChecked } = payload;
            const isAlreadyFeatured = state.featured_filters.includes(keyName);
            let newFeaturesArray = [];
            if (isChecked && !isAlreadyFeatured) {
                newFeaturesArray = [...state.featured_filters, keyName];
            } else {
                newFeaturesArray = state.featured_filters.filter((el) => el !== keyName);
            }
            return {
                ...state,
                featured_filters: [...newFeaturesArray],
            };
        },
        changeCustomSortFD: (state, { payload }) => {
            const { sortBy, sortValue } = payload;
            return {
                ...state,
                custom_sort: {
                    ...state.custom_sort,
                    sortBy,
                    sortValue,
                },
            };
        },
    },
});
export const { changeAllFP, removeAllFP, changeFeaturedFiltersFP, changeCustomSortFP } =
    filtersArrivalPending.actions;
export default filtersArrivalPending.reducer;
