import { Tabs } from 'antd';
import styled, { css } from 'styled-components';

export default styled(Tabs)`
    transition: 0s !important;
    animation-duration: 0s !important;

    .ant-tabs-tab {
        color: #7998a7 !important;
        padding: 0.8rem !important;
        font-weight: 500;
        letter-spacing: 0.5px;
        height: 41px;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: transparent;
        border-radius: 4px 4px 0px 0px;
        margin-bottom: 0 !important;
    }

    .ant-tabs-nav::before {
        border-color: #037fb9;
    }

    .ant-tabs-nav {
        margin-bottom: 0;
    }

    .ant-tabs-content-holder {
        ${({ $activeBorder }) =>
            $activeBorder &&
            css`
                // padding: 15px;
                border: 1px solid #037fb9;
                border-top: transparent;
            `}
    }

    ${({ $show }) =>
        !$show &&
        css`
            .ant-tabs-nav {
                display: none;
            }
        `}
    .ant-tabs-ink-bar {
        background-color: transparent;
    }

    .ant-tabs-tab-active {
        font-weight: 500;
        border-color: #037fb9;
        background: white;

        &:not(:last-child) {
            box-shadow: 5px 0 15px -10px black;
        }

        ${({ $activeBorder }) =>
            $activeBorder &&
            css`
                border-bottom: 0px !important;
            `}
        .ant-tabs-tab-btn {
            color: #037fb9 !important;
            text-shadow: unset;
        }
    }

    .ant-tabs-tab + .ant-tabs-tab-active {
        &:last-child {
            box-shadow: unset !important;
        }
    }
`;
