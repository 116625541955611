import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const MasterClient = fetchBaseQuery({
    baseUrl: 'https://api-master.be-flow.com/',
    headers: {
        'Content-Type': 'application/json',
    },
    prepareHeaders: async (headers) => {
        try {
            return headers;
        } catch (error) {
            console.error(error);
            return headers;
        }
    },
});

export default MasterClient;
