export const defaultColumnsView = [
    {
        name: 'remote_order_id',
        label: 'ID Ecommerce',
        status: true,
        position: 1,
    },
    {
        name: 'fulfillments',
        label: 'Fulfillments',
        status: true,
        position: 2,
    },
    {
        name: 'location_id',
        label: 'Tienda',
        status: true,
        position: 3,
    },
    {
        name: 'customer',
        label: 'Cliente',
        status: true,
        position: 4,
    },
    {
        name: 'promised_date',
        label: 'Plazo límite',
        status: true,
        position: 5,
    },
    {
        name: 'promised_date_range',
        label: 'Horario de entrega',
        status: true,
        position: 6,
    },
    {
        name: 'fulfillment_status',
        label: 'Estado de entrega',
        status: true,
        position: 7,
    },
    {
        name: 'assigned_courier',
        label: 'Courier',
        status: true,
        position: 8,
    },
    {
        name: 'createdAt',
        label: 'Fecha de creación',
        status: true,
        position: 9,
    },
    {
        name: 'delivery_method',
        label: 'Tipo de entrega',
        status: true,
        position: 10,
    },
    {
        name: 'documents',
        label: 'Documentos',
        status: true,
        position: 11,
    },
    {
        name: 'actions',
        label: 'Acciones',
        status: true,
        position: 12,
    },
];
export const INITIAL_PAGE_SIZE = 25;
