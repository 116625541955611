import { Typography } from 'antd';
import styled from 'styled-components';

export const ScannedCodesStyled = styled.section`
    .ant-card-body {
        padding: 1px;
    }
`;

export const ScannedCodeRow = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ScannedRowTitle = styled.span`
    ${({ width }) => width && `width: ${width}`};
    white-space: nowrap;
    margin-right: 0.5rem;
    color: #1f6373;
    ${({ bold }) => bold && `font-weight: 600`}
`;

export const ScannedRowItem = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.4rem;
`;

export const TypoText = styled(Typography.Text)`
    margin-bottom: 0px;
    font-weight: 600;
    display: flex;
    align-items: center;
    .ant-typography-copy {
        top: 0px;
    }
    .ant-typography-copy-success {
        display: flex;
        align-items: center;
    }
`;
