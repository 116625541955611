import { useMemo, useRef, useState } from 'react';
import { Skeleton } from 'antd';
import { toast } from 'react-hot-toast';
import { useUpdateUserPreferencesMutation } from 'redux/features/Users';
import Flex from 'components/Utils/Flex';
import Tabs from 'components/V2/Tabs';
import Menu from 'components/V2/Menu';
import MenuItem from 'components/V2/Menu/MenuItem';
import Dropdown from 'components/V2/Dropdown';
import { ReactComponent as TagIcon } from 'assets/tag-multiple-outline.svg';
import { ReactComponent as MeatballsVerticalIcon } from 'assets/dots-vertical.svg';
import { ReactComponent as AddIcon } from 'assets/plus-circle-outline.svg';
import { getLastNewViewIndex, defaultColumnsView } from './utils';
import { TabStyle, TypographyStyled } from './styles';
import { removeBasicTabs } from 'helpers/removeBasicTabs';

const ViewTabs = ({
    userPreferences,
    excludeTabs = [],
    selectedTab,
    handleChangeTab,
    preferences,
    dataPickupsPreferences,
}) => {
    const [isEditName, setIsEditName] = useState(false);
    const [isUpdatingTabs, setIsUpdatingTabs] = useState({ tab: '', isUpdating: false });
    const tabNameRef = useRef('');
    const meatballsRef = useRef();
    const [updateUserPreferences] = useUpdateUserPreferencesMutation();

    const handleRenameTab = (e) => {
        e.stopPropagation();
        const tabToRenameIndex = userPreferences?.preferences[preferences]?.tabs?.findIndex(
            (tab) => tab.value === selectedTab
        );

        if (tabToRenameIndex > -1) {
            tabNameRef.current =
                userPreferences?.preferences[preferences]?.tabs[tabToRenameIndex]?.name;
            setIsEditName(true);
        }
    };

    const handleDuplicateTab = async (e) => {
        e.stopPropagation();
        const newUserPreferences = structuredClone(userPreferences);
        // Get a deep copy of the tab object
        const tabToDuplicate = structuredClone(
            newUserPreferences?.preferences[preferences].tabs.find(
                (tab) => tab.value === selectedTab
            )
        );

        if (meatballsRef.current) {
            meatballsRef.current.style.border = 'none';
        }

        if (tabToDuplicate) {
            setIsUpdatingTabs({ tab: 'add', isUpdating: true });
            // Change tab value to not have duplicated, value should be unique
            tabToDuplicate.value =
                tabToDuplicate.value + Math.floor(Math.random() * tabToDuplicate.columns.length);

            // Change tab name, should be unique
            tabToDuplicate.name = 'Copia de ' + tabToDuplicate.name;

            const userPreferencesNoBasicTabs = removeBasicTabs(newUserPreferences);

            await updateUserPreferences({
                ...userPreferencesNoBasicTabs,
                preferences: {
                    ...userPreferencesNoBasicTabs.preferences,
                    [preferences]: {
                        ...userPreferencesNoBasicTabs.preferences[preferences],
                        tabs: [
                            ...userPreferencesNoBasicTabs.preferences[preferences].tabs,
                            tabToDuplicate,
                        ],
                    },
                },
            });
            toast.success('Vista duplicada con éxito');
            setIsUpdatingTabs({ tab: '', isUpdating: false });
        }
    };

    const handleDeleteTab = async (e) => {
        e.stopPropagation();
        const newUserPreferences = structuredClone(userPreferences);
        const tabToDeleteIndex = newUserPreferences?.preferences[preferences]?.tabs?.findIndex(
            (tab) => tab.value === selectedTab
        );

        if (tabToDeleteIndex > -1) {
            setIsUpdatingTabs({ tab: selectedTab, isUpdating: true });
            newUserPreferences?.preferences[preferences]?.tabs.splice(tabToDeleteIndex, 1);

            const userPreferencesNoBasicTabs = removeBasicTabs(newUserPreferences);

            await updateUserPreferences(userPreferencesNoBasicTabs);
            handleChangeTab('all');
            toast.success('Vista eliminada con éxito');
            setIsUpdatingTabs({ tab: '', isUpdating: false });
        }
    };

    const handleEditEnd = async () => {
        setIsEditName(false);
        // If name is empty, return
        if (!tabNameRef.current) return;
        setIsUpdatingTabs({ tab: selectedTab, isUpdating: true });
        const newUserPreferences = structuredClone(userPreferences);

        const tabToRenameIndex = newUserPreferences?.preferences[preferences]?.tabs?.findIndex(
            (tab) => tab.value === selectedTab
        );

        newUserPreferences.preferences[preferences].tabs[tabToRenameIndex].name =
            tabNameRef.current;

        const userPreferencesNoBasicTabs = removeBasicTabs(newUserPreferences);

        await updateUserPreferences(userPreferencesNoBasicTabs);
        toast.success('Vista renombrada con éxito');
        setIsUpdatingTabs({ tab: '', isUpdating: false });
    };

    const addTab = async () => {
        setIsUpdatingTabs({ tab: 'add', isUpdating: true });
        const newUserPreferences = structuredClone(userPreferences);
        const value = `Nueva vista ${getLastNewViewIndex(
            userPreferences?.preferences[preferences].tabs
        )}`;

        const newTab = {
            name: value,
            value: value?.toLowerCase().replace(/ /g, '_') + Date.now(),
            typeOfTab: 'custom',
            columns: [...defaultColumnsView[preferences]],
        };

        const userPreferencesNoBasicTabs = removeBasicTabs(newUserPreferences);

        await updateUserPreferences({
            ...userPreferencesNoBasicTabs,
            preferences: {
                ...userPreferencesNoBasicTabs.preferences,
                [preferences]: {
                    ...userPreferencesNoBasicTabs.preferences[preferences],
                    tabs: [...userPreferencesNoBasicTabs.preferences[preferences].tabs, newTab],
                },
            },
        });
        toast.success('Nueva vista creada con éxito');
        setIsUpdatingTabs({ tab: '', isUpdating: false });
        handleChangeTab(newTab.value);
    };

    const menu = useMemo(
        () => (
            <Menu>
                <MenuItem onClick={({ domEvent }) => handleRenameTab(domEvent)}>Renombrar</MenuItem>
                <MenuItem onClick={({ domEvent }) => handleDuplicateTab(domEvent)}>
                    Duplicar
                </MenuItem>
                <MenuItem onClick={({ domEvent }) => handleDeleteTab(domEvent)}>Eliminar</MenuItem>
            </Menu>
        ),
        [selectedTab, userPreferences]
    );

    const uniqueTabs = Array.from(userPreferences?.preferences[preferences]?.tabs ?? [])?.filter(
        (tab, index, self) =>
            index === self.findIndex((t) => t.name === tab.name) && !excludeTabs.includes(tab.value)
    );

    return (
        <>
            {userPreferences ? (
                <Tabs
                    margin="2rem 0 0"
                    defaultActiveKey={'all'}
                    activeKey={selectedTab}
                    type="editable-card"
                    addIcon={
                        isUpdatingTabs.tab === 'add' && isUpdatingTabs.isUpdating ? (
                            <Skeleton active paragraph={false} title={{ width: 24 }} />
                        ) : (
                            <AddIcon width={20} height={20} />
                        )
                    }
                    onTabClick={(key) => {
                        setIsEditName(false);
                        handleChangeTab(key);
                    }}
                    onEdit={(targetKey, action) => {
                        if (action === 'add') {
                            addTab();
                        }
                    }}
                    animated={{
                        inkBar: false,
                        tabPane: false,
                    }}
                >
                    {(uniqueTabs ?? [])
                        .filter((tab) =>
                            tab.value === 'pending_pickup' &&
                            typeof dataPickupsPreferences?.delivery_method_pickup === 'boolean'
                                ? dataPickupsPreferences?.delivery_method_pickup
                                : true
                        )
                        ?.map((tab) => (
                            <Tabs.TabPane
                                key={tab.value}
                                closeIcon={<></>}
                                tab={
                                    <Flex direction="row" alignItems="center">
                                        {isUpdatingTabs.isUpdating &&
                                        isUpdatingTabs.tab !== 'add' &&
                                        tab.value === selectedTab ? (
                                            <Skeleton
                                                active
                                                paragraph={false}
                                                title={{ width: 56 }}
                                            />
                                        ) : (
                                            <TabStyle
                                                onKeyDown={(e) => e.stopPropagation()}
                                                onClick={(e) => {
                                                    if (isEditName && selectedTab === tab.value) {
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            >
                                                {tab.typeOfTab === 'custom' ? (
                                                    <TagIcon
                                                        style={{
                                                            display: 'inline',
                                                            marginRight: '0.25rem',
                                                        }}
                                                    />
                                                ) : null}
                                                <TypographyStyled
                                                    editable={{
                                                        autoSize: true,
                                                        editing:
                                                            isEditName && selectedTab === tab.value,
                                                        enterIcon: null,
                                                        tooltip: false,
                                                        maxLength: 50,
                                                        triggerType: [],
                                                        onStart: () => setIsEditName(true),
                                                        onCancel: () => setIsEditName(false),
                                                        onChange: (value) =>
                                                            (tabNameRef.current = value),
                                                        onEnd: handleEditEnd,
                                                    }}
                                                    style={{ left: 0, margin: 0 }}
                                                >
                                                    {tab.name}
                                                </TypographyStyled>
                                                {tab.typeOfTab === 'custom' &&
                                                    tab.value === selectedTab && (
                                                        <Dropdown
                                                            overlay={menu}
                                                            trigger={['click']}
                                                            onOpenChange={(open) => {
                                                                if (
                                                                    open &&
                                                                    meatballsRef?.current?.style
                                                                ) {
                                                                    meatballsRef.current.style.border =
                                                                        '1px solid #9EBCC0';
                                                                } else {
                                                                    meatballsRef.current.style.border =
                                                                        'none';
                                                                }
                                                            }}
                                                        >
                                                            <a onClick={(e) => e.stopPropagation()}>
                                                                <MeatballsVerticalIcon
                                                                    ref={meatballsRef}
                                                                    width={22}
                                                                    height={22}
                                                                    style={{
                                                                        display: 'inline',
                                                                        marginLeft: '0.25rem',
                                                                        borderRadius: '2px',
                                                                    }}
                                                                />
                                                            </a>
                                                        </Dropdown>
                                                    )}
                                            </TabStyle>
                                        )}
                                    </Flex>
                                }
                            ></Tabs.TabPane>
                        ))}
                </Tabs>
            ) : (
                <Skeleton
                    active
                    paragraph={false}
                    title={{ width: 300 }}
                    style={{ margin: '26px 0' }}
                />
            )}
        </>
    );
};

export default ViewTabs;
