import { Typography } from 'antd';
import AntdTable from 'components/AntdTable';
import Flex from 'components/Utils/Flex';
import { useMemo } from 'react';

const ProductList = ({ order = {}, line_items = [] }) => {
    const { name = "" } = order ?? {};

    const tableData = useMemo(() => [...line_items], [line_items]);

    const tableColumns = useMemo(
        () => [
            {
                title: 'SKU',
                key: 'sku',
                dataIndex: 'sku',
                width: '200px',
                render: (value) => (
                    <Typography.Paragraph style={{ marginBottom: '0px' }}>
                        {value}
                    </Typography.Paragraph>
                ),
            },
            {
                title: 'Descripción',
                dataIndex: 'name',
                key: 'name',
            },

            {
                title: 'Cantidad',
                dataIndex: 'quantity',
                key: 'quantity',
                width: '100px',
            },
        ],
        [line_items]
    );

    return (
        <Flex direction="column" rowGap="1rem">
            <h1>Orden: {name}</h1>
            <AntdTable rowKey="_id" columns={tableColumns} dataSource={tableData} />
        </Flex>
    );
};

export default ProductList;
