import qs from 'qs';
import { useGetOrderReportsQuery } from 'redux/features/Reports';
import MetricCard from 'components/V2/Metrics/MetricCard';
import { ReactComponent as OrdersMetricsIcon } from 'assets/orders-metrics.svg';
import { ReactComponent as PendingMetricsIcon } from 'assets/pending-metrics.svg';
import { ReactComponent as AvgMetricsIcon } from 'assets/avg-metrics.svg';
import { MetricsContainer } from '../styles';

const OrderPreparationMetrics = ({ selectedDateRange, selectedLocation, isSAC = false, view }) => {
    // Para obtener datos para las metricas
    const metricsObject = {
        ...selectedDateRange,
        // location_id: !['', 'all'].includes(shopSelected) ? shopSelected : undefined,
        location_id: selectedLocation,
    };
    const {
        data = {},
        isLoading,
        isFetching,
    } = useGetOrderReportsQuery(qs.stringify(metricsObject), {
        pollingInterval: 1800000,
    });

    const isPreparations = view === 'preparations';

    const metrics = [
        {
            name: 'Total de pedidos',
            type: 'orders',
            quantity: data?.total_orders ?? 0,
            measurement: '',
            tooltipTitle:
                'Total de pedidos recibidos para la tienda seleccionada en el plazo especificado',
            icon: <OrdersMetricsIcon />,
            color: '#7998A7',
        },
        {
            name: isPreparations
                ? 'Pendientes de preparación'
                : 'Pedidos pendientes de preparación',
            type: 'pending_orders',
            quantity: data?.total_unfulfilled_orders ?? 0,
            measurement: '',
            tooltipTitle:
                'Total de pedidos pendientes de preparación en el plazo especificado para la tienda seleccionada',
            icon: <PendingMetricsIcon />,
            color: '#B00303',
        },
        {
            name: 'Promedio de preparación',
            type: 'fulfillment_avg',
            quantity: data?.AVG_fulfillment ?? 0,
            measurement: 'min',
            tooltipTitle:
                'Tiempo promedio de preparación para el usuario activo en el plazo especificado y la tienda seleccionada',
            icon: <AvgMetricsIcon />,
            color: '#037FB9',
        },
    ];

    const metricsSAC = [
        {
            name: 'Pedidos pendientes',
            type: 'sac_pending',
            quantity: data?.sac_pending ?? 0,
            measurement: '',
            tooltipTitle: 'Total de pedidos pendientes SAC',
            icon: <OrdersMetricsIcon />,
            color: '#7998A7',
        },
        {
            name: 'Pedidos en proceso',
            type: 'sac_inProgress',
            quantity: data?.sac_inProgress ?? 0,
            measurement: '',
            tooltipTitle: 'Total de pedidos en proceso SAC',
            icon: <PendingMetricsIcon />,
            color: '#B00303',
        },
        {
            name: 'Pedidos resueltos',
            type: 'sac_resolved',
            quantity: data?.sac_resolved ?? 0,
            measurement: '',
            tooltipTitle: 'Total de pedidos resueltos SAC',
            icon: <AvgMetricsIcon />,
            color: '#037FB9',
        },
    ];

    return (
        <MetricsContainer>
            {isSAC
                ? metricsSAC.map((metric) => (
                      <MetricCard
                          key={metric.name}
                          color={metric.color}
                          name={metric.name}
                          tooltipTitle={metric.tooltipTitle}
                          metricType={metric.type}
                          quantity={metric.quantity}
                          measurement={metric.measurement}
                          icon={metric.icon}
                          loading={isLoading || isFetching}
                      />
                  ))
                : metrics.map((metric) => (
                      <MetricCard
                          key={metric.name}
                          color={metric.color}
                          name={metric.name}
                          tooltipTitle={metric.tooltipTitle}
                          metricType={metric.type}
                          quantity={metric.quantity}
                          measurement={metric.measurement}
                          icon={metric.icon}
                          loading={isLoading || isFetching}
                      />
                  ))}
        </MetricsContainer>
    );
};

export default OrderPreparationMetrics;
