export const defaultColumnsView = [
    {
        name: 'name',
        label: 'Pedido',
        status: true,
        position: 0,
    },
    {
        name: 'fulfillments',
        label: 'Fulfillments',
        status: true,
        position: 1,
    },
    {
        name: 'module',
        label: 'Módulo (s)',
        status: true,
        position: 2,
    },
    {
        name: 'remote_order_id',
        label: 'ID Ecommerce',
        status: true,
        position: 3,
    },
    {
        name: 'sales_channel',
        label: 'Canal de venta',
        status: true,
        position: 4,
    },
    {
        name: 'origin_sold_at',
        label: 'Fecha de venta',
        status: true,
        position: 5,
    },
    {
        name: 'createdAt',
        label: 'Fecha de creación',
        status: true,
        position: 6,
    },
    {
        name: 'promised_date',
        label: 'Plazo límite',
        status: true,
        position: 7,
    },
    {
        name: 'custom_promised_date',
        label: 'Promesa de Entrega',
        status: true,
        position: 8,
    },
    {
        name: 'promised_date_range',
        label: 'Horario de entrega',
        status: true,
        position: 9,
    },
    {
        name: 'fulfillment_status',
        label: 'Estado',
        status: true,
        position: 10,
    },
    {
        name: 'delivery_method',
        label: 'Tipo de entrega',
        status: true,
        position: 11,
    },
    {
        name: 'assigned_courier',
        label: 'Courier',
        status: true,
        position: 12,
    },

    {
        name: 'customer',
        label: 'Cliente',
        status: true,
        position: 13,
    },
    {
        name: 'customer_document',
        label: 'Doc. de identidad',
        status: true,
        position: 14,
    },
    {
        name: 'shipping_address',
        label: 'Dirección de entrega',
        status: true,
        position: 15,
    },
    {
        name: 'delivery_date',
        label: 'Fecha de retiro',
        status: true,
        position: 16,
    },
    {
        name: 'line_items',
        label: 'Unidades',
        status: true,
        position: 17,
    },
    {
        name: 'location_id',
        label: 'Tienda',
        status: true,
        position: 18,
    },
    {
        name: 'locations.destination',
        label: 'Tienda de destino',
        status: false,
        position: 19,
    },
    {
        name: 'total_price',
        label: 'Total',
        status: true,
        position: 20,
    },
    {
        name: 'search_references',
        label: 'Folio',
        status: true,
        position: 21,
    },
];
