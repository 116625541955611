/* eslint-disable no-undef */
import { Auth } from 'aws-amplify';
import axios from 'axios';
import history from 'helpers/history';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import getFromEnv from 'helpers/getFromEnv';

let API_URL = getFromEnv('REACT_APP_API_URL') ?? 'https://api-dev.be-flow.com';

const getToken = async () => {
    const session = await Auth.currentSession();
    const { jwtToken } = session.accessToken;
    return jwtToken;
};

export const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    prepareHeaders: async (headers) => {
        try {
            const jwtToken = await getToken();
            if (jwtToken) headers.set('Authorization', `Bearer ${jwtToken}`);
            return headers;
        } catch (error) {
            await Auth.signOut();
            history.push('/login');
        }
    },
});

const AndesClient = axios.create({
    baseURL: API_URL,
    timeout: 30000,
});

AndesClient.interceptors.request.use(async (config) => {
    try {
        const jwtToken = await getToken();
        config.headers['Authorization'] = jwtToken ? `Bearer ${jwtToken}` : '';
        return config;
    } catch (e) {
        await Auth.signOut();
        history.push('/login');
    }
});

export default AndesClient;
