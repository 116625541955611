import { ReactComponent as EditIcon2 } from 'assets/edit-icon2.svg';
import NormalButton from '../NormalButton';

const EditButton = (props) => {
    return (
        <NormalButton
            size="small"
            variation="link"
            icon={<EditIcon2 style={{ width: '16px', height: '16px' }} />}
            {...props}
        />
    );
};

export default EditButton;
