export const itemsQuantity = [
    {
        name: 'Mostrar 25',
        quantity: 25,
    },
    {
        name: 'Mostrar 50',
        quantity: 50,
    },
    {
        name: 'Mostrar 75',
        quantity: 75,
    },
    {
        name: 'Mostrar 100',
        quantity: 100,
    },
];
