import { /* Select, */ Form } from 'antd';
import SelectTypeOne from 'components/Select';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import TextArea from 'antd/lib/input/TextArea';
import ModalWithButton from 'components/ModalWithButton';
import Flex from 'components/Utils/Flex';
import { renderOrderSacStatusLabel } from 'helpers/renderOrderLabel';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateOrderSacStatus } from 'redux/features/Orders/changeSacStatus';

const all_states = [
    'in_normal_process',
    'in_courier_shipping',
    'finished_normal',
    'waiting_for_solution',
    'client_contacted',
    'finished_with_incidence',
];

const ChangeSacOrderStatus = ({ order, modalProps, buttonProps, refetch }) => {
    const [hasNote, setHasNote] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const handleSubmit = (values) => {
        const obj = [
            {
                order_id: order._id,
                new_status: values.new_status,
                comment: values?.comment ?? '',
            },
        ];
        dispatch(updateOrderSacStatus(obj));
        console.log({ obj });

        setTimeout(() => {
            refetch();
        }, 3000);
    };
    const title = (
        <span style={{ fontSize: '16px', color: '#2D3D76', fontWeight: '600' }}>
            Cambiar estado sac del pedido
        </span>
    );

    return (
        <ModalWithButton
            modalProps={{
                cancelText: 'Cancelar',
                okText: 'Guardar cambios',
                title: title,
                name: 'order-change-sac-status-modal',
                onOk: () => {
                    form.submit();
                },
                ...modalProps,
            }}
            buttonType="menuitem"
            buttonProps={{
                ...buttonProps,
            }}
        >
            <Form form={form} name="order-change-sac-status-form" onFinish={handleSubmit}>
                <Flex direction="column" rowGap="20px">
                    <Flex direction="row" columnGap="10px">
                        <p>Estado actual </p>
                        <span>{renderOrderSacStatusLabel(order?.sac_status)}</span>
                    </Flex>
                    <Flex direction="row" columnGap="10px">
                        <p style={{ marginTop: '5px' }}>Nuevo estado a cambiar </p>
                        <Form.Item
                            name="new_status"
                            // label="Nuevo estado a modificar"
                            style={{ fontWeight: '500' }}
                            rules={[{ required: true, message: 'Este campo es requerido' }]}
                        >
                            <SelectTypeOne
                                data={all_states.filter((state) => state !== order?.sac_status)}
                                optionProps={{
                                    renderLabel: renderOrderSacStatusLabel,
                                }}
                                showArrow={true}
                                showSearch={false}
                                placeholder="Seleccione un estado"
                            />
                        </Form.Item>{' '}
                    </Flex>

                    <Flex direction="row" alignItems="center" columnGap="10px">
                        <Checkbox
                            className="checkbox-reversed"
                            checked={hasNote}
                            onChange={(e) => setHasNote(e.target.checked)}
                        >
                            <p>Agregar nota por modificación</p>
                        </Checkbox>
                    </Flex>

                    {hasNote && (
                        <Form.Item name="comment">
                            <TextArea style={{ resize: 'none' }}></TextArea>
                        </Form.Item>
                    )}
                </Flex>
            </Form>
        </ModalWithButton>
    );
};

export default ChangeSacOrderStatus;
