import Flex from 'components/Utils/Flex';

const PackageProductsTable = ({ pkg = {} }) => {
    return (
        <Flex direction="column" width="100%">
            {pkg?.line_items?.map((item) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',

                            padding: '5px',
                        }}
                        key={item?._id}
                    >
                        <b>Productos en el bulto: {pkg?.code} </b>
                        <br />
                        <span>
                            <b>Nombre producto: </b>
                            {item.line_item?.name}
                        </span>
                        <span>
                            <b>Cantidad: </b>
                            {item?.quantity}
                        </span>
                    </div>
                );
            })}
        </Flex>
    );
};

export default PackageProductsTable;
