import { useState } from 'react';
import { TextUnderline } from '../styles';
import { ModalRpro9ErrorDetail } from './ModalErrorDetail';

export const Rpro9ErrorTimeline = ({ log, entity, labels }) => {
    const [isOpenModalErrorDetail, setIsOpenModalErrorDetail] = useState(false);

    console.log('Rpro9ErrorTimeline', log);

    return (
        <>
            <span>
                Se han encontrado{' '}
                <TextUnderline onClick={() => setIsOpenModalErrorDetail(true)}>
                    errores
                </TextUnderline>{' '}
                en la integración de la {entity} a{' '}
                {labels[log?.other?.platform?.toLowerCase() ?? ''] ??
                    log?.other?.platform?.toLowerCase() ??
                    ''}{' '}
                para el pedido {log?.other?.order_name ?? ''}
            </span>

            <ModalRpro9ErrorDetail
                isOpen={isOpenModalErrorDetail}
                onClose={() => setIsOpenModalErrorDetail(false)}
                data={{ error: log?.other?.error } ?? {}}
            />
        </>
    );
};
