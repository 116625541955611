import { Transfer } from 'antd';
import styled from 'styled-components';

export default styled(Transfer)`
    .ant-transfer-list {
        border: 1px solid black;
    }

    .ant-transfer-customize-list {
        .ant-transfer-list {
            max-width: 260px !important;
        }
    }

    .ant-transfer-operation {
        .ant-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
    & .ant-btn > .anticon + span,
    .ant-btn > span + .anticon {
        margin-left: 4px;
    }

    & .ant-checkbox-inner {
        border-color: ${({ theme }) =>
            theme?.buttons?.colors?.secondary?.['first'] ?? '#037FB9'} !important;
    }

    & .ant-checkbox-checked .ant-checkbox-inner {
        background-color: ${({ theme }) =>
            theme?.buttons?.colors?.secondary?.['first'] ?? '#037FB9'} !important;
        border: 1px solid
            ${({ theme }) => theme?.buttons?.colors?.secondary?.['first'] ?? '#037FB9'} !important;
    }

    & .ant-checkbox-checked .ant-checkbox-inner::after {
        background-color: ${({ theme }) =>
            theme?.buttons?.colors?.secondary?.['first'] ?? '#037FB9'} !important;
    }
`;
