export const deliveriesFilterFields = [
    {
        label: 'Canal de venta',
        value: 'sales_channel',
    },
    {
        label: 'Código de bulto',
        value: 'package_code::search_references',
    },
    {
        label: 'Courier',
        value: 'courier',
    },
    {
        label: 'Estado',
        value: 'status',
    },
    {
        label: 'Tienda de destino',
        value: 'locations.destination',
    },
    {
        label: 'Folio',
        value: 'folio::search_references',
    },
    {
        label: 'Número de fulfillment',
        value: 'name',
    },
    {
        label: 'Número de pedido',
        value: 'remote_order_id',
    },
    {
        label: 'Plazo límite (h)',
        value: 'promised_date',
    },
    {
        label: 'Promesa de entrega',
        value: 'custom_promised_date',
    },
    {
        label: 'Horario de entrega',
        value: 'promised_date_range',
    },
    {
        label: 'Tipo de entrega',
        value: 'delivery_method',
    },
    {
        label: 'Cliente',
        value: 'customer_name',
    },
    {
        label: 'Región',
        value: 'destination.province',
    },
];
