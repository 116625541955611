import { Fragment, useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, Collapse, DatePicker, Form, Input, Row, Select, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import { overrideFilters } from 'redux/features/Deliveries/deliveriesFilters';
import { setDeliveriesFilters } from 'redux/features/Utils';
import ClosableTag from 'components/Tag/ClosableTag';
import NormalButton from 'components/Buttons/NormalButton';
import MenuItem from 'components/V2/Menu/MenuItem';

import { conditions, sorters } from 'components/V2/Filters/constants';

import { ReactComponent as RightIcon } from 'assets/right.svg';
import { ReactComponent as DownIcon } from 'assets/down.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/arrow-up.svg';
import { ReactComponent as ArrowDownIcon } from 'assets/arrow-down.svg';
import { CollapsePanelStyled, Divider, InColumnFiltersMenu } from './styles';
import { ReactComponent as SortDescIcon } from 'assets/sort_desc_icon.svg';
import { ReactComponent as SortAscIcon } from 'assets/sort_asc_icon.svg';
import moment from 'moment';

const TableHeaderExtension = ({
    columnKey,
    type,
    refetch,
    setActiveDropdownFilterColumn,
    tabColumns,
    hideSorter,
}) => ({
    onFilterDropdownVisibleChange: (visible) => {
        setActiveDropdownFilterColumn(visible ? columnKey : null);
    },
    filterDropdown: ({ close }) => {
        const { filters, valueOptionByField } = useSelector((state) => state.deliveriesFilters);
        const { pendingArrivalsFilters } = useSelector((state) => state.utils);

        const [form] = Form.useForm();
        const dispatch = useDispatch();

        const [filter, setFilter] = useState(
            filters?.find((filter) => filter.field === columnKey) ?? {
                andOr: filters.length > 0 ? 'and' : '',
                field: columnKey,
                condition: '',
                value: '',
                isRemovable: true,
                id: nanoid(),
                compromiseValues: [...(valueOptionByField[columnKey] ?? [])],
            }
        );

        // Efecto para actualizar el filtro desde la store de redux
        useEffect(() => {
            const filter = filters?.find((filter) => filter.field === columnKey) ?? {
                andOr: filters.length > 0 ? 'and' : '',
                field: columnKey,
                condition: '',
                value: '',
                isRemovable: true,
                id: nanoid(),
                compromiseValues: [...(valueOptionByField[columnKey] ?? [])],
            };
            setFilter(filter);
        }, [filters, columnKey]);

        // Efecto para actualizar compromiseValues desde la store de redux
        useEffect(() => {
            setFilter({
                ...filter,
                compromiseValues: [...(valueOptionByField[columnKey] ?? [])],
            });
        }, [valueOptionByField, columnKey]);

        const memoizedFilter = useMemo(() => {
            const filterMemo = filters?.find((filter) => filter.field === columnKey) ?? {
                andOr: filters.length > 0 ? 'and' : '',
                field: columnKey,
                condition: '',
                value: '',
                isRemovable: true,
                id: nanoid(),
                compromiseValues: [...(valueOptionByField[columnKey] ?? [])],
            };
            return filterMemo;
        }, [filters, filter]);

        const handleUpdateFiltersQuery = (updatedFilters, sort = {}) => {
            // Despachamos los filtros para que se apliquen
            dispatch(
                setDeliveriesFilters({
                    filters: [
                        ...(updatedFilters?.map((filter) => ({
                            andOr: filter.andOr,
                            field: filter.field,
                            condition: filter.condition,
                            value: filter.value,
                        })) ?? []),
                    ],
                    page: 1,
                    sort,
                })
            );
            refetch(true);
        };

        const handleSelectCondition = (value) => {
            setFilter({
                ...filter,
                condition: value,
                value: value === 'includes' || value === 'not_include' ? [] : '',
            });
        };

        const handleSelectValue = (value) => {
            setFilter({
                ...filter,
                value,
            });
        };

        const handleSelectMultipleValues = (value) => {
            setFilter({
                ...filter,
                value: [...filter.value, value],
            });
        };

        const handleDeselectMultipleValues = (value) => {
            const removedValueArray = filter?.value?.filter((item) => item !== value);
            setFilter({
                ...filter,
                value: removedValueArray,
            });
        };

        const handleCancel = () => {
            setFilter({
                ...memoizedFilter,
            });
            close();
        };

        const handleFormApply = () => {
            const filtersCopy = structuredClone(filters);

            // Check if filter already exists
            const filterExistsIndex = filtersCopy.findIndex((filter) => filter.field === columnKey);

            // If filter exists, override it
            if (filterExistsIndex !== -1) {
                filtersCopy[filterExistsIndex] = filter;
                dispatch(overrideFilters(filtersCopy));
                handleUpdateFiltersQuery(filtersCopy);
                return close();
            }

            dispatch(overrideFilters([...filtersCopy, filter]));
            handleUpdateFiltersQuery([...filtersCopy, filter]);

            close();
        };

        const handleSorter = (sorter) => {
            const sort = {
                sortBy: columnKey,
                sortValue: sorters[sorter],
            };
            handleUpdateFiltersQuery(filters, sort);
            close();
        };

        const menuPlacementHelper = () => {
            // tabColumns columnKey

            const thisColumn = (tabColumns ?? []).find(
                (column, index) => column.name === columnKey && index <= 2
            );

            if (!thisColumn) return {};

            return {
                position: 'absolute',
                left: '100px',
                boxShadow: `0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05)`,
            };
        };

        const isActiveDesc =
            columnKey === pendingArrivalsFilters?.sort?.sortBy &&
            pendingArrivalsFilters?.sort?.sortValue === sorters.desc;

        const isActiveAsc =
            columnKey === pendingArrivalsFilters?.sort?.sortBy &&
            pendingArrivalsFilters?.sort?.sortValue === sorters.asc;

        const isDateFilter = ['custom_promised_date', 'delivery_date', 'origin_sold_at'].includes(
            columnKey
        );

        return (
            <InColumnFiltersMenu
                style={{
                    ...menuPlacementHelper(),
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                {hideSorter ? null : (
                    <Fragment>
                        <MenuItem
                            onClick={() => handleSorter('desc')}
                            style={isActiveDesc && { color: '#037fb9' }}
                        >
                            <Space>
                                <ArrowDownIcon /> Ordenar descendente{' '}
                                {`${type === 'string' ? '(Z-A)' : ''}`}
                            </Space>
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleSorter('asc')}
                            style={isActiveAsc && { color: '#037fb9' }}
                        >
                            <Space>
                                <ArrowUpIcon /> Ordenar ascendente{' '}
                                {`${type === 'string' ? '(A-Z)' : ''}`}
                            </Space>
                        </MenuItem>
                    </Fragment>
                )}

                {!['createdAt', 'origin', 'delivery_date'].includes(columnKey) && (
                    <>
                        <Divider />
                        <Collapse
                            ghost
                            accordion={false}
                            expandIcon={({ isActive }) => (isActive ? <DownIcon /> : <RightIcon />)}
                        >
                            <CollapsePanelStyled header="Filtrar por condición">
                                <Form form={form} layout="vertical" onFinish={handleFormApply}>
                                    <Row gutter={4}>
                                        <Col span={12}>
                                            <Form.Item label="Condición:">
                                                <Select
                                                    options={[
                                                        ...conditions.filter((c) => {
                                                            if (isDateFilter) {
                                                                return (
                                                                    c.value !== 'includes' &&
                                                                    c.value !== 'not_include'
                                                                );
                                                            }
                                                            return true;
                                                        }),
                                                    ]}
                                                    onSelect={(value) =>
                                                        handleSelectCondition(value)
                                                    }
                                                    value={filter.condition}
                                                    disabled={!filter.isRemovable}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item label="Valor:">
                                                {isDateFilter ? (
                                                    <DatePicker
                                                        format="YYYY-MM-DD"
                                                        disabled={
                                                            !filter.condition || !filter.isRemovable
                                                        }
                                                        value={
                                                            filter.value ? moment(filter.value) : ''
                                                        }
                                                        onChange={(_, dateString) => {
                                                            handleSelectValue(dateString);
                                                        }}
                                                    />
                                                ) : !valueOptionByField[columnKey] ? (
                                                    <Input
                                                        disabled={
                                                            !filter.condition || !filter.isRemovable
                                                        }
                                                        value={filter.value}
                                                        onChange={(e) =>
                                                            handleSelectValue(e.target.value)
                                                        }
                                                        style={{
                                                            maxWidth: '120px',
                                                            // height: '2.5rem',
                                                        }}
                                                    />
                                                ) : (
                                                    <Select
                                                        showArrow
                                                        showSearch={false}
                                                        optionLabelProp="title"
                                                        maxTagCount="responsive"
                                                        value={filter.value}
                                                        disabled={
                                                            !filter.condition || !filter.isRemovable
                                                        }
                                                        onSelect={(value) => {
                                                            ['includes', 'not_include'].includes(
                                                                filter.condition
                                                            )
                                                                ? handleSelectMultipleValues(value)
                                                                : handleSelectValue(value);
                                                        }}
                                                        onDeselect={(value) =>
                                                            handleDeselectMultipleValues(value)
                                                        }
                                                        mode={
                                                            ['includes', 'not_include'].includes(
                                                                filter.condition
                                                            )
                                                                ? 'multiple'
                                                                : ''
                                                        }
                                                        tagRender={(props) => (
                                                            <ClosableTag
                                                                onClose={() =>
                                                                    handleDeselectMultipleValues(
                                                                        props.value
                                                                    )
                                                                }
                                                                style={{ fontWeight: 400 }}
                                                            >
                                                                {props.label}
                                                            </ClosableTag>
                                                        )}
                                                    >
                                                        {valueOptionByField[columnKey]?.map(
                                                            (compromise, index) => (
                                                                <Select.Option
                                                                    key={index}
                                                                    value={compromise.value}
                                                                    title={compromise.label}
                                                                >
                                                                    {[
                                                                        'includes',
                                                                        'not_include',
                                                                    ].includes(
                                                                        filter.condition
                                                                    ) && (
                                                                        <Checkbox
                                                                            value={compromise.value}
                                                                            checked={
                                                                                [
                                                                                    'includes',
                                                                                    'not_include',
                                                                                ].includes(
                                                                                    filter?.condition
                                                                                )
                                                                                    ? filter?.value?.includes(
                                                                                          compromise?.value
                                                                                      )
                                                                                    : filter?.value ===
                                                                                      compromise?.value
                                                                            }
                                                                        />
                                                                    )}
                                                                    <label
                                                                        style={{ paddingLeft: 8 }}
                                                                    >
                                                                        {compromise?.label}
                                                                    </label>
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </CollapsePanelStyled>
                        </Collapse>
                        <Divider />

                        <Space
                            style={{ marginTop: '10px', width: '100%', justifyContent: 'center' }}
                        >
                            <NormalButton
                                style={{ color: '#536D8F', border: '1px solid #2D3D76' }}
                                buttonText="Cancelar"
                                onClick={handleCancel}
                            />

                            <NormalButton
                                type="primary"
                                buttonText="Aplicar"
                                disabled={!filter.condition || !filter.value}
                                onClick={() => form.submit()}
                            />
                        </Space>
                    </>
                )}
            </InColumnFiltersMenu>
        );
    },

    filterIcon: () => {
        const { pendingArrivalsFilters } = useSelector((state) => state.utils);

        const isActiveDesc =
            columnKey === pendingArrivalsFilters?.sort?.sortBy &&
            pendingArrivalsFilters?.sort?.sortValue === sorters.desc;

        return columnKey === pendingArrivalsFilters?.sort?.sortBy &&
            pendingArrivalsFilters?.sort?.sortValue === sorters.asc ? (
            <SortAscIcon width={16} height={16} color="#037FB9" />
        ) : (
            <SortDescIcon width={16} height={16} color={isActiveDesc ? '#037FB9' : '#536D8F'} />
        );
    },
});

export default TableHeaderExtension;
