import { Skeleton } from 'antd';

const TCard = ({ children, loading, squaredBorderRadius, ...otherProps }) => {
    const { title, extra, paddingless, customStyle } = otherProps;

    return (
        <section
            style={customStyle ?? { border: '1px solid rgb(231, 234, 237)' }}
            className={`flex flex-col bg-white shadow-sm  overflow-hidden tcard 
            
            ${squaredBorderRadius ? '' : 'rounded-lg'}
            `}
        >
            {(title || extra) && (
                <div className={`flex flex-row items-center justify-between p-4`}>
                    <div className="flex flex-row items-center text-sm font-medium text-gray-800">
                        {title && title}
                    </div>
                    <div>{extra && extra}</div>
                </div>
            )}
            <div className={`tcard-body ${paddingless && !loading ? '' : 'p-4'}`}>
                {loading && <Skeleton />}
                {!loading && children}
            </div>
        </section>
    );
};

export default TCard;
