import qs from 'qs';
import { useGetPendingArrivalsReportsQuery } from 'redux/features/Reports';
import MetricCard from 'components/V2/Metrics/MetricCard';
import { ReactComponent as OrdersMetricsIcon } from 'assets/orders-metrics.svg';
import { ReactComponent as PendingMetricsIcon } from 'assets/pending-metrics.svg';
import { ReactComponent as AvgMetricsIcon } from 'assets/avg-metrics.svg';
import { MetricsContainer } from '../styles';

const OrderPreparationMetrics = ({ selectedDateRange, selectedLocation }) => {
    // Para obtener datos para las metricas
    const metricsObject = {
        ...selectedDateRange,
        // location_id: !['', 'all'].includes(shopSelected) ? shopSelected : undefined,
        origin: selectedLocation,
    };
    const {
        data = {},
        isLoading,
        isFetching,
    } = useGetPendingArrivalsReportsQuery(qs.stringify(metricsObject), {
        pollingInterval: 1800000,
    });

    const metrics = [
        {
            name: 'Pedidos recibidos',
            type: 'pending_orders',
            quantity: data?.total_received_fulfillments ?? 0,
            measurement: '',
            tooltipTitle:
                'Total de pedidos recibidos en la tienda, para la tienda seleccionada en el plazo especificado.',
            icon: <OrdersMetricsIcon />,
            color: '#7998A7',
        },
        {
            name: 'Promedio de recepción',
            type: 'fulfillment_avg',
            quantity: data?.average_pending_arrivals ?? 0,
            measurement: 'min',
            tooltipTitle:
                'Tiempo promedio de recepción en la tienda, desde que el pedido es preparado en la tienda/bodega de origen y se recibe en la tienda de entrega, para la tienda seleccionada en el plazo especificado.',
            icon: <PendingMetricsIcon />,
            color: '#B00303',
        },
        {
            name: 'Pedidos con retiro en tienda',
            type: 'created_orders',
            quantity: data?.total_created_fulfillments ?? 0,
            measurement: '',
            tooltipTitle:
                'Total de pedidos pendientes de recepción con retiro en tienda, para la tienda seleccionada en el plazo especificado.',
            icon: <AvgMetricsIcon />,
            color: '#B00303',
        },
    ];

    return (
        <MetricsContainer>
            {metrics.map((metric) => (
                <MetricCard
                    key={metric.name}
                    color={metric.color}
                    name={metric.name}
                    tooltipTitle={metric.tooltipTitle}
                    metricType={metric.type}
                    quantity={metric.quantity}
                    measurement={metric.measurement}
                    icon={metric.icon}
                    loading={isLoading || isFetching}
                />
            ))}
        </MetricsContainer>
    );
};

export default OrderPreparationMetrics;
