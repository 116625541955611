import { Form, Radio, Select } from 'antd';
import CheckboxStyled from 'components/Utils/CheckboxStyled';
import Flex from 'components/Utils/Flex';
import { Title } from 'pages/Settings/styles';
import { Fragment } from 'react';

const StockUnifiedForm = ({ form, locations = [] }) => {
    const hasUnifiedStock = Form.useWatch(['stock_unified_settings', 'enabled'], form);
    const stockUnifiedType = Form.useWatch(['stock_unified_settings', 'shipping_method'], form);
    const hasSpecificLocation = Form.useWatch(
        ['stock_unified_settings', 'enabled_specific_location'],
        form
    );

    return (
        <Fragment>
            <h3 className="mt-7 mb-3">
                <Title>Gestión de stock</Title>
            </h3>
            <Form.Item name={['stock_unified_settings', 'enabled']} valuePropName="checked">
                <CheckboxStyled>
                    Habilitar informe de actualización de stock (con unificación de stock)
                </CheckboxStyled>
            </Form.Item>
            {hasUnifiedStock && (
                <div style={{ padding: '1rem', background: '#f8f8f8' }}>
                    <Title style={{ marginBottom: 8 }}>Tipo de unificación</Title>
                    <Form.Item
                        className="ant-select-label-secondary"
                        name={['stock_unified_settings', 'shipping_method']}
                        required
                    >
                        <Radio.Group
                            onChange={() => {
                                form.setFieldsValue({ stock_unified_settings: { locations: [] } });
                            }}
                        >
                            <Flex direction="column">
                                <Radio value="all">Unificar stock de todas las tiendas</Radio>
                                <Radio value="shipping">
                                    Unificar stock de tiendas con Despacho
                                </Radio>
                                <Radio value="pickup">Unificar stock de tiendas con Retiro</Radio>
                                <Radio value="specific_locations">
                                    Seleccionar las tiendas para las cuales se informará el stock
                                    unificado
                                </Radio>
                            </Flex>
                        </Radio.Group>
                    </Form.Item>

                    {['specific_locations'].includes(stockUnifiedType) && (
                        <Form.Item
                            name={['stock_unified_settings', 'locations']}
                            label="Seleccionar las tiendas"
                            required
                        >
                            <Select mode="multiple" placeholder="Seleccionar tiendas">
                                {Array.from(locations ?? [])
                                    ?.filter((location) => location?.is_enabled)
                                    ?.map((location) => (
                                        <Select.Option key={location._id} value={location._id}>
                                            {location.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    )}

                    <Form.Item
                        name={['stock_unified_settings', 'enabled_specific_location']}
                        valuePropName="checked"
                    >
                        <CheckboxStyled>
                            Habilitar el informe de actualización de stock hacia Marketplace /
                            E-commerce considerando como destino la siguiente tienda/bodega
                        </CheckboxStyled>
                    </Form.Item>
                    {hasSpecificLocation && (
                        <Form.Item
                            name={['stock_unified_settings', 'specific_location']}
                            label="Seleccionar la tienda"
                            required
                            rules={[
                                {
                                    required: true,
                                    message: 'Este campo es requerido',
                                },
                            ]}
                        >
                            <Select placeholder="Seleccionar tienda">
                                {Array.from(locations ?? [])
                                    ?.filter((location) => location?.is_enabled)
                                    ?.map((location) => (
                                        <Select.Option key={location._id} value={location._id}>
                                            {location.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    )}
                </div>
            )}
        </Fragment>
    );
};

export default StockUnifiedForm;
