import { createSlice } from '@reduxjs/toolkit';

const initialStateSolveOrderData = {
    //GENERAL DATA
    is_shortcut_opened: false,
    is_can_continue: false,
    is_client_contacted: false,
    current_step: 0,
    global_solution_selected: '',
    alternative_solution_selected: '',

    //PRODUCTS
    line_items_static: [],
    line_items: [],
    line_items_selected: [],
    variants_ids_selected: [],
    line_items_formatted_to_fulfillment: [],
    success_line_items: [],

    /* UNITS */
    incidence_units_selected: 0,
    incidence_units_total: 0,
    incidence_units_total_static: 0,
    units_solved_per_location: {},

    //WAREHOUSE STEPS
    is_in_warehouse_validation_step: false,

    //NEED REFACTORING
    existing_fullfilment_with_current_store: {},
    data_to_update_fulfillment: {},
    products_to_fulfillment: [],
};

const solveOrderData = createSlice({
    name: 'solveOrderData',
    initialState: initialStateSolveOrderData,
    reducers: {
        changeSolveOrderData: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        changeSolveOrderField: (state, { payload }) => {
            return {
                ...state,
                [payload[0]]: payload[1],
            };
        },
        changeProductsToFulfillmentSO: (state, { payload }) => {
            return {
                ...state,
                products_to_fulfillment: {
                    ...state.products_to_fulfillment,
                    [payload[0]]: payload[1],
                },
            };
        },
        changeLineItemsIdsSelectedSO: (state) => {
            const lineItemsSelected = state.line_items.filter((li) =>
                state.variants_ids_selected.includes(li.variant_id._id)
            );

            return {
                ...state,
                line_items_selected: lineItemsSelected,
            };
        },
        changeIncidenceUnitsSelectedSO: (state) => {
            const lineItemsSelected = state.line_items.filter((li) =>
                state.variants_ids_selected.includes(li.variant_id._id)
            );
            const stockIncidence = lineItemsSelected.reduce((acc, lis) => {
                return acc + lis.quantity_out_of_stock;
            }, 0);

            return {
                ...state,
                incidence_units_selected: stockIncidence,
            };
        },
        changeIncidenceUnitsTotalSO: (state) => {
            const stockIncidence = state.line_items.reduce((acc, lis) => {
                return acc + lis.quantity_out_of_stock;
            }, 0);
            return {
                ...state,
                incidence_units_total: stockIncidence,
            };
        },
        changeIncidenceUnitsTotalStaticSO: (state) => {
            const stockIncidence = state.line_items_static.reduce((acc, lis) => {
                return acc + lis.quantity_out_of_stock;
            }, 0);
            return {
                ...state,
                incidence_units_total_static: stockIncidence,
            };
        },
        changeUnitsSolvedPerLocationSO: (state, { payload }) => {
            const lineItemsStaticForPayload = state.line_items_static.filter((li) =>
                payload[1].includes(li.variant_id._id)
            );
            const totalUnitsSolved = lineItemsStaticForPayload.reduce((acc, lis) => {
                return acc + lis.quantity_out_of_stock;
            }, 0);

            if (payload[0]) {
                return {
                    ...state,
                    units_solved_per_location: {
                        ...state.units_solved_per_location,
                        [payload[0]]: totalUnitsSolved,
                    },
                };
            }
        },
        reInitSolveOrderData: (state) => {
            return {
                ...state,
                ...initialStateSolveOrderData,
            };
        },
    },
});
export const {
    changeSolveOrderData,
    changeSolveOrderField,
    reInitSolveOrderData,
    changeProductsToFulfillmentSO,
    changeIncidenceUnitsSelectedSO,
    changeIncidenceUnitsTotalSO,
    changeLineItemsIdsSelectedSO,
    changeUnitsSolvedPerLocationSO,
    changeIncidenceUnitsTotalStaticSO,
} = solveOrderData.actions;
export default solveOrderData.reducer;
