import { Checkbox } from 'antd';
import styled from 'styled-components';

export default styled(Checkbox)`
    .ant-checkbox-input {
        width: 16px !important;
        height: 16px !important;
        
        &:disabled {
            opacity: .7;
        }
    }

    .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0 !important;
    }

    .ant-checkbox-wrapper-disabled {
        opacity: 0.6;
    }

    span {
        color: #142c4a;
        font-size: 14px;
        font-weight: 400;
    }

    .ant-checkbox-inner {
        border: 1px solid #2d3d76 !important;
        border-radius: 2px !important;

        &:after {
            transform: rotate(45deg) scale(0.96) translate(-55%, -50%);
        }
    }
`;
