import { ReactComponent as AddedIcon } from 'assets/add-ticket.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-icon3.svg';
import { ReactComponent as DisabledTrashIcon } from 'assets/trash-icon4.svg';

import { ReactComponent as AddIcon } from 'assets/add-icon-code2.svg';
import { Button } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';

export const BodyModalAddPackage = ({ e, setlineItemsToSend, initialAdded }) => {
    const [addedCode, setAddedCode] = useState(initialAdded);

    const handleAdd = () => {
        setAddedCode((prev) => !prev);
        setlineItemsToSend((prev) => ({
            ...prev,
            [e?.line_item?._id]: prev[e?.line_item?._id] ? prev[e?.line_item?._id] + 1 : 1,
        }));
    };

    const handleDelete = () => {
        setAddedCode((prev) => !prev);
        setlineItemsToSend((prev) => ({
            ...prev,
            [e?.line_item?._id]: prev[e?.line_item?._id] - 1,
        }));
    };

    useEffect(() => {
        if (initialAdded) {
            setlineItemsToSend((prev) => ({
                ...prev,
                [e?.line_item?._id]: prev[e?.line_item?._id] ? prev[e?.line_item?._id] + 1 : 1,
            }));
        }
    }, [initialAdded]);

    return (
        <div>
            <div style={{ border: '1px solid #E4E8EC', marginTop: '8px' }}>
                <div className="flex justify-between text-sm mt-2 mx-2">
                    <div>
                        <b style={{ color: '#2D3D76' }}>{e?.line_item?.name}</b>
                    </div>
                    {addedCode && (
                        <div className="flex gap-3">
                            <span
                                style={{
                                    color: '#7998A7',
                                    fontSize: '13px',
                                    marginTop: '5px',
                                }}
                            >
                                Añadido
                            </span>
                            <AddedIcon style={{ marginTop: '8px' }} />

                            <Button
                                icon={<TrashIcon />}
                                onClick={handleDelete}
                                size="small"
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    marginLeft: '2px',
                                    marginTop: '3px',
                                }}
                            />
                        </div>
                    )}

                    {!addedCode && (
                        <>
                            <div className="flex gap-3">
                                <span
                                    style={{
                                        color: '#7998A7',
                                        fontSize: '13px',
                                        marginTop: '5px',
                                    }}
                                >
                                    Añadir
                                </span>
                                <Button
                                    icon={<AddIcon />}
                                    size="small"
                                    onClick={handleAdd}
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        marginTop: '3px',
                                    }}
                                />
                                <div className="mr-2" style={{ marginTop: '5px' }}>
                                    <DisabledTrashIcon />
                                </div>
                            </div>
                        </>
                    )}
                </div>
                <span className="mx-2" style={{ color: '#7998A7' }}>
                    SKU: {e?.sku}
                </span>
                <br />
                <span className="mx-2">{e?.line_item?.unit_price}</span>
            </div>
        </div>
    );
};
