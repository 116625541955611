import { Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const AlertShareWarehouse = ({ setIsShareWarehouse }) => {
    return (
        <div
            className="px-2 py-10 flex flex-col justify-center items-center"
            style={{ border: '1px solid #BACBD9', borderRadius: '2px' }}
        >
            <p style={{ fontSize: '13px', color: '#1F6373' }}>
                ¿Los productos seleccionados comparten la misma incidencia?
            </p>
            <div className="flex justify-center">
                <Button onClick={() => setIsShareWarehouse('yes')}>
                    <div className="flex items-center">
                        <span className="mr-3">Si</span>
                        <CheckOutlined style={{ color: 'green' }} />
                    </div>
                </Button>
                <Button onClick={() => setIsShareWarehouse('no')} className="ml-9">
                    <div className="flex items-center">
                        <span className="mr-3">No</span>
                        <CloseOutlined style={{ color: 'red' }} />
                    </div>
                </Button>
            </div>
        </div>
    );
};

export default AlertShareWarehouse;
