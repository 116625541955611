import { Tag } from 'antd';
import styled from 'styled-components';

export const TagStyled = styled(Tag)`
    border: 1px solid rgb(45, 61, 118);
    background-color: rgb(245, 245, 245);
    color: rgb(83, 109, 143);
    border-radius: 5rem;
    display: flex;
    width: fit-content;
    align-items: center;
    :last-child {
        margin-right: 0px;
    }
`;

export const TagCircle = styled.span`
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: ${({ bgColor }) => bgColor};
`;
