import styled, { css } from 'styled-components';
import { Typography } from 'antd';

export const TabStyle = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;

    ${({ disable }) =>
        disable &&
        css`
            color: rgba(0, 0, 0, 0.25) !important;
            cursor: not-allowed !important;
            border: 1px solid #b599ad !important;
            background-color: #e7e7e7 !important;
        `}
`;

export const TypographyStyled = styled(Typography.Text)`
    & > .ant-input {
        padding: 0 11px;
        min-height: 0;
        background-color: transparent;
        min-width: 140px;
    }
`;
