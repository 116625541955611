import { useMemo } from 'react';
import { TextHover, TextUnderline } from '../styles';

const webhookActionDic = {
    'ORDER/CREATED': (log) => `de pedido/fulfillment ${log?.other?.order_name} creado`,
    'ORDER/DTE/TICKET/CREATED': (log) =>
        `pedido/fulfillment #${log?.other?.order_name} a facturado`,
    'ORDER/CANCELED': (log) =>
        `pedido/fulfillment ${log?.other?.order_name} ${
            log?.other?.partially_canceled ? 'parcialmente cancelado' : 'cancelado'
        }`,
    'FULFILLMENT/LABEL/CREATED': (log) =>
        `generación de etiquetas para pedido/fulfillment ${log?.other?.fulfillment_name}`,
    'FULFILLMENT/READY/PICKUP': (log) =>
        `cambio de estado de pedido/fulfillment ${log?.other?.fulfillment_name} a listo para retiro`,
    'FULFILLMENT/DELIVERED': (log) =>
        `cambio de estado de pedido/fulfillment ${log?.other?.fulfillment_name} a entregado`,
    'FULFILLMENT/PREPARED': (log) =>
        `de pedido/fulfillment #${log?.other?.fulfillment_name} preparado`,
    'FULFILLMENT/DISPATCHED': (log) =>
        `de pedido/fulfillment #${log?.other?.fulfillment_name} despachado`,
    'FULFILLMENT/PIN/RECEIVED': (log) =>
        `de pedido/fulfillment #${log?.other?.fulfillment_name} pin de entrega recibido`,
};

import { ReactComponent as RetryIcon } from 'assets/retry-icon.svg';

export const WebhookTimeLineDescription = ({
    onClickReport,
    onClickViewDetail,
    onClickRetry,
    isFailed,
    dataLog,
}) => {
    const webhookActionText = useMemo(() => {
        return webhookActionDic[dataLog?.other?.webhook_event]?.(dataLog);
    }, [dataLog]);

    return (
        <span>
            Se ha enviado mediante webhook{' '}
            <TextHover isFailed={isFailed}>
                <TextUnderline onClick={onClickReport} isFailed={isFailed}>
                    <u>Informe</u>
                </TextUnderline>{' '}
            </TextHover>
            {isFailed ? 'fallido' : 'exitoso'} de {webhookActionText} -{' '}
            <TextHover isFailed={isFailed}>
                <TextUnderline onClick={onClickViewDetail} isFailed={isFailed} color="#2D3D76">
                    <span>Ver respuesta</span>
                </TextUnderline>{' '}
            </TextHover>
            -{' '}
            <TextHover isFailed={isFailed}>
                <TextUnderline onClick={onClickRetry} color="#2D3D76">
                    <span>Reintentar informe</span>
                </TextUnderline>{' '}
                &nbsp;
                <RetryIcon style={{ display: 'inline' }} />
            </TextHover>
        </span>
    );
};
