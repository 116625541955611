export const getPackagesTagColor = (status, total) => {
    const processed = status.processed;
    const open = status.open;
    const re_processed = status.re_processed;

    if (open === total) {
        return '#B00303';
    }

    if (processed === total) {
        return '#089460';
    }

    if (re_processed === total) {
        return '#089460';
    }

    return '#B00303';
};
