import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getStripeCustomer = createAsyncThunk('stripe/customer', async (_, state) => {
    try {
        const res = await AndesClient.get(`/stripe/customer/cus_KDkIruEMhCQ4CN`);
        return res.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getStripeCustomerSlice = createSlice({
    name: 'getStripeCustomer',
    initialState: {
        loading: false,
        data: {},
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [getStripeCustomer.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getStripeCustomer.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [getStripeCustomer.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});

export default getStripeCustomerSlice.reducer;
