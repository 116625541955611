import { CalendarContainer } from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/es';
import { Button } from '@andescommercecloud/beflow-design-system';
import {
    ActionDatePicker,
    ContainerButton,
    ContainerOption,
    LabelCalendarCustom,
    Container,
} from './styles';
import { dateRangeOptions } from './utils';

export const ContainerReactDatePicker = ({
    className,
    children,
    valueCalendar,
    loading,
    possibleValueCalendar,
    handleChangeDate,
    handleConfirmCalendar,
    handleCancelCalendar,
}) => {
    return (
        <Container>
            <div style={{ display: 'flex' }}>
                <ContainerOption>
                    {dateRangeOptions?.map(({ label, value }) => (
                        <ActionDatePicker
                            onClick={() => handleChangeDate(value, true)}
                            $active={value === valueCalendar.type}
                            key={value}
                        >
                            {label}
                        </ActionDatePicker>
                    ))}
                </ContainerOption>
                <CalendarContainer className={className}>
                    <div style={{ position: 'relative' }}>{children}</div>
                </CalendarContainer>
            </div>
            <ContainerButton>
                <LabelCalendarCustom>
                    {possibleValueCalendar?.[0]
                        ? moment(possibleValueCalendar?.[0])?.format('DD/MM/YYYY')
                        : ''}{' '}
                    |{' '}
                    {possibleValueCalendar?.[1]
                        ? moment(possibleValueCalendar?.[1])?.format('DD/MM/YYYY')
                        : ''}
                </LabelCalendarCustom>
                <Button variation="secondary" onClick={handleCancelCalendar} disabled={loading}>
                    Cancelar
                </Button>
                <Button
                    onClick={handleConfirmCalendar}
                    disabled={loading}
                    title={loading ? 'Espere mientras se finaliza la solicitud...' : undefined}
                >
                    Confirmar
                </Button>
            </ContainerButton>
        </Container>
    );
};
