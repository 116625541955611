export const orderIncidencesTypesFromOrdersView = [
    {
        value: 'address_not_found',
        label: 'Dirección no encontrada',
        need_select_package: false,
    },
    // {
    //     value: 'misplaced_package',
    //     label: 'Paquete extraviado',
    //     need_select_package: true,
    // },
    // {
    //     value: 'accident_vehicle',
    //     label: 'Vehículo accidentado',
    //     need_select_package: false,
    // },
    // {
    //     value: 'damaged_package',
    //     label: 'Paquete sufrió daño',
    //     need_select_package: true,
    // },
    // {
    //     value: 'package_not_received',
    //     label: 'Paquete no recepcionado',
    //     need_select_package: true,
    // },
    // {
    //     value: 'package_not_pickup',
    //     label: 'Paquete no retirado',
    //     need_select_package: true,
    // },
    {
        value: 'insufficient_stock',
        label: 'Stock insuficiente',
        need_select_package: false,
    },
    // {
    //     value: 'other',
    //     label: 'Otro',
    //     need_select_package: false,
    // },
    // {
    //     value: 'courier_service_type',
    //     label: 'Courier / Tipo de servicio',
    //     need_select_package: false,
    // },
];
export const orderIncidencesTypesFromDeliveriesView = [
    // {
    //     value: 'package_not_found_on_delivery_to_logistics_operator',
    //     label: 'Bulto no encontrado en entrega a Operador logístico',
    //     need_select_package: true,
    // },
    // {
    //     value: 'return_received',
    //     label: 'Devolución recibida',
    //     need_select_package: false,
    // },
    {
        value: 'address_not_found',
        label: 'Dirección no encontrada',
        need_select_package: false,
    },
    {
        value: 'failed_delivery',
        label: 'Entrega fallida',
        need_select_package: false,
    },
    // {
    //     value: 'package_label_does_not_correspond_to_delivery_to_logistics_operator',
    //     label: 'Etiqueta de bulto no corresponde en entrega a operador logístico',
    //     need_select_package: true,
    // },
    {
        value: 'incident_with_order_delivery_by_logistics_operator',
        label: 'Incidencia con entrega de pedido por operador logístico',
        need_select_package: false,
    },
    // {
    //     value: 'package_not_found_in_store_Pickup',
    //     label: 'Paquete no encontrado en Retiro en tienda',
    //     need_select_package: true,
    // },
    // {
    //     value: 'store_pickup_denied',
    //     label: 'Retiro en tienda rechazado',
    //     need_select_package: false,
    // },
    {
        value: 'failed_pickup',
        label: 'Retiro fallido',
        need_select_package: false,
    },
    // {
    //     value: 'other',
    //     label: 'Otro',
    //     need_select_package: false,
    // },
];

export const fulfillmentIncidencesTypes = [
    {
        value: 'insufficient_stock',
        label: 'Stock insuficiente',
        need_select_package: false,
    },
    {
        value: 'product_in_bad_condition',
        label: 'Producto en mal estado',
        need_select_package: false,
    },
    // {
    //     value: 'other',
    //     label: 'Otro',
    //     need_select_package: false,
    // },
];
