import styled, { css } from 'styled-components';

export const SearchInputWrapper = styled.div`
    position: relative;
    height: 40px;
    width: 100%;
    max-width: ${(props) => props?.maxWidth};
    min-width: ${(props) => props?.minWidth};
    transform: rotate(180deg);

    ${(props) => props.extraStyles}

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 3px;
        border: 1px solid #7998a7;
        padding: 0.5rem;
        padding-right: 2.5rem;
        outline: unset;
        text-align: left;
        transform: rotate(180deg);
        font-size: 14px;
    }
    /* ${({ error }) =>
        error &&
        css`
            border-color: 1px solid red;
            background: white !important;
        `} */
`;

export const SearchIconWrapper = styled.div`
    position: absolute;
    z-index: 1;
    width: 44px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #f27127;

    border: 1px solid #e79b09;
    svg {
        transform: rotate(180deg);
        color: white;
        font-size: 26px;
    }
`;
