import Container from 'components/Grid/Container';
import { useGetOrderQuery } from 'redux/features/Orders';
import JsonView from 'components/JsonView';
import Loading from 'components/Loading';

const OrderJsonPage = ({ order_id, onlyLogs }) => {
    let { data, isLoading: loading } = useGetOrderQuery(order_id);

    const dataToDisplay = onlyLogs ? data?.logs : data;

    return (
        <Container extraTitle={!loading ? `Orden ${data?.name ?? '...'}` : ''}>
            {loading && <Loading loadingText="Cargando datos del pedido..." />}
            {!loading && data && <JsonView json={dataToDisplay} />}
        </Container>
    );
};

export default OrderJsonPage;
