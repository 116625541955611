import { Divider, Empty, Skeleton } from 'antd';
import Drawer from 'components/Drawer';
import Flex from 'components/Utils/Flex';
import history from 'helpers/history';
import { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from 'redux/features/Utils';
import styled, { css } from 'styled-components';
import Notifications from './Notifications';
import { useLocation } from 'react-router-dom';
import { useGetNotificationsQuery } from 'redux/features/Notifications';
import { FiBell } from 'react-icons/fi';

const DrawerHead = styled.div`
    display: flex;
    align-items: center;
    color: #037fb9;
    font-size: 1rem;
    letter-spacing: 1px;

    svg {
        width: 26px;
        height: 26px;
        margin-right: 10px;
    }
`;

const DrawerStyled = styled(Drawer)`
    .ant-drawer-body {
        display: flex;
        flex-direction: column;
    }

    .ant-drawer-header-title {
        flex-direction: row-reverse;

        .ant-drawer-close {
            svg {
                color: red;
            }
        }
    }
`;

const NotificationTag = styled.div`
    display: flex;
    align-items: center;
    margin-right: 10px;
    padding: 2px 10px;
    border-radius: 50px;
    user-select: none;

    &:last-child {
        margin-right: 0;
    }

    ${({ active }) => {
        return active
            ? css`
                  color: white;
                  background-color: ${({ theme }) =>
                      theme?.buttons?.colors?.secondary?.first ?? '#037FB9'};
              `
            : css`
                  color: #9ebcc0;
                  border: 1px solid #9ebcc0;
                  background: #f5f5f5;
              `;
    }}
`;

const NotificationIcon = styled.div`
    position: relative;
    cursor: pointer;

    svg {
        opacity: 0.6;
        ${({ haveUnreadNotifications }) =>
            haveUnreadNotifications &&
            `
            opacity: 0.8;
            animation-name:shake;
            animation-duration:0.6s;
            animation-iteration-count:infinite;
            animation-timing-function:linear;
            animation-delay:4s;
    `} @keyframes shake {
            0% {
                transform: rotate(0deg);
            }
            25% {
                transform: rotate(-10deg);
            }
            50% {
                transform: rotate(0deg);
            }
            75% {
                transform: rotate(10deg);
            }
            100% {
                transform: rotate(0deg);
            }
        }
    }
`;

const NotificationNumbers = styled.div`
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #e33512;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
    font-weight: bold;
    z-index: 1;
    user-select: none;
`;

const NotificationsDrawer = () => {
    const [notificationView, setNotificationView] = useState('new');
    const dispatch = useDispatch();
    const drawerName = 'notifications-drawer';
    const location = useLocation();

    const { data = [], isLoading, isFetching } = useGetNotificationsQuery(null);

    const handleToggleDrawer = () => {
        if (location.pathname === '/notifications') {
            return;
        }
        dispatch(toggleDrawer(drawerName));
    };

    const readNotifications = Array.from(data ?? []).filter((n) => n.viewed);
    const readNotificationsCount = readNotifications.length;

    const unreadNotifications = Array.from(data ?? []).filter((n) => !n.viewed);
    const unreadNotificationsCount = unreadNotifications.length;

    return (
        <Fragment>
            {/* Drawer body */}
            <DrawerStyled
                drawerProps={{
                    name: drawerName,
                    title: (
                        <DrawerHead>
                            <FiBell style={{ fill: '#5DA1D3' }} />
                            <span style={{ color: '#037FB9' }}>Notificaciones</span>
                        </DrawerHead>
                    ),
                    destroyOnClose: true,
                    dontShowFooter: true,
                    width: 480,
                }}
            >
                {data.length > 0 && (
                    <Fragment>
                        <Flex padding="16px" direction="row">
                            <NotificationTag
                                active={notificationView === 'new'}
                                role="button"
                                onClick={() => setNotificationView('new')}
                            >
                                <span>Nuevas ({unreadNotificationsCount})</span>
                            </NotificationTag>
                            <NotificationTag
                                active={notificationView === 'read'}
                                role="button"
                                onClick={() => setNotificationView('read')}
                            >
                                <span>Leídas ({readNotificationsCount})</span>
                            </NotificationTag>
                        </Flex>
                        <Divider style={{ margin: '0px' }} />
                        <Flex padding="16px" direction="row" justifyContent="space-between">
                            <span style={{ fontWeight: 'bold' }}>
                                {notificationView === 'new' ? 'Nuevas' : 'Leídas'}
                            </span>
                            <span
                                style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                                role="button"
                                onClick={() => {
                                    handleToggleDrawer();
                                    history.push('/notifications');
                                }}
                            >
                                Ver más
                            </span>
                        </Flex>
                        <Notifications
                            data={
                                notificationView === 'new' ? unreadNotifications : readNotifications
                            }
                            showDot
                            loading={isLoading}
                            isFetching={isFetching}
                        />
                    </Fragment>
                )}
                {data.length === 0 && !isLoading && (
                    <Empty
                        style={{ marginTop: '2rem' }}
                        description="No quedan notificaciones por mostrar"
                    />
                )}
                {isLoading && <Skeleton />}
            </DrawerStyled>
            {/* Boton drawer */}
            <NotificationIcon
                role="button"
                onClick={handleToggleDrawer}
                haveUnreadNotifications={
                    readNotificationsCount > 0 && location.pathname !== '/notifications'
                }
            >
                {unreadNotificationsCount > 0 && (
                    <NotificationNumbers>{unreadNotificationsCount}</NotificationNumbers>
                )}
                <FiBell style={{ fill: '#5DA1D3' }} />
            </NotificationIcon>
        </Fragment>
    );
};

export default NotificationsDrawer;
