import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateProfileFromStore } from './getAllProfiles';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const editProfile = createAsyncThunk('profile/edit', async (data, state) => {
    try {
        const res = await toast.promise(AndesClient.put(`/profiles/update`, data), {
            loading: 'Actualizando perfil...',
            success: 'Perfil actualizado con éxito.',
        });

        if (res.status === 200) {
            state.dispatch(updateProfileFromStore(data));
        }
        return res.data;
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const editProfileSlice = createSlice({
    name: 'editProfile',
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    reducers: {},
    extraReducers: {
        [editProfile.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [editProfile.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [editProfile.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default editProfileSlice.reducer;
