import SEO from 'components/SEO';
import PropTypes from 'prop-types';
import { Fragment } from 'react';

const Container = ({ children, extraTitle = '', backgroundColor }) => {
    return (
        <Fragment>
            <SEO extraTitle={extraTitle} />
            <main className="flex-1 relative overflow-y-auto focus:outline-none">
                <div style={{ backgroundColor }}>
                    <div className="max-w-screen mx-auto">{children}</div>
                </div>
            </main>
        </Fragment>
    );
};

Container.propTypes = {
    children: PropTypes.node,
};

export default Container;
