import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import history from 'helpers/history';
import AndesClient from 'services/AndesClient';

export const fetchBatchFulfillments = createAsyncThunk(
    'fulfillments/get/batch',
    async (_, state) => {
        try {
            const { utils } = state.getState();
            const { multipleDelivery } = utils;

            if (!multipleDelivery || multipleDelivery.length === 0) {
                history.push('/deliveries');
            }

            const response = await AndesClient.post('/fulfillments/batch/get', {
                fulfillment_ids: multipleDelivery,
            });

            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const inProgressFulfillmentSlice = createSlice({
    name: 'fulfillments/in_progress',
    initialState: {
        loading: false,
        success: false,
        data: [],
        error: null,
    },
    reducers: {},
    extraReducers: {
        [fetchBatchFulfillments.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [fetchBatchFulfillments.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: true,
                data: payload,
                error: null,
            };
        },
        [fetchBatchFulfillments.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default inProgressFulfillmentSlice.reducer;
