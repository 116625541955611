import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { getOrderById } from './getOrderById';
import toast from 'react-hot-toast';
import AndesClient from 'services/AndesClient';

export const cancelOrder = createAsyncThunk('orders/cancel', async (data, state) => {
    try {
        const promise = AndesClient.post(`/orders/cancelV2`, {
            order_id: data.order_id,
            purpose_annulment: data.purpose_annulment,
        });

        toast.promise(promise, {
            loading: <b>Cancelando pedido...</b>,
            success: <b>Pedido cancelado con éxito.</b>,
            error: (error) =>
                error.response.data.message ? (
                    error.response.data.message
                ) : (
                    <b>Ha ocurrido un error.</b>
                ),
        });

        const result = await promise;

        if (result.status === 200) {
            window.location.reload();
        }

        return await result.data;
    } catch (error) {
        // toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const cancelOrderSlice = createSlice({
    name: 'cancelOrder',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [cancelOrder.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [cancelOrder.fulfilled]: (state) => {
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };
        },
        [cancelOrder.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: payload,
            };
        },
    },
});

export default cancelOrderSlice.reducer;
