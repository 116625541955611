import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const fetchFulfilledOrders = createAsyncThunk('orders/fulfilledOrders', async (_, state) => {
    try {
        // const { filtersOrders } = state.getState();
        const response = await AndesClient.post(`/orders/findByFilters`, {
            fulfillment_status: ['in_progress'],
        });
        return response.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const fetchFulfilledOrdersSlice = createSlice({
    name: 'fetchFulfilledOrdersSlice',
    initialState: {
        loading: false,
        data: {
            docs: [],
        },
        succes: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [fetchFulfilledOrders.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [fetchFulfilledOrders.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                succes: true,
                error: null,
            };
        },
        [fetchFulfilledOrders.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                succes: false,
                error: payload,
            };
        },
    },
});

export default fetchFulfilledOrdersSlice.reducer;
