import { useContext, useEffect, useState } from 'react';
import { FeatureFlagContext } from './FeatureFlagContext';

export const useGetValueFeatureFlag = (keyPrimary, keySecondary) => {
    const { featureFlag } = useContext(FeatureFlagContext);
    const [value, setValue] = useState(null);

    useEffect(() => {
        const valueKeyPrimary = featureFlag?.[keyPrimary];
        if (keySecondary) {
            setValue(valueKeyPrimary?.enabled ? valueKeyPrimary?.[keySecondary] : null);
        } else setValue(valueKeyPrimary?.enabled ? valueKeyPrimary : null);
    }, [featureFlag]);

    return value;
};
