import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const createStripeSession = createAsyncThunk(
    'stripe/create/session',
    async (price_id, state) => {
        try {
            const res = await AndesClient.post(`/stripe/create/session`, {
                price_id,
            });
            window.location.href = res.data.url;
            return res.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const createStripeSessionSlice = createSlice({
    name: 'createStripeSession',
    initialState: {
        loading: false,
        data: {},
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [createStripeSession.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [createStripeSession.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [createStripeSession.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});

export default createStripeSessionSlice.reducer;
