import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import toast from 'react-hot-toast';

export const promiseEngineApi = createApi({
    reducerPath: 'promise_engine',
    baseQuery,
    tagTypes: ['promise_engine'],
    endpoints: (builder) => ({
        getPromiseEnginePreferenceByMerchant: builder.query({
            query: (params) => ({
                url: `/promise-engine/preferences/merchant/${params?.merchant_id}`,
                method: 'get',
            }),
            providesTags: ['promise_engine'],
        }),
        updatePromiseEnginePreferences: builder.mutation({
            method: 'put',
            query: (data) => {
                return {
                    url: `/promise-engine/preferences/${data._id}`,
                    method: 'put',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Guardando cambios...</b>,
                    success: <b>Cambios guardado con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['promise_engine'],
        }),
        createPromiseEnginePreferences: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/promise-engine/preferences`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Guardando cambios...</b>,
                    success: <b>Cambios guardado con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['promise_engine'],
        }),
    }),
});

export const {
    useCreatePromiseEnginePreferencesMutation,
    useGetPromiseEnginePreferenceByMerchantQuery,
    useUpdatePromiseEnginePreferencesMutation,
} = promiseEngineApi;
