import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import { getAllShops } from './getAllShops';
import toast from 'react-hot-toast';

export const editShops = createAsyncThunk('stores-warehouses/edit', async (data, state) => {
    try {
        const editShopsForItem = async (payload) => {
            const res = await toast.promise(AndesClient.put(`/locationsV2/update`, payload), {
                loading: 'Actualizando Tienda/Bodega...',
                success: 'Cambios realizados con éxito.',
                error: (error) => `Error: ${error.response.data.message}`,
            });
            if (res.status === 200) {
                window.location.reload();
                state.dispatch(getAllShops());
            }
            return res;
        };
        if (Array.isArray(data)) {
            for await (const item of data) {
                await editShopsForItem(item);
            }
        } else if (typeof data === 'object') {
            await editShopsForItem(data);
        }
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const editShopsSlice = createSlice({
    name: 'editShops',
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    reducers: {},
    extraReducers: {
        [editShops.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [editShops.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [editShops.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default editShopsSlice.reducer;
