import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const ArrivalsPendingApi = createApi({
    reducerPath: 'Arrivals',
    baseQuery,
    tagTypes: ['Arrivals'],
    endpoints: (builder) => ({
        changeStatusReceivedInStore: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/fulfillments/receivedInStore`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Cambiando estado...</b>,
                    success: <b>Estado cambiado</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            providesTags: ['Arrivals'],
        }),
        confirmfulfillmentReceived: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/fulfillments/pickuptostore`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Confirmando recepción...</b>,
                    success: <b>Fulfillment enviado a entregas</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            providesTags: ['Arrivals'],
        }),
    }),
});

export const { useChangeStatusReceivedInStoreMutation, useConfirmfulfillmentReceivedMutation } =
    ArrivalsPendingApi;
