export const preparationsFulfillmentStatusOptions = [
    {
        label: 'Pendiente',
        value: 'open',
    },
    {
        label: 'En proceso',
        value: 'in_progress',
    },
    {
        label: 'Pausado',
        value: 'paused',
    },
    {
        label: 'Cancelado',
        value: 'cancelled',
    },
    {
        label: 'Parcialmente preparado',
        value: 'partially_processed',
    },
    {
        label: 'Etiquetas pendientes',
        value: 'pending_pdf',
    },
    {
        label: 'Listo para enviar a entregas',
        value: 'ready_for_deliver',
    },
    // {
    //     label: 'SAC',
    //     value: 'sac',
    // },
];

export const preparationsDeliveryMethodOptions = [
    {
        label: 'Retiro',
        value: 'pickup',
    },
    { label: 'Retiro con consolidación', value: 'pickup_consolidation' },
    {
        label: 'Despacho',
        value: 'shipping',
    },
    { label: 'Despacho con consolidación', value: 'shipping_consolidation' },
    { label: 'Retiro en punto de tercero', value: 'pickup_point' },
];
