import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import { getAllCouriers } from './getAllCouriers';
import toast from 'react-hot-toast';

export const editCourier = createAsyncThunk('couriers/edit', async (data, state) => {
    try {
        const res = await toast.promise(AndesClient.put(`settings/couriers/${data?._id}`, data), {
            loading: 'Modificando courier...',
            success: 'Courier modificado con éxito.',
            error: 'No se pudo modificar el courier.',
        });
        if (res.status === 200) {
            state.dispatch(getAllCouriers());
        }
        return res.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const editCourierSlice = createSlice({
    name: 'editCourier',
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    reducers: {},
    extraReducers: {
        [editCourier.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [editCourier.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [editCourier.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default editCourierSlice.reducer;
