import styled from 'styled-components';

export const Text = styled.span`
    color: ${({ color }) => color ?? '#142C4A'};
    font-weight: ${({ fw }) => fw ?? 400};
    font-style: ${({ isItalic }) => isItalic && 'italic'};
    margin: ${({ m }) => m ?? '0px'};
    font-size: ${({ fs }) => fs ?? '14px'};
    display: inline-block;
`;

export const ImagePreview = styled.img`
    width: 250px;
    height: 167px;
    border: 1px solid #d0d0d0;
    object-fit: contain;
    margin-top: 10px;
`;
