import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFulfillmentsViewScanMode } from 'redux/features/Utils';
import useGetPreparationPreference from './useGetPreparationPreference';

const useFulfillmentsScanMode = () => {
    const dispatch = useDispatch();
    const [, , data] = useGetPreparationPreference();

    const scanMode = useSelector((state) => state.utils.fulfillmentsViewScanMode);

    const toggleScanMode = (bool) => {
        dispatch(toggleFulfillmentsViewScanMode(bool));
    };

    useEffect(() => {
        if (!scanMode && data?.preparations_preferences) {
            const value = data?.preparations_preferences?.auto_scan_mode ?? false;
            toggleScanMode(value);
        }

        return () => {
            toggleScanMode(false);
        };
    }, [data]);

    return [scanMode, toggleScanMode];
};

export default useFulfillmentsScanMode;
