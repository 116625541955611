import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getOrderDocuments = createAsyncThunk('orders/document', async (order_id, state) => {
    try {
        const response = await AndesClient.get(`/orders/document/${order_id}`);

        return response?.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const orderDocumentSlice = createSlice({
    name: 'orderDocument',
    initialState: {
        loading: false,
        data: {},
        error: null,
    },
    reducers: {},
    extraReducers: {
        [getOrderDocuments.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getOrderDocuments.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
            };
        },
        [getOrderDocuments.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default orderDocumentSlice.reducer;
