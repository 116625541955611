import { Tabs } from 'components/TabsV2';
import ProductTable from '../ProductTable';
import { useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import ChangeCourier from 'pages/Orders/components/Actions/ChangeCourier';
import { useSelector } from 'react-redux';

const FulfillmentsSection = ({
    data,
    setInfoSelected,
    loading,
    infoSelected,
    refetch,
    deliveryPromiseDateFormat,
}) => {
    const [showOriginalOrder, setShowOriginalOrder] = useState(false);
    const [itemsToShow, setItemsToShow] = useState([]);
    const { detailOrderForChangeCourier } = useSelector((state) => state.utils);
    const [buttonSelected, setButtonSelected] = useState(1);

    const logistics = Array.from(
        data?.logistics_info ?? [] /* ?.length ? data?.logistics_info : [data] ?? [] */
    );
    const fulfillments = Array.from(data?.fulfillments ?? []);
    // const original_order = Array.from(data?.original_order?.logistics_info ?? []);

    const originalLogisticItems = Array.from(data?.original_line_items_raw ?? []).reduce(
        (acc, el) => {
            if (el?.type === 'shipping_item') {
                return acc;
            }

            const logisticIndex = acc.findIndex((item) => item?.logistic_id === el?.logistic_id);

            if (logisticIndex === -1) {
                acc.push({
                    logistic_id: el?.logistic_id,
                    line_item_ids: [el],
                });
            }

            if (logisticIndex !== -1) {
                acc[logisticIndex].line_item_ids.push(el);
            }

            return acc;
        },
        []
    );

    const unassociatedLogistics = logistics.reduce((acc, logistic) => {
        const everyFulfillment = fulfillments?.every((fulfillment) => {
            const lineItemIds = logistic?.line_item_ids || [];

            return (
                fulfillment?.logistic_id !== logistic?._id &&
                lineItemIds?.every((lineItemId) => lineItemId?.fulfillment_id !== fulfillment?._id)
            );
        });

        const someItemWithoutFulfillment = logistic?.line_item_ids?.some(
            (lineItemId) => !lineItemId?.fulfillment_id
        );

        if (someItemWithoutFulfillment) {
            // remove items with fulfillment
            const lineItemIds = logistic?.line_item_ids || [];

            const lineItemIdsWithoutFulfillment = lineItemIds?.filter(
                (lineItemId) => !lineItemId?.fulfillment_id
            );

            let otherItemsFromLogisticHaveFulfillment = false;

            const itemHasFulfillment = logistic?.line_item_ids?.some(
                (lineItemId) => lineItemId?.fulfillment_id
                //TODO: validar si item tiene unidades
                // (lineItemId?.removed_quantity ?? 0) - (lineItemId?.refunded_quantity ?? 0) > 0
            );

            if (itemHasFulfillment) {
                otherItemsFromLogisticHaveFulfillment = true;
            }

            const lineItemIdsWithoutFulfillmentAreRemoved = lineItemIdsWithoutFulfillment?.every(
                (lineItemId) =>
                    (lineItemId?.quantity ?? 0) -
                        (lineItemId?.removed_quantity ?? 0) -
                        (lineItemId?.refunded_quantity ?? 0) <=
                    0
            );

            const newLogistic = {
                ...logistic,
                line_item_ids: lineItemIdsWithoutFulfillment,
                ...(otherItemsFromLogisticHaveFulfillment &&
                    lineItemIdsWithoutFulfillmentAreRemoved && {
                        only_show_table: true,
                    }),
            };

            acc.push(newLogistic);

            return acc;
        }

        if (everyFulfillment) {
            // console.log('EVERY_FULFILLMENT', logistic);
            acc.push(logistic);
        }

        return acc;
    }, []);

    // console.log('unassociatedLogistics', unassociatedLogistics);

    const orderWithoutLogistics = [
        {
            courier_id: {},
            courier_service: '',
            delivery_method: data?.delivery_method,
            line_items_ids: data?.line_items,
            location_id: {},
            promised_date: data?.promised_date,
            shipping_address: data?.shipping_address,
        },
    ];

    useEffect(() => {
        const unassociatedOrderWithoutLogistics = orderWithoutLogistics?.filter((logistic) => {
            return fulfillments?.every((fulfillment) => {
                const lineItemIds = logistic?.line_items_ids || [];
                return (
                    fulfillment?.logistic_id !== logistic?._id &&
                    lineItemIds?.every(
                        (lineItemId) => lineItemId?.fulfillment_id !== fulfillment?._id
                    )
                );
            });
        });

        if (unassociatedLogistics?.length > 0) {
            setItemsToShow(unassociatedLogistics);
        } else {
            setItemsToShow(unassociatedOrderWithoutLogistics);
        }

        const someFulfillmentWithDifferentDeliveryMethodThanLogistic = fulfillments?.some(
            (fulfillment) => {
                const logistic = logistics?.find(
                    (logistic) => String(logistic?._id) === fulfillment?.logistic_id
                );

                return logistic?.delivery_method !== fulfillment?.delivery_method;
            }
        );

        const orderChangedDeliveryMethod =
            logistics?.length === 1 && //
            data?.logistics_info && // con esto validamos que data sea la orden y que tenga logistics_info
            data?.delivery_method !== logistics?.[0]?.delivery_method;

        const line_items_states = ['cancelled', 'removed', 'restocked', 'replaced'];

        if (
            // Saber si se ha anulado algun item de la orden
            data?.line_items
                ?.map((item) => item?.removed_quantity)
                ?.some((quantity) => quantity !== 0) ||
            // Saber si se ha rembolsado algun item de la orden
            data?.line_items
                ?.map((item) => item?.refunded_quantity)
                ?.some((quantity) => quantity !== 0) ||
            // Saber si se ha cambiado el delivery method
            someFulfillmentWithDifferentDeliveryMethodThanLogistic ||
            (!fulfillments?.[0] && orderChangedDeliveryMethod) ||
            // Saber si se ha cambiado la tienda/bodega
            (data?.original_order &&
                data?.original_order?.location_id?._id !== data?.location_id?._id) ||
            // Saber si se ha cambiado la dirección de envío
            (data?.original_order &&
                data?.original_order?.shipping_address?.address1 !==
                    data?.shipping_address?.address1) ||
            // Saber si se a efectuado algun cambio que modifique la el estado del item
            data?.line_items?.some((item) => line_items_states.includes(item.status)) ||
            // Si la orden se encuentra en estado sac
            data?.fulfillment_status === 'sac' ||
            data?.custom?.order_updated
        ) {
            setShowOriginalOrder(true);
        }
    }, [data]);

    const hasConsolidationFulfillmentWithoutItems = logistics?.some(
        (logistic) =>
            logistic?.consolidation?.is_consolidation_fulfillment && !logistic?.line_item_ids?.[0]
    );

    return (
        <>
            <ChangeCourier
                {...(detailOrderForChangeCourier || {})}
                buttonType="link"
                orders={[data]}
                refetch={refetch}
                buttonProps={{
                    style: {
                        display: 'none',
                    },
                }}
            />
            {loading ? (
                <Skeleton active />
            ) : (
                <Tabs defaultActiveKey="1" $show $activeBorder $withoutContentPadding>
                    <Tabs.TabPane tab="Pedido actual" key="1">
                        {fulfillments.map((fulfillment, index) => {
                            let tableColor = index % 2 === 0 ? 'none' : '#F6F8FA';
                            const fulfillmentIndex = index + 1;
                            const isActive = fulfillmentIndex === buttonSelected;

                            let isConsolidationFulfillmentWithoutItems = false;
                            const hasMoreThanOneFulfillment = fulfillments?.length > 1;
                            if (
                                fulfillment?.consolidation?.is_consolidation_fulfillment &&
                                !fulfillment?.line_items?.[0]
                            ) {
                                isConsolidationFulfillmentWithoutItems = true;
                            }

                            if (hasConsolidationFulfillmentWithoutItems) {
                                if (isConsolidationFulfillmentWithoutItems) {
                                    tableColor = '#F6F8FA';
                                } else {
                                    tableColor = 'none';
                                }
                            }

                            const fulfillmentLineItems = fulfillment?.line_items.map((li) => {
                                return {
                                    ...li.line_item,
                                    ...li,
                                };
                            });

                            return (
                                <ProductTable
                                    deliveryPromiseDateFormat={deliveryPromiseDateFormat}
                                    hasMoreThanOneFulfillment={hasMoreThanOneFulfillment}
                                    tableColor={tableColor}
                                    is_fulfillment={true}
                                    line_items={fulfillmentLineItems}
                                    number={index + 1}
                                    key={index + 'fulfillment'}
                                    data={fulfillment}
                                    setInfoSelected={setInfoSelected}
                                    dataOrder={data}
                                    name="product_table_fulfillment"
                                    isActive={isActive}
                                    refetch={refetch}
                                    setButtonSelected={setButtonSelected}
                                />
                            );
                        })}

                        {itemsToShow?.map((logistic, index) => {
                            let tableColor = index % 2 === 0 ? 'none' : '#F6F8FA';
                            const logisticsIndex = index + 1 + fulfillments?.length;
                            const isActive = logisticsIndex === buttonSelected;
                            let isConsolidationFulfillmentWithoutItems = false;

                            if (
                                logisticsIndex?.consolidation?.is_consolidation_fulfillment &&
                                !logisticsIndex?.line_item_ids?.[0]
                            ) {
                                isConsolidationFulfillmentWithoutItems = true;
                            }

                            if (hasConsolidationFulfillmentWithoutItems) {
                                if (isConsolidationFulfillmentWithoutItems) {
                                    tableColor = '#F6F8FA';
                                } else {
                                    tableColor = 'none';
                                }
                            }

                            return (
                                <ProductTable
                                    tableColor={tableColor}
                                    is_logistic
                                    line_items={
                                        data?.logistics_info?.length > 0
                                            ? logistic?.line_item_ids
                                            : logistic?.line_items ??
                                              orderWithoutLogistics?.[0]?.line_items_ids ??
                                              []
                                    }
                                    number={logistics?.length + index + 1}
                                    key={index + 'logistic'}
                                    data={logistic}
                                    setInfoSelected={setInfoSelected}
                                    dataOrder={data}
                                    name="product_table_logistic"
                                    fulfillment_status={data?.fulfillment_status}
                                    isActive={isActive}
                                    refetch={refetch}
                                    setButtonSelected={setButtonSelected}
                                />
                            );
                        })}
                    </Tabs.TabPane>

                    {showOriginalOrder && (
                        <Tabs.TabPane tab="Pedido original" key="2">
                            {originalLogisticItems?.[0] ? (
                                <>
                                    {originalLogisticItems.map((logistic, index) => {
                                        {
                                            /* console.log('ORIGINAL ORDER logistic', logistic); */
                                        }

                                        return (
                                            <ProductTable
                                                is_logistic
                                                line_items={logistic?.line_item_ids}
                                                number={index + 1}
                                                key={index + 'original_order'}
                                                data={logistic}
                                                setInfoSelected={setInfoSelected}
                                                infoSelected={infoSelected}
                                                dataOrder={data}
                                                name="product_table_original_order"
                                                refetch={refetch}
                                            />
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    {itemsToShow.map((logistic, index) => {
                                        {
                                            /* console.log('ORIGINAL ORDER logistic', logistic); */
                                        }

                                        return (
                                            <ProductTable
                                                is_logistic
                                                line_items={logistic?.line_item_ids}
                                                number={index + 1}
                                                key={index + 'original_order'}
                                                data={logistic}
                                                setInfoSelected={setInfoSelected}
                                                infoSelected={infoSelected}
                                                dataOrder={data}
                                                name="product_table_original_order"
                                                refetch={refetch}
                                            />
                                        );
                                    })}
                                </>
                            )}
                        </Tabs.TabPane>
                    )}
                </Tabs>
            )}
        </>
    );
};

export default FulfillmentsSection;
