import { Avatar } from 'antd';
import styled from 'styled-components';
import { Button } from '@andescommercecloud/beflow-design-system';

export const TimelineContainer = styled.div`
    margin: 15px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    hr {
        width: 100%;
        z-index: 1003;
        // background-color: white !important;
        margin-top: 10px;
        position: absolute;
        // margin-bottom: 30px !important;
    }
`;

export const TimelineDate = styled.div`
    font-weight: bold;
    margin-bottom: 20px;
    z-index: 1000;
    background-color: #fff;
    color: #2d3d76;
    margin-left: 8px;
`;
export const CommentaryBox = styled.div`
    border-radius: 6px;
    background-color: #f5f5f5;
    padding: 6px 1rem;
    min-width: 600px;
`;
export const ActionButton = styled(Button)`
    opacity: 0;
    margin-left: 10px;
    border-radius: 3px;
    align-self: flex-start;
    &:hover,
    &:focus {
        color: #2d3d76;
        border: 1px solid #2d3d76;
    }
`;

export const ActionButtonTooltip = styled(Button)`
    background-color: #f27127;
    border: 1px solid #e79b09;
    color: #fff;
    // padding-left: 20px;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 95%;
        transform: translate(-5px, -50%);
        border-width: 6px;
        border-style: solid;
        border-color: transparent #f27127 transparent transparent;
        transition: border-color 0.3s;
    }

    &:hover {
        background-color: #e2550d;
        border-color: #c04400;
        &::before {
            border-color: transparent #2d3d76 transparent transparent;
        }
    }
`;

export const CustomTooltip = styled.div`
    position: relative;
    display: inline-block;
    padding: 10px;

    .tooltip-content {
        display: none;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translate(5px, -50%);
        background-color: none;
        border: none;
        box-shadow: none;
        padding: 5px;
        white-space: nowrap;
        font-size: 14px;
    }

    &:hover .tooltip-content {
        display: block;
    }

    // .tooltip-arrow {
    //     position: absolute;
    //     top: 50%;
    //     left: 100%;
    //     transform: translate(5px, -50%);
    //     border-width: 6px;
    //     border-style: solid;
    //     border-color: red;
    //     z-index: 0;
    // }
`;
export const CustomTooltipContent = styled.div``;
// export const ActionButton = styled(Button)`
//     opacity: 0;
//     border-radius: 3px;
//     position: fixed; /* or 'absolute' depending on your layout */
//     bottom: 100px; /* adjust as needed */
//     right: 30px; /* adjust as needed */
//     &:hover,
//     &:focus {
//         color: #2d3d76;
//         border: 1px solid #2d3d76;
//     }
// `;

export const LogItem = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    cursor: pointer;

    &:hover ${ActionButton} {
        opacity: 1;
    }
`;

export const Typography = styled.h4`
    color: #2d3d76;
    font-weight: 600;
`;
export const Text = styled.p`
    color: ${({ color }) => color || '#142C4A'};
    font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
    font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
    margin-top: ${({ mt }) => mt || 0};
    margin-bottom: ${({ mb }) => mb || '0.5rem'};
    font-size: 14px;
    display: inline;
`;
export const Textarea = styled.textarea`
    border: 1px solid #7998a7;
    border-radius: 3px;
    width: ${({ width }) => width || '100%'};
    padding: 8px;
    resize: none;
    color: #142c4a;
`;
export const AvatarChip = styled(Avatar)`
    background-color: #9ebcc0;
    color: #2d3d76;
    font-weight: 600;
    text-transform: uppercase;
`;
export const TimeColumn = styled.div`
    width: 80px;
    // margin-right: 10px;
    display: flex;
    // gap: 7px;
    color: #536d8f;

    span {
        flex-grow: 1;
        flex-basis: 0;
        // margin-right: 10px;
    }
`;

export const LogDescription = styled.div`
    flex-grow: 1;
    flex-basis: 0;
    font-weight: 400;
    color: ${(props) => props.color || '#142C4A'};
    margin-left: 10px;
`;

export const TimelineLine = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 74.5px;
    width: 1px;
    background-color: #ccc;
`;

export const DotTimeLine = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-top: 6px;
    z-index: 1000;
    margin-left: 10px;
    // flex-grow: 0;
    // flex-basis: 0;
    background-color: ${(props) => props.color || '#037FB9'};
`;

export const TitleLineTimeStatus = styled.div`
    font-size: 16px;
    z-index: 1000;
    background-color: #fff;
    width: 100%;
    color: #2d3d76;
    padding: 20px 0px 20px 0px;
    position: relative;
`;

export const LimitTimeFulfillment = styled.div`
    display: flex;
    gap: 10px;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
    padding-bottom: 25px;
    color: #1f2937;
    .time-line-status-tag {
        background-color: #f5f5f5;
        border: 1px solid #2d3d76;
        color: #536d8f;
    }
    .time-line-status-tag-dot {
        margin-right: 4px;
    }
`;

export const MessageTimeLineStatus = styled.div`
    width: 100%; /* O un tamaño fijo en píxeles */
    height: 100%; /* O un tamaño fijo en píxeles */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 80px 0px;
    font-size: 12px;
    color: #2d3d76;
    opacity: 0.5;
`;

export const TextUnderline = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;

export const TextHover = styled.span`
    color: ${({ isFailed, color }) => (isFailed ? '#E33512' : color ?? '#142C4A')};
    transition: 0.3s;
    font-weight: 500;

    :hover {
        color: #036896;
    }
`;
