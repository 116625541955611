import React, { Suspense } from 'react';
import { TimeLineStyled } from './styles';
import { Skeleton, Timeline as AntdTimeLine } from 'antd';
const TimeLineItem = React.lazy(() => import('./TimeLineItem'));

import { useMemo } from 'react';

const TimeLine = ({ loading, data = [], page = 'order', withoutBorders, order }) => {
    const memoredTimeLineItems = useMemo(() => {
        return data.map((item, index) => {
            return (
                <Suspense key={index} fallback={<Skeleton paragraph={{ rows: 1 }} />}>
                    <TimeLineItem
                        item={item}
                        index={index}
                        page={page}
                        orderTotalShipping={order.total_shipping}
                    />
                </Suspense>
            );
        });
    }, [data]);

    return (
        <TimeLineStyled loading={loading} withoutBorders={withoutBorders}>
            <AntdTimeLine>{memoredTimeLineItems}</AntdTimeLine>
        </TimeLineStyled>
    );
};

export default TimeLine;
