import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getOrderById } from '../Orders/getOrderById';
import { toggleDrawer, toggleModal } from '../Utils';
import { fulfillmentsApi } from './index';

export const createFulfillment = createAsyncThunk('fulfillments/create', async (data, state) => {
    try {
        const { order_id, ...body } = data;
        const states = state.getState();

        const promise = AndesClient.post(`/fulfillments/orders/${order_id}/fulfillments`, body);

        toast.promise(promise, {
            loading: <b>Creando Fulfillment...</b>,
            error: (err) => {
                return <b>{err?.response?.data?.message ?? 'Ocurrió un error.'}</b>;
            },
            success: <b>Fulfillment creado con éxito</b>,
        });

        const result = await promise;
        if (result.status === 200) {
            state.dispatch(getOrderById(order_id));
            state.dispatch(
                fulfillmentsApi.endpoints.getInProgressFulfillments.initiate(undefined, {
                    forceRefetch: true,
                })
            );

            // Cerramos el modal de preparacion global
            if (states.utils?.modal['order-global-actions-preparation-modal']) {
                state.dispatch(toggleModal('order-global-actions-preparation-modal'));
            }

            // Cerramos el drawer de acciones globales
            if (states.utils?.drawer['global-actions-order-drawer']) {
                state.dispatch(toggleDrawer('global-actions-order-drawer'));
            }

            // Cerramos el drawer de incidencias
            if (
                states.utils?.drawer['incidence-order-drawer'] === true &&
                !states.solveOrderData?.is_in_warehouse_validation_step
            ) {
                state.dispatch(toggleDrawer('incidence-order-drawer'));
            }
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        console.log({ error });
        return state.rejectWithValue(error.response.data.message);
    }
});

const createFulfillmentSlice = createSlice({
    name: 'createFulfillment',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createFulfillment.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createFulfillment.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createFulfillment.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createFulfillmentSlice.reducer;
