import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import usePreparationList from 'hooks/usePreparationList';
import PrintListButton from './components/ScannedCodes/PrintListButton';

const MultipleFlowArrows = ({ fulfillment_id }) => {
    const {
        preparationList,
        handleNextPreparation,
        handlePreviousPreparation,
        getFulfillmentNumber,
        hasPrev,
        hasNext,
    } = usePreparationList(fulfillment_id);

    const disable = (bool) => {
        return bool ? { opacity: '0.5', cursor: 'not-allowed', 'pointer-events': 'none' } : {};
    };

    return (
        preparationList.length > 0 && (
            <div style={{ display: 'flex' }}>
                <PrintListButton
                    buttonProps={{
                        style: {
                            flex: 1,
                            fill: 'white',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '1rem',
                        },
                        type: 'primary',
                    }}
                    multiple={true}
                    fulfillments={preparationList}
                />

                <ArrowLeftOutlined
                    onClick={handlePreviousPreparation}
                    style={{ fontSize: '1.5rem', ...disable(!hasPrev()) }}
                />
                <span style={{ margin: '0px 1rem' }}>
                    {getFulfillmentNumber()} de {preparationList.length}
                </span>
                <ArrowRightOutlined
                    onClick={handleNextPreparation}
                    style={{ fontSize: '1.5rem', ...disable(!hasNext()) }}
                />
            </div>
        )
    );
};

export default MultipleFlowArrows;
