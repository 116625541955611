import { Fragment } from 'react';
import {
    BackButton,
    BreadCrumbsContainer,
    BreadCrumbsLink,
    ExtraSection,
    PageHeaderContainer,
    PageSubTitle,
    PageTitleContainer,
    PageTitleSection,
} from './styles';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
export const PageHeader = ({ style, showBack, title, subtitle, extraSection, breadCrumbs }) => {
    const history = useHistory();

    return (
        <PageHeaderContainer style={style}>
            {breadCrumbs && (
                <BreadCrumbsContainer>
                    {breadCrumbs.map((breadCrumb, idx) => (
                        <Fragment key={`bread-crumb-${idx}`}>
                            <BreadCrumbsLink to={breadCrumb.path}>
                                <span className="mr-1">{breadCrumb.name}</span>
                                <span className="mr-1" color="#7998A7">
                                    /
                                </span>
                            </BreadCrumbsLink>
                        </Fragment>
                    ))}
                </BreadCrumbsContainer>
            )}
            <PageTitleSection>
                <PageTitleContainer>
                    {showBack && (
                        <BackButton onClick={() => history.goBack()}>
                            <ChevronLeftIcon />
                        </BackButton>
                    )}
                    <span className="text-3xl font-medium leading-7 text-gray-900 sm:text-2xl">
                        {title}
                    </span>
                    <PageSubTitle className="text-lg ml-3 font-medium">{subtitle}</PageSubTitle>
                </PageTitleContainer>
                {extraSection && <ExtraSection>{extraSection}</ExtraSection>}
            </PageTitleSection>
        </PageHeaderContainer>
    );
};
