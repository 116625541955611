import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const findProducts = createAsyncThunk('stock/findProducts', async (data, state) => {
    try {
        const res = await AndesClient.post(`stock/findProducts`, data);

        return res.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const findProductsSlice = createSlice({
    name: 'findProducts',
    initialState: {
        loading: false,
        data: {},
        error: null,
        success: false,
    },
    reducers: {
        reInitFindProducts: (state) => {
            return {
                ...state,
                loading: false,
                data: {},
                error: null,
                success: false,
            };
        },
    },
    extraReducers: {
        [findProducts.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [findProducts.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [findProducts.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});
export const { reInitFindProducts } = findProductsSlice.actions;
export default findProductsSlice.reducer;
