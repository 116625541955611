import { Modal, Tooltip } from 'antd';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import OrderDocumentButton from 'components/OrderDocumentButton';
import Dropdown from 'components/V2/Dropdown';
import Menu from 'components/V2/Menu';
import MenuItem from 'components/V2/Menu/MenuItem';
import history from 'helpers/history';
import { validationFlowCompletedInCancelation } from 'helpers/validationFlows';
import useAllSalesChannel from 'hooks/useAllSalesChannel';
import useGetPickupPreference from 'hooks/useGetPickupPreference';
import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';
import ChangeOrderStatus from 'pages/Order/components/GlobalActionsOrderDrawer/OptionsControl/ChangeOrderStatus';
import ChangeSlaDate from 'pages/Order/components/GlobalActionsOrderDrawer/OptionsControl/ChangeSLADate';
import ChangeSacOrderStatus from 'pages/Order/components/GlobalActionsOrderDrawer/OptionsControl/ChangeSacOrderStatus';
import DownloadOrderCSV from 'pages/Order/components/GlobalActionsOrderDrawer/OptionsControl/DownloadOrderCSV';
import RemoveShippingPrice from 'pages/Order/components/GlobalActionsOrderDrawer/OptionsControl/RemoveShippingPrice';
import { verifyDisabledButton } from 'pages/Orders/components/Actions/validations';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { HiMinus, HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { createFulfillment } from 'redux/features/Fulfillments/createFulfillment';
import { useCancelOrderV2Mutation, useDeleteOrderMutation } from 'redux/features/Orders';
import { setDetailOrderForChangeCourier, toggleModal } from 'redux/features/Utils';
import { ModalCancelOrder } from './ModalCancelOrder';
import { TitleModalCancelOrder } from './styles';

const orderDeliveredState = ['delivered'];

const Actions = ({ orderData, refetch }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [, setDeliveryMethodFulfillments] = useState(false);
    const [hasDteValidation, setHasDteValidation] = useState(true);
    const dispatch = useDispatch();
    const [permissions, isAdmin] = useGetCurrentPermissions();
    const [deleteOrder, deleteOrderState] = useDeleteOrderMutation();
    const [, salesChannels] = useAllSalesChannel();
    const { loading, data, error } = useSelector((state) => state.orderDocuments);
    const [openModalCancel, setOpenModalCancel] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [cancelOrderV2] = useCancelOrderV2Mutation();
    const [cancelOrderButtonStatus, setCancelOrderButtonStatus] = useState({
        salesChannelAllowCancel: false,
        enableCancelByProfile: false,
        cancelEnabledByOrderStatus: false,
        hasDTEDocument: false,
    });
    const [, dataPickupsPreferences] = useGetPickupPreference();

    const permission = isAdmin || permissions.includes('sac');

    const orderHasFailedPickupIncidence = Array.from(orderData?.manual_incidences ?? [])?.some(
        (incidence) => incidence?.incidence_type === 'failed_pickup' && incidence?.status === 'open'
    );

    const orderLineItemsManualIncidencesLength =
        orderData?.line_items_manual_incidences?.length ?? 0;
    const orderFulfillmentsLineItemsManualIncidencesLength = orderData?.fulfillments?.reduce(
        (acc, el) => {
            acc += el?.line_items_manual_incidences?.length ?? 0;
            return acc;
        },
        0
    );

    const buttonEnabled = permission && orderData?.total_shipping > 0;

    const hasDataBankForReturnMoney = !!orderData?.sales_channel?.has_bank_data_for_return_money;

    useEffect(() => {
        if (orderData && salesChannels) {
            const dteDocument = orderData?.logs?.filter((log) =>
                ['DTE_TICKET_CREATED', 'DTE_INVOICE_CREATED'].includes(log?.action)
            )?.[0];

            const saleChannelOrder = salesChannels?.find(
                (sc) => sc._id === orderData?.sales_channel?._id
            );

            const hasProfilePermission =
                isAdmin || permissions.includes('sac') || permissions.includes('supervisor');

            const canCancelByOrderStatus =
                !['closes', 'cancelled'].includes(orderData?.order_status) &&
                !['cancelled'].includes(orderData?.fulfillment_status);

            const saleChannelAllowCancelStatus = [
                ...Array.from(saleChannelOrder?.allow_cancel_in_status ?? []),
            ];

            if (saleChannelOrder?.allow_cancel_in_status?.includes('fulfilled')) {
                saleChannelAllowCancelStatus.push('partially_fulfilled');
            }

            setCancelOrderButtonStatus({
                salesChannelAllowCancel: saleChannelAllowCancelStatus?.includes(
                    orderData?.fulfillment_status
                ),
                enableCancelByProfile: hasProfilePermission,
                cancelEnabledByOrderStatus: canCancelByOrderStatus,
                hasDTEDocument: !!dteDocument,
            });
        }
    }, [orderData, salesChannels, isAdmin, permissions]);

    useEffect(() => {
        // Eliminar el pedido solo está habilitado si la validación de los items falla
        const haveDte = Array.from(orderData?.documents ?? []).some(
            (doc) => doc?.type === 'DTE_TICKET' || doc?.type === 'DTE_INVOICE'
        );
        if (!haveDte) setHasDteValidation(false);
        else setHasDteValidation(true);
    }, [orderData]);

    useEffect(() => {
        // si el delivery method es shipping se habilita el boton
        orderData?.delivery_method === 'shipping' && setDeliveryMethodFulfillments(true);

        // si el delivery method de la orden es pickup pero existe al menos un ful con delivery method shipping se habilita el botón
        orderData?.delivery_method === 'pickup' &&
            orderData?.fulfillments?.map((dm) => dm?.delivery_method).includes('shipping') &&
            setDeliveryMethodFulfillments(true);
        // si el delivery method de la orden es pickup pero existe al menos una logística con delivery method shipping se habilita el botón

        orderData?.delivery_method === 'pickup' &&
            orderData?.logistics_info?.map((dm) => dm?.delivery_method).includes('shipping') &&
            setDeliveryMethodFulfillments(true);
    }, [orderData]);

    const showPreparationForPreparatorAndSupervisors = () =>
        isAdmin || permissions.includes('preparer') || permissions.includes('supervisor');

    const showChangeStore = () => isAdmin || permissions.includes('sac');
    const showChangeSacState = () => isAdmin || permissions.includes('sac');
    const showChangeSlaDate = () => isAdmin || permissions.includes('sac');
    const showChangeDeleteOrder = () => isAdmin || permissions.includes('sac');

    const handleClickDeleteOrder = () => {
        deleteOrder(orderData._id).then(() => {
            history.push('/orders');
        });
    };

    const handleMenuClick = () => {
        setIsDropdownOpen(false);
    };

    const disabledButton = useMemo(() => {
        if (orderData.fulfillments?.length) {
            return orderData.fulfillments.find((fulfillment) =>
                verifyDisabledButton({
                    order_ids: [],
                    orders: [orderData],
                    fulfillment_id: fulfillment._id,
                    order_id: orderData?._id || orderData?.id,
                    logistic_id: undefined,
                })
            );
        } else if (orderData.logistics_info?.length) {
            return orderData.logistics_info.find((logistic) =>
                verifyDisabledButton({
                    order_ids: [],
                    orders: [orderData],
                    fulfillment_id: undefined,
                    order_id: orderData?._id || orderData?.id,
                    logistic_id: logistic._id,
                })
            );
        } else {
            return verifyDisabledButton({
                order_ids: [],
                orders: [orderData],
                fulfillment_id: undefined,
                order_id: orderData?._id || orderData?.id,
                logistic_id: undefined,
            });
        }
    }, [orderData]);

    const handleClickSendToPreparation = () => {
        const hasDteDocument = orderData?.logs?.some((log) =>
            ['DTE_TICKET_CREATED', 'DTE_INVOICE_CREATED'].includes(log?.action)
        );
        if (orderData?.sales_channel?.order_without_dte_cannot_prepared && !hasDteDocument) {
            toast.error(
                'El pedido/fulfillment no puede ser preparado debido a facturación electrónica pendiente'
            );
            return;
        }
        const obj = {
            order_id: orderData._id,
            assigned_operators: [],
        };
        dispatch(createFulfillment(obj));
        setTimeout(() => {
            refetch(); // revisar este refetch
        }, 3000);
    };

    const menu = useMemo(() => {
        return (
            <Menu onClick={handleMenuClick}>
                {/* Enviar a preparación */}
                <MenuItem
                    style={{ paddingLeft: '24px', paddingTop: '10px' }}
                    onClick={handleClickSendToPreparation}
                    disabled={
                        orderData?.logistics_info?.length > 1 || // Si la orden tiene mas de 1 logística, deben enviarse a preparación por separado
                        orderData?.fulfillment_status === 'sac' ||
                        orderData?.fulfillments?.length > 0 ||
                        orderData?.fulfillment_status === 'in_progress' ||
                        !showPreparationForPreparatorAndSupervisors() ||
                        orderData?.order_status === 'closed' ||
                        orderData?.order_status === 'cancelled' ||
                        orderData?.order_status === 'archived' ||
                        orderData?.fulfillment_status === 'cancelled' ||
                        !validationFlowCompletedInCancelation(orderData?.flows)
                    }
                >
                    <Tooltip
                        title={
                            !validationFlowCompletedInCancelation(orderData?.flows)
                                ? 'Acción deshabilitada por movimientos de integración pendiente'
                                : ''
                        }
                    >
                        Enviar a preparación
                    </Tooltip>
                </MenuItem>

                {/* imprimir boleta */}
                <MenuItem>
                    <OrderDocumentButton
                        loading={loading}
                        data={data}
                        error={error}
                        buttonProps={{
                            style: {
                                border: 'none',
                                background: 'none',
                                fontSize: '12px;',
                            },
                        }}
                        id={orderData?._id}
                        name={orderData?.name}
                    />
                </MenuItem>

                {/* Anular costo de envío */}
                <MenuItem
                    modalName="removed-shipping-price-action"
                    style={{ paddingLeft: '24px', paddingTop: '10px' }}
                    onClick={() => dispatch(toggleModal('removed-shipping-price-action'))}
                    // block
                    disabled={
                        ['cancelled'].includes(orderData?.fulfillment_status) ||
                        orderData?.delivery_method === 'pickup' ||
                        !buttonEnabled ||
                        !validationFlowCompletedInCancelation(orderData?.flows)
                    }
                >
                    <Tooltip
                        title={
                            !validationFlowCompletedInCancelation(orderData?.flows)
                                ? 'Acción deshabilitada por movimientos de integración pendiente'
                                : ''
                        }
                    >
                        {orderDeliveredState.includes(data.status) ? 'Devolver' : 'Anular'} costo de
                        envío
                    </Tooltip>
                </MenuItem>

                <hr />

                {/* <p>Cambio de sucursal (Bodega/Tienda)</p> */}
                <MenuItem
                    style={{ paddingLeft: '24px', paddingTop: '10px' }}
                    onClick={() => history.push(`/orders/${orderData._id}/change-store`)}
                    disabled={
                        !orderData?.manual_incidences ||
                        (orderData?.manual_incidences.length === 0 &&
                            orderLineItemsManualIncidencesLength === 0 &&
                            orderFulfillmentsLineItemsManualIncidencesLength === 0) ||
                        (permissions?.includes('preparer') && !isAdmin) ||
                        !showChangeStore() ||
                        ['closed', 'cancelled'].includes(orderData?.order_status) ||
                        ['cancelled'].includes(orderData?.fulfillment_status) ||
                        orderData?.logistics_info?.some(
                            (lg) => Object.keys(lg?.consolidation ?? {})?.length
                        ) ||
                        orderData?.logistics_info?.length >= 2 ||
                        orderData?.fulfillments?.some((ful) =>
                            [
                                'ready_for_deliver',
                                'success',
                                'delivered',
                                'delivered_to_courier',
                            ].includes(ful?.status)
                        )
                    }
                >
                    Cambio de sucursal (Bodega/Tienda)
                </MenuItem>

                {/* Exportar pedido */}
                <MenuItem style={{ paddingLeft: '24px', paddingTop: '10px' }}>
                    <DownloadOrderCSV orderData={orderData} isAdmin={isAdmin} />
                </MenuItem>

                {permission && (
                    <MenuItem
                        style={{ paddingLeft: '24px', paddingTop: '10px' }}
                        disabled={disabledButton}
                        onClick={() => {
                            dispatch(toggleModal('orders-change-couriers'));
                            dispatch(
                                setDetailOrderForChangeCourier({
                                    fulfillment_id: undefined,
                                    logistic_id: undefined,
                                    order_id: orderData?._id || orderData?.id,
                                })
                            );
                        }}
                    >
                        Cambiar Courier/Tipo de servicio
                    </MenuItem>
                )}

                {/* <p>Cambiar fecha de entrega</p> */}
                <MenuItem>
                    <ChangeSlaDate
                        refetch={refetch}
                        order={orderData}
                        buttonProps={{
                            // buttonText: 'Cambiar fecha de entrega',
                            buttonText: 'Cambiar fecha de entrega',
                            ghost: true,
                            style: {
                                border: 'none',
                                background: 'none',
                                fontSize: '12px;',
                            },
                            disabled: !showChangeSlaDate(),
                        }}
                    />
                </MenuItem>

                {/* <p>Cambiar estado del pedido</p> */}
                <ChangeOrderStatus
                    refetch={refetch}
                    order={orderData}
                    modalName={`order-change-status-modal-${orderData?._id}`}
                />
                <MenuItem
                    style={{ paddingLeft: '24px', paddingTop: '10px' }}
                    disabled={
                        orderData?.order_status === 'closed' ||
                        orderData?.order_status === 'cancelled' ||
                        orderData?.fulfillment_status === 'cancelled' ||
                        orderData.logistics_info?.length > 1 ||
                        orderData.fulfillments?.length > 1
                    }
                    onClick={() =>
                        dispatch(toggleModal(`order-change-status-modal-${orderData?._id}`))
                    }
                >
                    Cambiar estado del pedido
                </MenuItem>

                {/* <p>Cambiar estado SAC</p> */}
                <MenuItem>
                    <ChangeSacOrderStatus
                        refetch={refetch}
                        order={orderData}
                        buttonProps={{
                            buttonText: 'Cambiar estado SAC',
                            disabled:
                                !showChangeSacState() ||
                                orderData?.order_status === 'closed' ||
                                orderData?.order_status === 'cancelled' ||
                                orderData?.fulfillment_status === 'cancelled' ||
                                orderData?.fulfillment_status !== 'sac',
                            ghost: true,
                            style: {
                                border: 'none',
                                background: 'none',
                                fontSize: '12px;',
                            },
                        }}
                    />
                </MenuItem>

                <hr />
                {/* <p>Cancelar pedido</p> */}
                <MenuItem
                    onClick={() => setOpenModalCancel(true)}
                    style={{ paddingLeft: '24px', paddingTop: '10px' }}
                    disabled={
                        !cancelOrderButtonStatus?.salesChannelAllowCancel ||
                        !cancelOrderButtonStatus?.enableCancelByProfile ||
                        !cancelOrderButtonStatus?.cancelEnabledByOrderStatus ||
                        !cancelOrderButtonStatus?.hasDTEDocument ||
                        !validationFlowCompletedInCancelation(orderData?.flows)
                    }
                >
                    <Tooltip
                        title={
                            !validationFlowCompletedInCancelation(orderData?.flows)
                                ? 'Acción deshabilitada por movimientos de integración pendiente'
                                : ''
                        }
                    >
                        Cancelar pedido
                    </Tooltip>
                </MenuItem>

                <MenuItem
                    onClick={() => setOpenModalDelete(true)}
                    disabled={
                        orderData?.fulfillments?.length > 0 ||
                        !showChangeDeleteOrder() ||
                        deleteOrderState.isLoading ||
                        (orderData?.custom?.can_be_deleted && !orderData?.custom?.can_be_deleted) ||
                        orderData?.fulfillment_status === 'cancelled' ||
                        hasDteValidation
                    }
                    style={{ paddingLeft: '24px', paddingTop: '10px' }}
                >
                    Eliminar Pedido
                </MenuItem>
            </Menu>
        );
    }, [orderData, isAdmin, data, cancelOrderButtonStatus]);

    const titleModalDeleteOrder = <TitleModalCancelOrder>Eliminar pedido</TitleModalCancelOrder>;

    const handleCancelOrder = (values) => {
        const body = {
            order_id: orderData._id,
            purpose_annulment: values.purpose_annulment,
            ...(values?.transfer_location_id && {
                transfer_location_id: values.transfer_location_id,
            }),
        };

        cancelOrderV2(body);

        setOpenModalCancel(false);
    };
    return (
        <>
            <Dropdown
                placement="bottomRight"
                overlay={menu}
                onVisibleChange={(visible) => setIsDropdownOpen(visible)}
            >
                <PrimaryButton>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '10px' }}>Acciones del pedido</span>
                        {isDropdownOpen ? <HiMinus /> : <HiPlus />}
                    </div>
                </PrimaryButton>
            </Dropdown>

            <ModalCancelOrder
                isOpen={openModalCancel}
                orderData={orderData}
                onOk={handleCancelOrder}
                onCancel={() => setOpenModalCancel(false)}
                hasDataBankForReturnMoney={hasDataBankForReturnMoney}
                cancelationRequireTransferLocation={
                    orderData?.delivery_method === 'pickup' &&
                    orderHasFailedPickupIncidence &&
                    dataPickupsPreferences?.delivery_options?.pickup
                        ?.when_cancelation_is_through_oms_interface_require_destination_store_for_transfer_prism_sap_oms
                }
            />

            <Modal
                title={titleModalDeleteOrder}
                open={openModalDelete}
                onOk={handleClickDeleteOrder}
                onCancel={() => setOpenModalDelete(false)}
            >
                <span style={{ color: '#142C4A' }}>
                    Si eliminas el pedido desaparecerá del listado de pedidos y de la base de datos.
                    <b> ¿Deseas confirmar esta acción?</b>
                </span>
            </Modal>

            <RemoveShippingPrice
                order_id={orderData?._id || orderData?.id}
                total_shipping={orderData?.total_shipping}
                isRefund={orderDeliveredState.includes(data?.status)}
                refetch={refetch}
                modalName="removed-shipping-price-action"
            />
        </>
    );
};
export default Actions;
