import { useGetBatchDeliveryByIdQuery } from 'redux/features/Deliveries';
import { Table } from 'antd/lib';
import styled from 'styled-components';
import Flex from 'components/Utils/Flex';
import { useMemo } from 'react';

import PackageDetailsDrawer from 'pages/DeliveriesV2/components/PackageDetailsDrawer';
import TableEmpty from 'components/Table/Empty';
import Badge from 'components/V2/Badge';

const StyledRowFulfillments = styled.div`
    background-color: white;
    margin: 4px;
    padding: 2rem;
    width: 100%;
`;

const RowFulfillmentHeader = styled.div`
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    color: #2d3d76;
    gap: 2rem;
`;

const PackagesTable = styled(Table)`
    td,
    .ant-table-cell {
        background: white !important;
    }
    .ant-table-thead {
        th {
            border-bottom: 1px solid #e4e8ec;
            border-top: 0px;
        }
    }
`;

const FulfillmentPackages = ({
    packages = [],
    loading,
    deliveryPreferences = {},
    delivery_method = '',
    manifest = {},
}) => {
    const tableData = useMemo(() => Array.from(packages ?? []), [packages]);
    console.log({ delivery_method });
    const tableColumns = useMemo(
        () => [
            {
                title: 'Código Bulto',
                dataIndex: 'code',
                align: 'left',
                width: '250px',
                render: (value) => {
                    return value;
                },
            },
            {
                title: 'Descripción',
                dataIndex: ['type', 'name'],
                render: (val) => val ?? 'N/A',
                align: 'left',
                width: '250px',
            },
            {
                title: 'Unidades solicitadas',
                dataIndex: 'line_items',
                width: '200px',
                render: (value) => {
                    const allQuantities = Array.from(value ?? []).reduce((acc, ele) => {
                        acc += ele.quantity;
                        return acc;
                    }, 0);
                    return allQuantities;
                },
                align: 'left',
            },
            ...(deliveryPreferences?.delivery_options[delivery_method]?.enable_rescanning_packages
                ? [
                      {
                          title: 'Escaneado',
                          dataIndex: 'status',
                          align: 'left',
                          render: (value) => {
                              let color = '#B00303';
                              if (manifest?.status === 'delivered') value = 're_processed';

                              if (value === 're_processed') {
                                  color = '#089460';
                              }

                              return <Badge bgColor={color} label={value} namespace="manifests" />;
                          },
                      },
                  ]
                : []),
        ],
        [delivery_method, packages, deliveryPreferences, manifest]
    );

    return (
        <PackagesTable
            rowKey={'_id'}
            dataSource={tableData}
            columns={tableColumns}
            loading={loading}
            pagination={false}
        />
    );
};

const RowFulfillments = ({ manifest_id, deliveryPreferences }) => {
    const manifest = useGetBatchDeliveryByIdQuery(manifest_id);

    return (
        <StyledRowFulfillments>
            {manifest.isLoading ? (
                <TableEmpty loading={manifest.isLoading} loadingText="Cargando pedidos..." />
            ) : (
                Array.from(manifest?.data?.fulfillments ?? []).map((fulfillment) => (
                    <Flex
                        direction="column"
                        key={fulfillment?._id}
                        style={{ width: '100%', maxWidth: '1000px', marginBottom: '2.6rem' }}
                        rowGap="1rem"
                    >
                        <RowFulfillmentHeader>
                            <Flex direction="row" columnGap="3rem">
                                <span style={{ fontSize: '14px' }}>
                                    <b style={{ fontWeight: '500 !important' }}>
                                        Fulfillment: {fulfillment?.name}
                                    </b>
                                </span>
                                <span style={{ fontSize: '14px' }}>
                                    <b style={{ fontWeight: '500 !important' }}>ID Ecommerce:</b>{' '}
                                    {fulfillment?.remote_order_id}
                                </span>
                            </Flex>

                            <PackageDetailsDrawer fulfillment={fulfillment} />
                        </RowFulfillmentHeader>
                        <FulfillmentPackages
                            packages={fulfillment?.packages}
                            loading={manifest?.isLoading}
                            deliveryPreferences={deliveryPreferences}
                            delivery_method={manifest?.data?.raw_data?.type}
                            manifest={manifest?.data}
                        />
                    </Flex>
                ))
            )}
        </StyledRowFulfillments>
    );
};

export default RowFulfillments;
