import { createApi } from '@reduxjs/toolkit/query/react';
import { clean } from 'rut.js';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const updateOrderCustomerApi = createApi({
    reducerPath: 'updateOrderCustomerApi',
    baseQuery,
    endpoints: (builder) => ({
        updateOrderCustomer: builder.mutation({
            query: (data) => {
                const { order_id, ...otherData } = data;

                if (data?.otherData?.customer?.document) {
                    otherData.customer.document = clean(otherData?.customer?.document)
                        .trim()
                        .toLowerCase();
                }

                return {
                    url: `/ordersv2/update-customer/${order_id}`,
                    method: 'post',
                    body: {
                        customer: otherData.customer,
                    },
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Modificando...</b>,
                    success: <b>Modificado correctamente.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
    }),
});

export const { useUpdateOrderCustomerMutation } = updateOrderCustomerApi;
