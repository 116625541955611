import { Modal } from 'antd';
import { TitleModalCancelFulfillment } from './styles';
import LocationPicker from 'components/V2/LocationPicker';
import { useState } from 'react';

export const ModalCancelFulfillment = ({
    isOpen,
    onClose,
    onOK,
    cancelationRequireTransferLocation,
}) => {
    const [locationSelected, setLocationSelected] = useState(null);

    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            title={<TitleModalCancelFulfillment>Cancelar Fulfillment</TitleModalCancelFulfillment>}
            destroyOnClose={true}
            onOk={() =>
                onOK({
                    ...(cancelationRequireTransferLocation && {
                        transfer_location_id: locationSelected,
                    }),
                })
            }
            okText="Confirmar"
            okButtonProps={{
                disabled:
                    cancelationRequireTransferLocation &&
                    (!locationSelected || typeof locationSelected !== 'string'),
            }}
        >
            <div
                style={{
                    marginBottom: '20px',
                }}
            >
                <p
                    style={{
                        marginBottom: '10px',
                    }}
                >
                    Seleccione Tienda/bodega para destino de transferencia (Prism/SAP)
                </p>

                <LocationPicker
                    style={{ marginTop: '20px' }}
                    isGenericLocationPicker={true}
                    isSingleSelection={true}
                    handleChangeLocations={setLocationSelected}
                />
            </div>
            Si Cancelas el fulfillment este quedará inactivo. <b> ¿Deseas confirmar esta acción?</b>
        </Modal>
    );
};
