import { useState, useEffect } from 'react';
import { Divider, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { useChangeFulfillmentsCouriersMutation } from 'redux/features/Fulfillments';
import { toggleModal } from 'redux/features/Utils';
import ModalWithButton from 'components/ModalWithButton';
import Flex from 'components/Utils/Flex';
import FormStyled from 'components/Utils/Form';
import IF from 'components/Utils/IF';
import CouriersSelector from 'pages/Metafields/components/CouriersSelector';

const ChangeFulfillmentsCourier = ({ selectedRows }) => {
    const [showResult, setShowResult] = useState(false);
    const [sucessChanges, setSucessChanges] = useState([]);
    const [errorChanges, setErrorChanges] = useState([]);
    const [courierSelected, setCourierSelected] = useState('');
    const [serviceSelected, setServiceSelected] = useState('');

    const modalName = 'fulfillments-change-courier';
    const dispatch = useDispatch();

    const [updateFulfillmentsCourier, updateFulfillmentsCourierState] =
        useChangeFulfillmentsCouriersMutation();
    const { isLoading } = updateFulfillmentsCourierState;
    const [form] = Form.useForm();

    const unmountComponent = () => {
        setShowResult(false);
        setSucessChanges([]);
        setErrorChanges([]);
        updateFulfillmentsCourierState.reset();
        form.resetFields();
    };

    const closeModal = () => {
        dispatch(toggleModal(modalName));
        unmountComponent();
    };

    const handleChangeCourier = async () => {
        await updateFulfillmentsCourier({
            fulfillments: selectedRows,
            courier_id: courierSelected,
            shipping_service: serviceSelected,
        });
    };

    useEffect(() => {
        if (updateFulfillmentsCourierState.isSuccess) {
            setShowResult(true);
            setErrorChanges(Array.from(updateFulfillmentsCourierState?.data?.errors ?? []));
            setSucessChanges(Array.from(updateFulfillmentsCourierState?.data?.success ?? []));
        }
    }, [updateFulfillmentsCourierState.isSuccess]);

    useEffect(() => {
        return () => {
            unmountComponent();
        };
    }, []);

    return (
        <ModalWithButton
            buttonType="menuitem"
            onOk={() => {
                if (showResult) {
                    closeModal();
                } else {
                    form.submit();
                }
            }}
            modalProps={{
                name: modalName,
                title: `Cambio de courier (${selectedRows.length})`,
                destroyOnClose: true,
                cancelButtonProps: { style: { display: showResult ? 'none' : 'unset' } },
                okText: showResult ? 'Cerrar modal' : 'Aceptar',
            }}
            buttonProps={{
                disabled: isLoading,
                buttonText: `Cambiar courier (${selectedRows.length})`,
            }}
        >
            <IF condition={!showResult} preventRender>
                <FormStyled
                    form={form}
                    name="change-fulfillments-courier"
                    onFinish={handleChangeCourier}
                    layout="vertical"
                >
                    <CouriersSelector
                        courierSelected={courierSelected}
                        setSelectedTargetId={setCourierSelected}
                        form={form}
                        setServiceSelected={setServiceSelected}
                    />
                </FormStyled>
            </IF>

            <IF condition={isLoading}>
                <b>Cargando resultado...</b>
            </IF>

            <IF condition={showResult}>
                <div
                    style={{
                        width: '100%',
                        maxHeight: '320px',
                        overflowY: 'auto',
                    }}
                >
                    <Flex direction="column">
                        <Flex direction="row" justifyContent="space-between">
                            <b style={{ margin: '0px', display: 'block' }}>
                                Resultados cambios de courier
                            </b>
                            <p
                                role="button"
                                onClick={() => closeModal()}
                                style={{ margin: '0px', display: 'block' }}
                            >
                                <b>
                                    <u>Realizar otra acción</u>
                                </b>
                            </p>
                        </Flex>
                        <Divider />
                        {sucessChanges.length > 0 && (
                            <>
                                <h2 style={{ color: 'green' }}>
                                    Se han modificado {sucessChanges.length}{' '}
                                    {sucessChanges.length > 1 ? 'preparaciones' : 'preparación'}
                                </h2>

                                <ul>
                                    {sucessChanges.map((f) => (
                                        <li key={f._id}>
                                            <b>{f.name}</b> se ha modificado
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                        {errorChanges.length > 0 && (
                            <>
                                <h2 style={{ color: 'red' }}>
                                    Ha fallado la modificación de {errorChanges.length}{' '}
                                    {errorChanges.length > 1 ? 'preparaciones' : 'preparación'}
                                </h2>

                                <ul>
                                    {errorChanges.map((f) => (
                                        <li key={f._id}>
                                            <b>{f.name}</b> no se ha podido modificar
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </Flex>
                </div>
            </IF>
        </ModalWithButton>
    );
};

export default ChangeFulfillmentsCourier;
