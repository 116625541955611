import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCouriers } from 'redux/features/Couriers/getAllCouriers';

const useAllCouriers = () => {
    const dispatch = useDispatch();
    const { loading, data, success, error } = useSelector((state) => state.getAllCouriers);

    useEffect(() => {
        if (!data[0] && !success) {
            dispatch(getAllCouriers());
        }
    }, []);

    return [loading, data, error];
};

export default useAllCouriers;
