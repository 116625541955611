const getLabel = (keyName) => {
    const fieldDic = {
        first_name: {
            label: 'Nombre',
            placeholder: 'Ingresar nombre',
        },
        last_name: {
            label: 'Apellido',
            placeholder: 'Ingresar apellido',
        },
        address1: {
            label: 'Dirección 1',
            placeholder: 'Ingresar dirección 1',
        },
        address2: {
            label: 'Dirección 2',
            placeholder: 'Ingresar dirección 2',
        },
        city: {
            label: 'Ciudad',
            placeholder: 'Ingresar ciudad',
        },
        province: {
            label: 'Región',
            placeholder: 'Ingresar región',
        },
        country: {
            label: 'País',
            placeholder: 'Ingresar país',
        },
        zip: {
            label: 'Zip',
            placeholder: 'Ingresar zip',
        },
        email: {
            label: 'Email',
            placeholder: 'Ingresar email',
        },
        document: {
            label: 'Documento',
            placeholder: 'Ingresar documento',
        },
        business_name: {
            label: 'Razón social',
            placeholder: 'Ingresar razón social',
        },
        business_document: {
            label: 'RUT de la empresa',
            placeholder: 'Ej: 99.234.567-8',
        },
        business_activity: {
            label: 'Giro',
            placeholder: 'Ingresar giro',
        },
        phone: {
            label: 'Teléfono',
            placeholder: 'Ingresar teléfono',
        },
    };

    return (
        fieldDic[keyName] ?? {
            label: keyName,
            placeholder: keyName,
        }
    );
};

export default getLabel;
