import { Button } from '@andescommercecloud/beflow-design-system';
import EditButton from 'components/Buttons/EditButton';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';

const ToggleButton = ({ modalName, extraClickAction, buttonType, ...otherProps }) => {
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(toggleModal(modalName));
    };

    const buttonProps = {
        ...otherProps,
        onClick: () => {
            {
                extraClickAction && extraClickAction();
            }
            handleToggle();
        },
    };

    if (buttonType === 'edit') return <EditButton {...buttonProps} />;

    return <Button {...buttonProps} />;
};

export default ToggleButton;
