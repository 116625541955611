import styled from "styled-components";

export const PaginationWrapper = styled.div`
    border: 1px solid #DCE5EC;
    display: flex;
    align-items: center;
    height: 38px;
    width: max-content;
    border-radius: 3px;
`

export const PaginationButton = styled.button`
    width: 40px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .2s;
    color: #036896;
    :disabled {
        color: #E4E8EC;
    }
    outline: none;
`

export const PaginationContent = styled.div`
    display: flex;
    align-items: center;
    border-left: 1px solid #DCE5EC;
    border-right: 1px solid #DCE5EC;
    height: 100%;
    padding: 0 8px;
`
