import { Form, Input } from 'antd';
import CheckboxStyled from 'components/Utils/CheckboxStyled';
import { Fragment } from 'react';

const AliasCourier = ({ form }) => {
    const enabledAliasCourierName = Form.useWatch(['alias_courier_name', 'is_enabled'], form);

    return (
        <Fragment>
            <Form.Item
                name={['alias_courier_name', 'is_enabled']}
                style={{ marginTop: 20 }}
                valuePropName="checked"
            >
                <CheckboxStyled>
                    Activar personalización de nombre de pedido/fulfillment para la creación del
                    envío
                </CheckboxStyled>
            </Form.Item>

            {enabledAliasCourierName && (
                <Form.Item
                    style={{
                        width: '100%',
                        backgroundColor: '#F5F5F5',
                        padding: '10px',
                        border: '1px solid #cacaca',
                        borderRadius: '5px',
                    }}
                    name={['alias_courier_name', 'alias']}
                    label="Alias"
                    rules={[
                        {
                            required: true,
                            message: 'Este campo es requerido',
                        },
                        {
                            max: 3,
                            message: 'Máximo 3 caracteres',
                        },
                    ]}
                >
                    <Input maxLength={3} placeholder="abc" style={{ maxWidth: 300 }} />
                </Form.Item>
            )}
        </Fragment>
    );
};

export default AliasCourier;
