import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import qs from 'qs';

// Obtenemos todas las ultimas 50 fulfillments
export const fetchPendingArrivalsfulfillments = createAsyncThunk(
    'fulfillments/pendingArrivals',
    async (queryobj, state) => {
        try {
            const params = qs.stringify({
                limit: 50,
                preparationcd_transfer_pickupstore: [
                    'created',
                    'ready_for_deliver_to_pickup_location',
                    'delivered_to_pickup_location',
                    'cancelled',
                    'partially_received_in_pickup_location',
                ],
                ...queryobj,
            });
            const response = await AndesClient.get(`/fulfillments?${params}`);
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const pendingArrivalsFulfillmentSlice = createSlice({
    name: 'fulfillments/pendingArrivals',
    initialState: {
        loading: false,
        success: false,
        data: {
            pagination: {},
            docs: [],
        },
        error: null,
    },
    reducers: {},
    extraReducers: {
        [fetchPendingArrivalsfulfillments.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [fetchPendingArrivalsfulfillments.fulfilled]: (state, { payload }) => {
            const { docs, ...otherData } = payload;
            return {
                ...state,
                loading: false,
                success: true,
                data: {
                    pagination: otherData,
                    docs,
                },
                error: null,
            };
        },
        [fetchPendingArrivalsfulfillments.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default pendingArrivalsFulfillmentSlice.reducer;
