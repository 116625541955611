import MoneyFormat from 'helpers/MoneyFormat';
import { useState } from 'react';
import { useEffect } from 'react';
import { BsFillExclamationDiamondFill } from 'react-icons/bs';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import styled from 'styled-components';
import { Tooltip } from 'antd';

const IncidenceProductWrapper = styled.div`
    padding: 1rem;
`;
const TitleProductIncidence = styled.div`
    font-size: 14px;
    color: #1f2937;
    font-weight: 600;
`;
const BodyIncidence = styled.div`
    height: 100%;
    overflow: auto;
    margin-top: 1rem;
    background-color: #edf1f6;
    padding: 0.5rem;
    border-radius: 5px;
    align-items: start;
`;

const NameProduct = styled.div`
    font-size: 12px;
    color: #142c4a;
`;
const PriceProduct = styled.div`
    font-size: 12px;
    font-weight: 500;
    color: #2d3d76;
    display: flex;
    align-items: flex-start;
`;
const ProductDetails = styled.div`
    display: grid;
    grid-template-columns: 1fr 0.1fr 0.2fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    // padding-bottom: 0.3rem;
    padding: 0.3rem 0rem;
`;

const ProblemProducts = ({ lineItems }) => {
    const [quantityOflineItemsWithIncidence, setQuantityOfLineItemsWithIncidence] = useState(0);

    useEffect(() => {
        setQuantityOfLineItemsWithIncidence(
            lineItems.reduce((acc, lineItem) => {
                if (lineItem?.manual_incidences[0]) {
                    return acc + 1;
                } else {
                    return acc;
                }
            }, 0)
        );
    }, [lineItems]);

    return (
        <IncidenceProductWrapper>
            <TitleProductIncidence>{`Productos con incidencias (${quantityOflineItemsWithIncidence}/${lineItems.length})`}</TitleProductIncidence>
            <BodyIncidence>
                {lineItems[0] &&
                    lineItems.map((li) => {
                        return (
                            <ProductDetails
                                key={li?._id}
                                // className="flex items-center justify-between py-2"
                                // style={{ borderBottom: '1px solid #BACBD9' }}
                            >
                                {/* NOMBRE E INCIDENCIA */}
                                <div className="flex flex-col text-xs">
                                    <NameProduct>{li?.name}</NameProduct>
                                </div>
                                {/* ICONO */}
                                <div className="flex items-center">
                                    {li?.manual_incidences[0] ? (
                                        <BsFillExclamationDiamondFill
                                            style={{ color: '#B00303', fontSize: '14px' }}
                                        />
                                    ) : (
                                        <Tooltip title="Sin incidencias">
                                            <AiOutlineCheckCircle
                                                style={{ color: '#2D882C', fontSize: '14px' }}
                                            />
                                        </Tooltip>
                                    )}
                                </div>
                                {/* PRECIO */}
                                <PriceProduct>{MoneyFormat(li?.total_price)}</PriceProduct>
                            </ProductDetails>
                        );
                    })}
            </BodyIncidence>
        </IncidenceProductWrapper>
    );
};

export default ProblemProducts;
