import { createSlice } from '@reduxjs/toolkit';
import { deliveriesFilters as deliveryFilters } from 'helpers/constants/filters/deliveries';

const deliveriesFilters = createSlice({
    name: 'deliveriesFilters',
    initialState: {
        filters: [
            {
                andOr: '',
                field: 'status',
                condition: 'not_include',
                value: ['delivered', 'delivered_to_courier'],
                compromiseValues: deliveryFilters.values.status
            },
        ],
        valueOptionByField: {
            status: [...deliveryFilters.values.status],
            delivery_method: [...deliveryFilters.values.delivery_method],
        },
    },
    reducers: {
        addFilter: (state, { payload }) => {
            state.filters = [...state.filters, payload];
        },
        removeFilter: (state, { payload }) => {
            state.filters = state.filters?.filter((el) => el?.id !== payload);
            if (state.filters.length > 0) {
                state.filters[0].andOr = '';
            }
        },
        updateFilter: (state, { payload }) => {
            const indexToUpdate = state.filters.findIndex((filter) => filter.id === payload.id);
            state.filters[indexToUpdate] = payload;
        },
        overrideFilters: (state, { payload }) => {
            state.filters = payload;
        },
        setValueOptionByField: (state, { payload }) => {
            state.valueOptionByField = {
                ...state.valueOptionByField,
                ...payload,
            };
        },
        clearFilters: (state) => {
            state.filters = [];
        },
    },
});

export const {
    addFilter,
    removeFilter,
    updateFilter,
    overrideFilters,
    setValueOptionByField,
    clearFilters,
} = deliveriesFilters.actions;

export default deliveriesFilters.reducer;
