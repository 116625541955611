export const defaultColumnsView = [
    {
        name: 'name',
        label: 'ID Fulfillment',
        status: true,
        position: 1,
    },
    {
        name: 'remote_order_id',
        label: 'ID Ecommerce',
        status: true,
        position: 2,
    },
    {
        name: 'module',
        label: 'Flujo',
        status: true,
        position: 2,
    },
    {
        name: 'createdAt',
        label: 'Fecha de creación',
        status: true,
        position: 3,
    },
    {
        name: 'province',
        label: 'Región de entrega',
        status: true,
        position: 4,
    },
    {
        name: 'promised_date',
        label: 'Plazo límite',
        status: true,
        position: 5,
    },
    {
        name: 'custom_promised_date',
        label: 'Promesa de entrega',
        status: true,
        position: 6,
    },
    {
        name: 'promised_date_range',
        label: 'Horario de entrega',
        status: true,
        position: 7,
    },
    {
        name: 'line_items',
        label: 'Unidades',
        status: true,
        position: 8,
    },
    {
        name: 'origin',
        label: 'Tienda',
        status: true,
        position: 9,
    },
    {
        name: 'pickup_store_status',
        label: 'Estado de recepción',
        status: true,
        position: 10,
    },
    {
        name: 'delivery_method',
        label: 'Tipo de entrega',
        status: true,
        position: 11,
    },
    {
        name: 'customer_name',
        label: 'Cliente',
        status: true,
        position: 12,
    },
];

export const INITIAL_PAGE_SIZE = 25;
