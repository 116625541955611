import { Form, Select } from 'antd';
import Flex from 'components/Utils/Flex';
import useAllCouriers from 'hooks/useAllCouriers';
import { useMemo } from 'react';

const CouriersSelector = ({ form, setSelectedTargetId, courierFieldName = 'courier_id' }) => {
    const [loading, data] = useAllCouriers();

    const courierSelectedWatch = Form.useWatch(courierFieldName, form);

    const courierServices = useMemo(() => {
        if (!courierSelectedWatch) return [];
        const courier = data?.find((c) => c._id === courierSelectedWatch);
        return courier?.services ?? [];
    }, [courierSelectedWatch]);

    return (
        <Flex direction="column">
            <Form.Item
                name={courierFieldName}
                label="Courier"
                rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
                <Select
                    loading={loading}
                    placeholder={'Seleccione un courier'}
                    onChange={(event) => {
                        if (form)
                            form.setFieldsValue({
                                shipping_service: undefined,
                            });
                        if (setSelectedTargetId) setSelectedTargetId(event);
                    }}
                >
                    {Array.from(data ?? [])
                        ?.filter((l) => l.is_enabled)
                        ?.map((item) => (
                            <Select.Option value={item?._id} key={item?._id}>
                                {item?.public_name ?? item?.courier_name ?? ''}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>

            <Form.Item
                name="shipping_service"
                label="Tipo de servicio"
                rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
                <Select
                    loading={loading}
                    placeholder={'Seleccione un tipo de servicio'}
                    disabled={!courierSelectedWatch}
                    style={{
                        opacity: courierSelectedWatch ? 1 : 0.5,
                    }}
                >
                    {Array.from(courierServices ?? [])
                        ?.filter((l) => l.is_enabled)
                        ?.map((item) => (
                            <Select.Option value={item?.name} key={item?._id}>
                                {item?.name}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
        </Flex>
    );
};

export default CouriersSelector;