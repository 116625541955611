import styled from 'styled-components';

export const Alert = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: row;
    gap: 0.5rem;
    border-radius: 3px;
    width: fit-content;
    padding: 0.5em 0.5rem;
    align-self: center;
    // margin: 1rem 0rem;
    font-size: 12px;
    ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}
`;
