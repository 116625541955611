import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { setSelectedRows, toggleModal } from '../Utils';
import { fulfillmentsApi } from './index';
import { ordersApi } from '../Orders';

export const createBatchFulfillments = createAsyncThunk(
    'fulfillments/batchcreate',
    async (body, state) => {
        try {
            const promise = AndesClient.post(`/fulfillments/batchcreate`, body);

            toast.promise(promise, {
                loading: (
                    <b>
                        {`Creando fulfillment de ${body.orders.length} pedido${
                            body.orders.length > 1 ? 's' : ''
                        }`}
                        .
                    </b>
                ),
                success: <b>Fulfillments creados con éxito.</b>,
                error: <b>Ocurrió un error.</b>,
            });

            const result = await promise;

            if (result.status === 200) {
                state.dispatch(
                    ordersApi.endpoints.getOrdersByFilters.initiate(undefined, {
                        forceRefetch: true,
                    })
                );
                state.dispatch(
                    fulfillmentsApi.endpoints.getInProgressFulfillments.initiate(undefined, {
                        forceRefetch: true,
                    })
                );
                state.dispatch(toggleModal('order-actions-preparation-modal'));
                state.dispatch(toggleModal('batch-created-fulfillments-modal'));
                state.dispatch(
                    setSelectedRows({ selectedRows: [], tableName: 'prepare-orders-table' })
                );
            }
            return state.fulfillWithValue(result.data);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const createBatchFulfillmentsSlice = createSlice({
    name: 'createBatchFulfillments',
    initialState: {
        loading: false,
        success: false,
        data: {},
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createBatchFulfillments.pending]: (state) => {
            return {
                ...state,
                data: {},
                loading: true,
            };
        },
        [createBatchFulfillments.fulfilled]: (state, action) => {
            return {
                ...state,
                success: true,
                data: action.payload,
                loading: false,
            };
        },
        [createBatchFulfillments.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createBatchFulfillmentsSlice.reducer;
