import styled from 'styled-components';

export const ContentButtonCheck = styled.div`
    display: flex;
    direction: row;
    gap: 10px;

    .icon-check-button {
        margin-top: 4px;
    }
`;

export const TagsIntegrationErrors = styled.div`
    display: inline-block;
    padding: 3px 8px;
    border: 1px solid #536d8f;
    color: #2d3d76;
    background-color: white;
    border-radius: 20px;
    font-weight: 400;
    font-size: 12px;
`;
