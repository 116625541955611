import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import AndesClient from 'services/AndesClient';
import { toggleModal } from '../Utils';
import { getOrderById } from './getOrderById';

const editShippingAddressModalName = 'edit-order-shipping-address-modal';
const editBillingAddressModalName = 'edit-order-billing-address-modal';

export const updateOrder = createAsyncThunk('orders/update', async (body, state) => {
    try {
        const { utils } = state.getState();

        const promise = AndesClient.put(`/orders/update`, body);

        toast.promise(promise, {
            loading: <b>Actualizando datos del pedido.</b>,
            success: <b>Pedido actualizado con éxito.</b>,
            error: <b>Ocurrió un error.</b>,
        });

        if (utils?.modal[editShippingAddressModalName]) {
            state.dispatch(toggleModal(editShippingAddressModalName));
        }
        if (utils?.modal[editBillingAddressModalName]) {
            state.dispatch(toggleModal(editBillingAddressModalName));
        }

        const response = await promise;

        if (response.status === 200) {
            state.dispatch(getOrderById(body._id));
        }
        return response.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const orderUpdateSlice = createSlice({
    name: 'orderUpdate',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [updateOrder.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [updateOrder.fulfilled]: (state) => {
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };
        },
        [updateOrder.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: payload,
            };
        },
    },
});

export default orderUpdateSlice.reducer;
