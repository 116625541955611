import styled from 'styled-components';

export const AmplifyContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const AmplifyForm = styled.form`
    width: 440px;

    .amp-input-text {
        margin-top: 1rem;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #2d3d76;
    }

    .amp-input-text .ant-input-prefix {
        margin-inline-end: 1rem;
    }

    .amp-input-text:last-of-type {
        margin-top: 1.5rem;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #2d3d76;
    }

    .amp-input-text input::placeholder {
        color: #2d3d76;
        font-weight: 400;
    }

    .amp-checkbox .ant-checkbox-inner {
        /* background-color: #2d3d76; */
        border-width: 2px;
        border-color: #2d3d76 !important;
        border-radius: 2px;
    }
    .amp-checkbox .ant-checkbox-checked .ant-checkbox-inner,
    .amp-checkbox:hover .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #2d3d76 !important;
    }
    .amp-checkbox:hover
        .ant-checkbox-checked:where(
            .css-dev-only-do-not-override-12upa3x
        ).ant-checkbox-checked:after {
        border-color: #2d3d76 !important;
    }
    .amplify-radio {
        justify-content: center;
    }
`;
export const Button = styled.button`
    color: white !important;
    width: 100%;
    margin-top: 45px;
    border: none;
    font-size: 16px;
    font-weight: 400 !important;

    &.primary {
        background-color: #2d3d76;
    }
    &.secondary {
        background-color: #f27127;
    }
    &.primary:hover {
        color: white;
        background-color: #f27127;
    }
    &.secondary:hover {
        color: white;
        background-color: #2d3d76;
    }
`;
