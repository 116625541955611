import { Fragment, useEffect } from 'react';
import { MetafieldFormCard } from '../../styles';
import FormStyled from 'components/Utils/Form';
import { Col, Form, Input, Row, Select } from 'antd';
import Flex from 'components/Utils/Flex';
import { useCreateMetafieldMutation, useUpdateMetafieldMutation } from 'redux/features/Metafields';
import { Button } from '@andescommercecloud/beflow-design-system';
import { Title } from 'pages/Settings/styles';
import LocationsSelector from '../LocationsSelector';
import IntegrationsSelector from '../IntegrationsSelector';
import SalesChannelSelector from '../SalesChannelSelector';
import CouriersSelector from '../CouriersSelector';

export const CreateMetafieldForm = ({
    category,
    setSelectedTargetId,
    selectedTargetId,
    setMetafieldToEdit,
    metafieldToEdit,
    showInfo,
    setShowInfo,
}) => {
    const [form] = Form.useForm();

    const [createMetafield, createMetafieldState] = useCreateMetafieldMutation();
    const [updateMetafield, updateMetafieldState] = useUpdateMetafieldMutation();

    const showRestForm = showInfo && category.name !== undefined && selectedTargetId;

    useEffect(() => {
        form.setFieldsValue({
            target_id: selectedTargetId ? selectedTargetId : undefined,
            namespace: category?.name ?? undefined,
        });
    }, [category, selectedTargetId]);

    useEffect(() => {
        if (metafieldToEdit) {
            form.setFieldsValue({
                ...metafieldToEdit,
            });
        }
    }, [metafieldToEdit]);

    const handleSubmit = (values) => {
        console.log({ values });
        try {
            if (!values?.target_id) throw new Error('Debe seleccionar un target_id');
            if (metafieldToEdit._id) {
                const newValues = {
                    _id: metafieldToEdit._id,
                    namespace: category?.name ?? undefined,
                    ...values,
                };
                updateMetafield(newValues);
            }
            if (!metafieldToEdit._id) {
                createMetafield({ ...values, namespace: category?.name ?? undefined });
            }

            form.resetFields();
            setMetafieldToEdit({});

            form.setFieldsValue({
                namespace: category?.name ?? undefined,
                target_id: selectedTargetId ? selectedTargetId : undefined,
            });
            setShowInfo(false);
        } catch (error) {
            console.log({ error });
        }
    };

    let formClose = () => {
        setShowInfo(false);
    };

    const resetForm = () => {
        form.resetFields();
        setShowInfo(false);
        setMetafieldToEdit({});
        form.setFieldsValue({
            namespace: category?.name ?? undefined,
            target_id: selectedTargetId ? selectedTargetId : undefined,
        });
    };

    const form_name = metafieldToEdit?._id ? 'metafield-form-edit' : 'metafield-form-new';

    console.log({
        setSelectedTargetId,
        selectedTargetId,
    });
    return (
        <MetafieldFormCard>
            <FormStyled name={form_name} layout="vertical" form={form} onFinish={handleSubmit}>
                <Title>Listado de la selección metafields</Title>
                <Row gutter={24}>
                    {category.name !== undefined && (
                        <Col lg={12} xl={6}>
                            {category.name === 'locations' && (
                                <LocationsSelector
                                    selectedTargetId={selectedTargetId}
                                    setSelectedTargetId={setSelectedTargetId}
                                />
                            )}
                            {category.name === 'integrations' && (
                                <IntegrationsSelector setSelectedTargetId={setSelectedTargetId} />
                            )}
                            {category.name === 'saleschannels' && (
                                <SalesChannelSelector setSelectedTargetId={setSelectedTargetId} />
                            )}
                            {category.name === 'couriers' && (
                                <CouriersSelector
                                    setSelectedTargetId={setSelectedTargetId}
                                    form={form}
                                    courierFieldName="target_id"
                                />
                            )}
                        </Col>
                    )}

                    {showRestForm && (
                        <Fragment>
                            <Col lg={12} xl={6}>
                                <Form.Item
                                    name="type"
                                    label="Tipo"
                                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                                >
                                    <Select placeholder="Tipo de dato">
                                        <Select.Option value="string">Texto</Select.Option>
                                        <Select.Option value="number">Numerico</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={12} xl={6}>
                                <Form.Item
                                    name="key"
                                    label="Llave"
                                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                                >
                                    <Input placeholder="Llave del elemento" />
                                </Form.Item>
                            </Col>
                            <Col lg={12} xl={6}>
                                <Form.Item
                                    name="value"
                                    label="Valor"
                                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                                >
                                    <Input placeholder="Valor del elemento" />
                                </Form.Item>
                            </Col>
                        </Fragment>
                    )}
                </Row>

                {showRestForm && (
                    <Flex justifyContent="flex-end" gap="10px">
                        <Button
                            onClick={metafieldToEdit?._id ? resetForm : formClose}
                            variation="secondary"
                        >
                            Cancelar
                        </Button>

                        <Button
                            htmlType="submit"
                            disabled={
                                createMetafieldState.isLoading || updateMetafieldState.isLoading
                            }
                            loading={
                                createMetafieldState.isLoading || updateMetafieldState.isLoading
                            }
                        >
                            {metafieldToEdit?._id ? 'Guardar cambios' : 'Guardar Metafield'}
                        </Button>
                    </Flex>
                )}
            </FormStyled>
        </MetafieldFormCard>
    );
};