const MoneyFormat = (mount, currency = 'CLP') => {
    //mount = Number(String(mount).replace(/[^0-9]/g, ''));
    mount = Number(String(mount).replace(/[^0-9.,]/g, ''));
    let selectedCurrency = currency;
    let locale = 'es-ES';
    let parsedMount = mount;

    const localStorageCurrency = localStorage.getItem('merchantCurrency');

    const merchantDecimalSupport =
        localStorage.getItem('merchantDecimalSupport') === 'true' ?? false;

    if (localStorageCurrency && localStorageCurrency !== 'undefined') {
        selectedCurrency = localStorageCurrency;
    }

    const hasDecimalSupport = merchantDecimalSupport;

    selectedCurrency = String(selectedCurrency ?? '').toUpperCase();

    if (selectedCurrency === 'PEN') locale = 'es-PE';
    if (selectedCurrency === 'CLP') {
        locale = 'es-CL';
        // parsedMount = parseInt(mount);
    }

    // console.log(selectedCurrency, parsedMount)
    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: selectedCurrency,
        maximumFractionDigits: hasDecimalSupport ? 2 : 0,
        minimumFractionDigits: hasDecimalSupport ? 2 : 0,
    }).format(parsedMount);
};

export default MoneyFormat;
