import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { clearSelectedRows } from '../Utils';
import { getFulfillmentById } from '../Fulfillments/getOne';

export const addToPackage = createAsyncThunk('packages/add', async (data, state) => {
    try {
        const { fulfillment_id, ...otherData } = data;

        const promise = AndesClient.put('/packages/add', otherData);

        toast.promise(promise, {
            loading: <b>Añadiendo producto al bulto...</b>,
            success: <b>Producto añadido con éxito</b>,
            error: <b>Ocurrió un error.</b>,
        });

        const result = await promise;

        if (result.status === 200) {
            state.dispatch(clearSelectedRows('scanned-codes-table'));
            state.dispatch(getFulfillmentById(fulfillment_id));
        }

        return await result.data;
    } catch (error) {
        return state.rejectWithValue(error.message);
    }
});

const addToPackageSlice = createSlice({
    name: 'packages-add',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [addToPackage.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [addToPackage.fulfilled]: (state) => {
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };
        },
        [addToPackage.rejected]: (state, payload) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default addToPackageSlice.reducer;
