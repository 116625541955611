import { Modal } from 'antd';
import {
    ButtonModalShippingCostAdd,
    TitleModalShippingCost,
} from 'components/ProductsTable/Actions/styles';
import { ButtonModalShippingCost } from './styles';

export const ModalAddShippingCost = ({
    isOpen,
    onClose,
    onOK,
    onCancel,
    shippingCost,
    isRefund,
}) => {
    return (
        <Modal
            open={isOpen}
            onCancel={onClose}
            title={
                <TitleModalShippingCost>
                    {isRefund ? 'Devolución' : 'Anulación'} de productos
                </TitleModalShippingCost>
            }
            destroyOnClose={true}
            footer={[
                <ButtonModalShippingCost key="noAddShippingCost" onClick={onCancel}>
                    No añadir
                </ButtonModalShippingCost>,
                <ButtonModalShippingCostAdd
                    key="addShippingCost"
                    // type="primary"
                    onClick={onOK}
                >
                    Si, añadir
                </ButtonModalShippingCostAdd>,
            ]}
        >
            <span>
                Está efectuando {isRefund ? 'devolución' : 'anulación'} de todos los items
                <br />
                <br /> ¿Desea añadir a la {isRefund ? 'devolución' : 'anulación'} el monto
                correspondiente al costo de envío <strong>${shippingCost}</strong> ?
            </span>
        </Modal>
    );
};
