Object.filter = (mainObject, filterFunction) => {
    return Object.keys(mainObject)
        .filter((ObjectKey) => filterFunction(mainObject[ObjectKey]))
        .reduce((result, ObjectKey) => {
            result[ObjectKey] = mainObject[ObjectKey];
            return result;
        }, {});
};

export const takeOutEmptyValuesAnObject = (obj) => {
    return Object.filter(obj, (value) => {
        return value !== '';
    });
};

export const takeOutNullValuesAnObject = (obj) => {
    return Object.filter(obj, (value) => {
        return value !== null;
    });
};

export const takeOutKeysAnObject = (keysToDelete, obj) => {
    keysToDelete.forEach((keys) => {
        delete obj[keys];
    });
    return obj;
};

export const isObjectEmpty = (value) =>
    Object.prototype.toString.call(value) === '[object Object]' && JSON.stringify(value) === '{}';
