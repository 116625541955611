import styled, { css } from 'styled-components';
import { Form } from 'antd';

const { Item } = Form;

const variantRowStyles = css`
    flex-direction: row !important;

    .ant-form-item-label {
        line-height: 2.5;
        min-width: ${({ $minWidth }) => $minWidth ?? '100px'};
        label {
            color: ${({ theme }) => theme?.buttons?.colors?.primary?.second ?? '#536D8F'};
        }
    }
`;
const inputVariants = {
    row: variantRowStyles,
};

export default styled(Item)`
    ${({ $noMargin }) => $noMargin && `margin-bottom: 0px;`}
    ${({ $marginBottom }) => $marginBottom && `margin-bottom: ${$marginBottom};`}
    ${({ $marginTop }) => $marginTop && `margin-top: ${$marginTop};`}
    ${({ $marginLeft }) => $marginLeft && `margin-left: ${$marginLeft};`}
    ${({ $marginRight }) => $marginRight && `margin-right: ${$marginRight};`}
    ${({ $gridArea }) => $gridArea && `grid-area: ${$gridArea};`}
    ${({ $width }) => $width && `width: ${$width};`}
    ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
    ${({ $minWidth }) => $minWidth && `min-width: ${$minWidth}!important;`}
    .ant-form-item-label {
        padding: 0 0 4px;

        label {
            color: ${({ theme }) => theme?.font?.label ?? '#2D3D76'};
            font-weight: 400;
            font-size: 0.85rem;
        }
        label::before {
            display: none !important;
        }
    }

    ${({ $variant }) => $variant && inputVariants[$variant]}
`;
