import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const ordersConfigurationApi = createApi({
    reducerPath: 'orders_configuration',
    baseQuery,
    tagTypes: ['orders_configuration'],
    endpoints: (builder) => ({
        getOrdersConfiguration: builder.query({
            query: () => {
                return {
                    url: `/orders/ordersConfiguration`,
                    method: 'get',
                };
            },
            providesTags: ['orders_configuration'],
        }),
        createOrdersConfiguration: builder.mutation({
            query: (body) => ({
                url: `/orders/ordersConfiguration/create`,
                method: 'post',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando configuración de pedidos...</b>,
                    success: <b>Configuración guardada con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['orders_configuration'],
        }),
        updateOrdersConfiguration: builder.mutation({
            query: (body) => ({
                url: `/orders/ordersConfiguration/update`,
                method: 'post',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Guardando configuración de pedidos...</b>,
                    success: <b>Configuración guardada con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['orders_configuration'],
        }),
    }),
});

export const {
    useGetOrdersConfigurationQuery,
    useCreateOrdersConfigurationMutation,
    useUpdateOrdersConfigurationMutation,
} = ordersConfigurationApi;
