import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import toast from 'react-hot-toast';

export const stateChangeTraceabilityApi = createApi({
    reducerPath: 'stateChangeTraceability',
    baseQuery,
    tagTypes: ['stateChangeTraceability'],
    endpoints: (builder) => ({
        updateStateChangeTraceability: builder.mutation({
            method: 'put',
            query: (data) => {
                return {
                    url: `/state-change-traceability/update`,
                    method: 'put',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Guardando configuracion...</b>,
                    success: <b>Configuracion guardada</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['stateChangeTraceability'],
        }),
        getStateChangeTraceability: builder.query({
            method: 'get',
            query: () => {
                return {
                    url: `/state-change-traceability/getByMerchant`,
                    method: 'get',
                };
            },
            providesTags: ['stateChangeTraceability'],
        }),
    }),
});

export const { useUpdateStateChangeTraceabilityMutation, useGetStateChangeTraceabilityQuery } =
    stateChangeTraceabilityApi;
