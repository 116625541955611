import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';

const Index = ({ modalName, children, ...modalProps }) => {
    const dispatch = useDispatch();

    const state = useSelector((state) => state.utils);
    const isActive = state.modal[modalName] || false;

    const handleToggle = () => {
        dispatch(toggleModal(modalName));
    };

    return (
        <Modal open={isActive} onCancel={handleToggle} {...modalProps}>
            {children}
        </Modal>
    );
};

export default Index;
