import styled, { css } from 'styled-components';
import { Collapse } from 'antd';
import Menu from 'components/V2/Menu';

export const InColumnFiltersMenu = styled(Menu)`
    background: #fff;
    border: 1px solid #2d3d76;
    border-radius: 3px;
    color: #41515e;
    /* padding-left: 20px; */
    padding: 5px;
    width: 280px;

    .ant-menu-item {
        margin-right: 40px;
    }
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #7998a7;
    ${({ color }) =>
        color &&
        css`
            background: ${color};
        `}
`;

export const CollapsePanelStyled = styled(Collapse.Panel)`
    .ant-collapse-header {
        color: #41515e !important;
    }

    svg {
        width: 12px;
        height: 12px;

        > path {
            fill: #2d3d76;
        }
    }
`;
