import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getFulfillmentById = createAsyncThunk(
    'fulfillments/getById',
    async (fulfillment_id, state) => {
        try {
            const response = await AndesClient.get(`/fulfillments/${fulfillment_id}`);
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const fulfillmentSlice = createSlice({
    name: 'fulfillment',
    initialState: {
        loading: false,
        data: { line_items: [] },
        succes: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [getFulfillmentById.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getFulfillmentById.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                succes: true,
                error: null,
            };
        },
        [getFulfillmentById.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                succes: false,
                error: payload,
            };
        },
    },
});
export default fulfillmentSlice.reducer;
