import { createSlice } from '@reduxjs/toolkit';

const initialStateDetailsManualIncidenceDrawer = {
    orderData: {},
};

const solveOrderData = createSlice({
    name: 'detailsManualIncidenceDrawer',
    initialState: initialStateDetailsManualIncidenceDrawer,
    reducers: {
        changeDetailsManualIncidenceDrawerData: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        changeDetailsManualIncidenceDrawerField: (state, { payload }) => {
            return {
                ...state,
                [payload[0]]: payload[1],
            };
        },
        reInitDetailsManualIncidenceDrawerData: (state) => {
            return {
                ...state,
                ...initialStateDetailsManualIncidenceDrawer,
            };
        },
    },
});
export const {
    changeDetailsManualIncidenceDrawerData,
    changeDetailsManualIncidenceDrawerField,
    reInitDetailsManualIncidenceDrawerData,
} = solveOrderData.actions;
export default solveOrderData.reducer;
