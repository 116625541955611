import { andOrOptions, conditions } from './filters';
import { crossdockingFilters } from './filters/crossdocking';
import { deliveriesFilters } from './filters/deliveries';
import { manifestsFilters } from './filters/manifests';
import { pendingArrivalsFilters } from './filters/pendingArrivals';
import { preparationsFilters } from './filters/preparations';
import { fulfillmentStatusOptions as ordersFulfillmentStatus } from './ordersFulfillmentStatus';

export const labelsForAllModules = {
    andOrOptions,
    conditions,
    orders: {
        fulfillment_status: [...ordersFulfillmentStatus],
    },
    preparations: {
        status: {
            fields: [...preparationsFilters.fields],
            values: [...preparationsFilters.values.status],
        },
        delivery_method: {
            fields: [...preparationsFilters.fields],
            values: [...preparationsFilters.values.delivery_method],
        },
        sales_channel: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        courier: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        search_references: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        remote_order_id: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        name: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        product_name: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        promised_date: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        custom_promised_date: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        promised_date_range: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        sku: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        line_items: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        'folio::search_references': {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        'package_code::search_references': {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        'locations.destination': {
            fields: [...preparationsFilters.fields],
            values: [],
        },
    },
    pendingArrivals: {
        name: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        remote_order_id: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        province: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        promised_date: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        custom_promised_date: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        promised_date_range: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        line_items: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        origin: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        reception_status: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        delivery_method: {
            fields: [...pendingArrivalsFilters.fields],
            values: [...pendingArrivalsFilters.values.delivery_method],
        },
        preparationcd_transfer_pickupstore: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        'preparationcd_transfer_pickupstore.status': {
            fields: [...pendingArrivalsFilters.fields],
            values: [...pendingArrivalsFilters.values['preparationcd_transfer_pickupstore.status']],
        },
        'consolidation.status': {
            fields: [...pendingArrivalsFilters.fields],
            values: [...pendingArrivalsFilters.values['consolidation.status']],
        },
        'consolidation.consolidation_location_id': {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        search_references: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        product_name: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        customer_name: {
            fields: [...pendingArrivalsFilters.fields],
            values: [],
        },
        'folio::search_references': {
            fields: [...preparationsFilters.fields],
            values: [],
        },
    },
    crossdocking: {
        remote_order_id: {
            fields: [...crossdockingFilters.fields],
            values: [],
        },
        delivery_method: {
            fields: [...crossdockingFilters.fields],
            values: [...crossdockingFilters.values.delivery_method],
        },
        customer: {
            fields: [...crossdockingFilters.fields],
            values: [],
        },
        promised_date_range: {
            fields: [...crossdockingFilters.fields],
            values: [],
        },
        fulfillment_status: {
            fields: [...crossdockingFilters.fields],
            values: [...crossdockingFilters.values.fulfillment_status],
        },
        assigned_courier: {
            fields: [...crossdockingFilters.fields],
            values: [],
        },
    },
    deliveries: {
        name: {
            fields: [...deliveriesFilters.fields],
            values: [],
        },
        remote_order_id: {
            fields: [...deliveriesFilters.fields],
            values: [],
        },
        promised_date: {
            fields: [...deliveriesFilters.fields],
            values: [],
        },
        custom_promised_date: {
            fields: [...deliveriesFilters.fields],
            values: [],
        },
        promised_date_range: {
            fields: [...deliveriesFilters.fields],
            values: [],
        },
        status: {
            fields: [...deliveriesFilters.fields],
            values: [...deliveriesFilters.values.status],
        },
        packages: {
            fields: [...deliveriesFilters.fields],
            values: [],
        },
        delivery_method: {
            fields: [...deliveriesFilters.fields],
            values: [...deliveriesFilters.values.delivery_method],
        },
        search_references: {
            fields: [...deliveriesFilters.fields],
            values: [],
        },
        sales_channel: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        courier: {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        'package_code::search_references': {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        'folio::search_references': {
            fields: [...preparationsFilters.fields],
            values: [],
        },
        customer_name: {
            fields: [...deliveriesFilters.fields],
            values: [],
        },
        'destination.province': {
            fields: [...deliveriesFilters.fields],
            values: [],
        },
        'locations.destination': {
            fields: [...preparationsFilters.fields],
            values: [],
        },
    },
    manifests: {
        manifest_id: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        status: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        'search_references::folio': {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        closer_deadline_custom: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        location: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        client: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        courier: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        promised_date: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        delivery_method: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        sales_channel: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        packages_length: {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        'search_references::fulfillment_name': {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        'search_references::remote_order_id': {
            fields: [...manifestsFilters.fields],
            values: [],
        },
        'search_references::package_code': {
            fields: [...manifestsFilters.fields],
            values: [],
        },
    },
};
