import { Fragment, useState } from 'react';
import { /* useDispatch, */ useSelector } from 'react-redux';
import Flex from 'components/Utils/Flex';
import { useConfirmfulfillmentReceivedMutation } from '../../../redux/features/ArrivalsPending';

import DrawerWithButton from 'components/DrawerWithButton';
import { useHistory } from 'react-router-dom';
import { fulfillmentsApi } from 'redux/features/Fulfillments';
import { useDispatch } from 'react-redux';

const ConfirmModal = ({ fulfillments = [], disabled }) => {
    const history = useHistory();
    const [loadingDeliver, setLoadingDeliver] = useState(false);

    const [confirmReceive, dataConfirmReceiveStatus] = useConfirmfulfillmentReceivedMutation();

    const dispatch = useDispatch();

    const batchDeliverFulfillmentsState = useSelector((state) => state.batchDeliverFulfillments);

    const handleSubmit = async () => {
        const response = await confirmReceive({ fulfillments });

        // dispatch(fulfillmentsApi.util.invalidateTags('pendingArrivalFulfillment'));
        dispatch(fulfillmentsApi.util.resetApiState());

        setLoadingDeliver(false);

        if (response?.data) {
            history.push('/pendingArrival');
        }
    };

    return (
        <Flex direction="row" columnGap="1rem">
            <DrawerWithButton
                buttonProps={{
                    type: 'primary',
                    disabled: disabled || dataConfirmReceiveStatus.isLoading,
                    // ||
                    // instance.loading ||
                    // fulfillments.length === 0 ||
                    // allDelivered ||
                    // batchDeliverFulfillmentsState.loading ||
                    // loadingDeliver,
                    buttonText: 'Confirmar recepción',
                    style: {
                        height: '44px',
                        fontSize: '1rem',
                        padding: '0rem 2rem',
                        borderRadius: '0.25rem',
                    },
                }}
                drawerProps={{
                    /* title:
                        fulfillments.length === 1
                            ? `Detalles del pedido ${fulfillments[0].name}`
                            : `Detalles de los (${fulfillments.length}) pedidos seleccionados`, */
                    title: <span style={{ color: '' }}>Pedido listo para retiro</span>,
                    toSubmit: handleSubmit,
                    name: 'packages-details-drawer',
                    okText: 'Confirmar',
                    width: '400px',
                    okButtonProps: {
                        disabled:
                            fulfillments.length === 0 ||
                            batchDeliverFulfillmentsState.loading ||
                            loadingDeliver,
                    },
                }}
            >
                <p style={{ fontSize: '14px', fontWeight: 500 }}>
                    Los siguientes pedidos estan en optima condición para ser retirados en tienda:
                    {/* Los siguientes pedidos ( */}
                    {/* {fulfillments.filter((f) => f.status === 'ready_for_deliver').length}) estan en */}
                    {/* optima condición para procesarlos: */}
                </p>
                <div
                    style={{
                        maxHeight: '240px',
                        overflowY: 'auto',
                    }}
                >
                    {fulfillments
                        .filter((f) =>
                            [
                                'received_in_pickup_location',
                                'partially_received_in_pickup_location',
                            ].includes(f?.preparationcd_transfer_pickupstore?.status)
                        )
                        .map((fulfillment) => (
                            <Fragment key={fulfillment._id}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginBottom: '0.5rem',
                                        borderBottom: '1px solid #DCE5EC',
                                        padding: '0.65rem',
                                    }}
                                >
                                    <b className="">
                                        Pedido {fulfillment.name} |{' '}
                                        {fulfillment.delivery_method === 'pickup' && 'F.R'}
                                        {fulfillment.delivery_method === 'shipping' && 'F.D'}{' '}
                                        {fulfillment.delivery_method === 'pickup' &&
                                            'Retiro en tienda '}
                                        {fulfillment.delivery_method === 'shipping' &&
                                            `DESPACHO | ${
                                                fulfillment?.courier?.public_name ??
                                                fulfillment?.courier?.courier_name
                                            }`}
                                    </b>
                                    <ul style={{ padding: '6px' }}>
                                        {fulfillment.packages.map((pkg) => (
                                            <li key={pkg.code} className="flex items-center ml-3">
                                                <div
                                                    style={{
                                                        width: '3px',
                                                        height: '3px',
                                                        borderRadius: '50%',
                                                        backgroundColor: 'black',
                                                        marginRight: '0.5rem',
                                                    }}
                                                ></div>
                                                <span>
                                                    <b>Bulto </b>
                                                    <b style={{ color: '#037FB9' }}>#{pkg.code}</b>
                                                </span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </Fragment>
                        ))}
                </div>
                <br />
                <p style={{ fontSize: '14px', fontWeight: 500 }}>
                    ¿Desea confirmar que el pedido está listo para ser retirado?{' '}
                </p>
            </DrawerWithButton>
        </Flex>
    );
};

export default ConfirmModal;
