import { SelectStyled } from './styles';
// import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';

const orderListByKey = (data, key, order) => {
    const copyData = [...data];
    const compareValues =
        (key, order = 'asc') =>
        (elemA, elemB) => {
            if (!elemA.hasOwnProperty(key) || !elemB.hasOwnProperty(key)) return 0;
            const comparison = elemA[key].localeCompare(elemB[key]);
            return order === 'desc' ? comparison * -1 : comparison;
        };
    return copyData.sort(compareValues(key, order));
};

const SelectTypeOne = (props) => {
    const { optionProps, data, ...selectProps } = props;
    const { keyProp, valueProp, labelProp, labelPropToFilter = null, renderLabel } = optionProps;

    const stringifyLabel = (i) => {
        if (Array.isArray(labelProp)) {
            const stringifiedLabel = `${labelProp.map((label) => `${i[label] || label} `)}`
                .replace(',', '')
                .trim();
            if (renderLabel) {
                return renderLabel(stringifiedLabel);
            }
            return stringifiedLabel;
        }
        if (renderLabel) {
            return renderLabel(i);
        }
        return i;
    };

    return (
        <SelectStyled /* suffixIcon={<ChevronDown />} */ {...selectProps}>
            {labelPropToFilter &&
                orderListByKey(data, labelPropToFilter).map((elem) => (
                    <SelectStyled.Option
                        key={elem[keyProp] || elem}
                        value={elem[valueProp] || elem}
                    >
                        {stringifyLabel(elem)}
                    </SelectStyled.Option>
                ))}
            {!labelPropToFilter &&
                !!data?.length &&
                data.map((i) => (
                    <SelectStyled.Option key={i[keyProp] || i} value={i[valueProp] || i}>
                        {stringifyLabel(i)}
                    </SelectStyled.Option>
                ))}
        </SelectStyled>
    );
};

/* 
<SelectStyled suffixIcon={<ChevronDown />} {...selectProps}>
    {data[0] &&
        data.map((i) => (
            <SelectStyled.Option key={i[keyProp] || i} value={i[valueProp] || i}>
                {stringifyLabel(i)}
            </SelectStyled.Option>
        ))}
</SelectStyled> 
*/

export default SelectTypeOne;
