import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const masterStatesApi = createApi({
    reducerPath: 'masterStates',
    baseQuery,
    tagTypes: ['masterStates'],
    endpoints: (builder) => ({
        getMasterStates: builder.query({
            method: 'get',
            queryFn: async (args, api, extraOptions, fetchWithBQ) => {
                const localMasterStates = JSON.parse(localStorage.getItem('masterStates') || '{}');

                if (localMasterStates?.data?._id) {
                    return localMasterStates;
                }

                const response = await fetchWithBQ('/masterstates/merchantmasterstates');
                localStorage.setItem('masterStates', JSON.stringify(response));
                return response;
            },
            providesTags: ['masterStates'],
        }),
    }),
});

export const { useGetMasterStatesQuery } = masterStatesApi;
