export const centryLabels = [
    {
        name: 'Mercado Libre',
        code: 'centry-mercadolibre',
    },
    {
        name: 'Linio',
        code: 'centry-linio',
    },
    {
        name: 'Dafiti',
        code: 'centry-dafiti',
    },
    {
        name: 'Ripley',
        code: 'centry-ripley',
    },
    {
        name: 'París',
        code: 'centry-parís',
    },
    {
        name: 'Falabella',
        code: 'centry-falabella',
    },
    {
        name: 'Vtex',
        code: 'centry-vtex',
    },
    {
        name: 'Magento',
        code: 'centry-magento',
    },
    {
        name: 'Shopify',
        code: 'centry-shopify',
    },
    {
        name: 'Prestashop',
        code: 'centry-prestashop',
    },
    {
        name: 'Woocommerce',
        code: 'centry-woocommerce',
    },
];
