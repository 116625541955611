import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    useCreateLogMessageMutation,
    useGetLogMessageMutation,
    useDeleteLogMessageMutation,
    useUpdateLogMessageMutation,
} from 'redux/features/Logs/logMessages';
import { ordersApi } from 'redux/features/Orders';

export const useLogMessages = ({ data }) => {
    const dispatch = useDispatch();
    const [stateComments, setStateComments] = useState({
        isModalCommentVisible: false,
        isEdit: false,
    });
    const [currentLog, setCurrentLog] = useState(undefined);
    const [commentary, setCommentary] = useState('');
    const [getLogMessage, { data: LogMessage }] = useGetLogMessageMutation();
    const [createLogMessage, createLogMessageStatus] = useCreateLogMessageMutation();
    const [updateLogMessage, updateLogMessageStatus] = useUpdateLogMessageMutation();
    const [deleteLogMessage, deleteLogMessageStatus] = useDeleteLogMessageMutation();

    const onCreateComment = async (comment) => {
        try {
            await createLogMessage({ message: comment, log_id: currentLog?.id });
            setCurrentLog(undefined);
            setIsModalCommentVisible(false)
            dispatch(ordersApi.endpoints.getOrder.initiate(data?._id, { forceRefetch: true }));
        } catch (error) {
            console.log(error);
        }
    };

    const handleEdit = (logEvent) => {
        setCommentary(logEvent?.log_messages?.[0]?.message);
        setStateComments({
            ...stateComments,
            isEdit: {
                ...stateComments.isEdit,
                [logEvent?.id]: true,
            },
        });
    };

    const handleUpdate = async (logEvent) => {
        try {
            await updateLogMessage({
                id: logEvent?.log_messages?.[0]?._id,
                message: commentary,
            });

            setStateComments({
                ...stateComments,
                isEdit: {
                    ...stateComments.isEdit,
                    [logEvent?.id]: false,
                },
            });

            dispatch(ordersApi.endpoints.getOrder.initiate(data?._id, { forceRefetch: true }));
        } catch (error) {
            console.log(error);
        }
    };

    const handleDelete = async (logEvent) => {
        try {
            await deleteLogMessage(logEvent?.log_messages?.[0]?._id);
            dispatch(ordersApi.endpoints.getOrder.initiate(data?._id, { forceRefetch: true }));
        } catch (error) {
            console.log(error);
        }
    };

    const setIsModalCommentVisible = (val) => {
        setStateComments({
            ...stateComments,
            isModalCommentVisible: val,
        });
    };

    const setIsEdit = (name, value) => {
        setStateComments({
            ...stateComments,
            isEdit: {
                ...stateComments.isEdit,
                [name]: value,
            },
        });
    };

    return {
        LogMessage,
        getLogMessage,
        onCreateComment,
        handleDelete,
        handleEdit,
        handleUpdate,
        createLogMessageStatus,
        updateLogMessageStatus,
        deleteLogMessageStatus,
        setCurrentLog,
        currentLog,
        setIsModalCommentVisible,
        stateComments,
        setIsEdit,
        commentary,
        setCommentary,
    };
};
