import styled from "styled-components";

export const InputWrapper = styled.div`
    display: flex;
    border: 1px solid #7998A7;
    padding-left: 1rem;
    border-radius: 3px;
    width: 100%;
    max-width: 445px;
`
export const Input = styled.input`
    width: 100%;
    border-right: 1px solid #7998A7;

`
