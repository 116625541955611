import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const notificationsApi = createApi({
    reducerPath: 'user_notifications',
    baseQuery,
    tagTypes: ['user_notifications'],
    endpoints: (builder) => ({
        getNotifications: builder.query({
            method: 'get',
            query: () => `/user-notifications`,
            providesTags: ['user_notifications'],
        }),
        markAsReadNotification: builder.mutation({
            method: 'post',
            query: (body) => ({
                url: `/user-notifications/markasread`,
                method: 'post',
                body,
            }),
            onQueryStarted: (data, api) => {
                if (Array.from(data?.notification_ids ?? []).length > 1) {
                    toast.promise(api.queryFulfilled, {
                        loading: <b>Marcando notificaciones como vistas...</b>,
                        success: <b>Notificaciones actualizadas</b>,
                        error: <b>Ocurrió un error.</b>,
                    });
                }
            },
        }),
    }),
});

export const { useGetNotificationsQuery, useMarkAsReadNotificationMutation } = notificationsApi;
