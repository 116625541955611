import { deliveriesFulfillmentStatusOptions, deliveriesDeliveryMethodOptions } from './values';
import { deliveriesFilterFields } from './fields';

export const deliveriesFilters = {
    fields: [...deliveriesFilterFields],
    values: {
        status: [...deliveriesFulfillmentStatusOptions],
        delivery_method: [...deliveriesDeliveryMethodOptions],
    },
};
