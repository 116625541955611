import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';
import Flex from 'components/Utils/Flex';
import { Button } from '@andescommercecloud/beflow-design-system';
import { Modal } from 'antd';

export const SendDeliveryModal = ({ sentToDelivery, ordersSelected, onCancel }) => {
    const dispatch = useDispatch();
    const modalName = `orders-to-delivery-tickets-confirmation`;
    const state = useSelector((state) => state.utils);
    const isActive = state.modal[modalName] || false;

    return (
        <Modal
            open={isActive}
            buttonType="menuitem"
            title={`Enviar (${ordersSelected.length}) pedidos a entregas`}
            okText="Aceptar"
            footer={null}
            destroyOnClose
        >
            <Flex direction="column">
                <b>
                    ¿Confirma que imprimió y pegó las etiquetas de couriers para las orden(es)
                    seleccionada(s)?
                </b>
                <br />
                <Flex direction="row" columnGap="1rem">
                    <Button
                        block
                        onClick={() => {
                            onCancel();
                            dispatch(toggleModal(modalName));
                        }}
                        variation="secondary"
                    >
                        <b>No</b>
                    </Button>
                    <Button
                        block
                        onClick={() => {
                            sentToDelivery();
                            dispatch(toggleModal(modalName));
                        }}
                    >
                        <b>Si</b>
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
};
