import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html {
        box-sizing: border-box;
        background-color: ${({ theme }) => theme.background.html} !important;

    }

    body {
        margin: 0px;
        padding: 0px;
        min-height: 100vh;
        font-size: ${({ theme }) => theme.fontSizes.desktop};
        background-color: unset !important;
        color: ${({ theme }) => theme.font.primary} !important;
    }

    .ant-select-disabled {
        background-color: #f5f5f5 !important;
        border-color:#d9d9d9 !important;
    }

    .w-rjv-line {
        display:flex;
        flex-direction:row;
    }
    .ant-select-focused {
        box-shadow: none !important;
    }
    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    *:focus, *:active, *:hover {
        outline: none !important;
    }
    .ant-radio-button-wrapper-checked {
        background-color: #2d3d76 !important ;
        border-color: #2d3d76 !important;
        color: #fff !important;
    }
    ._1MGIk {
        font-weight: 500 !important;
        letter-spacing: 0.5px;
    }
    .ant-input-number-handler-wrap {  
        opacity: 1 !important;
    }
    .ant-popover-buttons {
        display: flex;
        justify-content: flex-end;
    }

    .disabled-icon {
        opacity: 0.5;
        cursor: not-allowed;
    }
 

    b {
        font-weight: 500;
    }

    .manifest_table__row-expanded {
        td {
            .manifest-table__expand-icon {
                transform: rotate(180deg);
                color: #F27127;
            }
        }

        &.isOdd {
            > td {
                background-color: rgba(245, 245, 245, 0.3) !important;
                border-bottom: 2px !important;
            }
        }

        &.isEven {
            > td {
                background-color: rgba(245, 245, 245, 1) !important;
                border-bottom: 2px !important;
            }
        }
    }

    .crossdocking_table__row-expanded {
        td {
            .crossdocking-table__expand-icon {
                transform: rotate(180deg);
                color: #F27127;
            }
        }

        &.isOdd {
            > td {
                background-color: rgba(245, 245, 245, 0.3) !important;
                border-bottom: 2px !important;
            }
        }

        &.isEven {
            > td {
                background-color: rgba(245, 245, 245, 1) !important;
                border-bottom: 2px !important;
            }
        }
    }

    
    .orders_table__row-expanded {
        td {
            .orders-table__expand-icon {
                transform: rotate(180deg);
                color: #F27127;
            }
        }

        &.isOdd {
            > td {
                background-color: rgba(245, 245, 245, 0.3) !important;
                border-bottom: 2px !important;
            }
        }

        &.isEven {
            > td {
                background-color: rgba(245, 245, 245, 1) !important;
                border-bottom: 2px !important;
            }
        }
    }



    .submenu-collapsed__open {
        padding-top: 0.5rem;
        display: flex;
        &:before {
            content: '';
            width: 4px;
            background: #ecedef;
        }
    }

    .submenu-collapsed__closed {
        overflow: hidden;
        display: none;
    }

    th.ant-table-cell:not(.ant-table-selection-column) {
        &:hover {
            background-color: rgb(250, 250, 250) !important;
            cursor: grab;
        }
    }
    .toast-error-container {
        color: #000 !important;
        border-radius: 8px !important;
        background: green !important;
        border: 1px solid #34A853 !important;
        box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
      }

    /* -------------------- BUTTON -------------------- */
    .ant-btn {
        border-radius: 3px;
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme?.buttons?.gap ?? '0.5rem'};
        height: ${({ theme }) => theme?.buttons?.height ?? '2.5rem'};
        color: ${({ theme }) => theme?.font?.primary ?? '#536d8f'};
    }

    .ant-btn-primary {
        background-color: ${({ theme }) => theme?.buttons?.colors?.primary?.first ?? '#F27127'};
        border: 1px solid ${({ theme }) =>
            theme?.buttons?.colors?.primary?.border ?? '#E79B09'}!important;
        color: #fff;

        &:hover {
            background-color: ${({ theme }) => theme?.buttons?.colors?.primary?.hover ?? '#F48647'};
            color: #fff;
            border-color: '#F48647'
        }

        &:focus {
            background-color: ${({ theme }) => theme?.buttons?.colors?.primary?.focus ?? '#DE6723'};
            color: #fff;
        }

        &[disabled], &[disabled]:hover {
            color: #9EBCC0;
            background: #E4E8EC;
        }
    }

    .ant-btn-secondary {
        background-color: ${({ theme }) => theme?.buttons?.colors?.secondary?.first ?? '#fff'};
        border: 1px solid ${({ theme }) => theme?.buttons?.colors?.secondary?.border ?? '#2D3D76'};

        &:hover {
            background-color: ${({ theme }) =>
                theme?.buttons?.colors?.secondary?.hover ?? '#036896'};
            color: #fff;
        }

        &:focus {
            background-color: ${({ theme }) =>
                theme?.buttons?.colors?.secondary?.focus ?? '#036896'};
            color: #fff;
        }
    }

    .ant-btn-default {
        background: #ffffff;
        border: 1px solid #2d3d76;
        border-radius: 3px;
        color: #536d8f;

        svg {
            min-height: 15px;
            min-width: 15px;
        }

        &:hover {
            background: #2d3d76;
            border: 1px solid #2d3d76;
            color: #ffffff;
        }
    }

    .ant-btn-background-ghost.ant-btn-primary {
        border-color: ${({ theme }) => theme?.buttons?.colors?.primary?.first ?? ''};
        color: ${({ theme }) => theme?.buttons?.colors?.primary?.first ?? ''};
        background-color: white;
        font-weight: 500;

        &:hover:enabled, &:focus:enabled {
            color: white;
            background-color: ${({ theme }) =>
                theme?.buttons?.colors?.secondary?.first ?? ''} !important;
            border-color: ${({ theme }) =>
                theme?.buttons?.colors?.secondary?.first ?? ''} !important;
        }
    }


    .order-products__wrapper {
        .ant-card-body {
            padding: 12px;
            @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
                padding: 6px ;
            }
        }
    }

    /* -------------------- TABLE HEADER ICON -------------------- */
   .ant-table-thead > tr > .table-column-active {
        background-color: #DCE5EC !important;
    }

    .table-column-selected {
        background-color: #FAFAFA !important;
    }


    .ant-layout-header {
        background: black;
    }

    .ant-layout-sider {
        background: #f5f5f5;
        border-right: 1px solid #c4c4c4;
    }

    .ant-layout-sider-trigger {
        border-top: 1px solid #dbdbdb;
        background: white;
        border-right: 1px solid #c4c4c4;

        span {
            border: 1px solid black;
            border-radius: 4px;
            padding: 0.5rem;
        }

        svg {
            fill: black;
        }
    }

    .ant-tag {
        font-weight: 600;
        border-radius: 4px;
    }

    .ant-menu {
        background: unset;
    }

    .ant-page-header {
        padding: 1rem 0rem;
        margin-bottom: 2rem;

        .ant-page-header-heading-title {
            font-size: 24px;
        }
    }

    .ant-menu-item {
        display: flex;
        align-items: center;

        svg {
            width: 24px;
            height: 24px;
        }
    }

    .ant-menu-inline,
    .ant-menu-vertical,
    .ant-menu-vertical-left {
        border-right: unset;
    }

    .ant-breadcrumb-link {
        font-weight: 500;
    }

    .ant-drawer-content-wrapper {
        height: calc(100% - 64px);
    }

    .ant-radio-inner {
        border-color: #2D3D76 !important;
        border: 2px solid #2D3D76;

    }

    .ant-radio-inner::after {
        background-color: #2D3D76 !important;
    }

    .order-actions-menu, .products-action-menu, .ant-dropdown {
        letter-spacing: 0.4px;

        .ant-btn {
            width: 100%;
            border: 0px;
            font-weight: normal;
            text-align: left;
        }
    }

    .upload-list-inline {
        width: 100%;
    }

    .checkbox-reversed {
        flex-direction: row-reverse;

        span {
            margin-left: 0px;
            padding-left: 0px;
        }
    }

    .show-mobile {
        display: none !important;
        @media (max-width: 768px) {
            display: flex !important;
        }
    }

    .show-desktop {
        @media (max-width: 768px) {
            display: none !important;
        }
    }

    .switch-theme-changer {
        background: #212f3c;

        .ant-switch-handle {
            &:before {
                background: white;
            }
        }

        svg {
            margin-top: 3px;
            width: 16px;
            height: 16px;
        }

        &.ant-switch-checked {
            background: #f0f2f5;

            .ant-switch-handle {
                &:before {
                    background-color: #212f3c;
                }
            }

            svg {
                fill: #212f3c;
            }

        }
    }

    .view-order-tag {
        margin-left: 6px;

        .ant-tag {
            background-color: #1F6373;
            color: white;
        }
    }

    .tag__info {
        background: #F5F5F5;
        border: 1px solid #7998A7;
        width: fit-content;
        color: #2D3D76;
        font-weight: 500;
    }

    /* -------------------- SELECT -------------------- */
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: ${({ theme }) => theme?.select?.colors?.active ?? '#037FB9'} !important;
        color: #fff;
    }

    .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: #D9E8EF;
    }

    .ant-select {
        background: #ffffff;
        border: 1px solid ${({ theme }) => theme?.select?.colors?.border ?? '#2D3D76'};
        border-radius: 3px;
        /* font-weight: 500; */
        /* height: 2.5rem;
        width: 12rem; */
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background: none !important;    }

    .ant-select-item-option-state {
        display: none;
    }

    .ant-select-dropdown {
        width: fit-content !important;
    }

    .ant-steps-item-title {
        font-weight: 500;
        letter-spacing: 0.4px;
    }

    .ant-input[disabled] {
        color: rgba(0, 0, 0, 0.8);
    }

    .ant-drawer-body {
        padding: 0.6rem;

        .ant-card {
            border: 0px;

            .ant-card-head {
                padding: 0 0.6rem;
            }

            .ant-card-body {
                padding: 1rem 0.6rem;
            }
        }

    }

    .ant-card {
        height: 100%;
        border: 0px;
        border: 1px solid #DCE5EC;

        .ant-card-head-title {
            font-size: 0.9rem;
            font-weight: 500;
            color: #043540;
        }
    }

    .ant-avatar-circle {
        flex: 0 0 auto;
        border-color: transparent !important;
    }

    /* -------------------- MODAL -------------------- */
    .ant-modal-footer {
        display: flex;
        justify-content: flex-end;
    }

    .ant-modal-mask {
        z-index: 1002;
    }

    .ant-modal-wrap {
        z-index: 1003;
    }

    /* -------------------- DROPDOWN -------------------- */
    .ant-dropdown-menu {
        border-radius: 3px;
        border: 1px solid ${({ theme }) => theme?.dropdown?.colors?.border ?? '#2D3D76'};

        &>.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
            background-color: #fff;
        }

        &>.ant-dropdown-menu-item.ant-dropdown-menu-item-active:hover {
            background-color: ${({ theme }) => theme?.dropdown?.colors?.hover ?? '#037FB9'};
        }
    }

    .ant-dropdown-menu-submenu-popup {
        border: none !important;
    }

    .ant-dropdown-menu-item {
        background-color: transparent;

        &:hover {
            // background-color: ${({ theme }) => theme?.dropdown?.colors?.hover ?? '#037FB9'};
            background-color: #037FB9 !important;
            color: #fff !important;
        }
    }

    .ant-dropdown-menu-item-selected {
        // background-color: ${({ theme }) => theme?.dropdown?.colors?.active ?? '#037FB9'};
        // background-color: #037FB9 !important;
        // color: #fff !important;
    }

    .ant-table-filter-dropdown .ant-dropdown-menu {
        max-height: 400px !important;
    }
    
    // para poder poner el scroll en modal de anular items
    .cap-modal-height {
        max-height: 80vh;
        overflow-y: auto;
        padding-bottom: 0; // para que no sobresalga el scroll
    }

    .ant-checkbox .ant-checkbox-inner,
    .ant-select-tree-checkbox .ant-select-tree-checkbox-inner {
        border: 3px solid #2d3d76 !important;
        border-radius: 3px;
    }

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
        background-color: #2d3d76;
    }

    .ant-checkbox .ant-checkbox-inner:focus,
    .ant-checkbox .ant-checkbox-inner:hover {
        border: 3px solid #2d3d76 !important;
        border-radius: 3px !important;
    }

    /* .ant-select-selection-overflow{
        display: none !important;
    } */
    .ant-select-tree-switcher {
        display: none;
    }

    .ant-checkbox-inner::after {
        background-color: #2d3d76;
    }

    .ant-switch {
        background-color: #9EBCC0;
    }

    .ant-switch-checked {
        background-color: #2D3D76;
    }

    /* -------------------- DATE RANGE PICKER -------------------- */
    .dateFilterOrder .ant-picker-cell-inner {
        color: #536D8F !important;
    }

    .dateFilterOrder .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .dateFilterOrder .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .dateFilterOrder .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
        color: white !important;
        background: red !important;
    }

    .dateFilterOrder .ant-picker {
        border: 1px solid #036896;
        border-radius: 5px;
    }

    .dateFilterOrder .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
        visibility: visible !important;
    }

    .dateFilterOrder .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
        visibility: visible !important;
    }

    .dateFilterOrder .ant-picker-panels > *:last-child {
        display: none;
    }

    .dateFilterOrder .ant-picker-panel-container, .ant-picker-footer {
        width: 280px !important;
    }

    .dateFilterOrder .ant-picker-footer-extra > div {
        flex-wrap: wrap !important;
    }

    .dateFilterOrder .ant-picker-range-wrapper {
        position: relative !important;
        bottom: 25px !important;
    }

    .dateFilterOrder .ant-picker-range-arrow {
        display: none !important;
    }

    .dateFilterOrder .ant-picker-dropdown-range {
        padding-top: 0 !important;
    }
    

    .dateFilterOrder .ant-picker-input {
        display: none !important;
    }

    .timePickerHeader {
        display: grid;
        grid-auto-flow: column;
        height: 1px !important;
        padding: 0;
        position: relative !important;
        bottom: 19rem !important;
    }

    .timePickerHeader div {
        text-align: center;
    }

    //  ------------------------------------------------------------------------------------------------------

    /* -------------------- TABS -------------------- */
    .ant-tabs-card {

    }

    .react-datepicker__day--outside-month {
        visibility: hidden;
    }

    .ant-tabs-tab {
        transition: unset;
        color: #7998a7;
        padding: 0.6rem 1rem;
        background: #fff !important;
        letter-spacing: 0.5px;
        height: 47px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #037fb9 !important;
        margin: 0 !important;

        .ant-typography {
            color: #7998a7;
            font-weight: 500;
        }
    }

    .ant-tabs-nav-add {
        border-bottom-color: #037fb9 !important;
        margin-left: 0 !important;
        background-color: #ffffff !important;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 48px;

        svg {
            font-size: 1.2rem;
            color: #7998a7;
            transition: 0.2s;
        }

        &:hover {
            svg {
                color: #2d3d76;
            }
        }
    }

    .ant-tabs-nav {
        margin-bottom: 0 !important;
    }

    .ant-tabs-nav::before {
        border-color: #7998A7 !important;
    }

    .ant-tabs-ink-bar {
        background-color: transparent;
    }

    .ant-tabs-tab-active {
        font-weight: 600 !important;
        border-color: #7998A7 !important;
        background: white;

        box-shadow: 2px 0 15px -10px black;
        z-index: 100;

        border-bottom-color: white !important;

        .ant-tabs-tab-btn {
            .ant-typography {
                color: #036896 !important;
                font-weight: 600 !important;
            }

            text-shadow: unset;
        }
    }

    .ant-tabs-tab + .ant-tabs-tab-active {
        &:last-child {
            box-shadow: unset !important;
        }
    }

    .anticon-swap-right svg {

        display: none;

    }

    // .anticon-clock-circle svg {
    //     color: #036896;
    // }


    #formWebHook .ant-tabs-tab-active {
        background-color: #DCE5EC;
    }

    #formWebHook .ant-tabs-tab b {
        color: #7998A7

    }

    #formWebHook .ant-tabs-tab-active b {
        color: #536D8F;
        padding: 0 10px;
    }

    #formWebHook .ant-tabs-ink-bar {
        background-color: #9EBCC0;
    }

    .trashIconWebHook {
        position: relative;
        right: 8px;
    }

    .ant-typography {
        color: #41515E;
    }


    .ant-typography-copy {
        position: relative;
        top: 4px
    }

    .nombreIntegracionEdicion {
        position: relative;
        left: -30px;
    }

    .boxProfileForm {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;

    }

    .OnlyerrorIconFormUser {
        position: absolute;
        top: 36%;
        right: 10%;
        z-index: 1;
    }

    .errorIconFormUser {
        position: absolute;
        top: 27%;
        right: 10%;
        z-index: 1;

    }

    .boxProfileForm div {
        /* flex-basis:20% !important; */
        justify-content: center;
        margin: 5px 0;
    }

    .alerts-border-placeholder {
        border: 3px solid transparent;
        border-radius: 1rem;
        padding: 1rem;
    }

    @keyframes blink {
        // blink border and background with only opacity
        0% {
            opacity: 0.4;
        }

        50% {
            opacity: 1;
        }

        100% {
            opacity: 0.4;
        }
    }

    .alerts-border {
        border: 2px solid #2D3D76 !important;
        border-radius: 6px;
        animation: blink 0.3s;
        animation-iteration-count: 10;
    }

    .ant-select-selector,
    select {
        height: 100% !important;
        border: none !important;
        display: flex;
        align-items: center;
    }

    .ant-select-selection-item {
        /* font-weight: 500; */
        /* color: #536d8f !important; */
        border: none !important;
    }


    /* -------------------- INPUT -------------------- */
    .ant-input {
        /* width: 15rem; */
        border: 1px solid ${({ theme }) => theme?.select?.colors?.border ?? '#2D3D76'};
    
        &:focus-within {
            border: 2px solid #2d3d76;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        }
    
        &:focus {
            border: 2px solid #2d3d76;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        }

        &::placeholder {
            color: #536D8F;
        }

    }

    .ant-select-selector {
        font-size: 12px;
        color: #2D3D76;
        &::placeholder {
            color: #2D3D76;
        }
    }

    .ant-modal-body {
        padding: 16px 24px
    }
    
    .ant-picker-input>input {
        font-size: 14px;
        &::placeholder {
            color: #2D3D76;
        }
    }    

    .ant-picker-focused, .ant-picker:hover {
        border: 1px solid #2D3D76;
    }

    .ant-picker {
        border: 1px solid #2D3D76;
    }
    .anticon-calendar {
        color: #2D3D76;
        > svg {
            width: 18px;
            height: 20px;
        }
    }

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
        border: 1px solid ${({ theme }) => theme?.select?.colors?.border ?? '#2D3D76'};
    }
    .ant-input-affix-wrapper  {
        .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
            border: 1px solid ${({ theme }) => theme?.select?.colors?.border ?? '#2D3D76'};
        }
        & .ant-input {
            border: none !important;
            min-height: 36px !important;
        }
            border: 1px solid ${({ theme }) => theme?.select?.colors?.border ?? '#2D3D76'};
        &:hover {
            border: 1px solid ${({ theme }) => theme?.select?.colors?.border ?? '#2D3D76'};
        }
        &:focus-within {
            border: 1px solid #2d3d76;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        }
    
        &:focus {
            border: 1px solid #2d3d76;
            box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        }
    }

    .ant-select-arrow {
        color: #F27127;
    }

    .ant-form-item-required {
        color: #142C4A !important;
    }
    .ant-form-item-required::before {
        color: #142C4A !important;
        display: none !important;
    }

    .ant-checkbox-inner::after {
        background-color: #2D3D76;
    }

    .ant-input-number-group-addon {
        color: #2D3D76;
        border-color: #7998A7;
        background-color: #F5F5F5;
    }

    .ant-input-number-handler-wrap {
        background-color: white;
    }

    .ant-input-number-handler-up-inner svg {
        color: #536D8F !important;
        stroke: white;
        width: 24px;
        height: 24px;
        transition: transform 250ms ease-in-out;
        filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
    }

    .ant-input-number-handler-down-inner svg {
        color: #536D8F !important;
        stroke: white;
        width: 24px;
        height: 24px;
        transition: transform 250ms ease-in-out;
        filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
    }

    .ant-select-label-secondary {
        & .ant-form-item-required {
            font-size: 14px !important;
            color: #2D3D76 !important;
        }
        
    }

    .ant-form-item-explain, ant-form-item-explain-connected {
        margin-top: 8px;
        font-size: 12px;
        color: #536D8F
    }

    .anticon-upload svg {
        color: #2D3D76 !important;
        stroke: white;
        width: 22px;
        height: 22px;
        transition: transform 250ms ease-in-out;
        // filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
    }

    input,
    select,
    .ant-select-selection-placeholder {
        color: #2D3D76 !important;
    }

    .ant-radio-inner {
        border-color: #2D3D76 !important;

        &:after {
            background-color: #2D3D76 !important;
        }
    }

    .ant-input,
    .ant-select-selector,
    .ant-input-number {
        border-style: solid;
        border-color: ${({ theme }) => theme?.inputs?.colors?.border?.initial ?? ''};

        &:active {
            border-color: ${({ theme }) => theme?.inputs?.colors?.border?.active ?? ''};
        }

        &:focus {
            border-color: ${({ theme }) => theme?.inputs?.colors?.border?.focus ?? ''};
        }

        &:valid {
            border-color: ${({ theme }) => theme?.inputs?.colors?.border?.done ?? ''};
        }
    }

    /* -------------------- SIDEBAR -------------------- */
    .sidebar__opened, .sidebar__closed {
        transition: 0.2s;
    }

    .sidebar-menu__item, .sidebar-menu__item-submenu {
        color: #142C4A !important;
    }

    .sidebar-menu__title {
        color: #142C4A !important;
    }

    .sidebar-menu__item {
        padding: 0.5rem 1.2rem;
        + .isCollapsed {
            br {
                display: none;
            }
        }
    }

    .sidebar-submenu__active {
        color: #F27127 !important;
        font-weight: 700;
        span {
            color: #142C4A !important;
        }
    }



    .sidebar-menu__active {
        color: #F27127 !important;
        font-weight: 700;
        position: relative; /* Agregado */
        width: 100%;
        span {
            color: #142C4A !important;
        }

        :not(.isSubmenu) {
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0; 
                bottom: 0; 
                width: 4px;
                background-color: #F27127;
                border-radius: 0px 5px 5px 0px;
            }
        }
    }

    .icon-chevron {
        transition: 0.2s;
        transform: rotate(180deg);
        :not(.isCollapsed) {
            transform: rotate(0deg);
        }
    }
    

    .sidebar-menu__active.withSubmenu::before {
        height: 40px;
    }


 
        .sidebar-menu__icon-active { 
        
                fill: #F27127;
         
        }
 

   
        .sidebar__submenu_icon-active{ 
         
                fill: #F27127 !important;
        
        }


    .sidebar__menu {
        position: relative;
        z-index: 1110;
    }

    .sidebar__chevron-button {
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
        user-select: none;
        display: flex;
        height: 24px;
        width: 24px;
        position: absolute;
        z-index: 1111;
        background: #142C4A;
        border-radius: 50%;
        top: 80px;
        left: calc(100% - 14px);
        cursor: pointer;
        place-items: center;
        place-content: center;

        svg {
            stroke: white;
            width: 18px;
            height: 18px;
            transition: transform 250ms ease-in-out;
            filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.4));
        }

        &.active {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
        color: #536D8F;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        .sidebar__menu {
            max-width: 200px;
        }
    }

    .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover, .ant-dropdown-menu-item.ant-dropdown-menu-item-active, .ant-dropdown-menu-item.ant-dropdown-menu-submenu-title-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-item-active, .ant-dropdown-menu-submenu-title.ant-dropdown-menu-submenu-title-active {
        background-color: #fff;
    }

    .ant-dropdown-menu-item:hover {
        background-color: #f5f5f5;
    }
    .ant-modal-title {
        color: #2D3D76;
        font-weight: 500;
    }


    .ant-checkbox-disabled+span {
        color: #142C4A;
    }
    
    .ant-checkbox-wrapper-disabled {
        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #9EBCC0 !important; 
            border: 2px solid #9EBCC0 !important;
            ::after {
            background-color: #9EBCC0 !important; 
            } 
        }
    }

    .ant-radio-wrapper, .ant-checkbox-wrapper {
        > span {
            font-weight: 400
        }
    }
    
    .ant-picker-content > thead > tr > th {
        color: #536D8F;
        font-style: normal;
    }


    .ant-picker-panel {
    .ant-picker-footer {
        width: fit-content !important;
    }
  
    .rc-virtual-list {
        width: 240px !important;
    }
   
    .ant-picker-time-panel {
        .ant-picker-content {
            .ant-picker-time-panel-column {
                width: 100px;

                &::before {
                    color: #536D8F;
                    padding: 14px;
                    height: 20px;
                }

                &:nth-child(1)::before {
                    content: 'Hora';
                }

                &:nth-child(2)::before {
                    content: 'Minuto';
                    position: sticky;
                }

                &:nth-child(3)::before {
                    content: 'Segundo';
                }
            }
        }
    }
}
  
   


    .ant-picker-super-prev-icon, .ant-picker-prev-icon, .ant-picker-next-icon, .ant-picker-super-next-icon {
        color: #1F2937!important;
    }

    .ant-picker-month-btn, .ant-picker-year-btn {
        color: #1F2937!important;
        font-style: normal!important;
        font-weight: 500!important;
    }

    .ant-picker-cell-inner {
        color: #C4C4C4;
        font-style: normal!important;
        font-weight: 300!important;
    }

    .ant-picker-cell-in-view > .ant-picker-cell-inner {
        color: #536D8F!important;
    }

    .ant-picker-cell-range-end > .ant-picker-cell-inner, .ant-picker-cell-range-start > .ant-picker-cell-inner {
        background-color: #0497db!important;
        color: #f5f5f5!important;
        font-style: normal!important;
        font-weight: 400!important;
    }

    .ant-picker-cell-in-range > .ant-picker-cell-inner, .ant-picker-cell-in-range::before, .ant-picker-cell-range-start::before, .ant-picker-cell-range-end::before {
        background-color: #E4E8EC!important;
        color: #536D8F!important;
    }

    .ant-picker-cell-today > .ant-picker-cell-inner::before {
        border: 1px solid #0497db!important;

    }

    .ant-picker-large .ant-picker-input>input {
        font-size: 12px;
        font-weight: 500;
        color: #2D3D76;
    }

    .ant-picker-disabled {
        background: #fff!important;
        border-color: #2D3D76!important;
    }

    .react-datepicker-popper {
        z-index: 2;
    }
    .react-datepicker-custom {
        font-family: 'Poppins';
        padding: 0.5rem;
        border: none;
        .react-datepicker__header {
            background-color: transparent;
            padding: 0;
            border-bottom: none;
        }
        .react-datepicker__day-name {
            color: #536D8F;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            
        }
        .react-datepicker__day {
            color: #536D8F;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
        }
        .react-datepicker__day--today {
            color: #1F2937;
            border: 1px solid #2d3d76;
            border-radius: 3px;
        }
        .react-datepicker__day--in-range {
            border: none;
            border-radius: 0px!important;
            background-color: #DFDFDF;
            color: #536D8F;
            position: relative;
            ::before {
                background-color: #DFDFDF;
                content: '';
                height: 27.5px;
                width: 9px;
                position: absolute;
                left: -9px
            }
        }
        .react-datepicker__day--in-range:hover {
            background-color: #E4E8EC;
        }
        .react-datepicker__day--range-start {
            border-top-left-radius: 3px!important;
            border-bottom-left-radius: 3px!important;
            ::before {
                display: none;
                content: '';
            }
        }
        .react-datepicker__day--range-end {
            border-top-right-radius: 3px!important;
            border-bottom-right-radius: 3px!important;
        }
        .react-datepicker__day--range-start, .react-datepicker__day--range-end {
            background-color: #037fb9!important;
            color: #F5F5F5;
        }
        .react-datepicker__day--in-selecting-range {
            border-radius: 0px!important;
            border: none;
            background-color: #E4E8EC;
            color: #536D8F;
            position: relative;
            ::before {
                background-color: #E4E8EC;
                content: '';
                height: 27.5px;
                width: 9px;
                position: absolute;
                left: -9px
            }
        }
        
        .react-datepicker__day--selecting-range-start {
            border-top-left-radius: 3px!important;
            border-bottom-left-radius: 3px!important;
            ::before {
                display: none;
                content: '';
            }
        }

        .react-datepicker__day--selecting-range-end {
            border-top-right-radius: 3px!important;
            border-bottom-right-radius: 3px!important;
        }

        .react-datepicker__day--selecting-range-start, .react-datepicker__day--selecting-range-end, .react-datepicker__day--keyboard-selected:hover {
            background-color: #1A9BD8;
            border-radius: 4px;
            color: #F5F5F5;
        }
    }

    .fulfillment-sub-table {
        .ant-table-cell-row-hover {
            background-color: #ffffff !important;
        }
        > :first-child {
            border-left: 8px solid #f5f5f5;
        }
       > td {
            border-bottom: 0px !important; 
       }
    }

    .fulfillment-selected {
        .ant-table-cell {
            background-color: #f5f5f5 !important;
        }
    
    }
    .select-style-v2 {
        .ant-select-selection-item {
            border: 1px solid #2D3D76 !important; 
            border-radius: 9px !important;
            height: 20px;
            display: flex;
            align-items: center;
            color: #2D3D76;
            font-weight: 500;
        }

        .ant-select-selection-item-remove {
            margin-left: 5px;
        }
    }

    .text-s {
        font-size: 12.8px;
    }

    .label-style {
        .ant-form-item-label {
            label {
                color: #142C4A;
            }
       }
    }

    .tag-activate {
        transition: 0.2s;
    }

    .tag-activate:hover {
        color: #ffffff !important;
        background-color: #F27127 !important;
        border: 1px solid transparent !important;
        > svg {
            fill: #ffffff !important;
        }
    }

`;
//037fb9
export default GlobalStyles;
