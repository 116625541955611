import { Button } from '@andescommercecloud/beflow-design-system';
// import { Button } from 'antd';
import styled from 'styled-components';

export const ActionsButton = styled(Button)`
    color: #ffffff;
    background: #f27127;
    border: 1px solid #f27127;
    border-radius: 3px;
    padding: 5px 16px;
    gap: 6px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 35px;
`;

export const ActionsWrapper = styled.div`
    position: absolute;
    top: 60px;
`;

export const TitleModalCancelOrder = styled.div`
    font-size: 18px;
    color: #2d3d76;
    font-weight: 700;
`;
