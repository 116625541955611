import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import { deleteIntegrationFromStore } from './getAllIntegration';
import toast from 'react-hot-toast';

export const deleteIntegration = createAsyncThunk(
    'integrations/delete',
    async (integrationToDelete, state) => {
        try {
            const deleteIntegrationForID = async (id) => {
                const res = await AndesClient.delete(`/integrations/delete/${id}`);
                if (res.status === 200) {
                    state.dispatch(deleteIntegrationFromStore(id));
                    toast.success('Integración/es eliminada/s con éxito.');
                }
                return res.data;
            };

            if (typeof integrationToDelete === 'string') {
                await deleteIntegrationForID(integrationToDelete);
            }

            if (Array.isArray(integrationToDelete)) {
                for await (const id of integrationToDelete) {
                    await deleteIntegrationForID(id);
                }
            }
            return state.fulfillWithValue(true);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const deleteIntegrationSlice = createSlice({
    name: 'deleteIntegrations',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [deleteIntegration.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [deleteIntegration.fulfilled]: (state) => {
            return {
                ...state,
                success: true,
                loading: false,
            };
        },
        [deleteIntegration.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default deleteIntegrationSlice.reducer;
