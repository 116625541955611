import ProblemProduct from './ProblemProduct';
import { Checkbox } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import useActionToggleDrawer from 'hooks/useActionToggleDrawer';
import { useSelector } from 'react-redux';

const ProblemProducts = ({
    setLineItemsSelected,
    isShareWarehouse,
    setIsShareWarehouse,
    isCloseIncidenceForm,
    setIsCloseIncidenceForm,
    fulfillment = {},
}) => {
    //Obtenemos los line items seleccionados de la TABLA (no del drawer)
    // const [line_items, line_items_ids] = useGetLineItemsOfFulfillment();

    const { selectedRows } = useSelector((state) => state.utils);
    const tableName = 'scanned-codes-table';

    const tableSelectedRows = selectedRows[tableName] || [];

    //Definimos estados de checkboxes
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);
    const [line_items, setLineItems] = useState([]);
    const [line_items_ids, setLineItemsIds] = useState([]);

    useEffect(() => {
        if (Array.from(tableSelectedRows ?? []).length > 0) {
            const { line_items = [] } = fulfillment;
            setLineItems(line_items.filter((li) => tableSelectedRows.includes(li.line_item._id)));
            setLineItemsIds(tableSelectedRows);
        }
    }, [tableSelectedRows, fulfillment]);

    //Definimos logica de checkboxes
    const onCheckboxGroupChange = (list) => {
        if (line_items && line_items[0]) {
            setCheckedList(list);
            setIndeterminate(!!list.length && list.length < line_items.length);
            setCheckAll(list.length === line_items.length);
        }
    };

    const onCheckboxAllChange = (e) => {
        if (line_items && line_items[0]) {
            setCheckedList(e.target.checked ? line_items.map((li) => li.line_item._id) : []);
            setIndeterminate(false);
            setCheckAll(e.target.checked);
        }
    };

    // Funciones de utilidad
    const uncheckAllCheckboxes = () => {
        setCheckedList([]);
        setIndeterminate(false);
        setCheckAll(false);
    };

    //Cambiamos la data de los line_items segun cambien los seleccionados
    useEffect(() => {
        setLineItemsSelected(line_items.filter((li) => checkedList.includes(li.line_item._id)));
    }, [checkedList]);

    // Desmarcamos todos los checkboxes si isShareWarehouse es "no"
    useEffect(() => {
        if (isShareWarehouse === 'no') {
            uncheckAllCheckboxes();
            setIsShareWarehouse('none');
        }
    }, [isShareWarehouse]);

    // Desmarcamos todos los checkboxes si isCloseIncidenceForm es true (si se cierra el formulario de incidencia)
    useEffect(() => {
        if (isCloseIncidenceForm === true) {
            uncheckAllCheckboxes();
            setIsShareWarehouse('none');
            setIsCloseIncidenceForm(false);
        }
    }, [isCloseIncidenceForm]);

    // Cada vez que se cierra el drawer, este se limpia
    const [,] = useActionToggleDrawer(
        () => {
            uncheckAllCheckboxes();
            setIsShareWarehouse('none');
            setIsCloseIncidenceForm(false);
        },
        'manual-incidence-drawer',
        false
    );
    // Cada vez que se abre el drawer, se marcan todos los checkboxes por default
    const [,] = useActionToggleDrawer(
        () => {
            if (line_items_ids && line_items_ids[0]) {
                setCheckedList(line_items_ids);
                setIndeterminate(false);
                setCheckAll(true);
            }
        },
        'manual-incidence-drawer',
        true
    );

    return (
        <>
            <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckboxAllChange}
                checked={checkAll}
            >
                PRODUCTOS CON PROBLEMAS ({checkedList.length}/{line_items && line_items.length})
            </Checkbox>
            <Checkbox.Group onChange={onCheckboxGroupChange} value={checkedList} className="w-full">
                {line_items &&
                    line_items.map((line_item, index) => (
                        <ProblemProduct
                            key={index}
                            lineItem={line_item.line_item}
                            quantity={line_item.quantity}
                        />
                    ))}
            </Checkbox.Group>
        </>
    );
};

export default ProblemProducts;
