import styled from 'styled-components';
import { Skeleton } from 'antd';
import { getFieldLabel, getConditionLabel, getValueLabel } from 'helpers/getLabels';
import { ReactComponent as RemoveIcon } from 'assets/remove-minus-icon.svg';

const InfoFilterStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    gap: 15px;
    border-radius: 15px;
    background-color: #1d71b5;
    color: #ffffff;
    font-size: 13px;

    svg {
        cursor: pointer;
    }
`;

const SkeletonStyled = styled(Skeleton.Input)`
    border-radius: 10px;
`;

const InfoFilter = ({
    filter = {},
    onDelete,
    showDeleteIcon = false,
    loading = false,
    preferences,
    valueOptionByField,
}) => {
    const { andOr, field, condition, value } = filter;

    if (loading) {
        return <SkeletonStyled active size="small" />;
    }

    if (Object.keys(filter).length === 0) {
        return (
            <InfoFilterStyled style={{ backgroundColor: '#E4E8EC', color: '#536D8F' }}>
                <div className="info-filter_text">No hay filtros asociados</div>
            </InfoFilterStyled>
        );
    }

    console.log({ preferences, field, condition, value, valueOptionByField });

    return (
        <InfoFilterStyled>
            <div className="info-filter_text">
                {andOr && (andOr === 'and' ? 'Y' : 'O')}{' '}
                {field && getFieldLabel(preferences, field)}{' '}
                {condition && getConditionLabel(condition)}{' '}
                {['includes', 'not_include'].includes(condition) && value?.length > 0
                    ? `(${
                          value &&
                          getValueLabel(preferences, field, condition, value, valueOptionByField)
                      })`
                    : value &&
                      getValueLabel(preferences, field, condition, value, valueOptionByField)}
            </div>
            {filter.isRemovable && showDeleteIcon && (
                <RemoveIcon
                    onClick={() => onDelete(filter.id)}
                    color="#ffffff"
                    width={15}
                    height={15}
                    className="delete_condition_button"
                />
            )}
        </InfoFilterStyled>
    );
};

export default InfoFilter;
