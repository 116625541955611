import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getAllSalesChannel = createAsyncThunk('salesChannel/getAll', async (_, state) => {
    try {
        const res = await AndesClient.get(`/settings/sales-channels`);
        return res.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getAllSalesChannelSlice = createSlice({
    name: 'getAllSalesChannel',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
        channelsThatAShopWasEliminated: [],
    },
    reducers: {
        addSalesChannelToStore: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                data: [...state.data, payload],
            };
        },
        deleteSalesChannelFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.filter(({ _id }) => _id !== payload);
            return {
                ...state,
                data: [...newArrayData],
            };
        },
        updateSalesChannelFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.map((salesChannel) => {
                if (salesChannel._id === payload._id) {
                    return {
                        ...salesChannel,
                        ...payload,
                    };
                } else {
                    return salesChannel;
                }
            });
            return {
                ...state,
                data: [...newArrayData],
            };
        },
    },
    extraReducers: {
        [getAllSalesChannel.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getAllSalesChannel.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [getAllSalesChannel.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});
export const {
    addSalesChannelToStore,
    deleteSalesChannelFromStore,
    updateSalesChannelFromStore,
    removeShopFromSalesChannel,
} = getAllSalesChannelSlice.actions;
export default getAllSalesChannelSlice.reducer;
