import { createSlice } from '@reduxjs/toolkit';

const getPermissionsOfCurrentUserSlice = createSlice({
    name: 'getPermissionsOfCurrentUser',
    initialState: [],
    reducers: {
        initGetPermissionsOfCurrentUser: (state, { payload }) => {
            return payload;
        },
    },
});

export const { initGetPermissionsOfCurrentUser } = getPermissionsOfCurrentUserSlice.actions;
export default getPermissionsOfCurrentUserSlice.reducer;
