let parsedProcess = {};

if (typeof process === 'undefined') {
    let api_url = 'https://api-dev.be-flow.com';
    let stage = 'dev';
    let title = 'Be Flow';

    let hostname = window.location.hostname;

    if (hostname === 'demo.be-flow.com') {
        api_url = 'https://api-demo.be-flow.com';
        stage = 'demo';
    }

    if (['oms.be-flow.com', 'v2.be-flow.com', 'pre-release.be-flow.com'].includes(hostname)) {
        api_url = 'https://api.be-flow.com';
        stage = 'prod';
    }

    parsedProcess = {
        env: {
            REACT_APP_API_URL: api_url,
            REACT_APP_STAGE: stage,
            REACT_APP_TITLE: title,
        },
    };
} else {
    // eslint-disable-next-line no-undef
    parsedProcess = process;
}

const getFromEnv = (key) => {
    return parsedProcess?.env?.[key] ?? null;
};

export default getFromEnv;
