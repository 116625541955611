import Auth from '@aws-amplify/auth';
import returnObj from 'helpers/returnObj';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLogged } from 'redux/features/Users/getUserLogged';

const useCurrentUser = () => {
    const [user, setUser] = useState({});

    const dispatch = useDispatch();

    const { data } = useSelector((state) => state.userLogged);

    const getUserData = async () => {
        const usr = await Auth.currentSession();
        const payload = usr.idToken.payload;

        localStorage.setItem('merchantDecimalSupport', payload?.merchant_decimal_support ?? false);
        localStorage.setItem('merchantCountry', payload?.merchant_country);
        localStorage.setItem('merchantCurrency', payload?.merchant_currency);
        localStorage.setItem('merchantLanguage', payload?.merchant_general_language);

        const cognitoUserName = payload['cognito:username'];

        if (!data.username || data.username !== cognitoUserName) {
            dispatch(getUserLogged(cognitoUserName));
        }

        setUser(payload);
    };

    useEffect(() => {
        getUserData();
    }, []);

    return {
        cognitoUser: returnObj(user),
        userData: returnObj(data),
    };
};

export default useCurrentUser;
