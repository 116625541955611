import styled from 'styled-components';

export const IncidenceContainer = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    padding: 10px;
    width: 100%;
`;
export const Label = styled.span`
    font-weight: 400

    line-height: 26px;
    display: flex;
    align-items: center;
    color:#142c4a;
    font-size: ${({ fontSize }) => fontSize ?? '12px'}; 
    margin-bottom:  ${({ marginBottom }) => marginBottom ?? '8px'};
    margin-top:  ${({ marginTop }) => marginTop ?? '0px'};
    justify-content: ${({ justifyContent }) => justifyContent ?? 'left'};
`;
