import { useSelector, useDispatch } from 'react-redux';
import Drawer from 'components/Drawer/index';
import CurrentIncidences from './CurrentIncidence';
import { toggleDrawer } from 'redux/features/Utils';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { SubTitleShowDetailsDrawer } from './styled';
import { HeaderManualIncidenceDetailsOrderDrawer } from './HeaderManualIncidenceDetailsOrderDrawer';
import { useLazyGetManualIncidencesQuery } from 'redux/features/ManualIncidences';
import { Fragment, useEffect, useMemo } from 'react';
import { SkeletonIncidence } from './SkeletonIncidence';
import { ProblemOrderDetailsDrawer } from './ProblemOrderDetailsDrawer';

const DetailsIncidenceDrawerOrder = ({
    name,
    drawerName = 'manual-incidence-details-order-drawer', // el name puede ser este (por defecto) o puede ser otro si es invocado desde una tabla
}) => {
    const dispatch = useDispatch();
    const { orderData, fulfillmentData } = useSelector(
        (state) => state.detailsManualIncidenceOrderDrawer
    );
    const [getManualIncidences, { data, isLoading, isFetching }] = useLazyGetManualIncidencesQuery({
        order_id: orderData?._id,
    });

    useEffect(() => {
        if (!orderData?._id) return;
        getManualIncidences({
            ...(fulfillmentData
                ? {
                      fulfillment_id: fulfillmentData?._id,
                  }
                : {
                      order_id: orderData._id,
                  }),
        });
    }, [orderData, fulfillmentData]);

    const closeDrawer = () => {
        dispatch(toggleDrawer(drawerName));
    };

    const renderTitle = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <HeaderManualIncidenceDetailsOrderDrawer />
            <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={closeDrawer}>
                <CloseIcon style={{ color: '#2D3D76', width: '14px', height: '14px' }} />
            </div>
        </div>
    );

    const itemLength = useMemo(() => {
        if (!data?.length) return 0;

        return data?.reduce((itemLength, manualIncident) => {
            if (manualIncident.status === 'open') itemLength += manualIncident?.line_items?.length;

            return itemLength;
        }, 0);
    }, [data]);

    return (
        <Drawer
            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
            contentWrapperStyle={{ boxShadow: 'none' }}
            drawerProps={{
                title: renderTitle(),
                name: drawerName,
                width: '457px',
                dontShowFooter: true,
                maskClosable: true,
                destroyOnClose: true,
                loading: isLoading || isFetching,
            }}
        >
            <SubTitleShowDetailsDrawer>
                Pedidos seleccionados con incidencia ({'1'})
            </SubTitleShowDetailsDrawer>
            {isLoading || isFetching ? (
                <SkeletonIncidence />
            ) : (
                <Fragment>
                    {!!data?.length && name !== 'header_order_v2' && orderData && (
                        <ProblemOrderDetailsDrawer
                            itemLength={itemLength}
                            nameOrder={orderData?.name}
                            totalPrice={orderData?.total_price}
                        />
                    )}
                    {(data ?? []).map((mi) => (
                        <CurrentIncidences incidence={mi} key={mi?._id} />
                    ))}
                </Fragment>
            )}
        </Drawer>
    );
};

export default DetailsIncidenceDrawerOrder;
