import Flex from 'components/Utils/Flex';
import { useEffect } from 'react';
import { useState } from 'react';
import { BiMinus, BiPlus } from 'react-icons/bi';
import { useUpdateStockMutation } from 'redux/features/Stock';
import styled from 'styled-components';

const StockInputWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 34px);
    max-width: 136px;
    grid-template-rows: 36px;
`;

const SaveStockButton = styled.button`
    background: #ffffff;
    border: 1px solid #2d3d76;
    border-radius: 3px;
    padding: 2px 16px;
    font-weight: 500;
    &:disabled {
        background: #e5e5e5;
        opacity: 0.8;
    }
`;

const StockInputBox = styled.div`
    background-color: white;
    border: 1px solid #9ebcc0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;

    input,
    button {
        width: 100%;
        height: 100%;
        border: none;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type='number'] {
        -moz-appearance: textfield;
    }

    svg {
        color: #037fb9;
    }

    &:nth-child(1) {
        border-radius: 3px 0px 0px 3px;
    }
    &:nth-child(2) {
        border: 1px solid #9ebcc0;
    }
    &:nth-child(3) {
        border-radius: 0px 3px 3px 0px;
    }
`;

const StockInputs = ({ product_id, location_id, quantity }) => {
    const [stockQuantity, setStockQuantity] = useState(0);

    const [updateStock, updateStockStatus] = useUpdateStockMutation();

    useEffect(() => {
        if (quantity) {
            setStockQuantity(quantity);
        }
    }, [quantity]);

    const handleStockQuantity = async () => {
        const type =
            stockQuantity === quantity ? 'SET' : stockQuantity > quantity ? 'INCREASE' : 'DROP';
        const params = {
            product_id,
            quantity: +Math.abs(Number(stockQuantity - quantity)),
            location_id,
            type,
        };

        await updateStock(params);
        setStockQuantity(stockQuantity === 0 ? 0 : quantity);
    };

    const isDisabled = updateStockStatus.isLoading;

    return (
        <Flex direction="row" width="240px" columnGap="10px" alignItems="center">
            <StockInputWrapper>
                <StockInputBox>
                    <button
                        type="button"
                        disabled={isDisabled}
                        onClick={() => {
                            setStockQuantity(Number(stockQuantity - 1));
                        }}
                    >
                        <BiMinus />
                    </button>
                </StockInputBox>
                <StockInputBox>
                    <input
                        min={0}
                        type="number"
                        disabled={isDisabled}
                        value={stockQuantity}
                        onChange={(e) => setStockQuantity(Number(e.target.value))}
                    />
                </StockInputBox>
                <StockInputBox>
                    <button
                        type="button"
                        disabled={isDisabled}
                        onClick={() => {
                            setStockQuantity(Number(stockQuantity + 1));
                        }}
                    >
                        <BiPlus />
                    </button>
                </StockInputBox>
            </StockInputWrapper>
            {quantity !== stockQuantity && (
                <SaveStockButton
                    role="button"
                    onClick={() => handleStockQuantity()}
                    disabled={isDisabled}
                >
                    Guardar
                </SaveStockButton>
            )}
        </Flex>
    );
};

export default StockInputs;
