import { Form, Modal, Select } from 'antd';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';
import { ReactComponent as ShoppingBagSVG } from 'assets/shopping-bag.svg';
import { ReactComponent as TruckSVG } from 'assets/truck.svg';
import Flex from 'components/Utils/Flex';
import FormStyled from 'components/Utils/Form';
import useAllCouriers from 'hooks/useAllCouriers';
import { Fragment, useEffect, useState } from 'react';
import useCountryData from '../../../../hooks/useCountryData';
import { OptionButton } from './styles';

const DeliveryConfigModal = ({ isOpen, onClose, onSubmit }) => {
    const activeDirectionColombia = useGetValueFeatureFlag('direction_colombia');
    const [selectedDeliveryType, setSelectedDeliveryType] = useState('');
    const [form] = Form.useForm();
    const [, data] = useAllCouriers();
    const couriers = data ?? [];

    const countryData = useCountryData();

    const handleSubmit = () => {
        const values = {
            ...form.getFieldsValue(),
            delivery_method: selectedDeliveryType,
        };
        onSubmit && onSubmit(values);
    };

    useEffect(() => {
        if (selectedDeliveryType === 'pickup') {
            handleSubmit();
        }
    }, [selectedDeliveryType]);

    return (
        <Modal
            title="Configuración de entrega"
            cancelText="Volver"
            okText="Confirmar"
            destroyOnClose={true}
            onOk={() => form.submit()}
            open={isOpen}
            onCancel={onClose}
            {...(selectedDeliveryType !== 'shipping' && { footer: null })}
        >
            <FormStyled form={form} onFinish={handleSubmit}>
                <Flex direction="column" rowGap="1rem">
                    <Fragment>
                        <span>Debes seleccionar un tipo de entrega para continuar:</span>
                        <Flex direction="row" columnGap="2rem">
                            <OptionButton
                                type="primary"
                                style={{ flex: 1, height: 'auto' }}
                                onClick={() => setSelectedDeliveryType('shipping')}
                                isActive={selectedDeliveryType === 'shipping'}
                            >
                                <TruckSVG />
                                <b className="mt-3 mb-2">Entrega a domicilio</b>
                            </OptionButton>

                            <OptionButton
                                type="primary"
                                style={{ flex: 1, height: 'auto' }}
                                onClick={() => setSelectedDeliveryType('pickup')}
                                isActive={selectedDeliveryType === 'pickup'}
                            >
                                <ShoppingBagSVG />
                                <b className="mt-3 mb-2">Retiro en tienda</b>
                            </OptionButton>
                        </Flex>
                    </Fragment>

                    {selectedDeliveryType === 'shipping' && (
                        <>
                            <br />
                            <span>
                                Elige la <b>región</b> y el <b>courier</b> que se encuentra{' '}
                                <b>asociado a la entrega de los pedidos:</b>
                            </span>
                            <Flex direction="row" columnGap="1rem">
                                <Form.Item
                                    name="province"
                                    style={{ flex: 1, height: '44px' }}
                                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                                >
                                    <Select
                                        placeholder={`Seleccione ${countryData?.provinceLabel.toLowerCase()} de entrega`}
                                    >
                                        {activeDirectionColombia &&
                                        countryData.country === 'colombia' ? (
                                            <Select.Option value="bogota d.c.">
                                                Bogotá D.C.
                                            </Select.Option>
                                        ) : (
                                            <Select.Option value="REGION METROPOLITANA">
                                                {countryData?.capitalName}
                                            </Select.Option>
                                        )}
                                        <Select.Option value="OTHER">
                                            {countryData?.provinceLabelPluralOther}{' '}
                                            {countryData?.provinceLabelPlural.toLowerCase()}
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="courier"
                                    style={{ flex: 1, height: '44px' }}
                                    rules={[{ required: true, message: 'Este campo es requerido' }]}
                                >
                                    <Select
                                        placeholder="Seleccione courier"
                                        disabled={couriers.length === 1}
                                    >
                                        {couriers.map((courier) => (
                                            <Select.Option key={courier._id} value={courier._id}>
                                                {courier?.public_name ?? courier?.courier_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Flex>
                        </>
                    )}
                </Flex>
            </FormStyled>
        </Modal>
    );
};

export default DeliveryConfigModal;
