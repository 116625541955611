import styled from 'styled-components';
import { Select } from 'antd';

export default styled(Select)`
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme?.inputs?.colors?.border?.initial ?? '#DCE5EC'};
    border-radius: 3px;
    min-height: 36px;
    min-width: ${({ $minWidth }) => $minWidth ?? 'auto'};
    height: ${({ $height }) => $height ?? '36px'};
    .ant-select-selector {
        border: unset !important;
    }
    .ant-select-arrow {
        color: #f27127;
    }
`;
