import React, { Suspense, useEffect } from 'react';
import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Flex from 'components/Utils/Flex';
import {
    useLazyGetOrdersAllIdsByFiltersQuery,
    useLazyGetOrdersByFiltersQuery,
} from 'redux/features/Orders';
import { useGetUserPreferencesQuery } from 'redux/features/Users';
import { setOrdersFilters } from 'redux/features/Utils';
import { clearFilters } from 'redux/features/Orders/orderTabFilters';
import store from '../../redux';
import useAllSalesChannel from 'hooks/useAllSalesChannel';
import useAllCouriers from 'hooks/useAllCouriers';
import { useDispatch } from 'react-redux';
import { setValueOptionByField } from 'redux/features/Orders/orderTabFilters';
import { deliveryMethodOptions, fulfillmentStatusOptions } from './components/Filters/constants';
import { promisedDateRanges } from 'data/promised_date_ranges';
import useAllShops from '../../hooks/useAllShops';

const OrdersMainDisplay = React.lazy(() => import('pages/OrdersV2/components/OrdersMainDisplay'));

const OrdersPage = () => {
    const [getOrders, { data, isLoading, isFetching }] = useLazyGetOrdersByFiltersQuery({
        refetchOnMountOrArgChange: true,
    });

    const [getOrdersAllIds, { data: dataIds }] = useLazyGetOrdersAllIdsByFiltersQuery({
        refetchOnMountOrArgChange: true,
    });
    const {
        data: userPreferences,
        isLoading: userLoading,
        isFetching: userFetching,
    } = useGetUserPreferencesQuery();

    const [salesChannelsLoading, salesChannels] = useAllSalesChannel();
    const [couriersLoading, couriers] = useAllCouriers();
    const [locationsLoading, locations] = useAllShops();

    const loading =
        isLoading ||
        isFetching ||
        salesChannelsLoading ||
        locationsLoading ||
        couriersLoading ||
        userLoading ||
        userFetching;

    const dispatch = useDispatch();

    const handleRefetch = (forceRefetch) => {
        const utils = store.getState().utils;
        const filters = utils.ordersFilters;
        getOrders(filters, !forceRefetch);
        getOrdersAllIds(filters, !forceRefetch);
    };

    useEffect(() => {
        return () => {
            dispatch(clearFilters());
            dispatch(setOrdersFilters({}));
        };
    }, []);

    useEffect(() => {
        if (loading) return;

        const origins = ['mercadolibre', 'dafiti', 'fcom', 'ripley', 'paris'];
        const sales_channels_with_origins = Array.from(salesChannels ?? [])
            .filter((sc) => ['Multivende'].includes(sc?.platform))
            .reduce((acc, ele) => {
                origins.forEach((origin) => {
                    acc.push({
                        label: `${ele?.alias ?? ''} - ${origin}`,
                        value: `${ele._id}::${origin}`,
                    });
                });
                return acc;
            }, []);

        const sales_channels = [
            ...Array.from(salesChannels ?? []).map((sc) => ({
                label: sc?.alias ?? sc?.integration?.api_name,
                value: sc?._id,
            })),
            ...sales_channels_with_origins,
        ].sort((a, b) => String(a.label).localeCompare(String(b.label)));

        const destination_stores = Array.from(locations ?? [])
            .filter((location) => location?.is_enabled && location?.location_type !== 'virtual')
            .map((location) => ({
                label: location?.name,
                value: location?._id,
            }))
            .sort((a, b) => String(a.label).localeCompare(String(b.label)));

        dispatch(
            setValueOptionByField({
                fulfillment_status: fulfillmentStatusOptions,
                sales_channel: sales_channels,
                delivery_method: deliveryMethodOptions,
                assigned_courier: couriers.map((courier) => ({
                    label: courier?.public_name ?? courier?.courier_name ?? '',
                    value: courier?._id,
                })),
                promised_date_range: promisedDateRanges,
                ['locations.destination']: destination_stores,
            })
        );
    }, [loading]);

    const forceRefetchWithButton = (event) => {
        const key = event?.key;
        if (key === '.') {
            handleRefetch(true);
        }
    };

    useEffect(() => {
        document.addEventListener('keypress', forceRefetchWithButton);
        return () => {
            document.removeEventListener('keypress', forceRefetchWithButton, true);
        };
    }, []);

    return (
        <Container extraTitle="Pedidos" backgroundColor="#fff">
            <PageHeader title="Pedidos" withMarginBottom={false} />
            <Flex direction="column">
                {/* <OrdersDataMenu /> */}
                <Suspense fallback={<></>}>
                    <OrdersMainDisplay
                        data={data}
                        dataIds={dataIds?.ids ?? []}
                        loading={loading}
                        userPreferences={userPreferences}
                        userFetching={userFetching}
                        getOrders={() => {
                            getOrders();
                            getOrdersAllIds();
                        }}
                        refetch={handleRefetch}
                    />
                </Suspense>
            </Flex>
        </Container>
    );
};

export default OrdersPage;
