import ModalWithButton from 'components/ModalWithButton';
import { useRetryShippingCreationMassiveTagsMutation } from 'redux/features/Fulfillments';

import { ReactComponent as TicketIcon } from 'assets/ticket_light_blue.svg';
import { ReactComponent as AlertIcon } from 'assets/alert-error.svg';
import { toggleModal } from 'redux/features/Utils';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

const MassiveTags = ({ selectedRows, fulfillments = [], isMenuItem = true }) => {
    const [retryShippingCreationMassiveTags] = useRetryShippingCreationMassiveTagsMutation();
    const dispatch = useDispatch();

    const fulfillmentsToRetryShippingCreation = useMemo(() => {
        // validate if fulfillments.labels.pdf exist
        const fulfillmentsToRetryShippingCreation = Array.from(fulfillments ?? []).filter(
            (fulfillment) => !fulfillment.labels?.pdf && fulfillment?.status === 'ready_for_deliver'
        );
        // return only the ids if match with selectedRows
        return Array.from(selectedRows ?? []).filter((_id) =>
            Array.from(fulfillmentsToRetryShippingCreation ?? []).some(
                (fulfillment) => fulfillment?._id === _id
            )
        );
    }, [fulfillments, selectedRows]);

    const handleSubmit = (ids = []) => {
        retryShippingCreationMassiveTags({ ful_ids: ids });
        dispatch(toggleModal('generate_massive_tags'));
    };

    return (
        <ModalWithButton
            buttonType="menuitem"
            onOk={() => handleSubmit(fulfillmentsToRetryShippingCreation)}
            onClose={() => dispatch(toggleModal('generate_massive_tags'))}
            modalProps={{
                name: 'generate_massive_tags',
                cancelText: 'Cancelar',
                okText: 'Confirmar',
                title: (
                    <b className="text-lg" style={{ color: '#2D3D76' }}>
                        Envío de etiquetas masivo
                    </b>
                ),

                width: '600px',
                destroyOnClose: true,
            }}
            buttonProps={{
                buttonText: `Generar etiquetas masivas (${fulfillmentsToRetryShippingCreation.length}) `,
                isMenuItem: isMenuItem,
                disabled: fulfillmentsToRetryShippingCreation?.length === 0
            }}
        >
            <div>
                <span>
                    Está realizando un envío masivo de {fulfillmentsToRetryShippingCreation?.length}{' '}
                    etiquetas, debe considerar que:
                </span>

                <div className="flex gap-2 mt-2">
                    <span>
                        1. Las etiquetas pueden realizarse exitosamente y quedar con el ícono
                    </span>
                    <TicketIcon style={{ marginTop: '5px' }} />
                </div>

                <div className="flex gap-2 mt-3">
                    <span>
                        2. Algunas etiquetas pueden no generarse de forma correcta y quedar con el
                        ícono
                    </span>
                    <AlertIcon />
                </div>

                <div style={{ marginTop: '30px' }}>
                    <span>
                        *De no generarse de forma correcta la etiqueta, deberá volver a realizar la
                        acción
                    </span>
                </div>

                <div style={{ marginTop: '50px' }}>
                    <b>¿Desea confirmar el envío masivo de etiquetas?</b>
                </div>
            </div>
        </ModalWithButton>
    );
};

export default MassiveTags;
