import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getPreparationPreferenceByMerchant } from './getPreparationPreferenceByMerchant';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const createPreparationPreference = createAsyncThunk(
    'preparationPreference/create',
    async (data, state) => {
        try {
            const res = await AndesClient.post(`/settings/preparation`, data);

            if (res.status === 200) {
                toast.success('Configuración de preferencia de preparación creada con éxito.');
                localStorage.removeItem('preparationSettings');
                state.dispatch(getPreparationPreferenceByMerchant());
            }

            return state.fulfillWithValue(true);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const createPreparationPreferenceSlice = createSlice({
    name: 'createPreparationPreference',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createPreparationPreference.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createPreparationPreference.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createPreparationPreference.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createPreparationPreferenceSlice.reducer;
