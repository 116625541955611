export const pendingArrivalsFilterFields = [
    {
        label: 'Estado de recepción',
        value: 'preparationcd_transfer_pickupstore.status',
    },
    {
        label: 'Estado de consolidación',
        value: 'consolidation.status',
    },
    {
        label: 'CD a tienda de retiro',
        value: 'preparationcd_transfer_pickupstore',
    },
    {
        label: 'Consolidación',
        value: 'consolidation.consolidation_location_id',
    },
    {
        label: 'Folio',
        value: 'folio::search_references',
    },
    {
        label: 'ID Ecommerce',
        value: 'remote_order_id',
    },
    {
        label: 'ID Fulfillment',
        value: 'name',
    },
    {
        label: 'Plazo límite (h)',
        value: 'promised_date',
    },
    {
        label: 'Promesa de entrega',
        value: 'custom_promised_date',
    },
    {
        label: 'Horario de entrega',
        value: 'promised_date_range',
    },
    {
        label: 'Región de entrega',
        value: 'province',
    },
    {
        label: 'Tipo de entrega',
        value: 'delivery_method',
    },
    {
        label: 'Cliente',
        value: 'customer_name',
    },
];
