import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addProfileToStore } from './getAllProfiles';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const createProfile = createAsyncThunk('profile/create', async (data, state) => {
    try {
        const res = await toast.promise(AndesClient.post(`/profiles/create`, data), {
            loading: 'Creando perfil...',
            success: 'Perfil creado con éxito.',
        });

        if (res.status === 200) {
            state.dispatch(addProfileToStore(res.data));
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const createProfileSlice = createSlice({
    name: 'createProfile',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createProfile.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createProfile.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createProfile.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createProfileSlice.reducer;
