import { Flex } from '@aws-amplify/ui-react';
import { Form, Input, Modal } from 'antd';
import capitalizeStrings from 'helpers/capitalizeStrings';
import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useValidatePinDeliveryMutation } from 'redux/features/Deliveries';
export const ModalValidateDeliveryPin = ({ isOpen, fulfillments, onClose, onSuccess }) => {
    const [validatePinDelivery, { isLoading }] = useValidatePinDeliveryMutation();
    const [currentFulfillmentIndex, setCurrentFulfillmentIndex] = useState(0);
    const [validatedFulfillments, setValidatedFulfillments] = useState([]);

    const currentFulfillment = useMemo(
        () => fulfillments[currentFulfillmentIndex],
        [fulfillments, currentFulfillmentIndex]
    );

    const customer = useMemo(
        () => currentFulfillment?.order?.customer || currentFulfillment?.order_id?.customer,
        [currentFulfillment]
    );

    const [form] = Form.useForm();

    const handleReset = () => {
        setCurrentFulfillmentIndex(0);
        setValidatedFulfillments([]);
        form.resetFields();
    };

    const handleValidate = (values) => {
        const nextIndex = currentFulfillmentIndex + 1;
        validatePinDelivery({
            delivery_pin: values?.delivery_pin,
            fulfillment_id: currentFulfillment?._id,
        })
            .then((resp) => {
                console.log({ resp });
                if (resp?.data?.is_valid) {
                    const updateValidatedFulfillments = [
                        ...validatedFulfillments,
                        currentFulfillment._id,
                    ];
                    setValidatedFulfillments(updateValidatedFulfillments);
                    if (nextIndex <= fulfillments.length - 1) {
                        setCurrentFulfillmentIndex(nextIndex);
                    }

                    if (
                        currentFulfillmentIndex === fulfillments.length - 1 &&
                        updateValidatedFulfillments.length
                    ) {
                        handleReset();
                        onSuccess(updateValidatedFulfillments);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleCancel = () => {
        const nextIndex = currentFulfillmentIndex + 1;
        toast.error(`Entrega del pedido ${currentFulfillment.name} cancelada`);
        if (nextIndex <= fulfillments.length - 1) {
            setCurrentFulfillmentIndex(nextIndex);
            return;
        }
        if (currentFulfillmentIndex === fulfillments.length - 1 && validatedFulfillments.length) {
            handleReset();
            onSuccess(validatedFulfillments);
        } else {
            handleReset();
            onClose();
        }
    };

    return (
        <Modal
            onOk={() => form.submit()}
            onCancel={handleCancel}
            okText="Validar"
            title="Introduzca el PIN asociado a la entrega"
            open={isOpen}
            confirmLoading={isLoading}
            closable={false}
        >
            <Form onFinish={handleValidate} form={form}>
                <Flex style={{ marginBottom: 5 }}>
                    <span>
                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>Pedido: </span>
                        <span style={{ fontSize: 16 }}>{currentFulfillment?.name}</span>
                    </span>
                </Flex>
                <Flex style={{ marginBottom: 20 }}>
                    <span>
                        <span style={{ fontSize: 16, fontWeight: 'bold' }}>Cliente: </span>
                        <span style={{ fontSize: 16 }}>
                            {customer?.first_name && capitalizeStrings(customer?.first_name)}{' '}
                            {customer?.last_name && capitalizeStrings(customer?.last_name)}
                        </span>
                    </span>
                </Flex>
                <Form.Item
                    name="delivery_pin"
                    rules={[
                        {
                            required: true,
                            message: 'El pin de 5 dígitos es obligatorio',
                        },
                    ]}
                >
                    <Input minLength={6} maxLength={6} placeholder="Ingresar PIN de 5 dígitos" />
                </Form.Item>

                <Flex marginTop={12} justifyContent="flex-end">
                    <span>
                        Pedido: {currentFulfillmentIndex + 1} / {fulfillments?.length}{' '}
                    </span>
                </Flex>
            </Form>
        </Modal>
    );
};
