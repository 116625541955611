import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import AndesClient from 'services/AndesClient';
import { getAllSalesChannel } from './getAllSalesChannel';

export const changeStatusManySalesChannels = createAsyncThunk(
    'salesChannels/changeStatus',
    async (data, state) => {
        try {
            const res = await toast.promise(
                AndesClient.post(`/settings/sales-channels/change-status`, data),
                {
                    loading: 'Actualizando canal de venta...',
                    success: 'Canal de venta actualizado',
                    error: (error) => `Error: ${error.response.data.message}`,
                }
            );
            if (res.status === 200) {
                state.dispatch(getAllSalesChannel());
            }
            return state.fulfillWithValue(true);
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const changeStatusManySalesChannelsSlice = createSlice({
    name: 'changeStatusManyChannels',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [changeStatusManySalesChannels.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [changeStatusManySalesChannels.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [changeStatusManySalesChannels.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default changeStatusManySalesChannelsSlice.reducer;
