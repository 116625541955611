import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import qs from 'qs';

export const errorsApi = createApi({
    reducerPath: 'errors',
    baseQuery,
    tagTypes: ['errors'],
    endpoints: (builder) => ({
        getErrors: builder.query({
            method: 'get',
            query: (args) => {
                const querystring = qs.stringify(args ?? {});
                return {
                    url: `/errors${querystring ? `?${querystring}` : ''}`,
                    method: 'get',
                };
            },
            providesTags: ['errors'],
        }),
        retryError: builder.mutation({
            method: 'post',
            query: ({ _id }) => ({
                url: `/errors/retry`,
                method: 'post',
                body: {
                    _id,
                },
            }),
            invalidatesTags: ['errors'],
        }),
        makeResolveError: builder.mutation({
            method: 'post',
            query: ({ _id }) => ({
                url: `/errors/makeresolve`,
                method: 'post',
                body: { _id },
            }),
            invalidatesTags: ['errors'],
        }),
    }),
});

export const {
    useGetErrorsQuery,
    useLazyGetErrorsQuery,
    useRetryErrorMutation,
    useMakeResolveErrorMutation,
} = errorsApi;
