import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const featureFlagApi = createApi({
    reducerPath: 'featureFlag',
    baseQuery,
    tagTypes: ['featureFlag'],
    endpoints: (builder) => ({
        getFeatureFlag: builder.query({
            method: 'get',
            query: () => ({
                url: 'settings/feature-flags/oms/frontend',
                method: 'get',
            }),
            providesTags: ['errors'],
        }),
    }),
});

export const { useGetFeatureFlagQuery } = featureFlagApi;
