import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getManyOrdersByCustom = createAsyncThunk(
    'orders/getManyOrdersByCustom',
    async (data, state) => {
        try {
            const response = await AndesClient.post(`/orders/getManyOrdersByCustom`, data);
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const getManyOrdersByCustomSlice = createSlice({
    name: 'getManyOrdersByCustom',
    initialState: {
        loading: false,
        data: [],
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [getManyOrdersByCustom.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getManyOrdersByCustom.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                success: true,
                error: null,
            };
        },
        [getManyOrdersByCustom.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: payload,
            };
        },
    },
});
export default getManyOrdersByCustomSlice.reducer;
