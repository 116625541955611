export const pendingArrivalsDeliveryMethodOptions = [
    {
        label: 'Retiro',
        value: 'pickup',
    },
    { label: 'Retiro con consolidación', value: 'pickup_consolidation' },
    {
        label: 'Despacho',
        value: 'shipping',
    },
    { label: 'Despacho con consolidación', value: 'shipping_consolidation' },
    { label: 'Retiro en punto de tercero', value: 'pickup_point' },
];

export const pendingArrivalsPickupStoreStatusOptions = [
    {
        label: 'Pendiente',
        value: 'created',
    },
    {
        label: 'Cancelado',
        value: 'cancelled',
    },
    {
        label: 'Listo para despacho a tienda',
        value: 'ready_for_deliver_to_pickup_location',
    },
    {
        label: 'Despachado a tienda',
        value: 'delivered_to_pickup_location',
    },
    {
        label: 'Parcialmente recepcionado en tienda',
        value: 'partially_received_in_pickup_location',
    },
    {
        label: 'Recibido en tienda',
        value: 'received_in_pickup_location',
    },
    {
        label: 'Recibido en CD/Tienda',
        value: 'received_in_cd_location',
    },
    {
        label: 'Entregado',
        value: 'delivered',
    },
    {
        label: 'Listo para despachar a cliente',
        value: 'success',
    },
];
