import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import CreateManualIncidenceOrderDrawer from 'components/ManualIncidences/Order/CreateIncidenceDrawer';
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import Dropdown from 'components/V2/Dropdown';
import Menu from 'components/V2/Menu';
import { orderIncidencesTypesFromDeliveriesView } from 'helpers/renderManualIncidencesLabels';
import ChangeFulfillmentsCourier from './ChangeFulfillmentsCourier';

const Actions = ({ selectedRows = [], disabled = false }) => {
    const menu = (
        <Menu>
            {/* <MenuItem onClick={handleMultipleDeliveries}>
                Procesar Entrega ({selectedRows.length})
            </MenuItem> */}

            <CreateManualIncidenceOrderDrawer
                selectedIds={selectedRows}
                searchFrom="fulfillment"
                incidencesTypes={orderIncidencesTypesFromDeliveriesView}
                isMenuItem
            />

            <ChangeFulfillmentsCourier selectedRows={selectedRows} />
        </Menu>
    );

    return (
        <Dropdown disabled={disabled} overlay={menu}>
            <PrimaryButton icon={<ChevronDownIcon width={24} height={24} />} iconPosition="right">
                Acciones ({selectedRows.length})
            </PrimaryButton>
        </Dropdown>
    );
};

export default Actions;
