import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const findStockOfLineItemsPerLocation = createAsyncThunk(
    'stock/findInventoryByLineItems',
    async (lineItems, state) => {
        try {
            const res = await AndesClient.post(`stock/findInventoryByLineItems`, lineItems);
            return res.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const findStockOfLineItemsPerLocationSlice = createSlice({
    name: 'findStockOfLineItemsPerLocation',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [findStockOfLineItemsPerLocation.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [findStockOfLineItemsPerLocation.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [findStockOfLineItemsPerLocation.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});

export default findStockOfLineItemsPerLocationSlice.reducer;
