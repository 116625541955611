import AntdTable from 'components/AntdTable';
import Drawer from 'components/Drawer';
import Tabs from 'components/Tabs';
import TimeLine from 'components/TimeLine';
import Flex from 'components/Utils/Flex';
import MoneyFormat from 'helpers/MoneyFormat';
import { useMemo } from 'react';
import styled from 'styled-components';

const DetailItem = styled.div`
    font-size: 14px;
    display: flex;
    flex-direction: row;

    b {
        font-weight: bold;
        display: block;
        width: 120px;
        color: #1f6373;
        font-weight: 500;
    }
    span {
        flex: 1;
    }
`;

const OrderDetail = ({ row }) => {
    return (
        <Flex direction="column">
            <DetailItem>
                <b>Descripción</b>
                <span>{row.line_item.name}</span>
            </DetailItem>
            <DetailItem>
                <b>Marca</b>
                <span>{row.line_item.vendor}</span>
            </DetailItem>
            <DetailItem>
                <b>Código</b>
                <span>{row.line_item.sku}</span>
            </DetailItem>
            <DetailItem>
                <b>Cantidad</b>
                <span>{row.quantity}</span>
            </DetailItem>
            <DetailItem>
                <b>Precio</b>
                <span>{MoneyFormat(row.line_item.unit_price)}</span>
            </DetailItem>
        </Flex>
    );
};

const OrderDetails = ({ fulfillment }) => {
    const tableData = useMemo(() => [...fulfillment.line_items], [fulfillment]);
    const columns = useMemo(
        () => [
            {
                title: 'Productos',
                render: (_, row) => <OrderDetail row={row} />,
            },
        ],
        { fulfillment }
    );
    return (
        <Drawer
            drawerProps={{
                title: `Detalles del pedido ${fulfillment.name}`,
                name: 'fulfillment-order-details',
                width: '600px',
                dontShowFooter: true,
                forceRender: true,
            }}
        >
            <Tabs
                withBorder
                tabs={[
                    {
                        id: 'details',
                        label: 'Detalles',
                        component: <AntdTable dataSource={tableData} columns={columns} />,
                        default: true,
                    },
                    {
                        id: 'timeline',
                        label: 'Historial',
                        component: <TimeLine data={fulfillment.logs} page="preparation" />,
                    },
                ]}
            />
        </Drawer>
    );
};

export default OrderDetails;
