import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getFilteredOrders } from '../Orders/getFilteredOrders';
// import { toggleDrawer } from '../Utils';
import { fulfillmentsApi } from '../Fulfillments';
import { ordersApi } from '../Orders';

export const createOrderManualIncidence = createAsyncThunk(
    'incidences/createOrderManualIncidence',
    async (data, state) => {
        try {
            const promise = AndesClient.post(`/incidents/createOrderManualIncidence`, data);

            toast.promise(promise, {
                loading: <b>Creando incidencia manual...</b>,
                success: <b>Incidencia manual creada con éxito</b>,
                error: <b>Ocurrió un error</b>,
            });

            const result = await promise;

            if (result.status === 200) {
                state.dispatch(getFilteredOrders());
                state.dispatch(fulfillmentsApi.util.invalidateTags(['fulfillmentsToDelivery']));
                state.dispatch(fulfillmentsApi.util.invalidateTags(['fulfillmentsToPreparation']));
                state.dispatch(ordersApi.util.invalidateTags(['Orders', 'Order']));
            }
            return state.fulfillWithValue(true);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const createOrderManualIncidenceSlice = createSlice({
    name: 'createOrderManualIncidence',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createOrderManualIncidence.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createOrderManualIncidence.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createOrderManualIncidence.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createOrderManualIncidenceSlice.reducer;
