export const deliveryPromiseDateFormatDic = (format) => {
    const formats = {
        time_limit: {
            title: 'Plazo limite',
            isHours: true,
        },
        delivery_promise: {
            title: 'Promesa de entrega',
            isHours: false,
        },
    };

    return formats[format] || formats.delivery_promise;
};
