import { Checkbox } from 'antd';
import { useEffect } from 'react';
import useActionToggleDrawer from 'hooks/useActionToggleDrawer';
import { ReactComponent as InfoIcon } from 'assets/info-cleaner-grey.svg';
import { Label } from '../styled';
import { ProblemOrderDetailsDrawer } from '../../ShowDetailsDrawer/ProblemOrderDetailsDrawer';

const ProblemOrders = ({
    ordersCopy,
    ordersIds,
    isShareWarehouse,
    setIsShareWarehouse,
    isCloseIncidenceForm,
    setIsCloseIncidenceForm,
    isCheckboxDisabled,
    setIsCheckboxDisabled,
    isFormSubmitted,
    setCheckedList,
    checkedList,
    setUnCheckedList,
    unCheckedList,
}) => {
    const onCheckboxGroupChange = (list) => {
        if (!ordersCopy?.length) return;

        setCheckedList(list);
        setUnCheckedList(ordersCopy.map((order) => order._id).filter((id) => !list.includes(id)));
    };

    const uncheckAllCheckboxes = () => setCheckedList([]);

    useEffect(() => {
        if (isShareWarehouse !== 'no') return;

        uncheckAllCheckboxes();
        setIsShareWarehouse('none');
    }, [isShareWarehouse]);

    useEffect(() => {
        if (isCloseIncidenceForm !== true) return;

        uncheckAllCheckboxes();
        setIsShareWarehouse('none');
        setIsCloseIncidenceForm(false);
    }, [isCloseIncidenceForm]);

    const [,] = useActionToggleDrawer(
        () => {
            uncheckAllCheckboxes();
            setIsShareWarehouse('none');
            setIsCloseIncidenceForm(false);
            setIsCheckboxDisabled(false);
        },
        'manual-incidence-order-drawer',
        false
    );

    const [,] = useActionToggleDrawer(
        () => {
            if (ordersIds && ordersIds[0]) {
                setCheckedList(ordersIds);
                setIsCheckboxDisabled(false);
            }
        },
        'manual-incidence-order-drawer',
        true
    );

    return (
        <>
            {((!isFormSubmitted && !!checkedList.length) ||
                (isFormSubmitted && !unCheckedList.length)) && (
                <Label fontWeight={600} className="mb-4">
                    Pedidos seleccionados con incidencia ({checkedList.length})
                </Label>
            )}

            {isFormSubmitted && !!unCheckedList.length && (
                <div className="flex p-2" style={{ display: 'flex', backgroundColor: '#D6F3FB' }}>
                    <InfoIcon
                        className="mr-1"
                        style={{ width: '2.5em', height: '2em', border: 2 }}
                    />
                    <label
                        style={{
                            fontSize: 12,
                            marginTop: 5,
                            marginBottom: 5,
                            fontWeight: 4000,
                            lineHeight: 1.5,
                            color: '#255035',
                        }}
                    >
                        Tienes <b>({unCheckedList.length})</b> pedido(s) en la lista sin reportar
                        incidencia, para continuar con el proceso debes seleccionar el pedido.{' '}
                        <br /> Si quieres finalizar el proceso solo debes hacer click en la{' '}
                        <b>“X”</b>.
                    </label>
                </div>
            )}

            <Checkbox.Group
                onChange={onCheckboxGroupChange}
                value={checkedList}
                className="w-full"
                disabled={isCheckboxDisabled}
            >
                {ordersCopy?.map((order) => (
                    <ProblemOrderDetailsDrawer
                        itemLength={order?.line_items?.length}
                        nameOrder={order?.name}
                        totalPrice={order?.total_price}
                        key={order._id}
                        id={order._id}
                    />
                ))}
            </Checkbox.Group>
        </>
    );
};

export default ProblemOrders;
