// import { ReactComponent as IncidenceIcon } from 'assets/alert-rhombus.svg';
import { Tooltip } from 'antd';
import { useDispatch } from 'react-redux';
import { changeDetailsManualIncidenceDrawerField } from 'redux/features/ManualIncidences/detailsManualIncidenceDrawer';
import { toggleDrawer } from 'redux/features/Utils';
import { BsFillExclamationDiamondFill } from 'react-icons/bs';

const OpenDrawerByIconography = ({ order }) => {
    // console.log({ order });
    const dispatch = useDispatch();
    const drawerName = 'manual-incidence-details-drawer';
    return (
        <div>
            <Tooltip title="Incidencia Manual" className="cursor-pointer ml-1">
                <BsFillExclamationDiamondFill
                    onClick={() => {
                        dispatch(toggleDrawer(drawerName));
                        dispatch(changeDetailsManualIncidenceDrawerField(['orderData', order]));
                    }}
                    style={{ color: '#b00303', fontSize: '16px', marginTop: '2px' }}
                />
            </Tooltip>
        </div>
    );
};

export default OpenDrawerByIconography;
