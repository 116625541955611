import TCard from 'components/TCard';
/*import { Alert } from 'antd';*/
import Flex from 'components/Utils/Flex';
import AntdTable from 'components/AntdTable';
import { useEffect, useMemo, useState } from 'react';
import {
    ProductDescription,
    // ProductImage,
    ProductRow,
    ProductRowRight,
    ProductsHeader,
} from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { setChangeStoreValues } from 'redux/features/ChangeStore';
import Tag from 'components/Tag';
import MoneyFormat from '../../../helpers/MoneyFormat';
import { FiTruck } from 'react-icons/fi';
import { RiShoppingBag3Line } from 'react-icons/ri';
import { ReactComponent as IncidenceIcon } from 'assets/alert-rhombus.svg';
import { ReactComponent as DeliveredToCourierIcon } from 'assets/estado-despachado.svg'; // entregado a courier
import { Typography } from '@andescommercecloud/beflow-design-system';
import useAllShops from 'hooks/useAllShops';

const renderDeliveryMethod = {
    all: 'Despacho/Retiro',
    shipping: 'Despacho',
    pickup: 'Retiro',
};

const getLineItemWithIncidenceQuantity = ({ line_item_id, line_items_manual_incidences = [] }) => {
    return line_items_manual_incidences.reduce((acc, incidence) => {
        const incidence_quantity =
            incidence?.line_items?.find((li) => String(li.line_item._id) === String(line_item_id))
                ?.incident_quantity ?? 0;

        return (acc += incidence_quantity);
    }, 0);
};

const ChangeStoreProducts = ({ line_items = [], order, fulfillmentId }) => {
    const [productsWithIncidence, setProductsWithIncidence] = useState([]);
    const dispatch = useDispatch();
    const [, dataStores] = useAllShops();
    const changeStoreData = useSelector((state) => state.changeStore);
    const [consolidationLocations, setConsolidationLocations] = useState({});

    const fulfillmentSelected = useMemo(() => {
        const logistic = !fulfillmentId
            ? order?.logistics_info?.[0]
            : order?.logistics_info?.find((f) => String(f._id) === String(fulfillmentId));
        const logisticSelected = {
            ...logistic,
            origin: {
                _id: order?.location_id,
                id: order?.location_id,
                name: order?.location_id?.name,
            },
        };
        if (!fulfillmentId) return order?.fulfillments?.[0] ?? logisticSelected;
        return (
            order?.fulfillments?.find((f) => String(f._id) === String(fulfillmentId)) ??
            logisticSelected
        );
    }, [fulfillmentId, order]);

    useEffect(() => {
        if (
            fulfillmentSelected?.consolidation &&
            fulfillmentSelected?.consolidation?.consolidation_location_id &&
            !fulfillmentSelected?.consolidation?.is_consolidation_fulfillment
        ) {
            setConsolidationLocations({
                consolidation_location: dataStores?.find(
                    (store) =>
                        store._id === fulfillmentSelected?.consolidation?.consolidation_location_id
                )?.name,
                preparation_location: dataStores?.find(
                    (store) =>
                        store._id === fulfillmentSelected?.consolidation?.preparation_location_id
                )?.name,
            });
        }
    }, [dataStores, fulfillmentSelected]);

    const columns = useMemo(
        () => [
            {
                /*title: (
                    <span>
                        Unidad(es) a resolver seleccionadas{' '}
                        <b>
                            ({selectedRows.length}/{line_items.length})
                        </b>
                    </span>
                ),*/
                render: (row) => {
                    const thisRowItemIncidence = productsWithIncidence.find(
                        (li) => String(li.line_item) === String(row._id)
                    );

                    return (
                        <ProductRow>
                            {/* <ProductImage></ProductImage> */}
                            <ProductDescription>
                                <p>{row.name}</p>
                                <span>SKU: {row.sku}</span>
                            </ProductDescription>

                            <ProductRowRight>
                                <p>{MoneyFormat(row?.total_price)}</p>
                                {thisRowItemIncidence ? (
                                    thisRowItemIncidence.quantity ? (
                                        <p className="item-incidence-count-text">
                                            <span
                                                style={{
                                                    marginRight: '0.2rem',
                                                }}
                                            >
                                                ({thisRowItemIncidence.quantity}/{row.quantity})
                                            </span>
                                            <span>Unidades con incidencia </span>
                                            <span>
                                                <IncidenceIcon
                                                    style={{ marginLeft: 5 }}
                                                    width="16px"
                                                    height="16px"
                                                />
                                            </span>
                                        </p>
                                    ) : (
                                        <p className="item-incidence-count-text">
                                            <span>Sin incidencias</span>
                                        </p>
                                    )
                                ) : (
                                    ''
                                )}
                            </ProductRowRight>
                        </ProductRow>
                    );
                },
            },
        ],
        [line_items]
    );

    const data = useMemo(() => {
        return line_items;
    }, [line_items, changeStoreData]);

    const deliveryMethod = useMemo(() => {
        return fulfillmentSelected?.preparationcd_transfer_pickupstore?.cd_location
            ? 'pickup'
            : fulfillmentSelected?.delivery_method ?? order?.delivery_method;
    }, [fulfillmentSelected?.delivery_method, order?.delivery_method, fulfillmentSelected?.preparationcd_transfer_pickupstore]);

    useEffect(() => {
        const objLineItems = line_items.map((li) => {
            const incidence_quantity = getLineItemWithIncidenceQuantity({
                line_item_id: li._id,
                line_items_manual_incidences: order?.line_items_manual_incidences,
            });

            return {
                line_item: li._id,
                quantity: incidence_quantity,
                total_price: li.total_price,
                sku: li.sku,
            };
        });

        setProductsWithIncidence(objLineItems);
    }, [line_items]);

    // Por el momento el cambio es para toda la orden, no de forma parcial
    useEffect(() => {
        if (!changeStoreData.line_items && line_items.length > 0) {
            const objLineItems = line_items.map((li) => {
                const incidence_quantity = getLineItemWithIncidenceQuantity({
                    line_item_id: li._id,
                    line_items_manual_incidences: order?.line_items_manual_incidences,
                });

                return {
                    line_item: li._id,
                    quantity: incidence_quantity,
                    total_price: li.total_price,
                    sku: li.sku,
                };
            });

            dispatch(
                setChangeStoreValues({
                    line_items: objLineItems,
                    delivery_method: deliveryMethod,
                })
            );
        }
    }, [line_items, changeStoreData, deliveryMethod]);

    return (
        <TCard>
            <Flex direction="column" rowGap="1rem">
                <ProductsHeader>
                    <span>
                        <b className="product-header-title">{order.name}</b>
                        <p className="product-header-counter">Productos ({line_items.length})</p>
                    </span>
                    <Flex direction="column">
                        <Flex direction="row" alignItems="center" columnGap="0.5rem">
                            <b>Entrega:</b>
                            <span>
                                <Tag color="info">
                                    <Flex direction="row" alignItems="center" columnGap="0.3rem">
                                        <b className="product-header-delivery-method">
                                            {renderDeliveryMethod[deliveryMethod]}
                                        </b>
                                        {deliveryMethod === 'pickup' && (
                                            <RiShoppingBag3Line size={16} />
                                        )}
                                        {deliveryMethod === 'shipping' && <FiTruck size={16} />}
                                    </Flex>
                                </Tag>
                            </span>
                            {fulfillmentSelected?.consolidation?.preparation_location_id && (
                                <Typography variant="captionBold" color="primary.00">
                                    (Consolidación)
                                </Typography>
                            )}
                        </Flex>
                        <Flex direction="row" alignItems="center" columnGap="0.5rem">
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}
                                >
                                    <b>Tienda/Bodega: </b>
                                    {fulfillmentSelected?.preparationcd_transfer_pickupstore
                                        ?.cd_location ? (
                                        <>
                                            <div className="order_page_product_table_value_location">
                                                <b>
                                                    {
                                                        fulfillmentSelected
                                                            ?.preparationcd_transfer_pickupstore
                                                            ?.cd_location?.name
                                                    }
                                                </b>
                                            </div>

                                            <DeliveredToCourierIcon
                                                style={{ width: '20px', height: '20px' }}
                                            />
                                            <div className="order_page_product_table_value_location">
                                                <b>
                                                    {
                                                        fulfillmentSelected
                                                            ?.preparationcd_transfer_pickupstore
                                                            ?.pickup_location?.name
                                                    }
                                                </b>
                                            </div>
                                        </>
                                    ) : consolidationLocations?.preparation_location ? (
                                        <>
                                            <div className="order_page_product_table_value_location">
                                                <b>
                                                    {consolidationLocations?.preparation_location}
                                                </b>
                                            </div>

                                            <DeliveredToCourierIcon
                                                style={{ width: '20px', height: '20px' }}
                                            />
                                            <div className="order_page_product_table_value_location">
                                                <b>
                                                    {consolidationLocations?.consolidation_location}
                                                </b>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="order_page_product_table_value_location">
                                            <b>
                                                {fulfillmentSelected?.origin?.name ??
                                                    order?.location_id?.name}
                                            </b>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Flex>
                    </Flex>
                </ProductsHeader>

                <p className="products-with-incidences-title">
                    Productos con incidencia (
                    {productsWithIncidence?.filter((x) => x.quantity > 0).length}/
                    {fulfillmentSelected?.line_items?.length ?? productsWithIncidence?.length})
                </p>

                <AntdTable
                    rowKey="_id"
                    columns={columns}
                    dataSource={data}
                    /*rowSelection={rowSelections}*/
                    removeHeaderBackground
                />
            </Flex>
        </TCard>
    );
};
export default ChangeStoreProducts;
