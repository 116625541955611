import LogoSimplePNG from 'assets/LogoSimple.png';
import styled, { css } from 'styled-components';

const LoadingState = styled.div`
    margin: 0 auto;
    padding: 2rem 0.5rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #037fb9;
    opacity: 0.7;
    background-color: rgba(255, 255, 255, 0.5);

    ${({ $center }) =>
        $center &&
        css`
            top: 0;
            bottom: 0;
            position: absolute;
        `}

    @keyframes blink {
        0% {
            opacity: 0.5;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.5;
        }
    }

    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    animation: blink 0.6s linear infinite;

    img {
        width: 32px;
        height: 32px;
        animation: rotation 1s infinite linear;
    }

    span {
        b {
            display: block;
            font-weight: 500 !important;
            font-size: 18px;
            line-height: 22px;
        }

        p {
            font-size: 14px;
        }
    }
`;

const Loading = ({ loadingText, $center = false }) => {
    return (
        <LoadingState className="img-blink" $center={$center}>
            <img
                src={LogoSimplePNG}
                className="img-rotation"
                style={{ height: '32px', width: '32px' }}
            />

            <span>
                <b>
                    <br />
                </b>
                <p>{loadingText ?? 'Cargando datos...'}</p>
            </span>
        </LoadingState>
    );
};

export default Loading;
