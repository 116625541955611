import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Divider } from 'antd';
import { Menu, Transition } from '@headlessui/react';
import { ChevronRightIcon, CogIcon /* TruckIcon */ } from '@heroicons/react/outline';
import { FiTrello } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { ReactComponent as LogoColorBeflowSVG } from 'assets/LogoColorBeFlowSVG.svg';
import { ReactComponent as LogoSimpleSVG } from 'assets/LogoSimpleSVG.svg';
import { ReactComponent as PreparationIconSVG } from 'assets/PreparationIcon.svg';
import { ReactComponent as DeliveriesIconSVG } from 'assets/trolley-svgrepo-com.svg';
import { ReactComponent as TruckIcon } from 'assets/truck-outline-sidebar.svg';
import { ReactComponent as CrossdockingIcon } from 'assets/consolidation-icon.svg';
import { ReactComponent as ExternalLinkIcon } from 'assets/external-link.svg';
import { ReactComponent as UserConfigIcon } from 'assets/user-config.svg';
import { ReactComponent as StockIcon } from 'assets/stock-box.svg';
import { ReactComponent as ArrowActionsIcon } from 'assets/arrow-actions.svg';
import { HiOutlineClipboard, HiOutlineSwitchHorizontal } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import useCurrentUser from 'hooks/useCurrentUser';
import Tag from 'components/Tag';
import Flex from 'components/Utils/Flex';
import { toggleSidebar } from 'redux/features/Utils';
import onLogout from '../../helpers/onLogout';
import usePermissionAdmin from 'hooks/usePermissionAdmin';
import styled from 'styled-components';
import { BiChevronDown, BiMapPin } from 'react-icons/bi';
import { TiDocumentText } from 'react-icons/ti';
import { useLocation } from 'react-router-dom';
import { useChangeUserMerchantMutation } from 'redux/features/Users';
import useAllSalesChannel from 'hooks/useAllSalesChannel';
import { MdOutlineLogout } from 'react-icons/md';
import { decodeToken } from 'react-jwt';
import { PiUserSwitchDuotone } from 'react-icons/pi';
import { useGetValueFeatureFlag } from 'app-config/useGetValueFeatureFlag';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const navigation = [
    { name: 'Dashboard', href: '/', icon: FiTrello, current: false },
    { name: 'Pedidos', href: '/orders', icon: HiOutlineClipboard, current: false },
    {
        name: 'Preparación',
        href: '/fulfillments',
        icon: PreparationIconSVG,
        setFillOnActive: true,
        current: false,
    },
    {
        name: (
            <span style={{ whiteSpace: 'nowrap' }}>
                Pendientes <br />
                de recepción
            </span>
        ),
        href: '/pendingArrival',
        setFillOnActive: true,
        icon: TruckIcon,
        current: false,
    },
    {
        name: 'Crossdocking',
        href: '/crossdocking',
        setFillOnActive: true,
        icon: CrossdockingIcon,
        current: false,
    },
    {
        name: 'Entregas',
        href: '/deliveries',
        icon: DeliveriesIconSVG,
        setFillOnActive: true,
        current: false,
    },
    {
        name: 'Manifiestos',
        href: '/manifests',
        icon: TiDocumentText,
        setFillOnActive: true,
        current: false,
    },
];

const adminNavigation = [
    { name: 'Stock', href: '/stocks', icon: StockIcon, current: false, setFillOnActive: true },
    { name: 'Configuración', href: '/settings', icon: CogIcon, current: false },
];

const CustomMenuElement = styled.div`
    color: #41515e;
    font-size: 14px;
    font-weight: 400;

    svg {
        width: 18px;
        height: 18px;
    }

    ${(props) =>
        props.inSettingAndPreferences
            ? `
        svg {
            path {
                    fill: #f27127 !important;
                }
                color: #f27127;
        }
    `
            : ''}

    &:hover {
        svg {
            ${(props) =>
                props.pathFillColor
                    ? `
                path {
                    fill: #f27127 !important;
                }
            `
                    : `
                color: #f27127;
            `}
        }
    }
`;

const SidebarWrapper = styled.div`
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-right: 1px solid #dce5ec;
`;

const MainSidebar = styled.div`
    background-color: #f5f5f5;
`;

const ArrowActionsIconStyled = styled(ArrowActionsIcon)`
    ${(props) => (props.nonCollapsedMenuIsOpen ? 'transform: rotate(180deg);' : '')}

    path {
        stroke: #142c4a;
    }

    margin-top: 1.5rem;
`;

const UserSidebar = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    text-align: left;
    display: inline-block;
    position: relative;
    background-color: #ecedef;
    z-index: 1110;

    &:hover {
        background-color: rgba(196, 204, 214, 0.6);

        .custom-menu-element-wrapper {
            border-radius: 4px;
            background-color: #dae3eb;
        }
    }
`;

const SidebarMenuItem = ({
    item,
    sidebarCollapsed,
    handleSignOut,
    currentPath,
    showConsolidation = false,
}) => {
    const exact = item.href === '/';
    const [showSubMenus, setShowSubMenus] = useState(true);
    const [isAdmin] = usePermissionAdmin();
    const isActive = currentPath === item.href;
    const isExternalLink = item?.href?.includes('http');

    const subMenuCurrent =
        Array.from(item?.submenus ?? []).some((sub) => sub.href === currentPath) ?? false;

    const isMainActive = isActive || subMenuCurrent;
    if (item.name === 'Dashboard' && !isAdmin) {
        return null;
    }

    if (item.name === 'Crossdocking' && !showConsolidation) {
        return null;
    }

    if (item.submenus) {
        return (
            <NavLink
                exact={exact}
                key={item.name}
                to={isExternalLink ? { pathname: item.href } : item.href ?? '/'}
                target={isExternalLink ? '_blank' : ''}
                onClick={item.action === 'logout' ? handleSignOut : () => {}}
                className={classNames(
                    isMainActive ? '' : '',
                    `group flex items-center py-2 font-medium rounded-md sidebar-menu__item withSubmenu ${
                        sidebarCollapsed ? 'text-sm isCollapsed px-2' : 'text-xs'
                    }`,
                    isMainActive ? 'sidebar-menu__active' : ''
                )}
                activeClassName="sidebar-menu__active"
                style={{
                    width: '100%',
                }}
            >
                {sidebarCollapsed && (
                    <div style={{ width: '100%' }}>
                        <Flex direction="row" justifyContent="space-between" alignItems="center">
                            <Flex direction="row" alignItems="center">
                                <item.icon
                                    className={classNames(
                                        item.current ? '' : '',
                                        'mr-3 flex-shrink-0 h-6 w-6 sidebar__menu_icon',
                                        isMainActive ? 'sidebar-menu__icon-active' : ''
                                    )}
                                    aria-hidden="true"
                                    width={20}
                                    height={20}
                                />
                                <span>{item.name}</span>
                            </Flex>
                            <BiChevronDown
                                style={{
                                    fontSize: '1.4rem',
                                    color: '#2D3D76 !important',
                                }}
                                className={`prevent-change-icon-color icon-chevron ${
                                    showSubMenus ? 'isCollapsed' : ''
                                }`}
                                color="#2D3D76"
                                onClick={() => setShowSubMenus(!showSubMenus)}
                            />
                        </Flex>

                        <ul
                            className={
                                showSubMenus
                                    ? 'submenu-collapsed__open'
                                    : 'submenu-collapsed__closed'
                            }
                        >
                            {item.submenus.map((submenu) => (
                                <li key={submenu.name}>
                                    <NavLink
                                        exact={exact}
                                        to={submenu.href}
                                        className={classNames(
                                            submenu.current ? '' : '',
                                            'group flex items-center py-2 font-medium rounded-md sidebar-menu__item-submenu',
                                            sidebarCollapsed
                                                ? 'text-sm isCollapsed px-2'
                                                : 'text-xs'
                                        )}
                                        activeClassName="sidebar-submenu__active"
                                    >
                                        <submenu.icon
                                            className={classNames(
                                                submenu.current ? '' : '',
                                                'mr-3 flex-shrink-0 h-6 w-6',
                                                submenu.href === currentPath
                                                    ? 'sidebar__submenu_icon-active'
                                                    : ''
                                            )}
                                            aria-hidden="true"
                                            width={18}
                                            height={18}
                                        />
                                        <span>{submenu.name}</span>
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                {!sidebarCollapsed && (
                    <Flex
                        flex={1}
                        direction="column"
                        rowGap="4px"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <item.icon
                            className={classNames(item.current ? '' : '', 'flex-shrink-0 h-6 w-6')}
                            aria-hidden="true"
                            style={{
                                marginRight: '0px !important',
                            }}
                        />
                        {/* <p className="truncate sidebar-menu__title ...">{item.name}</p> */}
                        <p className="text-center sidebar-menu__title ...">{item.name}</p>
                    </Flex>
                )}
            </NavLink>
        );
    }
    return (
        <NavLink
            exact={exact}
            key={item.name}
            to={isExternalLink ? { pathname: item.href } : item.href ?? '/'}
            target={isExternalLink ? '_blank' : ''}
            onClick={item.action === 'logout' ? handleSignOut : () => {}}
            className={classNames(
                item.current ? '' : '',
                `group flex items-center py-2 font-medium rounded-md sidebar-menu__item ${
                    sidebarCollapsed ? 'text-sm isCollapsed px-2' : 'text-xs'
                } `,
                isMainActive ? 'sidebar-menu__active' : ''
            )}
            activeClassName="sidebar-menu__active"
        >
            {sidebarCollapsed && (
                <>
                    <item.icon
                        className={classNames(
                            item.current ? '' : '',
                            'mr-3 flex-shrink-0 h-6 w-6',
                            isActive && item.setFillOnActive ? 'sidebar-menu__icon-active' : ''
                        )}
                        aria-hidden="true"
                        width={20}
                        height={20}
                    />
                    <span
                        style={{
                            ...(item?.underline ? { borderBottom: '1.2px solid #142C4A' } : {}),
                        }}
                    >
                        {item.name}
                    </span>
                </>
            )}

            {!sidebarCollapsed && (
                <Flex
                    flex={1}
                    direction="column"
                    rowGap="4px"
                    alignItems="center"
                    justifyContent="center"
                >
                    <item.icon
                        className={classNames(item.current ? '' : '', 'flex-shrink-0 h-6 w-6')}
                        aria-hidden="true"
                        style={{
                            marginRight: '0px !important',
                        }}
                    />
                    {/* <p className="truncate sidebar-menu__title ...">{item.name}</p> */}
                    <p className="text-center sidebar-menu__title ...">{item.name}</p>
                </Flex>
            )}
        </NavLink>
    );
};

const getLastAuthUser = () => {
    return Object.keys(localStorage).reduce((acc, key) => {
        if (key.includes('LastAuthUser')) {
            acc = localStorage.getItem(key);
        }
        return acc;
    }, '');
};

const getUserData = () => {
    const actualUserAuth = getLastAuthUser();
    const users = Object.keys(localStorage).reduce((acc, key) => {
        if (key.includes('userData')) {
            const username = JSON.parse(localStorage.getItem(key)).Username;
            const params = Object.keys(localStorage).reduce((token, tokenKey) => {
                if (tokenKey.includes('idToken') && tokenKey.includes(username)) {
                    token = decodeToken(localStorage.getItem(tokenKey));
                }
                return token;
            }, null);
            acc.push({
                username,
                is_active: actualUserAuth === username,
                params,
            });
        }
        return acc;
    }, []);

    return users?.sort((a, b) => b.is_active - a.is_active);
};

const Sidebar = () => {
    const activeUserSwitch = useGetValueFeatureFlag('sidebar', 'user_switch');
    const { sidebarCollapsed } = useSelector((state) => state.utils);
    // get sales channels
    const [, salesChannels] = useAllSalesChannel();
    const [changeUserMerchant, changeUserMerchantStatus] = useChangeUserMerchantMutation();
    const history = useHistory();
    const user = useCurrentUser();
    const dispatch = useDispatch();
    const [isAdmin] = usePermissionAdmin();
    const location = useLocation();
    const [nonCollapsedMenuIsOpen, setNonCollapsedMenuIsOpen] = useState(false);
    const [inSettingAndPreferences, setInSettingAndPreferences] = useState(false);

    const setSidebarOpen = () => {
        dispatch(toggleSidebar());
    };

    useEffect(() => {
        if (location.pathname === '/settings-and-preferences') {
            setNonCollapsedMenuIsOpen(true);
            setInSettingAndPreferences(true);
        } else {
            setNonCollapsedMenuIsOpen(false);
            setInSettingAndPreferences(false);
        }
    }, [location]);

    const handleSignOut = async () => {
        await Auth.signOut();
        onLogout();
        window.location.replace('/');
    };

    const handleSettingsAndPreferences = () => {
        history.push('/settings-and-preferences');
    };

    const first_name = user?.userData?.firstname ?? '';
    const last_name = user?.userData?.lastname ?? '';

    const initials = first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase();

    const hasSalesChannelWithConsolidation = useMemo(() => {
        return salesChannels.some(
            (salesChannel) =>
                salesChannel.is_enabled && salesChannel?.fulfillment_consolidation?.enabled === true
        );
    }, [salesChannels]);

    const userMerchants = useMemo(() => {
        return user?.userData?.invites ?? [];
    }, [user]);

    const userMerchantId = useMemo(() => {
        return user?.userData?.merchant_id?._id;
    }, [user]);

    const handleChangeMerchant = async (invite_id) => {
        const response = await changeUserMerchant({
            invite_id,
        });
        console.log({ response });
        onLogout();
        window.location.replace('/');
    };

    const localStorageUsers = getUserData();

    const switchUser = (username) => {
        const localKeys = [
            'amplify-signin-with-hostedUI',
            'userLogged',
            'preparationSettings',
            'merchantCurrency',
            'generalSettings',
            'merchantDecimalSupport',
            'masterStates',
            'merchantLanguage',
            'merchantCountry',
        ];

        localKeys.forEach((k) => localStorage.removeItem(k));

        Object.keys(localStorage).forEach((key) => {
            if (key.includes('accessToken')) {
                localStorage.removeItem(key);
            }
        });

        Object.keys(localStorage).forEach((key) => {
            if (key.includes('LastAuthUser')) {
                localStorage.setItem(key, username);
            }
        });

        setTimeout(() => {
            window.location.href = '/';
        }, 500);
    };

    return (
        <SidebarWrapper
            className={`flex flex-col sidebar__menu ${
                sidebarCollapsed ? 'w-64 sidebar__opened' : 'w-24 sidebar__closed'
            }`}
        >
            <div
                className={`sidebar__chevron-button ${sidebarCollapsed ? 'active' : ''}`}
                role="button"
                onClick={setSidebarOpen}
            >
                <ChevronRightIcon />
            </div>

            <div className="flex-1 flex flex-col min-h-0">
                {/*  LOGO */}
                <div
                    className="flex items-center h-auto flex-shrink-0 px-6 py-5"
                    style={{ backgroundColor: '#F5F5F5' }}
                >
                    <LogoColorBeflowSVG
                        className={` w-auto  ${sidebarCollapsed ? '' : 'hidden'}`}
                        src={LogoColorBeflowSVG}
                        alt="Workflow"
                        style={{ height: '54px', marginTop: '8px' }}
                    />

                    <LogoSimpleSVG
                        className={`h-8 w-auto m-auto ${sidebarCollapsed ? 'hidden' : ''}`}
                        alt="Workflow"
                        style={{ height: '40px', marginTop: '8px', marginBottom: '12px' }}
                    />
                </div>
                {/*  USER MENU */}

                {sidebarCollapsed && (
                    <UserSidebar>
                        <Menu
                            as="div"
                            onClick={() => {
                                setNonCollapsedMenuIsOpen(!nonCollapsedMenuIsOpen);
                            }}
                        >
                            <Menu.Button
                                className="group w-full rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700"
                                onClick={() => {
                                    setNonCollapsedMenuIsOpen(!nonCollapsedMenuIsOpen);
                                }}
                            >
                                <span
                                    className="flex w-full justify-between items-center"
                                    onClick={() => {
                                        setNonCollapsedMenuIsOpen(!nonCollapsedMenuIsOpen);
                                    }}
                                >
                                    <span className="flex min-w-0 items-center justify-between space-x-3">
                                        <div
                                            className="h-10 w-10 rounded-full flex items-center justify-center font-medium"
                                            style={{ backgroundColor: '#536D8F', color: 'white' }}
                                        >
                                            {initials}
                                        </div>

                                        <span className="flex-1 flex flex-col min-w-0">
                                            <span className="text-gray-500 text-sm truncate">
                                                <Tag
                                                    style={{
                                                        color: '#22505E',
                                                        border: '1px solid #22505E',
                                                        marginBottom: '0.5rem',
                                                    }}
                                                >
                                                    {user.cognitoUser.profile_name}
                                                </Tag>
                                            </span>

                                            <span className="text-sm font-medium truncate px-2">
                                                {user.userData.firstname || ''}{' '}
                                                {user.userData.lastname || ''}
                                            </span>
                                        </span>
                                    </span>
                                    <ArrowActionsIconStyled
                                        className="settings-arrow-action"
                                        nonCollapsedMenuIsOpen={nonCollapsedMenuIsOpen}
                                    />
                                </span>
                            </Menu.Button>

                            <Transition as={Fragment} show={nonCollapsedMenuIsOpen}>
                                <Menu.Items
                                    className="z-10 mx-3 origin-bottom right-0 left-0 mt-1 bg-white divide-y divide-gray-200 focus:outline-none border-none custom-menu-element-wrapper"
                                    style={{
                                        width: 220,
                                        backgroundColor: 'rgb(236, 237, 239)',
                                    }}
                                >
                                    {Array.from(userMerchants)
                                        .sort((a) => {
                                            if (String(a.merchant._id) === String(userMerchantId)) {
                                                return -1;
                                            }
                                            return 0;
                                        })
                                        .map((merchant) => {
                                            const isSameMerchant =
                                                String(merchant?.merchant?._id) ===
                                                String(userMerchantId);

                                            return (
                                                <div
                                                    key={merchant.merchant_id}
                                                    className="flex flex-row px-2 justify-between"
                                                >
                                                    <Menu.Item
                                                        onClick={() =>
                                                            handleChangeMerchant(merchant._id)
                                                        }
                                                        loading={changeUserMerchantStatus.isLoading}
                                                        disabled={isSameMerchant}
                                                    >
                                                        <div className="flex flex-row items-center justify-between gap-2 cursor-pointer">
                                                            {isSameMerchant ? (
                                                                <BiMapPin />
                                                            ) : (
                                                                <HiOutlineSwitchHorizontal />
                                                            )}
                                                            <b
                                                                role="button"
                                                                className="block px-2 py-2 text-sm"
                                                                style={{
                                                                    fontWeight: isSameMerchant
                                                                        ? 700
                                                                        : 500,
                                                                }}
                                                            >
                                                                {merchant?.merchant?.merchant_name}
                                                            </b>
                                                        </div>
                                                    </Menu.Item>
                                                </div>
                                            );
                                        })}
                                    <div className="inline-block px-2">
                                        <Menu.Item onClick={handleSettingsAndPreferences}>
                                            <CustomMenuElement
                                                className="flex flex-row items-center gap-1 cursor-pointer align-left"
                                                pathFillColor={true}
                                                inSettingAndPreferences={inSettingAndPreferences}
                                            >
                                                <UserConfigIcon />
                                                <p role="button" className="block px-2 py-2">
                                                    Ajustes y preferencias
                                                </p>
                                            </CustomMenuElement>
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </UserSidebar>
                )}

                {!sidebarCollapsed && (
                    <UserSidebar>
                        <Menu as="div">
                            <Menu.Button className="group w-full rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700">
                                <div className="flex flex-col items-center justify-center">
                                    <span className="flex-1 flex flex-col min-w-0">
                                        <span
                                            className="text-gray-500 text-sm truncate"
                                            style={{ maxWidth: 100, padding: '0 0.5rem' }}
                                        >
                                            <Tag
                                                style={{
                                                    display: 'block',
                                                    color: '#22505E',
                                                    border: '1px solid #22505E',
                                                    marginBottom: '0.5rem',
                                                    marginRight: 0,
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                            >
                                                {user.cognitoUser.profile_name}
                                            </Tag>
                                        </span>
                                    </span>

                                    <div
                                        className="h-10 w-10 rounded-full flex items-center justify-center  font-medium"
                                        style={{ backgroundColor: '#536D8F', color: 'white' }}
                                    >
                                        {initials}
                                    </div>
                                </div>
                            </Menu.Button>

                            <Transition as={Fragment}>
                                <Menu.Items
                                    className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                                    style={{
                                        width: 220,
                                    }}
                                >
                                    <div className="inline-block px-2">
                                        <Menu.Item onClick={handleSettingsAndPreferences}>
                                            <CustomMenuElement
                                                className="flex flex-row items-center gap-1 cursor-pointer align-left"
                                                pathFillColor={true}
                                                inSettingAndPreferences={inSettingAndPreferences}
                                            >
                                                <UserConfigIcon />
                                                <p role="button" className="block px-2 py-2">
                                                    Ajustes y preferencias
                                                </p>
                                            </CustomMenuElement>
                                        </Menu.Item>
                                    </div>
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </UserSidebar>
                )}

                {/*  SIDEBAR NAVIGATIONS */}
                <MainSidebar className="flex-1 flex flex-col justify-between overflow-y-auto overflow-x-hidden">
                    {/*  MENU */}
                    <nav
                        // className={`px-4 pt-8 pb-2 ${sidebarCollapsed ? 'space-y-6' : 'space-y-1'}`}
                        className={`flex flex-col ${
                            sidebarCollapsed ? 'items-start' : 'items-center'
                        } pt-6`}
                        style={
                            sidebarCollapsed
                                ? {
                                      gap: '1.25rem',
                                  }
                                : {}
                        }
                    >
                        {navigation.map((item, key) => (
                            <SidebarMenuItem
                                key={key}
                                item={item}
                                sidebarCollapsed={sidebarCollapsed}
                                currentPath={location?.pathname}
                                showConsolidation={hasSalesChannelWithConsolidation}
                            />
                        ))}
                    </nav>

                    <div>
                        <Divider
                            orientation="right"
                            style={{
                                margin: '1.25rem auto 1rem',
                                minWidth: '80%',
                                width: '80%',
                            }}
                        />

                        <nav className={`px-2 pb-2 flex flex-col ${sidebarCollapsed ? '' : ''}`}>
                            <SidebarMenuItem
                                key="helpcenter-item"
                                item={{
                                    name: 'Centro de ayuda',
                                    href: 'https://be-flow.com/help-center/?auth_token=VVczmYXAUbASCtYKIiau',
                                    icon: ExternalLinkIcon,
                                    current: false,
                                    underline: true,
                                }}
                                sidebarCollapsed={sidebarCollapsed}
                                currentPath={location?.pathname}
                            />

                            {activeUserSwitch && (
                                <Menu as="div">
                                    <Menu.Button className="group w-full rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700">
                                        <Flex direction="row" alignItems="center">
                                            <PiUserSwitchDuotone
                                                size={24}
                                                style={{ marginLeft: '6px' }}
                                            />
                                            <p style={{ marginLeft: '14px' }}>Cambiar de cuenta</p>
                                        </Flex>
                                    </Menu.Button>
                                    <Transition as={Fragment}>
                                        <Menu.Items
                                            className="z-10 mx-3 absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                                            style={{
                                                marginTop: '-4.5rem',
                                                marginLeft: sidebarCollapsed ? '16.3rem' : '6.6rem',
                                                width: 220,
                                                bottom: '1rem',
                                            }}
                                        >
                                            {localStorageUsers?.map((user) => {
                                                return (
                                                    <div
                                                        className="inline-block px-2"
                                                        key={user?.username}
                                                    >
                                                        <Menu.Item
                                                            onClick={() => {
                                                                if (!user?.is_active)
                                                                    switchUser(user?.username);
                                                            }}
                                                        >
                                                            <CustomMenuElement className="flex flex-column items-center gap-1 cursor-pointer align-left">
                                                                <p
                                                                    role="button"
                                                                    className="block px-2 py-2"
                                                                >
                                                                    <p
                                                                        style={{
                                                                            fontWeight:
                                                                                user?.is_active
                                                                                    ? '600'
                                                                                    : '',
                                                                        }}
                                                                    >
                                                                        {user?.params
                                                                            ?.merchant_name ??
                                                                            user?.username}
                                                                    </p>

                                                                    <small>
                                                                        {user?.params?.profile_name}
                                                                    </small>
                                                                </p>
                                                            </CustomMenuElement>
                                                        </Menu.Item>
                                                    </div>
                                                );
                                            })}

                                            <div className="inline-block px-2">
                                                <Menu.Item
                                                    onClick={() =>
                                                        (window.location.href = '/login?type=multi')
                                                    }
                                                >
                                                    <CustomMenuElement className="flex flex-row items-center gap-1 cursor-pointer align-left">
                                                        <p
                                                            role="button"
                                                            className="block px-2 py-2"
                                                        >
                                                            <b>+ Añadir otra cuenta</b>
                                                        </p>
                                                    </CustomMenuElement>
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            )}

                            {isAdmin &&
                                adminNavigation.map((item, key) => (
                                    <SidebarMenuItem
                                        key={key}
                                        item={item}
                                        sidebarCollapsed={sidebarCollapsed}
                                        handleSignOut={handleSignOut}
                                        currentPath={location?.pathname}
                                    />
                                ))}

                            <SidebarMenuItem
                                key="logout-item"
                                item={{
                                    name: 'Cerrar sesión',
                                    href: '/logout',
                                    icon: MdOutlineLogout,
                                    current: false,
                                    action: 'logout',
                                }}
                                sidebarCollapsed={sidebarCollapsed}
                                handleSignOut={handleSignOut}
                                currentPath={location?.pathname}
                            />
                        </nav>
                    </div>
                </MainSidebar>
            </div>
        </SidebarWrapper>
    );
};

export default React.memo(Sidebar);
