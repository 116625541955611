import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
// import useAllStripePlans from 'hooks/useAllStripePlans';
// import Plans from './components/Plans';

const Payments = () => {
    // const [loading, data] = useAllStripePlans();
    return (
        <Container extraTitle="Planes">
            <PageHeader title="Pagos" breadcrumb={[{ name: 'Pagos', path: '/payments' }]} />
            {/* <Plans loading={loading} data={data} /> */}
        </Container>
    );
};

export default Payments;
