import { isObjectEmpty } from 'helpers/filterObjectByCustom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPreparationPreferenceByMerchant } from 'redux/features/PreparationsPreferences/getPreparationPreferenceByMerchant';

const cacheData = JSON.parse(localStorage.getItem('preparationSettings') || '{}');

const useGetPreparationPreference = () => {
    const dispatch = useDispatch();
    const { loading, data, success, error, finish } = useSelector(
        (state) => state.getPreparationPreferenceByMerchant
    );
    useEffect(() => {
        if (isObjectEmpty(data) && !success) {
            dispatch(getPreparationPreferenceByMerchant());
        }
    }, []);

    const preparationSettings = isObjectEmpty(data) ? cacheData : data;

    return [loading, finish, preparationSettings, error];
};

export default useGetPreparationPreference;
