import ImageNotFound from 'assets/ImageNotFound.svg';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
    object-fit: cover;
    cursor: ${(props) => (props.$hasImage ? 'pointer' : 'default')};
    width: 32px !important;
    height: 32px !important;
    background-color: #f0f0f0;
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000;
`;

const ModalContent = styled.div`
    position: relative;
    max-width: 80%;
    max-height: 80%;
    background-color: black;
`;

const CloseButton = styled.div`
    position: absolute;
    top: -25px;
    right: -15px;
    cursor: pointer;
    color: white;
    font-size: 20px;
`;

const ThumbnailImage = ({ imageSrc = '', alt = '' }) => {

    const active = true;
    if(!active) return ""
    
    const [isOpen, setIsOpen] = useState(false);
    const [enableModal, setEnableModal] = useState(false);

    const openModal = () => {
        if (!imageSrc || !enableModal) return;
        setIsOpen(true);
    };

    const closeModal = () => setIsOpen(false);

    const stopPropagation = (event) => {
        event.stopPropagation();
    };

    useEffect(() => {
        if (imageSrc) setEnableModal(true);
        return () => setEnableModal(false);
    }, [imageSrc]);

    const changeImageSize = (e) => {
        return String(e ?? '').replace(/\b\d{1,3}-\d{1,3}\b/g, '512-512');
    };
    return (
        <div>
            <StyledImage
                $hasImage={enableModal}
                src={imageSrc ?? ImageNotFound}
                alt={alt}
                loading="lazy"
                onClick={openModal}
                onError={(e) => {
                    e.target.src = ImageNotFound;
                    setEnableModal(false);
                }}
            />

            {isOpen && (
                <Modal onClick={closeModal}>
                    <ModalContent onClick={stopPropagation}>
                        <img src={changeImageSize(imageSrc)} alt={alt} width={512} />
                        <CloseButton onClick={closeModal}>x</CloseButton>
                    </ModalContent>
                </Modal>
            )}
        </div>
    );
};

export default ThumbnailImage;
