import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getGeneralByMerchant = createAsyncThunk('general/getOne', async (state) => {
    try {
        const merchant_id = JSON.parse(localStorage.getItem('userLogged') || '{}')?.merchant_id
            ?._id;
        const response = await AndesClient.get(`settings/generals/merchants/${merchant_id}`);
        localStorage.setItem('generalSettings', JSON.stringify(response.data));
        return response.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getGeneralByMerchantSlice = createSlice({
    name: 'getPickupPreferenceByMerchant',
    initialState: {
        loading: false,
        data: {},
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [getGeneralByMerchant.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getGeneralByMerchant.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
                success: true,
            };
        },
        [getGeneralByMerchant.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
                success: false,
            };
        },
    },
});
export default getGeneralByMerchantSlice.reducer;
