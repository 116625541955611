import { Form, Select, Space, Table, Tag } from 'antd';
import AntdTable from 'components/AntdTable';
import Flex from 'components/Utils/Flex';
import FormStyled from 'components/Utils/Form';
import dayjs from 'helpers/dayjs';
import { useEffect, useMemo, useState } from 'react';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import { FiChevronLeft } from 'react-icons/fi';
import { GoGitMerge, GoSync } from 'react-icons/go';
import { useLazyGetStockProductsHistoryQuery } from 'redux/features/Stock';
import styled from 'styled-components';
import {
    StockHistoryBackButton,
    StockHistoryInfoStyled,
    StockHistorySectionStyled,
} from '../styles';
import LocationsSelector from './LocationsSelector';
import { itemsQuantity } from 'components/V2/ShowQuantityItems/config';
import { FormItem } from 'components/Form';

const types = {
    INCREASE: 'Aumentó',
    DROP: 'Bajó',
    SET: 'Ajuste',
    UNIFIED: 'Unificado',
};

const statusDict = {
    pending: (
        <Tag bordered={false} color="warning">
            Pendiente
        </Tag>
    ),
    done: (
        <Tag bordered={false} color="success">
            Completado
        </Tag>
    ),
    error: (
        <Tag bordered={false} color="error">
            Error
        </Tag>
    ),
    process: (
        <Tag bordered={false} color="processing">
            Procesando
        </Tag>
    ),
};

const StockHistoryDetailsTableStyled = styled.div`
    background-color: white;
    margin: 4px;
    padding: 1rem;
    width: 100%;
`;

const StockHistoryTable = styled(Table)`
    width: 100%;
    max-width: 1280px;
    td,
    .ant-table-cell {
        background: white !important;
    }
    .ant-table-thead {
        th {
            border-bottom: 1px solid #e4e8ec;
            border-top: 0px;
        }
    }
`;

const StockHistoryDetailsTable = ({ data }) => {
    const tableColumns = useMemo(
        () => [
            {
                title: 'Canal de venta',
                dataIndex: 'alias',
                key: 'alias',
                render: (value, row) => value ?? row?.platform ?? 'N/A',
                align: 'left',
            },
            {
                title: 'Unificación de stock',
                dataIndex: 'unified_stock',
                key: 'unified_stock',
                render: (value) => (statusDict[value] ?? value ? 'Si' : 'No'),
                align: 'left',
            },
            {
                title: 'Estado',
                dataIndex: 'status',
                key: 'status',
                render: (value) => statusDict[value] ?? value,
                align: 'left',
            },
            {
                title: 'Detalles',
                render: (value) => {
                    if (
                        (value?.done_details && value?.done_details !== 'null') ||
                        (value?.error_details && value?.error_details !== 'null')
                    ) {
                        return (
                            <a
                                style={{ textDecoration: 'underline' }}
                                target="_blank"
                                href={`
                        https://beflow-prod-stocks.s3.amazonaws.com/${
                            value.status === 'error' ? value.error_details : value?.done_details
                        }
                        `}
                                rel="noreferrer"
                            >
                                Descargar detalles
                            </a>
                        );
                    } else {
                        return (
                            <a
                                style={{
                                    textDecoration: 'through',
                                    opacity: '0.6',
                                    cursor: 'not-allowed',
                                }}
                            >
                                Detalles no disponibles
                            </a>
                        );
                    }
                },
                align: 'left',
            },
            {
                title: 'Creación',
                dataIndex: 'created_at',
                key: 'created_at',
                render: (value) => dayjs(value).format('DD/MM/YYYY HH:mm:ss'),
                align: 'left',
            },
            {
                title: 'Ultima actualización',
                dataIndex: 'updated_at',
                key: 'updated_at',
                render: (value) => dayjs(value).format('DD/MM/YYYY HH:mm:ss'),
                align: 'left',
            },
        ],
        [data]
    );
    const tableData = useMemo(() => data, [data]);

    return (
        <StockHistoryDetailsTableStyled>
            <StockHistoryTable
                dataSource={tableData}
                columns={tableColumns}
                rowKey="id"
                size="small"
                pagination={{
                    pageSize: 10,
                    hideOnSinglePage: true,
                    size: 'small',
                }}
            />
        </StockHistoryDetailsTableStyled>
    );
};

const StockHistory = ({ sku, location_id, setRenderView, productId }) => {
    const [form] = Form.useForm();
    const watchLocationId = Form.useWatch('location_id', form);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const query_location_id = watchLocationId || location_id;
    const [pagination, setPagination] = useState({ limit: 25, page: 1 });

    const [getStockProductsHistory, { data, isLoading }] = useLazyGetStockProductsHistoryQuery(
        {
            product_id: productId,
            location_id: query_location_id,
        },
        {
            skip: !query_location_id,
            pollingInterval: 1000 * 5,
        }
    );

    useEffect(() => {
        if (location_id) {
            form.setFieldsValue({
                location_id,
            });
        }
    }, [location_id]);

    const tableColumns = useMemo(
        () => [
            {
                title: 'Fecha',
                dataIndex: 'created_at',
                align: 'left',
                key: 'created_at',
                render: (value) => {
                    return (
                        <div>
                            {dayjs(value).format('DD/MM/YYYY')}
                            <br />
                            {dayjs(value).format('HH:mm:ss')}
                        </div>
                    );
                },
                //  transform to timestamp and sort
                sorter: (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at),
            },
            {
                title: 'Origen',
                align: 'left',
                dataIndex: 'from_location_name',
                key: 'from_location_name',
                sorter: (a, b) => String(a.from_location_name).localeCompare(b.from_location_name),
                render: (value, row) => {
                    return (
                        <div> 
                            {value} 
                            <br/>
                            IP: {row?.ip ?? ""}
                        </div>
                    )
                }
            },
            {
                title: 'Destino',
                align: 'left',
                dataIndex: 'to_location_name',
                key: 'to_location_name',
                sorter: (a, b) => String(a.to_location_name).localeCompare(b.to_location_name),
            },

            {
                title: 'Descripción',
                align: 'left',
                dataIndex: 'description',
                key: 'description',
                render: (value, row) => {
                    if (value) return value;

                    return `Actualización de inventario mediante ${row.type}`;
                },
                sorter: (a, b) => String(a.description).localeCompare(b.description),
            },
            {
                title: 'Variación inventario',
                align: 'left',
                dataIndex: 'type',
                key: 'type',
                render: (value) => {
                    return (
                        <Flex direction="row" alignItems="center" gap="8px">
                            {value === 'UNIFIED' && (
                                <GoGitMerge
                                    style={{ width: '16px', height: '16px', fontSize: '16px' }}
                                />
                            )}
                            {value === 'INCREASE' && (
                                <BsArrowUpShort
                                    style={{
                                        width: '22px',
                                        height: '22px',
                                        fontSize: '22px',
                                        color: 'green',
                                    }}
                                />
                            )}
                            {value === 'DROP' && (
                                <BsArrowDownShort
                                    style={{
                                        width: '22px',
                                        height: '22px',
                                        fontSize: '22px',
                                        color: 'red',
                                    }}
                                />
                            )}

                            {value === 'SET' && (
                                <GoSync
                                    style={{ width: '16px', height: '16px', fontSize: '16px' }}
                                />
                            )}

                            <span>{types[value] ?? value}</span>
                        </Flex>
                    );
                },
                sorter: (a, b) => String(a.type).localeCompare(b.type),
            },
            {
                title: 'Cantidad ingresada',
                dataIndex: 'quantity',
                key: 'quantity',
                align: 'center',
                render: (value, row) => {
                    if (row.type === 'INCREASE') {
                        return <span style={{ color: 'green' }}>+{value}</span>;
                    }

                    if (row.type === 'DROP') {
                        return <span style={{ color: 'red' }}>-{value}</span>;
                    }

                    return value;
                },
                sorter: (a, b) => {
                    const a_quantity = a.type === 'increase' ? a.quantity : -a.quantity;
                    const b_quantity = b.type === 'increase' ? b.quantity : -b.quantity;
                    return a_quantity - b_quantity;
                },
            },
            {
                title: 'Cantidad actual',
                dataIndex: 'current_quantity',
                key: 'current_quantity',
                sorter: (a, b) => a.current_quantity - b.current_quantity,
            },
            {
                title: 'Stock de seguridad',
                dataIndex: 'stock_security_value',
                key: 'stock_security_value',
                align: 'center',
            },
            {
                title: 'Cantidad notificada al canal',
                dataIndex: 'notify_quantity',
                key: 'notify_quantity',
                align: 'center',
            },
            {
                title: 'Responsable',
                align: 'left',
                dataIndex: 'author',
                key: 'author',
                render: (value) => value || 'OMS',
                sorter: (a, b) => String(a?.author).localeCompare(b?.author),
            },
        ],
        [data, expandedKeys]
    );

    const tableData = useMemo(() => Array.from(data?.data ?? []), [data?.data]);

    const onChangePagination = (paginationData) => {
        setPagination({
            limit: paginationData.pageSize,
            page: paginationData.current,
        });
    };

    const handlePaginationSize = (perPageValue) => {
        setPagination({
            page: 1,
            limit: perPageValue,
        });
    };

    useEffect(() => {
        if (location_id && productId) {
            getStockProductsHistory({
                product_id: productId,
                location_id: location_id,
                per_page: pagination?.limit,
                page: pagination?.page,
            });
        }
    }, [location_id, pagination, productId]);

    return (
        <StockHistorySectionStyled>
            <StockHistoryBackButton role="button" onClick={() => setRenderView('stock_form')}>
                <div className="stock-history__back-button">
                    <FiChevronLeft />
                </div>
                <b className="stock-history__back-button-title">Historial de inventario</b>
            </StockHistoryBackButton>
            <br />
            <FormStyled layout="vertical" form={form}>
                <Space direction="horizontal">
                    <div style={{ minWidth: 400, maxWidth: 600 }}>
                        <LocationsSelector />
                    </div>
                    <FormItem name="per_page" label="Mostrar filas">
                        <Select
                            placeholder="25"
                            onChange={handlePaginationSize}
                            value={pagination.limit ?? '25'}
                        >
                            {itemsQuantity.map((qty) => (
                                <Select.Option key={qty.quantity} value={qty.quantity}>
                                    {qty.quantity}
                                </Select.Option>
                            ))}
                        </Select>
                    </FormItem>
                </Space>
            </FormStyled>
            <br />
            <StockHistoryInfoStyled>
                <Flex direction="column">
                    <span style={{ fontSize: '14px' }}>
                        <b>Código/SKU/ID seleccionado</b>
                    </span>
                    <span style={{ fontSize: '13px' }}>{sku}</span>
                </Flex>
                <Flex direction="column">
                    <span style={{ fontSize: '14px' }}>
                        <b>Inventario</b>
                    </span>
                    <p style={{ fontSize: '13px' }}>{data?.[0]?.current_quantity ?? 0}</p>
                </Flex>
            </StockHistoryInfoStyled>
            <br />
            <div className="mb-4">
                <AntdTable
                    styleV2
                    removeHeaderBackground
                    loading={isLoading}
                    dataSource={tableData}
                    columns={tableColumns}
                    rowKey="id"
                    paginationStyle={true}
                    setFiltersDisplayed={() => {}}
                    onChange={onChangePagination}
                    pagination={{
                        position: ['bottomCenter'],
                        showSizeChanger: false,
                        showQuickJumper: false,
                        showLessItems: true,
                        total: data?.meta_data?.total_count ?? 0,
                        current: pagination?.page ?? 1,
                        pageSize: pagination?.limit ?? 25,
                    }}
                    expandable={{
                        onExpand: (expanded, record) => {
                            if (expanded) {
                                setExpandedKeys([...expandedKeys, record?.id]);
                            } else {
                                setExpandedKeys(expandedKeys.filter((key) => key !== record?.id));
                            }
                        },
                        expandedRowRender: (data) => (
                            <StockHistoryDetailsTable
                                data={data?.stock_update_sales_channels ?? []}
                            />
                        ),
                        rowExpandable: () => true,
                        expandedRowKeys: expandedKeys,
                        expandedRowClassName: (_, index) => {
                            if (index % 2 === 0) {
                                return 'manifest_table__row-expanded isEven';
                            }
                            return 'manifest_table__row-expanded isOdd';
                        },
                    }}
                    rowClassName={(record, index) => {
                        if (expandedKeys.includes(record?._id)) {
                            if (index % 2 === 0) {
                                return 'manifest_table__row-expanded isEven';
                            }
                            return 'manifest_table__row-expanded isOdd';
                        }
                    }}
                />
            </div>
        </StockHistorySectionStyled>
    );
};

export default StockHistory;