import { Auth } from 'aws-amplify';
import { useState, useEffect } from 'react';

const usePermissionAdmin = () => {
    const [userIsAdminRender, setUserIsAdminRender] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [auth, setAuth] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const handleAuth = (idTokenPayload) => {
        setUserIsAdminRender(idTokenPayload.profile_admin === 'true');
        setIsSuperAdmin(idTokenPayload.profile_superadmin === 'true');
    };

    useEffect(() => {
        setIsLoading(true);
        Auth.currentSession()
            .then((session) => {
                const idToken = session.idToken;
                handleAuth(idToken?.payload);
            })
            .catch(() => setAuth(false))
            .finally(() => setIsLoading(false));
    }, []);

    return [userIsAdminRender, isSuperAdmin, { auth, isLoading }];
};

export default usePermissionAdmin;
