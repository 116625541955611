import styled from 'styled-components';

export const PackageDetailsRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const PackageDetailsItem = styled.div`
    display: flex;
    flex-direction: row;
`;

export const PackageDetailsTitle = styled.div`
    ${({ width }) => width && `width: ${width}`};
    white-space: nowrap;
    margin-right: 0.5rem;
    color: #1f6373;
    ${({ bold }) => bold && `font-weight: 600`}
`;
