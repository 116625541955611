import { Card } from 'antd';
import styled from 'styled-components';

export const TimeLineStyled = styled(Card)`
    border-radius: 0px;
    ${(props) => props.withoutBorders && 'border: none'}};

    .ant-timeline-item {
        display: flex;
        align-items: center;
    }

    .ant-timeline-item-content {
        min-height: 0px !important;
        top: -4px;
    }
`;
