const { Auth } = require('aws-amplify');

export const getTokenId = async () => {
    try {
        const res = await Auth.currentSession();
        return res.getIdToken();
    } catch (error) {
        return null;
    }
};

export const userIsAdmin = async () => {
    let idToken = await getTokenId();

    if (!idToken?.payload) return false;

    return (
        idToken?.payload?.user_profile_admin === 'true' ||
        idToken?.payload?.profile_superadmin === 'true' ||
        idToken?.payload?.profile_admin === 'true'
    );
};
