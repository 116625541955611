import { cloneElement } from 'react';
import { Form } from 'antd';
import Modal from './index';

const FormModal = ({ modalProps, formProps, formComponent }) => {
    const [form] = Form.useForm();
    return (
        <Modal onOk={form.submit} {...modalProps}>
            {cloneElement(formComponent, { form, ...formProps })}
        </Modal>
    );
};

export default FormModal;
