import { Skeleton } from 'antd';
import TCard from 'components/TCard';
import Flex from 'components/Utils/Flex';
import { useMemo, useState } from 'react';
import { Tab, TabContent, TabWrapper } from './styles';

const Tabs = ({ withBorder = false, loading, tabs = [] }) => {
    const [tabActive, setTabActive] = useState('');

    useMemo(() => {
        if (tabs.length > 0) {
            const defaultTab = tabs.find((tab) => tab.default);
            if (defaultTab.id) {
                return setTabActive(defaultTab.id);
            }
            return setTabActive(tabs[0].id);
        }
    }, [tabs]);

    return (
        <Flex direction="column">
            <TabWrapper withBorder={withBorder}>
                {loading ? (
                    <Skeleton />
                ) : (
                    tabs.map((tab, index) => (
                        <Tab
                            withBorder={withBorder}
                            key={index}
                            active={tabActive === tab.id && true}
                            onClick={() => setTabActive(tab.id)}
                        >
                            {tab.label}
                        </Tab>
                    ))
                )}
            </TabWrapper>

            <TabContent withBorder={withBorder}>
                {loading ? (
                    <TCard>
                        <Skeleton />
                    </TCard>
                ) : (
                    tabs.find((tab) => tab.id === tabActive)?.component ?? ''
                )}
            </TabContent>
        </Flex>
    );
};

export default Tabs;
