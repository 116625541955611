import { getTokenId } from 'data/tokenId';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { initGetPermissionsOfCurrentUser } from 'redux/features/Permissions/getPermissionsOfCurrentUser';

const useGetCurrentPermissions = () => {
    const dispatch = useDispatch();
    const [permissions, setPermissions] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);

    const getPermissions = async () => {
        let idToken = await getTokenId();
        if (idToken) {
            const {
                payload: { profile_permissions },
            } = idToken;
            setPermissions(JSON.parse(profile_permissions));
            setIsAdmin(
                idToken.payload.profile_admin === 'true' ||
                    idToken.payload.profile_superadmin === 'true'
            );
        }
    };

    useEffect(() => {
        getPermissions();
    }, []);

    useEffect(() => {
        if (permissions[0]) {
            dispatch(initGetPermissionsOfCurrentUser(permissions));
        }
    }, [permissions]);

    return [permissions, isAdmin];
};

export default useGetCurrentPermissions;
