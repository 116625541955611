/* eslint indent: 0 */
import { Form, Input, Select } from 'antd';
import FormStyled from 'components/Utils/Form';
import QuantityWithIncidence from './QuantityWithIncidence';
import Comment from './Comment';
import useCurrentUser from 'hooks/useCurrentUser';
import { useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createLineItemsManualIncidence } from 'redux/features/ManualIncidences/createLineItemsManualIncidence';
import ModalWithButton from 'components/ModalWithButton';
import Flex from 'components/Utils/Flex';
import NormalButton from 'components/Buttons/NormalButton';
import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import { FiAlertCircle } from 'react-icons/fi';
import { useGetAllUsersQuery } from 'redux/features/Users';

const IncidenceForm = ({ lineItemsSelected = [], setIsCloseIncidenceForm, fulfillment = {} }) => {
    const dispatch = useDispatch();

    const { _id: fulfillment_id, order } = fulfillment;

    const { loading } = useSelector((state) => state.createLineItemsManualIncidence);
    const [form] = Form.useForm();
    const allUsers = useGetAllUsersQuery();
    const [, , preparationPreferences] = useGetPreparationPreference();
    const loadingUsers = allUsers.isLoading;
    const usersData = allUsers.data;
    const { userData } = useCurrentUser();

    useEffect(() => {
        if (userData?._id) {
            form.setFieldsValue({
                responsible_id: userData?._id,
            });
        }
    }, [userData]);

    const onSubmit = (values) => {
        dispatch(
            createLineItemsManualIncidence({
                ...values,
                fulfillment_id,
                line_items_ids: lineItemsSelected.map((item) => item.line_item._id),
                order_id: order._id,
            })
        );
    };

    return (
        <div className="w-full px-5">
            <div className="w-full flex flex-col justify-center">
                <div className="mb-px flex w-full items-center justify-between">
                    <p className="font-bold">INCIDENCIA PRODUCTO(S) : </p>
                    <CloseOutlined
                        onClick={() => {
                            setIsCloseIncidenceForm(true);
                            form.resetFields();
                        }}
                        className="cursor-pointer"
                        style={{ color: '#1F6373', fontSize: '17px' }}
                    />
                </div>
                {lineItemsSelected[0] &&
                    lineItemsSelected.map((lis) => {
                        const { line_item } = lis;
                        return (
                            <span key={line_item._id} className="text-xs">
                                {line_item?.name}
                            </span>
                        );
                    })}
            </div>
            <div className="mt-4">
                <FormStyled
                    form={form}
                    layout={'vertical'}
                    name="manual-incidence-form"
                    onFinish={onSubmit}
                >
                    <Form.Item
                        name="incidence_type"
                        label="Tipo de incidencia"
                        rules={[{ required: true }]}
                    >
                        <Select>
                            <Select.Option value="insufficient_stock">
                                Stock insuficiente
                            </Select.Option>
                            <Select.Option value="product_in_bad_condition">
                                Producto en mal estado
                            </Select.Option>
                            <Select.Option value="other">Otro</Select.Option>
                        </Select>
                    </Form.Item>
                    {/* EN CASO DE SER TIPO STOCK INSUFICIENTE/PRODUCTO MAL ESTADO */}
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.incidence_type !== currentValues.incidence_type
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('incidence_type') === 'insufficient_stock' ||
                            getFieldValue('incidence_type') === 'product_in_bad_condition' ? (
                                <QuantityWithIncidence lineItemsSelected={lineItemsSelected} />
                            ) : null
                        }
                    </Form.Item>
                    {/* EN CASO DE SER TIPO OTRO */}
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.incidence_type !== currentValues.incidence_type
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('incidence_type') === 'other' && (
                                <Form.Item
                                    name={'ocurrence'}
                                    rules={[{ required: true }]}
                                    label={'Ingrese la ocurrencia'}
                                >
                                    <Input />
                                </Form.Item>
                            )
                        }
                    </Form.Item>
                    <Form.Item
                        name="responsible_id"
                        label="Responsable del reporte"
                        rules={[{ required: true }]}
                    >
                        <Select loading={loadingUsers}>
                            {usersData &&
                                usersData.map((ud) => {
                                    return (
                                        <Select.Option key={ud?._id} value={ud?._id}>
                                            {ud?.firstname + ' ' + ud?.lastname}
                                        </Select.Option>
                                    );
                                })}
                        </Select>
                    </Form.Item>
                    {/* COMENTARIO OPCIONAL */}
                    <Comment form={form} />
                    <Form.Item>
                        {!preparationPreferences?.preparations_preferences
                            ?.remove_fulfillment_on_incidence ? (
                            <NormalButton
                                buttonText={'Guardar Incidencia'}
                                onClick={() => form.submit()}
                                style={{ color: 'white', backgroundColor: '#2D3D76' }}
                            />
                        ) : (
                            <ModalWithButton
                                modalProps={{
                                    width: '800px',
                                    name: 'create-incidence-modal-confirm',
                                    title: (
                                        <span className="text-lg ">
                                            Confirmar creación de incidencia
                                        </span>
                                    ),
                                    bodyStyle: { paddingTop: '10px' },
                                    okText: 'Confirmar',
                                    cancelText: 'Cancelar',
                                    onOk: () => {
                                        form.submit();
                                    },
                                    okButtonProps: {
                                        disabled: loading,
                                    },
                                }}
                                buttonProps={{
                                    style: { color: 'white', backgroundColor: '#2D3D76' },
                                    buttonText: 'Guardar Incidencia',
                                    type: 'button',
                                }}
                            >
                                <Flex direction="column">
                                    <div className="flex">
                                        <FiAlertCircle
                                            className="mt-1"
                                            style={{ fontSize: '1.5rem' }}
                                        />
                                        <b className="ml-1 mb-2" style={{ fontSize: '1.5rem' }}>
                                            Atención
                                        </b>
                                    </div>

                                    <h2 style={{ fontSize: '1rem', color: 'red' }}>
                                        Al asociar una incidencia al pedido, el proceso de
                                        preparación actual se reversará mientras el SAC solventa la
                                        incidencia
                                    </h2>
                                </Flex>
                            </ModalWithButton>
                        )}
                    </Form.Item>
                </FormStyled>
            </div>
        </div>
    );
};

export default IncidenceForm;
