import { Toaster as HotToaster } from 'react-hot-toast';
import styled from 'styled-components';
import Flex from './Flex';

const ToastStyled = styled.div`
    background-color: ${(props) => props.bcolor ?? 'unset'} !important;
`;

export const ToastJSX = ({ title, description, action }) => {
    const background_colors = {
        success: '#b7f7c4',
        info: '#b3e6f5',
        warning: '#fbe7c6',
        error: '#F3D8DA',
    };

    return (
        <ToastStyled bcolor={background_colors[action]}>
            <Flex direction="column">
                <h2>{title || ''}</h2>
                <p>{description || ''}</p>
            </Flex>
        </ToastStyled>
    );
};

const Toaster = () => {
    return (
        <HotToaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
                className: '',
                duration: 6000,
                // style: {
                //     borderRadius: '10px',
                //     fontSize: '1.2rem',
                //     background: '#F3D8DA',
                //     color: '#fff',
                // },
                iconTheme: {
                    width: '3rem',
                    height: '3rem',
                },
                success: {
                    iconTheme: {
                        primary: '#D5E6DE',
                        secondary: '#255035',
                    },
                    style: {
                        background: '#D5E6DE',
                        color: '#255035',
                    },
                },
                error: {
                    style: {
                        background: '#F3D8DA',
                        color: '#79282C',
                    },
                },
            }}
        />
    );
};

export default Toaster;
