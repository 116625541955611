import { useDispatch } from 'react-redux';
import SecondaryButton from 'components/V2/Buttons/SecondaryButton';
import Dropdown from 'components/V2/Dropdown';
import Menu from 'components/V2/Menu';
import MenuItem from 'components/V2/Menu/MenuItem';
import { dispatchers } from 'components/V2/Filters/config';
import { ReactComponent as ChrevronDown } from 'assets/chevron-down.svg';
import { itemsQuantity } from './config';

const ShowQuantityItems = ({
    tablePageSize,
    handleChangePageSize,
    data,
    refetch,
    disabled = false,
    dispatchersType,
}) => {
    const dispatch = useDispatch();
    const { setFilters } = dispatchers[dispatchersType];

    const handleChangeQuantityItems = (e) => {
        const sizePage = parseInt(e.key);

        handleChangePageSize(sizePage);

        dispatch(
            setFilters({
                limit: sizePage,
                totalPages: Math.ceil(data?.totalDocs / sizePage),
                page: 1,
            })
        );

        refetch();
    };

    const menu = (
        <Menu onKeyDown={(e) => e.stopPropagation()}>
            {itemsQuantity?.map((q) => (
                <MenuItem key={q.quantity} onClick={handleChangeQuantityItems}>
                    {q.name}
                </MenuItem>
            ))}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} disabled={disabled}>
            <SecondaryButton icon={<ChrevronDown width={24} height={24} />} iconPosition="right">
                Mostrar {tablePageSize}
            </SecondaryButton>
        </Dropdown>
    );
};

export default ShowQuantityItems;
