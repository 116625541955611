import {
    pendingArrivalsDeliveryMethodOptions,
    pendingArrivalsPickupStoreStatusOptions,
} from './values';
import { pendingArrivalsFilterFields } from './fields';

export const pendingArrivalsFilters = {
    fields: [...pendingArrivalsFilterFields],
    values: {
        pickup_store_status: [...pendingArrivalsPickupStoreStatusOptions],
        'consolidation.status': [...pendingArrivalsPickupStoreStatusOptions],
        'preparationcd_transfer_pickupstore.status': [...pendingArrivalsPickupStoreStatusOptions],
        delivery_method: [...pendingArrivalsDeliveryMethodOptions],
    },
};
