import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getFulfillmentById } from '../Fulfillments/getOne';
import { toggleDrawer, toggleModal } from '../Utils';
import history from 'helpers/history';

export const createLineItemsManualIncidence = createAsyncThunk(
    'incidences/createLineItemsManualIncidence',
    async (data, state) => {
        try {
            const states = state.getState();
            const promise = AndesClient.post(`/incidents/createLineItemsManualIncidence`, data);

            toast.promise(promise, {
                loading: <b>Creando Incidencia...</b>,
                success: <b>Incidencia creada con éxito</b>,
                error: <b>Ocurrió un error.</b>,
            });

            const result = await promise;

            if (result.status === 200) {
                state.dispatch(getFulfillmentById(data.fulfillment_id));

                if (states.utils?.drawer['manual-incidence-drawer']) {
                    state.dispatch(toggleDrawer('manual-incidence-drawer'));
                }
                if (states.utils?.modal['create-incidence-modal-confirm']) {
                    state.dispatch(toggleModal('create-incidence-modal-confirm'));
                }
                history.push(`/orders/${data.order_id}`);
            }
            return state.fulfillWithValue(true);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const createLineItemsManualIncidenceSlice = createSlice({
    name: 'createLineItemsManualIncidence',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createLineItemsManualIncidence.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createLineItemsManualIncidence.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createLineItemsManualIncidence.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createLineItemsManualIncidenceSlice.reducer;
