import { notification } from 'antd';
import { useEffect, useState } from 'react';

const CheckOnLine = () => {
    const [hasOnline, setHasOnline] = useState(true);

    // Se verifica la conexion a internet y tambien la recuperacion de conexion
    useEffect(() => {
        setInterval(() => {
            if (navigator.onLine !== hasOnline) {
                setHasOnline(navigator.onLine);
            }
        }, 1000);
    }, [hasOnline]);

    // Si perdemos la conexion a internet devolvemos un mensaje
    useEffect(() => {
        if (!hasOnline) {
            notification.warning({
                message: 'Problemas con la conexion a internet!',
                description:
                    'Al parecer se a perdido la conexion, verifica tu conexion a internet',
                placement: 'topRight',
                duration: 0,
            });
        }
    }, [hasOnline]);

    return null;
};

export default CheckOnLine;
