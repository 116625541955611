import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as PrintIcon } from 'assets/Printer.svg';
import NormalButton from 'components/Buttons/NormalButton';
import { useReactToPrint } from 'react-to-print';
import ProductList from './ProductList';
import { Menu } from 'antd';
import Flex from 'components/Utils/Flex';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { fulfillmentsApi } from '../../../../redux/features/Fulfillments';
import useFulfillmentsScanMode from 'hooks/useFulfillmentsScanMode';

class ListToPrint extends React.PureComponent {
    render() {
        return (
            <Flex direction="column" rowGap="2rem" padding="1rem">
                <h2>Fecha de emision: {dayjs().format('DD/MM/YYYY HH:mm')}</h2>
                {this.props.multiple &&
                    this.props.fulfillments.map((fulfillment, index) => (
                        <ProductList
                            key={index}
                            order={fulfillment.order}
                            line_items={fulfillment.line_items}
                        />
                    ))}

                {!this.props.multiple && (
                    <ProductList order={this.props.order} line_items={this.props.line_items} />
                )}
            </Flex>
        );
    }
}

const PrintListButton = ({
    order,
    line_items = [],
    buttonProps = {},
    fulfillments = [],
    multiple = false,
    buttonType = 'button',
    changeIcon = false,
}) => {
    const componentRef = useRef();
    const { preparationsFilters, massiveFulfillmentPreparation = [] } = useSelector(
        (state) => state.utils
    );

    const [scanMode] = useFulfillmentsScanMode();

    const fulfillmentState = useSelector(
        fulfillmentsApi.endpoints.getInProgressFulfillments.select(preparationsFilters)
    );

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const inProgressFulfillments = fulfillmentState?.data?.docs ?? [];

    const [formattedFulfillments, setFormattedFulfillments] = useState([]);

    const fulfillmentsData = Array.from(
        scanMode ? massiveFulfillmentPreparation : inProgressFulfillments
    );

    useEffect(() => {
        if (fulfillments.length > 0 && fulfillmentsData?.length > 0) {
            setFormattedFulfillments(
                fulfillments.reduce((acc, ele) => {
                    const fulfillment = fulfillmentsData.find((f) => f._id === ele) || {};
                    if (fulfillment._id) {
                        acc.push({
                            ...fulfillment,
                            line_items: fulfillment.line_items.map((li) => {
                                return {
                                    name: li?.line_item?.name ?? '',
                                    sku: li?.line_item?.sku ?? '',
                                    quantity: li?.quantity ?? '',
                                    total_price: li?.line_item?.total_price ?? '',
                                    vendor: li?.line_item?.vendor ?? '',
                                };
                            }),
                        });
                    }
                    return acc;
                }, [])
            );
        }
    }, [fulfillments, fulfillmentsData]);

    const handleClick = () => {
        handlePrint();
        if (buttonProps.onClick) {
            buttonProps.onClick();
        }
    };

    const buttonText = multiple ? 'Imprimir lista de preparación' : 'Imprimir lista de preparación';

    return (
        <React.Fragment>
            <div className="hidden">
                <ListToPrint
                    ref={componentRef}
                    multiple={multiple}
                    fulfillments={formattedFulfillments}
                    order={order}
                    line_items={line_items}
                />
            </div>
            {buttonType === 'button' && (
                <>
                    {!changeIcon ? (
                        <NormalButton
                            {...buttonProps}
                            buttonText={buttonText}
                            onClick={handleClick}
                        />
                    ) : (
                        <PrintIcon
                            className="normal-button__text"
                            {...buttonProps}
                            onClick={handleClick}
                            buttonText={buttonText}
                        />
                    )}
                </>
            )}
            {buttonType === 'menuitem' && (
                <Menu.Item onClick={handleClick}>Imprimir lista de preparación</Menu.Item>
            )}
        </React.Fragment>
    );
};

export default PrintListButton;