import { createSlice } from '@reduxjs/toolkit';

export const utilsSlice = createSlice({
    name: 'utils',
    initialState: {},
    reducers: {
        setChangeStoreValues: (state, action) => {
            return {
                ...state,
                ...action.payload,
            };
        },
        setAndResetChangeStoreValues: (_, action) => {
            return {
                ...action.payload,
            };
        },
    },
});

export const { setChangeStoreValues, setAndResetChangeStoreValues } = utilsSlice.actions;

export default utilsSlice.reducer;
