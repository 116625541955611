/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessage = `
    subscription onCreateMessage($merchant_id: String, $user_id: String) {
        onCreateMessage(merchant_id: $merchant_id, user_id: $user_id) {
            action
            id
            merchant_id
            message
            user_id
        }
    }
`;
