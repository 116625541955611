import NotificationsV2 from 'components/NotificationsV2';
import Sidebar from 'components/Sidebar';
import styled from 'styled-components';

/*
    Configuración rápida para mostrar u ocultar el navbar
    show: true -> muestra el navbar
    show: false -> oculta el navbar
    hideOnPaths: ['/orders'] -> oculta el navbar en las rutas especificadas
    (si show is false entonces no se mostrará en ninguna ruta)
*/

const MainLayoutStyled = styled.main`
    overflow: hidden;
    height: 100vh;
    display: flex;
    background: white;
`;

export const Content = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

const Scroller = styled.div`
    overflow-y: scroll;
    height: 100%;
`;

const PageContentStyled = styled.div`
    padding: 1.6rem;
    width: 100%;
    max-width: 2560px;
`;

const MainLayout = ({ children }) => {
    return (
        <MainLayoutStyled>
            <Sidebar />
            <Content>
                <Scroller>
                    <PageContentStyled>{children}</PageContentStyled>
                    <NotificationsV2 />
                </Scroller>
            </Content>
        </MainLayoutStyled>
    );
};

export default MainLayout;
