import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getOrderById } from '../Orders/getOrderById';
import { toggleDrawer } from '../Utils';
import { fulfillmentsApi } from './index';

export const changeStoreFulfillment = createAsyncThunk(
    'fulfillments/changeStore',
    async (data, state) => {
        try {
            const states = state.getState();
            const { order_id, ...body } = data;

            const promise = AndesClient.post(
                `/fulfillments/orders/${order_id}/fulfillments/changeStore`,
                body
            );

            toast.promise(promise, {
                loading: <b>Cambiando de tienda...</b>,
                success: <b>Cambio de tienda exitoso</b>,
                error: <b>Ocurrió un error.</b>,
            });

            const result = await promise;

            if (result.status === 200) {
                state.dispatch(getOrderById(order_id));
                state.dispatch(
                    fulfillmentsApi.endpoints.getInProgressFulfillments.initiate(undefined, {
                        forceRefetch: true,
                    })
                );
                // Cerramos el drawer de acciones globales
                if (states.utils?.drawer['global-actions-order-drawer']) {
                    state.dispatch(toggleDrawer('global-actions-order-drawer'));
                }
            }
            return state.fulfillWithValue(true);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const changeStoreFulfillmentSlice = createSlice({
    name: 'changeStoreFulfillment',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [changeStoreFulfillment.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [changeStoreFulfillment.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [changeStoreFulfillment.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default changeStoreFulfillmentSlice.reducer;
