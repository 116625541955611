import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import styled from 'styled-components';

const StyledPhoneInput = styled(PhoneInput)`
    .PhoneInputCountrySelectArrow {
        width: 6px;
        color: #f27127;
        height: 6px;
        opacity: 1 !important;
        margin-left: 8px;
    }
    .PhoneInputInput,
    .PhoneInputCountry {
        height: 40px;
        border: 1px solid rgb(45, 61, 118);
        border-radius: 3px;
        padding: 2px 8px;
    }
`;
export default (props) => {
    return <StyledPhoneInput {...props} />;
};
