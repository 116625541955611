import styled from 'styled-components';
import { Table } from 'antd';

export const SubtableWrapper = styled.div`
    background-color: white;
    margin: -8px -8px 6px 6px;
    padding: 2rem;
    width: 100%;
    border-bottom-left-radius: 10px;
    border: 1px solid #e3e3e3;
`;

export const PackagesTable = styled(Table)`
    .ant-table {
        margin: 0px !important;
    }
    td,
    .ant-table-cell {
        background: white !important;
    }
    .ant-table-thead {
        th {
            border-bottom: 1px solid #e4e8ec;
            border-top: 0px;
        }
    }
    table {
        border-collapse: collapse;
    }
`;

export const CircleIcon = styled.span`
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const colorPackages = {
    processed: '#089460',
    selected: '#089460',
    re_processed: '#089460',
    open: '#B00303',
    pending_for_processing: '#B00303',
    received_pickup_location: '#B00303',
    pending_for_processing_in_pickup_location: '#B00303',
    nullable: '#E3CD06',
};
