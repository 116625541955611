import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Flex from 'components/Utils/Flex';
import { useHistory, useParams } from 'react-router-dom';
import useBatchFulfillments from 'hooks/useBatchFulfillments';
import AntdTable from 'components/AntdTable';
import { Fragment, useEffect, useMemo } from 'react';
import Tag from 'components/Tag';
import TCard from 'components/TCard';
import PackagesToolbar from './components/PackagesToolbar';

import PackageDetailsDrawer from './components/PackageDetailsDrawer';
import { Button, Skeleton, Typography } from 'antd';

import { useDispatch } from 'react-redux';
import { setFulfillmentForDelivery } from 'redux/features/Utils';
import { renderFulFillmentTagSLA } from 'helpers/renderTags';
import { unProccesPackage } from 'redux/features/Packages/unProcessPackage';
import { Dot } from 'components/Utils/Dot';
import renderFulfillmentLabel from 'helpers/renderFulfillmentLabels';

import { ReactComponent as CopyIcon } from 'assets/Content-copy.svg';

const renderPackageStatus = (status) => {
    if (status === 'pending_for_processing' || status === 'closed') {
        return <Tag color="warning">Pendiente</Tag>;
    }
    if (status === 'received_pickup_location' || status === 'closed') {
        return <Tag color="warning">Pendiente</Tag>;
    }
    if (status === 'processed') {
        return <Tag color="success">Escaneado</Tag>;
    }
    return <Tag color="danger">Error</Tag>;
};

const renderFulfillmentPackageStatus = (fulfillment, withoutLabel, withoutDot) => {
    if (fulfillment.status === 'success') {
        return renderFulFillmentTagSLA({
            status: 'unfulfilled',
            createdAt: fulfillment?.order?.createdAt,
            promised_date: fulfillment?.order?.promised_date,
            customLabel: withoutLabel ? '' : 'Pendiente',
            withoutDot,
        });
    }

    if (fulfillment.status === 'all_packages_processed') {
        return renderFulFillmentTagSLA({
            status: 'fulfilled',
            createdAt: fulfillment?.order?.createdAt,
            promised_date: fulfillment?.order?.promised_date,
            customLabel: withoutLabel ? '' : 'Todos los paquetes procesados',
            withoutDot,
        });
    }

    if (fulfillment.status === 'partially_processed') {
        return renderFulFillmentTagSLA({
            status: 'in_progress',
            createdAt: fulfillment?.order?.createdAt,
            promised_date: fulfillment?.order?.promised_date,
            customLabel: withoutLabel ? '' : 'Parcialmente procesado',
            withoutDot,
        });
    }

    if (fulfillment.status === 'ready_for_deliver') {
        return renderFulFillmentTagSLA({
            status: 'fulfilled',
            createdAt: fulfillment?.order?.createdAt,
            promised_date: fulfillment?.order?.promised_date,
            customLabel: withoutLabel
                ? ''
                : fulfillment.delivery_method === 'pickup'
                ? 'Listo para entrega'
                : 'Listo para enviar',
            withoutDot,
        });
    }

    if (fulfillment.status === 'delivered' || fulfillment.status === 'delivered_to_courier') {
        return renderFulFillmentTagSLA({
            status: 'fulfilled',
            createdAt: fulfillment?.order?.createdAt,
            promised_date: fulfillment?.order?.promised_date,
            customLabel: withoutLabel
                ? ''
                : fulfillment.delivery_method === 'pickup'
                ? 'Entregado'
                : 'Despachado',
            withoutDot,
        });
    }

    return <Tag color="danger">Error</Tag>;
};

const ActionsNode = ({ fulfillment }) => {
    let dot_color = '#FFC107';

    if (fulfillment?.status === 'success') {
        dot_color = '#FF0000';
    }

    if (
        [
            'ready_for_deliver',
            'all_packages_processed',
            'delivered',
            'delivered_to_courier',
        ].includes(fulfillment?.status)
    ) {
        dot_color = '#00FF00';
    }

    return (
        <Flex direction="row" justifyContent="space-between" alignItems="center">
            <Flex direction="row" columnGap="1rem" alignItems="center">
                <Dot color={dot_color} size="24px" />
                {renderFulfillmentLabel(fulfillment?.status, null, null, true)}
                <span>-</span>
                <span>
                    <b>ID Ecommerce: </b>
                    <Typography.Text
                        style={{
                            weight: 'bold',
                            color: '#1F6373',
                        }}
                    >
                        {fulfillment?.order?.remote_order_id ?? ''}
                    </Typography.Text>
                </span>
                {renderFulfillmentPackageStatus(fulfillment, true, true)}
            </Flex>

            <PackageDetailsDrawer fulfillment={fulfillment} />
        </Flex>
    );
};

const PackagesTable = ({ fulfillment }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const tableData = useMemo(
        () => [...Array.from(fulfillment.packages ?? [])],
        [fulfillment.packages]
    );

    useEffect(() => {
        if (fulfillment) {
            if (
                (fulfillment?.labels?.pdf === null || fulfillment?.labels?.pdf === '') &&
                fulfillment?.delivery_method === 'shipping'
            ) {
                history.push('/deliveries');
            }
        }
    }, [fulfillment]);

    const sumProcessedPackages = (fulfillment) => {
        return fulfillment?.packages.reduce((acc, ele) => {
            if (ele.status === 'processed') {
                acc += 1;
            }
            return acc;
        }, 0);
    };

    const unProcessPackageHandle = (code, fulfillment_id) => {
        dispatch(
            unProccesPackage({
                code,
                fulfillment_id,
            })
        );
    };

    const tableColumns = useMemo(
        () => [
            {
                title: 'Código Bulto',
                dataIndex: 'code',
                render: (value) => {
                    return (
                        <Typography.Paragraph
                            copyable={{
                                format: 'text/plain',
                                icon: <CopyIcon />,
                                text: value,
                                onCopy: () => {},
                            }}
                            style={{
                                marginBottom: '0px',
                                color: '#037FB9',
                                textDecoration: 'underline',
                            }}
                        >
                            {value}
                        </Typography.Paragraph>
                    );
                },
            },
            {
                title: 'Descripción',
                dataIndex: ['type', 'name'],
                key: ['type', 'name'],
            },
            {
                title: 'Productos',
                dataIndex: 'line_items',
                render: (value) => {
                    const line_items_count = value.reduce((acc, ele) => {
                        acc += ele.quantity;
                        return acc;
                    }, 0);
                    return line_items_count;
                },
            },
            {
                title: 'Tipo Entrega',
                render: () => fulfillment.order?.fulfillment_method ?? <Tag>Sin Datos</Tag>,
            },
            {
                title: 'Estado',
                dataIndex: 'status',
                render: renderPackageStatus,
            },
            {
                title: 'Tienda',
                render: () => fulfillment.origin?.name ?? <Tag>Sin Datos</Tag>,
            },
            {
                title: 'Op. Logístico',
                render: () =>
                    fulfillment.courier?.public_name ??
                    fulfillment.courier?.courier_name ?? <Tag>Sin Datos</Tag>,
            },
            // {
            //     title: 'Estado Courier',
            //     render: () =>
            //         fulfillment?.order?.delivery_method === 'pickup' ? (
            //             <span>No aplica</span>
            //         ) : (
            //             <span>Programado</span>
            //         ),
            // },
            {
                title: 'Opciones',
                render: ({ code, status }) => {
                    return (
                        <Button
                            disabled={
                                status !== 'processed' ||
                                fulfillment.status === 'delivered_to_courier'
                            }
                            onClick={() => unProcessPackageHandle(code, fulfillment._id)}
                            style={{
                                border: '2px solid #1F6373',
                            }}
                        >
                            Eliminar
                        </Button>
                    );
                },
            },
        ],
        [fulfillment, fulfillment.packages]
    );
    const parsed_fulfillment_name = String(fulfillment?.name ?? '').replace(/#/g, '');
    return (
        <section
            className="alerts-border-placeholder"
            id={`packages-table-${parsed_fulfillment_name}`}
        >
            <b
                style={{
                    fontSize: '1.5rem',
                    color: '#1F6373',
                    display: 'block',
                    width: '100%',
                    borderBottom: '2px solid #1F6373',
                    marginBottom: '1rem',
                    fontWeight: '500',
                    letterSpacing: '0.5px',
                }}
            >
                Bulto pedido: {fulfillment?.name ?? ''} (escaneados:{' '}
                {sumProcessedPackages(fulfillment)}/{fulfillment?.packages?.length ?? 0})
            </b>

            <TCard paddingless>
                <AntdTable
                    actionsNode={<ActionsNode fulfillment={fulfillment} />}
                    rowKey="_id"
                    loading={false}
                    dataSource={tableData}
                    columns={tableColumns}
                />
            </TCard>
        </section>
    );
};

const MultipleDelivery = ({ loading, fulfillments }) => {
    return (
        <Fragment>
            <PackagesToolbar fulfillments={fulfillments} loading={loading} />
            {fulfillments.map((fulfillment, index) => (
                <PackagesTable loading={loading} fulfillment={fulfillment} key={index} />
            ))}
        </Fragment>
    );
};

const Delivery = () => {
    const dispatch = useDispatch();
    const { fulfillment_id } = useParams();

    const setSingleBatchFulfillment = () => {
        if (fulfillment_id !== 'multiple') {
            dispatch(setFulfillmentForDelivery([fulfillment_id]));
        }
    };

    useEffect(() => {
        setSingleBatchFulfillment();
    }, [fulfillment_id]);

    const [loading, data] = useBatchFulfillments();

    if (loading && !data[0]) {
        return (
            <Container>
                <Skeleton />
            </Container>
        );
    }

    // este useEffect nos ayuda a sacar el listado de bultos que se van a entregar y así poder procesarlos mediante algún script. Lo dejamos comentado para futuras oportunidades
    // useEffect(() => {
    //     if (!loading && data[0]) {
    //         const arr = [];

    //         data.forEach((el) => {
    //             el.packages.forEach((ele) => {
    //                 arr.push({
    //                     package_code: ele.code,
    //                     fulfillment_id: ele.fulfillment_id,
    //                 });
    //             });
    //         });

    //         console.log(JSON.stringify(arr));
    //     }
    // }, [data]);

    return (
        <>
            <Container extraTitle={!loading ? `Entregas (${data?.length ?? 0})` : ''}>
                <PageHeader
                    showBack
                    title={`Pedidos seleccionados (${data?.length ?? 0})`}
                    showResponsables={true}
                    responsables={
                        data &&
                        data[0] &&
                        data.reduce((acc, ele) => {
                            if (ele?.assigned_operators && ele?.assigned_operators[0]) {
                                return [...acc, ...ele.assigned_operators];
                            }
                            return acc;
                        }, [])
                    }
                    titleExtra={
                        <Fragment>
                            {data.some((f) => f.delivery_method === 'shipping') && (
                                <Tag color="warning" key="dom">
                                    Domicilio
                                </Tag>
                            )}
                            {data.some((f) => f.delivery_method === 'pickup') && (
                                <Tag color="warning" key="dom">
                                    Retiro en tienda
                                </Tag>
                            )}
                        </Fragment>
                    }
                    breadcrumb={[
                        { name: 'Entregas', path: '/deliveries' },
                        { name: `Entrega Pedidos` },
                    ]}
                />
                <Flex direction="column" rowGap="2rem">
                    <MultipleDelivery loading={loading} fulfillments={data} />
                </Flex>
            </Container>
        </>
    );
};

export default Delivery;
