import { Form, Modal } from 'antd';
import { Select, Input } from 'components/Form';
import FormStyled from 'components/Utils/Form';
import { bankDataChile } from 'data/bankDataChile';
import { useEffect, useState } from 'react';
import { useUpdateOrderCustomerMutation } from 'redux/features/Orders/updateOrderCustomer';

export const ModalOrderDataBank = ({ open, onClose, onSuccess, initialValues, refetch }) => {
    const [dataOrderBank, setDataOrderBank] = useState();

    const [updateOrderCustomerBank, updateOrderCustomerBankState] =
        useUpdateOrderCustomerMutation();

    const [form] = Form.useForm();

    const handleSubmit = () => {
        if (updateOrderCustomerBankState.isLoading) return;
        const customerUpdateData = form.getFieldsValue();
        updateOrderCustomerBank({
            _id: initialValues._id,
            customer_bank: {
                ...initialValues?.customer_bank,
                ...customerUpdateData,
            },
        }).then(() => {
            refetch();
        });
        onSuccess(customerUpdateData);
    };

    useEffect(() => {
        if (initialValues?.customer_bank) {
            setDataOrderBank(initialValues?.customer_bank);
        }
    }, [initialValues]);

    return (
        <Modal
            title={
                initialValues?.customer_bank ? 'Editar datos bancarios' : 'Agregar datos bancarios'
            }
            okText="Guardar cambios"
            open={open}
            onOk={() => form.submit()}
            onCancel={onClose}
            okButtonProps={{
                style: {
                    backgroundColor: '#F27127',
                    color: 'white',
                    border: '1px solid #E79B09 ',
                },
            }}
            cancelButtonProps={{
                style: {
                    backgroundColor: 'none',
                    color: '#2D3D76',
                    border: '1px solid #2D3D76 ',
                },
            }}
        >
            <FormStyled
                name="customerContactForm"
                layout="vertical"
                initialValues={dataOrderBank}
                form={form}
                onFinish={handleSubmit}
            >
                <Form.Item
                    name="first_name"
                    label="Nombre"
                    rules={[{ required: true, message: 'Por favor ingrese su nombre' }]}
                >
                    <Input placeholder="Ingresar nombre" />
                </Form.Item>

                <Form.Item
                    name="last_name"
                    label="Apellido"
                    rules={[{ required: true, message: 'Por favor ingrese su apellido' }]}
                >
                    <Input placeholder="Ingresar apellido" />
                </Form.Item>

                <Form.Item
                    name="bank_account_rut"
                    label="Documento (RUT)"
                    rules={[{ required: true, message: 'Por favor ingrese su documento' }]}
                >
                    <Input placeholder="Ingresar documento" />
                </Form.Item>

                <Form.Item
                    name="bank_name"
                    label="Banco"
                    rules={[{ required: true, message: 'Por favor ingrese nombre del banco' }]}
                >
                    <Select placeholder="Seleccionar banco">
                        {bankDataChile?.banks?.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="bank_account_type"
                    label="Tipo de cuenta"
                    rules={[{ required: true, message: 'Por favor ingrese tipo de cuenta' }]}
                >
                    <Select placeholder="Seleccionar tipo de cuenta">
                        {bankDataChile?.accountTypes?.map((item) => (
                            <Select.Option key={item.value} value={item.value}>
                                {item.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="bank_account_number"
                    label="Numero de cuenta"
                    rules={[{ required: true, message: 'Por favor ingrese el numero de cuenta' }]}
                >
                    <Input placeholder="Ingresar numero de cuenta" />
                </Form.Item>

                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: 'Por favor ingrese su email' },
                        { type: 'email', message: 'Por favor ingrese un email valido' },
                    ]}
                >
                    <Input placeholder="Ingresar email" />
                </Form.Item>
            </FormStyled>
        </Modal>
    );
};
