import Drawer from 'components/Drawer/index';
import { useState } from 'react';
import AlertShareWarehouse from './AlertShareWarehouse';
import CurrentIncidence from './CurrentIncidence';
import HeaderManualIncidenceDrawer from './HeaderManualIncidenceDrawer';
import IncidenceForm from './IncidenceForm';
import ProblemProducts from './ProblemProducts/index';

const CreateIncidenceDrawer = ({ fulfillment }) => {
    // Data de las incidencias actuales del fulfillment
    const { line_items_manual_incidences } = fulfillment;

    // Data de los line_items que estan seleccionados en el drawer
    const [lineItemsSelected, setLineItemsSelected] = useState([]);

    // Se selecciono SI/NO en alerta de compartir bodega
    const [isShareWarehouse, setIsShareWarehouse] = useState('none');

    // Se cerro/borro el formulario de incidencia
    const [isCloseIncidenceForm, setIsCloseIncidenceForm] = useState(false);

    // Funciones para determinar cuando se muestra cada componente
    const isAlertShareWarehouseShowing = () =>
        lineItemsSelected.length > 1 && isShareWarehouse === 'none';
    const isIncidenceFormShowing = () => lineItemsSelected.length >= 1;
    const isNothingSelectedMessageShowing = () => lineItemsSelected.length === 0;

    return (
        <Drawer
            forceRender={true}
            buttonProps={{
                backgroundColor: '#ffffff',
                textColor: '#043540',
                buttonText: 'Agregar incidencia',
            }}
            drawerProps={{
                name: 'manual-incidence-drawer',
                title: <HeaderManualIncidenceDrawer fulfillmentName={fulfillment.name} />,
                width: '500px',
                dontShowFooter: true,
            }}
        >
            <div className="manual-incidence-drawer">
                <div className="mb-5">
                    <ProblemProducts
                        setLineItemsSelected={setLineItemsSelected}
                        isShareWarehouse={isShareWarehouse}
                        setIsShareWarehouse={setIsShareWarehouse}
                        isCloseIncidenceForm={isCloseIncidenceForm}
                        setIsCloseIncidenceForm={setIsCloseIncidenceForm}
                        fulfillment={fulfillment}
                    />
                </div>
                {isNothingSelectedMessageShowing() && (
                    <p className="text-center w-full font-bold" style={{ color: '#1F6373' }}>
                        Debe seleccionar al menos un producto para agregar una incidencia. En caso
                        de seleccionar más de uno deben compartir la incidencia.
                    </p>
                )}
                {isAlertShareWarehouseShowing() && (
                    <div>
                        <AlertShareWarehouse setIsShareWarehouse={setIsShareWarehouse} />
                    </div>
                )}
                {isIncidenceFormShowing() && !isAlertShareWarehouseShowing() && (
                    <div>
                        <IncidenceForm
                            lineItemsSelected={lineItemsSelected}
                            setIsCloseIncidenceForm={setIsCloseIncidenceForm}
                            fulfillment={fulfillment}
                        />
                    </div>
                )}
                {line_items_manual_incidences && line_items_manual_incidences?.[0] && (
                    <div>
                        <p className="mt-8 underline font-bold uppercase">INCIDENCIAS ACTUALES</p>
                        <div className="h-96 overflow-auto">
                            {line_items_manual_incidences.map((incidence, index) => (
                                <CurrentIncidence key={index} incidence={incidence} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </Drawer>
    );
};

export default CreateIncidenceDrawer;
