import React, { useState, useEffect, useMemo, Suspense } from 'react';
import { Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useGetUserPreferencesQuery } from 'redux/features/Users';
import {
    useGetFulfillmentScannedStatusMutation,
    useLazyGetInProgressFulfillmentsAllIdsQuery,
    useLazyGetInProgressFulfillmentsQuery,
} from 'redux/features/Fulfillments';
import {
    removeFilter,
    overrideFilters,
    setValueOptionByField,
} from 'redux/features/Preparations/preparationsFilters';
import { changeState, setStateFilters } from 'redux/features/Preparations/preparationState';
import {
    clearFullfilmentMassivePreparation,
    replaceFulfillmentToMassivePreparation,
    setPreparationFilters,
} from 'redux/features/Utils';
import useCurrentUser from 'hooks/useCurrentUser';
import useAllSalesChannel from 'hooks/useAllSalesChannel';
import useAllShops from 'hooks/useAllShops';
import useAllCouriers from 'hooks/useAllCouriers';
import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Flex from 'components/Utils/Flex';
import CollapsibleContainer from 'components/V2/CollapsibleContainer';
import LocationPicker from 'components/V2/LocationPicker';
import { getDateFilterToSend } from 'components/V2/DatePicker/utils';
import OrderPreparationMetrics from 'components/V2/Metrics/OrderPreparationMetrics';
import ViewTabs from 'components/V2/ViewTabs';
import SearchInput from 'components/V2/SearchInput';
import FilterButton from 'components/V2/Buttons/FilterButton';
import ColumnsButton from 'components/V2/Buttons/ColumnsButton';
import ShowQuantityItems from 'components/V2/ShowQuantityItems';
import InfoFilter from 'components/V2/Filters/InfoFilter';
import Switch from 'components/V2/Switch';
import PreparationActions from './PreparationActions';
import { removeAccents } from 'helpers/removeAccents';
import { defaultColumnsView, INITIAL_PAGE_SIZE } from './config';
import store from '../../redux';
import { promisedDateRanges } from 'data/promised_date_ranges';
import { useLocation } from 'react-router-dom';
import printJS from 'print-js';
import toast from 'react-hot-toast';
import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import { RangePicker } from 'components/RangePicker';

const DraggableTable = React.lazy(() => import('pages/PreparationsV2/Table/DraggableTable'));

const Preparations = () => {
    const PREPARATIONS = 'preparations';
    // Redux queries
    const {
        data: userPreferences,
        isLoading: userLoading,
        isFetching: userFetching,
    } = useGetUserPreferencesQuery();

    const params = useLocation();

    const [getInProgressFulfillments, { data, isFetching, isLoading, originalArgs }] =
        useLazyGetInProgressFulfillmentsQuery({
            refetchOnMountOrArgChange: true,
        });

    const [getInProgressFulfillmentsAllIds, { data: allIds }] =
        useLazyGetInProgressFulfillmentsAllIdsQuery({
            refetchOnMountOrArgChange: true,
        });

    const [getFulfillmentStatus, { isLoading: isLoadingFulfillmentScan }] =
        useGetFulfillmentScannedStatusMutation();
    // Redux state and get data from local storage
    const { filters, valueOptionByField } = useSelector((state) => state.preparationsFilters);
    const preparationState = useSelector((state) => state.preparationState);
    const { massiveFulfillmentPreparation } = useSelector((state) => state.utils);
    const { userData } = useCurrentUser();
    const [salesChannelsLoading, salesChannels] = useAllSalesChannel();
    const [locationsLoading, locations] = useAllShops();
    const [couriersLoading, couriers] = useAllCouriers();
    const [fulfillmentUrlParams, setFulfillmentUrlParams] = useState({});
    const [isFilterLoading, setIsFilterLoading] = useState(false);

    const [scanMode, setScanMode] = useState(false);
    const [isLoadingPreparationPreferences, , preparationPreferences] =
        useGetPreparationPreference();
    const userDataLocalStorage = JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;
    const objectDateToSend = getDateFilterToSend(
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter,
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter_custom
    );

    const dispatch = useDispatch();

    const [selectedTab, setSelectedTab] = useState('all');
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedFulfillments, setSelectedFulfillments] = useState([]);
    const [tabColumns, setTabColumns] = useState(defaultColumnsView);
    const [tablePageSize, setTablePageSize] = useState(INITIAL_PAGE_SIZE);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedDateRange, setSelectedDateRange] = useState({
        fromDate: objectDateToSend?.start ?? dayjs().format('YYYY-MM-DD'),
        toDate: objectDateToSend?.end ?? dayjs().format('YYYY-MM-DD'),
    });
    const [selectedLocation, setSelectedLocation] = useState([
        userData?.store_warehouse_id || 'all',
    ]);

    const loading =
        isLoading ||
        isFilterLoading ||
        userLoading ||
        userFetching ||
        salesChannelsLoading ||
        locationsLoading ||
        isLoadingFulfillmentScan ||
        isLoadingPreparationPreferences ||
        couriersLoading;

    console.log({ isLoading, isFetching });

    const handlePrintDocument = (orderDocument) => {
        const toastId = toast.loading(<b>Obteniendo documentos</b>);
        printJS(orderDocument.url);
        toast.success(<b>Documentos obtenidos con éxito</b>, {
            duration: 2000,
            id: toastId,
        });
    };

    const handleReceiptPrintOnScan = (firstFulfillment, searchTerm) => {
        const hasPrevSearchValue = preparationState?.filters?.searchTerm;

        const allowedStatus = ['in_progress', 'partially_processed'];
        if (
            preparationPreferences?.preparations_preferences?.enable_receipt_printing_on_scan &&
            firstFulfillment &&
            searchTerm &&
            !hasPrevSearchValue
        ) {
            if (
                (allowedStatus.includes(firstFulfillment?.status) &&
                    (firstFulfillment?.name ?? '')?.toUpperCase() === searchTerm) ||
                (firstFulfillment?.remote_order_id ?? '')?.toUpperCase() === searchTerm
            ) {
                const orderDocument =
                    Array.from(firstFulfillment?.order?.documents ?? []).find((doc) =>
                        ['DTE_TICKET', 'DTE_INVOICE'].includes(doc?.type)
                    ) ?? {};

                if (orderDocument?.url) {
                    handlePrintDocument(orderDocument);
                }
            }
        }
    };

    const getActiveTabByUrlParams = (hasPrevFilter) => {
        const searchParams = new URLSearchParams(params.search);
        if (searchParams.get('activeTab')) {
            handleChangeTab(searchParams.get('activeTab'), hasPrevFilter);
            window.history.replaceState(null, null, window.location.pathname);
        }
    };

    const scanModeDefault = useMemo(() => {
        return preparationPreferences?.preparations_preferences?.auto_scan_mode ?? false;
    }, [preparationPreferences]);

    const searchFulfillmentByUrlParams = (fulfillmentId, remoteOrderId) => {
        const state = store.getState();
        const utils = state.utils;
        const filters = utils.preparationsFilters;

        setFulfillmentUrlParams({
            fulfillment_id: fulfillmentId,
            remote_order_id: remoteOrderId,
        });

        getInProgressFulfillmentsAllIds(
            {
                ...filters,
                searchTerm: remoteOrderId,
                page: 1,
                date_range: [],
            },
            false
        );
        getInProgressFulfillments(
            {
                ...filters,
                searchTerm: remoteOrderId,
                page: 1,
                date_range: [],
            },
            false
        ).then(async () => {
            const filter = {
                andOr: '',
                condition: 'is',
                field: 'remote_order_id',
                value: remoteOrderId,
                consolidation: false,
                isRemovable: true,
                id: 'fulfillment_url_params',
                compromiseValues: [],
            };
            dispatch(overrideFilters([filter]));
        });
        // window.history.replaceState(null, null, window.location.pathname);
    };

    const handleOnInit = () => {
        const scanModePreparationPreferences =
            preparationPreferences?.preparations_preferences?.auto_scan_mode ?? false;
        setScanMode(scanModePreparationPreferences);
        const searchParams = new URLSearchParams(params.search);
        const fulfillmentId = searchParams.get('fulfillment_id');
        const remoteOrderId = searchParams.get('remote_order_id');
        if (fulfillmentId && remoteOrderId) {
            searchFulfillmentByUrlParams(fulfillmentId, remoteOrderId);
            return;
        }
        if (params.search && userPreferences) {
            getActiveTabByUrlParams();
        } else {
            handlePreparationsRefetch(true, true);
        }
    };

    const handlePreparationsRefetch = async (forceRefetch, disabledReceiptPrint) => {
        const state = store.getState();
        const utils = state.utils;
        const filters = utils.preparationsFilters;
        if (scanMode) return;
        setIsFilterLoading(true);
        await getInProgressFulfillmentsAllIds(filters, !forceRefetch);
        await getInProgressFulfillments(filters, !forceRefetch).then((res) => {
            dispatch(
                changeState({
                    initialSearchTerm: '',
                    massiveFulfillmentPreparation: null,
                })
            );
            dispatch(setStateFilters(null));

            if (disabledReceiptPrint) return;

            const prevSearchTerm = originalArgs?.searchTerm?.toUpperCase().trim();
            const searchTerm = (filters?.searchTerm ?? '').toUpperCase().trim();
            const firstFulfillment = res?.data?.docs?.[0];
            if (prevSearchTerm !== searchTerm) {
                handleReceiptPrintOnScan(firstFulfillment, searchTerm);
            }
        });
        setIsFilterLoading(false);
    };
    // Funcion para actualizar el estado de la tab seleccionada a traves de los hijos
    const handleChangeTab = (tabValue, hasPrevFilter) => {
        const selectedTabIndex = userPreferences?.preferences[PREPARATIONS]?.tabs?.findIndex(
            (tab) => tab.value === tabValue
        );
        setScanMode(hasPrevFilter ? false : scanModeDefault);
        console.log({ scanModeDefault, hasPrevFilter });
        if (scanModeDefault && !hasPrevFilter) {
            dispatch(clearFullfilmentMassivePreparation());
        }
        setSelectedTab(tabValue);
        setSelectedTabIndex(
            selectedTabIndex === -1
                ? userPreferences?.preferences[PREPARATIONS]?.tabs?.length
                : selectedTabIndex
        );
        const filtersToOverride = structuredClone(
            userPreferences?.preferences[PREPARATIONS]?.tabs[selectedTabIndex]?.filters ?? []
        );
        filtersToOverride.forEach((filter) => {
            filter.compromiseValues = valueOptionByField[filter.field];
        });
        dispatch(overrideFilters(filtersToOverride));
        const state = store.getState();
        const filters = state?.preparationState?.filters;
        console.log({ filters });
        // Despachamos los filtros para que se apliquen
        dispatch(
            setPreparationFilters({
                ...filters,
                filters: [
                    ...(filtersToOverride?.map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })) ?? []),
                ],
                page: 1,
            })
        );
        handlePreparationsRefetch(true);
    };

    const handleChangeDateRange = (updatedDateRange) => {
        setSelectedDateRange(updatedDateRange);
        dispatch(changeState({ dateSelected: updatedDateRange.type }));

        dispatch(
            setPreparationFilters({
                date_range: [updatedDateRange?.fromDate, updatedDateRange?.toDate],
                page: 1,
            })
        );
        handlePreparationsRefetch();
    };

    const handleChangeLocations = (updatedLocation) => {
        setSelectedLocation(updatedLocation);

        dispatch(
            setPreparationFilters({
                origin: updatedLocation,
                page: 1,
            })
        );
        handlePreparationsRefetch();
    };

    const handleChangeTabColumns = (updatedColumns) => {
        setTabColumns(updatedColumns);
    };

    const handleChangePageSize = (sizePage) => {
        setTablePageSize(sizePage);
    };

    const handleSelectRows = (selectedRowKeys, selectedRecords) => {
        setSelectedRows(selectedRowKeys);
        setSelectedFulfillments(selectedRecords);
    };

    const handleChangeColumns = (columns) => {
        setTabColumns(columns);
    };

    const handleDeleteFilter = (filterId) => {
        if (fulfillmentUrlParams?.fulfillment_id) {
            setFulfillmentUrlParams({});
        }
        dispatch(removeFilter(filterId));
        dispatch(
            setPreparationFilters({
                filters: filters
                    ?.filter((filter) => filter.id !== filterId)
                    .map((filter) => ({
                        andOr: filter.andOr,
                        field: filter.field,
                        condition: filter.condition,
                        value: filter.value,
                    })),
                page: 1,
            })
        );
        handlePreparationsRefetch(true);
    };

    // const handleSelectAllRows = () => {
    //     if (scanMode) {
    //         setSelectedRows(massiveFulfillmentPreparation.map((item) => item._id));
    //         return;
    //     }
    //     const allRows = data?.ids ?? [];
    //     setSelectedRows(allRows);
    // };

    useEffect(() => {
        const tab = userPreferences?.preferences[PREPARATIONS]?.tabs?.find(
            (tab) => tab.value === selectedTab
        );

        if (!tab || tab?.typeOfTab === 'basic') {
            setTabColumns(defaultColumnsView);
            return;
        }

        setTabColumns(tab.columns);
    }, [selectedTab, selectedTabIndex]);

    // Para actualizar con el punto
    const forceRefetchWithButton = (event) => {
        const key = event?.key;
        const state = store.getState();
        const utils = state.utils;
        const filters = utils.preparationsFilters;

        if (key === '.') {
            if (scanMode) {
                getFulfillmentStatus({
                    withoutToast: true,
                    searchTerms: filters?.searchTerm ?? '',
                    view: 'fulfillments',
                    filters: filters,
                }).then((response) => {
                    dispatch(replaceFulfillmentToMassivePreparation(response?.data ?? []));
                });
            } else {
                handlePreparationsRefetch(true, true);
            }
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', forceRefetchWithButton, true);
        return () => {
            document.removeEventListener('keydown', forceRefetchWithButton, true);
        };
    }, [scanMode]);

    // Efecto para obtener las opciones de los filtros segun el campo
    useEffect(() => {
        if (loading) return;

        const destination_stores = Array.from(locations ?? [])
            .filter((location) => location?.is_enabled && location?.location_type !== 'virtual')
            .map((location) => ({
                label: location?.name,
                value: location?._id,
            }))
            .sort((a, b) => String(a.label).localeCompare(String(b.label)));

        console.log({ destination_stores });

        const fieldValuesObj = {
            sales_channel: salesChannels.map((sc) => ({
                label: sc?.alias ?? sc?.integration?.api_name,
                value: sc?._id,
            })),
            origin: [{ label: 'Todas', value: 'all' }].concat(
                Array.from(locations ?? [])
                    .filter((l) => l.is_enabled)
                    ?.sort((a, b) =>
                        removeAccents(a.name.toUpperCase()).localeCompare(
                            removeAccents(b.name.toUpperCase())
                        )
                    )
                    .map((location) => ({
                        label: location?.name || '',
                        value: location._id,
                    }))
            ),
            courier: couriers.map((courier) => ({
                label: courier?.public_name ?? courier?.courier_name ?? '',
                value: courier?._id,
            })),
            promised_date_range: promisedDateRanges,
            ['locations.destination']: destination_stores,
        };
        dispatch(setValueOptionByField(fieldValuesObj));
    }, [loading]);

    useEffect(() => {
        if (Array.from(massiveFulfillmentPreparation ?? []).length > 0) {
            const fulfillmentRowId = massiveFulfillmentPreparation.map((item) => item._id);
            setSelectedRows(fulfillmentRowId);
            setSelectedFulfillments(massiveFulfillmentPreparation);
        }
    }, [massiveFulfillmentPreparation]);

    useEffect(() => {
        if (!scanMode) {
            setSelectedRows([]);
        }
    }, [scanMode]);

    useEffect(() => {
        setSelectedRows([]);
    }, [selectedTab, selectedTabIndex, filters, selectedDateRange, selectedLocation, scanMode]);

    useEffect(() => {
        if (Object.keys(preparationPreferences)?.length && !isLoadingPreparationPreferences) {
            handleOnInit();
        }
    }, [params.search && userPreferences, preparationPreferences, isLoadingPreparationPreferences]);

    const filtersToShow = useMemo(() => {
        return filters.filter((filter) => filter.field);
    }, [filters]);

    console.log({ selectedFulfillments });

    return (
        <Container extraTitle="Preparación">
            <PageHeader title="Pedidos a preparar" withMarginBottom={false} />
            <p style={{ margin: '0.5rem 0 0.2rem' }}>Filtrar métricas y tabla por:</p>
            <Flex direction="row" columnGap="1rem" paddingBottom="2rem">
                <RangePicker loading={loading} setDateCalendar={handleChangeDateRange} />
                <LocationPicker handleChangeLocations={handleChangeLocations} loading={loading} />
            </Flex>
            {/* Container con fechas, ubicaciones y Metricas */}
            <Flex direction="column">
                <CollapsibleContainer>
                    <OrderPreparationMetrics
                        selectedDateRange={selectedDateRange}
                        selectedLocation={selectedLocation}
                        isSAC={false}
                        view={PREPARATIONS}
                    />
                </CollapsibleContainer>
            </Flex>

            {/* Vistas personalizadas */}
            <ViewTabs
                userPreferences={userPreferences}
                selectedTab={selectedTab}
                handleChangeTab={handleChangeTab}
                preferences={PREPARATIONS}
            />

            {/* Searchbar y botones de acciones */}
            <Flex
                width="100%"
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                marginBottom="1.5rem"
                padding="1rem 0px 2rem"
            >
                <Flex className="mt-4" direction="column" rowGap="0.5rem">
                    <Flex direction="row" gap="1rem">
                        <Switch
                            checked={scanMode}
                            onChange={(checked) => {
                                setScanMode(checked);
                                setSelectedFulfillments([]);
                                setSelectedRows([]);
                            }}
                        />
                        <span style={{ fontSize: '14px' }}>
                            Modo scan {scanMode ? 'habilitado' : 'deshabilitado'}
                        </span>
                    </Flex>
                    <SearchInput
                        onChange={(searchTerm) => setSearchTerm(searchTerm)}
                        value={searchTerm ?? ''}
                        refetch={handlePreparationsRefetch}
                        handleReceiptPrintOnScan={handleReceiptPrintOnScan}
                        dispatchersType={PREPARATIONS}
                        scanMode={scanMode}
                        disabled={loading}
                    />
                </Flex>
                <Flex gap="1rem">
                    <FilterButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        refetch={handlePreparationsRefetch}
                        data={data}
                        modalName="preparation-filter-modal"
                        formName="preparation-filter-form"
                        filters={filters}
                        valueOptionByField={valueOptionByField}
                        dispatchersType={PREPARATIONS}
                    />
                    <ColumnsButton
                        userPreferences={userPreferences}
                        selectedTab={selectedTab}
                        selectedTabIndex={selectedTabIndex}
                        loading={loading}
                        userFetching={userFetching}
                        tabColumns={tabColumns}
                        handleChangeTabColumns={handleChangeTabColumns}
                        formName="preparation-column-form"
                        modalName="preparation-column-modal"
                        dispatchersType={PREPARATIONS}
                    />
                    <ShowQuantityItems
                        tablePageSize={tablePageSize}
                        handleChangePageSize={handleChangePageSize}
                        disabled={loading}
                        dispatchersType={PREPARATIONS}
                        refetch={handlePreparationsRefetch}
                    />
                    <PreparationActions
                        preparationPreferences={preparationPreferences?.preparations_preferences}
                        disabled={loading || selectedRows.length < 1}
                        fulfillments={selectedFulfillments}
                        selected={selectedRows}
                        scanMode={scanMode}
                        handlePreparationsRefetch={handlePreparationsRefetch}
                    />
                </Flex>
            </Flex>
            {/* Filtros seleccionados */}
            <Flex gap="0.75rem" marginBottom="1rem">
                <span>Filtros: </span>
                {filtersToShow.length === 0 ? (
                    <InfoFilter loading={loading} />
                ) : (
                    filtersToShow?.map((filter) => (
                        <InfoFilter
                            key={filter.id}
                            filter={filter}
                            onDelete={handleDeleteFilter}
                            loading={loading}
                            showDeleteIcon
                            preferences={PREPARATIONS}
                            valueOptionByField={valueOptionByField}
                        />
                    ))
                )}
            </Flex>

            <Flex direction="row" alignItems="center">
                {selectedRows.length === 0 ? (
                    <b style={{ display: 'block' }} className="text-sm">
                        No tienes pedidos seleccionados
                    </b>
                ) : (
                    <b style={{ display: 'block' }} className="text-sm">
                        En esta vista tienes seleccionado{' '}
                        <strong>
                            {selectedRows.length} pedido(s) de{' '}
                            {scanMode ? massiveFulfillmentPreparation?.length : data?.totalDocs}
                        </strong>{' '}
                        {filtersToShow.length > 0 && '(Aplican filtros)'}
                    </b>
                )}
                <Divider type="vertical" style={{ marginBottom: '25px' }} />
                {data?.totalDocs > 0 && data?.totalPages > 1 && !scanMode && (
                    <b
                        className="text-sm"
                        role="button"
                        onClick={() => {
                            if (selectedRows.length === data?.totalDocs) {
                                setSelectedRows([]);
                            } else {
                                setSelectedRows(allIds?.ids);
                            }
                        }}
                        style={{ display: 'block' }}
                    >
                        <u>
                            {(scanMode
                                ? massiveFulfillmentPreparation?.length
                                : data?.totalDocs) === selectedRows.length
                                ? 'Deseleccionar todos'
                                : 'Seleccionar todos'}{' '}
                            ({scanMode ? massiveFulfillmentPreparation?.length : data?.totalDocs})
                        </u>
                    </b>
                )}
            </Flex>

            {/* Table */}
            <Suspense fallback={<></>}>
                <DraggableTable
                    fulfillmentSelected={fulfillmentUrlParams.fulfillment_id}
                    data={data}
                    loading={loading}
                    userPreferences={userPreferences}
                    selectedTab={selectedTab}
                    refetch={handlePreparationsRefetch}
                    selectedRows={selectedRows}
                    handleSelectRows={handleSelectRows}
                    tabColumns={tabColumns}
                    handleChangeColumns={handleChangeColumns}
                    preferences={PREPARATIONS}
                    scanMode={scanMode}
                    massiveFulfillmentPreparation={massiveFulfillmentPreparation}
                    handlePrintDocument={handlePrintDocument}
                />
            </Suspense>
        </Container>
    );
};

export default Preparations;
