import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getOrderById } from '../Orders/getOrderById';
import { toggleDrawer } from '../Utils';
import { fulfillmentsApi } from './index';

export const updateFulfillment = createAsyncThunk('fulfillments/create', async (data, state) => {
    try {
        const { order_id, fulfillment_id, line_items } = data;
        const states = state.getState();
        const promise = AndesClient.put(
            `/fulfillments/orders/${order_id}/fulfillments/${fulfillment_id}`,
            line_items
        );

        toast.promise(promise, {
            loading: <b>Agregando producto/s a Fulfillment existente...</b>,
            success: <b>Productos agregados con éxito</b>,
            error: <b>Ocurrió un al agregar los productos al fulfillment.</b>,
        });

        const result = await promise;

        if (result.status === 200) {
            state.dispatch(getOrderById(order_id));
            state.dispatch(
                fulfillmentsApi.endpoints.getInProgressFulfillments.initiate(undefined, {
                    forceRefetch: true,
                })
            );
            if (
                states.utils?.drawer['incidence-order-drawer'] === true &&
                !states.solveOrderData?.is_in_warehouse_validation_step
            ) {
                state.dispatch(toggleDrawer('incidence-order-drawer'));
            }
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const updateFulfillmentSlice = createSlice({
    name: 'updateFulfillment',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [updateFulfillment.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [updateFulfillment.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [updateFulfillment.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default updateFulfillmentSlice.reducer;
