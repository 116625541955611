import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getStripePlans = createAsyncThunk('stripe/plans', async (_, state) => {
    try {
        const res = await AndesClient.get(`/stripe/plans`);
        return res.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getStripePlansSlice = createSlice({
    name: 'getStripePlans',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [getStripePlans.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getStripePlans.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [getStripePlans.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});

export default getStripePlansSlice.reducer;
