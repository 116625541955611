/* import ActionsButton from 'components/Buttons/ActionsButton'; */
import Flex from 'components/Utils/Flex';
import { useMemo } from 'react';
import { ScannedCodeRow, ScannedRowItem, ScannedRowTitle, TypoText } from './styles';
import AntdTable from 'components/AntdTable';
import { TableCard } from 'components/AntdTable/styles';
import PrintListButton from './PrintListButton';
import PickingStatus from './PickingStatus';
import Actions from './Actions/index';
import { ReactComponent as IncidenceIcon } from 'assets/alert-rhombus.svg';
import { ReactComponent as BarCodeScan } from 'assets/qr-code.svg';
import { MdContentCopy as CopyIcon } from 'react-icons/md';
import IconContainer from 'components/Utils/IconContainer';
import { useEffect } from 'react';

const ScannedCodes = ({
    fulfillment = {},
    code_for_scanning = 'sku',
    setIsTotallyPicking,
    blockPreparation = false,
}) => {
    const tableData = useMemo(() => {
        const line_items_formatted =
            Array.from(fulfillment.line_items).map((lineItem) => {
                const { quantity } = lineItem;
                return {
                    ...lineItem.line_item,
                    quantity: quantity,
                };
            }) || [];
        return line_items_formatted;
    }, [fulfillment]);

    const tableColumns = useMemo(
        () => [
            {
                title: () => {
                    return (
                        <Actions fulfillment={fulfillment} blockPreparation={blockPreparation} />
                    );
                },
                render: (_, row) => {
                    return (
                        <ScannedCodeRow>
                            <ScannedRowItem>
                                <Flex direction="row" justifyContent="space-between" width="100%">
                                    <Flex direction="row" alignItems="center">
                                        <ScannedRowTitle bold width="100px">
                                            Estado Picking:{' '}
                                        </ScannedRowTitle>
                                        <PickingStatus
                                            line_item_id={row._id}
                                            quantity={row.quantity}
                                            packages={row.packages || []}
                                        />
                                        {/* {row?.status === 'sac' && ( */}
                                        {row?.manual_incidences?.length > 0 && (
                                            <IncidenceIcon
                                                className="ml-1"
                                                style={{
                                                    color: 'red',
                                                    fontSize: '20px',
                                                    marginLeft: '0.75rem',
                                                }}
                                            />
                                        )}
                                    </Flex>
                                    <span className="grid grid-cols-1 mr-10">
                                        <Flex alignItems="center">
                                            <ScannedRowTitle>Código:</ScannedRowTitle>{' '}
                                            <TypoText
                                                copyable={{
                                                    icon: (
                                                        <IconContainer
                                                            Icon={CopyIcon}
                                                            style={{
                                                                color: '#fff',
                                                                backgroundColor: '#2D3D76',
                                                                padding: '4px 5px',
                                                                fontSize: '1.1rem',
                                                                borderRadius: '3px',
                                                            }}
                                                        />
                                                    ),
                                                }}
                                            >
                                                {row[code_for_scanning] ?? row.sku ?? ''}
                                            </TypoText>
                                        </Flex>
                                    </span>
                                </Flex>
                            </ScannedRowItem>
                            <ScannedRowItem>
                                <ScannedRowTitle width="100px">Descripción: </ScannedRowTitle>{' '}
                                {row.name}{' '}
                            </ScannedRowItem>
                            <ScannedRowItem>
                                <ScannedRowTitle>Cantidad</ScannedRowTitle>
                                <div className="text-right mr-3">{row.quantity}</div>
                            </ScannedRowItem>
                        </ScannedCodeRow>
                    );
                },
            },
        ],
        [fulfillment]
    );

    const totally_scanned = fulfillment?.line_items?.reduce((acc, li) => {
        const total_in_package = fulfillment?.packages?.reduce((acc2, pk) => {
            const total_in_package = pk?.line_items?.reduce((acc3, li2) => {
                if (li2.line_item?._id === li.line_item?._id) {
                    acc3 += li2.quantity;
                }
                return acc3;
            }, 0);
            return acc2 + total_in_package;
        }, 0);
        return acc + total_in_package;
    }, 0);

    const totally_quantity = fulfillment?.line_items?.reduce((acc, li) => {
        return acc + li.quantity;
    }, 0);

    useEffect(() => {
        if (totally_scanned === totally_quantity) {
            setIsTotallyPicking(true);
        } else {
            setIsTotallyPicking(false);
        }

        return () => {
            setIsTotallyPicking(false);
        };
    }, [totally_scanned, totally_quantity]);

    const title = (
        <span className="flex gap-2">
            <BarCodeScan />
            <div>{`Códigos por escanear (${totally_scanned} de ${totally_quantity})`}</div>
        </span>
    );

    return (
        <div>
            <TableCard
                headStyle={{ paddingLeft: '15px' }}
                title={title}
                extra={
                    <Flex direction="row" columnGap="10px">
                        <PrintListButton
                            order={fulfillment.order}
                            line_items={tableData}
                            changeIcon
                        />
                    </Flex>
                }
            >
                <AntdTable
                    style={{ paddingLeft: '10px' }}
                    // {...(!blockPreparation && { multiselect: true })}
                    multiSelect={!blockPreparation}
                    tableName="scanned-codes-table"
                    // headerBackground
                    removeHeaderBackground
                    rowKey="_id"
                    columns={tableColumns}
                    dataSource={tableData}
                />
            </TableCard>
        </div>
    );
};

export default ScannedCodes;
