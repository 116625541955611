import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getAllIntegration = createAsyncThunk('integrations/getAll', async (_, state) => {
    try {
        const res = await AndesClient.get(`/integrations`);
        return res.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getAllIntegrationSlice = createSlice({
    name: 'getAllIntegrations',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {
        addIntegrationToStore: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                data: [...state.data, payload],
            };
        },
        deleteIntegrationFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.filter(
                ({ _id }) => _id !== payload
            );
            return {
                ...state,
                data: [...newArrayData],
            };
        },
        updateIntegrationFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.map((integration) => {
                if (integration._id === payload._id) {
                    return {
                        ...integration,
                        ...payload,
                    };
                } else {
                    return integration;
                }
            });
            return {
                ...state,
                data: [...newArrayData],
            };
        },
    },
    extraReducers: {
        [getAllIntegration.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getAllIntegration.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [getAllIntegration.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});
export const { addIntegrationToStore, deleteIntegrationFromStore, updateIntegrationFromStore } =
    getAllIntegrationSlice.actions;
export default getAllIntegrationSlice.reducer;
