export const validationFlowCompletedInCancelation = (flows) => {
    if (!flows?.length) return true;

    const existsFlowIntegrationInRunning = flows.find(
        (flow) => flow?.flow_type === 'INTEGRATIONS' && flow.status === 'RUNNING'
    );

    const existsFlowStockTransferSapInRunning = flows.find(
        (flow) => flow?.flow_type === 'STOCK_TRANSFER_SAP' && flow.status === 'RUNNING'
    );

    const existsFlowStockTransferPrismInRunning = flows.find(
        (flow) => flow?.flow_type === 'STOCK_TRANSFER_PRISM' && flow.status === 'RUNNING'
    );

    const existsFlowStockTransferRPRO9InRunning = flows.find(
        (flow) => flow?.flow_type === 'STOCK_TRANSFER_RPRO9' && flow.status === 'RUNNING'
    );

    const existsFlowDeliveryNoteSapInRunning = flows.find(
        (flow) => flow?.flow_type === 'DELIVERY_NOTE_SAP' && flow.status === 'RUNNING'
    );

    const existsFlowDeliveryNotePrismInRunning = flows.find(
        (flow) => flow?.flow_type === 'DELIVERY_NOTE_PRISM' && flow.status === 'RUNNING'
    );

    const existsFlowDeliveryNoteRPRO9InRunning = flows.find(
        (flow) => flow?.flow_type === 'DELIVERY_NOTE_RPRO9' && flow.status === 'RUNNING'
    );

    const existsFlowCreditNoteSapInRunning = flows.find(
        (flow) => flow?.flow_type === 'CREDIT_NOTE_SAP' && flow.status === 'RUNNING'
    );

    const existsFlowCreditNotePrismInRunning = flows.find(
        (flow) => flow?.flow_type === 'CREDIT_NOTE_PRISM' && flow.status === 'RUNNING'
    );

    const existsFlowCreditNoteRPRO9InRunning = flows.find(
        (flow) => flow?.flow_type === 'CREDIT_NOTE_RPRO9' && flow.status === 'RUNNING'
    );

    const validation =
        existsFlowIntegrationInRunning ||
        existsFlowStockTransferSapInRunning ||
        existsFlowStockTransferPrismInRunning ||
        existsFlowStockTransferRPRO9InRunning ||
        existsFlowDeliveryNoteSapInRunning ||
        existsFlowDeliveryNotePrismInRunning ||
        existsFlowDeliveryNoteRPRO9InRunning ||
        existsFlowCreditNoteSapInRunning ||
        existsFlowCreditNotePrismInRunning ||
        existsFlowCreditNoteRPRO9InRunning;

    return !validation;
};
