import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateIntegrationFromStore } from './getAllIntegration';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const editIntegration = createAsyncThunk('integrations/edit', async (data, state) => {
    try {
        const editIntegrationForItem = async (payload) => {
            const res = await AndesClient.put(`/integrations/update`, payload);
            if (res.status === 200) {
                toast.success('Integración/es modificada/s con éxito.');
                state.dispatch(updateIntegrationFromStore(payload));
            }
            return res.data;
        };
        if (typeof data === 'object') {
            await editIntegrationForItem(data);
        }
        if (Array.isArray(data)) {
            for await (const item of data) {
                await editIntegrationForItem(item);
            }
        }
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const editIntegrationSlice = createSlice({
    name: 'editIntegrations',
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    reducers: {},
    extraReducers: {
        [editIntegration.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [editIntegration.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [editIntegration.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default editIntegrationSlice.reducer;
