import { Select } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import { ModalBody, ModalSelectStyled } from './styles';

export const ModalSamePackageCode = ({
    data,
    setFulSelectedModalSamePackageCode,
    deliveriesFilters,
    fulfillments,
    name,
    setFulToProcess,
    inputValue,
}) => {
    const [fulfillmentsToShow, setFulfillmentsToShow] = useState([]);
    const [fulToShotToProcess, setFulToShowToProcess] = useState([]);
    const handleChangeFulfillment = (e) => {
        if (name === 'same-package-code-delivery-modal') {
            setFulToProcess(e);
        } else {
            setFulSelectedModalSamePackageCode(e);
        }
    };

    useEffect(() => {
        if (name === 'same-package-code-delivery-modal') {
            setFulToShowToProcess(fulfillments);
        }

        setFulfillmentsToShow(
            data?.filter((fulfillment) =>
                fulfillment?.search_references?.includes(deliveriesFilters?.search_terms)
            )
        );
    }, [data, deliveriesFilters, fulfillments, name]);

    return (
        <ModalBody>
            <span className="div1">
                Se ha detectado la existencia del mismo código de bulto para más de un pedido
                pendiente de entrega.
            </span>

            <span className="div2">
                Por favor especifique a cual pedido pertenece el código de bulto escaneado:
            </span>

            <div className="div3">
                <ModalSelectStyled
                    onChange={handleChangeFulfillment}
                    placeholder="Seleccionar pedido"
                >
                    {name === 'same-package-code-delivery-modal'
                        ? fulToShotToProcess?.map((fulfillment) => {
                              const allProcessed = fulfillment?.packages?.every(
                                  (pack) => pack.status === 'processed'
                              );
                              const alreadyProcessed = fulfillment?.packages?.filter(
                                  (pack) => pack.code === inputValue
                              );

                              return (
                                  <Select.Option
                                      disabled={
                                          allProcessed ||
                                          alreadyProcessed[0]?.status === 'processed'
                                      }
                                      key={fulfillment?._id}
                                      value={fulfillment?._id}
                                  >
                                      {fulfillment?.name}
                                  </Select.Option>
                              );
                          })
                        : fulfillmentsToShow?.map((fulfillment) => {
                              return (
                                  <Select.Option key={fulfillment?._id} value={fulfillment?._id}>
                                      {fulfillment?.name}
                                  </Select.Option>
                              );
                          })}
                </ModalSelectStyled>
            </div>
        </ModalBody>
    );
};
