import { DownOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Select } from 'antd';
import FormStyled from 'components/Utils/Form';
import useCurrentUser from 'hooks/useCurrentUser';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fulfillmentsApi } from 'redux/features/Fulfillments';
import { useCreateManualIncidenceMutation } from 'redux/features/ManualIncidences';
import { ordersApi } from 'redux/features/Orders';
import { useGetAllUsersQuery } from 'redux/features/Users';
import CurrentIncidences from '../../ShowDetailsDrawer/CurrentIncidence';
import { ContainerOther, Description, Label } from '../styled';
import AssociatedPackages from './AssociatedPackages';
import Comment from './Comment';

const IncidenceForm = ({
    selectedIds,
    ordersCopy,
    setOrdersCopy,
    ordersSelected = [],
    incidencesTypes,
    setIsCheckboxDisabled,
    closeDrawer,
    setCheckedList,
    setIsFormSubmitted,
    selectedPackageCodes,
    setSelectedPackageCodes,
}) => {
    const [form] = Form.useForm();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const [idFulfillmentsSelected, setIdFulfillmentsSelected] = useState([]);
    const [editMode, setEditMode] = useState(true);
    const [occurrenceValue, setOccurrenceValue] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const { data: usersData, isFetching: loadingUsers } = useGetAllUsersQuery();
    const [createManualIncidence, { isLoading }] = useCreateManualIncidenceMutation();
    const { userData } = useCurrentUser();

    useEffect(() => {
        if (!loadingUsers && userData?._id) {
            form.setFieldsValue({
                responsible_id: userData?._id,
            });
        }
    }, [loadingUsers, userData]);

    const handleChangeIncidence = (e) => {
        if (
            e === 'address_not_found' ||
            e == 'incident_with_order_delivery_by_logistics_operator' ||
            e == 'failed_delivery'
        ) {
            setIdFulfillmentsSelected(
                ...ordersSelected.map((order) =>
                    order?.fulfillments
                        .filter((fulfillment_id) => selectedIds.includes(fulfillment_id))
                        .map((fulfillment_id) => ({
                            fulfillment_id,
                            order_id: order?._id,
                        }))
                )
            );
        }

        if (e === 'other' && occurrenceValue.trim() === '') {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    };

    const onSubmit = async () => {
        const values = formRef.current;
        const response = await createManualIncidence({
            ...values,
            order_ids: idFulfillmentsSelected?.length
                ? []
                : ordersSelected.map((order) => order._id),
            fulfillments: idFulfillmentsSelected,
        }).catch((error) => {
            console.log('error incidence', { error });
        });

        if (!response) return;

        // dispatch(getFilteredOrders());
        dispatch(fulfillmentsApi.util.invalidateTags(['fulfillmentsToDelivery']));
        dispatch(fulfillmentsApi.util.invalidateTags(['fulfillmentsToPreparation']));
        dispatch(ordersApi.util.invalidateTags(['Orders', 'Order']));

        if (ordersSelected.length < ordersCopy.length) {
            const ordersAux = ordersCopy.filter(
                (order) => !ordersSelected.map((order) => order._id).includes(order._id)
            );

            setOrdersCopy(ordersAux);
        } else {
            closeDrawer();
        }

        setIsCheckboxDisabled(false);
        setCheckedList([]);
        setIsFormSubmitted(true);
    };

    const onSubmitCreate = (values) => {
        setEditMode(false);
        formRef.current = values;
    };

    const handleEditClick = () => {
        setEditMode(true);
        setIsCheckboxDisabled(false);
    };

    const handleOccurrenceChange = (e) => {
        setOccurrenceValue(e.target.value);

        if (form.getFieldValue('incidence_type') === 'other' && e.target.value.trim() === '') {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    };

    const handleSetIsCheckboxDisabled = () => {
        setIsCheckboxDisabled(true);
    };

    if (!editMode) {
        return (
            <FormStyled
                form={form}
                layout="vertical"
                name="manual-incidence-form"
                onFinish={onSubmit}
            >
                <CurrentIncidences
                    incidence={{
                        order_ids: ordersSelected,
                        incidence_type: form.getFieldValue('incidence_type'),
                        responsible_id: userData,
                        comment: form.getFieldValue('comment') || 'Sin comentarios',
                        ocurrence: occurrenceValue,
                    }}
                    editMode
                    handleEditClick={handleEditClick}
                />
                <div className="mt-4">
                    <div className="w-full">
                        <Divider style={{ marginTop: '50px' }} />
                        <div className="flex justify-end">
                            <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    htmlType="submit"
                                    disabled={isLoading}
                                    style={{
                                        color: 'white',
                                        backgroundColor: '#F27127',
                                        border: '#E79B09',
                                    }}
                                >
                                    <Label color="currentColor">Guardar incidencia</Label>
                                </Button>
                            </Form.Item>
                        </div>
                    </div>
                </div>
            </FormStyled>
        );
    }

    return (
        <div className="w-full px-2">
            <div className="w-full flex flex-col justify-center">
                <Label>Detalles de Incidencia</Label>
                {ordersSelected?.map((order) => (
                    <Description key={order._id}>{`Pedido ${order?.name}`}</Description>
                ))}
            </div>

            <div className="mt-4">
                <FormStyled
                    form={form}
                    layout="vertical"
                    name="manual-incidence-form"
                    onFinish={onSubmitCreate}
                    requiredMark={false}
                >
                    <Form.Item
                        name="incidence_type"
                        label={<Label>Tipo de incidencia</Label>}
                        style={{ marginBottom: '20px' }}
                        rules={[
                            {
                                required: true,
                                message: 'Por favor, seleccione un tipo de incidencia.',
                            },
                        ]}
                        requiredMark={false}
                    >
                        <Select
                            onChange={handleChangeIncidence}
                            suffixIcon={<DownOutlined style={{ color: '#F27127' }} />}
                            placeholder="Selecciona"
                        >
                            {incidencesTypes.map((incidenceType) => (
                                <Select.Option
                                    key={incidenceType.value}
                                    value={incidenceType.value}
                                >
                                    {incidenceType.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {/* EN CASO DE SER TIPO ... */}
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.incidence_type !== currentValues.incidence_type
                        }
                    >
                        {({ getFieldValue }) => {
                            const incidencesTypesNeedsSelectPackage = incidencesTypes.filter(
                                (it) => it.need_select_package
                            );
                            const incidenceTypeSelected = incidencesTypesNeedsSelectPackage.find(
                                (it) => it.value === getFieldValue('incidence_type')
                            );
                            return incidenceTypeSelected ? (
                                <AssociatedPackages
                                    ordersSelected={ordersSelected}
                                    setSelectedPackageCodes={setSelectedPackageCodes}
                                    setIsButtonDisabled={setIsButtonDisabled}
                                    selectedPackageCodes={selectedPackageCodes}
                                />
                            ) : null;
                        }}
                    </Form.Item>

                    {/* EN CASO DE SER TIPO 'OTRO' */}
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.incidence_type !== currentValues.incidence_type
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('incidence_type') === 'other' ? (
                                <ContainerOther>
                                    <Form.Item
                                        name={'ocurrence'}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Por favor, ingresa una ocurrencia.',
                                            },
                                        ]}
                                        label={<Label color="#536D8F">Ingrese la ocurrencia</Label>}
                                    >
                                        <Input onChange={handleOccurrenceChange} />
                                    </Form.Item>
                                </ContainerOther>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        name="responsible_id"
                        label={<Label>Responsable del reporte</Label>}
                        style={{ marginTop: '10px' }}
                        rules={[{ required: true }]}
                    >
                        <Select
                            loading={loadingUsers}
                            suffixIcon={<DownOutlined style={{ color: '#F27127' }} />}
                        >
                            {usersData?.map((ud) => (
                                <Select.Option key={ud?._id} value={ud?._id}>
                                    {`${ud?.firstname ?? ''} ${ud?.lastname ?? ''}`}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* COMENTARIO OPCIONAL */}
                    <Comment form={form} />

                    <Divider style={{ marginTop: '50px' }} />

                    <Form.Item style={{ display: 'flex', justifyContent: 'flex-end' }} shouldUpdate>
                        {() => (
                            <Button
                                onSubmit={handleSetIsCheckboxDisabled}
                                onClick={handleSetIsCheckboxDisabled}
                                htmlType="submit"
                                style={{
                                    color: isButtonDisabled ? '#9EBCC0' : 'white',
                                    backgroundColor: isButtonDisabled ? '#E4E8EC' : '#F27127',
                                    border: isButtonDisabled ? '#E4E8EC' : '#E79B09',
                                }}
                                disabled={isButtonDisabled || ordersSelected.length === 0}
                            >
                                <Label color="currentColor">Crear incidencia</Label>
                            </Button>
                        )}
                    </Form.Item>
                </FormStyled>
            </div>
        </div>
    );
};

export default IncidenceForm;
