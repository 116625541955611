export const manifestsFilterFields = [
    {
        label: 'ID Manifiesto',
        value: 'manifest_id',
    },
    {
        label: 'Codigo de bulto',
        value: 'search_references::package_code',
    },
    {
        label: 'Folio',
        value: 'search_references::folio',
    },
    {
        label: 'Estado',
        value: 'status',
    },
    {
        label: 'Courier',
        value: 'courier',
    },
    {
        label: 'Tipo de entrega',
        value: 'delivery_method',
    },
    {
        label: 'Nro pedido',
        value: 'search_references::remote_order_id',
    },
    {
        label: 'Nro fulfillment',
        value: 'search_references::fulfillment_name',
    },
    {
        label: 'Nro de bultos',
        value: 'packages_length',
    },
];
