import styled, { css } from 'styled-components';
import { Button } from 'antd';

export const ButtonAttachedFiles = styled(Button)`
    border: none;
    background-color: transparent;
    gap: 0.4rem;
    padding: 0;
    font-size: 13px;
    display: ${({ $display }) => ($display ? $display : 'flex')};

    p {
        font-weight: 600;
        text-decoration: underline;
        color: #2d3d76;
    }
    .icon-attached-files {
        margin-top: 0.2rem;
        font-size: 12px;
    }
    &:disabled {
        background-color: transparent;
        border: none;
    }

    &:hover {
        background-color: transparent;
        color: #2d3d76;
        border: none;
    }
`;

export const ModalTitle = styled.div`
    color: #2d3d76;
    font-size: 16px;
    font-weight: 700;
`;

export const DownloadContainer = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fill, 130px);
    gap: 8px;
    grid-auto-flow: row;
`;
export const DownloadStyled = styled.div`
    width: 132px;
    height: 130px;
    border-radius: 5px 5px 0 0;
    border: 1px solid #e4e8ec;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 6px;
    svg {
        height: 80%;
        width: 80%;
    }
    .ticket-icon {
        height: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    p,
    b {
        text-align: center;
        font-size: 9px;
        color: #142c4a;
        padding: 8px 4px;
        flex: 1;
        max-height: 50px;
    }
    b {
        font-size: 12px;
    }
`;

export const DownloadCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ $disabled }) =>
        $disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`;

export const DownloadButton = styled(Button)`
    width: 132px;
    height: 40px;
    border: 1px solid #e4e8ec;
    border-radius: 0 0 5px 5px;
    background-color: #e4e8ec;
    display: flex;
    gap: 0.6rem;

    &:hover {
        p {
            color: white !important;
        }
    }

    p {
        font-size: 15px;
        color: #2d3d76;
        margin-top: 0.1rem;
    }
    .icon-download {
        margin-top: 0.4rem;
        width: 17px;
        height: 17px;
    }
`;
