export const allIncidences = (type) => {
    if (type === 'other') {
        return 'Otro';
    } else if (type === 'insufficient_stock') {
        return 'Stock Insuficiente';
    } else if (type === 'product_in_bad_condition') {
        return 'Producto en mal estado';
    } else if (type === 'address_not_found') {
        return 'Dirección no encontrada';
    } else if (type === 'misplaced_package') {
        return 'Paquete extraviado';
    } else if (type === 'accident_vehicle') {
        return 'Vehículo accidentado';
    } else if (type === 'damaged_package') {
        return 'Paquete sufrió daño';
    } else if (type === 'package_not_received') {
        return 'Paquete no recepcionado';
    } else if (type === 'package_not_pickup') {
        return 'Paquete no retirado';
    } else if (type === 'incidence_with_order_delivery_by_logistics_operator') {
        return 'Incidencia con entrega de pedidos por operador logístico';
    } else {
        return '';
    }
};
