import {
    BackButton,
    HeaderBody,
    HeaderCollapsible,
    HeaderFooter,
    HeaderHomeStyle,
    HeaderLabelBody,
    HeaderLabelTitle,
    HeaderLabelWrapper,
    HeaderTitle,
    TagStatusContent,
} from './styles';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { FiChevronDown } from 'react-icons/fi';
import { useState, useEffect } from 'react';
import PageTag from 'components/TagsV2';
import dayjs from 'dayjs';
import useMasterLabels from 'hooks/useMasterLabels';
import { Link } from 'react-router-dom';
import { CompleteFulfillmentButton } from './completeFulfillment';
import { AssignedOperators } from 'pages/OrderV2/components/Header/AssignedOperators';
import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import { Dot } from 'components/Utils/Dot';
import { Skeleton } from 'antd';
import Flex from 'components/Utils/Flex';

import { BsFillExclamationDiamondFill } from 'react-icons/bs';
import { BiHourglass } from 'react-icons/bi'; //pendiente
import { ReactComponent as PausedIcon } from 'assets/pause-circle-blue.svg'; //  pausado
import { BsPersonCheck } from 'react-icons/bs';
import { ReactComponent as DeliveredToCourierIcon } from 'assets/estado-despachado.svg'; // entregado a courier
import { MdOutlineCancel } from 'react-icons/md'; // cancelado
import { ReactComponent as FulfilledIcon } from 'assets/box.svg';
import { ReactComponent as PartiallyFulfilledIcon } from 'assets/box-unpackec.svg';
import { MdKeyboardArrowRight } from 'react-icons/md'; // cancelado

const HeaderLabel = ({ children, title, ...otherProps }) => {
    return (
        <HeaderLabelWrapper {...otherProps}>
            <HeaderLabelTitle>{title ?? ''}</HeaderLabelTitle>
            <HeaderLabelBody>{children}</HeaderLabelBody>
        </HeaderLabelWrapper>
    );
};
const getShippingModeLabel = {
    shipping: 'Despacho',
    pickup: 'Retiro en tienda',
};

const statusColors = {
    open: '#B00303',
    paused: '#B00303',
    cancelled: '#B00303',
    sac: '#B00303',
    partially_processed: '#E3CD06',
    pending_pdf: '#E3CD06',
    ready_for_deliver: '#089460',
    delivered_to_courier: '#089460',
    delivered: '#089460',
};

export const PreparationHeaderV2 = ({ fulfillment, fulfillmentList, loading }) => {
    const [showMoreHeader, setShowMoreHeader] = useState(true);
    const toggleHeader = () => setShowMoreHeader(!showMoreHeader);
    const { getLabel } = useMasterLabels();
    const [, , preparationPreferences] = useGetPreparationPreference();

    const [iconStatusFulfillment, setIconStatusFulfillment] = useState('');

    useEffect(() => {
        let icon;

        if (fulfillment?.status === 'open') {
            icon = <BiHourglass className="header-table-icon-status" />;
        }
        if (fulfillment?.status === 'paused') {
            icon = <PausedIcon />;
        }
        if (fulfillment?.status === 'delivered_to_courier') {
            icon = <DeliveredToCourierIcon />;
        }
        if (fulfillment?.status === 'delivered') {
            icon = <BsPersonCheck />;
        }
        if (fulfillment?.status === 'cancelled') {
            icon = <MdOutlineCancel />;
        }
        if (fulfillment?.status === 'success' || fulfillment?.status === 'ready_for_deliver') {
            icon = <FulfilledIcon style={{ width: '14px' }} />;
        }
        if (fulfillment?.status === 'sac') {
            icon = <BsFillExclamationDiamondFill />;
        }
        if (fulfillment?.status === 'partially_processed') {
            icon = <PartiallyFulfilledIcon />;
        }
        setIconStatusFulfillment(icon);
    }, [fulfillment]);

    const promised_date = dayjs(fulfillment?.promised_date);
    const actually_date = dayjs();
    const diff = promised_date.diff(actually_date, 'hour');

    const history = useHistory();

    return (
        <>
            <HeaderHomeStyle>
                <Link style={{ color: '#7998a7' }} to="/fulfillments">
                    Preparación
                </Link>{' '}
                /{' '}
                <Link style={{ color: '#536D8F' }} to={`/fulfillments/${fulfillment?._id}`}>
                    Preparación pedido
                </Link>
            </HeaderHomeStyle>
            {loading ? (
                <Skeleton active />
            ) : (
                <HeaderCollapsible $showMore={showMoreHeader}>
                    <>
                        <HeaderBody $showMore={showMoreHeader}>
                            <div className="preparation-page__header-back-button">
                                <BackButton onClick={() => history.goBack()}>
                                    <AiOutlineArrowLeft />
                                </BackButton>
                            </div>

                            <HeaderTitle className="preparation-page__header-title">
                                {fulfillment?.name ?? ''}
                            </HeaderTitle>

                            {/* PLazo límite */}
                            <div className="preparation-page__header-term_limit">
                                <PageTag type="preparation_tags" label="Plazo límite:">
                                    <Dot
                                        size="8px"
                                        color={statusColors[fulfillment?.status] || '#B00303'}
                                        style={{ marginRight: '3px' }}
                                    />
                                    {diff} hrs
                                </PageTag>
                            </div>

                            {/* Estado */}
                            <div className="preparation-page__header-status">
                                <PageTag type="preparation_tags" label="Estado:">
                                    <TagStatusContent>
                                        <div className="fulfillment-status">
                                            {getLabel(fulfillment?.status)}
                                        </div>
                                        <div className="icon-fulfillment-status">
                                            {iconStatusFulfillment}
                                        </div>
                                    </TagStatusContent>
                                </PageTag>
                            </div>

                            {/* Fecha pedido */}
                            <HeaderLabel
                                className="preparation-page__header-date_order"
                                title="Fecha pedido:"
                            >
                                {dayjs(fulfillment?.order?.createdAt).format('DD/MM/YYYY HH:mm')}
                            </HeaderLabel>

                            {/* Tipo de envío */}
                            <div className="preparation-page__header-delivery_method">
                                <PageTag type="preparation_tags" label="Tipo de envío:">
                                    {getShippingModeLabel[fulfillment?.delivery_method] ??
                                        fulfillment?.delivery_method}
                                </PageTag>
                            </div>

                            {/* Courier */}
                            <div className="preparation-page__header-courier">
                                <PageTag type="preparation_tags" label="Courier:">
                                    {fulfillment?.courier?.public_name ?? ''}
                                </PageTag>
                            </div>

                            {/* Detalle del pedido */}
                            <div className="preparation-page__header-order_detail">
                                <Link
                                    className="preparation-page__header-order_detail"
                                    to={`/orders/${fulfillment?.order?._id}`}
                                    style={{
                                        fontSize: '12px',
                                    }}
                                >
                                    <u>
                                        <Flex direction="row" columnGap="4px">
                                            <span>Ver detalle pedido</span>
                                            <MdKeyboardArrowRight style={{ fontSize: '16px' }} />
                                        </Flex>
                                    </u>
                                </Link>
                            </div>
                            {/* completar preparación */}
                            <div className="preparation-page__header-complete_fulfillment">
                                <CompleteFulfillmentButton
                                    fulfillment={fulfillment}
                                    fulfillmentList={fulfillmentList}
                                    showMoreHeader={showMoreHeader}
                                />
                            </div>

                            {/* Responsables */}
                            {preparationPreferences?.preparations_preferences
                                ?.required_operator && (
                                <HeaderLabel
                                    className="preparation-page__header-responsible"
                                    title={`Responsables: ${
                                        fulfillment?.assigned_operators?.length === 0
                                            ? 'Pendientes por asignar'
                                            : ''
                                    } `}
                                >
                                    {fulfillment?.assigned_operators?.map((operator) => {
                                        return (
                                            <AssignedOperators
                                                key={operator?._id}
                                                operator={operator}
                                            />
                                        );
                                    })}
                                </HeaderLabel>
                            )}
                        </HeaderBody>
                        <HeaderFooter $showMore={showMoreHeader}>
                            <u role="button" onClick={toggleHeader}>
                                {showMoreHeader ? 'Ver menos' : 'Ver más'}
                            </u>
                            <FiChevronDown />
                        </HeaderFooter>
                    </>
                </HeaderCollapsible>
            )}
        </>
    );
};
