import FormModal from 'components/Modal/FormModal';

import EditAddressForm from 'pages/Order/components/Forms/EditAddressForm';

export const ModalInfo = ({ isOpen, onClose, data }) => {
    // data puede ser fulfillment o logística

    const otherProps = {
        isShipping: data?.delivery_method === 'shipping' || false,
        locationData: data?.location_id || {},
        disableEverything: true,
    };

    const modalName = 'edit-shipping-address';

    return (
        <FormModal
            formComponent={
                <EditAddressForm
                    otherProps={otherProps}
                    // refetch={refetch}
                    setShowModalEditShippingAddress={isOpen}
                />
            }
            formProps={{
                formName: modalName,
                initialValues: {
                    _id: data?._id,
                    ...data?.shipping_address,
                },
                mainObject: 'shipping_address',
            }}
            modalProps={{
                visible: isOpen,
                modalName: modalName,
                title: `Información de entrega`,
                width: 600,
                okText: 'Guardar cambios',
                // onOk: () => setShowModalEditShippingAddress(false),
                onCancel: () => onClose(false),
                okButtonProps: {
                    style: {
                        display: 'none',
                        backgroundColor: '#F27127',
                        color: 'white',
                        border: '1px solid #E79B09 ',
                    },
                },
                cancelText: 'Cerrar',
                cancelButtonProps: {
                    style: {
                        backgroundColor: 'none',
                        color: '#2D3D76',
                        border: '1px solid #2D3D76 ',
                    },
                },
            }}
        ></FormModal>
    );
};
