import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const mappingsApi = createApi({
    reducerPath: 'mappings',
    baseQuery,
    tagTypes: ['mappings'],
    endpoints: (builder) => ({
        getMappings: builder.query({
            method: 'get',
            query: (body) => ({
                url: `/settings/mappings`,
                method: 'get',
                body,
            }),
            providesTags: ['mappings'],
        }),
        createMapping: builder.mutation({
            method: 'post',
            query: (body) => ({
                url: `/settings/mappings`,
                method: 'post',
                body,
            }),
            invalidatesTags: ['mappings'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando mapeo...</b>,
                    success: <b>Mapeo creado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        duplicateMapping: builder.mutation({
            method: 'post',
            // eslint-disable-next-line no-unused-vars
            query: ({ _id, ...body }) => ({
                url: `/settings/mappings`,
                method: 'post',
                body,
            }),
            invalidatesTags: ['mappings'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Duplicando mapeo...</b>,
                    success: <b>Regla de mapeo duplicada con éxito</b>,
                    error: <b>Error en el intento de duplicación de regla de mapeo</b>,
                });
            },
        }),
        updateMapping: builder.mutation({
            method: 'put',
            query: (body) => ({
                url: `/settings/mappings/${body._id}`,
                method: 'put',
                body,
            }),
            invalidatesTags: ['mappings'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando mapeo...</b>,
                    success: <b>Mapeo actualizado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        deleteMapping: builder.mutation({
            method: 'delete',
            query: (id) => ({
                url: `/settings/mappings/${id}`,
                method: 'delete',
                body: { id },
            }),
            invalidatesTags: ['mappings'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Eliminando mapeo...</b>,
                    success: <b>Mapeo eliminado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
    }),
});

export const {
    useGetMappingsQuery,
    useCreateMappingMutation,
    useDuplicateMappingMutation,
    useUpdateMappingMutation,
    useDeleteMappingMutation,
} = mappingsApi;
