import MoneyFormat from 'helpers/MoneyFormat';
import { useEffect, useMemo, useState } from 'react';
import { FulfillmentSummaryPrices, FulfillmentSummaryWrapper } from './styles';

const genericItemsReducer = (items = []) => {
    return items.reduce(
        (acc, item) => {
            const itemsQuantity = item?.quantity ?? 0;
            // -
            // (item?.removed_quantity ?? 0) -
            // (item?.refunded_quantity ?? 0);

            acc.totalPrice += itemsQuantity * (item?.unit_price ?? 0);
            acc.totalDiscount += itemsQuantity * (item?.unit_discount ?? 0);

            return acc;
        },
        {
            totalPrice: 0,
            totalDiscount: 0,
        }
    );
};

export const FulfillmentSummary = ({ name, data, orderData }) => {
    const [totalPriceTotalOrder, setTotalPriceTotalOrder] = useState(0);
    const [totalDiscountTotalOrder, setTotalDiscountTotalOrder] = useState(0);
    const [totalPriceFulfillment, setTotalPriceFulfillment] = useState(0);
    const [totalDiscountFulfillment, setTotalDiscountFulfillment] = useState(0);
    const [totalPriceLogistic, setTotalPriceLogistic] = useState(0);
    const [totalDiscountLogistic, setTotalDiscountLogistic] = useState(0);
    const [withRemovedShippingCost, setWithRemovedShippingCost] = useState(false);

    useEffect(() => {
        const hasAdjustDecimal = !!orderData?.special_line_items?.length;
        const isAllItemsCancelled = orderData?.line_items?.every((lineItem) => {
            return lineItem?.removed_quantity === lineItem?.quantity;
        });
        const lineItemsTotalOrder =
            hasAdjustDecimal && !isAllItemsCancelled
                ? [...(orderData?.line_items ?? []), ...(orderData?.special_line_items ?? [])]
                : orderData?.line_items;

        const reduceLineItemsTotalOrder = genericItemsReducer(lineItemsTotalOrder);
        setTotalPriceTotalOrder(reduceLineItemsTotalOrder.totalPrice);
        setTotalDiscountTotalOrder(reduceLineItemsTotalOrder.totalDiscount);
    }, [orderData]);

    useEffect(() => {
        const isFulfillmentItemsCancelled = data?.line_items?.every((lineItem) => {
            const currentLineItem = lineItem?.line_item ?? lineItem;
            return currentLineItem?.removed_quantity === currentLineItem?.quantity;
        });
        const lineItemsFulfillment = (
            data?.line_items
                ? [
                      ...(data?.line_items ?? []),
                      ...(!isFulfillmentItemsCancelled ? orderData?.special_line_items ?? [] : []),
                  ]
                : []
        )?.map((line_item) => {
            if (line_item?.line_item) {
                return line_item?.line_item;
            }
            return line_item;
        });

        const isLogisticItemsCancelled = data?.line_item_ids?.every((lineItem) => {
            return lineItem?.removed_quantity === lineItem?.quantity;
        });
        const lineItemsLogistics = data?.line_item_ids
            ? [
                  ...(data?.line_item_ids ?? []),
                  ...(!isLogisticItemsCancelled ? orderData?.special_line_items ?? [] : []),
              ]
            : [];

        const reducePriceItemsFulfillment = genericItemsReducer(lineItemsFulfillment);

        const reducePriceItemsLogistics = genericItemsReducer(lineItemsLogistics);

        if (!lineItemsFulfillment?.[0]) {
            setTotalPriceFulfillment(undefined);
            setTotalDiscountFulfillment(undefined);
        } else {
            setTotalPriceFulfillment(reducePriceItemsFulfillment.totalPrice);
            setTotalDiscountFulfillment(reducePriceItemsFulfillment.totalDiscount);
        }

        setTotalPriceLogistic(reducePriceItemsLogistics.totalPrice);
        setTotalDiscountLogistic(reducePriceItemsLogistics.totalDiscount);
    }, [data]);

    let shippingItem =
        Array.from(data?.shipping_items ?? [])?.[0] ??
        Array.from(data?.shipping_item_ids ?? [])?.[0];

    if (data?.logistic_id && !shippingItem) {
        const findLogistic = orderData?.logistics_info?.find(
            (logistic) => String(logistic._id) === String(data?.logistic_id)
        );

        if (findLogistic && findLogistic?.shipping_item_ids?.[0]) {
            shippingItem = findLogistic.shipping_item_ids[0];
        }
    }

    const shippingCost = useMemo(() => {
        const withoutShippingCost = orderData?.total_shipping === 0;
        if (withoutShippingCost) setWithRemovedShippingCost(true);

        if (name === 'order_total_summary' && orderData?.total_shipping > 0)
            return orderData?.total_shipping ?? 0;

        if (name === 'fulfillment_summary') {
            const shippingItems = orderData?.shipping_items ?? [];
            const shippingItemFulfillment = shippingItems?.reduce((acc, item) => {
                if ((item?.fulfillment_id ?? item?.logistic_id) === data?._id)
                    acc += item?.price ?? 0;
                return acc;
            }, 0);

            return shippingItemFulfillment;
        }

        return 0;
    }, [name, orderData, data, shippingItem]);

    return (
        <FulfillmentSummaryWrapper name={name}>
            <span className="summary_fulfillment_title">
                {name === 'fulfillment_summary' && 'Resumen del fulfillment'}

                {name === 'order_total_summary' && 'Valor total del pedido:'}
            </span>
            <FulfillmentSummaryPrices name={name}>
                <span className="summary_fulfillment_subtitule">
                    Sub-total:{' '}
                    <span className="summary_fulfillment_price">
                        {' '}
                        {name === 'fulfillment_summary'
                            ? totalPriceFulfillment !== undefined
                                ? MoneyFormat(
                                      Number(totalPriceFulfillment) +
                                          Number(totalDiscountFulfillment),
                                      orderData?.currency
                                  )
                                : MoneyFormat(
                                      totalPriceLogistic + totalDiscountLogistic,
                                      orderData?.currency
                                  )
                            : MoneyFormat(
                                  Number(totalPriceTotalOrder) + Number(totalDiscountTotalOrder),
                                  orderData?.currency
                              )}
                    </span>
                </span>
                <span className="summary_fulfillment_subtitule">
                    Descuentos:
                    <span className="summary_fulfillment_price">
                        {' '}
                        -
                        {name === 'fulfillment_summary'
                            ? totalDiscountFulfillment !== undefined
                                ? MoneyFormat(Number(totalDiscountFulfillment), orderData?.currency)
                                : MoneyFormat(Number(totalDiscountLogistic), orderData?.currency)
                            : MoneyFormat(Number(totalDiscountTotalOrder), orderData?.currency)}
                    </span>
                </span>
                {(data?.delivery_method === 'shipping' ||
                    (name === 'order_total_summary' && shippingCost > 0)) && (
                    <span className="summary_fulfillment_subtitule">
                        Costo de envío:{' '}
                        <span
                            className={`summary_fulfillment_price ${
                                withRemovedShippingCost
                                    ? 'summary_fulfillment_shipping_removed'
                                    : ''
                            }`}
                        >
                            {' '}
                            {MoneyFormat(shippingCost)}
                        </span>
                    </span>
                )}
                <span className="summary_fulfillment_subtitule total_price">
                    Total:{' '}
                    <span className="summary_fulfillment_price">
                        {' '}
                        {name === 'fulfillment_summary'
                            ? totalPriceFulfillment +
                              totalDiscountFulfillment -
                              totalDiscountFulfillment +
                              shippingCost
                                ? MoneyFormat(
                                      Number(totalPriceFulfillment) +
                                          Number(totalDiscountFulfillment) -
                                          Number(totalDiscountFulfillment) +
                                          Number(shippingCost),
                                      orderData?.currency
                                  )
                                : MoneyFormat(
                                      Number(totalPriceLogistic) +
                                          Number(totalDiscountLogistic) -
                                          Number(totalDiscountLogistic) +
                                          Number(shippingCost),
                                      orderData?.currency
                                  )
                            : MoneyFormat(
                                  Number(totalPriceTotalOrder) +
                                      Number(totalDiscountTotalOrder) -
                                      Number(totalDiscountTotalOrder) +
                                      Number(shippingCost),
                                  orderData?.currency
                              )}
                    </span>
                </span>
            </FulfillmentSummaryPrices>
        </FulfillmentSummaryWrapper>
    );
};
