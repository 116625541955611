import styled from 'styled-components';

const IncidenceWrapper = styled.div`
    border: 1px solid #e6eaee;
    // border-radius: 5px;
    padding: 1rem;
`;
const TitleIncidence = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #142c4a;
    margin-bottom: 1rem;

    .title_incidence {
        font-size: 14px;
        font-weight: 500;
    }
    .subtitle_incidence {
        font-size: 12px;
    }
`;

const BodyIncidence = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 14px;
    margin-top: 2rem;

    .label_body_incidence {
        color: #536d8f;
    }

    .value_body_incidence {
        color: #142c4a;
        font-weight: 500;
        margin-left: 20px;
        align-items: center;
    }
    .quantity_incidence_body {
        display: flex;
        flex-direction: row;
        gap: 5px;
    }
`;

const DetailIncidence = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;

    .quantity_incidence {
        background-color: #dce5ec;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const CurrentIncidence = ({
    incidence: {
        line_items = [],
        incidence_type = '',
        responsible_id = {},
        comment = '',
        incidence_quantity = {},
        ocurrence = '',
    },
}) => {
    const writeIncidencesTypes = (type) => {
        if (type === 'other') {
            return 'Otro';
        } else if (type === 'insufficient_stock') {
            return 'Stock Insuficiente';
        } else if (type === 'product_in_bad_condition') {
            return 'Producto en mal estado';
        } else {
            return '';
        }
    };
    console.log('line_items', line_items);
    console.log('incidence_quantity', incidence_quantity);
    return (
        <IncidenceWrapper>
            <TitleIncidence>
                <span className="title_incidence">Detalles incidencia</span>
                <span className="subtitle_incidence">
                    {' '}
                    {line_items[0] &&
                        line_items.map((lis) => {
                            return <span key={lis._id}>{lis.name}</span>;
                        })}
                </span>
            </TitleIncidence>
            <BodyIncidence>
                <DetailIncidence>
                    <span className="label_body_incidence">Tipo de incidencia</span>
                    <span className="value_body_incidence">
                        {writeIncidencesTypes(incidence_type)}
                    </span>
                </DetailIncidence>
                {/* OCURRENCIA */}
                <DetailIncidence>
                    <span className="label_body_incidence">Ocurrencia</span>
                    <span className="value_body_incidence">{ocurrence ? ocurrence : 'N/A'}</span>
                </DetailIncidence>
                {/* CANTIDADES CON INCIDENCIA */}
                <DetailIncidence>
                    <span className="label_body_incidence">Cantidades con incidencia</span>
                    {line_items?.length ? (
                        line_items?.map((lineItemIncidence) => (
                            <div
                                className="value_body_incidence quantity_incidence_body"
                                key={lineItemIncidence._id}
                            >
                                <span>{lineItemIncidence?.line_item?.name}:</span>
                                <span className="quantity_incidence">
                                    {lineItemIncidence.incident_quantity}
                                </span>
                            </div>
                        ))
                    ) : (
                        <span className="value_body_incidence">N/A</span>
                    )}
                </DetailIncidence>
                {/* RESPONSABLE */}
                <DetailIncidence>
                    <span className="label_body_incidence">Responsable del reporte</span>
                    <span className="value_body_incidence">
                        {responsible_id?.firstname || responsible_id?.lastname
                            ? `${responsible_id?.firstname} ${responsible_id?.lastname}`
                            : 'Servicio externo'}
                    </span>
                </DetailIncidence>
                {/* COMENTARIO */}
                <DetailIncidence>
                    <span className="label_body_incidence">Comentario</span>
                    <span className="value_body_incidence">{comment ? comment : 'N/A'}</span>
                </DetailIncidence>
            </BodyIncidence>
            {/* TIPO INCIDENCIA */}
        </IncidenceWrapper>
    );
};

export default CurrentIncidence;
