import Drawer from 'components/Drawer';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from 'redux/features/Utils';
import PropTypes from 'prop-types';
import NormalButton from 'components/Buttons/NormalButton';
import MenuItem from 'components/V2/Menu/MenuItem';
import { Tooltip } from 'antd';

const DrawerWithButton = ({ children, drawerProps, buttonProps, titleTooltip }) => {
    const { name } = drawerProps;
    const dispatch = useDispatch();
    return (
        <Tooltip title={titleTooltip}>
            <Drawer
             maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.12)' }}
             contentWrapperStyle={{ boxShadow: 'none' }}
             drawerProps={drawerProps}>{children}</Drawer>
            {buttonProps?.isMenuItem ? (
                <MenuItem onClick={() => dispatch(toggleDrawer(name))} {...buttonProps}>
                    {buttonProps?.buttonText}
                </MenuItem>
            ) : (
                <NormalButton onClick={() => dispatch(toggleDrawer(name))} {...buttonProps} />
            )}
        </Tooltip>
    );
};

DrawerWithButton.propTypes = {
    name: PropTypes.string,
    title: PropTypes.any,
    buttonText: PropTypes.any,
    children: PropTypes.node,
    buttonProps: PropTypes.object,
    drawerProps: PropTypes.object,
};

export default DrawerWithButton;
