import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';
import { toggleDrawer, toggleModal } from '../Utils';
import { getFilteredOrders } from './getFilteredOrders';

import moment from 'moment';
import qs from 'qs';
import { Fragment } from 'react';
import getOrderById from './getOrderById';
import pako from 'pako';

// const parseSalesChannelWithOrigin = (sales_channel) => {
//     const separator = '::';
//     const salesChannelWithOrigin = String(sales_channel).split(separator);

//     return {
//         sales_channel: salesChannelWithOrigin[0],
//         origin: salesChannelWithOrigin[1],
//     };
// };

export const ordersApi = createApi({
    reducerPath: 'orders',
    baseQuery,
    tagTypes: ['Orders', 'Order'],
    endpoints: (builder) => ({
        AssignMultiplesResponsables: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/orders/assignMultipleResponsables`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Asignando responsables...</b>,
                    success: <b>Responsables asignados</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            onCacheEntryAdded(arg, api) {
                api.dispatch(getFilteredOrders());
            },
            invalidatesTags: ['Orders'],
        }),
        getOrdersByFilters: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.ordersFilters;
                let filters = args ? args : actuallyFilters;
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );

                if (clearedFilters.date_range.length > 0) {
                    clearedFilters.date_range = [
                        moment(clearedFilters.date_range[0]).format('YYYY-MM-DD'),
                        moment(clearedFilters.date_range[1]).format('YYYY-MM-DD'),
                    ];
                }

                const querystring = qs.stringify(clearedFilters);

                return fetchWithBQ(`ordersv2/search?${querystring}`);
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.docs.map(({ _id }) => ({ type: 'Orders', id: _id })),
                          {
                              type: 'Orders',
                              id: 'LIST',
                          },
                      ]
                    : [{ type: 'Orders', id: 'LIST' }];
            },
        }),
        getOrdersAllIdsByFilters: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.ordersFilters;
                let filters = args ? args : actuallyFilters;
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );

                if (clearedFilters.date_range.length > 0) {
                    clearedFilters.date_range = [
                        moment(clearedFilters.date_range[0]).format('YYYY-MM-DD'),
                        moment(clearedFilters.date_range[1]).format('YYYY-MM-DD'),
                    ];
                }

                const querystring = qs.stringify(clearedFilters);

                return fetchWithBQ(`ordersv2/search/ids?${querystring}`);
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.docs.map(({ _id }) => ({ type: 'Orders', id: _id })),
                          {
                              type: 'Orders',
                              id: 'LIST',
                          },
                      ]
                    : [{ type: 'Orders', id: 'LIST' }];
            },
        }),
        cancelItem: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/orders/cancelItem`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                const fulfillmentId = data?.fulfillmentId;

                const thisFullfillment = data?.orderId?.fulfillments.find(
                    (el) => String(el?._id) === String(fulfillmentId)
                );

                const quantityToCancelOrRefund = data?.itemsToCancel?.reduce(
                    (acc, el) => acc + el.quantity,
                    0
                );

                let removeItemsText = 'Anulando ítem...';
                let refundItemsText = 'Devolviendo ítem...';

                if (quantityToCancelOrRefund > 1) {
                    removeItemsText = 'Anulando ítems...';
                    refundItemsText = 'Devolviendo ítems...';
                }

                if (
                    (!thisFullfillment && data?.orderId?.fulfillment_status === 'delivered') ||
                    thisFullfillment?.status === 'delivered' ||
                    (thisFullfillment?.status === 'delivered_to_courier' &&
                        !thisFullfillment?.preparationcd_transfer_pickupstore?.status ===
                            'delivered_to_pickup_location')
                ) {
                    toast.promise(api.queryFulfilled, {
                        loading: <b>{refundItemsText}</b>,
                        success: <b>Devolución efectuada con éxito.</b>,
                        error: <b>Ocurrió un error intentando efectuar la devolución.</b>,
                    });
                } else {
                    toast.promise(api.queryFulfilled, {
                        loading: <b>{removeItemsText}</b>,
                        success: <b>Anulación efectuada con éxito.</b>,
                        error: <b>Ocurrió un error intentando efectuar la anulación.</b>,
                    });
                }
            },
            invalidatesTags: ['Orders', 'Order'],
        }),
        ChangeOrderStore: builder.mutation({
            method: 'put',
            query: (data) => {
                return {
                    url: '/orders/update/change-store/v2',
                    method: 'put',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando pedido...</b>,
                    success: <b>Pedido actualizado con éxito</b>,
                    error: (response) => (
                        <b>{response?.error?.data?.message ?? 'Ocurrió un error.'}</b>
                    ),
                });
            },
            invalidatesTags: ['Orders', 'Order'],
        }),
        MergePdfs: builder.mutation({
            method: 'put',
            query: (data) => {
                return {
                    url: '/orders/merge-pdfs',
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Obteniendo documentos...</b>,
                    success: <b>Documentos obtenidos con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        getManualIncidencesById: builder.query({
            method: 'get',
            query: (id) => {
                return {
                    url: `/orders/manualIncidences/${id}`,
                    method: 'get',
                };
            },
        }),
        createBatchFulfillments: builder.mutation({
            query: (data) => {
                return {
                    url: `/fulfillments/batchcreate`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                console.log('createBatchFulfillments', data, api);
                console.log(api.queryFulfilled);
                const promise = async () => {
                    const result = await api.queryFulfilled;
                    if (result.data?.errors?.length) {
                        return Promise.reject(result.data);
                    }
                };
                toast.promise(promise(), {
                    loading: <b>Creando fulfillment de {data.orders.length} ordenes</b>,
                    success: <b>Fulfillments creados con éxito</b>,
                    error: (err) => {
                        return (
                            <b>
                                {err?.errors?.map((error, idx, arr) => (
                                    <Fragment key={`error-alert-${idx}`}>
                                        <p>{error.message}</p>
                                        {idx !== arr.length - 1 && <br />}
                                    </Fragment>
                                ))}
                            </b>
                        );
                    },
                });

                // api.dispatch(
                //     fulfillmentsApi.endpoints.getInProgressFulfillments.initiate(undefined, {
                //         forceRefetch: true,
                //     })
                // );
                // api.dispatch(toggleModal('order-actions-preparation-modal'));
                // api.dispatch(toggleModal('batch-created-fulfillments-modal'));
                // api.dispatch(
                //     setSelectedRows({ selectedRows: [], tableName: 'prepare-orders-table' })
                // );
            },
            invalidatesTags: ['Orders'],
        }),
        changeCourier: builder.mutation({
            query: (data) => {
                return {
                    url: `/fulfillments/orders/changecourier`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                console.log('change courier', data, api);

                const actuallyDrawerState =
                    api.getState().utils.drawer['global-actions-order-drawer'];
                const actuallyModalState = api.getState().utils.modal['orders-change-couriers'];

                if (actuallyDrawerState) {
                    api.dispatch(toggleDrawer('global-actions-order-drawer'));
                }

                if (actuallyModalState) {
                    api.dispatch(toggleModal('orders-change-couriers'));
                }

                toast.promise(api.queryFulfilled, {
                    loading: <b>Cambiando Courier/Tipo de servicio...</b>,
                    success: <b>Cambio de Courier/Tipo de servicio exitoso</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['Orders'],
        }),
        CancelByLogistic: builder.mutation({
            query: (data) => {
                return {
                    url: `/orders/logistic/cancel`,
                    method: 'put',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Cancelando fulfillment...</b>, // Cancelando Logistica...
                    success: <b>Fulfillment cancelado con éxito</b>, // Logistica cancelada con éxito
                    error: <b>Ocurrió un error al cancelar el fulfillment.</b>, // Ocurrió un error al cancelar la logistica
                });
            },
            invalidatesTags: ['Order'],
        }),
        getOrder: builder.query({
            providesTags: ['Order'],
            query: (order_id) => {
                return {
                    url: `/ordersv2/order/${order_id}`,
                    method: 'get',
                };
            },
            transformResponse: async (response, meta) => {
                const contentEncoding = meta?.response?.headers.get('Content-Encoding');

                if (contentEncoding === 'gzip') {
                    const arrayBuffer = await response.arrayBuffer();
                    const decompressedData = pako.ungzip(new Uint8Array(arrayBuffer), {
                        to: 'string',
                    });
                    return JSON.parse(decompressedData)?.order;
                }

                return response?.order;
            },
        }),
        updateOrderStatus: builder.mutation({
            query: (data) => ({
                url: `/ordersv2/change-status/${data.order_id}`,
                method: 'POST',
                body: data,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando el estado del pedido</b>,
                    success: <b>Estado del pedido modificado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            onBeforeMutation: () => {
                toast.loading(<b>Actualizando el estado de la orden</b>);
            },
            onMutate: (data, { dispatch }) => {
                dispatch(toggleModal('order-change-status-modal'));
                dispatch(getOrderById(data.order_id));
                // if (states.utils?.drawer['global-actions-order-drawer']) {
                dispatch(toggleDrawer('global-actions-order-drawer'));
                // }
            },
            onError: (error) => {
                toast.error(`Error: ${error.response.data.message}`);
            },
            onSettled: () => {
                toast.dismiss();
            },
            invalidatesTags: ['Order'],
        }),
        updateOrderStatusMassive: builder.mutation({
            query: (data) => ({
                url: '/ordersv2/change-status-multiple',
                method: 'POST',
                body: data,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando estado de los pedidos</b>,
                    success: <b>Actualizando estado de los pedidos en segundo plano</b>,
                    error: (response) => (
                        <b>{response?.error?.data?.message ?? 'Ocurrió un error.'}</b>
                    ),
                });
            },
            onBeforeMutation: () => {
                toast.loading(<b>Actualizando estado de los pedidos en segundo plano</b>);
            },
            onMutate: (data, { dispatch }) => {
                dispatch(toggleModal('order-change-status-modal-massive'));
            },
            onError: (error) => {
                toast.error(`Error: ${error.response.data.message}`);
            },
            onSettled: () => {
                toast.dismiss();
            },
            invalidatesTags: ['Order'],
        }),
        deleteOrder: builder.mutation({
            query: (_id) => ({
                url: `/orders/${_id}/delete`,
                method: 'delete',
            }),
            onQueryStarted: (data, api) => {
                console.log({ data, api });
                api.dispatch(toggleDrawer('global-actions-order-drawer'));
                toast.promise(api.queryFulfilled, {
                    loading: <b>Eliminando pedido...</b>,
                    success: <b>Pedido eliminado con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['Order'],
        }),
        updateLineItemSku: builder.mutation({
            query: (data) => ({
                url: `/orders/${data.order_id}/line-items/${data.line_item_id}/sku`,
                method: 'put',
                body: {
                    sku: data.sku,
                },
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando SKU ...</b>,
                    success: () => {
                        return (
                            <b>
                                SKU actualizado con éxito. <br />
                            </b>
                        );
                    },
                    error: (response) => {
                        return <b>{response?.error?.data?.message}</b>;
                    },
                });
            },
            invalidatesTags: ['Order'],
        }),
        processShipment: builder.mutation({
            query: (data) => ({
                url: `/deliveries/orders/process-shipment`,
                method: 'post',
                body: {
                    order_ids: data.order_ids,
                },
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Procesando envío ...</b>,
                    success: () => {
                        return (
                            <b>
                                Operación exitosa <br />
                            </b>
                        );
                    },
                    error: (response) => {
                        return <b>{response?.error?.data?.message}</b>;
                    },
                });
            },
            invalidatesTags: ['Order'],
        }),
        sendOrdersToDeliveries: builder.mutation({
            query: (data) => ({
                url: `/deliveries/orders/send-orders-to-deliveries`,
                method: 'post',
                body: {
                    order_ids: data.order_ids,
                },
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Enviando a entregas ...</b>,
                    success: () => {
                        return (
                            <b>
                                Operación exitosa <br />
                            </b>
                        );
                    },
                    error: (response) => {
                        return <b>{response?.error?.data?.message}</b>;
                    },
                });
            },
            invalidatesTags: ['Order'],
        }),
        cancelOrderV2: builder.mutation({
            query: (data) => ({
                url: `/ordersv2/cancel/order`,
                method: 'post',
                body: data,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Cancelando pedido...</b>,
                    success: <b>Pedido cancelado con éxito.</b>,
                    error: (response) => (
                        <b>{response?.error?.data?.message ?? 'Ha ocurrido un error.'}</b>
                    ),
                });
            },
            invalidatesTags: ['Orders', 'Order'],
        }),
        cancelItemV2: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: `/ordersv2/cancel/item`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                const fulfillmentId = data?.fulfillmentId;

                const thisFullfillment = data?.orderId?.fulfillments.find(
                    (el) => String(el?._id) === String(fulfillmentId)
                );

                const quantityToCancelOrRefund = data?.itemsToCancel?.reduce(
                    (acc, el) => acc + el.quantity,
                    0
                );

                let removeItemsText = 'Anulando ítem...';
                let refundItemsText = 'Devolviendo ítem...';

                if (quantityToCancelOrRefund > 1) {
                    removeItemsText = 'Anulando ítems...';
                    refundItemsText = 'Devolviendo ítems...';
                }

                if (
                    (!thisFullfillment && data?.orderId?.fulfillment_status === 'delivered') ||
                    thisFullfillment?.status === 'delivered' ||
                    (thisFullfillment?.status === 'delivered_to_courier' &&
                        !thisFullfillment?.preparationcd_transfer_pickupstore?.status ===
                            'delivered_to_pickup_location')
                ) {
                    toast.promise(api.queryFulfilled, {
                        loading: <b>{refundItemsText}</b>,
                        success: <b>Devolución efectuada con éxito.</b>,
                        error: <b>Ocurrió un error intentando efectuar la devolución.</b>,
                    });
                } else {
                    toast.promise(api.queryFulfilled, {
                        loading: <b>{removeItemsText}</b>,
                        success: <b>Anulación efectuada con éxito.</b>,
                        error: <b>Ocurrió un error intentando efectuar la anulación.</b>,
                    });
                }
            },
            invalidatesTags: ['Orders', 'Order'],
        }),
        CancelByLogisticV2: builder.mutation({
            query: (data) => {
                return {
                    url: `/ordersv2/cancel/logistic-or-fulfillment`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Cancelando fulfillment...</b>, // Cancelando Logistica...
                    success: <b>Fulfillment cancelado con éxito</b>, // Logistica cancelada con éxito
                    error: <b>Ocurrió un error al cancelar el fulfillment.</b>, // Ocurrió un error al cancelar la logistica
                });
            },
            invalidatesTags: ['Orders', 'Order'],
        }),
        removeShippingPriceV2: builder.mutation({
            query: (data) => {
                return {
                    url: `/ordersv2/cancel/shipping`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando costo de envío...</b>,
                    success: <b>Costo de envío actualizado con éxito</b>,
                    error: <b>Ocurrió un error al actualizar el costo de envío.</b>,
                });
            },
            invalidatesTags: ['Orders', 'Order'],
        }),
    }),
});

export const {
    useAssignMultiplesResponsablesMutation,
    useGetOrdersByFiltersQuery,
    useMergePdfsMutation,
    useGetOrderQuery,
    useLazyGetOrdersByFiltersQuery,
    useLazyGetOrdersAllIdsByFiltersQuery,
    useCancelItemMutation,
    useChangeOrderStoreMutation,
    useGetManualIncidencesByIdQuery,
    useCreateBatchFulfillmentsMutation,
    useChangeCourierMutation,
    useCancelByLogisticMutation,
    useUpdateOrderStatusMutation,
    useDeleteOrderMutation,
    useUpdateLineItemSkuMutation,
    useProcessShipmentMutation,
    useSendOrdersToDeliveriesMutation,
    useUpdateOrderStatusMassiveMutation,
    useCancelOrderV2Mutation,
    useCancelItemV2Mutation,
    useCancelByLogisticV2Mutation,
    useRemoveShippingPriceV2Mutation,
} = ordersApi;
