import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useGetFulfillmentScannedStatusMutation } from 'redux/features/Fulfillments';
import { setFulfillmentForDelivery, setFulfillmentForPreparation } from 'redux/features/Utils';
import { useDispatch } from 'react-redux';
import history from 'helpers/history';
import { Checkbox, Form, Input } from 'antd';
import Flex from 'components/Utils/Flex';

const PreScanWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
`;
const PreScanInput = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    label {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    .ant-form-item {
        width: 100%;
        input {
            border: 2px solid black;
            height: 60px;
        }
    }
`;

const Buttons = styled.button`
    padding: 0.1rem 1rem;
    font-weight: 500;
    background-color: #1f6373;
    color: white;
`;

const ColumnTitle = styled.h2``;

const PreScan = () => {
    const scannedInputRef = useRef(null);
    const [fulfillmentsToFulfill, setFulfillmentsToFulfill] = useState([]);
    const [fulfillmentsToDeliver, setFulfillmentsToDeliver] = useState([]);
    const [multipleMode, setMultipleMode] = useState(false);

    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const [getFulfillmentStatus, scanStatus] = useGetFulfillmentScannedStatusMutation();

    const handleScan = () => {
        if (scannedInputRef.current) {
            scannedInputRef.current.focus();
        }
    };

    const handleSearchFulfillment = async () => {
        const { searchTerms } = form.getFieldsValue();
        const value = searchTerms;
        form.resetFields();

        if (scanStatus.isLoading || scanStatus.isFetching) return;

        if (searchTerms === '') return;

        const fulfillment = await getFulfillmentStatus({
            searchTerms: value,
            view: 'fulfillments',
        });

        if (fulfillment?.data?.toFulfill) {
            setFulfillmentsToFulfill((prev) => {
                const isFulfillmentInArray = prev.find((item) => item._id === fulfillment.data._id);
                if (isFulfillmentInArray) return prev;
                return [...prev, fulfillment.data];
            });
        }

        if (fulfillment?.data?.toDeliver) {
            setFulfillmentsToDeliver((prev) => {
                const isFulfillmentInArray = prev.find((item) => item._id === fulfillment.data._id);
                if (isFulfillmentInArray) return prev;
                return [...prev, fulfillment.data];
            });
        }

        const redirect_url = fulfillment?.data?.redirect_url;

        if (redirect_url && !multipleMode) {
            window.open(redirect_url, '_blank', 'noopener,noreferrer');
        }
    };

    const goToDeliveryPage = () => {
        if (fulfillmentsToDeliver.length === 0) return;
        dispatch(setFulfillmentForDelivery(fulfillmentsToDeliver.map((item) => item._id)));
        history.push(`/deliveries/multiple`);
    };

    const goToPreparationPage = () => {
        if (fulfillmentsToFulfill.length === 0) return;
        dispatch(setFulfillmentForPreparation(fulfillmentsToFulfill.map((item) => item._id)));
        history.push(`/fulfillments/${fulfillmentsToFulfill[0]._id}/multiple`);
    };

    useEffect(() => {
        if (scannedInputRef.current) {
            scannedInputRef.current.focus();
        }
        window.addEventListener('keydown', handleScan);
        return () => {
            window.removeEventListener('keydown', handleScan);
        };
    }, []);

    return (
        <Container extraTitle="Escaneo masivo">
            <PageHeader
                showBack
                title="Escaneo masivo"
                subtitle="Escaneo masivo de preparaciones y entregas"
            />

            <PreScanWrapper>
                <Form name="massive-scan-form" form={form} onFinish={handleSearchFulfillment}>
                    <PreScanInput>
                        <label>Escanea el código de barras de la preparación o entrega</label>
                        <Form.Item
                            name="searchTerms"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingresa un código de barras',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Codigo de barras, numero de orden, codigo de bulto..."
                                ref={scannedInputRef}
                                type="text"
                                disabled={scanStatus.isLoading || scanStatus.isFetching}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        form.submit();
                                    }
                                }}
                            />
                        </Form.Item>
                    </PreScanInput>
                </Form>

                <label htmlFor="test">
                    <Checkbox
                        type="checkbox"
                        id="test"
                        style={{ marginRight: '10px' }}
                        onChange={(e) => {
                            setMultipleMode(e.target.checked);
                        }}
                    />
                    <b>Modo masivo ?</b>
                </label>

                <br />
                <br />
                {multipleMode && (
                    <Flex direction="row" columnGap="1rem">
                        {fulfillmentsToFulfill.length > 0 && (
                            <Flex direction="column" flex={1} justifyContent="start">
                                <ColumnTitle>
                                    Listos para preparar {`(${fulfillmentsToFulfill.length})`}
                                </ColumnTitle>
                                <Buttons role="button" onClick={goToPreparationPage}>
                                    Ir a preparar
                                </Buttons>
                                <br />
                                <ul>
                                    {fulfillmentsToFulfill.map((fulfillment) => (
                                        <li key={fulfillment._id}>{fulfillment.remote_order_id}</li>
                                    ))}
                                </ul>
                            </Flex>
                        )}

                        {fulfillmentsToDeliver.length > 0 && (
                            <Flex direction="column" flex={1}>
                                <ColumnTitle>
                                    Listos para procesar entrega{' '}
                                    {`(${fulfillmentsToDeliver.length})`}
                                </ColumnTitle>
                                <Buttons role="button" onClick={goToDeliveryPage}>
                                    Ir a procesar entregas
                                </Buttons>
                                <br />
                                <ul>
                                    {fulfillmentsToDeliver.map((fulfillment) => (
                                        <li key={fulfillment._id}>{fulfillment.remote_order_id}</li>
                                    ))}
                                </ul>
                            </Flex>
                        )}
                    </Flex>
                )}
            </PreScanWrapper>
        </Container>
    );
};

export default PreScan;
