import { SectionTitleContainer } from './ViewTitle.styles';

export const ViewTitle = ({ icon, title }) => {
    return (
        <SectionTitleContainer
            style={{ marginLeft: !icon ? '0' : '16px' }}
            className="flex flex-cols"
        >
            <span className="mr-3">{icon}</span>
            <span style={{ color: '#2D3D76', fontWeight: 700 }} className="text-lg">
                {title}
            </span>
        </SectionTitleContainer>
    );
};
