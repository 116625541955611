import { Menu } from 'antd';
import styled, { css } from 'styled-components';

export const MenuItemStyled = styled(Menu.Item)`
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            color: #999;
        `}
`;

const MenuItem = ({ children, ...props }) => {
    return <MenuItemStyled {...props}>{children}</MenuItemStyled>;
};

export default MenuItem;
