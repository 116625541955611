import Container from 'components/Grid/Container';
import { InputCode, PageHeader, Pagination } from './components';
import {
    CardTable,
    CardTop,
    ExtraSectionStyled,
    InfoLabel,
    InfoLabelContainer,
    InfoTitle,
    InfoValue,
    ManifestPageContent,
    TableHeader,
    TagCircle,
    TagStyled,
} from './styles';
import { Button } from 'antd';
import AntdTable from 'components/AntdTable';
import {
    useConfirmBatchDeliverMutation,
    useGetBatchDeliveryByIdQuery,
    useGetBulkBatchDeliversQuery,
    useRemoveBatchDeliveryOfListMutation,
    useUpdateBatchDeliverStatusMutation,
} from 'redux/features/Deliveries';
import { useParams, useHistory } from 'react-router-dom';
import { fulfillmentColumns } from './config';
import { useEffect, useMemo, useRef, useState } from 'react';
import PackageDetailsDrawer from 'pages/DeliveriesV2/components/PackageDetailsDrawer';

const calculateBatchDeliverInReprocessed = (fulfillmentPackages) =>
    fulfillmentPackages.reduce(
        (acc, fulfillmentPackage) =>
            fulfillmentPackage.status === 're_processed' ? (acc += 1) : acc,
        0
    );

const ReValidateDeliveryPage = () => {
    const confirmButtonRef = useRef(null);
    const [batchCode, setBatchCode] = useState('');
    const history = useHistory();
    const { batch_delivery_id, multi_batch_id } = useParams();
    const { data: dataManifest } = useGetBatchDeliveryByIdQuery(batch_delivery_id);
    const { data: dataBulkBatchDelivers } = useGetBulkBatchDeliversQuery(multi_batch_id, {
        skip: !multi_batch_id,
    });

    const [confirmBatchMutation, { isLoading }] = useConfirmBatchDeliverMutation();
    const [removeBatchDeliveryMutation] = useRemoveBatchDeliveryOfListMutation();
    const [updateBatchDeliveryStatusMutation, { isLoading: isLoadingUpdateBatchStatus }] =
        useUpdateBatchDeliverStatusMutation();

    const batchDelivers = dataBulkBatchDelivers?.batch_delivers || [];
    const currentIndexBatchDelivery = batchDelivers.findIndex(
        (batchDeliveryId) => batchDeliveryId === batch_delivery_id
    );

    const handleClickConfirmBatchDeliver = () => {
        confirmBatchMutation({
            batch_id: batch_delivery_id,
            manifest_id: dataManifest.manifest_id,
        }).then((resp) => {
            if (resp.error) {
                return;
            }
            redirectNextManifest(true);
        });
    };

    const activateAnimationScanned = (code) => {
        const fulfillmentScanned = dataManifest?.fulfillments?.find(
            // (fulfillment) => !!fulfillment.packages.find((pkg) => pkg.code === code)
            (fulfillment) => fulfillment._id === code
        );
        if (!fulfillmentScanned) {
            return;
        }

        const section = document.getElementById(fulfillmentScanned._id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            section.classList.add('alerts-border');
            setTimeout(() => {
                section.classList.remove('alerts-border');
            }, 3000);
        }
    };

    const handleScanBatchCode = ({ code, fulfillmentId }) => {
        setBatchCode(code);
        activateAnimationScanned(fulfillmentId);
        updateBatchDeliveryStatusMutation({
            id: batch_delivery_id,
            package_code: code,
            fulfillment_id: fulfillmentId,
        }).finally(() => {
            setBatchCode('');
        });
    };

    const redirectNextManifest = (redirectToInit) => {
        const batchDeliversFiltered = batchDelivers.filter(
            (batchDeliveryId) => batchDeliveryId !== batch_delivery_id
        );
        const isLastIndex = currentIndexBatchDelivery + 1 === batchDelivers.length;
        if (redirectToInit && isLastIndex) {
            history.push(`/manifests`);
            return;
        }
        const nextIndex = isLastIndex ? 0 : currentIndexBatchDelivery + 1;
        const nextBatchDeliveryId = batchDelivers[nextIndex];

        if (batchDeliversFiltered.length > 1) {
            history.push(`/manifests/${nextBatchDeliveryId}/${multi_batch_id}`);
        } else {
            history.push(`/manifests/${nextBatchDeliveryId}`);
        }
    };

    const handleClickRemoveBatchDelivery = () => {
        removeBatchDeliveryMutation({ batch_deliver_id: batch_delivery_id, multi_batch_id }).then(
            (resp) => {
                if (resp.error) {
                    return;
                }

                redirectNextManifest();
            }
        );
    };

    const handleChangePagination = (page) => {
        const batchDeliveryId = batchDelivers[page - 1];
        history.push(`/manifests/${batchDeliveryId}/${multi_batch_id}`);
    };

    const totals = useMemo(
        () =>
            dataManifest?.fulfillments?.reduce(
                (acc, fulfillment) => {
                    const isAllPackagesReProcessed = fulfillment.packages.every(
                        (fulfillmentPackages) => fulfillmentPackages.status === 're_processed'
                    );
                    isAllPackagesReProcessed && (acc.orders_ready_to_deliver += 1);
                    acc.batch_totals += fulfillment.packages.length;
                    acc.batch_reprocessed += fulfillment.packages.reduce(
                        (acc, fulfillmentPackages) =>
                            (acc += fulfillmentPackages.status === 're_processed'),
                        0
                    );
                    return acc;
                },
                { orders_ready_to_deliver: 0, batch_totals: 0, batch_reprocessed: 0 }
            ),
        [dataManifest?.fulfillments]
    );

    const allDeliverScanned = totals?.batch_reprocessed === totals?.batch_totals;

    useEffect(() => {
        if (totals && allDeliverScanned) {
            confirmButtonRef?.current?.focus();
        }
    }, [allDeliverScanned]);

    return (
        <Container extraTitle={'Revalidar entrega'}>
            <PageHeader
                extraSection={
                    <ExtraSectionStyled>
                        <div className="mr-5">
                            <TagStyled>
                                <TagCircle bgColor={allDeliverScanned ? '#089460' : '#F9E107'} />{' '}
                                {totals?.batch_reprocessed}/{totals?.batch_totals} bultos escaneados
                            </TagStyled>
                            <TagStyled>
                                <TagCircle bgColor="#089460" /> Pedidos listos para entregar{' '}
                                {totals?.orders_ready_to_deliver}
                            </TagStyled>
                        </div>
                        <Button
                            ref={confirmButtonRef}
                            disabled={
                                isLoading ||
                                dataManifest?.status === 'delivered' ||
                                totals?.batch_reprocessed !== totals?.batch_totals
                            }
                            onClick={handleClickConfirmBatchDeliver}
                            type="primary"
                        >
                            Confirmar
                        </Button>
                    </ExtraSectionStyled>
                }
                showBack
                title={'Manifiesto:'}
                subtitle={dataManifest?.manifest_id}
                breadCrumbs={[
                    {
                        name: 'Entregas',
                        path: '/deliveries',
                    },
                    {
                        name: 'Revalidar pedido',
                    },
                ]}
            />
            <ManifestPageContent>
                <CardTop>
                    <InputCode
                        disabled={isLoadingUpdateBatchStatus || allDeliverScanned}
                        value={batchCode}
                        handleScanCode={handleScanBatchCode}
                        fulfillmentsManifest={dataManifest?.fulfillments}
                    />
                    {multi_batch_id && !!batchDelivers.length && (
                        <div className="text-center">
                            <InfoLabel className="justify-center">
                                <InfoTitle>Manifiesto:</InfoTitle>
                                <InfoValue>{dataManifest?.manifest_id}</InfoValue>
                            </InfoLabel>
                            <Pagination
                                onChange={handleChangePagination}
                                currentPage={currentIndexBatchDelivery + 1}
                                totalPage={batchDelivers.length}
                            />
                            <InfoTitle
                                onClick={handleClickRemoveBatchDelivery}
                                className="underline cursor-pointer"
                            >
                                Quitar de la lista
                            </InfoTitle>
                        </div>
                    )}
                </CardTop>
                {dataManifest?.fulfillments.map((fulfillment) => {
                    const batchScanned = calculateBatchDeliverInReprocessed(fulfillment.packages);
                    const isPickingCompleted = batchScanned === fulfillment.packages.length;
                    return (
                        <CardTable id={fulfillment._id} key={`fulfillment-${fulfillment._id}`}>
                            <TableHeader>
                                <InfoLabelContainer>
                                    <InfoLabel fz="18px">
                                        <InfoTitle>Pedido:</InfoTitle>
                                        <InfoValue>{fulfillment.order.name}</InfoValue>
                                    </InfoLabel>
                                    <div>
                                        <InfoLabel>
                                            <InfoTitle>ID Ecommerce:</InfoTitle>
                                            <InfoValue>{fulfillment.remote_order_id}</InfoValue>
                                        </InfoLabel>
                                        <InfoLabel>
                                            <InfoTitle>N° Fulfillment:</InfoTitle>
                                            <InfoValue>{fulfillment.name}</InfoValue>
                                        </InfoLabel>
                                    </div>
                                </InfoLabelContainer>
                                <div className="flex items-center">
                                    <TagStyled>
                                        <TagCircle
                                            bgColor={isPickingCompleted ? '#089460' : '#F9E107'}
                                        />
                                        {isPickingCompleted
                                            ? 'Escaneo completo'
                                            : `Escaneo en curso ${batchScanned}/
                                        ${fulfillment.packages.length}`}
                                    </TagStyled>
                                    <PackageDetailsDrawer fulfillment={fulfillment} />
                                </div>
                            </TableHeader>
                            <AntdTable
                                removeHeaderBackground
                                styleV2
                                rowKey="_id"
                                loading={false}
                                dataSource={fulfillment.packages.map((packages) => ({
                                    ...packages,
                                    sales_channel: fulfillment.sales_channel,
                                }))}
                                columns={fulfillmentColumns}
                            />
                        </CardTable>
                    );
                })}
            </ManifestPageContent>
        </Container>
    );
};

export default ReValidateDeliveryPage;
