import { Button, Card } from 'antd';
import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import Notifications from 'components/Notifications/Notifications';
// import TimeLine from 'components/TimeLine';
import Flex from 'components/Utils/Flex';
import { useState } from 'react';
// import { useGetLogsQuery } from 'redux/features/Logs';
import styled, { css } from 'styled-components';
import {
    useGetNotificationsQuery,
    useMarkAsReadNotificationMutation,
} from 'redux/features/Notifications';

const ButtonMenu = styled.div`
    padding: 8px;
    color: #7998a7;

    ${({ active }) =>
        active &&
        css`
            color: #536d8f;
            background-color: #dce5ec;
            border-bottom: 2px solid #9ebcc0;
        `}
`;

const CardStyled = styled(Card)`
    .ant-card-body {
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const NotificationsPage = () => {
    const [contentToRender, setContentToRender] = useState('notifications');
    const [notificationsIds, setNotificationsIds] = useState([]);

    const [maskAsRead] = useMarkAsReadNotificationMutation();

    // const logs = useGetLogsQuery(null, {
    //     pollingInterval: 180000,
    // });

    const notifications = useGetNotificationsQuery(null, {
        pollingInterval: 60000,
    });

    const handleCheckboxChange = (event) => {
        const checked = event.target.checked;
        const value = event.target.value;
        if (checked) {
            setNotificationsIds([...notificationsIds, value]);
        } else {
            setNotificationsIds(notificationsIds.filter((_id) => _id !== value));
        }
    };

    const handleMarkAsRead = () => {
        if (notificationsIds.length > 0) {
            maskAsRead({ notification_ids: notificationsIds });
            setNotificationsIds([]);
        }
    };

    return (
        <Container extraTitle="Notificaciones">
            <PageHeader
                showBack
                title="Centro de registros"
                subtitle="Mostrando los registros de las ultimas 24 horas"
                breadcrumb={[{ name: `Notificaciones y Comentarios` }]}
            />

            <CardStyled
                title={
                    <Flex direction="row" columnGap="16px">
                        <ButtonMenu
                            role="button"
                            onClick={() => setContentToRender('notifications')}
                            active={contentToRender === 'notifications'}
                        >
                            Notificaciones
                        </ButtonMenu>

                        <ButtonMenu
                            role="button"
                            onClick={() => setContentToRender('timeline')}
                            active={contentToRender === 'timeline'}
                        >
                            Histórico
                        </ButtonMenu>
                    </Flex>
                }
                extra={
                    contentToRender === 'notifications' && (
                        <Button
                            type="primary"
                            ghost
                            onClick={handleMarkAsRead}
                            disabled={notificationsIds.length === 0}
                        >
                            Limpiar notificaciones
                        </Button>
                    )
                }
            >
                {contentToRender === 'notifications' && (
                    <Notifications
                        data={notifications?.data ?? []}
                        loading={notifications.isLoading}
                        showDate
                        showCheckbox
                        showSelectAll
                        handleCheckboxChange={handleCheckboxChange}
                        setNotificationsIds={setNotificationsIds}
                        notificationsIds={notificationsIds}
                    />
                )}
                {/* {contentToRender === 'timeline' && (
                    <TimeLine data={logs?.data ?? []} loading={logs.isLoading} withoutBorders />
                )} */}
            </CardStyled>
        </Container>
    );
};

export default NotificationsPage;
