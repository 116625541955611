import JsonView from '@uiw/react-json-view';
import { lightTheme } from '@uiw/react-json-view/light';

export default ({ json = {} }) => {
    return (
        <JsonView
            value={json}
            style={lightTheme}
            enableClipboard={true}
            displayDataTypes={false}
            shortenTextAfterLength={0}
            indentWidth={24}
        />
    );
};
