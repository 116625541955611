import styled from 'styled-components';

export const NotificationWrap = styled.div`
    @keyframes fadeIn {
        from {
            opacity: 1;
            transform: translate3d(0, -100%, 0);
        }
        to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }

    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: transparent;
    /* filter: drop-shadow(0px 4px 2px rgba(69, 69, 69, 0.05));
    border: 1px solid #e4e8ec; */
    position: absolute;
    right: 40px;
    top: 10px;
    z-index: 111;
    justify-content: center;
    animation: fadeIn 0.2s ease-in;
    display: flex;
    /* display: ${(props) => (props.$show ? 'flex' : 'none')}; */
    cursor: pointer;
    svg {
        place-self: center;
        width: 28px;
        height: 28px;
        color: #2d3d76;
    }
`;

export const NotificationsBox = styled.div`
    width: 457px;
    border: 1px solid #dce5ec;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 6px;
    background: white;
    position: absolute;
    right: 0px;
    top: 66px;
    opacity: 1;
    display: ${(props) => (props.$open ? 'flex' : 'none')};
    flex-direction: column;
`;

export const NotificationsHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
    color: #2d3d76;
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset;

    b {
        font-weight: 600;
    }
    svg {
        width: 16px;
        height: 16px;
    }
`;

export const NotificationsContent = styled.div`
    height: 100%;
    max-height: 700px;
    background-color: white;
    padding: 1rem;
`;
