import { Form, Input, Select } from 'antd';
import Flex from 'components/Utils/Flex';
import FormStyled from 'components/Utils/Form';
import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPackage, resetCreatePackageState } from 'redux/features/Packages/createPackage';
import { useGetPackageTypesQuery } from 'redux/features/PackageTypes';

const OpenPackageForm = ({ form, order, fulfillment }) => {
    const dispatch = useDispatch();
    const [selectedPackageType, setSelectedPackageType] = useState(null);
    const [, , preparationPreferences] = useGetPreparationPreference();
    const { data, success, error } = useSelector((state) => state.createPackage);

    const packageTypes = useGetPackageTypesQuery();

    // const handlePrintNewPackage = async () => {};

    useEffect(() => {
        return () => {
            setSelectedPackageType(null);
            form.resetFields();
        };
    }, []);

    useEffect(() => {
        if (success && data._id) {
            if (preparationPreferences?.preparations_preferences?.intern_control) {
                console.log('');
            }
            dispatch(resetCreatePackageState());
        }
    }, [success, data]);

    const handleSubmit = (values) => {
        dispatch(
            createPackage({ order_id: order._id, fulfillment_id: fulfillment._id, ...values })
        );
    };

    const selectedPackage = useMemo(
        () => packageTypes?.data?.find((pkg) => pkg._id === selectedPackageType),
        [selectedPackageType]
    );

    const manualPackageCode =
        preparationPreferences?.preparations_preferences?.manual_package_code ?? true;

    const codeAlreadyUsed = error?.payload === 'CodeAlreadyUsed' ?? false;

    return (
        <FormStyled
            form={form}
            name="open_package_form"
            layout="vertical"
            onFinish={handleSubmit}
            loading={packageTypes.isLoading}
        >
            <Form.Item
                name="type"
                label="Tipo de bulto"
                rules={[
                    {
                        required: true,
                        message: 'Este campo es requerido',
                    },
                ]}
            >
                <Select
                    placeholder="Seleccione tipo de bulto"
                    onChange={(v) => setSelectedPackageType(v)}
                    loading={packageTypes.isLoading}
                >
                    {packageTypes?.data
                        ?.filter((pkg) =>
                            pkg.delivery_methods.includes(fulfillment.delivery_method)
                        )
                        ?.map((item) => (
                            <Select.Option key={item._id} value={item._id}>
                                {item.name}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>
            {manualPackageCode && (
                <Form.Item
                    name="code"
                    label="Código de bulto manual"
                    rules={[
                        {
                            required: true,
                            message: 'Este campo es requerido',
                        },
                    ]}
                    {...(codeAlreadyUsed && {
                        help: 'Este código ya existe, favor ingrese un código distinto',
                        // hasFeedback: true,
                        validateStatus: 'error',
                    })}
                >
                    <Input placeholder="Ingrese código de bulto" />
                </Form.Item>
            )}

            {selectedPackageType && (
                <blockquote>
                    <b>Detalles del tipo de bulto:</b>
                    <br />
                    <br />
                    <Flex direction="column">
                        <span>
                            <b>Alto:</b> {selectedPackage?.sizes?.height}{' '}
                            {selectedPackage?.sizes?.metric_unit}
                        </span>
                        <span>
                            <b>Largo:</b> {selectedPackage?.sizes?.length}{' '}
                            {selectedPackage?.sizes?.metric_unit}{' '}
                        </span>
                        <span>
                            <b>Ancho:</b> {selectedPackage?.sizes?.width}{' '}
                            {selectedPackage?.sizes?.metric_unit}{' '}
                        </span>
                        <span>
                            <b>Capacidad:</b> {selectedPackage?.sizes?.capacity}{' '}
                            {selectedPackage?.sizes?.measurement_unit}
                        </span>
                    </Flex>
                </blockquote>
            )}
        </FormStyled>
    );
};

export default OpenPackageForm;
