import { DeleteOutlined } from '@ant-design/icons';
import { Input, Form } from 'antd';
import { useState } from 'react';

const Comment = ({ form }) => {
    const [showCommentInput, setShowCommentInput] = useState(false);
    return (
        <div className="mt-1">
            <div className="w-full flex items-center justify-between mb-1">
                <span
                    className="underline cursor-pointer"
                    style={{ color: '#077CDB', fontSize: '12px' }}
                    onClick={() => {
                        if (!showCommentInput) {
                            setShowCommentInput(true);
                        }
                    }}
                >
                    Agregar comentario
                </span>
                {showCommentInput && (
                    <DeleteOutlined
                        className="cursor-pointer"
                        style={{ color: '#1F6373', fontSize: '17px' }}
                        onClick={() => {
                            if (showCommentInput) {
                                setShowCommentInput(false);
                                form.setFieldsValue({ comment: '' });
                            }
                        }}
                    />
                )}
            </div>
            {showCommentInput && (
                <Form.Item name={'comment'} initialValue={''} rules={[{ required: false }]}>
                    <Input.TextArea />
                </Form.Item>
            )}
        </div>
    );
};

export default Comment;
