import { Checkbox } from 'antd';
import { ContainerProblemOrderDetailsDrawer, Description, Label } from './styled';
import { useState } from 'react';

export const ProblemOrderDetailsDrawer = ({ itemLength, nameOrder, totalPrice, id }) => {
    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const formattedPrice = totalPrice?.toLocaleString('es-CL', {
        style: 'currency',
        currency: 'CLP',
    });

    return (
        <ContainerProblemOrderDetailsDrawer className="flex no-wrap justify-between py-5 px-2 w-full mb-2 mt-2">
            <div className="flex items-start">
                {id && <Checkbox value={id} onChange={handleCheckboxChange} checked={isChecked} />}
                <div className="flex flex-col ml-3">
                    <Label fontSize="14px">Pedido {nameOrder}</Label>
                    <Description>Cantidad de productos: {itemLength}</Description>
                </div>
            </div>
            <div className="ml-auto">
                <Description fontWeight={600}>{formattedPrice}</Description>
            </div>
        </ContainerProblemOrderDetailsDrawer>
    );
};
