import axios from 'axios';
import { createContext, useEffect, useState } from 'react';

export const CountryDirectionContext = createContext({
    countries: [],
    getCountryInfo: () => null,
});

export const CountryDirectionProvider = ({ children }) => {
    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const [chile, peru, colombia] = await Promise.all(
                    ['chile', 'peru', 'colombia'].map((v) =>
                        axios.get(
                            `https://andes-commerce-cloud-prod-assets.s3.us-east-1.amazonaws.com/directions/${v}.json`
                        )
                    )
                );

                setCountries([
                    {
                        id: 'chile',
                        name: 'Chile',
                        code: 'CL',
                        province: chile?.data ?? [],
                    },
                    {
                        id: 'peru',
                        name: 'Peru',
                        code: 'PE',
                        province: peru?.data ?? [],
                    },
                    {
                        id: 'colombia',
                        name: 'Colombia',
                        code: 'CO',
                        province: colombia?.data ?? [],
                    },
                ]);
            } catch (error) {
                console.log('Error fetching countries:', error);
            }
        };

        fetchCountries();
    }, []);

    const getCountryInfo = (country, province, municipality) => {
        const countryInfo = countries.find(
            (c) => c.id === country || c.name === country || c.code === country
        );

        const provinces = countryInfo?.province ?? [];

        if (!province) return provinces;

        const municipalities =
            provinces.find((p) => p.id === province || p.name === province)?.municipalities ?? [];

        if (!municipality) return municipalities;

        return (
            municipalities.find((m) => m.id === municipality || m.name === municipality)
                ?.districts ?? []
        );
    };

    return (
        <CountryDirectionContext.Provider value={{ countries, getCountryInfo }}>
            {children}
        </CountryDirectionContext.Provider>
    );
};
