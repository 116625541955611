import { isObjectEmpty } from 'helpers/filterObjectByCustom';

const Tag = ({
    style,
    color,
    children,
    closable,
    onClose,
    onClick,
    borderColor,
    className,
    activeHover,
}) => {
    const tag_classes = {
        success: 'bg-green-600 text-gray-100',
        warning: 'bg-yellow-300 text-black',
        danger: 'bg-red-500 text-gray-100',
        archived: 'bg-gray-100 text-gray-800',
        progress: 'bg-purple-500 text-gray-100',
        uncritical: 'bg-uncritical_bg text-uncritical_text',
        critical_risk: 'bg-critical_risk_bg text-critical_risk_text',
        critical: 'bg-critical_bg text-critical_text',
        paused_or_anulated: 'bg-paused_or_anulated_bg text-paused_or_anulated_text',
        info: 'tag__info',
    };

    const getBadgeColor = (color) => {
        return tag_classes[color] || 'bg-gray-100 text-gray-800';
    };

    return (
        <span
            {...(onClick ? { role: 'button', onClick } : {})}
            className={`text-center bg-ghun inline-flex items-center px-2.5 py-0.5 mr-2 rounded-full text-xs font-medium ${getBadgeColor(
                color
            )} ${className} ${activeHover ? 'tag-activate' : ''} `}
            {...(!isObjectEmpty(style) ? { style } : {})}
            {...(borderColor ? { style: { border: `1px solid ${borderColor}` } } : {})}
        >
            {children}
            {closable && (
                <button
                    onClick={onClose}
                    type="button"
                    className=" flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                >
                    <span className="sr-only">Remove small option</span>
                    <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                        <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                    </svg>
                </button>
            )}
        </span>
    );
};

export default Tag;
