import { Button, Checkbox, DatePicker, Form, Select, Tooltip } from 'antd';
import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import FormStyled from 'components/Utils/Form';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useGetUserPreferencesQuery, useUpdateUserPreferencesMutation } from 'redux/features/Users';
import { SaveButtonWrapper, SpeakerButtonWrapper, SelectSoundWrapper } from './styles';
import { ReactComponent as SpeakerIcon } from 'assets/speaker.svg';

const formatRangeDate = (dates) => {
    return [moment(dates[0], 'YYYY-MM-DD'), moment(dates[1], 'YYYY-MM-DD')];
};

const SettingsAndPreferencesPage = () => {
    const [form] = Form.useForm();
    const [enableAlertsNotificationSound, setEnableAlertsNotificationSound] = useState(false);
    const [someFieldUpdated, setSomeFieldUpdated] = useState(false);
    const [afterSetFields, setAfterSetFields] = useState(false);
    const { data = [], isLoading } = useGetUserPreferencesQuery();
    const [updateUserPreferences] = useUpdateUserPreferencesMutation();
    const defaultDateOrdersFilterWatch = Form.useWatch('default_date_orders_filter', form);
    const soundToPlayWatch = Form.useWatch('alerts_notification_sound', form);

    const [playPopSound, setPlayPopSound] = useState(null);
    const [playBubblePopSound, setPlayBubblePopSound] = useState(null);
    const [playCartoonSound, setPlayCartoonSound] = useState(null);
    const [playUiPositiveSound, setPlayUiPositiveSound] = useState(null);
    const [playing, setPlaying] = useState(false);

    useEffect(() => {
        setPlayPopSound(
            new Audio('https://andes-commerce-cloud-prod-assets.s3.amazonaws.com/sounds/pop1.mp3')
        );
        setPlayBubblePopSound(
            new Audio(
                'https://andes-commerce-cloud-prod-assets.s3.amazonaws.com/sounds/bubble-pop.mp3'
            )
        );
        setPlayCartoonSound(
            new Audio(
                'https://andes-commerce-cloud-prod-assets.s3.amazonaws.com/sounds/cartoon.mp3'
            )
        );
        setPlayUiPositiveSound(
            new Audio(
                'https://andes-commerce-cloud-prod-assets.s3.amazonaws.com/sounds/ui-positive.mp3'
            )
        );
    }, []);

    useEffect(() => {
        if (!playPopSound || !playBubblePopSound || !playCartoonSound || !playUiPositiveSound) {
            return;
        }

        playPopSound.pause();
        playBubblePopSound.pause();
        playCartoonSound.pause();
        playUiPositiveSound.pause();

        if (soundToPlayWatch === 'default') {
            playing ? playPopSound.play() : playPopSound.pause();
        } else if (soundToPlayWatch === 'bubble_pop') {
            playing ? playBubblePopSound.play() : playBubblePopSound.pause();
        } else if (soundToPlayWatch === 'cartoon') {
            playing ? playCartoonSound.play() : playCartoonSound.pause();
        } else if (soundToPlayWatch === 'ui_positive') {
            playing ? playUiPositiveSound.play() : playUiPositiveSound.pause();
        }
    }, [playing, playPopSound, playBubblePopSound, playCartoonSound, playUiPositiveSound]);

    useEffect(() => {
        if (!playPopSound || !playBubblePopSound || !playCartoonSound || !playUiPositiveSound) {
            return;
        }

        playPopSound.addEventListener('ended', () => setPlaying(false));
        playBubblePopSound.addEventListener('ended', () => setPlaying(false));
        playCartoonSound.addEventListener('ended', () => setPlaying(false));
        playUiPositiveSound.addEventListener('ended', () => setPlaying(false));
        return () => {
            playPopSound.removeEventListener('ended', () => setPlaying(false));
            playBubblePopSound.removeEventListener('ended', () => setPlaying(false));
            playCartoonSound.removeEventListener('ended', () => setPlaying(false));
            playUiPositiveSound.removeEventListener('ended', () => setPlaying(false));
        };
    }, [playPopSound, playBubblePopSound, playCartoonSound, playUiPositiveSound]);

    const playSoundHandler = () => setPlaying(!playing);

    const someFieldUpdatedOnChange = () => {
        if (afterSetFields) {
            setSomeFieldUpdated(true);
        }
    };

    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                _id: data?._id,
                default_date_orders_filter: data?.default_date_orders_filter ?? 'last_7_days',
                ...(data?.default_date_orders_filter_custom?.[0] &&
                    data?.default_date_orders_filter_custom?.[1] && {
                        default_date_orders_filter_custom: formatRangeDate(
                            data?.default_date_orders_filter_custom
                        ),
                    }),
                enable_alerts_notification_sound: data?.enable_alerts_notification_sound ?? false,
                alerts_notification_sound: data?.alerts_notification_sound ?? undefined,
                oms_appearance: data?.oms_appearance ?? 'light',
            });

            setEnableAlertsNotificationSound(data?.enable_alerts_notification_sound ?? false);

            setAfterSetFields(true);
        }
    }, [data]);

    const handleSubmit = async (values) => {
        const res = await updateUserPreferences({
            ...values,
        });

        if (!values?._id && res?._id) {
            form.setFieldsValue({
                _id: res._id,
            });
        }

        const userDataLocalStorage = JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;

        const ordersConfiguration = {
            ...userDataLocalStorage?.orders_configuration,
        };

        const newUserData = {
            ...userDataLocalStorage,
            ...(values?._id &&
                res?._id && {
                    _id: res._id,
                }),
            enable_alerts_notification_sound: values?.enable_alerts_notification_sound ?? false,
            alerts_notification_sound: values?.alerts_notification_sound ?? 'default',
            oms_appearance: values?.oms_appearance ?? 'light',
            orders_configuration: {
                ...ordersConfiguration,
                ...(values?.default_date_orders_filter && {
                    default_date_orders_filter: values.default_date_orders_filter,
                }),
                ...(values?.default_date_orders_filter_custom && {
                    default_date_orders_filter_custom: values.default_date_orders_filter_custom,
                }),
                enable_alerts_notification_sound: values?.enable_alerts_notification_sound ?? false,
                alerts_notification_sound: values?.alerts_notification_sound ?? 'default',
                oms_appearance: values?.oms_appearance ?? 'light',
            },
        };

        localStorage.setItem('userLogged', JSON.stringify(newUserData));
    };

    return (
        <Container extraTitle="Ajustes y preferencias" backgroundColor="#fff">
            <PageHeader title="Ajustes y preferencias" withMarginBottom={false} />

            <FormStyled
                form={form}
                name={'settings-and-preferences-form'}
                layout="vertical"
                onFinish={handleSubmit}
            >
                <h4>
                    <b>Personalización</b>
                </h4>

                <Form.Item name="_id" style={{ display: 'none' }}></Form.Item>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <p
                        style={{
                            marginTop: '1rem',
                        }}
                    >
                        Filtro de fecha por defecto{' '}
                    </p>
                    <Tooltip
                        overlayStyle={{ maxWidth: '400px' }}
                        title={
                            <ul>
                                La configuración de esta fecha será considerada para filtrar pedidos
                                en todos los módulos y afectará únicamente el usuario actual
                            </ul>
                        }
                    >
                        <AiOutlineExclamationCircle
                            color="rgb(83, 109, 143)"
                            style={{
                                width: '19px',
                                height: '19px',
                                cursor: 'pointer',
                                marginTop: '14px',
                                marginLeft: '3px',
                            }}
                        />
                    </Tooltip>
                </div>
                <Form.Item
                    name="default_date_orders_filter"
                    style={{
                        maxWidth: '25rem',
                        marginTop: '1rem',
                    }}
                    rules={[
                        {
                            required: true,
                            message: 'Campo requerido',
                        },
                    ]}
                    initialValue="last_7_days"
                >
                    <Select onChange={someFieldUpdatedOnChange}>
                        <Select.Option value="today">Hoy</Select.Option>
                        <Select.Option value="last_24_hours">Últimas 24 horas</Select.Option>
                        <Select.Option value="last_7_days">Últimos 7 días</Select.Option>
                        <Select.Option value="current_month">Mes actual</Select.Option>
                        <Select.Option value="last_month">Mes anterior</Select.Option>
                        <Select.Option value="last_three_month">Últimos 3 meses</Select.Option>
                        <Select.Option value="custom">Personalizado</Select.Option>
                    </Select>
                </Form.Item>

                {defaultDateOrdersFilterWatch === 'custom' && (
                    <Form.Item
                        name="default_date_orders_filter_custom"
                        label="Rango de fechas personalizado"
                        style={{
                            maxWidth: '25rem',
                            marginTop: '1rem',
                        }}
                        rules={[
                            {
                                required: true,
                                message: 'Campo requerido',
                            },
                        ]}
                        initialValue={[]}
                    >
                        <DatePicker.RangePicker
                            onChange={someFieldUpdatedOnChange}
                            format="DD-MM-YYYY"
                            placeholder={['Inicio', 'Fin']}
                        />
                    </Form.Item>
                )}

                <Form.Item
                    name={'enable_alerts_notification_sound'}
                    valuePropName="checked"
                    style={{
                        marginTop: '2rem',
                    }}
                >
                    <Checkbox
                        checked={enableAlertsNotificationSound}
                        onChange={(e) => {
                            setEnableAlertsNotificationSound(e.target.checked);
                            someFieldUpdatedOnChange();
                        }}
                    >
                        <span>Habilitar sonido de notificación para alertas en OMS</span>
                    </Checkbox>
                </Form.Item>

                <SelectSoundWrapper disabled={!enableAlertsNotificationSound}>
                    <Form.Item
                        name={'alerts_notification_sound'}
                        style={{
                            width: '25rem',
                            marginTop: '1rem',
                            marginRight: '1rem',
                        }}
                        disabled={!enableAlertsNotificationSound}
                        rules={[
                            {
                                required: enableAlertsNotificationSound,
                                message: 'Campo requerido',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Seleccionar opción"
                            disabled={!enableAlertsNotificationSound}
                            onChange={someFieldUpdatedOnChange}
                        >
                            <Select.Option value="default">Pop (Por defecto)</Select.Option>
                            <Select.Option value="bubble_pop">Bubble Pop</Select.Option>
                            <Select.Option value="cartoon">Cartoon</Select.Option>
                            <Select.Option value="ui_positive">Ui Positive</Select.Option>
                        </Select>
                    </Form.Item>

                    <SpeakerButtonWrapper
                        disabled={!enableAlertsNotificationSound || !soundToPlayWatch || playing}
                    >
                        <Button
                            type="secondary"
                            disabled={
                                !enableAlertsNotificationSound || !soundToPlayWatch || playing
                            }
                            onClick={() => {
                                playSoundHandler();
                            }}
                        >
                            <SpeakerIcon />
                        </Button>
                    </SpeakerButtonWrapper>
                </SelectSoundWrapper>

                {/*                 

                <h4
                    style={{
                        marginTop: '3rem',
                    }}
                >
                    <b>Apariencia OMS</b>
                </h4>

                <p
                    style={{
                        marginTop: '1rem',
                        marginBottom: '0.8rem',
                    }}
                >
                    Elige entre <b>light mode</b> (Color por defecto) o <b>dark mode</b> (colores
                    oscuros)
                </p>
                <Form.Item
                    style={{ margin: '0 0 14px' }}
                    name="oms_appearance"
                    valuePropName="value"
                    className="label-style"
                    initialValue="light" // Set the initial value to "light"
                >
                    <Radio.Group name="oms_appearance_radio" onChange={someFieldUpdatedOnChange}>
                        <Space direction="vertical">
                            <Radio value="light">
                                <span
                                    className="text-s"
                                    style={{
                                        color: '#142C4A',
                                        fontWeight: 400,
                                    }}
                                >
                                    Light mode
                                </span>
                            </Radio>
                            <Radio value="dark">
                                <span
                                    className="text-s"
                                    style={{
                                        color: '#142C4A',
                                        fontWeight: 400,
                                    }}
                                >
                                    Dark mode
                                </span>
                            </Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item> */}

                <SaveButtonWrapper>
                    <Button
                        disabled={isLoading || !someFieldUpdated}
                        className="justify-end"
                        onClick={form.submit}
                        type="primary"
                    >
                        Guardar cambios
                    </Button>
                </SaveButtonWrapper>
            </FormStyled>
        </Container>
    );
};

export default SettingsAndPreferencesPage;
