export const dateRangeOptions = [
    { label: 'Hoy', value: 'today' },
    { label: 'Últimas 24 horas', value: 'last_24_hours' },
    { label: 'Últimos 7 días', value: 'last_7_days' },
    { label: 'Mes actual', value: 'current_month' },
    { label: 'Mes anterior', value: 'last_month' },
    { label: 'Últimos 3 meses', value: 'last_three_month' },
    { label: 'Rango de fecha', value: 'custom' },
];

export const range = (start, end, step = 1) => {
    const output = [];
    if (typeof end === 'undefined') {
        end = start;
        start = 0;
    }
    for (let i = start; i < end; i += step) {
        output.push(i);
    }
    return output;
};

export const getYear = (date) => {
    const d = new Date(date);
    return d.getFullYear();
};

export const getMonth = (date) => {
    const d = new Date(date);
    return d.getMonth();
};

export const years = range(2020, getYear(new Date()) + 1, 1);
export const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];
