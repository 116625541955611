import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getStoresByDeliveryType = createAsyncThunk(
    'changeStoreGAOD/findLocationsWithStock',
    async (data, state) => {
        try {
            const response = await AndesClient.post(`stock/findLocationsWithStock`, data);
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const initialStateChangeStoreGAOD = {
    is_can_continue: false,
    current_step: 0,
    delivery_type: '',
    store_selected: {
        _id: '',
    },
    delivery_type_store_selected: '',
    is_shipping_cost_paid_by_customer: '',
    stores_by_delivery_type: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    data_verification_form: {},
    shipping_service: {},
};

const changeStoreGAOD = createSlice({
    name: 'changeStoreGAOD',
    initialState: initialStateChangeStoreGAOD,
    reducers: {
        changeGlobalChangeStoreGAODData: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        changeGlobalChangeStoreGAODField: (state, { payload }) => {
            return {
                ...state,
                [payload[0]]: payload[1],
            };
        },
        reInitGlobalChangeStoreGAODData: (state) => {
            return {
                ...state,
                ...initialStateChangeStoreGAOD,
            };
        },
    },
    extraReducers: {
        [getStoresByDeliveryType.pending]: (state) => {
            return {
                ...state,
                stores_by_delivery_type: {
                    ...state.stores_by_delivery_type,
                    loading: true,
                    success: false,
                },
            };
        },
        [getStoresByDeliveryType.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                stores_by_delivery_type: {
                    ...state.stores_by_delivery_type,
                    loading: false,
                    data: payload,
                    error: null,
                    success: true,
                },
            };
        },
        [getStoresByDeliveryType.rejected]: (state, { payload }) => {
            return {
                ...state,
                stores_by_delivery_type: {
                    ...state.stores_by_delivery_type,
                    loading: false,
                    error: payload,
                    success: false,
                },
            };
        },
    },
});
export const {
    changeGlobalChangeStoreGAODData,
    changeGlobalChangeStoreGAODField,
    reInitGlobalChangeStoreGAODData,
} = changeStoreGAOD.actions;
export default changeStoreGAOD.reducer;
