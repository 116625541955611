import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import { getAllCouriers } from './getAllCouriers';
import toast from 'react-hot-toast';

export const deleteCourier = createAsyncThunk('courier/delete', async (courierToDelete, state) => {
    try {
        const deleteCourierForID = async (id) => {
            const res = await AndesClient.delete(`/settings/${id}`);
            if (res.status === 200) {
                state.dispatch(getAllCouriers());
                toast.success('Courier/s eliminado/s con éxito.');
            }
            return res.data;
        };

        if (typeof courierToDelete === 'string') {
            await deleteCourierForID(courierToDelete);
        }

        if (Array.isArray(courierToDelete)) {
            for await (const id of courierToDelete) {
                await deleteCourierForID(id);
            }
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const deleteCourierSlice = createSlice({
    name: 'deleteCourier',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [deleteCourier.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [deleteCourier.fulfilled]: (state) => {
            return {
                ...state,
                success: true,
                loading: false,
            };
        },
        [deleteCourier.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default deleteCourierSlice.reducer;
