import printJS from 'print-js';
import toast from 'react-hot-toast';
import { useMergePdfsMutation } from 'redux/features/Orders';

const usePrintFulfillmentsDocuments = () => {
    const [mergePdfs] = useMergePdfsMutation();

    const handlePrintDocuments = async (data = [], selectedRows = []) => {
        const fulfillments = data.filter((s) => selectedRows.includes(s?._id));
        const documents =
            Array.from(fulfillments ?? []).reduce((acc, ele) => {
                const document =
                    Array.from(ele?.order?.documents ?? []).find((doc) =>
                        ['DTE_TICKET', 'DTE_INVOICE'].includes(doc?.type)
                    ) ?? {};
                if (document.url) {
                    acc.push(document.url);
                }
                return acc;
            }, []) ?? [];

        if (documents?.length === 0) {
            toast.error('No se han encontrado boletas en los pedidos seleccionados.');
            return;
        }

        const file = await mergePdfs(documents);
        if (file.data) {
            printJS(file.data);
        }
    };

    return handlePrintDocuments;
};

export default usePrintFulfillmentsDocuments;
