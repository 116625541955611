export const getItemQuantityInPackages = (packages = [], item_id) => {
    const quantity = packages.reduce((acc, pack) => {
        const { line_items = [] } = pack;
        const item = line_items.find((item) => item.line_item._id === item_id);
        if (item) {
            return acc + item.quantity;
        }
        return acc;
    }, 0);
    return quantity;
};

export const calculatePickingItems = (fulfillment = {}) => {
    const { line_items = [], packages = [] } = fulfillment;
    const lineItems = line_items.reduce((acc, item) => {
        const quantity = getItemQuantityInPackages(packages, item.line_item._id);
        const existingItem = acc.find((item2) => item2.line_item._id === item.line_item._id);

        if (existingItem) {
            existingItem.quantity = quantity;
            return acc;
        }

        if (quantity > 0) {
            const pickingItem = {
                ...item,
                quantity,
            };
            acc.push(pickingItem);
        }

        return acc;
    }, []);
    return lineItems;
};

export const calculateNotPickedItems = (fulfillment = {}) => {
    const { line_items = [], packages = [] } = fulfillment;
    const lineItems = line_items.reduce((acc, item) => {
        const pickedQuantity = getItemQuantityInPackages(packages, item.line_item._id);

        const notPickedQuantity = item.quantity - pickedQuantity;

        if (notPickedQuantity > 0) {
            const pickingItem = {
                ...item,
                quantity: notPickedQuantity,
            };
            acc.push(pickingItem);
        }

        return acc;
    }, []);
    return lineItems;
};

export const calculateIfAllItemsPicked = (fulfillment = {}) => {
    const { line_items = [], packages = [] } = fulfillment;
    const lineItems = line_items.reduce((acc, item) => {
        const pickedQuantity = getItemQuantityInPackages(packages, item?.line_item?._id);

        const notPickedQuantity =
            (item?.line_item?.quantity ?? 0) -
            pickedQuantity -
            (item?.line_item?.removed_quantity ?? 0) -
            (item?.line_item?.refunded_quantity ?? 0);

        if (notPickedQuantity > 0) {
            acc.push(item);
        }

        return acc;
    }, []);
    return lineItems.length === 0;
};
