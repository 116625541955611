import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import qs from 'qs';
import toast from 'react-hot-toast';
import { toggleModal } from '../Utils';
import getFromEnv from 'helpers/getFromEnv';
import pako from 'pako';

const batchDeliverApis = {
    dev: 'https://biztks6v2metb5mztwdwva7qvy0jmtwk.lambda-url.us-east-1.on.aws/',
    demo: 'https://mo2xvrizbnhs2za3qrbftfolei0hwnjl.lambda-url.us-east-1.on.aws',
    prod: 'https://4hvcm4s7eij63ucwlwx7yc67iy0icspk.lambda-url.us-east-1.on.aws',
};

export const fulfillmentsApi = createApi({
    reducerPath: 'fulfillments',
    baseQuery,
    tagTypes: [
        'fulfillmentsToPreparation',
        'pendingArrivalFulfillment',
        'fulfillmentsToDelivery',
        'oneFulfillment',
        'fulfillmentList',
    ],
    keepUnusedDataFor: 30,
    endpoints: (builder) => ({
        getOneFulfillment: builder.query({
            query: (id) => {
                return {
                    url: `/fulfillments/${id}`,
                };
            },
            providesTags: ['oneFulfillment'],
            transformResponse: async (response, meta) => {
                const contentEncoding = meta?.response?.headers.get('Content-Encoding');

                if (contentEncoding === 'gzip') {
                    const arrayBuffer = await response.arrayBuffer();
                    const decompressedData = pako.ungzip(new Uint8Array(arrayBuffer), {
                        to: 'string',
                    });

                    return JSON.parse(decompressedData);
                }

                return response;
            },
        }),
        getInProgressFulfillmentsAllIds: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.preparationsFilters;
                let filters = args ? args : actuallyFilters;
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );
                const querystring = qs.stringify(clearedFilters);
                return fetchWithBQ(`deliveries/fulfillments/v2/search/ids?${querystring}`);
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.docs.map(({ _id }) => ({
                              type: 'fulfillmentsToPreparation',
                              id: _id,
                          })),
                          {
                              type: 'fulfillmentsToPreparation',
                              id: 'LIST',
                          },
                      ]
                    : [{ type: 'fulfillmentsToPreparation', id: 'LIST' }];
            },
        }),
        getInProgressFulfillments: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.preparationsFilters;
                let filters = args ? args : actuallyFilters;
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );
                const querystring = qs.stringify(clearedFilters);
                return fetchWithBQ(`deliveries/fulfillments/v2/search?${querystring}`);
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.docs.map(({ _id }) => ({
                              type: 'fulfillmentsToPreparation',
                              id: _id,
                          })),
                          {
                              type: 'fulfillmentsToPreparation',
                              id: 'LIST',
                          },
                      ]
                    : [{ type: 'fulfillmentsToPreparation', id: 'LIST' }];
            },
        }),
        getPendingArrivalFulfillments: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.pendingArrivalsFilters;
                let filters = args ? args : actuallyFilters;
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );
                const querystring = qs.stringify(clearedFilters);
                return fetchWithBQ(`deliveries/fulfillments/v2/search?${querystring}`);
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.docs.map(({ _id }) => ({
                              type: 'pendingArrivalFulfillment',
                              id: _id,
                          })),
                          {
                              type: 'pendingArrivalFulfillment',
                              id: 'LIST',
                          },
                      ]
                    : [{ type: 'pendingArrivalFulfillment', id: 'LIST' }];
            },
        }),
        getPendingArrivalFulfillmentsAllIds: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.pendingArrivalsFilters;
                let filters = args ? args : actuallyFilters;
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );
                const querystring = qs.stringify(clearedFilters);
                return fetchWithBQ(`deliveries/fulfillments/v2/search/ids?${querystring}`);
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.docs.map(({ _id }) => ({
                              type: 'pendingArrivalFulfillment',
                              id: _id,
                          })),
                          {
                              type: 'pendingArrivalFulfillment',
                              id: 'LIST',
                          },
                      ]
                    : [{ type: 'pendingArrivalFulfillment', id: 'LIST' }];
            },
        }),
        getSuccessFulfillments: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.deliveriesFilters;
                let filters = args ? args : actuallyFilters;
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );
                const querystring = qs.stringify(clearedFilters);
                return fetchWithBQ(`deliveries/fulfillments/v2/search?${querystring}`);
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.docs.map((row) => ({
                              type: 'fulfillmentsToDelivery',
                              id: row._id,
                          })),
                          {
                              type: 'fulfillmentsToDelivery',
                              id: 'LIST',
                          },
                      ]
                    : [{ type: 'fulfillmentsToDelivery', id: 'LIST' }];
            },
        }),
        getSuccessFulfillmentsAllIds: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                const actuallyFilters = api.getState().utils.deliveriesFilters;
                let filters = args ? args : actuallyFilters;
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );
                const querystring = qs.stringify(clearedFilters);
                return fetchWithBQ(`deliveries/fulfillments/v2/search/ids?${querystring}`);
            },
            providesTags: (result) => {
                return result
                    ? [
                          ...result.docs.map((row) => ({
                              type: 'fulfillmentsToDelivery',
                              id: row._id,
                          })),
                          {
                              type: 'fulfillmentsToDelivery',
                              id: 'LIST',
                          },
                      ]
                    : [{ type: 'fulfillmentsToDelivery', id: 'LIST' }];
            },
        }),
        getUsersPerLocation: builder.query({
            queryFn: (args, api, extraOptions, fetchWithBQ) => {
                let filters = args ? args : {};
                const clearedFilters = Object.entries(filters).reduce(
                    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
                    {}
                );
                const querystring = qs.stringify(clearedFilters);
                return fetchWithBQ(`fulfillments/usersPerLocation?${querystring}`);
            },
        }),
        changeFulfillmentsCouriers: builder.mutation({
            query: (body) => ({
                url: '/fulfillments/change/courier',
                method: 'PUT',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando fulfillments...</b>,
                    success: <b>Fulfillments actualizados con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['fulfillmentsToDelivery', 'fulfillmentsToPreparation'],
        }),
        getShippingPrice: builder.mutation({
            query: (body) => ({
                url: '/fulfillments/shipping-price',
                method: 'POST',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(
                    api.queryFulfilled,
                    {},
                    {
                        success: {
                            style: {
                                display: 'none',
                            },
                        },
                        error: {
                            style: {
                                display: 'none',
                            },
                        },
                    }
                );
            },
        }),
        retryShippingCreation: builder.mutation({
            method: 'post',
            query: (fulfillment_id) => ({
                method: 'post',
                url: `/fulfillments/retryShippingCreation/${fulfillment_id}`,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando envío...</b>,
                    success: <b>Envío creado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['fulfillmentsToDelivery', 'fulfillmentsToPreparation'],
        }),
        updateAdressOrCourier: builder.mutation({
            method: 'PUT',
            query: (data) => {
                return {
                    method: 'put',
                    url: `/fulfillments/orders/${data?._id}/fulfillments/${data?.elementId}`,
                    body: data,
                };
            },
            // onQueryStarted: (data, api) => {
            //     toast.promise(api.queryFulfilled, {
            //         loading: <b>Creando envío...</b>,
            //         success: <b>Envío creado con éxito</b>,
            //         error: <b>Ocurrió un error.</b>,
            //     });
            // },
            invalidatesTags: ['fulfillmentsToDelivery', 'Order'],
        }),
        retryShippingCreationMassiveTags: builder.mutation({
            method: 'post',
            query: (data) => ({
                method: 'post',
                url: `/fulfillments/retryShippingCreationMassiveTags`,
                body: data,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando envíos...</b>,
                    success: ({ data }) => {
                        return <b>{data?.message}</b>;
                    },
                    error: ({ error }) => <b>{error?.data?.message ?? 'Ocurrió un error'}.</b>,
                });
            },
            invalidatesTags: ['fulfillmentsToDelivery', 'fulfillmentsToPreparation'],
        }),
        getFulfillmentScannedStatus: builder.mutation({
            method: 'post',
            // eslint-disable-next-line no-unused-vars
            query: ({ withoutToast: ___, ...data }) => {
                return {
                    method: 'post',
                    url: `/fulfillments/scanFulfillmentStatus`,
                    body: data,
                };
            },
            onQueryStarted: ({ withoutToast }, api) => {
                if (withoutToast) return;
                toast.promise(api.queryFulfilled, {
                    loading: <b>Obteniendo pedido(s)...</b>,
                    // success: 'Pedido(s) obtenido(s) con éxito',
                    success: (response) => {
                        const numOrders = response.data?.length || 0;
                        const message = ` Pedido${numOrders === 1 ? '' : 's'} obtenido${
                            numOrders === 1 ? '' : 's'
                        } con éxito`;
                        return <b>{message}</b>;
                    },

                    error: ({ error }) => <b>{error?.data?.message ?? 'Ocurrió un error'}.</b>,
                });
            },
        }),
        addToPackageV2: builder.mutation({
            query: (data) => {
                return {
                    url: '/packages/add',
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['oneFulfillment'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Añadiendo producto al bulto...</b>,
                    success: <b>Producto añadido con éxito</b>,
                    error: ({ error }) => <b>{error?.data?.message ?? 'Ocurrió un error'}.</b>,
                });
            },
        }),
        updateFulfillmentPromisedDate: builder.mutation({
            query: (data) => {
                return {
                    url: '/deliveries/fulfillments/v2/change-promise',
                    method: 'PUT',
                    body: data,
                };
            },
            // invalidatesTags: ['oneFulfillment'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando fecha de entrega</b>,
                    success: <b>Fecha de entrega actualizada con éxito</b>,
                    error: ({ error }) => <b>{error?.data?.message ?? 'Ocurrió un error'}.</b>,
                });
            },
        }),
        createPackageV2: builder.mutation({
            query: (data) => {
                return {
                    url: '/packages/create',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['oneFulfillment'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando bulto..</b>,
                    success: () => {
                        api.dispatch(toggleModal('new_package_modal'));
                        return <b>Bulto creado exitosamente</b>;
                    },
                    error: () => {
                        let errorMessage = 'Ocurrió un error.';
                        return errorMessage;
                    },
                });
            },
        }),
        completeFulfillmentV2: builder.mutation({
            query: (data) => {
                const { order_id, fulfillment_id } = data;
                if (!order_id || !fulfillment_id) {
                    throw new Error('No se pudo completar el fulfillment');
                }
                return {
                    url: `/fulfillments/orders/${order_id}/fulfillments/${fulfillment_id}/complete`,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: [
                'fulfillmentsToDelivery',
                'fulfillmentsToPreparation',
                'oneFulfillment',
            ],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Completando preparación...</b>,
                    success: 'Preparación completada con éxito',
                    error: ({ error }) => (
                        <b>
                            {error?.data?.message ??
                                'Ocurrió un error al completar la preparación.'}
                            .
                        </b>
                    ),
                });

                api.dispatch(toggleModal('send-to-deliveries-modal'));
            },
        }),
        deletePackageV2: builder.mutation({
            query: (data) => {
                return {
                    url: `/packages/delete`,
                    body: data,
                    method: 'delete',
                };
            },
            invalidatesTags: ['oneFulfillment'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Eliminando bulto...</b>,
                    success: <b>Bulto eliminado exitosamente</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        updatePackageV2: builder.mutation({
            query: (data) => {
                return {
                    url: '/packages/update',
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['oneFulfillment'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Cerrando bulto...</b>,
                    success: <b>El bulto se ha cerrado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        removeItemFromPackage: builder.mutation({
            query: (data) => {
                return {
                    url: '/packages/removeItem',
                    method: 'PUT',
                    body: data,
                };
            },
            invalidatesTags: ['oneFulfillment'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Quitando producto del bulto...</b>,
                    success: <b>Artículo eliminado exitosamente</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        CreateFulfillmentList: builder.mutation({
            query: (data) => {
                return {
                    url: '/fulfillments/createFulfillmentList',
                    method: 'POST',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando lista de preparación...</b>,
                    success: <b>Lista de preparación creada con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        CreateFulfillmentByLogistic: builder.mutation({
            query: (data) => {
                return {
                    url: `/fulfillments/orders/${data.order_id}/fulfillments`,
                    method: 'POST',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Enviando a preparación...</b>,
                    success: <b>Fulfillment creado con éxito</b>,
                    error: ({ error }) => <b>{error?.data?.message ?? 'Ocurrió un error.'}</b>,
                });
            },
        }),
        GetFulfillmentList: builder.query({
            query: (fulfillment_list_id) => {
                return {
                    url: `/fulfillments/getFulfillmentList/${fulfillment_list_id}`,
                    method: 'GET',
                };
            },
            providesTags: ['fulfillmentList'],
        }),
        RemoveFulfillmentFromList: builder.mutation({
            query: (data) => {
                return {
                    url: '/fulfillments/removeFulfillmentFromList',
                    method: 'POST',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Quitando preparación de la lista.</b>,
                    success: <b>Preparación removida de la lista con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['fulfillmentList'],
        }),
        SentFulfillmentsToDelivery: builder.mutation({
            query: (data) => {
                return {
                    url: '/fulfillments/sentFulfillmentsToDelivery',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['fulfillmentsToDelivery', 'fulfillmentsToPreparation'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Enviando preparaciones a entregas.</b>,
                    success: <b>Preparaciones enviadas a entregas con éxito.</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
        FulfillmentsBatchDelivery: builder.mutation({
            query: (data) => {
                return {
                    url:
                        batchDeliverApis[getFromEnv('REACT_APP_STAGE')] ??
                        '/fulfillments/batch/deliver',
                    timeout: 290000,
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['oneFulfillment', 'fulfillmentsToDelivery'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Procesando entrega de pedidos..</b>,
                    success: <b>Pedidos entregados con éxito</b>,
                    error: <b>Ocurrió un error al entregar el pedido.</b>,
                });
            },
        }),
        generateChangeTicket: builder.mutation({
            query: (data) => {
                return {
                    url: `/deliveries/fulfillments/v2/generate-change-ticket`,
                    method: 'POST',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Generando ticket de cambio...</b>,
                    success: <b>Ticket de cambio generado con éxito</b>,
                    error: <b>Ocurrió un error al generar el ticket de cambio</b>,
                });
            },
        }),
        GeneratePickingListPDF: builder.mutation({
            query: (data) => {
                return {
                    url: `/fulfillments/generatePickingListPdf`,
                    method: 'POST',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Generando lista de preparación...</b>,
                    success: <b>Lista generada con éxito</b>,
                    error: <b>Ocurrió un error al generar lista de preparación.</b>,
                });
            },
        }),
        deliveriesScanPackage: builder.mutation({
            query: (data) => {
                return {
                    url: `/fulfillments/scan-packages`,
                    method: 'POST',
                    body: data,
                };
            },
            onQueryStarted: async (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Buscando pedidos asociados al dato escaneado.</b>,
                    success: (response) => {
                        let message = 'Bulto escaneado con éxito.';

                        if (response?.data?.not_found_packages) {
                            message = `No se encontró bulto con el dato escaneado.`;
                            throw new Error(message);
                        } else if (response?.data?.multi_orders_match) {
                            message = `El pedido tiene más de un bulto asociado, debe escanear el código del bulto para poder procesarlo`;
                            throw new Error(message);
                        } else if (response?.data?.not_allowed_different_delivery_method) {
                            message = `No es posible combinar en una misma entrega: el procesamiento de pedidos que no comparten la misma configuración de entrega. (Entrega en 1 paso ó en 2 pasos)`;
                            throw new Error(message);
                        } else if (response?.data?.fulfillment_is_delivered) {
                            message = `El dato escaneado pertenece a un pedido que ya fue entregado`;
                            throw new Error(message);
                        } else if (response?.data?.waiting_collect_manifest) {
                            message = `El dato escaneado pertenece a un pedido pendiente de retiro`;
                            throw new Error(message);
                        } else if (response?.data?.multiple_match) {
                            message = `Se encontraron ${
                                response?.data?.packages?.length ?? 0
                            } bultos.`;
                        }

                        return <b>{message}</b>;
                    },
                    error: (response) => {
                        return (
                            <b>
                                {response.message
                                    ? response.message
                                    : 'Ocurrió un error al escanear bulto.'}
                            </b>
                        );
                    },
                });
            },
        }),
        validationDeliveryFulfillments: builder.mutation({
            query: (data) => {
                return {
                    url: `/deliveries/fulfillments/validations/delivery-fulfillments`,
                    method: 'POST',
                    body: data,
                };
            },
            onQueryStarted: async (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Validando todos los fulfillments.</b>,
                    success: (response) => (
                        <b>
                            Total de fulfillment permitidos para entrega:{' '}
                            {response.data.filter((f) => f.validation).length}
                        </b>
                    ),
                    error: (response) => (
                        <b>
                            {response.message
                                ? response.message
                                : 'Ocurrió un error al escanear bulto.'}
                        </b>
                    ),
                });
            },
        }),
        MergePdfsUtils: builder.mutation({
            method: 'put',
            query: (data) => {
                return {
                    url: `/orders/merge-pdfs`,
                    method: 'post',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Obteniendo datos</b>,
                    success: <b>Datos obtenidos con éxito</b>,
                    error: <b>Ocurrió un error</b>,
                });
            },
        }),
    }),
});

export const {
    useGetOneFulfillmentQuery,
    useLazyGetInProgressFulfillmentsQuery,
    useLazyGetSuccessFulfillmentsQuery,
    useLazyGetPendingArrivalFulfillmentsQuery,
    useLazyGetUsersPerLocationQuery,
    useGetUsersPerLocationQuery,
    useChangeFulfillmentsCouriersMutation,
    useGetShippingPriceMutation,
    useRetryShippingCreationMutation,
    useUpdateAdressOrCourierMutation,
    useRetryShippingCreationMassiveTagsMutation,
    useGetFulfillmentScannedStatusMutation,
    useCompleteFulfillmentV2Mutation,
    useAddToPackageV2Mutation,
    useCreatePackageV2Mutation,
    useDeletePackageV2Mutation,
    useUpdatePackageV2Mutation,
    useCreateFulfillmentListMutation,
    useCreateFulfillmentByLogisticMutation,
    useGetFulfillmentListQuery,
    useRemoveFulfillmentFromListMutation,
    useSentFulfillmentsToDeliveryMutation,
    useRemoveItemFromPackageMutation,
    useFulfillmentsBatchDeliveryMutation,
    useGeneratePickingListPDFMutation,
    useDeliveriesScanPackageMutation,
    useValidationDeliveryFulfillmentsMutation,
    useMergePdfsUtilsMutation,
    useGenerateChangeTicketMutation,
    useLazyGetInProgressFulfillmentsAllIdsQuery,
    useLazyGetPendingArrivalFulfillmentsAllIdsQuery,
    useLazyGetSuccessFulfillmentsAllIdsQuery,
    useUpdateFulfillmentPromisedDateMutation,
} = fulfillmentsApi;
