import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import Input from 'components/V2/Input';
import { dispatchers } from '../Filters/config';
import { addManifestsToMassiveProcess, clearManifestsMassiveProcess } from 'redux/features/Utils';
import { useLazyGetBatchDeliversByManifestIdQuery } from 'redux/features/Deliveries';

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 3px;
    height: 40px;
    transition: 0.2s;
    width: 330px;
`;

const ManifestScanInput = ({ refetch, dispatchersType, scanMode = false, disabled = false }) => {
    const [getManifest, scanStatus] = useLazyGetBatchDeliversByManifestIdQuery();
    const [searchTerm, setSearchTerm] = useState('');
    const scannedInputRef = useRef(null);
    const dispatch = useDispatch();
    const { setFilters } = dispatchers[dispatchersType];

    const handleScanManifestId = async (value) => {
        const response = await getManifest(value);
        if (!response?.data?.docs?.length || response?.data?.docs?.length === 0) return;
        for (const manifest of response?.data?.docs ?? []) {
            if (Array.from(scanStatus?.data ?? [])?.find((item) => item._id === manifest._id))
                continue;
            dispatch(addManifestsToMassiveProcess(manifest));
        }
    };

    const handleSearch = async (value = '', hard = false) => {
        value = value.trim();
        if (value === '' || !value) return;
        dispatch(setFilters({ searchTerm: value }));

        if (!scanMode) {
            return refetch(hard);
        }

        setSearchTerm('');
        if (scannedInputRef?.current) scannedInputRef.current.value = '';

        await handleScanManifestId(value);
    };

    const handleScan = () => {
        if (scannedInputRef.current) scannedInputRef.current.focus();
    };

    const enableScanMode = () => {
        if (scannedInputRef.current) scannedInputRef.current.focus();
        window.addEventListener('keydown', handleScan);
    };

    const disableScanMode = () => {
        window.removeEventListener('keydown', handleScan);
        dispatch(clearManifestsMassiveProcess());
    };

    useEffect(() => {
        if (!scanMode) {
            const delayDebounceFn = setTimeout(() => {
                handleSearch(searchTerm);
            }, 500);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (scanMode) {
            enableScanMode();
        } else {
            disableScanMode();
        }
        return () => {
            disableScanMode();
        };
    }, [scanMode]);

    return (
        <>
            <SearchWrapper>
                <Input
                    placeholder="Escanea o ingresa el código del manifiesto"
                    ref={scannedInputRef}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    disabled={disabled || scanStatus.isLoading || scanStatus.isFetching}
                    style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setSearchTerm(e.target.value);
                            handleSearch(e.target.value);
                        }
                    }}
                />
            </SearchWrapper>
        </>
    );
};

export default ManifestScanInput;
