export const semaphoreDefaultStates = [
    {
        color: '#089460',
        state: 'in_progress',
        range: [0, 30],
    },
    {
        color: '#F9E107',
        state: 'warning',
        range: [31, 70],
    },
    {
        color: '#B00303',
        state: 'critical',
        range: [71, 100],
    },
    {
        color: '#A72FDF',
        state: 'serious',
        range: [101, 200],
    },
];
export const semaphoreStateDic = {
    in_progress: 'Estado normal en curso',
    warning: 'Estado Riesgoso',
    critical: 'Estado crítico',
    serious: 'Estado grave',
};
