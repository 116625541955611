import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getAllShops } from './getAllShops';

export const changeStatusManyShops = createAsyncThunk(
    'stores-warehouses/changeStatus',
    async (data, state) => {
        try {
            const res = await toast.promise(AndesClient.post(`/stores-warehouses/changeStatusMany`, data),{
                loading: 'Cambiando estado Tienda/Bodega...',
                success: 'Estado Tienda/Bodega cambiado con éxito.',
                error: (error) => `Error: ${error.response.data.message}`
            });
            if (res.status === 200) {
                state.dispatch(getAllShops());
            }
            return state.fulfillWithValue(true);
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const changeStatusManyShopsSlice = createSlice({
    name: 'changeStatusManyShops',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [changeStatusManyShops.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [changeStatusManyShops.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [changeStatusManyShops.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default changeStatusManyShopsSlice.reducer;
