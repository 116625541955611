const printMassiveZpl = (text) => {
    var iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    const labels = text.split('--LABEL--');

    labels.forEach((label = '') => {
        if (label === '') {
            return;
        }
        label = label.replace('--LABEL--', '')
        const div = document.createElement('div');
        div.className = 'zpl-label';
        div.innerHTML = label;
        iframe.contentWindow.document.body.appendChild(div);
    });

    const style = document.createElement('style');
    style.innerHTML = `
      @media print {
          thead, tfoot {  
              display: none !important
            }
          @page {
              size: auto;
              margin: 0mm;
          } 
          .zpl-label {
              page-break-after: always;
          }

      }
  `;
    iframe.contentWindow.document.head.appendChild(style);
    var script = document.createElement('script');
    script.innerHTML = 'window.print()';
    iframe.contentWindow.document.body.appendChild(script);
    iframe.contentWindow.document.close();
    // remove
    document.body.removeChild(iframe);
};

export default printMassiveZpl;
