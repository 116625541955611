import { preparationsDeliveryMethodOptions, preparationsFulfillmentStatusOptions } from './values';
import { preparationsFilterFields } from './fields';

export const preparationsFilters = {
    fields: [...preparationsFilterFields],
    values: {
        status: [...preparationsFulfillmentStatusOptions],
        delivery_method: [...preparationsDeliveryMethodOptions],
    },
};
