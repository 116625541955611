import styled, { css } from 'styled-components';

export const ContainerTitle = styled.div`
    color: #2d3d76;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    padding: 0px;
`;

export const SubTitleShowDetailsDrawer = styled.span`
    color: #1f2937;
    font-weight: 600;
    font-size: 14px;
    line-height: 188.6%;
    margin-bottom: 10px;
`;

export const Label = styled.span`
    font-weight: ${({ fontWeight }) => fontWeight ?? 500};
    font-size: ${({ fontSize }) => fontSize ?? '14px'};
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #142c4a;
    font-family: Poppins;
    display: inline-flex;
    ${({ whiteSpace }) =>
        css`
            white-space: ${whiteSpace};
        `}
`;

export const Description = styled.span`
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: ${({ fontWeight }) => fontWeight ?? 400};
    line-height: 22px;
    color: #2d3d76;
`;

// Problem order details drawer
export const ContainerProblemOrderDetailsDrawer = styled.div`
    background-color: #f5f5f5;
    border: 1px solid #e4e8ec;
    border-radius: 4px;
    box-sizing: border-box;
`;
