import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import toast from 'react-hot-toast';

export const emailNotificationApi = createApi({
    reducerPath: 'emailNotification',
    baseQuery,
    tagTypes: ['emailNotification'],
    endpoints: (builder) => ({
        createEmailNotification: builder.mutation({
            method: 'post',
            query: (data) => {
                return {
                    url: '/settings/notifications/email',
                    method: 'post',
                    body: data
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Guardando cambios...</b>,
                    success: <b>Cambios aplicados con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['emailNotification'],
        }),
        updateEmailNotification: builder.mutation({
            method: 'put',
            query: ({ _id, ...data }) => {
                return {
                    url: `/settings/notifications/email/${_id}`,
                    method: 'put',
                    body: data,
                };
            },
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Guardando cambios...</b>,
                    success: <b>Cambios aplicados con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['emailNotification'],
        }),
        getEmailNotification: builder.query({
            method: 'get',
            query: () => {
                return {
                    url: '/settings/notifications/email/by-merchant',
                    method: 'get',
                };
            },
            providesTags: ['emailNotification'],
        }),
    }),
});

export const { useUpdateEmailNotificationMutation, useGetEmailNotificationQuery, useCreateEmailNotificationMutation } =
    emailNotificationApi;
