import {
    overrideFilters as preparationsOverrideFilters,
    addFilter as preparationsAddFilters,
    clearFilters as preparationsClearFilters,
    removeFilter as preparationsRemoveFilter,
    updateFilter as preparationsUpdateFilter,
    setValueOptionByField as preparationsSetValueOptionByField,
} from 'redux/features/Preparations/preparationsFilters';
import {
    overrideFilters as pendingArrivalsOverrideFilters,
    addFilter as pendingArrivalsAddFilter,
    clearFilters as pendingArrivalsClearFilters,
    removeFilter as pendingArrivalsRemoveFilter,
    updateFilter as pendingArrivalsUpdateFilter,
    setValueOptionByField as pendingArrivalsSetValueOptionByField,
} from 'redux/features/PendingArrivals/pendingArrivalsFilters';
import {
    overrideFilters as crossdockingOverrideFilters,
    addFilter as crossdockingAddFilter,
    clearFilters as crossdockingClearFilters,
    removeFilter as crossdockingRemoveFilter,
    updateFilter as crossdockingUpdateFilter,
    setValueOptionByField as crossdockingSetValueOptionByField,
} from 'redux/features/Crossdocking/crossdockingFilters';

import {
    overrideFilters as deliveriesOverrideFilters,
    addFilter as deliveriesAddFilter,
    clearFilters as deliveriesClearFilters,
    removeFilter as deliveriesRemoveFilter,
    updateFilter as deliveriesUpdateFilter,
    setValueOptionByField as deliveriesSetValueOptionByField,
} from 'redux/features/Deliveries/deliveriesFilters';

import {
    overrideFilters as manifestsOverrideFilters,
    addFilter as manifestsAddFilter,
    clearFilters as manifestsClearFilters,
    removeFilter as manifestsRemoveFilter,
    updateFilter as manifestsUpdateFilter,
    setValueOptionByField as manifestsSetValueOptionByField,
} from 'redux/features/Manifests/manifestsFilters';

import {
    setPreparationFilters,
    setPendingArrivalsFilters,
    setDeliveriesFilters,
    setManifestsFilters,
    setCrossdockingFilters
} from 'redux/features/Utils';

export const dispatchers = {
    preparations: {
        overrideFilters: preparationsOverrideFilters,
        addFilter: preparationsAddFilters,
        clearFilters: preparationsClearFilters,
        removeFilter: preparationsRemoveFilter,
        updateFilter: preparationsUpdateFilter,
        setValueOptionByField: preparationsSetValueOptionByField,
        setFilters: setPreparationFilters,
    },
    pendingArrivals: {
        overrideFilters: pendingArrivalsOverrideFilters,
        addFilter: pendingArrivalsAddFilter,
        clearFilters: pendingArrivalsClearFilters,
        removeFilter: pendingArrivalsRemoveFilter,
        updateFilter: pendingArrivalsUpdateFilter,
        setValueOptionByField: pendingArrivalsSetValueOptionByField,
        setFilters: setPendingArrivalsFilters,
    },
    crossdocking: {
        overrideFilters: crossdockingOverrideFilters,
        addFilter: crossdockingAddFilter,
        clearFilters: crossdockingClearFilters,
        removeFilter: crossdockingRemoveFilter,
        updateFilter: crossdockingUpdateFilter,
        setValueOptionByField: crossdockingSetValueOptionByField,
        setFilters: setCrossdockingFilters,
    },
    deliveries: {
        overrideFilters: deliveriesOverrideFilters,
        addFilter: deliveriesAddFilter,
        clearFilters: deliveriesClearFilters,
        removeFilter: deliveriesRemoveFilter,
        updateFilter: deliveriesUpdateFilter,
        setValueOptionByField: deliveriesSetValueOptionByField,
        setFilters: setDeliveriesFilters,
    },
    manifests: {
        overrideFilters: manifestsOverrideFilters,
        addFilter: manifestsAddFilter,
        clearFilters: manifestsClearFilters,
        removeFilter: manifestsRemoveFilter,
        updateFilter: manifestsUpdateFilter,
        setValueOptionByField: manifestsSetValueOptionByField,
        setFilters: setManifestsFilters,
    },
};
