import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getAllCouriers = createAsyncThunk('couriers/getAll', async (_, state) => {
    try {
        const res = await AndesClient.get(`/settings/couriers`);
        return res.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const getAllCouriersSlice = createSlice({
    name: 'getAllCouriers',
    initialState: {
        loading: false,
        data: [],
        error: null,
    },
    reducers: {
        addCourierToStore: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                data: [payload, ...state.data],
            };
        },
        deleteCourierFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.filter(({ _id }) => _id !== payload);
            return {
                ...state,
                data: [...newArrayData],
            };
        },
        updateCourierFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.map((courier) => {
                if (courier._id === payload._id) {
                    return {
                        ...courier,
                        ...payload,
                    };
                } else {
                    return courier;
                }
            });
            return {
                ...state,
                data: [...newArrayData],
            };
        },
    },
    extraReducers: {
        [getAllCouriers.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getAllCouriers.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [getAllCouriers.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});
export const { addCourierToStore, deleteCourierFromStore, updateCourierFromStore } =
    getAllCouriersSlice.actions;
export default getAllCouriersSlice.reducer;
