import { DatePicker, Form } from 'antd';
import ModalWithButton from 'components/ModalWithButton';
import { updateOrder } from 'redux/features/Orders/updateOrder';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';
import { ModalChangeSLADateContent, ModalChangeSLADateSection } from './styles';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { useState } from 'react';
import { useUpdateFulfillmentPromisedDateMutation } from 'redux/features/Fulfillments';

const TitleModalChangePromiseDate = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: #2d3d76;
`;

const ChangeSlaDate = ({
    order,
    modalProps,
    buttonProps,
    fulfillment,
    isFulfillment = false,
    refetch,
}) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [isButtonEnabled, setButtonEnabled] = useState(false);
    const [updateFulfillmentPromisedDate] = useUpdateFulfillmentPromisedDateMutation();

    const handleSubmit = async (values) => {
        if (isFulfillment) {
            await updateFulfillmentPromisedDate({
                promised_date: `${dayjs(values.promised_date).toISOString()}`,
                fulfillment_id: fulfillment._id,
            });
        }

        if (!isFulfillment) {
            dispatch(
                updateOrder({
                    _id: order._id,
                    promised_date: `${dayjs(values.promised_date).toISOString()}`,
                })
            );
        }

        setTimeout(() => {
            refetch();
        }, 3000);
        dispatch(
            toggleModal(`order-change-sla-date-modal${isFulfillment ? `-${fulfillment._id}` : ''}`)
        );
    };
    const title = (
        <TitleModalChangePromiseDate>Cambiar fecha de entrega</TitleModalChangePromiseDate>
    );

    return (
        <ModalWithButton
            modalProps={{
                destroyOnClose: true,
                cancelText: 'Cancelar',
                okText: 'Confirmar cambio',
                title: title,
                name: `order-change-sla-date-modal${isFulfillment ? `-${fulfillment._id}` : ''}`,
                onOk: () => {
                    form.submit();
                },
                ...modalProps,
                okButtonProps: {
                    disabled: !isButtonEnabled,
                    style: {
                        backgroundColor: '#F27127',
                        color: 'white',
                        border: '1px solid #E79B09 ',
                    },
                },
                cancelButtonProps: {
                    style: {
                        backgroundColor: 'none',
                        color: '#2D3D76',
                        border: '1px solid #2D3D76 ',
                    },
                },
            }}
            buttonProps={{
                ...buttonProps,
            }}
            buttonType="menuitem"
        >
            <ModalChangeSLADateContent>
                <ModalChangeSLADateSection>
                    <div className="label_modal_change_sla_date">Fecha de entrega actual: </div>
                    <div className="promise_date_old">
                        {dayjs(order?.promised_date).format('DD/MM/YYYY HH:mm')}
                    </div>
                </ModalChangeSLADateSection>

                <ModalChangeSLADateSection>
                    <div className="label_modal_change_sla_date new_date_to_selected">
                        Nueva fecha a seleccionar:{' '}
                    </div>

                    <Form form={form} name="order-change-sla-date-form" onFinish={handleSubmit}>
                        <div className="flex justify-center">
                            <Form.Item name="promised_date" style={{ width: '100%' }}>
                                <DatePicker
                                    showTime={{ format: 'HH:mm' }}
                                    onChange={(date) => setButtonEnabled(!!date)} // Actualizar el estado cuando se seleccione o cambie una fecha
                                    placeholder="Fecha"
                                    format="DD-MM-YYYY HH:mm"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </Form>
                </ModalChangeSLADateSection>

                {!isFulfillment && (
                    <b
                        style={{
                            fontSize: '12px',
                            lineHeight: '16px',
                            fontSizeAdjust: '1',
                            color: 'rgb(20, 44, 74)',
                        }}
                    >
                        <b style={{ color: 'red' }}>Nota:</b> La modificación de la fecha de entrega
                        actual, afectará la fecha de entrega de todos los fulfillments pendiente de
                        entrega
                    </b>
                )}
            </ModalChangeSLADateContent>
        </ModalWithButton>
    );
};

export default ChangeSlaDate;
