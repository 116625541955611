import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const batchDeliversApi = createApi({
    reducerPath: 'batchDeliver',
    baseQuery,
    tagTypes: ['batchDeliver'],
    endpoints: (builder) => ({
        getBatchDelivers: builder.query({
            method: 'get',
            query: () => `/fulfillments/batch/deliver`,
            providesTags: ['batchDeliver'],
            transformResponse: (response) => {
                if (response) {
                    return {
                        docs: response,
                        totalDocs: 100,
                        ids: [],
                    };
                }
                return { error: '' };
            },
        }),
    }),
});

export const { useGetBatchDeliversQuery, useLazyGetBatchDeliversQuery } = batchDeliversApi;
