import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

export const couriersApi = createApi({
    reducerPath: 'couriers',
    baseQuery,
    tagTypes: ['Couriers', 'CourierDynamicTable'],
    endpoints: (builder) => ({
        getDynamicTable: builder.query({
            providesTags: ['CourierDynamicTable'],
            query: (args) => {
                return {
                    url: `settings/couriers/dynamic-tables/courier/${args.courier_id}${
                        args.type ? `?type=${args.type}` : ''
                    } `,
                    method: 'get',
                };
            },
        }),
        getAllCouriers: builder.query({
            providesTags: ['Couriers'],
            query: (data) => ({
                url: `/settings/couriers?master_courier_info=${data?.master_courier_info ?? false}`,
                method: 'get',
            }),
        }),
        getInfoCourierPreferences: builder.query({
            query: () => ({
                url: `/settings/couriers/preferences`,
                method: 'get',
            }),
            providesTags: ['couriers'],
        }),
        saveInfoCourierPreference: builder.mutation({
            query: (data) => ({
                url: `/settings/couriers/preferences`,
                method: 'post',
                body: data,
            }),
            invalidatesTags: ['couriers'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando las preferencias del courier</b>,
                    success: <b>Preferencias del courier actualizadas</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
    }),
});

export const {
    useLazyGetDynamicTableQuery,
    useGetAllCouriersQuery,
    useGetInfoCourierPreferencesQuery,
    useSaveInfoCourierPreferenceMutation,
} = couriersApi;
