import { createSlice } from '@reduxjs/toolkit';

const filtersShops = createSlice({
    name: 'filtersShops',
    initialState: {
        channelSalesFilter: [],
        functionFilter: [],
        nameFilter: '',
        hasDevolution: null,
        hasFulfillment: null,
        selectAll: null,
    },

    reducers: {
        addAllFilters: (state, action) => {
            return {
                ...state,
                channelSalesFilter: action.payload,
            };
        },
        clearAllFilters: (state) => {
            return {
                ...state,
                channelSalesFilter: [],
            };
        },
        addTochannelSalesFilter: (state, action) => {
            return {
                ...state,
                channelSalesFilter: [...state.channelSalesFilter, action.payload],
            };
        },
        removeTochannelSalesFilter: (state, action) => {
            return {
                ...state,
                selectAll: null,

                channelSalesFilter: state.channelSalesFilter.filter((p) => p !== action.payload),
            };
        },
        addFunctionFilter: (state, action) => {
            return {
                ...state,
                functionFilter: [...state.functionFilter, action.payload],
            };
        },
        removeFunctionFilter: (state, action) => {
            return {
                ...state,
                functionFilter: state.functionFilter.filter((p) => p !== action.payload),
            };
        },
        changeNameFilter: (state, action) => {
            return {
                ...state,
                nameFilter: action.payload,
            };
        },
        changeHasDevolutionFilter: (state, action) => {
            return {
                ...state,
                hasDevolution: action.payload,
            };
        },
        changeHasFulfillmentFilter: (state, action) => {
            return {
                ...state,
                hasFulfillment: action.payload,
            };
        },
        changeSelectAll: (state, action) => {
            return {
                ...state,
                selectAll: action.payload,
            };
        },
        removeAllOF: (state) => {
            return {
                ...state,
                channelSalesFilter: [],
                functionFilter: [],
                nameFilter: '',
                hasDevolution: null,
                hasFulfillment: null,
                selectAll: null,
            };
        },
    },
});
export const {
    addTochannelSalesFilter,
    removeTochannelSalesFilter,
    addFunctionFilter,
    removeFunctionFilter,
    changeNameFilter,
    changeHasDevolutionFilter,
    changeHasFulfillmentFilter,
    changeSelectAll,
    removeAllOF,
    addAllFilters,
    clearAllFilters,
} = filtersShops.actions;
export default filtersShops.reducer;
