import { Dot } from 'components/Utils/Dot';
import Tag from 'components/Tag';
import Flex from 'components/Utils/Flex';
import useMasterLabels from 'hooks/useMasterLabels';

const renderFulfillmentLabel = ({
    status,
    customLabel,
    customColor,
    textColor,
    statusInTag,
    withoutDot,
}) => {
    const { getLabel } = useMasterLabels();

    if (status === 'fulfilled') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#18a354'} borderColor="#41515E" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('fulfilled')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#18a354',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('fulfilled')}
                </Tag>
            );
        }
    }

    if (status === 'unfulfilled' || !status) {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#fcd34d'} borderColor="#41515E" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('unfulfilled')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#fcd34d',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('unfulfilled')}
                </Tag>
            );
        }
    }

    if (status === 'in_progress') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#8b5cf6'} borderColor="#41515E" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('in_progress')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#8b5cf6',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('in_progress')}
                </Tag>
            );
        }
    }

    if (status === 'paused') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#8b5cf6'} borderColor="#41515E" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('paused')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#8b5cf6',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('paused')}
                </Tag>
            );
        }
    }

    if (status === 'partially_fulfilled') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#8b5cf6'} borderColor="#41515E" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('partially_fulfilled')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#8b5cf6',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('partially_fulfilled')}
                </Tag>
            );
        }
    }

    if (status === 'sac') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#ef4444'} borderColor="#000" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('sac')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#ef4444',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('sac')}
                </Tag>
            );
        }
    }
    if (status === 'cancelled') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#ef4444'} borderColor="#000" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('cancelled')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#ef4444',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('cancelled')}
                </Tag>
            );
        }
    }

    if (status === 'delivered') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#00BA76'} borderColor="#000" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('delivered')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#00BA76',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('delivered')}
                </Tag>
            );
        }
    }

    if (status === 'delivered_to_courier') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#00BA76'} borderColor="#000" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('delivered_to_courier')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#00BA76',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('delivered_to_courier')}
                </Tag>
            );
        }
    }

    if (status === 'open') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#00BA76'} borderColor="#000" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('open')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#00BA76',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('open')}
                </Tag>
            );
        }
    }

    if (status === 'ready_for_deliver') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#00BA76'} borderColor="#000" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('ready_for_deliver')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#00BA76',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('ready_for_deliver')}
                </Tag>
            );
        }
    }
    if (status === 'partially_processed') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#00BA76'} borderColor="#000" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('partially_processed')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#00BA76',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('partially_processed')}
                </Tag>
            );
        }
    }

    if (status === 'archived') {
        if (!statusInTag) {
            return (
                <Flex direction="row" alignItems="center" columnGap="6px">
                    <Dot size="12px" color={customColor ?? '#00BA76'} borderColor="#000" />
                    <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                        {customLabel ?? getLabel('archived')}
                    </span>
                </Flex>
            );
        }

        if (statusInTag) {
            return (
                <Tag
                    centered
                    style={{
                        backgroundColor: customColor ?? '#00BA76',
                        color: textColor ?? '#fff',
                        fontWeight: '400',
                    }}
                >
                    {customLabel ?? getLabel('archived')}
                </Tag>
            );
        }
    }
    if (status === 'success') {
        return (
            <Flex direction="row" alignItems="center" columnGap="6px">
                {!withoutDot && (
                    <Dot size="12px" color={customColor ?? '#00BA76'} borderColor="#000" />
                )}
                <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                    {customLabel ?? getLabel('open')}
                </span>
            </Flex>
        );
    }

    if (status === 'all_packages_processed') {
        return (
            <Flex direction="row" alignItems="center" columnGap="6px">
                {!withoutDot && (
                    <Dot size="12px" color={customColor ?? '#00BA76'} borderColor="#000" />
                )}
                <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                    Todos los bultos procesados
                </span>
            </Flex>
        );
    }

    return status;
};

export default renderFulfillmentLabel;
