import history from 'helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import { setSettingContentActive } from 'redux/features/Utils';

const useSettingContent = () => {
    const dispatch = useDispatch();

    const { settingsContentActive, settingsContentActiveParams } = useSelector(
        (state) => state.utils
    );

    const handleActiveContent = (v, params) => {
        dispatch(setSettingContentActive({ contentName: v, params }));
        history.push(`/settings/${v}${params ?? ''}`);
    };

    return [{ settingsContentActive, settingsContentActiveParams }, handleActiveContent];
};

export default useSettingContent;
