import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getFulfillmentById } from '../Fulfillments/getOne';

export const addMarketingProductsToPackage = createAsyncThunk(
    'packages/addmarketing',
    async (data, state) => {
        try {
            const promise = AndesClient.put('/packages/addmarketing', data);

            toast.promise(promise, {
                loading: <b>Añadiendo productos al bulto...</b>,
                success: <b>Productos añadidos con éxito</b>,
                error: <b>Ocurrió un error.</b>,
            });

            const result = await promise;

            if (result.status === 200) {
                state.dispatch(getFulfillmentById(data.fulfillment_id));
            }

            return await result.data;
        } catch (error) {
            return state.rejectWithValue(error.message);
        }
    }
);

const addMarketingProductsToPackageSlice = createSlice({
    name: 'packages-marketing-add',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [addMarketingProductsToPackage.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [addMarketingProductsToPackage.fulfilled]: (state) => {
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };
        },
        [addMarketingProductsToPackage.rejected]: (state, payload) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default addMarketingProductsToPackageSlice.reducer;
