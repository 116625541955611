import { Upload as AntdUpload } from 'antd';
import uploadFile from 'helpers/uploadFile';

const Upload = ({ children, fileList, setFileList, setLoadingLogo }) => {
    const handleUploadFiles = async (options) => {
        const { file, onSuccess, onError } = options;
        try {
            setLoadingLogo(true);
            const url = await uploadFile({ file });
            onSuccess(null, file);
            const image = new Image();
            image.src = url;
            image.onload = () => {
                setFileList([
                    ...fileList,
                    {
                        url: `${url}?width=${image.width}&height=${image.height}`,
                        uid: file.uid,
                        content_type: file.type,
                        size: file.size,
                        file_name: file.name.toLowerCase(),
                    },
                ]);
                setLoadingLogo(false);
            };
        } catch (error) {
            onError(null, file);
        }
    };

    const handleRemoveFiles = async (file) => {
        setFileList([...fileList.filter((fileToRemove) => fileToRemove.uid !== file.uid)]);
    };

    return (
        <AntdUpload
            listType="picture"
            className="upload-list-inline"
            customRequest={handleUploadFiles}
            defaultFileList={[]}
            onRemove={handleRemoveFiles}
            maxCount={1}
        >
            {children}
        </AntdUpload>
    );
};

export default Upload;
