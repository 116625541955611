export const fulfillmentStatusOptions = [
    {
        label: 'Pendiente',
        value: 'unfulfilled',
    },
    {
        label: 'En proceso',
        value: 'in_progress',
    },
    {
        label: 'Parcialmente preparado',
        value: 'partially_fulfilled',
    },
    {
        label: 'Listo para enviar',
        value: 'fulfilled',
    },
    {
        label: 'Entregado',
        value: 'delivered',
    },
    {
        label: 'Despachado',
        value: 'delivered_to_courier',
    },
    {
        label: 'Cancelado',
        value: 'cancelled',
    },
    {
        label: 'SAC',
        value: 'sac',
    },
];
