import { createApi } from '@reduxjs/toolkit/query/react';
import getFromEnv from 'helpers/getFromEnv';
import { isML } from 'helpers/isML';
import qs from 'qs';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';

const stockApi = {
    dev: 'https://api-pim-demo.be-flow.com/',
    demo: 'https://api-pim-demo.be-flow.com/',
    prod: 'https://api-pim.be-flow.com/',
};

export const stocksApi = createApi({
    reducerPath: 'stocks',
    baseQuery,
    tagTypes: ['stocks', 'stocks_history'],
    endpoints: (builder) => ({
        getStockProducts: builder.query({
            method: 'get',
            query: (args) => {
                const querystring = qs.stringify(args ?? {});
                const userLogged = JSON.parse(localStorage.getItem('userLogged') || '{}');

                if (isML(userLogged?.merchant_id?._id)) {
                    return `${stockApi[getFromEnv('REACT_APP_STAGE')]}products${
                        querystring ? `?${querystring}` : ''
                    }`;
                }
                return `/stock/products${querystring ? `?${querystring}` : ''}`;
            },
            providesTags: ['stocks'],
            invalidatesTags: ['stocks_history'],
        }),
        getStockProductsHistory: builder.query({
            method: 'get',
            query: (args) => {
                const querystring = qs.stringify(args ?? {});
                const userLogged = JSON.parse(localStorage.getItem('userLogged') || '{}');

                if (isML(userLogged?.merchant_id?._id)) {
                    return `${stockApi[getFromEnv('REACT_APP_STAGE')]}products/${
                        args.product_id
                    }/history${querystring ? `?${querystring}` : ''}`;
                }
                return `/stock/products/history${querystring ? `?${querystring}` : ''}`;
            },
            providesTags: ['stocks_history'],
        }),
        updateStock: builder.mutation({
            method: 'post',
            query: (data) => {
                const userLogged = JSON.parse(localStorage.getItem('userLogged') || '{}');
                const url = isML(userLogged?.merchant_id?._id)
                    ? `${stockApi[getFromEnv('REACT_APP_STAGE')]}stock-updates/by-product`
                    : '/stock/update';
                const method = isML(userLogged?.merchant_id?._id) ? 'put' : 'post';

                return {
                    url,
                    method,
                    body: data,
                };
            },
            invalidatesTags: ['stocks', 'stocks_history'],
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando stock</b>,
                    success: <b>Stock actualizado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
    }),
});

export const {
    useLazyGetStockProductsQuery,
    useLazyGetStockProductsHistoryQuery,
    useUpdateStockMutation,
} = stocksApi;