import { Skeleton } from 'antd';
import { Tabs } from '../../../../components/TabsV2';
import { IntegrationsErrors } from './integrationsErrors';
import TimelineV2 from './timeline';

const OrderStates = ({ data, loading }) => {
    return (
        <>
            {loading ? (
                <Skeleton active />
            ) : (
                <Tabs defaultActiveKey="1" $show $activeBorder $withoutContentPadding>
                    <Tabs.TabPane tab="Línea de tiempo" key="1">
                        <TimelineV2 name="time_line" data={data} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Estados del pedido" key="2">
                        <TimelineV2 name="time_line_status" data={data} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Errores de integración" key="3">
                        <IntegrationsErrors data={data} />
                    </Tabs.TabPane>
                </Tabs>
            )}
        </>
    );
};

export default OrderStates;
