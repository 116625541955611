import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralByMerchant } from 'redux/features/General/getGeneralByMerchant';
import { isObjectEmpty } from 'helpers/filterObjectByCustom';

const cacheData = localStorage.getItem('generalSettings')
    ? JSON.parse(localStorage.getItem('generalSettings') || '{}')
    : {};

const useGetGenerals = () => {
    const dispatch = useDispatch();
    const { loading, data, success, error } = useSelector((state) => state.getGeneralByMerchant);

    useEffect(() => {
        if (isObjectEmpty(data) && !success) {
            dispatch(getGeneralByMerchant());
        }
    }, []);

    const generals = isObjectEmpty(data) ? cacheData : data;

    return [loading, generals, error];
};

export default useGetGenerals;
