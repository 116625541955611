import { DownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Button, Popconfirm } from 'antd';

import { useDispatch } from 'react-redux';
import { pauseFulfillment } from 'redux/features/Fulfillments/pauseFulfillment';
import { toggleDrawer } from 'redux/features/Utils';

const PreparationActions = ({ fulfillment, order, disableButtons = false }) => {
    const dispatch = useDispatch();

    const handlePausePackage = () => {
        dispatch(pauseFulfillment({ fulfillment_id: fulfillment._id, order_id: order._id }));
    };

    const handleShowDetails = () => {
        dispatch(toggleDrawer('fulfillment-order-details'));
    };

    const menu = (
        <Menu>
            <Popconfirm
                title={
                    <span>
                        La preparación del bulto se pausará y se mantendrá visible solo en la
                        sección de pedidos. ¿Desea confirmar?
                    </span>
                }
                onConfirm={handlePausePackage}
                okText="Confirmar"
                width="300px"
                cancelText="Cancelar"
                style={{ width: '100px' }}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
                <Menu.Item disabled={disableButtons}>Pausar</Menu.Item>
            </Popconfirm>

            <Menu.Item onClick={handleShowDetails}>Detalles del pedido</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu}>
            <Button
                onClick={(e) => e.preventDefault()}
                style={{
                    background: 'white',
                    borderRadius: '3px',
                    border: '1px solid #2D3D76',
                    color: '#2D3D76',
                    padding: '0 2rem',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    fontSize: '1rem',
                    fontWeight: '400',
                    letterSpacing: '0.5px',
                }}
            >
                <span>Acciones</span>
                <DownOutlined
                    style={{
                        width: '1rem',
                        height: '1rem',
                        fontSize: '0.9rem',
                    }}
                />
            </Button>
        </Dropdown>
    );
};

export default PreparationActions;
