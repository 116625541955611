import { Button } from 'antd';
// import { CSVLink } from 'react-csv';
import AntdTable from 'components/AntdTable';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Flex from 'components/Utils/Flex';
import { Popconfirm } from 'antd';
import dayjs from 'helpers/dayjs';
import { useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMakeResolveErrorMutation, useRetryErrorMutation } from 'redux/features/Errors';
import JSONView from 'components/JsonView';
import TCard from 'components/TCard';
import ErrorsToolbar from '../ErrorsToolbar';
import { setErrorsFilters } from 'redux/features/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { FiRefreshCw } from 'react-icons/fi';
import { FiCheck } from 'react-icons/fi';
import { ContentButtonCheck, TagsIntegrationErrors } from 'pages/Errors/styles';
// import { BsFilter } from 'react-icons/bs';

const ErrorsTable = ({ loading, data, showFilters, refetch, name }) => {
    console.log({ dataForErrors: data });
    const [retryError, retryErrorState] = useRetryErrorMutation();
    const [makeResolve, makeResolveState] = useMakeResolveErrorMutation();

    const [selectedRows, setSelectedRows] = useState([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const { errorsFilters } = useSelector((state) => state.utils);

    const dispatch = useDispatch();

    const handleTableChange = (pagination, filters, sorter) => {
        let actuallySort = {};

        actuallySort = {
            sortBy: sorter.field,
            sortValue: sorter.order,
        };

        if (sorter.order === undefined) {
            actuallySort = {};
        }

        dispatch(
            setErrorsFilters({
                sort: actuallySort,
                page: pagination.current,
            })
        );
        if (name !== 'integration-errors-detail-order') {
            refetch();
        }
    };

    const handleRetry = async (_id) => {
        await retryError({ _id });
    };

    const handleMakeResolve = async (_id) => {
        await makeResolve({ _id });
    };

    let tableColumns = useMemo(
        () => [
            {
                // title: 'Descripción',
                title: () => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Descripción
                            {/* <BsFilter
                                style={{
                                    marginLeft: '8px',
                                    fontSize: '16px',
                                }}
                            /> */}
                        </div>
                    );
                },
                dataIndex: 'description',
                key: 'description',
                render: (description) => {
                    return (
                        <Flex direction='row' columnGap='1rem'>
                            <span>
                                {description === 'order not found message'
                                    ? 'Sin descripción'
                                    : description}
                            </span>
                        </Flex>
                    );
                },
                // sorter: (a, b) => {
                //     return String(a?.description).localeCompare(String(b.description));
                // },
                // defaultSortOrder: 'ascend',
                // sortDirections: ['ascend', 'descend', 'ascend'],
            },
            {
                key: 'createdAt',
                dataIndex: 'createdAt',
                title: () => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>
                                F<span style={{ textTransform: 'lowercase' }}>echa error</span>
                            </span>

                            {/* <BsFilter
                                style={{
                                    marginLeft: '8px',
                                    fontSize: '16px',
                                }}
                            /> */}
                        </div>
                    );
                },

                render: (createdAt) => {
                    return dayjs(createdAt).format('DD/MM/YYYY HH:mm');
                },
                // sorter: (a, b) => {
                //     return String(a?.createdAt).localeCompare(String(b.createdAt));
                // },
                // defaultSortOrder: 'ascend',
                // sortDirections: ['ascend', 'descend', 'ascend'],
            },
            {
                key: 'updatedAt',
                dataIndex: 'updatedAt',
                title: () => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>
                                Ú<span style={{ textTransform: 'lowercase' }}>ltimo intento</span>
                            </span>

                            {/* <BsFilter
                                style={{
                                    marginLeft: '8px',
                                    fontSize: '16px',
                                    // width: '25px',
                                }}
                            /> */}
                        </div>
                    );
                },

                render: (updatedAt, row) => {
                    if (updatedAt === row.createdAt) {
                        return '-';
                    }
                    return dayjs(updatedAt).format('DD/MM/YYYY HH:mm');
                },
                // sorter: (a, b) => {
                //     return String(a?.updatedAt).localeCompare(String(b.updatedAt));
                // },
                // defaultSortOrder: 'ascend',
                // sortDirections: ['ascend', 'descend', 'ascend'],
            },
            {
                key: 'status',
                dataIndex: 'status',
                title: () => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Estado</span>

                            {/* <BsFilter
                                style={{
                                    marginLeft: '8px',
                                    fontSize: '16px',
                                }}
                            /> */}
                        </div>
                    );
                },
                // sorter: (a, b) => {
                //     return String(a?.status).localeCompare(String(b.status));
                // },
                // defaultSortOrder: 'ascend',
                // sortDirections: ['ascend', 'descend', 'ascend'],
                render: (status) => {
                    if (status === 'pending') {
                        return (
                            <TagsIntegrationErrors color='warning'>Pendiente</TagsIntegrationErrors>
                        );
                    }
                    if (status === 'resolved') {
                        return (
                            <TagsIntegrationErrors color='success'>Resuelto</TagsIntegrationErrors>
                        );
                    }
                    if (status === 'in_progress') {
                        return (
                            <TagsIntegrationErrors color='processing'>
                                En proceso
                            </TagsIntegrationErrors>
                        );
                    }
                    return <TagsIntegrationErrors color='error'>Error</TagsIntegrationErrors>;
                },
            },
            {
                key: 'integration_name',
                dataIndex: 'integration_name',
                title: () => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Integración</span>

                            {/* <BsFilter
                                style={{
                                    marginLeft: '8px',
                                    fontSize: '16px',
                                }}
                            /> */}
                        </div>
                    );
                },
                render: (text) => (text === 'Notification' ? 'Notificación' : text),
                // sorter: (a, b) => {
                //     return String(a?.integration_name).localeCompare(String(b.integration_name));
                // },
                // sortDirections: ['ascend', 'descend', 'ascend'],
            },
            {
                key: 'attempts',
                dataIndex: 'attempts',
                title: () => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>Intentos</span>

                            {/* <BsFilter
                                style={{
                                    marginLeft: '8px',
                                    fontSize: '16px',
                                }}
                            /> */}
                        </div>
                    );
                },
                // sorter: (a, b) => {
                //     return String(a?.attempts).localeCompare(String(b.attempts));
                // },
                // sortDirections: ['ascend', 'descend', 'ascend'],
            },
            {
                title: 'Opciones',
                render: (row) => {
                    return (
                        <Flex direction='row' columnGap='1rem'>
                            <Button
                                type='secondary'
                                size='small'
                                disabled={
                                    ['in_progress', 'resolved', 'success'].includes(row.status) ||
                                    (retryErrorState.isLoading &&
                                        retryErrorState.originalArgs?._id === row._id) ||
                                    (retryErrorState.isSuccess &&
                                        retryErrorState.originalArgs?._id === row._id)
                                }
                                onClick={() => handleRetry(row._id)}
                            >
                                <FiRefreshCw />
                            </Button>

                            <Popconfirm
                                title='¿Está seguro que desea marcar este error como solucionado?'
                                onConfirm={() => handleMakeResolve(row._id)}
                                okText='Confirmar'
                                cancelText='Cancelar'
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                disabled={
                                    ['resolved', 'success'].includes(row.status) ||
                                    (makeResolveState.isLoading &&
                                        makeResolveState.originalArgs?._id === row._id) ||
                                    (makeResolveState.isSuccess &&
                                        makeResolveState.originalArgs?._id === row._id)
                                }
                            >
                                <Button
                                    size='small'
                                    type='primary'
                                    disabled={
                                        ['resolved', 'success'].includes(row.status) ||
                                        (makeResolveState.isLoading &&
                                            makeResolveState.originalArgs?._id === row._id) ||
                                        (makeResolveState.isSuccess &&
                                            makeResolveState.originalArgs?._id === row._id)
                                    }
                                >
                                    <ContentButtonCheck>
                                        <span>Solucionado</span>
                                        <FiCheck className='icon-check-button' />
                                    </ContentButtonCheck>
                                </Button>
                            </Popconfirm>

                            {/*<CSVLink*/}
                            {/*    data={handleExportButton(row)}*/}
                            {/*    filename={`${row?.extra_data?.order_name}.csv`}*/}
                            {/*    style={{ display: 'hidden' }}*/}
                            {/*>*/}
                            {/*    <Button size="small" type="primary" ghost>*/}
                            {/*        Exportar CSV*/}
                            {/*    </Button>*/}
                            {/*</CSVLink>*/}
                        </Flex>
                    );
                },
            },
        ],
        [
            data,
            retryErrorState.isLoading,
            retryErrorState.isFetching,
            makeResolveState.isLoading,
            makeResolveState.isFetching,
        ]
    );

    if (name !== 'integration-errors-detail-order') {
        tableColumns = [
            {
                title: 'ID Objetivo',
                render: (row) => {
                    return (
                        <Link to={`/orders/${row?.extra_data?.order_id ?? ''}`}>
                            {row?.extra_data?.order_name ?? ''}
                        </Link>
                    );
                },
                filterSearch: true,
                onFilter: (value, record) =>
                    String(record?.extra_data?.order_name ?? '').startsWith(value),
            },
            ...tableColumns,
        ];
    }

    const tableData = useMemo(() => Array.from(data?.docs ?? []), [data]);

    // const prepareDataForExport = (row) => {
    //     return {
    //         'ID Objetivo': row?.extra_data?.order_name ?? '',
    //         Descripcion: row?.description,
    //         'Fecha del error': dayjs(row?.createdAt).format('DD/MM/YYYY HH:mm'),
    //         'Fecha ultimo intento':
    //             row?.updatedAt === row?.createdAt
    //                 ? '-'
    //                 : dayjs(row?.updatedAt).format('DD/MM/YYYY HH:mm'),
    //         Estado: row?.status,
    //         Integración: row?.integration_name,
    //         Intentos: row?.attempts,
    //         'Error JSON': JSON.stringify(row?.log?.other?.error_object),
    //     };
    // };
    //
    // const handleExportButton = (row) => {
    //     const data = [prepareDataForExport(row)];
    //     const headers = Object.keys(data[0]);
    //     const csvData = [headers, ...data.map((item) => headers.map((key) => item[key]))];
    //     return csvData;
    // };

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedRows,
        onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys);
        },
    };

    useEffect(() => {
        setExpandedRowKeys([]);
        setSelectedRows([]);
    }, [tableData]);

    return (
        <>
            <TCard paddingless>
                <AntdTable
                    locale={{ emptyText: 'No se encontraron errores.' }}
                    scroll={{ x: true }}
                    rowKey='_id'
                    loading={loading}
                    rowSelection={name === 'integration-errors-detail-order' ? '' : rowSelection}
                    dataSource={tableData}
                    columns={tableColumns}
                    onChange={handleTableChange}
                    removeHeaderBackground
                    newSortIcon
                    pagination={{
                        position: ['bottomCenter'],
                        showSizeChanger: false,
                        showQuickJumper: false,
                        total: data?.totalDocs,
                        current: errorsFilters.page,
                        pageSize: data?.limit,
                    }}
                    actionsNode={
                        showFilters && (
                            <ErrorsToolbar
                                refetch={refetch}
                                handleSelectAll={() => {}}
                                selectedRows={selectedRows}
                                data={tableData}
                                setSelectedRows={setSelectedRows}
                                totalDocs={1}
                                totalPages={1}
                            />
                        )
                    }
                    expandable={{
                        expandedRowRender: (row) => {
                            const error_object = row?.log?.other?.error_object ?? {};
                            {
                                console.log(error_object);
                            }
                            // eslint-disable-next-line no-unused-vars
                            const { stack = '', ...otherErrors } = error_object;
                            return (
                                <div>
                                    <b>{row?.description ?? ''}</b>
                                    <JSONView json={otherErrors} />
                                </div>
                            );
                        },
                        rowExpandable: (row) => row._id !== 'Not Expandable',
                        expandedRowKeys,
                        onExpand: (expanded, record) => {
                            setExpandedRowKeys(expanded ? [record._id] : []);
                        },
                    }}
                />
            </TCard>
        </>
    );
};

export default ErrorsTable;
