import styled from 'styled-components';

export const PageHeaderStyled = styled.section`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
`;

export const PageHeaderHead = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;
export const PageHeaderBody = styled.div`
    display: flex;
    flex-direction: row;
`;
export const PageHeaderFooter = styled.div``;

export const BackButtonStyled = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    background: white;
    width: 40px;
    height: 40px;
    margin-right: 1rem;
    cursor: pointer;
    border: 1px solid #2d3d76;
    align-self: flex-start;
    svg {
        width: 32px;
        height: 32px;
    }
`;

export const BreadcrumbStyled = styled.ul`
    display: flex;
    list-style: none;
    padding: 0px;
    margin: 0px;
    text-transform: uppercase !important;
    li {
        font-weight: 500;
        opacity: 0.8;
        &:after {
            content: '/';
            padding: 0px 0.6rem;
        }
        &:last-child {
            opacity: 1;
            font-weight: 600 !important;
            &:after {
                content: '';
                padding: 0px;
            }
        }
    }
    a {
        text-decoration: none;
        color: unset;
    }
`;

export const PageTitleStyled = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0px;
    margin-bottom: 0.5rem;
    span {
        margin-right: 12px;
    }
`;

export const PageHeaderTags = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 0.4rem;
`;

export const PageSubtitleStyled = styled.small`
    font-size: 0.9rem;
    font-weight: 500;
`;

export const PageActionsStyled = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;
    * {
        margin-right: 0.625rem;
        opacity: 0.8;
        &:last-child {
            margin-right: 0px;
            opacity: 1;
        }
    }
`;
