import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getPickupPreferenceByMerchant = createAsyncThunk(
    'pickupPreference/getOne',
    async (state) => {
        try {
            const response = await AndesClient.get(`/settings/pickup/merchant`);
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const getPickupPreferenceByMerchantSlice = createSlice({
    name: 'getPickupPreferenceByMerchant',
    initialState: {
        loading: false,
        data: {},
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [getPickupPreferenceByMerchant.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getPickupPreferenceByMerchant.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
                success: true,
            };
        },
        [getPickupPreferenceByMerchant.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
                success: false,
            };
        },
    },
});

export default getPickupPreferenceByMerchantSlice.reducer;
