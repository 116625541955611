import { defaultColumnsView as preparationsColumns } from 'pages/PreparationsV2/config';
import { defaultColumnsView as pendingArrivalsColumns } from 'pages/PendingArrivalsV2/config';

const defaultColumnsViewOrders = [
    {
        name: 'remote_order_id',
        label: 'Pedido',
        status: true,
        position: 1,
    },
    {
        name: 'sales_channel',
        label: 'Canal',
        status: true,
        position: 2,
    },
    {
        name: 'createdAt',
        label: 'Fecha de creación',
        status: true,
        position: 3,
    },
    {
        name: 'promised_date',
        label: 'Plazo límite',
        status: true,
        position: 4,
    },
    {
        name: 'custom_promised_date',
        label: 'Promesa de entrega',
        status: true,
        position: 5,
    },
    {
        name: 'promised_date_range',
        label: 'Horario de entrega',
        status: true,
        position: 6,
    },
    {
        name: 'fulfillment_status',
        label: 'Estado',
        status: true,
        position: 7,
    },
    {
        name: 'delivery_method',
        label: 'Tipo de entrega',
        status: true,
        position: 8,
    },
    {
        name: 'assigned_courier',
        label: 'Operador logístico',
        status: true,
        position: 9,
    },
    {
        name: 'fulfillments',
        label: 'Fulfillment',
        status: true,
        position: 10,
    },
    {
        name: 'customer',
        label: 'Cliente',
        status: true,
        position: 11,
    },
    {
        name: 'customer_document',
        label: 'Documento de identidad',
        status: true,
        position: 13,
    },
    {
        name: 'shipping_address',
        label: 'Dirección de entrega',
        status: true,
        position: 14,
    },
    {
        name: 'delivery_date',
        label: 'Fecha de retiro',
        status: true,
        position: 15,
    },
    {
        name: 'line_items',
        label: 'Unidades',
        status: true,
        position: 16,
    },
    {
        name: 'location_id',
        label: 'Tienda',
        status: true,
        position: 17,
    },
    {
        name: 'total_price',
        label: 'Total',
        status: true,
        position: 18,
    },
];

export const defaultColumnsView = {
    orders: defaultColumnsViewOrders,
    preparations: preparationsColumns,
    pendingArrivals: pendingArrivalsColumns,
};
