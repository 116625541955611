export const notValidDeliveryMethod = (deliveryMethod, preparationCd) => {
    if (deliveryMethod !== 'shipping' && !preparationCd?.cd_location)
        throw new Error('not valid delivery method');
};

export const notValidStatusFulfillment = (fulfillmentStatus) => {
    if (
        ![
            'open',
            'unfulfilled',
            'in_progress',
            'success',
            'ready_for_deliver',
            'fulfilled',
            'partially_fulfilled',
            'sac',
        ].includes(fulfillmentStatus)
    )
        throw new Error('not valid status fulfillment');
};

export const haveLabel = (labels) => {
    if (labels?.pdf) throw new Error('have label');
};

export const notHaveIncidenceValid = (incidences) => {
    if (!incidences?.length) return;

    const manualIncidenceNotExists = incidences?.some(
        (manualIncidence) =>
            (manualIncidence.incidence_type === 'failed_delivery' ||
                manualIncidence.incidence_type ===
                'incident_with_order_delivery_by_logistics_operator') &&
            manualIncidence.status === 'open'
    );

    if (!manualIncidenceNotExists) throw new Error('not have incidence valid');
};

export const verifyDisabledButton = ({
    order_ids,
    orders,
    fulfillment_id,
    order_id,
    logistic_id,
}) => {
    if (!orders?.length) return false;

    try {
        if (order_ids?.length) {
            const ordersFilter = orders?.filter((order) => order_ids.includes(order._id));
            for (const order of ordersFilter) {
                if (order.fulfillment_status === 'sac')
                    notHaveIncidenceValid(order?.manual_incidences);

                const filterFulfillments = order?.fulfillments?.filter((fulfillment) => {
                    try {
                        notValidDeliveryMethod(
                            fulfillment?.delivery_method,
                            fulfillment?.preparationcd_transfer_pickupstore
                        );

                        notValidStatusFulfillment(fulfillment?.status);

                        haveLabel(fulfillment?.labels);
                    } catch (error) {
                        return false;
                    }

                    return true;
                });
                const hasLogisticsInfo = order?.logistics_info?.length > 0 && order?.logistics_info?.every((logistic) => !!logistic?._id);
                const logisticsInfo = hasLogisticsInfo ? order?.logistics_info : [];

                const filterLogistics = logisticsInfo?.filter((logistic) => {
                    try {
                        notValidDeliveryMethod(
                            logistic?.delivery_method,
                            logistic?.preparationcd_transfer_pickupstore
                        );
                    } catch (error) {
                        return false;
                    }

                    return true;
                });

                if (!hasLogisticsInfo && !order?.fulfillments?.length) {
                    const allowedStatus = ['open', 'unfulfilled', 'in_progress', 'partially_fulfilled'];

                    if (!allowedStatus.includes(order?.fulfillment_status)) throw new Error('validations failed');
                }

                if (
                    (order?.fulfillments?.length &&
                        filterFulfillments?.length !== order?.fulfillments?.length) ||
                    (!order?.fulfillments?.length &&
                        logisticsInfo?.length &&
                        filterLogistics?.length !== logisticsInfo?.length)
                )
                    throw new Error('validations failed');
            }
        } else if (order_id) {
            const order = orders?.[0];

            if (order.fulfillment_status === 'sac') notHaveIncidenceValid(order?.manual_incidences);

            const filterFulfillments = order?.fulfillments?.filter((fulfillment) => {
                try {
                    notValidDeliveryMethod(
                        fulfillment?.delivery_method,
                        fulfillment?.preparationcd_transfer_pickupstore
                    );

                    notValidStatusFulfillment(fulfillment?.status);

                    haveLabel(fulfillment?.labels);
                } catch (error) {
                    return false;
                }

                return true;
            });

            const filterLogistics = order?.logistics_info?.filter((logistic) => {
                try {
                    notValidDeliveryMethod(
                        logistic?.delivery_method,
                        logistic?.preparationcd_transfer_pickupstore
                    );
                } catch (error) {
                    return false;
                }

                return true;
            });

            if (
                (order?.fulfillments?.length &&
                    filterFulfillments?.length !== order?.fulfillments?.length) ||
                (!order?.fulfillments?.length &&
                    order?.logistics_info?.length &&
                    filterLogistics?.length !== order?.logistics_info?.length)
            )
                throw new Error('validations failed');
        } else if (fulfillment_id) {
            const order = orders?.[0];
            const fulfillment = order?.fulfillments.find(
                (f) => f._id === fulfillment_id || f.id === fulfillment_id
            );

            notValidDeliveryMethod(
                fulfillment?.delivery_method,
                fulfillment?.preparationcd_transfer_pickupstore
            );

            notValidStatusFulfillment(fulfillment?.status);

            haveLabel(fulfillment?.labels);

            if (order?.fulfillment_status === 'sac')
                notHaveIncidenceValid(order?.manual_incidences);
        } else if (logistic_id) {
            const logistic = orders?.[0].logistics_info.find(
                (f) => f._id === fulfillment_id || f.id === fulfillment_id
            );

            notValidDeliveryMethod(
                logistic?.delivery_method,
                logistic?.preparationcd_transfer_pickupstore
            );
        }
    } catch (error) {
        console.log(error);
        return true;
    }

    return false;
};
