import { Form, Modal, Input, Space } from 'antd';
import FormStyled from 'components/Utils/Form';
import getLabel from 'helpers/labels';
import { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { useUpdateOrderCustomerMutation } from 'redux/features/Orders/updateOrderCustomer';
import styled from 'styled-components';
import { KeyValueWrapper } from './styles';

const SubTitle = styled.span`
    color: #2d3d76;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;

    button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 20px;
        color: inherit;
        padding: 0;
        // position: relative;
        // top: 40px;
    }
`;

const customerFieldsToShow = [
    'first_name',
    'last_name',
    'business_document',
    'business_activity',
    'business_name',
    'email',
    'phone',
];

export const BusinessForm = ({ orderId, data, refetch }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [updateOrderCustomer, updateOrderCustomerState] = useUpdateOrderCustomerMutation();
    const [customer, setCustomer] = useState({});
    const [customerData, setCustomerData] = useState({});

    const [form] = Form.useForm();

    useEffect(() => {
        setCustomer(data?.customer);
    }, [data]);

    const handleEditClick = () => {
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const handleOk = () => {
        const customerUpdateData = form.getFieldsValue();

        updateOrderCustomer({
            order_id: orderId,
            customer: customerUpdateData,
        });

        setIsModalVisible(false);
        setTimeout(() => {
            refetch();
        }, 1000);
    };

    const client_email = customer.email ?? data?.shipping_address?.email ?? null;
    const client_phone = customer.phone ?? data?.shipping_address?.phone ?? null;
    const client_full_name = `${customer.first_name} ${customer.last_name}`;

    useEffect(() => {
        setCustomerData(customer);

        if (updateOrderCustomerState.isSuccess) {
            setCustomerData(updateOrderCustomerState.originalArgs.customer);
        }
    }, [updateOrderCustomerState, customer]);

    return (
        <div>
            <SubTitle>
                <span>Información de empresa (Factura)</span>
                <button type="button" onClick={handleEditClick}>
                    <FiEdit />
                </button>
            </SubTitle>
            <Space $size="15px" />
            <div style={{ paddingTop: '1rem' }}>
                <KeyValueWrapper>
                    <b>Nombre:</b>
                    <p>{client_full_name ?? 'Sin datos'}</p>
                </KeyValueWrapper>
                <KeyValueWrapper>
                    <b>RUT de la empresa:</b>
                    <p>{customer.business_document ?? 'Sin datos'}</p>
                </KeyValueWrapper>
                <Space $size="8px" />
                <KeyValueWrapper>
                    <b>Giro:</b>
                    <p
                        title={customer?.business_activity}
                        style={{ textOverflow: 'ellipsis', overflow: 'hidden', textWrap: 'nowrap' }}
                    >
                        {customer?.business_activity ?? 'Sin datos'}
                    </p>
                </KeyValueWrapper>
                <KeyValueWrapper>
                    <b>Razón social:</b>
                    <p>{customer?.business_name ?? 'Sin datos'}</p>
                </KeyValueWrapper>
                <KeyValueWrapper>
                    <b>Email:</b>
                    <p>{client_email ?? 'Sin datos'}</p>
                </KeyValueWrapper>
                <KeyValueWrapper>
                    <b>Teléfono:</b>
                    <p>{client_phone ? client_phone : 'Sin datos'}</p>
                </KeyValueWrapper>
            </div>
            <Modal
                title="Editar información de contacto"
                okText="Guardar cambios"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleModalClose}
                okButtonProps={{
                    style: {
                        backgroundColor: '#F27127',
                        color: 'white',
                        border: '1px solid #E79B09 ',
                    },
                }}
                cancelButtonProps={{
                    style: {
                        backgroundColor: 'none',
                        color: '#2D3D76',
                        border: '1px solid #2D3D76 ',
                    },
                }}
            >
                <FormStyled
                    name="customerContactForm"
                    layout="vertical"
                    initialValues={customerData}
                    form={form}
                >
                    {customerFieldsToShow.map((key) => {
                        const inputLabel = getLabel(key);

                        return (
                            <Form.Item
                                style={{ marginBottom: '1rem' }}
                                key={key}
                                name={key}
                                label={inputLabel?.label}
                            >
                                <Input placeholder={inputLabel?.placeholder} />
                            </Form.Item>
                        );
                    })}
                </FormStyled>
            </Modal>
        </div>
    );
};
