import { Divider, Skeleton } from 'antd';
import { ReactComponent as CloseIcon } from 'assets/close-icon.svg';
import { ReactComponent as InfoIcon } from 'assets/info-cleaner.svg';
import DrawerWithButton from 'components/DrawerWithButton';
import Flex from 'components/Utils/Flex';
import useGetManyOrdersByCustom from 'hooks/useGetManyOrdersByCustom';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toggleDrawer } from 'redux/features/Utils';
import AlertShareWarehouse from './AlertShareWarehouse';
import IncidenceForm from './IncidenceForm/index';
import ProblemOrders from './ProblemOrders';
import { TitleHeader } from './styled';

const CreateIncidenceDrawer = ({
    incidencesTypes = [],
    isMenuItem = false,
    selectedIds: selected_ids,
    searchFrom: search_from,
    orders: infoOrders,
}) => {
    // Obtener las ordenes seleccionadas a partir de los ids selccionados
    const [loadingGetOrders, orders] = useGetManyOrdersByCustom({
        search_from,
        selected_ids,
    });
    const dispatch = useDispatch();
    const [unCheckedList, setUnCheckedList] = useState([]);
    const [ordersSelected, setOrdersSelected] = useState([]);
    const [ordersCopy, setOrdersCopy] = useState([...orders]);
    const [selectedPackageCodes, setSelectedPackageCodes] = useState([]);
    const [unselectedOrders, setUnselectedOrders] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [isShareWarehouse, setIsShareWarehouse] = useState('none');
    const [isCloseIncidenceForm, setIsCloseIncidenceForm] = useState(false);
    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
        setOrdersCopy(orders);
        setOrdersSelected(orders?.filter((order) => checkedList.includes(order._id)));
    }, [orders, checkedList]);

    const closeDrawer = () => {
        setOrdersSelected([]);
        setIsShareWarehouse('none');
        setIsCloseIncidenceForm(false);
        dispatch(toggleDrawer('manual-incidence-order-drawer'));
        setIsCheckboxDisabled(false);
        setIsFormSubmitted(false);
        setOrdersCopy([...orders]);
        setSelectedPackageCodes([]);
    };

    const isAlertShareWarehouseShowing = () =>
        ordersSelected.length > 1 && isShareWarehouse === 'none';

    const disabledButtonDrawer = useMemo(() => {
        return infoOrders?.find((order) => {
            const findId =
                selected_ids.includes(order._id) ?? selected_ids.includes(order.order_id);
            if (!findId) return false;

            return (
                ['cancelled', 'anulated', 'archived'].includes(order?.order_status) ||
                ['cancelled', 'closed', 'archived'].includes(order?.fulfillment_status) ||
                ['cancelled', 'closed', 'archived'].includes(order?.status)
            );
        });
    }, [infoOrders, selected_ids]);

    return (
        <DrawerWithButton
            forceRender
            drawerProps={{
                title: (
                    <Flex alignItems="center" justifyContent="space-between">
                        <TitleHeader>Crear incidencia en pedido(s)</TitleHeader>
                        <div style={{ cursor: 'pointer' }} onClick={closeDrawer}>
                            <CloseIcon
                                style={{ color: '#2D3D76', width: '14px', height: '14px' }}
                            />
                        </div>
                    </Flex>
                ),
                name: 'manual-incidence-order-drawer',
                width: '457px',
                dontShowFooter: true,
                forceRender: true,
                maskClosable: false,
            }}
            buttonProps={{
                buttonText: `${
                    selected_ids && selected_ids.length > 1
                        ? `Agregar incidencias (${selected_ids.length})`
                        : 'Agregar incidencia'
                }`,
                isMenuItem: isMenuItem,
                closeDrawer: closeDrawer,
                disabled: disabledButtonDrawer,
            }}
            titleTooltip={
                disabledButtonDrawer
                    ? 'No es posible agregar una incidencia a un pedido cancelado'
                    : undefined
            }
        >
            {loadingGetOrders ? (
                <Skeleton active />
            ) : (
                <div className="manual-incidence-drawer">
                    {!loadingGetOrders && (
                        <div className="mb-5 p-1">
                            <ProblemOrders
                                ordersCopy={ordersCopy}
                                ordersIds={orders && orders.map((order) => order._id)}
                                setUnselectedOrders={setUnselectedOrders}
                                isShareWarehouse={isShareWarehouse}
                                setIsShareWarehouse={setIsShareWarehouse}
                                isCloseIncidenceForm={isCloseIncidenceForm}
                                setIsCloseIncidenceForm={setIsCloseIncidenceForm}
                                isCheckboxDisabled={isCheckboxDisabled}
                                setIsCheckboxDisabled={setIsCheckboxDisabled}
                                isFormSubmitted={isFormSubmitted}
                                unselectedOrders={unselectedOrders}
                                setCheckedList={setCheckedList}
                                checkedList={checkedList}
                                unCheckedList={unCheckedList}
                                setUnCheckedList={setUnCheckedList}
                            />
                        </div>
                    )}

                    {!ordersSelected.length && (
                        <>
                            <div style={{ paddingLeft: 5, paddingRight: 5 }}>
                                <Divider />
                            </div>
                            <div className="flex">
                                <InfoIcon
                                    style={{
                                        color: '#037FB9',
                                        width: '2em',
                                        height: '2em',
                                        marginLeft: 20,
                                    }}
                                />
                                <span
                                    className="ml-4"
                                    style={{ fontSize: 14, marginTop: 5, fontWeight: 450 }}
                                >
                                    Para continuar con la creación de la incidencia debes
                                    seleccionar al menos un pedido. <br /> En caso de seleccionar
                                    más de uno deben compartir la incidencia.
                                </span>
                            </div>
                        </>
                    )}

                    {isAlertShareWarehouseShowing() && (
                        <AlertShareWarehouse setIsShareWarehouse={setIsShareWarehouse} />
                    )}

                    {!!ordersSelected.length && !isAlertShareWarehouseShowing() && (
                        <div>
                            <IncidenceForm
                                ordersCopy={ordersCopy}
                                selectedIds={selected_ids}
                                setOrdersCopy={setOrdersCopy}
                                ordersSelected={ordersSelected}
                                setIsCloseIncidenceForm={setIsCloseIncidenceForm}
                                incidencesTypes={incidencesTypes}
                                setIsCheckboxDisabled={setIsCheckboxDisabled}
                                setIsFormSubmitted={setIsFormSubmitted}
                                closeDrawer={closeDrawer}
                                setCheckedList={setCheckedList}
                                selectedPackageCodes={selectedPackageCodes}
                                setSelectedPackageCodes={setSelectedPackageCodes}
                            />
                        </div>
                    )}
                </div>
            )}
        </DrawerWithButton>
    );
};

export default CreateIncidenceDrawer;
