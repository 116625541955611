import { createSlice } from '@reduxjs/toolkit';

const sortOrders = createSlice({
    name: 'sortOrders',
    initialState: { sortBy: 'promised_date', sortValue: 'descend' },
    reducers: {
        changeParamsSO: (state, { payload }) => {
            const { sortBy, sortValue } = payload;
            return {
                ...state,
                sortBy,
                sortValue,
            };
        },
        restartParamSO: () => {
            return {};
        },
        changeSortValueSO: (state, { payload }) => {
            return {
                ...state,
                sortValue: payload,
            };
        },
        changeSortBySO: (state, { payload }) => {
            return {
                ...state,
                sortBy: payload,
            };
        },
    },
});
export const { changeParamsSO, restartParamSO, changeSortValueSO, changeSortBySO } =
    sortOrders.actions;
export default sortOrders.reducer;
