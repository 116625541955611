import styled, { css } from 'styled-components';

export const HeaderTitle = styled.div`
    color: #2d3d76;
    font-weight: 700;
    font-size: 24px;
`;

export const HeaderLabelTitle = styled.div`
    color: #2d3d76;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-right: 6px;
`;
export const HeaderLabelBody = styled.div`
    color: #2d3d76;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    display: flex;
    flex-direction: row;
`;
export const HeaderTagsBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3px;
`;
export const HeaderLabelWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    & .ant-typography-copy {
        top: -4px;
    }
`;

export const HeaderBody = styled.div`
    padding: 1rem;
    overflow: hidden;
    transition: height 200ms;
    display: grid;
    grid-template-columns: 40px 200px 0.9fr 300px 200px;
    grid-auto-flow: row;
    gap: 1rem;

    ${({ $showMore }) =>
        !$showMore &&
        css`
            .order-page__header-location,
            .order-page__header-sales-channel,
            .order-page__header-date,
            .order-page__header-responsables,
            .order-page__files-attachments,
            // .order-page__header-status,
            .order-page__header-tags,
            .order-page__header-promised_date {
                display: none;
            }
        `}
    .order-page__header-back-button {
        grid-row: 1;
        grid-column: 1;
    }

    .order-page__header-title {
        grid-row: 1;
        // grid-column: 2;
        grid-column-start: 2;
        grid-column-end: 4;
        display: flex;
        gap: 25px;
    }

    .order-page__header-id {
        grid-row: 1;
        grid-column-start: 3;
        grid-column-end: 5;
    }

    .order-page__header-date {
        grid-row: 2;
        grid-column: 2;
    }

    .order-page__header-location {
        grid-row: 3;
        grid-column: 3 / span 3;
    }

    .order-page__header-sales-channel {
        grid-row: 3;
        grid-column: 2;
    }
    .order-page__header-promised_date {
        grid-row: 2;
        grid-column: 3 / span 3;
    }

    .order-page__header-status {
        grid-row: 1;
        grid-column: 4;
        font-size: 12px;
        .header-tag-status-styles {
            margin-left: 4px;
            background-color: #f5f5f5;
            border: 1px solid #2d3d76;
            color: #142c4a;
            font-size: 10px;

            .header-table-icon-status {
                margin-left: 8px;
            }
            .icon-sac {
                color: #b00303;
            }
            .icon-header-delivered-to-courier {
                color: #2d3d76;
                margin-left: 8px;
                font-weight: 500;
                font-size: 12px;
            }
            .header-table-icon-cancelled {
                margin-left: 8px;
                font-size: 14px;
            }
            .fulfilled-icon {
                font-size: 10px;
                width: 14px;
            }
            .header-table-icon-delivered {
                margin-left: 7px;
                color: #2d3d76 !important;
                width: 13px;
                height: 13px;
            }
            .in-progress-icon {
                margin-left: 8px;
                color: #2d3d76 !important;
                font-size: 12px;
            }
            .partially_fulfilled-icon {
                font-size: 10px;
                width: 14px;
            }
        }
    }

    .order-page__header-responsables {
        grid-row: 4 !important;
        grid-column: 3 / span 3;
    }

    .order-page__files-attachments {
        grid-row: 4 !important;
        grid-column: 2;
    }

    .order-page__header-tags {
        grid-row: 3/5;
        grid-column: 4;
    }

    .order-page__header-action-button {
        grid-row: 1;
        grid-column: 5;
    }

    @media (max-width: 1083px) {
        grid-template-columns: 50px 1.5fr 1fr;
        gap: 0.6rem;
        ${({ $showMore }) =>
            !$showMore &&
            css`
                .order-page__header-location,
                .order-page__header-sales-channel,
                .order-page__header-date,
                .order-page__header-responsables,
                .order-page__files-attachments,
                .order-page__header-status,
                .order-page__header-tags,
                .order-page__header-promised_date {
                    display: none;
                }
            `}

        .order-page__header-back-button {
            grid-row: 1;
            grid-column: 1;
        }

        .order-page__header-title {
            grid-row: 1;
            grid-column: 2 / span 2;
        }

        .order-page__header-id {
            grid-row: 3;
            grid-column: 2;
        }

        .order-page__header-date {
            grid-row: 3;
            grid-column: 2;
        }
        .order-page__header-promised_date {
            grid-row: 4;
            grid-column: 2;
        }

        .order-page__header-location {
            grid-row: 5;
            grid-column: 2;
        }

        .order-page__header-sales-channel {
            grid-row: 6;
            grid-column: 2;
        }

        .order-page__files-attachments {
            grid-row: 5;
            grid-column: 3;
        }

        .order-page__header-status {
            grid-row: 4;
            grid-column: 3;
        }

        .order-page__header-responsables {
            grid-row: 6;
            grid-column: 3;
        }

        .order-page__header-tags {
            grid-row: 4;
            grid-column: 3;
            display: flex;
        }

        .order-page__header-action-button {
            grid-row: 1;
            grid-column: 3;
            // justify-self: center;
            margin-left: 5rem;
        }

        @media (max-width: 1084px) {
            grid-template-columns: 50px 1.5fr 1fr;
            gap: 0.6rem;
            .order-page__header-responsables {
                grid-row: 5 !important;
                grid-column: 3;
            }

            .order-page__files-attachments {
                grid-row: 6 !important;
                grid-column: 3;
            }
        }
    }
`;

export const HeaderCollapsible = styled.div`
    border: 1px solid #e4e8ec;
`;

export const HeaderHomeStyle = styled.div`
    font-size: 16px;
    color: #7998a7;
    margin-bottom: 30px;
`;
export const HeaderFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    background: #f5f5f5;
    color: #2d3d76;
    height: 36px;
    transition: 0.5s;
    transform: rotate(0deg);

    svg {
        font-size: 1rem;
        ${({ $showMore }) =>
            $showMore &&
            css`
                transform: rotate(180deg);
            `}
    }
    u {
        font-size: 12px;
    }
`;

export const BackButton = styled.button`
    background-color: white;
    border: 1px solid #2d3d76;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 36px;

    svg {
        font-size: 1.6rem;
    }
`;

export const ResponsablesAvatar = styled.div`
    display: flex;
    align-items: center;
    margin-right: -8px;
    position: relative;

    .avatar-group-responsable {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: ${(props) => (props.backGroundColor ? props.backGroundColor : 'red')};
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        position: relative;
        cursor: pointer;
    }

    .avatar-group-responsable:last-child {
        margin-right: 0;
    }

    /* Estilos del tooltip */
    .avatar-group-responsable::before {
        content: attr(data-tooltip);
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 5px 10px;
        border-radius: 5px;
        white-space: nowrap;
        font-size: 12px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.2s ease;
    }

    .avatar-group-responsable:hover::before {
        opacity: 1;
    }
`;

export const TagsHeader = styled.div`
    display: inline-block;
    padding: 3px 8px;
    border: 1px solid #536d8f;
    color: #2d3d76;
    background-color: white;
    border-radius: 20px;
    font-weight: 400;
    font-size: 12px;
`;
