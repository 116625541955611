import { forwardRef } from 'react';
import { Select as AntdSelect } from 'antd';

const Select = forwardRef(({ children, ...props }, ref) => {
    return (
        <AntdSelect ref={ref} {...props}>
            {children}
        </AntdSelect>
    );
});

export default Select;
