import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import { deleteProfileFromStore } from './getAllProfiles';
import toast from 'react-hot-toast';

export const deleteProfile = createAsyncThunk('profile/delete', async (profileToDelete, state) => {
    try {
        const deleteProfileForID = async (id) => {
            const res = await toast.promise(AndesClient.delete(`/profiles/delete/${id}`), {
                loading: 'Eliminando perfil...',
                success: 'Perfil eliminado con éxito.',
            });
            if (res.status === 200) {
                state.dispatch(deleteProfileFromStore(id));
            }
            return res.data;
        };

        if (typeof profileToDelete === 'string') {
            await deleteProfileForID(profileToDelete);
        }

        if (Array.isArray(profileToDelete)) {
            for await (const id of profileToDelete) {
                await deleteProfileForID(id);
            }
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const deleteProfileSlice = createSlice({
    name: 'deleteProfile',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [deleteProfile.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [deleteProfile.fulfilled]: (state) => {
            return {
                ...state,
                success: true,
                loading: false,
            };
        },
        [deleteProfile.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default deleteProfileSlice.reducer;
