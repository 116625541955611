import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { addIntegrationToStore } from './getAllIntegration';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const createIntegration = createAsyncThunk('integrations/create', async (data, state) => {
    try {
        const res = await AndesClient.post(`/integrations/create`, data);
        if (res.status === 200) {
            toast.success('Integración creada con éxito.');
            state.dispatch(addIntegrationToStore(res.data));
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const createIntegrationSlice = createSlice({
    name: 'createIntegrations',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createIntegration.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createIntegration.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createIntegration.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createIntegrationSlice.reducer;
