export const ERROR_CODE_DIC = {
    MANIFEST_DTE_NOT_FOUND: 'Alguno de los documentos no tiene DTE generado',
    BATCH_DELIVER_NOT_FOUND: 'Entrega de lote no encontrada',
    MULTIPLE_BATCH_DELIVER_NOT_FOUND: 'Entrega de múltiples lotes no encontrada',
    PACKAGE_NOT_FOUND: 'Paquete no encontrado',
    PICKUP_PREFERENCE_NOT_FOUND: 'Preferencia de recogida no encontrada',
    BATCH_DELIVER_IS_STATUS_DELIVERED: 'La entrega del lote es estado entregado',
    FULFILLMENT_IT_WAS_ALREADY_SEND: 'Cumplimiento ya fue enviado',
    DOES_NOT_HAVE_ACTIVE_REESCANNING_SHIPPING: 'No tiene envío re escaneo activo',
    DOES_NOT_HAVE_ACTIVE_REESCANNING_PICKUP: 'No tiene re escaneo activo recogida',
    MORE_IS_NEEDED_ONE_BATCH_DELIVER: 'Se necesita más entrega de un lote',
    MISSING_PACKAGES_TO_RE_SCAN: 'Paquetes faltantes para re escanear',
};
