import TCard from 'components/TCard';
import Flex from 'components/Utils/Flex';
import { Button } from '@andescommercecloud/beflow-design-system';
import IncidenceSelector from './IncidenceSelector';
import { useSelector } from 'react-redux';
import WarehousesAvailable from './WarehouresAvailables';
import WarehousesList from './WarehousesList';
import ChangeConfirmation from './ChangeConfirmation';
import { useMemo } from 'react';
import { useLocation } from '../hooks/useLocation';

const ChangeStoreIncidence = ({ order, handleBackStep, currentStep, fulfillmentId }) => {
    const { step, warehouseType } = useSelector((state) => state.changeStore);

    const lineItems = useMemo(() => {
        if (!fulfillmentId) {
            return order.line_items?.map((item) => ({
                _id: item._id,
                total_price: item.total_price,
                sku: item.sku,
                quantity: item.quantity,
            }));
        }
        return null;
    }, [order, fulfillmentId]);

    const fulfillmentSelected = useMemo(() => {
        const logistic = !fulfillmentId
            ? order?.logistics_info?.[0]
            : order?.logistics_info?.find((f) => String(f._id) === String(fulfillmentId));
        const logisticSelected = {
            ...logistic,
            origin: {
                _id: order?.location_id,
                id: order?.location_id,
                name: order?.location_id?.name,
            },
        };
        if (!fulfillmentId) return order?.fulfillments?.[0] ?? logisticSelected;
        return (
            order?.fulfillments?.find((f) => String(f._id) === String(fulfillmentId)) ??
            logisticSelected
        );
    }, [fulfillmentId, order]);

    const deliveryMethod = useMemo(() => {
        return fulfillmentSelected?.preparationcd_transfer_pickupstore?.cd_location
            ? 'pickup'
            : fulfillmentSelected?.delivery_method ?? order?.delivery_method;
    }, [fulfillmentSelected?.delivery_method, order?.delivery_method]);

    const { data } = useLocation({
        lineItems,
        fulfillmentId,
        order,
        fulfillmentSelected,
        searchTerms: '',
    });

    return (
        <TCard>
            {!step && (
                <IncidenceSelector deliveryMethod={deliveryMethod} hasStore={!!data?.length} />
            )}
            {(step === 'warehouse-available' || (step === 'warehouse-list' && warehouseType)) && (
                <WarehousesAvailable
                    fulfillmentId={fulfillmentId}
                    order={order}
                    handleBackStep={handleBackStep}
                    currentStepForComeback={currentStep}
                />
            )}
            {step === 'warehouse-list' && (
                <WarehousesList
                    lineItems={lineItems}
                    fulfillmentId={fulfillmentId}
                    order={order}
                    handleBackStep={handleBackStep}
                    currentStepForComeback={currentStep}
                />
            )}
            {step === 'change-confirmation' && (
                <ChangeConfirmation
                    fulfillmentId={fulfillmentId}
                    order={order}
                    handleBackStep={handleBackStep}
                    currentStepForComeback={currentStep}
                />
            )}
            {step === 'warehouse-available' && !warehouseType && (
                <Flex direction="row" wrap width="100%" columnGap="0.8rem" justifyContent="end">
                    <Button type="primary" variation="secondary" ghost onClick={handleBackStep}>
                        Volver
                    </Button>
                </Flex>
            )}
        </TCard>
    );
};
export default ChangeStoreIncidence;
