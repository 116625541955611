import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useActionToggleDrawer = (actionFunction, drawerName, stateToTriggerAction) => {
    const [actioned, setActioned] = useState(false);
    const { drawer } = useSelector((state) => state.utils);
    useEffect(() => {
        if (drawer[drawerName] === stateToTriggerAction) {
            actionFunction();
            setActioned(true);
        }
    }, [drawer]);

    return [actioned];
};

export default useActionToggleDrawer;
