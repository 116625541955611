import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';
import { isML } from 'helpers/isML';
import getFromEnv from 'helpers/getFromEnv';
const stockApi = {
    dev: 'https://api-pim-demo.be-flow.com/', //'https://api-pim-demo.be-flow.com/',
    demo: 'https://api-pim-demo.be-flow.com/',
    prod: 'https://api-pim.be-flow.com/',
};

export const locationsApi = createApi({
    reducerPath: 'locations',
    baseQuery,
    tagTypes: ['locations'],
    endpoints: (builder) => ({
        getLocationsByItems: builder.query({
            method: 'post',
            query: (body) => {
                const userLogged = JSON.parse(localStorage.getItem('userLogged') || '{}');
                const { availability, ...otherBody } = body;
                if (isML(userLogged?.merchant_id?._id)) {
                    return {
                        url: `${
                            stockApi[getFromEnv('REACT_APP_STAGE')]
                        }products/info/stock_availability`,
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: {
                            availability,
                        },
                    };
                }

                return {
                    url: '/stores-warehouses/getStoresByItems',
                    method: 'post',
                    body: otherBody,
                };
            },
            providesTags: ['locations'],
            transformResponse: (response) => {
                const transform = Array.from(response).map((item) => ({
                    _id: item?._id ?? item?.oms_location_id,
                    functions: item?.functions ?? ['pickup', 'shipping'],
                    is_enabled: item?.is_enabled ?? true,
                    name: item?.name ?? item?.location_name,
                    address1: item?.address1 ?? '',
                    address2: item?.address2 ?? '',
                    province_name: item?.province_name ?? '',
                    supply_store: item?.supply_store
                }));
                return transform;
            },
        }),
        lazyLocationsByItems: builder.mutation({
            method: 'post',
            query: (body) => {
                const userLogged = JSON.parse(localStorage.getItem('userLogged') || '{}');
                const { availability, ...otherBody } = body;
                if (isML(userLogged?.merchant_id?._id)) {
                    return {
                        url: `${
                            stockApi[getFromEnv('REACT_APP_STAGE')]
                        }products/info/stock_availability`,
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: {
                            availability,
                        },
                    };
                }

                return {
                    url: `/stores-warehouses/getStoresByItems`,
                    method: 'post',
                    body: otherBody,
                };
            },
            providesTags: ['locations'],
            transformResponse: (response) => {
                const transform = Array.from(response).map((item) => ({
                    _id: item?._id ?? item?.oms_location_id,
                    functions: item?.functions ?? ['pickup', 'shipping'],
                    is_enabled: item?.is_enabled ?? true,
                    name: item?.name ?? item?.location_name,
                    address1: item?.address1 ?? '',
                    address2: item?.address2 ?? '',
                    province_name: item?.province_name ?? '',
                    supply_store: item?.supply_store
                }));
                return transform;
            },
        }),
    }),
});

export const { useGetLocationsByItemsQuery, useLazyLocationsByItemsMutation } = locationsApi;
