import styled from 'styled-components';

const IFStyled = styled.div`
    display: ${(props) => (props.condition ? '' : 'none !important')};
`;

const IF = ({ condition, children, preventRender }) => {
    if (preventRender) {
        return condition ? children : null;
    }
    return <IFStyled condition={condition}>{children}</IFStyled>;
};

export default IF;
