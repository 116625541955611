import { Skeleton } from 'antd';
import FormModal from 'components/Modal/FormModal';
import Tag from 'components/Tag';
import Flex from 'components/Utils/Flex';
import MoneyFormat from 'helpers/MoneyFormat';
import { formatRut } from 'helpers/fomatRut';
import { tagsOrder } from 'helpers/formatedTags';
import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';
import EditAddressForm from 'pages/Order/components/Forms/EditAddressForm';
import { useEffect, useState } from 'react';
import { FiEdit, FiShoppingBag } from 'react-icons/fi';
import { MdOutlineLocalShipping } from 'react-icons/md';
import { ModalOrderDataBank } from '../OrderBankData/ModalOrderDataBank';
import { FulfillmentSummary } from '../ProductTable/fulfillmentSummary';
import { ClientContact } from './clientContact';
import {
    ButtonEditContent,
    HeadingText,
    KeyValueWrapper,
    KeyValueWrapperTags,
    OrderDataLayout,
    OrderDataSectionWrapper,
    Space,
    SubTitle,
    TagDeliveryMethodStyle,
    Title,
} from './styles';
import { BusinessForm } from './BusinessForm';

const paymentStatusTranslations = {
    paid: 'Exitoso',
    pending: 'Pendiente',
    refunded: 'Reembolsado',
    // agregar los posibles estados de pago
};

const OrderData = ({ data, infoSelected, loading, refetch, setInfoSelected }) => {
    const [dataToShow, setDataToShow] = useState({});
    const [shippingTypeData, setShippingTypeData] = useState({
        _id: data?._id,
        type: 'order',
    });
    const [isOpenModalBankInfo, setIsOpenModalBankInfo] = useState(false);
    // const [hasFulfillment, setHasFulfillment] = useState(true);

    const customerObject = data?.customer || {};
    const client_full_name = `${customerObject.first_name} ${customerObject.last_name}`;
    const client_bank_full_name = `${data?.customer_bank?.first_name || ''} ${
        data?.customer_bank?.last_name || ''
    }`;
    const [permissions, isAdmin] = useGetCurrentPermissions();
    const isSac = permissions?.includes('sac');

    const client_email = customerObject.email ?? data?.shipping_address?.email ?? null;
    const client_phone = customerObject.phone ?? data?.shipping_address?.phone ?? null;

    const isBusiness = !!customerObject?.business_name;

    const [showModalEditShippingAddress, setShowModalEditShippingAddress] = useState(false);
    const [showModalEditBillingAddress, setShowModalEditBillingAddress] = useState(false);
    const otherProps = {
        isShipping: data?.delivery_method === 'shipping' || false,
        locationData: data?.location_id || {},
    };

    const editShippingAddressFormName = 'edit-order-shipping-address-form';
    const editShippingAddressModalName = 'edit-order-shipping-address-modal';

    const editBillingAddressFormName = 'edit-order-billing-address-form';
    const editBillingAddressModalName = 'edit-order-billing-address-modal';

    // console.log({ data });
    const hasDataBankForReturnMoney = !!data?.sales_channel?.has_bank_data_for_return_money;
    const hasLogPurposeAnnulmentRefundMoney = data?.logs?.some(
        (log) => log.other?.purpose_annulment === 'refund_money'
    );
    const allowShowBankData =
        (isAdmin || isSac) && hasDataBankForReturnMoney && hasLogPurposeAnnulmentRefundMoney;

    // console.log({ allowShowBankData, isAdmin, isSac, hasDataBankForReturnMoney });

    useEffect(() => {
        if (data.fulfillments.length !== 0) {
            setInfoSelected(data?.fulfillments[0]?._id);
        } else {
            setInfoSelected(data?.logistics_info[0]?._id);
        }
    }, [data]);

    useEffect(() => {
        if (!infoSelected) return setDataToShow(data);
        let fulfillmentSelected = data?.fulfillments?.find(
            (f) => String(f._id) === String(infoSelected)
        );

        if (!fulfillmentSelected && data?.fulfillments.length === 1) {
            fulfillmentSelected = data?.fulfillments?.[0];
        }

        if (!fulfillmentSelected) setDataToShow(data);

        if (fulfillmentSelected) {
            setShippingTypeData({
                _id: fulfillmentSelected._id,
                type: 'fulfillment',
            });
            const regexOnlyNumbers = /[^\d]/g;
            const fulfillmentNumber =
                fulfillmentSelected?.name?.split('-')?.[1].replace(regexOnlyNumbers, '') ?? '';

            setDataToShow({
                ...fulfillmentSelected,
                fulfillment_number: fulfillmentNumber,
            });
        } else {
            const logisticSelected = data?.logistics_info?.find(
                (l) => String(l._id) === String(infoSelected)
            );

            if (logisticSelected) {
                setShippingTypeData({
                    _id: logisticSelected._id,
                    type: 'logistic',
                });
                setDataToShow({
                    ...logisticSelected,
                });
            }
        }

        const logisticsWithoutFulfillment = data?.logistics_info
            .filter(
                (logistic) =>
                    !data?.fulfillments?.some(
                        (fulfillment) => fulfillment?.logistic_id === logistic?._id
                    )
            )
            .map((logistic) => logistic._id);

        if (logisticsWithoutFulfillment?.length > 0 && data?.fulfillments?.length > 0) {
            let selectedData = data?.fulfillments?.find(
                (fulfillment) => String(fulfillment?._id) === String(infoSelected)
            );

            if (!selectedData) {
                selectedData = data?.logistics_info?.find(
                    (logistic) => String(logistic?._id) === String(infoSelected)
                );
            }

            if (selectedData) {
                setDataToShow(selectedData);
            } else {
                console.log('No se encontró ningún fulfillment o logística.');
            }
        }
    }, [data, infoSelected]);

    const handleClickEditShippingAddress = () => {
        setShowModalEditShippingAddress(true);
    };
    const handleClickEditBillingAddress = () => {
        setShowModalEditBillingAddress(true);
    };

    const isShipping =
        dataToShow?.delivery_method === 'shipping' || data?.delivery_method === 'shipping';
    const hasNoPickupLocation = !dataToShow?.preparationcd_transfer_pickupstore?.pickup_location;
    const hasPickupLocation = data?.preparationcd_transfer_pickupstore?.pickup_location;

    const addressType =
        isShipping && hasNoPickupLocation && !hasPickupLocation
            ? 'Dirección de despacho:'
            : 'Dirección de retiro:';

    return (
        <OrderDataLayout>
            <FormModal
                formComponent={
                    <EditAddressForm
                        otherProps={{
                            ...otherProps,
                            shippingTypeData: shippingTypeData,
                            // ...(dataToShow?.shipping_address && {
                            //     disableEverything: true,
                            // }),
                        }}
                        refetch={refetch}
                        setShowModalEditShippingAddress={setShowModalEditShippingAddress}
                    />
                }
                formProps={{
                    formName: editShippingAddressFormName,
                    initialValues: {
                        _id: data?._id,
                        ...(dataToShow?.shipping_address
                            ? {
                                  ...dataToShow?.shipping_address,
                              }
                            : {
                                  ...data?.shipping_address,
                              }),
                    },
                    mainObject: 'shipping_address',
                }}
                modalProps={{
                    visible: showModalEditShippingAddress,
                    modalName: editShippingAddressModalName,
                    title: `Editar dirección de despacho`,
                    width: 600,
                    okText: 'Guardar cambios',
                    // onOk: () => setShowModalEditShippingAddress(false),
                    onCancel: () => setShowModalEditShippingAddress(false),

                    okButtonProps: {
                        style: {
                            // ...(dataToShow?.shipping_address && {
                            //     display: 'none',
                            // }),
                            backgroundColor: '#F27127',
                            color: 'white',
                            border: '1px solid #E79B09 ',
                        },
                    },
                    cancelButtonProps: {
                        style: {
                            backgroundColor: 'none',
                            color: '#2D3D76',
                            border: '1px solid #2D3D76 ',
                        },
                    },
                }}
            />

            <FormModal
                formComponent={
                    <EditAddressForm
                        refetch={refetch}
                        setShowModalEditBillingAddress={setShowModalEditBillingAddress}
                    />
                }
                formProps={{
                    formName: editBillingAddressFormName,
                    initialValues: {
                        _id: data?._id,
                        ...data?.billing_address,
                    },
                    mainObject: 'billing_address',
                }}
                modalProps={{
                    visible: showModalEditBillingAddress,
                    modalName: editBillingAddressModalName,
                    title: `Editar dirección de facturación`,
                    width: 600,
                    okText: 'Guardar cambios',
                    onCancel: () => setShowModalEditBillingAddress(false),
                    // onOk: () => setShowModalEditBillingAddress(false),
                    okButtonProps: {
                        style: {
                            backgroundColor: '#F27127',
                            color: 'white',
                            border: '1px solid #E79B09 ',
                        },
                    },
                    cancelButtonProps: {
                        style: {
                            backgroundColor: 'none',
                            color: '#2D3D76',
                            border: '1px solid #2D3D76 ',
                        },
                    },
                }}
            />
            {loading ? (
                <Skeleton active />
            ) : (
                <>
                    <OrderDataSectionWrapper>
                        <Title>Cliente:</Title>

                        {isBusiness ? (
                            <BusinessForm orderId={data._id} data={data} refetch={refetch} />
                        ) : (
                            <>
                                <ClientContact orderId={data._id} data={data} refetch={refetch} />
                                <Space $size="15px" />
                                <div style={{ paddingLeft: '1rem' }}>
                                    <KeyValueWrapper>
                                        <b>Nombre:</b>
                                        <p>{client_full_name ?? 'Sin datos'}</p>
                                    </KeyValueWrapper>
                                    <KeyValueWrapper>
                                        <b>Documento (Rut/DNI):</b>
                                        <p>{formatRut(customerObject.document) ?? 'Sin datos'}</p>
                                    </KeyValueWrapper>
                                    <Space $size="8px" />
                                    <KeyValueWrapper>
                                        <b>Email:</b>
                                        <p>{client_email ? client_email : 'Sin datos'}</p>
                                    </KeyValueWrapper>
                                    <KeyValueWrapper>
                                        <b>Teléfono:</b>
                                        <p>{client_phone ? client_phone : 'Sin datos'}</p>
                                    </KeyValueWrapper>
                                </div>
                            </>
                        )}

                        <Space $size="15px" />
                        {allowShowBankData && (
                            <div>
                                <Flex justifyContent="space-between">
                                    <SubTitle>Datos bancarios:</SubTitle>
                                    <FiEdit
                                        onClick={() => setIsOpenModalBankInfo(true)}
                                        style={{ fontSize: '20px', cursor: 'pointer' }}
                                    />
                                </Flex>
                                <ModalOrderDataBank
                                    refetch={refetch}
                                    onSuccess={() => setIsOpenModalBankInfo(false)}
                                    onClose={() => setIsOpenModalBankInfo(false)}
                                    initialValues={data}
                                    open={isOpenModalBankInfo}
                                />
                                {!data?.customer_bank && (
                                    <div style={{ paddingLeft: '1rem' }}>
                                        <KeyValueWrapper>
                                            <b>Sin información</b>
                                        </KeyValueWrapper>
                                    </div>
                                )}
                                {data?.customer_bank && (
                                    <div style={{ paddingLeft: '1rem' }}>
                                        <Space $size="15px" />
                                        <KeyValueWrapper>
                                            <b>Nombre y apellido:</b>
                                            <p>{client_bank_full_name ?? 'Sin datos'}</p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <b>RUT:</b>
                                            <p>
                                                {formatRut(data?.customer_bank?.bank_account_rut) ??
                                                    'Sin datos'}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <b>Banco:</b>
                                            <p>{data?.customer_bank?.bank_name ?? 'Sin datos'}</p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <b>Tipo de cuenta:</b>
                                            <p>
                                                {data?.customer_bank?.bank_account_type ??
                                                    'Sin datos'}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <b>Email:</b>
                                            <p>{data?.customer_bank?.email ?? 'Sin datos'}</p>
                                        </KeyValueWrapper>
                                    </div>
                                )}
                            </div>
                        )}
                    </OrderDataSectionWrapper>

                    <OrderDataSectionWrapper>
                        <FulfillmentSummary orderData={data} name="order_total_summary" />
                    </OrderDataSectionWrapper>

                    <OrderDataSectionWrapper>
                        <Title>Tipo de entrega:</Title>
                        <Space $size="4px" />
                        {/* {data?.delivery_method} */}

                        <HeadingText>
                            {dataToShow?.fulfillment_number === '0' ? (
                                <b>Consolidación</b>
                            ) : (
                                <>
                                    <b>
                                        {dataToShow?.name?.includes('-F')
                                            ? `Fulfillment ${dataToShow?.fulfillment_number ?? 1}:`
                                            : ''}
                                    </b>
                                    <p>{dataToShow?.name ?? data?.name}</p>
                                </>
                            )}
                        </HeadingText>
                        <TagDeliveryMethodStyle>
                            <Tag className="tag-delivery_method">
                                {dataToShow?.preparationcd_transfer_pickupstore?.pickup_location ||
                                data?.preparationcd_transfer_pickupstore?.pickup_location ||
                                dataToShow?.delivery_method === 'pickup' ||
                                data?.delivery_method === 'pickup'
                                    ? 'Retiro'
                                    : data?.delivery_method === 'shipping'
                                    ? 'Despacho'
                                    : ''}

                                {dataToShow?.preparationcd_transfer_pickupstore?.pickup_location ||
                                data?.preparationcd_transfer_pickupstore?.pickup_location ||
                                dataToShow?.delivery_method === 'pickup' ||
                                data?.delivery_method === 'pickup' ? (
                                    <FiShoppingBag className="icon-delivery_method" />
                                ) : (
                                    <MdOutlineLocalShipping className="icon-delivery_method" />
                                )}
                            </Tag>
                        </TagDeliveryMethodStyle>

                        <Space $size="10px" />
                        {/* DIRECCIóN DE DESPACHO */}
                        <SubTitle>
                            <span>{addressType}</span>

                            <button onClick={handleClickEditShippingAddress}>
                                <FiEdit style={{ fontSize: '20px' }} />
                            </button>
                        </SubTitle>
                        <Space $size="10px" />
                        <ButtonEditContent>
                            <div style={{ paddingLeft: '1rem' }}>
                                {dataToShow?.delivery_method === 'shipping' ||
                                data?.delivery_method === 'shipping' ? (
                                    <>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.shipping_address?.first_name ??
                                                    data?.shipping_address?.first_name ??
                                                    ''}{' '}
                                                {dataToShow?.shipping_address?.last_name ??
                                                    data?.shipping_address?.last_name ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.shipping_address?.address1 ??
                                                    data?.shipping_address?.address1 ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.shipping_address?.number ??
                                                    data?.shipping_address?.number ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.shipping_address?.address2 ??
                                                    data?.shipping_address?.address2 ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.shipping_address?.city ??
                                                    data?.shipping_address?.city ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.shipping_address?.neighborhood ??
                                                    data?.shipping_address?.neighborhood ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.shipping_address?.province ??
                                                    data?.shipping_address?.province ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                    </>
                                ) : (
                                    <>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.destination?.address1 ??
                                                    data?.location_id?.address1 ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.destination?.address2 ??
                                                    data?.location_id?.address2 ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.destination?.city ??
                                                    data?.location_id?.city ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.destination?.province_code ??
                                                    data?.location_id?.province_code ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>
                                                {dataToShow?.destination?.country ??
                                                    data?.destination?.country ??
                                                    ''}
                                            </p>
                                        </KeyValueWrapper>
                                    </>
                                )}
                            </div>
                            {/* <button onClick={handleClickEditShippingAddress}>
                                <FiEdit />
                            </button> */}
                        </ButtonEditContent>
                        <Space $size="14px" />
                        {/* DIRECCIÓN DE FACTURACIÓN */}
                        <SubTitle>
                            <span className="title_billing_address">Dirección de facturación:</span>
                            {/* <ButtonEditContent> */}

                            <button onClick={handleClickEditBillingAddress}>
                                <FiEdit style={{ fontSize: '20px' }} />
                            </button>

                            {/* </ButtonEditContent> */}
                        </SubTitle>
                        <Space $size="10px" />
                        <ButtonEditContent>
                            <div style={{ paddingLeft: '1rem' }}>
                                <KeyValueWrapper>
                                    <p>
                                        {data?.billing_address?.first_name ?? ''}{' '}
                                        {data?.billing_address?.last_name ?? ''}
                                    </p>
                                </KeyValueWrapper>
                                <KeyValueWrapper>
                                    <p>{data?.billing_address?.address1 ?? ''}</p>
                                </KeyValueWrapper>
                                <KeyValueWrapper>
                                    <p>{data?.billing_address?.number ?? ''}</p>
                                </KeyValueWrapper>
                                <KeyValueWrapper>
                                    <p>{data?.billing_address?.address2 ?? ''}</p>
                                </KeyValueWrapper>
                                <KeyValueWrapper>
                                    <p>{data?.billing_address?.city ?? ''}</p>
                                </KeyValueWrapper>
                                <KeyValueWrapper>
                                    <p>{data?.billing_address?.neighborhood ?? ''}</p>
                                </KeyValueWrapper>
                                <KeyValueWrapper>
                                    <p>{data?.billing_address?.province ?? ''}</p>
                                </KeyValueWrapper>
                            </div>

                            {/* <button onClick={handleClickEditBillingAddress}>
                                <FiEdit />
                            </button> */}
                        </ButtonEditContent>

                        <Space $size="14px" />
                    </OrderDataSectionWrapper>
                    <OrderDataSectionWrapper>
                        <SubTitle>Datos del pago:</SubTitle>
                        <Space $size="10px" />
                        {data?.payments?.length > 0 ? (
                            data?.payments?.map((payment, i) => {
                                return (
                                    <div style={{ paddingLeft: '1rem' }} key={i}>
                                        <KeyValueWrapper>
                                            <p>Estado del pago:</p>
                                            <b>
                                                {paymentStatusTranslations[data?.payment_status] ??
                                                    data?.payment_status ??
                                                    0}
                                            </b>
                                        </KeyValueWrapper>
                                        <KeyValueWrapper>
                                            <p>Medio de pago:</p>

                                            <b>
                                                {payment?.cardtypename ??
                                                    payment?.tender_name ??
                                                    ''}{' '}
                                                {payment?.last_digits && '*****'}
                                                {payment?.last_digits}
                                                {[
                                                    String(
                                                        payment?.tender_type ?? ''
                                                    )?.toUpperCase(),
                                                ].includes('COD') && '(pago contra entrega)'}
                                            </b>
                                        </KeyValueWrapper>
                                        {payment.taken && payment.taken !== 0 && (
                                            <KeyValueWrapper>
                                                <p>Monto:</p>
                                                <b>{MoneyFormat(payment?.taken) ?? '$0'}</b>
                                            </KeyValueWrapper>
                                        )}
                                        {[
                                            String(payment?.tender_type ?? '')?.toUpperCase(),
                                        ].includes('COD') && (
                                            <KeyValueWrapper>
                                                <p>cscNotaCredito:</p>
                                                <b>{data?.transaction?.custom?.customCreditNote}</b>
                                            </KeyValueWrapper>
                                        )}
                                        <KeyValueWrapper>
                                            <p>Cuotas:</p>
                                            {/* <b>{data?.transaction?.custom?.installments ?? 0}</b> */}
                                            <b>{payment?.installments ?? 1}</b>
                                        </KeyValueWrapper>

                                        {data?.payments?.length > 1 && <hr />}
                                    </div>
                                );
                            })
                        ) : (
                            <>
                                <div style={{ paddingLeft: '1rem' }}>
                                    <KeyValueWrapper>
                                        <p>Estado del pago:</p>
                                        <b>
                                            {paymentStatusTranslations[data?.payment_status] ??
                                                data?.payment_status ??
                                                0}
                                        </b>
                                    </KeyValueWrapper>
                                    <KeyValueWrapper>
                                        <p>Medio de pago:</p>
                                        <b>
                                            {data?.transaction?.custom?.paymentSystemName ??
                                                data?.transaction?.custom?.tender_name ??
                                                data?.transaction?.system_name ??
                                                ''}{' '}
                                            {data?.last_digits && '***'}
                                            {data?.last_digits}
                                        </b>
                                    </KeyValueWrapper>

                                    <KeyValueWrapper>
                                        <p>Monto:</p>
                                        <b>{MoneyFormat(data?.total_price) ?? '$0'}</b>
                                    </KeyValueWrapper>

                                    <KeyValueWrapper>
                                        <p>Cuotas:</p>
                                        <b>{data?.transaction?.custom?.installments ?? 1}</b>
                                    </KeyValueWrapper>
                                </div>
                            </>
                        )}

                        <>
                            {data?.transaction?.custom?.customPaymentTypeCode ||
                            data?.transaction?.custom?.customPaymentAuthorizationCode ? (
                                <div style={{ paddingLeft: '1rem' }}>
                                    {data?.transaction?.custom?.customPaymentTypeCode && (
                                        <KeyValueWrapper>
                                            <p>Tipo de pago:</p>
                                            <b>{data.transaction.custom.customPaymentTypeCode}</b>
                                        </KeyValueWrapper>
                                    )}

                                    {data?.transaction?.custom?.customPaymentAuthorizationCode && (
                                        <KeyValueWrapper>
                                            <p>Código de autorización:</p>
                                            <b>
                                                {
                                                    data.transaction.custom
                                                        .customPaymentAuthorizationCode
                                                }
                                            </b>
                                        </KeyValueWrapper>
                                    )}
                                </div>
                            ) : (
                                ''
                            )}
                        </>
                    </OrderDataSectionWrapper>
                    <OrderDataSectionWrapper>
                        <SubTitle>Notas</SubTitle>
                        <div style={{ paddingLeft: '1rem' }}>
                            <KeyValueWrapperTags>
                                {data?.tags?.length > 0 ? (
                                    data?.tags?.map((tag, i) => (
                                        <li key={i}>{tagsOrder[tag] || tag}</li>
                                    ))
                                ) : (
                                    <p>Sin datos</p>
                                )}
                            </KeyValueWrapperTags>
                        </div>
                    </OrderDataSectionWrapper>
                </>
            )}
        </OrderDataLayout>
    );
};

export default OrderData;
