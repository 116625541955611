import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const activitiesApi = createApi({
    reducerPath: 'activities',
    baseQuery,
    endpoints: (builder) => ({
        getActivities: builder.query({
            method: 'get',
            query: (country_code) => `/helpers/getEconomicActivities/${country_code ?? 'CL'}`,
        }),
    }),
});

export const { useGetActivitiesQuery } = activitiesApi;
