import Flex from 'components/Utils/Flex';
import styled from 'styled-components';

export const StockFormStyled = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    .ant-table {
        .ant-table-thead {
            th {
                background: #f0f2f5;
            }
        }
        .ant-table-column-sorters {
            justify-content: unset !important;
            .ant-table-column-title {
                flex: unset !important;
            }
        }
    }
`;

export const StockFormInputsStyled = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 300px));
    gap: 1rem;
    .ant-form-item {
        flex-direction: column !important;
        flex-wrap: unset !important;
        .ant-form-item-label {
            flex: unset !important;
            label {
                font-weight: 400;
                color: #536d8f !important;
            }
        }
    }
`;

export const StockHistorySectionStyled = styled.div`
    display: flex;
    flex-direction: column;
    .ant-table {
        .ant-table-thead {
            th {
                background: #f0f2f5;
            }
        }
        .ant-table-column-sorters {
            justify-content: unset !important;
            .ant-table-column-title {
                flex: unset !important;
            }
        }
    }
`;

export const StockHistoryBackButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    .stock-history__back-button {
        margin-right: 1rem;
        border: 1px solid #2d3d76;
        border-radius: 3px 0px 0px 3px;

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 8px;
        svg {
            width: 26px;
            height: 26px;
        }
    }
    .stock-history__back-button-title {
        color: #2d3d76;
    }
`;

export const StockHistoryInfoStyled = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;
    color: #536d8f;

    ${Flex} {
        flex: 1;
        span {
            color: #2d3d76;
            &:first-of-type {
                margin-bottom: 0.5rem;
            }
        }
    }
`;

export const StockParagraphStyled = styled.p`
    color: #142c4a;
    font-size: 14px;
`;
