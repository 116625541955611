import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';
import { toggleModal } from '../Utils';

const newPackageTypeModalName = 'new-package-type-modal';
const editPackageTypeModalName = 'edit-package-type-modal';

export const packageTypesApi = createApi({
    reducerPath: 'packageTypes',
    baseQuery,
    tagTypes: ['packageTypes'],
    endpoints: (builder) => ({
        getPackageTypes: builder.query({
            method: 'get',
            query: () => `/packages-types`,
            providesTags: ['packageTypes'],
        }),
        createPackageTypes: builder.mutation({
            method: 'post',
            query: (body) => ({
                url: `/packages-types/create`,
                method: 'post',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando nuevo tipo de paquete...</b>,
                    success: <b>Tipo de paquete creado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
                api.dispatch(toggleModal(newPackageTypeModalName));
            },
            invalidatesTags: ['packageTypes'],
        }),
        updatePackageTypes: builder.mutation({
            method: 'put',
            query: (body) => ({
                url: `/packages-types/update`,
                method: 'put',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando tipo de paquete...</b>,
                    success: <b>Tipo de paquete actualizado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
                api.dispatch(toggleModal(editPackageTypeModalName));
            },
            invalidatesTags: ['packageTypes'],
        }),
        deletePackageTypes: builder.mutation({
            method: 'delete',
            query: (id) => ({
                url: `/packages-types/delete/${id}`,
                method: 'delete',
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Eliminando tipo de paquete...</b>,
                    success: <b>Tipo de paquete eliminado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['packageTypes'],
        }),
        setDefaultPackageTypes: builder.mutation({
            method: 'post',
            query: (body) => ({
                url: `/packages-types/setdefault`,
                method: 'post',
                body,
            }),
            onQueryStarted: (data, api) => {
                const dict = {
                    shipping: 'despacho',
                    pickup: 'retiro',
                };
                let nombre = dict[data?.delivery_methods] ?? 'despacho y retiro';

                toast.promise(api.queryFulfilled, {
                    loading: <b>Modificando bulto...</b>,
                    success: `Bulto para ${nombre} actualizado`,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['packageTypes'],
        }),
    }),
});

export const {
    useCreatePackageTypesMutation,
    useDeletePackageTypesMutation,
    useGetPackageTypesQuery,
    useUpdatePackageTypesMutation,
    useSetDefaultPackageTypesMutation,
} = packageTypesApi;
