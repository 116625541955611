import React from 'react';
import { Menu } from 'antd';
import Flex from 'components/Utils/Flex';
import styled from 'styled-components';
import { Button } from 'antd';
import { FiPrinter } from 'react-icons/fi';
import printJS from 'print-js';
import { useGeneratePickingListPDFMutation } from 'redux/features/Fulfillments';

const PrintButtonStyled = styled(Button)`
    padding: 4px;
    height: fit-content;
    background: #ffffff;
    border: 1px solid #2d3d76;
    border-radius: 3px;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        svg {
            color: #ffffff;
        }
    }
`;

const PrintListButton = ({
    fulfillments = [],
    buttonType = 'button',
    fulfillment_list_id = null,
}) => {
    const [generatePrintList] = useGeneratePickingListPDFMutation();
    console.log({ fulfillments, fulfillment_list_id });
    const generateMassivePrintListPDF = async () => {
        const pickingList = await generatePrintList({
            fulfillments,
            preparationList: fulfillment_list_id,
        });
        if (pickingList?.data?.url) {
            printJS(pickingList?.data?.url);
        }
    };

    return (
        <React.Fragment>
            {buttonType === 'modal-button' && (
                <Button onClick={generateMassivePrintListPDF}>
                    <Flex direction="row" alignItems="center" columnGap="0.6rem">
                        <FiPrinter size="22" />
                        <span>
                            <b>Imprimir lista de preparación</b>
                        </span>
                    </Flex>
                </Button>
            )}

            {buttonType === 'button' && (
                <PrintButtonStyled onClick={generateMassivePrintListPDF}>
                    <FiPrinter size="20" />
                </PrintButtonStyled>
            )}

            {buttonType === 'menuitem' && (
                <Menu.Item onClick={generateMassivePrintListPDF}>
                    Imprimir lista de preparación
                </Menu.Item>
            )}
        </React.Fragment>
    );
};

export default PrintListButton;
