import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getAllUsers } from './getAllUsers';
//import { updateManyUsersFromStore } from './getAllUsers';
import { getPreparersByCustom } from './getPreparersByCustom';

export const changeStatusManyUsers = createAsyncThunk('users/changeStatus', async (data, state) => {
    try {
        const res = await toast.promise(AndesClient.post(`/users/changeStatusMany`, data), {
            loading: 'Cambiando estado de usuario...',
            success: 'Operación realizada con éxito.',
            error: (err) => `Error: ${err.response.data.message}`,
        });
        if (res.status === 200) {
            state.dispatch(getAllUsers());
            state.dispatch(getPreparersByCustom());
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const changeStatusManyUsersSlice = createSlice({
    name: 'changeStatusManyUsers',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [changeStatusManyUsers.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [changeStatusManyUsers.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [changeStatusManyUsers.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default changeStatusManyUsersSlice.reducer;
