import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const fetchSuccessFulfillments = createAsyncThunk(
    'fulfillments/success',
    async (queryobj = {}, state) => {
        try {
            const { filtersDeliveries } = state.getState();
            const { custom_sort } = filtersDeliveries;
            const { sortBy, sortValue } = custom_sort;

            // if (queryobj.origin) {
            //     queryobj.origin = queryobj.origin.join(',');
            // }

            const response = await AndesClient.get('/fulfillments', {
                params: {
                    status: 'success,partially_processed,ready_for_deliver,delivered_to_courier,delivered',
                    ...queryobj,
                    limit: 50,
                    ...(sortValue && {
                        sort: sortValue,
                        sort_by: sortBy,
                        priority: false,
                    }),
                },
            });
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const successFulfillmentsSlice = createSlice({
    name: 'fulfillments/success',
    initialState: {
        loading: false,
        success: false,
        data: {},
        error: null,
    },
    reducers: {},
    extraReducers: {
        [fetchSuccessFulfillments.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [fetchSuccessFulfillments.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: true,
                data: payload,
                error: null,
            };
        },
        [fetchSuccessFulfillments.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default successFulfillmentsSlice.reducer;
