import { Form } from 'antd';
import { InputNumberStyled } from 'components/Utils/InputNumber';
import { isObjectEmpty } from 'helpers/filterObjectByCustom';

const QuantityWithIncidence = ({ lineItemsSelected = [] }) => {
    return (
        <div>
            <span className="font-medium underline" style={{ fontSize: '13px', color: '#043540' }}>
                INGRESE LA CANTIDAD CON INCIDENCIA
            </span>
            {lineItemsSelected[0] &&
                lineItemsSelected.map((lis) => {
                    if (!isObjectEmpty(lis)) {
                        const { line_item } = lis;
                        return (
                            <div key={line_item._id} className="flex flex-col">
                                <span className="text-xs">{line_item.name}</span>
                                <Form.Item
                                    name={['incidence_quantity', line_item._id]}
                                    initialValue={1}
                                    /* label={line_item.name} */
                                    rules={[{ required: true }]}
                                >
                                    <InputNumberStyled
                                        style={{ width: '120px', display: 'flex', justifyContent: 'center', alignItems: 'center'  }}	
                                        defaultValue={1}
                                        max={line_item.fulfilled_quantity}
                                        min={1}
                                    />
                                </Form.Item>
                            </div>
                        );
                    }
                })}
        </div>
    );
};

export default QuantityWithIncidence;
