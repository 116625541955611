import Flex from 'components/Utils/Flex';
import { WarehousesOption, WarehousesOptionsSelector } from '../styles';
import { FiTruck } from 'react-icons/fi';
import { RiShoppingBag3Line } from 'react-icons/ri';
import { RiLuggageCartLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { setChangeStoreValues } from 'redux/features/ChangeStore';
import { Typography } from '@andescommercecloud/beflow-design-system';

const WarehousesAvailable = () => {
    const dispatch = useDispatch();
    const changeStoreData = useSelector((state) => state.changeStore);

    const selectWarehouseType = (warehouseType) => {
        dispatch(
            setChangeStoreValues({
                step: 'warehouse-list',
                warehouseType,
                location: undefined,
                location_name: undefined,
                delivery_method: undefined,
            })
        );
    };

    return (
        <Flex direction="column">
            <Typography variant="bold" color="primary.01">
                Soluciones alternativas
            </Typography>
            <br />

            <Typography variant="regular" color="primary.01">
                Selecciona la opción más conveniente de sucursales con despacho o retiro para
                realizar cambio
            </Typography>
            <br />

            <WarehousesOptionsSelector>
                <WarehousesOption
                    role="button"
                    onClick={() => selectWarehouseType('shipping')}
                    isSelected={changeStoreData.warehouseType === 'shipping'}
                >
                    <FiTruck />
                    <b>
                        Sucursales <br /> con despacho
                    </b>
                </WarehousesOption>
                <WarehousesOption
                    role="button"
                    onClick={() => selectWarehouseType('pickup')}
                    isSelected={changeStoreData.warehouseType === 'pickup'}
                >
                    <RiShoppingBag3Line />
                    <b>
                        Sucursales con <br /> retiro
                    </b>
                </WarehousesOption>
                <WarehousesOption
                    role="button"
                    onClick={() => selectWarehouseType('all')}
                    isSelected={changeStoreData.warehouseType === 'all'}
                >
                    <RiLuggageCartLine />
                    <b>Sucursales con despacho y retiro</b>
                </WarehousesOption>
            </WarehousesOptionsSelector>
        </Flex>
    );
};

export default WarehousesAvailable;
