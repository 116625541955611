import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const logsApi = createApi({
    reducerPath: 'logs',
    baseQuery,
    tagTypes: ['logs'],
    endpoints: (builder) => ({
        getLogs: builder.query({
            method: 'get',
            query: () => `/logs`,
            providesTags: ['logs'],
        }),
    }),
});

export const { useGetLogsQuery } = logsApi;
