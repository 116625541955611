import { Button } from 'antd';
import Modal from 'components/Modal';
import Flex from 'components/Utils/Flex';
import usePreparationList from 'hooks/usePreparationList';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';
import PrintListButton from '../ScannedCodes/PrintListButton';

const PickingConfirmationModal = ({ showPickingConfirmation, fulfillment = {} }) => {
    const [showPickingList, setShowPickingList] = useState(false);
    const modal_name = 'picking-confirmation';
    const dispatch = useDispatch();
    const { preparationList } = usePreparationList();

    useEffect(() => {
        if (showPickingConfirmation) {
            dispatch(toggleModal(modal_name));
        }
    }, [showPickingConfirmation]);

    const handleClose = () => {
        dispatch(toggleModal(modal_name));
    };

    const handleShowPickingList = () => {
        setShowPickingList(true);
    };

    const modalTitle =
        preparationList.length > 0
            ? `¿Los productos de los (${preparationList.length}) pedidos seleccionados ya fueron pickeados?`
            : '¿Los productos de este pedido ya fueron pickeados?';

    return (
        <Modal
            title={modalTitle}
            modalName={modal_name}
            onOk={handleClose}
            footer={null}
            maskClosable={false}
            closable={false}
        >
            <Flex direction="column" rowGap="1rem">
                {!showPickingList && (
                    <Flex direction="row" columnGap="1rem">
                        <Button style={{ flex: 1 }} danger onClick={handleShowPickingList}>
                            <b>No</b>
                        </Button>
                        <Button style={{ flex: 1 }} type="primary" onClick={handleClose}>
                            <b>Si</b>
                        </Button>
                    </Flex>
                )}
                {showPickingList && (
                    <Flex direction="row" columnGap="1rem">
                        {preparationList && preparationList.length > 0 ? (
                            <PrintListButton
                                buttonProps={{
                                    style: {
                                        flex: 1,
                                        fill: 'white',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    },
                                    type: 'primary',
                                    onClick: handleClose,
                                }}
                                multiple={true}
                                fulfillments={preparationList}
                            />
                        ) : (
                            <PrintListButton
                                buttonProps={{
                                    style: {
                                        flex: 1,
                                        fill: 'white',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    },
                                    type: 'primary',
                                    onClick: handleClose,
                                }}
                                order={fulfillment.order}
                                line_items={fulfillment.line_items}
                            />
                        )}

                        <Button style={{ flex: 1 }} danger onClick={handleClose}>
                            <b>Cerrar sin imprimir</b>
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Modal>
    );
};

export default PickingConfirmationModal;
