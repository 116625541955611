import styled, { css } from 'styled-components';

const Flex = styled.div`
    display: flex;
    max-width: 100%;

    ${({ width }) =>
        width &&
        css`
            width: ${width};
        `};

    ${({ height }) =>
        height &&
        css`
            height: ${height};
        `};

    ${({ marginTop }) =>
        marginTop &&
        css`
            margin-top: ${marginTop};
        `};

    ${({ marginBottom }) =>
        marginBottom &&
        css`
            margin-bottom: ${marginBottom};
        `};

    ${({ wrap }) =>
        wrap &&
        css`
            flex-wrap: wrap;
        `};

    ${({ direction }) =>
        direction &&
        css`
            flex-direction: ${direction};
        `};

    ${({ flex }) =>
        flex &&
        css`
            flex: ${flex};
        `};

    ${({ justifyContent }) =>
        justifyContent &&
        css`
            justify-content: ${justifyContent};
        `};

    ${({ gap }) =>
        gap &&
        css`
            gap: ${gap};
        `};

    ${({ alignItems }) =>
        alignItems &&
        css`
            align-items: ${alignItems};
        `};

    ${({ rowGap }) =>
        rowGap &&
        css`
            > * {
                margin-bottom: ${rowGap} !important;
                &:last-child,
                &:only-child {
                    margin-bottom: 0px !important;
                }
            }
        `};
    ${({ columnGap }) =>
        columnGap &&
        css`
            > * {
                margin-right: ${columnGap} !important;
                &:last-child,
                &:only-child {
                    margin-right: 0px !important;
                }
            }
        `};

    ${({ padding }) =>
        padding &&
        css`
            padding: ${padding};
        `};

    ${({ paddingRight }) =>
        paddingRight &&
        css`
            padding-right: ${paddingRight};
        `};
    ${({ paddingLeft }) =>
        paddingLeft &&
        css`
            padding-left: ${paddingLeft};
        `};
    ${({ paddingTop }) =>
        paddingTop &&
        css`
            padding-top: ${paddingTop};
        `};
    ${({ paddingBottom }) =>
        paddingBottom &&
        css`
            padding-bottom: ${paddingBottom};
        `};
    ${({ margin }) =>
        margin &&
        css`
            margin: ${margin};
        `};
    ${({ marginTop }) =>
        marginTop &&
        css`
            margin-top: ${marginTop};
        `};
    ${({ marginBottom }) =>
        marginBottom &&
        css`
            margin-bottom: ${marginBottom};
        `};

    ${({ marginRight }) =>
        marginRight &&
        css`
            margin-right: ${marginRight};
        `};
    ${({ marginLeft }) =>
        marginLeft &&
        css`
            margin-left: ${marginLeft};
        `};
    ${({ gap }) =>
        gap &&
        css`
            gap: ${gap};
        `};
`;

export default Flex;
