import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBatchFulfillments } from 'redux/features/Fulfillments/getBatchFulfillments';

const useBatchFulfillments = () => {
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector((state) => state.batchFulfillments);
    useEffect(() => {
        dispatch(fetchBatchFulfillments());
    }, []);
    return [loading, data, error];
};

export default useBatchFulfillments;
