import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const changeBlurUsername = createAsyncThunk(
    'users/changeBlurUsername',
    async (data, state) => {
        try {
            await AndesClient.post(`/users/verifyIfExists`, data);

            return state.fulfillWithValue(true);
        } catch (error) {
            return state.rejectWithValue(error.response.data);
        }
    }
);

const changeBlurUsernameSlice = createSlice({
    name: 'changeBlurUsername',
    initialState: {
        loading: false,
        success: false,
        error: null,
        sugestion: null,
    },
    reducers: {
        clearUsernameError: () => {
            return {
                loading: false,
                success: false,
                error: null,
                sugestion: null,
            };
        },
    },
    extraReducers: {
        [changeBlurUsername.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [changeBlurUsername.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                error: 'No hay errores',
                loading: false,
                sugestion: null,
            };
        },
        [changeBlurUsername.rejected]: (state, action) => {
            console.log(action.payload);
            return {
                ...state,
                error: action.payload.message,
                sugestion: action.payload.sugestion ?? 'No hay sugerencias',
                loading: false,
            };
        },
    },
});
export const { clearUsernameError } = changeBlurUsernameSlice.actions;
export default changeBlurUsernameSlice.reducer;
