import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPickupPreferenceByMerchant } from 'redux/features/PickupsPreferences/getPickupPreferenceByMerchant';

const useGetPickupPreference = () => {
    const dispatch = useDispatch();
    const { loading, data, success, error } = useSelector(
        (state) => state.getPickupPreferenceByMerchant
    );
    useEffect(() => {
        if (!data?.[0] && !success) {
            dispatch(getPickupPreferenceByMerchant());
        }
    }, []);
    return [loading, data, error];
};

export default useGetPickupPreference;
