import history from 'helpers/history';
import { useDispatch, useSelector } from 'react-redux';
import { getFulfillmentById } from 'redux/features/Fulfillments/getOne';

const usePreparationList = (fulfillment_id) => {
    const dispatch = useDispatch();
    const { multiplePreparation = [] } = useSelector((state) => state.utils);

    const handlePreviousPreparation = () => {
        const index = multiplePreparation.findIndex((item) => item === fulfillment_id);
        const prevPreparation = multiplePreparation[index - 1] || null;

        if (prevPreparation) {
            history.push(`/fulfillments/${prevPreparation}/multiple`);
            dispatch(getFulfillmentById(prevPreparation));
        }
    };

    const handleNextPreparation = () => {
        const index = multiplePreparation.findIndex((item) => item === fulfillment_id);
        const nextPreparation = multiplePreparation[index + 1] || null;

        if (nextPreparation) {
            history.push(`/fulfillments/${nextPreparation}/multiple`);
            dispatch(getFulfillmentById(nextPreparation));
        }
    };

    const getFulfillmentNumber = () => {
        const index = multiplePreparation.findIndex((item) => item === fulfillment_id);
        return index + 1;
    };

    const hasPrev = () => {
        const index = multiplePreparation.findIndex((item) => item === fulfillment_id);
        return index > 0;
    };

    const hasNext = () => {
        const index = multiplePreparation.findIndex((item) => item === fulfillment_id);
        return multiplePreparation[index + 1] !== undefined;
    };

    return {
        preparationList: multiplePreparation,
        handlePreviousPreparation,
        handleNextPreparation,
        getFulfillmentNumber,
        hasPrev,
        hasNext,
    };
};
export default usePreparationList;
