import { createSlice } from '@reduxjs/toolkit';

const initialStateSOwarehouseAvailabilityVerification = {
    location_id_selected: '',
};

const SOwarehouseAvailabilityVerification = createSlice({
    name: 'SOwarehouseAvailabilityVerification',
    initialState: initialStateSOwarehouseAvailabilityVerification,
    reducers: {
        reInitSOwarehouseAvailabilityVerification: (state) => {
            return {
                ...state,
                ...initialStateSOwarehouseAvailabilityVerification,
            };
        },
        changeSOwarehouseAvailabilityVerificationData: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        changeSOwarehouseAvailabilityVerificationField: (state, { payload }) => {
            return {
                ...state,
                [payload[0]]: payload[1],
            };
        },
    },
});
export const {
    reInitSOwarehouseAvailabilityVerification,
    changeSOwarehouseAvailabilityVerificationData,
    changeSOwarehouseAvailabilityVerificationField,
} = SOwarehouseAvailabilityVerification.actions;
export default SOwarehouseAvailabilityVerification.reducer;
