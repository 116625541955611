import MainLayout from 'layouts/MainLayout';
import { Route } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [userLogged, setUserLogged] = useState({});
    const [auth, setAuth] = useState({});

    const enhancedComponent = <Component {...rest} userLogged={userLogged} />;

    useEffect(() => {
        Auth.currentSession()
            .then((session) => {
                setAuth(session.getIdToken().decodePayload());
            })
            .catch(() => setAuth(false));
    }, []);

    useEffect(() => {
        const idTokenPayload = auth ?? {};
        if (idTokenPayload) {
            setUserLogged(idTokenPayload);
        }
    }, [auth]);

    return (
        <MainLayout>
            <Route {...rest} render={() => enhancedComponent} />
        </MainLayout>
    );
};

export default PrivateRoute;
