import {
    crossdockingDeliveryMethodOptions, fulfillmentStatusOptions,
} from './values';
import { crossdockingFilterFields } from './fields';

export const crossdockingFilters = {
    fields: [...crossdockingFilterFields],
    values: {
        delivery_method: [...crossdockingDeliveryMethodOptions],
        fulfillment_status: [...fulfillmentStatusOptions],
    },
};
