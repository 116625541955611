//import { ReactComponent as SaveIcon } from 'assets/save-icon.svg';
//import { Button } from 'antd';

import { Button, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import TCard from 'components/TCard';
import Flex from 'components/Utils/Flex';
import { ScannedInput } from '../styles';
import Tag from 'components/Tag';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmModal from './ConfirmModal';
import { proccesPackagecd } from '../../../redux/features/Packages/processPackagecd';

// import { useChangeStatusReceivedInStoreMutation } from '../../../redux/features/ArrivalsPending';

const PackagesToolbar = ({ fulfillments = [], loading }) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    // const [changeStatus, changeStatusState] = useChangeStatusReceivedInStoreMutation();

    const scannedInputRef = useRef(null);

    const handleScan = () => {
        if (scannedInputRef.current) {
            scannedInputRef.current.focus();
        }
    };

    useEffect(() => {
        if (scannedInputRef.current) {
            scannedInputRef.current.focus();
        }
        window.addEventListener('keydown', handleScan);
        return () => {
            window.removeEventListener('keydown', handleScan);
        };
    }, []);

    const scroll = (id) => {
        const section = document.querySelector(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            section.classList.add('alerts-border');
            setTimeout(() => {
                section.classList.remove('alerts-border');
            }, 3000);
        }
    };

    const handleProcessPackage = () => {
        const code = inputValue.trim();

        setInputValue('');
        const fulfillment =
            fulfillments.find((fulfillment) =>
                fulfillment.packages.some((pkg) => pkg.code === code)
            ) || {};

        if (!fulfillment._id) {
            return toast.error('Este codigo de bulto no existe en los pedidos seleccionados');
        }

        if (fulfillment._id) {
            const pkg = fulfillment.packages.find((pkg) => pkg.code === code);
            if (pkg.status === 'received_pickup_location') {
                return toast.error('Este codigo de bulto ya fue escaneado');
            }
        }

        // changeStatus({ _id: fulfillment?._id });
        // console.log(changeStatusState);

        scroll(`#packages-table-${fulfillment.name}`);

        dispatch(
            proccesPackagecd({
                code,
                fulfillment_id: fulfillment._id,
            })
        );
    };

    const sumPackages = (fulfillments) => {
        return fulfillments.reduce((acc, ele) => {
            acc += ele.packages.length;
            return acc;
        }, 0);
    };

    const sumProcessedPackages = (fulfillments) => {
        console.log(fulfillments);
        return fulfillments.reduce((acc, ele) => {
            ele.packages.map((pkg) => {
                if (
                    pkg.status === 'received_pickup_location' ||
                    pkg.status === 'received_in_cd_location'
                ) {
                    acc += 1;
                }
            });
            return acc;
        }, 0);
    };

    console.log(sumPackages(fulfillments), sumProcessedPackages(fulfillments));
    const allPackagesProcessed = sumPackages(fulfillments) === sumProcessedPackages(fulfillments);

    const processButtonDisabled = !allPackagesProcessed || loading;
    return (
        <TCard
            paddingless
            customStyle={{
                position: 'sticky',
                top: '10px',
                zIndex: 99,
                boxShadow: '0 15px 20px -15px rgba(0,0,0,0.1),0 10px 10px -10px rgba(0,0,0,0.1)',
                margin: '1rem',
            }}
        >
            <Flex
                direction="row"
                columnGap="5px"
                padding="12px"
                alignItems="center"
                justifyContent="space-between"
            >
                <ScannedInput>
                    <input
                        ref={scannedInputRef}
                        value={inputValue}
                        placeholder="Copie y pegue el código de bulto"
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleProcessPackage();
                                setInputValue('');
                            }
                        }}
                    />
                    <Button
                        onClick={handleProcessPackage}
                        style={{ color: '#0A6A77', border: '1px solid #0A6A77', fontWeight: 500 }}
                    >
                        Ingresar
                    </Button>
                    <Tooltip
                        className="ml-2 cursor-pointer"
                        title={
                            'Ingresa el código de escaneo y coloca el botón guardar para ingresar el bulto escaneado'
                        }
                    >
                        <QuestionCircleOutlined />
                    </Tooltip>
                </ScannedInput>

                <div>
                    <Flex direction="row" columnGap="1rem" alignItems={'center'}>
                        <Flex direction="column" alignItems="center" justifyContent="center">
                            <Tag color={allPackagesProcessed ? 'success' : 'warning'}>
                                <b style={{ fontSize: '0.9rem' }}>
                                    {sumProcessedPackages(fulfillments)}/{sumPackages(fulfillments)}{' '}
                                    Bultos recibidos
                                </b>
                            </Tag>
                            <small style={{ fontSize: '1rem' }}>
                                Total de Bultos a recibir: {sumPackages(fulfillments)}
                            </small>
                        </Flex>
                        <ConfirmModal
                            fulfillments={fulfillments}
                            disabled={processButtonDisabled}
                            loading={loading}
                        />
                        <Tooltip
                            className="cursor-pointer"
                            title={
                                'Cuando todos los bultos esten escaneados se activará este botón que procesará a entrega todos los pedidos.'
                            }
                        >
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Flex>
                </div>
            </Flex>
        </TCard>
    );
};

export default PackagesToolbar;
