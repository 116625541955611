import { Form, Select } from 'antd';
import useAllShops from 'hooks/useAllShops';
import styled from 'styled-components';

const FormItem = styled(Form.Item)`
    .ant-form-item-label {
        label {
            font-weight: 400;
            color: #536d8f !important;
        }
    }
`;

const LocationsSelector = () => {
    const [loadingLocations, locations] = useAllShops();
    return (
        <FormItem name="location_id" label="1. Selecciona la bodega o tienda">
            <Select loading={loadingLocations} placeholder="Selecciona">
                {Array.from(locations)
                    .filter((location) => location.is_enabled)
                    .map((location) => (
                        <Select.Option key={location._id} value={location._id}>
                            {location.name}
                        </Select.Option>
                    ))}
            </Select>
        </FormItem>
    );
};

export default LocationsSelector;
