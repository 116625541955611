import styled, { css } from 'styled-components';

const LinkButton = css`
    color: #2d3d76;
    font-weight: 500;
    background-color: white;
    border-color: ${({ theme }) => theme?.buttons?.font?.label ?? '#2D3D76'};
`;

const DefaultButton = css`
    color: white;
    border-color: ${({ theme }) => theme?.buttons?.font?.label ?? '#2D3D76'};
    background-color: ${({ theme }) => theme?.buttons?.font?.label ?? '#2D3D76'};

    &:disabled {
        border-color: #e4e8ec;
        background: #e4e8ec;
        color: #9ebcc0;
    }
`;

const getButtonType = ($type) => {
    if ($type === 'link') return LinkButton;
    return DefaultButton;
};

export default styled.button`
    height: 36px;
    border-radius: 3px;
    padding: 5px 16px;
    font-weight: 400;
    border: 1px solid transparent;
    ${({ $type = '' }) => getButtonType($type)}
`;
