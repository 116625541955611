import styled from 'styled-components';

export const ModalChangeSLADateContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    .promise_date_old {
        color: #142c4a;
        font-weight: 500;
    }
`;

export const ModalChangeSLADateSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;

    .label_modal_change_sla_date {
        color: #142c4a;
    }

    .new_date_to_selected {
        margin-top: 6px;
    }
`;
