import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledOrderLink = styled(Link)`
    font-weight: 500;
    font-size: 0.8rem;
    text-align: center;
    text-decoration-line: underline;
    color: #142c4a;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
        color: #142c4a;
        text-decoration-line: underline;
    }
`;

export const ModalTitleDeleteManifest = styled.div`
    color: #2d3d76;
    font-size: 16px;
    font-weight: 700;
`;
