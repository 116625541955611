import FormStyled from 'components/Utils/Form';
import { ContainerProblemOrderDetailsDrawer } from './styled';
import { Skeleton } from 'antd';

export const SkeletonIncidence = () => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ContainerProblemOrderDetailsDrawer className="flex no-wrap justify-between p-2 w-full mb-2 mt-2">
            <Skeleton />
        </ContainerProblemOrderDetailsDrawer>

        <FormStyled layout={'vertical'} name="manual-incidence-details-show">
            <div className="w-full" style={{ padding: '4px' }}>
                <div
                    className="w-full flex flex-col justify-center p-2 my-2"
                    style={{
                        border: '1px solid #E4E8EC',
                        boxSizing: 'border-box',
                    }}
                >
                    <Skeleton />
                </div>
            </div>
        </FormStyled>
    </div>
);
