import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import AndesClient from 'services/AndesClient';
import { getStripeCustomer } from './getCustomer';

export const cancelSubscription = createAsyncThunk(
    'stripe/cancel/subscription',
    async (subscription_id, state) => {
        try {
            const res = await AndesClient.post(`/stripe/cancel/subscription`, {
                subscription_id,
            });
            if (res.status === 200) {
                toast.success('Subscripcion cancelada con éxito.');
                state.dispatch(getStripeCustomer());
            }
            return res.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const cancelSubscriptionSlice = createSlice({
    name: 'cancelSubscription',
    initialState: {
        loading: false,
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [cancelSubscription.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [cancelSubscription.fulfilled]: (state) => {
            return {
                ...state,
                loading: false,
                success: true,
            };
        },
        [cancelSubscription.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});

export default cancelSubscriptionSlice.reducer;
