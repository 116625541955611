import { Form, Select } from 'antd';
import { removeAccents } from 'helpers/removeAccents';
import useAllShops from 'hooks/useAllShops';
import { useLocation, useHistory } from 'react-router-dom';

const LocationsSelector = ({ setSelectedTargetId }) => {
    const [loading, data] = useAllShops();
    const history = useHistory();
    const search = useLocation().search;

    const handleClickSelect = () => {
        if (search) {
            history.push(`/settings/metafields`);
        }
    };

    return (
        <Form.Item
            name="target_id"
            label="Tienda"
            rules={[{ required: true, message: 'Este campo es requerido' }]}
        >
            {/*  */}
            <Select
                onClick={handleClickSelect}
                loading={loading}
                placeholder="Seleccione un item"
                onChange={(v) => setSelectedTargetId(v)}
                showSearch
                optionFilterProp="children"
            >
                {data
                    .filter((location) => location?.is_enabled)
                    .sort((a, b) =>
                        removeAccents(a.name.toUpperCase()) > removeAccents(b.name.toUpperCase())
                            ? 1
                            : removeAccents(a.name.toUpperCase()) <
                              removeAccents(b.name.toUpperCase())
                            ? -1
                            : 0
                    )
                    .map((item) => (
                        <Select.Option value={item._id} key={item._id}>
                            {item.name}
                        </Select.Option>
                    ))}
            </Select>
        </Form.Item>
    );
};

export default LocationsSelector;
