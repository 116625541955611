import Modal from 'components/Modal';
import Flex from 'components/Utils/Flex';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';
import PrintButton from '../PrintButton';
import { Button } from '@andescommercecloud/beflow-design-system';
import NormalButton from 'components/Buttons/NormalButton';

const PickingConfirmationModal = ({
    showPickingConfirmation,
    fulfillment = {},
    blockPreparation,
    fulfillment_list_id = null,
}) => {
    const [showPickingList, setShowPickingList] = useState(false);
    const modalName = 'picking-confirmation';
    const buttonConfirmId = 'confirm-pickup';

    const dispatch = useDispatch();
    const { massiveFulfillmentPreparation = [] } = useSelector((state) => state.utils);

    useEffect(() => {
        if (showPickingConfirmation && !blockPreparation) {
            dispatch(toggleModal(modalName));
            setTimeout(() => {
                const elementButton = document.getElementById(buttonConfirmId);
                elementButton?.focus();
            }, 1000);
        }
    }, [showPickingConfirmation, blockPreparation]);

    const handleClose = () => {
        dispatch(toggleModal(modalName));
    };

    const handleShowPickingList = () => {
        setShowPickingList(true);
    };

    const modalTitle =
        massiveFulfillmentPreparation.length > 0
            ? `¿Los productos de los (${massiveFulfillmentPreparation.length}) pedidos seleccionados ya fueron pickeados?`
            : '¿Los productos de este pedido ya fueron pickeados?';

    return (
        <Modal
            title={modalTitle}
            modalName={modalName}
            onOk={handleClose}
            footer={null}
            maskClosable={false}
            closable={false}
        >
            <Flex direction="column" rowGap="1rem">
                {!showPickingList && (
                    <Flex direction="row" columnGap="1rem">
                        <NormalButton block variation="secondary" onClick={handleShowPickingList}>
                            <b>No</b>
                        </NormalButton>

                        <NormalButton
                            autoFocus
                            style={{ width: '100%', justifyContent: 'center' }}
                            onClick={handleClose}
                            id={buttonConfirmId}
                        >
                            <b>Si</b>
                        </NormalButton>
                    </Flex>
                )}
                {showPickingList && (
                    <Flex direction="row" columnGap="1rem">
                        <PrintButton
                            buttonType="modal-button"
                            buttonProps={{
                                style: {
                                    flex: 1,
                                    fill: 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                },
                                type: 'primary',
                                onClick: handleClose,
                            }}
                            fulfillment_list_id={fulfillment_list_id}
                            fulfillments={[fulfillment?._id]}
                        />

                        <Button style={{ flex: 1 }} danger onClick={handleClose}>
                            <b>Cerrar sin imprimir</b>
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Modal>
    );
};

export default PickingConfirmationModal;
