import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const filtersOrders = createSlice({
    name: 'filtersOrders',
    initialState: {
        price_range: [],
        date_range: [],
        search_by_order: '',
        user_id: [],
        delivery_method: [],
        fulfillment_status: [
            // 'in_progress',
            // 'partially_fulfilled',
            // 'paused',
            // 'unfulfilled',
            // 'fulfilled',
        ],
        location_id: [],
        featured_filters: ['delivery_method', 'fulfillment_status', 'user_id', 'location_id'],
        limit: 100,
        page: 1,
    },
    reducers: {
        changeAllOF: (state, { payload }) => {
            return {
                ...state,
                ...payload,
                date_range: payload?.date_range || [],
            };
        },
        removeAllOF: () => {
            return {
                price_range: [],
                date_range: [],
                search_by_order: '',
                user_id: [],
                delivery_method: [],
                fulfillment_status: [],
                location_id: [],
                featured_filters: [
                    'delivery_method',
                    'fulfillment_status',
                    'user_id',
                    'location_id',
                ],
                limit: 100,
                page: 1,
            };
        },
        changeFeaturedFilters: (state, { payload }) => {
            const { keyName, isChecked } = payload;
            const isAlreadyFeatured = state.featured_filters.includes(keyName);
            let newFeaturesArray = [];
            if (isChecked && !isAlreadyFeatured) {
                newFeaturesArray = [...state.featured_filters, keyName];
            } else {
                newFeaturesArray = state.featured_filters.filter((el) => el !== keyName);
            }
            return {
                ...state,
                featured_filters: [...newFeaturesArray],
            };
        },
        changePageFO: (state, { payload }) => {
            return {
                ...state,
                page: payload,
            };
        },
        changeLimitFO: (state, { payload }) => {
            return {
                ...state,
                limit: payload,
            };
        },
        changeSearchByOrder: (state, { payload }) => {
            return {
                ...state,
                search_by_order: payload,
            };
        },
        changeDateRangeFO: (state, { payload }) => {
            if (payload) {
                return {
                    ...state,
                    date_range:
                        payload && payload[0] && payload[1]
                            ? [
                                moment(payload[0]).format('YYYY-MM-DD'),
                                moment(payload[1]).format('YYYY-MM-DD'),
                            ]
                            : [],
                };
            } else {
                return {
                    ...state,
                    date_range: [],
                };
            }
        },
        changePriceRangeFO: (state, { payload }) => {
            return {
                ...state,
                price_range:
                    (payload[0] !== null && payload[1] !== null && [payload[0], payload[1]]) || [],
            };
        },
        changeDeliveryTypeFO: (state, { payload }) => {
            return {
                ...state,
                delivery_type: [...payload],
            };
        },
        changeStoreWarehouseIdFO: (state, { payload }) => {
            return {
                ...state,
                location_id: [...payload],
            };
        },
        changePreparationStateIdFO: (state, { payload }) => {
            return {
                ...state,
                preparation_state_id: [...payload],
            };
        },
    },
});
export const {
    changeFullfilmentFO,
    changeOrderToSearchFO,
    changeDateRangeFO,
    /* changeChannelIdFO, */
    changeDeliveryTypeFO,
    changeStoreWarehouseIdFO,
    changePreparationStateIdFO,
    changeAllOF,
    changeFeaturedFilters,
    changePriceRangeFO,
    removeAllOF,
    changePageFO,
    changeSearchByOrder,
    changeLimitFO,
} = filtersOrders.actions;
export default filtersOrders.reducer;
