import { Dropdown } from 'antd';
import NormalButton from '../NormalButton';

const ActionsButton = ({ buttonProps, dropdownProps, buttonText }) => {
    return (
        <Dropdown {...dropdownProps}>
            <NormalButton buttonText={buttonText} {...buttonProps} />
        </Dropdown>
    );
};

export default ActionsButton;
