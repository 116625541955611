import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const separateTicketsEnviame = createAsyncThunk(
    'fulfillments/separateTicketsEnviame',
    async (data, state) => {
        try {
            const response = await AndesClient.post('/fulfillments/separateTicketsEnviame', {
                ticket_url: data,
            });

            return state.fulfillWithValue(response.data.map((el) => Object.values(el)));
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const separateTicketsEnviameSlice = createSlice({
    name: 'separateTicketsEnviame',
    initialState: {
        loading: false,
        data: [],
        succes: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [separateTicketsEnviame.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [separateTicketsEnviame.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                succes: true,
                error: null,
            };
        },
        [separateTicketsEnviame.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                succes: false,
                error: payload,
            };
        },
    },
});

export default separateTicketsEnviameSlice.reducer;
