import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getOrderById = createAsyncThunk('orders/getOrderById', async (order_id, state) => {
    try {
        const response = await AndesClient.get(`/orders/${order_id}`);
        return response.data;
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const orderSlice = createSlice({
    name: 'order',
    initialState: {
        loading: false,
        data: {},
        success: false,
        error: null,
    },
    reducers: {
        addNewPackage: (state, action) => {
            const { payload } = action;
            const { packages } = state.data;
            return {
                ...state,
                data: { ...state.data, packages: [payload, ...packages] },
            };
        },
        updateRemoveItems: (state, action) => {
            console.log('llegue a redux: ', action.payload);
            const { payload } = action;

            return {
                ...state,
                data: { ...state.data, line_items: payload },
            };
        },
        updatePackages: (state, action) => {
            const { payload } = action;
            const { packages } = state.data;

            const updatedPackages = packages.map((p) => {
                if (p._id === payload._id) {
                    return {
                        ...p,
                        ...payload.newData,
                    };
                }
                return p;
            });

            return {
                ...state,
                data: { ...state.data, packages: [...updatedPackages] },
            };
        },
    },
    extraReducers: {
        [getOrderById.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [getOrderById.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                success: true,
                error: null,
            };
        },
        [getOrderById.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: payload,
            };
        },
    },
});
export const { addNewPackage, updatePackages, updateRemoveItems } = orderSlice.actions;
export default orderSlice.reducer;
