import { Menu } from 'antd';
import ActionsButton from 'components/Buttons/ActionsButton';
import Flex from 'components/Utils/Flex';

const Actions = ({ selectedPackages = [] }) => {
    const menu = <Menu>n</Menu>;

    return (
        <Flex direction="row" columnGap="1rem">
            <ActionsButton
                dropdownProps={{ overlay: menu, disabled: selectedPackages.length === 0 }}
                buttonProps={{ buttonText: `Acciones (${selectedPackages.length})` }}
            />
        </Flex>
    );
};

export default Actions;
