import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const unProccesPackage = createAsyncThunk('packages/unprocess', async (data, state) => {
    try {
        const {
            code,
            fulfillment,
            totalPackages = 1,
            updateList,
            handleSelectRows,
            isScan,
            selectedRows,
            handleInsertFulfillments,
        } = data;

        const promise = AndesClient.put('/packages/unprocess', {
            code,
            fulfillment_id: fulfillment._id,
        });

        toast.promise(promise, {
            loading: <b>Desprocesando bulto...</b>,
            success: <b>Bulto suprimido con éxito de la pre-entrega</b>,
            error: <b>Ocurrió un error al intentar suprimir el bulto de la pre-entrega.</b>,
        });

        const result = await promise;

        if (result.status === 200) {
            updateList((state) => {
                const values = state
                    .map((f) => {
                        if (f._id === fulfillment._id) {
                            return {
                                ...f,
                                packages: f.packages.map((p) => {
                                    if (p.code === code)
                                        return { ...p, status: 'pending_for_processing' };
                                    return p;
                                }),
                            };
                        }

                        return f;
                    })
                    .filter((f) => {
                        if (!isScan) return true;

                        if (f._id === fulfillment._id) {
                            if (totalPackages === 1) return false;

                            return f.packages.some((p) => p.status === 'processed');
                        }

                        return true;
                    });
                if (isScan) {
                    const fulfillments = values.filter((f) => selectedRows.includes(f._id));

                    handleSelectRows(fulfillments.map((f) => f._id));

                    handleInsertFulfillments(
                        fulfillments,
                        values.filter(
                            (fulfillment) => !fulfillments.find((f) => f._id === fulfillment._id)
                        )
                    );
                } else {
                    const fulfillments = values.filter((f) => {
                        if (f._id === fulfillment._id) {
                            if (totalPackages === 1) return false;

                            return f.packages.some((p) => p.status === 'processed');
                        }

                        return selectedRows.includes(f._id);
                    });

                    handleSelectRows(fulfillments.map((f) => f._id));

                    handleInsertFulfillments(
                        fulfillments,
                        values.filter(
                            (fulfillment) => !fulfillments.find((f) => f._id === fulfillment._id)
                        )
                    );
                }

                return values;
            });
        }

        return await result.data;
    } catch (error) {
        return state.rejectWithValue(error.message);
    }
});

const unProccesPackageSlice = createSlice({
    name: 'packages-unprocess',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [unProccesPackage.pending]: (state) => ({
            ...state,
            loading: true,
        }),
        [unProccesPackage.fulfilled]: (state) => ({
            ...state,
            loading: false,
            success: true,
            error: null,
        }),
        [unProccesPackage.rejected]: (state, payload) => ({
            ...state,
            loading: false,
            error: payload,
        }),
    },
});

export default unProccesPackageSlice.reducer;
