export const defaultColumnsView = [
    {
        name: 'name',
        label: 'ID Fulfillment',
        status: true,
        position: 1,
    },
    {
        name: 'remote_order_id',
        label: 'ID Ecommerce',
        status: true,
        position: 2,
    },
    {
        name: 'packages',
        label: 'N° de bultos',
        status: true,
        position: 3,
    },
    {
        name: 'origin',
        label: 'Tienda',
        status: true,
        position: 4,
    },
    {
        name: 'locations.destination',
        label: 'Tienda de destino',
        status: false,
        position: 5,
    },
    {
        name: 'createdAt',
        label: 'Fecha pedido',
        status: true,
        position: 6,
    },
    {
        name: 'promised_date',
        label: 'Plazo límite',
        status: true,
        position: 7,
    },
    {
        name: 'custom_promised_date',
        label: 'Promesa de entrega',
        status: true,
        position: 8,
    },
    {
        name: 'promised_date_range',
        label: 'Horario de entrega',
        status: true,
        position: 9,
    },
    {
        name: 'status',
        label: 'Estado entrega',
        status: true,
        position: 10,
    },
    {
        name: 'courier',
        label: 'Courier',
        status: true,
        position: 11,
    },
    {
        name: 'delivery_method',
        label: 'Tipo de entrega',
        status: true,
        position: 12,
    },
    {
        name: 'customer_name',
        label: 'Cliente',
        status: true,
        position: 13,
    },
];

export const INITIAL_PAGE_SIZE = 25;
