export const sorters = {
    desc: 'descend',
    asc: 'ascend',
};

export const conditions = [
    {
        value: 'is',
        label: 'Es',
    },
    {
        value: 'is_not',
        label: 'No es',
    },
    {
        value: 'includes',
        label: 'Incluye',
    },
    {
        value: 'not_include',
        label: 'No incluye',
    },
];
