import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import AndesClient from 'services/AndesClient';
import { getAllCouriers } from './getAllCouriers';

export const createCourier = createAsyncThunk('couriers/create', async (data, state) => {
    try {
        delete data._id;

        const res = await toast.promise(AndesClient.post(`/settings/couriers`, data), {
            loading: 'Creando courier...',
            success: 'Courier creado con éxito.',
            error: 'No se pudo crear el courier.',
        });

        if (res.status === 200) {
            state.dispatch(getAllCouriers());
        }
        return state.fulfillWithValue(true);
    } catch (error) {
        return state.rejectWithValue(error.response.data.message);
    }
});

const createCourierSlice = createSlice({
    name: 'createCouriers',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [createCourier.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [createCourier.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [createCourier.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default createCourierSlice.reducer;
