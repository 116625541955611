import DrawerWithButton from 'components/DrawerWithButton';
import Flex from 'components/Utils/Flex';
import { Collapse } from 'antd';
import Tag from 'components/Tag';
import dayjs from 'helpers/dayjs';
import {
    CollapseHeaderStyles,
    FulfillmentDeliveryDetails,
    PackageDetailsDrawerStyles,
    PackageProductsDetails,
} from '../styles';
import { Fragment, useState } from 'react';
import { ReactComponent as ChevronDown } from 'assets/chevron-down.svg';
import { format } from 'rut.js';
import { FiFileText } from 'react-icons/fi';

const { Panel } = Collapse;

const PackageDetailsDrawer = ({ fulfillment }) => {
    const [activeKey, setActiveKey] = useState([]);
    return (
        <DrawerWithButton
            drawerProps={{
                title: `Detalles pedido ${fulfillment?.name ?? ''}`,
                name: `package-details-drawer=${fulfillment?.name ?? ''}`,
                width: '500px',
                dontShowFooter: true,
                forceRender: true,
            }}
            buttonProps={{
                buttonText: 'Ver detalle',
                variation: 'secondary',
                style: {
                    border: '1px solid #2D3D76',
                    padding: '1rem',
                    height: '28px',
                },
                Icon: FiFileText,
            }}
        >
            <PackageDetailsDrawerStyles>
                <Flex direction="column">
                    <Flex direction="column">
                        <Collapse
                            defaultActiveKey={['0']}
                            expandIconPosition="right"
                            ghost
                            expandIcon={(e) => (
                                <ChevronDown
                                    style={
                                        e.isActive
                                            ? {
                                                  transform: 'rotate(180deg)',
                                                  transition: 'ease-in .2s',
                                                  top: '25%',
                                              }
                                            : {
                                                  transform: 'rotate(0deg)',
                                                  transition: 'ease-in .2s',
                                                  top: '25%',
                                              }
                                    }
                                />
                            )}
                        >
                            <Panel
                                header={
                                    <span style={{ fontSize: '1rem', marginLeft: '10px' }}>
                                        BULTOS DEL PEDIDO {fulfillment.name}
                                    </span>
                                }
                            >
                                <Collapse
                                    expandIconPosition="right"
                                    ghost
                                    onChange={setActiveKey}
                                    activeKey={activeKey}
                                    expandIcon={() => null}
                                >
                                    {Array.from(fulfillment?.packages ?? []).map((pkg, index) => (
                                        <Panel
                                            header={
                                                <CollapseHeaderStyles>
                                                    <Flex
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                        wrap="wrap"
                                                    >
                                                        <b
                                                            className="flex w-full no-wrap items-center"
                                                            style={{
                                                                fontSize: '1rem',
                                                            }}
                                                        >
                                                            <div className="mini-dot"></div>
                                                            <span>BULTO #{pkg.code}</span>
                                                        </b>
                                                        <Flex
                                                            direction="row"
                                                            className="text-xs w-full my-2"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                        >
                                                            <b className="text-xs">
                                                                Tipo Bulto:{' '}
                                                                <Tag>
                                                                    {pkg?.type?.name ??
                                                                        pkg?.type ??
                                                                        '-'}
                                                                </Tag>
                                                            </b>
                                                        </Flex>
                                                    </Flex>
                                                    <div className="flex underline items-center flex-row w-full justify-center">
                                                        <b style={{ color: '#1F6373' }}>
                                                            Ver detalles
                                                        </b>
                                                        <ChevronDown
                                                            style={
                                                                activeKey.includes(String(index))
                                                                    ? {
                                                                          transform:
                                                                              'rotate(180deg)',
                                                                          transition: 'ease-in .2s',
                                                                      }
                                                                    : {
                                                                          transform: 'rotate(0deg)',
                                                                          transition: 'ease-in .2s',
                                                                      }
                                                            }
                                                        />
                                                    </div>
                                                </CollapseHeaderStyles>
                                            }
                                            key={index}
                                        >
                                            <Flex direction="column">
                                                {Array.from(pkg?.line_items ?? [])?.map(
                                                    (li, index2) => {
                                                        let line_item =
                                                            Array.from(
                                                                pkg?.line_items_data ?? []
                                                            ).find((li2) => {
                                                                return (
                                                                    li2?._id?.toString() ===
                                                                    (li?.line_item?._id?.toString() ??
                                                                        li?.line_item)
                                                                );
                                                            }) ??
                                                            li?.line_item ??
                                                            {};

                                                        return (
                                                            <PackageProductsDetails key={index2}>
                                                                <span>
                                                                    <b>Nombre producto: </b>
                                                                    {line_item?.name}
                                                                </span>
                                                                <span>
                                                                    <b>SKU: </b>
                                                                    {line_item?.sku}
                                                                </span>
                                                                <span>
                                                                    <b>Cantidad: </b>
                                                                    {li.quantity}
                                                                </span>
                                                            </PackageProductsDetails>
                                                        );
                                                    }
                                                )}
                                            </Flex>
                                        </Panel>
                                    ))}
                                </Collapse>
                            </Panel>
                        </Collapse>

                        <br />
                        <br />

                        <FulfillmentDeliveryDetails>
                            <h1 style={{ fontSize: '1rem' }}>
                                DATOS DEL CLIENTE - PEDIDO {fulfillment.name}
                            </h1>
                            <Flex direction="column" rowGap="0.5rem">
                                <span>
                                    <b>N° de Pedido: </b>
                                    <span>{fulfillment.name}</span>
                                </span>
                                <span>
                                    <b>Fecha Pedido: </b>
                                    <span>
                                        {dayjs(
                                            fulfillment.order?.createdAt ?? fulfillment.createdAt
                                        ).format('DD/MM/YYYY HH:mm')}
                                    </span>
                                </span>
                                <span>
                                    <b>Tienda: </b>
                                    <span>{fulfillment?.origin?.name ?? ''}</span>
                                </span>
                                <span>
                                    <b>Cliente: </b>
                                    <span>
                                        {fulfillment?.order?.customer?.first_name ?? ''}{' '}
                                        {fulfillment?.order?.customer?.last_name ?? ''}{' '}
                                    </span>
                                </span>
                                <span>
                                    <b>Correo: </b>
                                    <span>{fulfillment?.order?.customer?.email ?? ''} </span>
                                </span>
                                <span>
                                    <b>Documento (Rut/Dni): </b>
                                    <span>
                                        {fulfillment?.order?.customer?.document
                                            ? format(fulfillment?.order?.customer?.document, {
                                                  dots: false,
                                              })
                                            : ''}{' '}
                                    </span>
                                </span>
                                <span>
                                    <b>Dirección: </b>
                                    <span>
                                        {fulfillment?.destination?.address1 ?? ''}
                                        {fulfillment?.destination?.address2 ? (
                                            <Fragment>
                                                ,<br />
                                                {fulfillment?.destination?.address2 ?? ''}
                                            </Fragment>
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </span>
                                <span>
                                    <b>Ciudad: </b>
                                    <span>{fulfillment?.destination?.city ?? ''}</span>
                                </span>
                                <span>
                                    <b>Region: </b>
                                    <span>{fulfillment?.destination?.province ?? ''}</span>
                                </span>
                                <span>
                                    <b>Teléfono: </b>
                                    <span>{fulfillment?.destination?.phone ?? ''}</span>
                                </span>
                            </Flex>
                        </FulfillmentDeliveryDetails>
                    </Flex>
                </Flex>
            </PackageDetailsDrawerStyles>
        </DrawerWithButton>
    );
};
export default PackageDetailsDrawer;
