import { Skeleton, Tooltip } from 'antd';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import FormatDuration from './FormatDuration';

const MetricCardContent = styled.div`
    height: 100%;
    background: #f7f9fb;
    border-left: 5px solid ${({ color }) => (color ? color : '#0497db')};
    box-shadow: 2px 0px 2px rgba(45, 61, 118, 0.2);
    border-radius: 6px;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-width: 340px;
    width: fit-content;
    padding-right: 30px;
    height: 90px;
`;

const MetricName = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.175rem;
    font-weight: 400;
    color: #536d8f;
    margin-bottom: 10px;
    font-size: 0.85rem;
`;

const MetricDetail = styled.div`
    display: flex;
    flex-direction: row;
    color: #2d3d76;

    h1,
    h3 {
        margin: 0;
        color: #2d3d76;
    }

    h1 {
        display: block;
        margin-right: 6px;
        font-size: 2rem;
        font-weight: 700;
        line-height: 1rem;
    }

    h3 {
        display: block;
        font-size: 1rem;
        font-weight: 300;
    }
`;

const MetricIconCard = styled.div`
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    z-index: 20;
    border-radius: 50%;
    background-color: #f7f9fb;
    box-shadow: 4px 0px 3px 0px rgba(165, 145, 145, 0.68);
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
`;

const MetricCard = ({
    color,
    name,
    tooltipTitle,
    metricType = '',
    quantity,
    measurement = '',
    icon,
    loading,
}) => {
    return (
        <MetricCardContent color={color}>
            <MetricName>
                {name}{' '}
                <Tooltip title={tooltipTitle}>
                    <InfoIcon width={20} height={20} />
                </Tooltip>
            </MetricName>
            <MetricDetail>
                {loading ? (
                    <Skeleton active paragraph={false} />
                ) : (
                    <>
                        {metricType === 'fulfillment_avg' ? (
                            <FormatDuration minutes={quantity ?? 0} />
                        ) : (
                            <>
                                <h1>{quantity}</h1>
                                <h3>{measurement}</h3>
                            </>
                        )}
                    </>
                )}
            </MetricDetail>
            <MetricIconCard>{icon}</MetricIconCard>
        </MetricCardContent>
    );
};

export default MetricCard;
