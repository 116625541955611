/* eslint-disable no-unused-vars */
import { Modal } from 'antd';
import JsonView from 'components/JsonView';

export const ModalRpro9ErrorDetail = ({ data, isOpen, onClose }) => {
    return (
        <Modal
            width={700}
            open={isOpen}
            cancelButtonProps={{ style: { display: 'none' } }}
            onClose={onClose}
            onOk={onClose}
            okText="Cerrar"
            title="Detalle de errores de integración"
            bodyStyle={{ overflow: 'auto' }}
        >
            <JsonView json={data} />
        </Modal>
    );
};
