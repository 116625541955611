/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { ConfigProvider, Tooltip, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReactDragListView from 'react-drag-listview';
// import useGetPreparationPreference from 'hooks/useGetPreparationPreference';
import AntdTable from 'components/AntdTable';
import TableEmpty from 'components/Table/Empty';
import Flex from 'components/Utils/Flex';
// import OrderManualIncidencesDetails from 'components/ManualIncidences/Order/ShowDetailsDrawer/OpenDrawerByIconography';
import { useUpdateUserPreferencesMutation } from 'redux/features/Users';
import { setManifestsFilters } from 'redux/features/Utils';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
// import { StyledOrderLink } from './styles';
import TableHeaderExtension from '../TableHeaderExtension';
import dayjs from 'dayjs';
import { FiArrowRight, FiChevronDown } from 'react-icons/fi';
import printJS from 'print-js';
import history from 'helpers/history';
import RowFulfillments from 'pages/Manifests/components/RowFulfillments';
import { ReactComponent as ShippingNoteIcon } from 'assets/print_shipping_note.svg';
import { ReactComponent as ReShippingNoteIcon } from 'assets/re_print_shipping_note.svg';
import { ReactComponent as FileDocumentArrowRight } from 'assets/file-document-arrow-right.svg';
import { ReactComponent as ProcessDeliveryIcon } from 'assets/process-delivery-icon.svg';
import { ReactComponent as FileDocument } from 'assets/file-document.svg';
import {
    useConfirmBatchDeliverMutation,
    useDeleteManifestMutation,
    useGenerateShippingNotesMutation,
} from 'redux/features/Deliveries';
import { Button } from '@andescommercecloud/beflow-design-system';
import Loading from 'components/Loading';
import { BiTrash } from 'react-icons/bi';
import { ModalTitleDeleteManifest } from './styles';
import useGetCurrentPermissions from 'hooks/useGetCurrentPermissions';
import { useGetOrdersConfigurationQuery } from 'redux/features/OrdersConfiguration';
import { deliveryPromiseDateFormatDic } from 'helpers/constants/deliveryPromiseDateFormat';
import { ModalValidateDeliveryPin } from 'pages/DeliveriesV2/components/ModalValidateDeliveryPin';
// import useGetPickupPreference from 'hooks/useGetPickupPreference';

const FulfillmentDeadline = React.lazy(() =>
    import('pages/OrdersV2/components/Table/FulfillmentDeadline')
);
const FulfillmentStatus = React.lazy(() =>
    import('pages/PendingArrivalsV2/components/FulfillmentStatus')
);

function DraggableTable({
    data,
    loading,
    userPreferences,
    selectedTab,
    refetch,
    selectedRows,
    handleSelectRows,
    tabColumns,
    handleChangeColumns,
    preferences,
    scanMode,
    massiveManifestsToProcess,
    deliveryPreferences,
}) {
    const { manifestsFilters } = useSelector((state) => state.utils);
    const [columnsTable, setColumnsTable] = useState([]);
    // const [, , slaPreferences] = useGetPreparationPreference();
    const [updateUserPreferences] = useUpdateUserPreferencesMutation();
    const [activeDropdownFilterColumn, setActiveDropdownFilterColumn] = useState(null);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [confirmBatchMutation, confirmBatchStatus] = useConfirmBatchDeliverMutation();
    const [, isAdmin] = useGetCurrentPermissions();
    const [deleteManifest] = useDeleteManifestMutation();
    const [isModalDeleteManifestVisible, setModalDeleteManifestVisible] = useState(false);
    const [isOpenModalReprocessShippingOrder, setIsOpenModalReprocessShippingOrder] =
        useState(false);
    const [selectedRow, setSelectedRow] = useState('');
    const [generateShippingNotes, { isLoading: loadingGenerateShippingNotes }] =
        useGenerateShippingNotesMutation();
    // const [pickupPreferencesLoading, pickupPreferences] = useGetPickupPreference();
    const { data: orderConfiguration } = useGetOrdersConfigurationQuery();
    const [openModalValidatePinDelivery, setOpenModalValidatePinDelivery] = useState(false);
    const [fulfillmentToValidate, setFulfillmentToValidate] = useState([]);
    const [validateDeliveries, setValidateDeliveries] = useState([]);
    const [manifestSelected, setManifestSelected] = useState();

    const deliveryPromiseDateFormat = orderConfiguration?.delivery_promise_date_format;

    const updateUserColumnsPreferencesHandler = async (newColumnsPreferences) => {
        // Debemos verificar si el tab.typeOfTab es basic o custom
        const activeTabIndex = userPreferences?.preferences[preferences]?.tabs?.findIndex(
            (tab) => tab.value === selectedTab
        );

        const newPreferences = structuredClone(userPreferences);

        newPreferences.preferences[preferences].tabs[activeTabIndex].columns = [
            ...newColumnsPreferences,
        ];

        // Eliminar tabs de tipo basic
        newPreferences.preferences[preferences].tabs = newPreferences.preferences[
            preferences
        ].tabs.filter((tab) => tab.typeOfTab !== 'basic');

        await updateUserPreferences(newPreferences);
    };

    const handleConfirmManifest = async (data) => {
        await confirmBatchMutation(data);
    };

    const handleClickDeleteManifest = async (id) => {
        await deleteManifest(id);
    };

    useEffect(() => {
        if (columnsTable?.length > 0) {
            const columnsCopy = columnsTable.slice();

            const newColumns = columnsCopy.map((el) => {
                el.className = '';

                if (manifestsFilters?.sort?.sortBy === el?.dataIndex) {
                    el.className = 'table-column-selected';
                }

                if (activeDropdownFilterColumn && el?.dataIndex === activeDropdownFilterColumn) {
                    el.className = 'table-column-active';
                }

                return el;
            });

            setColumnsTable(newColumns);
        }
    }, [manifestsFilters, activeDropdownFilterColumn]);

    const dispatch = useDispatch();

    const tableData = useMemo(() => {
        return scanMode
            ? Array.from(massiveManifestsToProcess ?? [])
            : Array.from(data?.docs ?? []);
    }, [data, massiveManifestsToProcess, scanMode]);

    const rowSelection = {
        preserveSelectedRowKeys: true,
        selectedRowKeys: selectedRows,
        onChange: (selectedRowKeys) => {
            handleSelectRows(selectedRowKeys);
        },
    };

    // Function para registrar Cambios de orden en los headers
    const onTableHeaderDragEnd = (fromIndex, toIndex) => {
        if (fromIndex === 0 || toIndex === 0) return;

        const columnsCopy = columnsTable.slice();

        const item = columnsCopy.splice(fromIndex - 1, 1)[0];

        columnsCopy.splice(toIndex - 1, 0, item);

        setColumnsTable(columnsCopy);

        const newColumnsViewStatus = columnsCopy.map((el, i) => ({
            name: el.dataIndex,
            label: el.title,
            status: true,
            position: i + 1,
        }));

        const disabledColumnsStatus = tabColumns.filter((el) => !el.status);

        const columnsViewStatusCopy = [...disabledColumnsStatus, ...newColumnsViewStatus];

        handleChangeColumns(columnsViewStatusCopy);

        updateUserColumnsPreferencesHandler(columnsViewStatusCopy);

        //setColumns(columnsViewStatusCopy)

        // dispatch(addTab(personalizedTab));
    };

    // Funcion para registrar cambios en la tabla
    const handleTableChange = (pagination, filters, sorter) => {
        let actuallySort = {};

        actuallySort = {
            sortBy: sorter.field,
            sortValue: sorter.value,
        };

        if (sorter.value === undefined) {
            actuallySort = {};
        }

        dispatch(
            setManifestsFilters({
                sort: actuallySort,
                page: pagination.current,
            })
        );

        refetch();
    };

    const tableLoading = {
        spinning: loading,
        indicator: <Loading loadingText="Cargando manifiestos..." $center />,
    };

    const handleProcessSingleManifest = async (manifest) => {
        setManifestSelected(manifest);
        const delivery_method = manifest?.delivery_method;

        const manifestFulfillments = manifest.fulfillments;

        const hasDeliveryPinValidation = manifestFulfillments.some(
            (fulfillment) => fulfillment.has_delivery_pin_validation
        );

        const showValidateDeliveryPin = hasDeliveryPinValidation & !validateDeliveries.length;

        const delivery_method_configs =
            deliveryPreferences?.delivery_options?.[delivery_method] ?? {};

        if (showValidateDeliveryPin) {
            setOpenModalValidatePinDelivery(true);
            setFulfillmentToValidate(
                manifestFulfillments.filter((fulfillment) => fulfillment.delivery_pin)
            );
            return;
        }
        if (delivery_method_configs?.enable_rescanning_packages) {
            history.push(`/manifests/${manifest._id}`);
        } else {
            await handleConfirmManifest({
                manifest_id: manifest._id,
                batch_id: manifest._id,
            });
        }
    };

    const handleExpandRow = (row_id) => {
        setExpandedKeys((prevState) =>
            prevState.includes(row_id)
                ? prevState.filter((id) => id !== row_id)
                : [...prevState, row_id]
        );
    };

    const allColumns = useMemo(
        () => [
            {
                dataIndex: 'manifest_id',
                title: 'ID de manifiesto',
                align: 'left',
                render: (value, row) => (
                    <Flex justifyContent="space-between" alignItems="center">
                        <span role="button" onClick={() => handleExpandRow(row?._id)}>
                            <u>{row?.manifest_id}</u>
                        </span>
                    </Flex>
                ),
                ...TableHeaderExtension({
                    columnKey: 'manifest_id',
                    type: 'string',
                    refetch,
                    setActiveDropdownFilterColumn,
                    tabColumns,
                }),
            },
            {
                dataIndex: 'fulfillments_length',
                title: 'Pedidos en entrega',
                render: (value, row) => {
                    return (
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            flex={1}
                            columnGap="1rem"
                            direction="row"
                        >
                            <b>{row?.fulfillments_length ?? 0}</b>
                            <Button
                                size="small"
                                variation="link"
                                style={{
                                    color: '#2D3D76',
                                    backgroundColor: 'white',
                                    width: 'fit-content',
                                    height: 'fit-content',
                                    fontWeight: 'bold',
                                    border: '1px solid #E4E8EC',
                                    borderRadius: '6px',
                                    padding: '1px',
                                }}
                                onClick={() => handleExpandRow(row?._id)}
                            >
                                <FiChevronDown
                                    className="manifest-table__expand-icon"
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                />
                            </Button>
                        </Flex>
                    );
                },
                ...TableHeaderExtension({
                    columnKey: 'fulfillments_length',
                    type: 'string',
                    refetch,
                    setActiveDropdownFilterColumn,
                    tabColumns,
                }),
            },
            {
                dataIndex: 'promised_date',
                title: deliveryPromiseDateFormatDic(deliveryPromiseDateFormat).title,
                render: (value, row) => {
                    const rowToSend = {
                        fulfillment_status: value ?? row.status ?? '',
                        createdAt: row.createdAt ?? '',
                        promised_date: row.promised_date,
                    };
                    return (
                        <Flex justifyContent="center" alignItems="center">
                            <Suspense fallback={<div>Cargando...</div>}>
                                <FulfillmentDeadline
                                    isDeadline={
                                        deliveryPromiseDateFormatDic(deliveryPromiseDateFormat)
                                            .isHours
                                    }
                                    row={rowToSend}
                                />
                            </Suspense>
                        </Flex>
                    );
                },
                ...TableHeaderExtension({
                    columnKey: 'promised_date',
                    type: 'number',
                    refetch,
                    setActiveDropdownFilterColumn,
                    tabColumns,
                }),
            },
            {
                dataIndex: 'status',
                title: 'Estado entrega',
                render: (value, row) => {
                    const statusRow = {
                        status: value ?? row.status ?? 'delivered',
                        createdAt: row.createdAt ?? '',
                        promised_date: row.closer_promiser_date ?? '',
                        isFulfillmentStatus: true,
                    };
                    return (
                        <Flex justifyContent="center" alignItems="center">
                            <Suspense fallback={<div>Cargando...</div>}>
                                <FulfillmentStatus row={statusRow} />
                            </Suspense>
                        </Flex>
                    );
                },
                ...TableHeaderExtension({
                    columnKey: 'status',
                    type: 'string',
                    refetch,
                    setActiveDropdownFilterColumn,
                    tabColumns,
                }),
            },
            {
                dataIndex: 'createdAt',
                title: 'Listo para entregar desde',
                render: (value) => dayjs(value).format('DD/MM/YYYY HH:mm'),
                ...TableHeaderExtension({
                    columnKey: 'createdAt',
                    type: 'number',
                    refetch,
                    setActiveDropdownFilterColumn,
                    tabColumns,
                }),
            },
            {
                dataIndex: 'delivered_at',
                title: 'Fecha de entrega',
                render: (value) => {
                    if (!value) return '-';
                    return dayjs(value).format('DD/MM/YYYY HH:mm');
                },
                ...TableHeaderExtension({
                    columnKey: 'delivered_at',
                    type: 'string',
                    refetch,
                    setActiveDropdownFilterColumn,
                    tabColumns,
                }),
            },
            {
                dataIndex: 'courier',
                title: 'Courier',
                render: (value) => {
                    return value?.public_name ?? 'NA';
                },
                ...TableHeaderExtension({
                    columnKey: 'courier',
                    type: 'string',
                    refetch,
                    setActiveDropdownFilterColumn,
                    tabColumns,
                }),
            },
            {
                dataIndex: 'delivery_method',
                title: 'Tipo de entrega',
                render: (value, row) => {
                    if (row?.delivery_method === 'pickup') {
                        return <span>Retiro en tienda</span>;
                    }

                    if (row?.delivery_method === 'shipping') {
                        return <span>Despacho</span>;
                    }

                    if (row?.delivery_method === 'consolidation') {
                        return <span>Consolidado</span>;
                    }

                    return <span>{row?.delivery_method ?? ''}</span>;
                },
                ...TableHeaderExtension({
                    columnKey: 'delivery_method',
                    type: 'string',
                    refetch,
                    setActiveDropdownFilterColumn,
                    tabColumns,
                }),
            },
            {
                dataIndex: 'documents',
                title: 'Documentos',
                render: (value, row) => {
                    const iconStyles = { fontSize: '1.6rem', cursor: 'pointer', color: '#2D3D76' };
                    return (
                        <Flex direction="row" columnGap="0.6rem" alignItems="center">
                            <Tooltip
                                title={
                                    !row.shipping_note_url
                                        ? 'Guías de despacho no disponible'
                                        : 'Imprimir guía de despacho'
                                }
                            >
                                <ShippingNoteIcon
                                    style={{
                                        ...iconStyles,
                                    }}
                                    onClick={() => {
                                        row?.shipping_note_url && printJS(row.shipping_note_url);
                                    }}
                                    className={!row.shipping_note_url ? 'disabled-icon' : ''}
                                />
                            </Tooltip>
                            <Tooltip
                                title={
                                    row?.manifest_url
                                        ? 'Imprimir manifiesto de entrega OMS'
                                        : 'Manifiesto no disponible'
                                }
                            >
                                <FileDocument
                                    style={iconStyles}
                                    onClick={() => row.manifest_url && printJS(row.manifest_url)}
                                    className={!row.manifest_url ? 'disabled-icon' : ''}
                                />
                            </Tooltip>
                            <Tooltip
                                title={
                                    row?.external_manifest?.external_url
                                        ? 'Imprimir manifiesto externo de entrega'
                                        : 'Manifiesto externo no disponible'
                                }
                            >
                                <FileDocumentArrowRight
                                    style={{ ...iconStyles, marginTop: '2px' }}
                                    onClick={() =>
                                        row?.external_manifest?.external_url &&
                                        printJS(row.external_manifest?.external_url)
                                    }
                                    disabled={!row?.external_manifest?.external_url}
                                    className={
                                        !row.external_manifest?.external_url ? 'disabled-icon' : ''
                                    }
                                />
                            </Tooltip>
                        </Flex>
                    );
                },
            },
            {
                dataIndex: 'actions',
                title: 'Acciones',
                render: (value, row) => {
                    const iconStyles = { fontSize: '1.6rem', cursor: 'pointer', color: '#2D3D76' };
                    return (
                        <Flex direction="row" columnGap="0.6rem" alignItems="center">
                            <Tooltip title="Re-procesar guía(s) de despacho">
                                <ReShippingNoteIcon
                                    role="button"
                                    onClick={() => {
                                        setSelectedRow(row?._id);
                                        setIsOpenModalReprocessShippingOrder(true);
                                    }}
                                    disabled={loadingGenerateShippingNotes}
                                />
                            </Tooltip>

                            <Tooltip title="Procesar entrega">
                                <ProcessDeliveryIcon
                                    style={iconStyles}
                                    onClick={() =>
                                        row.status !== 'delivered' &&
                                        handleProcessSingleManifest(row)
                                    }
                                    className={row.status === 'delivered' ? 'disabled-icon' : ''}
                                />
                            </Tooltip>

                            <Tooltip title="Cancelar entrega">
                                <BiTrash
                                    style={{
                                        ...iconStyles,
                                        marginTop: '2px',
                                        cursor: 'pointer',
                                    }}
                                    className={
                                        !isAdmin || row.status !== 'pending' ? 'disabled-icon' : ''
                                    }
                                    onClick={() => {
                                        if (isAdmin || row.status !== 'pending') {
                                            setSelectedRow(row?._id);
                                            setModalDeleteManifestVisible(true);
                                        }
                                    }}
                                />
                            </Tooltip>
                        </Flex>
                    );
                },
            },
        ],
        [
            data,
            deliveryPreferences,
            selectedRows,
            expandedKeys,
            handleExpandRow,
            isAdmin,
            confirmBatchStatus.isLoading,
            loadingGenerateShippingNotes,
        ]
    );

    useEffect(() => {
        if (tabColumns) {
            const columnsToShow = tabColumns
                .filter((column) => column.status)
                .map((column) => column.name);

            const newColumns = allColumns
                .filter((column) => columnsToShow.includes(column.dataIndex))
                .map((el) => {
                    el.className = '';

                    if (manifestsFilters?.sort?.sortBy === el?.dataIndex) {
                        el.className = 'table-column-selected';
                    }

                    return el;
                });

            const viewStatusSorted = tabColumns.concat().sort((a, b) => a.position - b.position);

            newColumns.sort((a, b) => {
                const aIndex = viewStatusSorted.findIndex((item) => item.name === a.dataIndex);
                const bIndex = viewStatusSorted.findIndex((item) => item.name === b.dataIndex);

                return aIndex - bIndex;
            });

            setColumnsTable(newColumns);
        }
    }, [tabColumns, isAdmin, deliveryPromiseDateFormat]);

    useEffect(() => {
        if (validateDeliveries.length) {
            handleProcessSingleManifest(manifestSelected);
        }
    }, [validateDeliveries]);

    const TableView = (
        <ConfigProvider
            renderEmpty={() => (
                <TableEmpty
                    loading={loading && !data?.docs}
                    loadingText="Cargando manifiestos..."
                />
            )}
        >
            <AntdTable
                showSorterTooltip={false}
                locale={{
                    triggerDesc: 'Click para ver pedidos más recientes',
                    triggerAsc: 'Click para ver pedidos más recientes',
                    cancelSort: 'Click para ver pedidos más antiguos',
                }}
                displayNone={columnsTable?.length < 1}
                sortDirections={['descend', 'ascend', 'descend']}
                scroll={{ x: true }}
                tableName="fulfillment-preparations-table"
                onChange={(pagination, filters) =>
                    handleTableChange(pagination, filters, {
                        field: manifestsFilters?.sort?.sortBy,
                        value: manifestsFilters?.sort?.sortValue,
                    })
                }
                multiSelect
                rowSelection={rowSelection}
                rowClassName={(record, index) => {
                    if (expandedKeys.includes(record?._id)) {
                        if (index % 2 === 0) {
                            return 'manifest_table__row-expanded isEven';
                        }
                        return 'manifest_table__row-expanded isOdd';
                    }
                }}
                loading={data?.docs && tableLoading && loadingGenerateShippingNotes}
                styleV2={true}
                rowKey="_id"
                headerBackground
                actionsPadding="0px"
                dataSource={tableData}
                expandable={{
                    expandIcon: () => null,
                    expandedRowRender: (row) => (
                        <RowFulfillments
                            manifest_id={row?._id}
                            deliveryPreferences={deliveryPreferences}
                        />
                    ),
                    rowExpandable: () => true,
                    expandedRowKeys: expandedKeys,
                    expandIconColumnIndex: -1,
                    expandedRowClassName: (_, index) => {
                        if (index % 2 === 0) {
                            return 'manifest_table__row-expanded isEven';
                        }
                        return 'manifest_table__row-expanded isOdd';
                    },
                }}
                columns={columnsTable}
                paginationStyle={true}
                pagination={{
                    position: ['bottomCenter'],
                    showSizeChanger: false,
                    showQuickJumper: false,
                    showLessItems: true,
                    ...(scanMode
                        ? {
                              total: Array.from(massiveManifestsToProcess ?? [])?.length,
                              current: 1,
                              pageSize: Array.from(massiveManifestsToProcess ?? [])?.length + 1,
                          }
                        : {
                              total: data?.totalDocs ?? 0,
                              current: data?.page ?? 1,
                              pageSize: data?.limit ?? 25,
                          }),

                    itemRender: (page, type, originalElement) => {
                        if (type === 'prev') {
                            return (
                                <Button
                                    type="text"
                                    icon={<LeftOutlined />}
                                    disabled={data?.page === 1}
                                    onClick={() => {
                                        handleTableChange(data?.page ?? 1, null, {
                                            field: manifestsFilters?.sort?.sortBy,
                                            value: manifestsFilters?.sort?.sortValue,
                                        });
                                    }}
                                />
                            );
                        }
                        if (type === 'next') {
                            return (
                                <Button
                                    type="text"
                                    icon={<RightOutlined />}
                                    disabled={data?.page === data?.totalPages}
                                    onClick={() => {
                                        handleTableChange(data?.page ?? 1, null, {
                                            field: manifestsFilters?.sort?.sortBy,
                                            value: manifestsFilters?.sort?.sortValue,
                                        });
                                    }}
                                />
                            );
                        }
                        return <>{originalElement}</>;
                    },
                }}
            />
        </ConfigProvider>
    );

    const titleModalDeleteManifest = (
        <ModalTitleDeleteManifest>Cancelar manifiesto</ModalTitleDeleteManifest>
    );

    return (
        <>
            <ReactDragListView.DragColumn
                onDragEnd={onTableHeaderDragEnd}
                nodeSelector="th"
                lineClassName={'global-drag-line'}
            >
                {TableView}
            </ReactDragListView.DragColumn>
            <ModalValidateDeliveryPin
                onSuccess={(e) => {
                    setValidateDeliveries(e);
                    setOpenModalValidatePinDelivery(false);
                }}
                onClose={() => setOpenModalValidatePinDelivery(false)}
                fulfillments={fulfillmentToValidate}
                isOpen={openModalValidatePinDelivery}
            />
            <Modal
                onCancel={() => setIsOpenModalReprocessShippingOrder(false)}
                onOk={() => {
                    setIsOpenModalReprocessShippingOrder(false);
                    generateShippingNotes({ manifest_ids: [selectedRow] });
                    setSelectedRow('');
                }}
                open={isOpenModalReprocessShippingOrder}
                title="Re-procesar guia(s) de despacho"
                okText="Aceptar"
            >
                <span style={{ color: '#142C4A' }}>
                    ¿Confirma el re-procesamiento de las guias de despacho?
                </span>
            </Modal>
            <Modal
                onCancel={() => setModalDeleteManifestVisible(false)}
                onOk={() => {
                    setModalDeleteManifestVisible(false);
                    handleClickDeleteManifest(selectedRow);
                    setSelectedRow('');
                }}
                open={isModalDeleteManifestVisible}
                title={titleModalDeleteManifest}
                name={'modal-delete-manifest'}
                okText={'Aceptar'}
            >
                <span style={{ color: '#142C4A' }}>
                    ¿Confirma cancelación de manifiesto y retorno de pedido a módulo de entregas?
                </span>
            </Modal>
        </>
    );
}

export default DraggableTable;
