import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import OrderJsonPage from '../OrderJson';
import { useGetOrderQuery } from 'redux/features/Orders';
import Header from './components/Header/Header';
import FulfillmentsSection from './components/Fulfillments';
import OrderData from './components/OrderData';
import OrderStates from './components/OrderStates';
import Container from 'components/Grid/Container';
import { useEffect, useState } from 'react';
// import { OrderSummary } from './components/Fulfillments/orderSummary';
import NotFoundPage from 'pages/NotFound';
import { LoadingLayout } from 'pages/NotFound/loadingLayout';
import { useGetOrdersConfigurationQuery } from 'redux/features/OrdersConfiguration';

const PageLayout = styled.div`
    padding: 1rem 1rem 2.5rem 1rem;
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr minmax(300px, 500px);

    @media (max-width: 1080px) {
        grid-template-columns: 1fr;
    }

    .order-page__header {
        grid-row: 1;
        grid-column: span 2;

        @media (max-width: 1080px) {
            grid-row: auto;
            grid-column: auto;
        }
    }

    .order-page__fulfillments {
        grid-row: 2;
        grid-column: 1;

        @media (max-width: 1080px) {
            grid-row: 2;
            grid-column: auto;
        }
    }

    .order-page__order-data {
        grid-row: span 3;
        grid-column: 2;

        @media (max-width: 1080px) {
            grid-row: auto;
            grid-column: auto;
        }
    }
    .order-page_summary {
        grid-row: 3;
        grid-column: auto;
        margin-top: -18px;
        @media (max-width: 1080px) {
            grid-row: 3;
            grid-column: auto;
        }
    }

    .order-page__order-timeline {
        grid-row: auto;
        grid-column: auto;
    }
`;

const OrderPage = () => {
    const { order_id } = useParams();
    const [infoSelected, setInfoSelected] = useState(null);

    if (String(order_id ?? '').includes('.json') || String(order_id ?? '').includes('.logs')) {
        return (
            <OrderJsonPage
                order_id={String(order_id).replace('.json', '').replace('.logs', '')}
                onlyLogs={String(order_id ?? '').includes('.logs')}
            />
        );
    }

    const {
        isLoading,
        data = {},
        isFetching,
        refetch,
    } = useGetOrderQuery(order_id, { refetchOnMountOrArgChange: true });

    const { data: orderConfiguration } = useGetOrdersConfigurationQuery();

    const deliveryPromiseDateFormat = orderConfiguration?.delivery_promise_date_format;

    const showLoading = isLoading || isFetching;

    useEffect(() => {
        const forceRefetchWithButton = (event) => {
            const key = event?.key;
            if (key === '.' && !showLoading && data?._id) {
                refetch(true);
            }
        };

        document.addEventListener('keypress', forceRefetchWithButton);

        return () => {
            document.removeEventListener('keypress', forceRefetchWithButton);
        };
    }, [showLoading, data]);

    return (
        <>
            {isFetching ? (
                <LoadingLayout />
            ) : Object.keys(data).length === 0 ? (
                <NotFoundPage />
            ) : (
                <Container>
                    <PageLayout>
                        <div className="order-page__header">
                            <Header loading={showLoading} data={data} refetch={refetch} />
                        </div>
                        <div className="order-page__order-data">
                            <OrderData
                                data={data}
                                infoSelected={infoSelected}
                                loading={showLoading}
                                refetch={refetch}
                                setInfoSelected={setInfoSelected}
                            />
                        </div>

                        <div className="order-page__fulfillments">
                            <FulfillmentsSection
                                deliveryPromiseDateFormat={deliveryPromiseDateFormat}
                                loading={showLoading}
                                data={data}
                                setInfoSelected={setInfoSelected}
                                infoSelected={infoSelected}
                                refetch={refetch}
                            />
                        </div>

                        {/* <div className="order-page_summary">
                            <OrderSummary orderData={data} loading={showLoading} />
                        </div> */}

                        <div className="order-page__order-timeline">
                            <OrderStates data={data} loading={showLoading} />
                        </div>
                    </PageLayout>
                </Container>
            )}
        </>
    );
};

export default OrderPage;
