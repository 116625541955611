import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const getAllUsers = createAsyncThunk('users/getAll', async (_, state) => {
    try {
        const res = await AndesClient.get(`/users`);
        return res.data;
    } catch (error) {
        toast.error(`Error: ${error.response.data.message}`);
        return state.rejectWithValue(error.response.data.message);
    }
});

const getAllUsersSlice = createSlice({
    name: 'getAllUsers',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {
        addUserToStore: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                data: [...state.data, payload],
            };
        },
        deleteUserFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.filter(({ _id }) => _id !== payload);
            return {
                ...state,
                data: [...newArrayData],
            };
        },
        updateUserFromStore: (state, action) => {
            const { payload } = action;
            const newArrayData = state.data.map((user) => {
                if (user._id === payload._id) {
                    return {
                        ...user,
                        ...payload,
                    };
                } else {
                    return user;
                }
            });
            return {
                ...state,
                data: [...newArrayData],
            };
        },
    },
    extraReducers: {
        [getAllUsers.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getAllUsers.fulfilled]: (state, action) => {
            return {
                ...state,
                loading: false,
                data: action.payload,
                success: true,
            };
        },
        [getAllUsers.rejected]: (state, action) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        },
    },
});
export const { addUserToStore, deleteUserFromStore, updateUserFromStore } =
    getAllUsersSlice.actions;
export default getAllUsersSlice.reducer;
