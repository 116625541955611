export const promisedDateRanges = [
    {
        value: '08:00 a 10:00',
        label: '08:00 a 10:00',
    },
    {
        value: '10:00 a 12:00',
        label: '10:00 a 12:00',
    },
    {
        value: '12:00 a 14:00',
        label: '12:00 a 14:00',
    },
    {
        value: '14:00 a 16:00',
        label: '14:00 a 16:00',
    },
    {
        value: '16:00 a 18:00',
        label: '16:00 a 18:00',
    },
    {
        value: '18:00 a 20:00',
        label: '18:00 a 20:00',
    },
];
