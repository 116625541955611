import dayjs from 'helpers/dayjs';

export function getSlaPercentage({ delivery_date, promise_date, created_at, timezone }) {
    const current = dayjs(delivery_date || undefined)
        .tz(timezone)
        .utc(false);
    let currentPromise = promise_date;
    const promise = dayjs(currentPromise).tz(timezone).utc();
    const created = dayjs(created_at).tz(timezone).utc();

    const totalDiffMinutes = promise.diff(created, 'minute');
    const elapsedDiffMinutes = current.diff(created, 'minute');

    const percentage = (elapsedDiffMinutes / totalDiffMinutes) * 100;

    return percentage;
}
