import { isObjectEmpty } from 'helpers/filterObjectByCustom';

const CurrentIncidence = ({
    incidence: {
        line_items_ids = [],
        incidence_type = '',
        responsible_id = {},
        comment = '',
        incidence_quantity = {},
        ocurrence = '',
    },
}) => {
    const writeIncidencesTypes = (type) => {
        if (type === 'other') {
            return 'Otro';
        } else if (type === 'insufficient_stock') {
            return 'Stock Insuficiente';
        } else if (type === 'product_in_bad_condition') {
            return 'Producto en mal estado';
        } else {
            return '';
        }
    };

    return (
        <div className="p-3 my-1" style={{ border: '2px solid #1F6373', borderRadius: '5px' }}>
            <div className="w-full flex flex-col justify-center mb-3">
                <span className="font-bold">INCIDENCIA PRODUCTO(S) : </span>
                {line_items_ids[0] &&
                    line_items_ids.map((lis) => {
                        return (
                            <span key={lis._id} className="text-xs">
                                {lis.name}
                            </span>
                        );
                    })}
            </div>
            {/* TIPO INCIDENCIA */}
            <div className="mb-2">
                <span className="font-bold">Tipo de incidencia</span>
                <span
                    className="px-3 py-1 ml-2"
                    style={{ backgroundColor: '#1F6373', color: 'white', borderRadius: '3px' }}
                >
                    {writeIncidencesTypes(incidence_type)}
                </span>
            </div>
            {/* OCURRENCIA */}
            <div className="mb-2">
                <span className="font-bold">Ocurrencia</span>
                <span className="px-3 py-1 ml-2">{ocurrence ? ocurrence : 'N/A'}</span>
            </div>
            {/* CANTIDADES CON INCIDENCIA */}
            <div className="mb-2">
                <span className="font-bold">Cantidades con incidencia</span>
                {!isObjectEmpty(incidence_quantity) ? (
                    Object.entries(incidence_quantity).map((iq) => {
                        const lineItemName = line_items_ids.find((li) => li._id === iq[0]);
                        return (
                            <div className="px-3 py-1 ml-2" key={iq[0]}>
                                <span className="underline">{lineItemName?.name}</span>
                                <span
                                    className="ml-2 font-bold px-2 py-1"
                                    style={{
                                        backgroundColor: '#1F6373',
                                        color: 'white',
                                        borderRadius: '3px',
                                    }}
                                >
                                    {iq[1]}
                                </span>
                            </div>
                        );
                    })
                ) : (
                    <span className="ml-2">N/A</span>
                )}
            </div>
            {/* RESPONSABLE */}
            <div className="mb-2">
                <span className="font-bold">Responsable del reporte</span>
                <span
                    className="px-3 py-1 ml-2 capitalize"
                    style={{ backgroundColor: '#1F6373', color: 'white', borderRadius: '3px' }}
                >
                    {responsible_id?.firstname || responsible_id?.lastname
                        ? `${responsible_id?.firstname} ${responsible_id?.lastname}`
                        : 'Servicio externo'}
                </span>
            </div>
            {/* COMENTARIO */}
            <div className="mb-2">
                <span className="font-bold">Comentario</span>
                <span className="px-3 py-1 ml-2">{comment ? comment : 'N/A'}</span>
            </div>
        </div>
    );
};

export default CurrentIncidence;
