const breakpoints = {
    smallDesktop: '1200px',
    tablet: '980px',
    mobile: '768px',
};

const fontSizes = {
    desktop: '16px',
    mobile: '14px',
};

const lightTheme = {
    fontSizes,
    breakpoints,
    gap: '1.6rem',
    background: {
        muted: '#f0f2f5',
        html: '#f8f8f8',
        navbar: '#043540',
        sidebar: 'white',
        primaryButton: '#1f6373',
    },
    borderColor: {
        primary: '#BACBD9',
        secondary: '#7998a7',
    },
    font: {
        primary: '#536d8f',
        secondary: '#585858',
        muted: '#868686',
        error: '#E33512',
        label: '#2D3D76',
    },
    buttons: {
        height: '2.5rem',
        gap: '0.5rem',
        colors: {
            primary: {
                first: '#F27127',
                border: '#E79B09',
                hover: '#F48647',
                focus: '#DE6723',
            },
            secondary: {
                first: '#fff',
                border: '#2D3D76',
                hover: '#2D3D76',
                focus: '#2D3D76',
            },
        },
    },
    dropdown: {
        colors: {
            border: '#2D3D76',
            hover: '#037FB9',
            active: '#037FB9',
        },
    },
    select: {
        colors: {
            border: '#2D3D76',
        },
    },
    inputs: {
        colors: {
            border: {
                initial: '#7998A7',
                active: '#2D3D76',
                focus: '#2D3D76',
                success: '#036896',
            },
        },
    },
};

const darkTheme = {
    fontSizes,
    breakpoints,
    gap: '10px',
    background: {
        muted: 'black',
        html: 'black',
        navbar: 'black',
        sidebar: 'black',
    },
    borderColor: {
        primary: 'white',
    },
    font: {
        primary: 'white',
        secondary: 'gray',
    },
};

export const themes = {
    light: lightTheme,
    dark: darkTheme,
};
