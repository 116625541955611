/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useGetFulfillmentScannedStatusMutation } from 'redux/features/Fulfillments';
import {
    clearFullfilmentMassivePreparation,
    addFulfillmentToMassivePreparation,
} from 'redux/features/Utils';

import Input from 'components/V2/Input';
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg';
import { dispatchers } from '../Filters/config';
import { changeState } from 'redux/features/Preparations/preparationState';
import { useDebounce } from 'hooks/useDebounce';

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 3px;
    height: 40px;
    transition: 0.2s;
    width: 320px;
`;

const SearchInput = ({
    value,
    onChange,
    refetch,
    dispatchersType,
    scanMode = false,
    disabled = false,
    setMassiveFulfillmentPendingArrival = () => {},
    massiveFulfillmentPendingArrival,
    name,
    handleReceiptPrintOnScan,
    stateSearchKey = 'searchTerm',
}) => {
    const [getFulfillmentStatus, scanStatus] = useGetFulfillmentScannedStatusMutation();
    const [searchTerm, setSearchTerm] = useState(undefined);

    const debounceValue = useDebounce(searchTerm, 1000);

    const scannedInputRef = useRef(null);
    const dispatch = useDispatch();
    const { setFilters } = dispatchers[dispatchersType];
    const {
        preparationsFilters,
        massiveFulfillmentPreparation = [],
        pendingArrivalsFilters,
    } = useSelector((state) => state.utils);

    const [isMounted, setIsMounted] = useState(false);

    const preparationState = useSelector((state) => state.preparationState);

    const handleSearch = async (value = '', hard = false) => {
        value = value.trim();
        dispatch(setFilters({ [stateSearchKey]: value }));

        if (!scanMode) {
            return refetch(hard);
        }

        if (name !== 'pendingArrivals-search-input') {
            if (!value) return;

            const response = await getFulfillmentStatus({
                searchTerms: value,
                view: 'fulfillments',
                filters: preparationsFilters,
            });

            const firstFulfillment = response?.data?.[0];
            const searchTerm = (value ?? '').toUpperCase().trim();

            for (const fulfillment of Array.from(response?.data ?? [])) {
                if (massiveFulfillmentPreparation.find((item) => item._id === fulfillment._id)) {
                    return;
                }
                handleReceiptPrintOnScan && handleReceiptPrintOnScan(firstFulfillment, searchTerm);
                dispatch(addFulfillmentToMassivePreparation(fulfillment));
            }
        } else {
            const responsePendingArrival = await getFulfillmentStatus({
                searchTerms: value,
                view: 'pending_arrival',
                filters: pendingArrivalsFilters,
            });

            for (const fulfillment of Array.from(responsePendingArrival?.data ?? [])) {
                if (massiveFulfillmentPendingArrival.find((item) => item._id === fulfillment._id)) {
                    return;
                }
                // dispatch(addFulfillmentToMassivePendingArrival(fulfillment));
                setMassiveFulfillmentPendingArrival((prev) => [...prev, fulfillment]);
            }
        }
    };

    const handleScan = () => {
        if (scannedInputRef.current) scannedInputRef.current.focus();
    };

    const enableScanMode = () => {
        if (scannedInputRef.current) scannedInputRef.current.focus();
        console.log('enableScanMode');
        window.addEventListener('keydown', handleScan);
        setSearchTerm(undefined);
    };

    const disableScanMode = () => {
        console.log('disableScanMode');
        window.removeEventListener('keydown', handleScan);
        dispatch(clearFullfilmentMassivePreparation());
        setMassiveFulfillmentPendingArrival([]);
        setSearchTerm(undefined);
    };

    const handleChange = (e) => {
        onChange && onChange(e.target.value);
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        if (isMounted) {
            const prevSearchTerm = preparationState?.initialSearchTerm;
            if (!scanMode) {
                handleSearch(debounceValue);
            }
            prevSearchTerm && dispatch(changeState({ initialSearchTerm: '' }));
        }
    }, [debounceValue]);

    useEffect(() => {
        if (isMounted) {
            if (scanMode) {
                enableScanMode();
            } else {
                handleSearch('', true);
                disableScanMode();
            }
        }
        setIsMounted(true);
        return () => {
            if (isMounted) {
                disableScanMode();
                setIsMounted(false);
            }
        };
    }, [scanMode]);

    return (
        <SearchWrapper>
            <Input
                value={value !== undefined ? value : searchTerm ?? ''}
                placeholder={scanMode ? 'Escanear / introducir código de orden' : 'Búsqueda'}
                ref={scannedInputRef}
                onChange={handleChange}
                disabled={disabled || scanStatus.isLoading || scanStatus.isFetching}
                style={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                }}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        e?.target?.value && handleSearch(e?.target?.value, true);
                        if (scanMode) {
                            setSearchTerm(undefined);
                            onChange && onChange('');
                        }
                    }
                }}
            />
            <PrimaryButton
                onClick={() => {
                    const currentSearchTerm = value !== undefined ? value : searchTerm;
                    handleSearch(currentSearchTerm);
                    if (scanMode) {
                        setSearchTerm(undefined);
                        onChange && onChange('');
                    }
                }}
                style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                }}
            >
                <SearchIcon />
            </PrimaryButton>
        </SearchWrapper>
    );
};

export default SearchInput;
