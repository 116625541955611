export const removeBasicTabs = (preferences) => {
    const newPreferences = structuredClone(preferences);

    // ESTO ES POR MIENTRAS PARA NO ROMPER PEDIDOS
    newPreferences.tabs = newPreferences?.tabs?.filter((tab) => {
        return tab.typeOfTab !== 'basic';
    });

    newPreferences.preferences.orders.tabs = newPreferences?.preferences?.orders?.tabs?.filter(
        (tab) => {
            return tab.typeOfTab !== 'basic';
        }
    );

    newPreferences.preferences.preparations.tabs =
        newPreferences?.preferences?.preparations?.tabs?.filter((tab) => {
            return tab.typeOfTab !== 'basic';
        });

    newPreferences.preferences.pendingArrivals.tabs =
        newPreferences?.preferences?.pendingArrivals?.tabs?.filter((tab) => {
            return tab.typeOfTab !== 'basic';
        });

    newPreferences.preferences.deliveries.tabs =
        newPreferences?.preferences?.deliveries?.tabs?.filter((tab) => {
            return tab.typeOfTab !== 'basic';
        });

    return newPreferences;
};
