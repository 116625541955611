import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { toggleModal } from 'redux/features/Utils';
import FormModal from 'components/Modal/FormModal';
import InfoFilter from 'components/V2/Filters/InfoFilter';
import FilterForm from 'components/V2/Forms/FilterForm';
import SecondaryButton from 'components/V2/Buttons/SecondaryButton';
import { dispatchers } from 'components/V2/Filters/config';
import { ReactComponent as FilterIcon } from 'assets/filters-icon.svg';

const FloatingFiltersContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 0.9rem;
    padding: 10px 20px 0 0;
    .title {
        color: #1d71b5;
    }
`;

function FilterButton({
    userPreferences,
    selectedTab,
    selectedTabIndex,
    loading = false,
    refetch,
    data,
    modalName,
    formName,
    filters = [],
    valueOptionByField = {},
    dispatchersType,
}) {
    const dispatch = useDispatch();
    const buttonRef = useRef();

    // Get Dispatchers
    const { overrideFilters } = dispatchers[dispatchersType];

    // Get a deep copy of filters every time tab changes
    const memoizedFiltersBeforeModal = useMemo(() => {
        return structuredClone(filters);
    }, [selectedTab, data]);

    const handleOpenModal = () => {
        dispatch(toggleModal(modalName));
        buttonRef.current.blur();
    };

    const handleCancelModal = () => {
        dispatch(overrideFilters(memoizedFiltersBeforeModal));
        dispatch(toggleModal(modalName));
    };

    return (
        <>
            <FormModal
                formComponent={<FilterForm />}
                formProps={{
                    formName: formName,
                    modalName: modalName,
                    userPreferences,
                    selectedTab,
                    selectedTabIndex,
                    refetch,
                    memoizedFiltersBeforeModal,
                    dispatchersType,
                    filters,
                    valueOptionByField,
                }}
                modalProps={{
                    modalName: modalName,
                    title: (
                        <FloatingFiltersContainer>
                            <span className="title">Filtrando por: </span>
                            {filters.length === 0 ? (
                                <InfoFilter loading={loading} />
                            ) : (
                                filters
                                    ?.filter((filter) => filter?.hide !== true)
                                    ?.map((filter) => (
                                        <InfoFilter
                                            key={filter.id}
                                            filter={filter}
                                            preferences={dispatchersType}
                                            valueOptionByField={valueOptionByField}
                                        />
                                    ))
                            )}
                        </FloatingFiltersContainer>
                    ),
                    onCancel: handleCancelModal,
                    width: 700,
                    footer: null,
                }}
            />
            <SecondaryButton
                ref={buttonRef}
                loading={loading}
                disabled={loading}
                onClick={handleOpenModal}
                icon={<FilterIcon />}
                iconPosition="right"
            >
                Filtros {loading ? '' : `(${filters.length})`}
            </SecondaryButton>
        </>
    );
}

export default FilterButton;
