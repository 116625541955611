import { Form, Input, Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from 'assets/info.svg';
import { Checkbox, Radio } from 'components/Form';
import Loading from 'components/Loading';
import Flex from 'components/Utils/Flex';
import { Title } from 'pages/Settings/styles';
import { useFindProductTypesQuery } from 'redux/features/Master';
import { rulesRequired } from 'utils';
import { Text } from './styles';

export const ClassificationProductTypeForm = ({ form }) => {
    const { data: dataProductType, isLoading: isLoadingProductType } = useFindProductTypesQuery({
        parseByConfig: true,
    });

    const classificationProductTypeWatch = Form.useWatch(
        ['classification_product_type', 'enabled'],
        form
    );
    const type_operation = Form.useWatch(['classification_product_type', 'type_operation'], form);

    console.log('type_operation', type_operation);

    return (
        <div>
            <Title>Compatibilidad por tipo de producto</Title>

            <Form.Item valuePropName="checked" name={['classification_product_type', 'enabled']}>
                <Checkbox>Habilitar compatibilidad con tipo de producto</Checkbox>
            </Form.Item>

            {classificationProductTypeWatch && (
                <>
                    <Flex direction="column" marginBottom="8px">
                        <Text>Los tipos de productos en OMS son:</Text>
                        {isLoadingProductType ? (
                            <Loading />
                        ) : (
                            Array.from(dataProductType ?? [])?.map((productType) => (
                                <Text key={productType._id} paddingLeft="16px">
                                    {productType?.label} = {productType.description}
                                </Text>
                            ))
                        )}
                    </Flex>

                    <br />

                    <Flex direction="column" columnGap="0.5rem">
                        <Text>
                            <Flex direction="row" alignItems="center">
                                <span>
                                    Especifique la capacidad de preparación (minutos) que puede
                                    tomar la preparación de cada tipo de producto:
                                </span>

                                <Tooltip
                                    title="Es el total de tiempo que requerirá la preparación
                                                de ese tipo de producto y se establece en minutos"
                                    className="flex items-center ml-1"
                                >
                                    <InfoIcon width={20} height={20} />
                                </Tooltip>
                            </Flex>
                        </Text>
                        <br />
                        {isLoadingProductType ? (
                            <Loading />
                        ) : (
                            dataProductType?.map((productType) => (
                                <Flex direction="row" key={productType._id} alignItems="center">
                                    <Text
                                        paddingBottom="10px"
                                        paddingLeft="16px"
                                        style={{ width: 120 }}
                                    >
                                        Producto {productType.label}
                                    </Text>
                                    <Form.Item
                                        name={[
                                            'classification_product_type',
                                            'capacity_preparation_required',
                                            productType.key,
                                        ]}
                                        rules={[
                                            {
                                                required: true,
                                                pattern: /^-?[0-9]\d*$/,
                                                message: '',
                                                whitespace: true,
                                            },
                                        ]}
                                    >
                                        <Input style={{ width: 80, minHeight: 20 }} />
                                    </Form.Item>
                                </Flex>
                            ))
                        )}

                        <Form.Item
                            name={['classification_product_type', 'type_operation']}
                            initialValue="type_product_and_sku"
                            rules={[rulesRequired]}
                        >
                            <Radio.Group>
                                <Radio value="type_product_and_sku">
                                    Por Tipo de producto y SKU
                                </Radio>
                                <Radio value="type_product">Por tipo de producto</Radio>
                                <Radio value="unit">Por unidad</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Flex>
                </>
            )}

            {!classificationProductTypeWatch && (
                <Flex direction="column">
                    <Flex direction="row" alignItems="center">
                        <Text paddingBottom="10px" paddingLeft="16px" style={{ width: 160 }}>
                            Producto por defecto
                        </Text>
                        <Form.Item
                            name={[
                                'classification_product_type',
                                'capacity_preparation_required',
                                'default',
                            ]}
                            rules={[
                                {
                                    pattern: /^-?[0-9]\d*$/,
                                    message: '',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input style={{ width: 80, minHeight: 20 }} />
                        </Form.Item>
                    </Flex>

                    <Form.Item
                        key="type_operation"
                        form={form}
                        name={['classification_product_type', 'type_operation']}
                        initialValue="type_product_and_sku"
                        rules={[rulesRequired]}
                    >
                        <Radio.Group>
                            <Radio value="type_product_and_sku">Por Tipo de producto y SKU</Radio>
                            <Radio value="type_product">Por tipo de producto</Radio>
                            <Radio value="unit">Por unidad</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Flex>
            )}
        </div>
    );
};
