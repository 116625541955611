import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

// Asignamos los usuarios a la orden
export const assignUsersToOrders = createAsyncThunk('orders/assign', async (data, state) => {
    try {
        const response = await AndesClient.put('/orders/assign', data);

        if (response.status === 200) {
            toast.success('Operador asignado con éxito.');
        }

        return await response.data;
    } catch (error) {
        return state.rejectWithValue(error.message);
    }
});

const assignOrdersSlice = createSlice({
    name: 'assign-orders',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [assignUsersToOrders.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [assignUsersToOrders.fulfilled]: (state) => {
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };
        },
        [assignUsersToOrders.rejected]: (state, payload) => {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        },
    },
});

export default assignOrdersSlice.reducer;
