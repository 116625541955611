import { Button, Menu } from 'antd';
import styled, { css } from 'styled-components';

export const MenuStyled = styled(Menu)`
    letter-spacing: 0.4px;
    font-weight: 500;
    border: 1px solid #036896;

    .actions_product_table_title {
        color: #536d8f;
        margin-left: 10px;
        font-size: 10px;
    }
    .actions_product_table_option {
        color: #41515e;
        font-size: 12px;
        padding-left: 20px;
    }
    hr {
        margin: 5px 10px;
    }
`;

export const MenuItemStyled = styled(Menu.Item)`
    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.5;
            /* pointer-events: none; */
            color: #999;
            // background-color: #037fb9;
        `}
`;

export const ButtonModalShippingCost = styled(Button)`
    border-radius: 3px;
    border: 1px solid #2d3d76;
`;

export const TitleModalCancelFulfillment = styled.div`
    font-size: 18px;
    color: #2d3d76;
    font-weight: 700;
`;
