import Flex from 'components/Utils/Flex';
import { Tooltip } from 'antd';
import { BsBoxSeam } from 'react-icons/bs';

const FulfillmentsTransferLocations = ({ preparationcd_transfer_pickupstore = {} }) => {
    if (Object.keys(preparationcd_transfer_pickupstore).length === 0) return '';
    return (
        <Flex alignItems="center" gap="6px">
            <Tooltip
                title={`${preparationcd_transfer_pickupstore?.cd_location?.name} -> ${preparationcd_transfer_pickupstore?.pickup_location?.name}`}
            >
                <BsBoxSeam color="#1b304a" />
            </Tooltip>
            <span
                style={{
                    whiteSpace: 'nowrap',
                }}
            >
                {preparationcd_transfer_pickupstore?.pickup_location?.name}
            </span>
        </Flex>
    );
};

export default FulfillmentsTransferLocations;
