import { createSlice } from '@reduxjs/toolkit';

const preparationState = createSlice({
    name: 'preparationState',
    initialState: {
        filters: null,
        initialSearchTerm: '',
    },
    reducers: {
        changeState: (state, { payload }) => {
            return {
                ...state,
                ...payload,
            };
        },
        setStateFilters: (state, { payload }) => {
            if (!payload) {
                return {
                    ...state,
                    filters: null,
                };
            }
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload,
                },
            };
        },
    },
});
export const { changeState, setStateFilters } = preparationState.actions;
export default preparationState.reducer;
