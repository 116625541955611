import styled from 'styled-components';

export const PageActions = styled.section`
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    gap: 1rem;

    margin-bottom: 1rem;
    .preparation__search-input {
        width: 100%;
        height: 38px;
        border: 1px solid ${({ theme }) => theme?.buttons?.colors?.primary?.first ?? ''};
        padding: 5px 10px;
        border-right: none;
        outline: none;
        border-radius: 4px 0px 0px 4px;
    }

    .ant-btn {
        height: 40px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        * {
            margin-right: 0px;
        }
        .preparation__left-actions {
            width: 100%;
            margin-top: 10px;
            flex-wrap: wrap;
            .preparation__search-input {
                flex: 1;
            }
        }
        .preparation__right-actions {
            width: 100%;
            .ant-btn {
                flex: 1;
            }
        }
    }
`;

export const PageLayout = styled.main`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
    gap: 1rem;
    &:first-child {
        grid-row: span 2;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }
`;

export const BGWhite = styled.div`
    background-color: white;
    padding: 1rem;
    margin-top: 2rem;
`;
