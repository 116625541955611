import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getPickupPreferenceByMerchant } from './getPickupPreferenceByMerchant';

export const editPickupPreference = createAsyncThunk(
    'pickupPreference/edit',
    async (data, state) => {
        try {
            const res = await AndesClient.put(`/settings/pickup/${data?._id}`, data);
            if (res.status === 200) {
                toast.success('Configuracion de preferencia de entrega modificada con éxito.');
                state.dispatch(getPickupPreferenceByMerchant());
            }
            return res.data;
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const updatePickupPreferenceSlice = createSlice({
    name: 'updatePickupPreference',
    initialState: {
        loading: false,
        data: {},
        error: null,
    },
    reducers: {},
    extraReducers: {
        [editPickupPreference.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [editPickupPreference.fulfilled]: (state, action) => {
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        },
        [editPickupPreference.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default updatePickupPreferenceSlice.reducer;
