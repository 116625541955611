import styled from 'styled-components';

export const PageLayout = styled.section`
    display: grid;
    grid-template-columns: 35% 1fr;
    grid-auto-flow: column;
    gap: 1rem;
    background-color: #ffff;
    height: 100vh;
    
    
    

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
    }

`;



export const MetafieldFormCard = styled.section`
    flex: 1 !important;
    grid-row: span 2;
    padding: 1rem 0;
`;

export const MetafieldTabButton = styled.button`
    color: #037fb9;
    font-size: 14px;
    font-weight: 500;

    letter-spacing: 0.5px;
    padding: 10px;
    cursor: pointer;

    border-bottom: none;

    &:last-child {
        margin-right: 0;
    }

`
export const ExpandedRowContainer = styled.div`
    background-color: #ffffff;
    padding: 2rem;
    text-align: left;
`