import dayjs from 'dayjs';

export const getDateFilterToSend = (dateSelected = 'last_7_days', dateCalendary) => {
    const baseTime = dayjs(dayjs().format('YYYY-MM-DDTHH:mm:ssZ'));
    const options = [
        {
            date: 'today',
            start: baseTime?.startOf('day').toISOString(),
            end: baseTime?.endOf('day').toISOString(),
        },
        {
            date: 'last_24_hours',
            start: baseTime?.subtract(1, 'day').toISOString(),
            end: baseTime?.toISOString(),
        },
        {
            date: 'last_7_days',
            start: baseTime?.subtract(6, 'day').toISOString(),
            end: baseTime?.toISOString(),
        },
        {
            date: 'current_month',
            start: baseTime?.startOf('month').toISOString(),
            end: baseTime?.endOf('month').toISOString(),
        },
        {
            date: 'last_month',
            start: baseTime?.subtract(1, 'month').startOf('month').toISOString(),
            end: baseTime?.subtract(1, 'month').endOf('month').toISOString(),
        },
        {
            date: 'last_three_month',
            start: baseTime?.subtract(3, 'month').startOf('month').toISOString(),
            end: baseTime?.toISOString(),
        },
        {
            date: 'custom',
            start: dateCalendary?.[0]
                ? dayjs(dateCalendary[0]).startOf('day').format()
                : baseTime.startOf('day').toISOString(),
            end: dateCalendary?.[1]
                ? dayjs(dateCalendary[1]).endOf('day').format()
                : baseTime.toISOString(),
        },
    ];

    return options.find((date) => date.date === dateSelected);
};
