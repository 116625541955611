import ReactDatePicker from 'react-datepicker';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import { ButtonCalendar, LabelSpanStyled, SearchSelectStyled } from './styles';
import { HeaderReactDatePicker } from './HeaderReactDatePicker';
import { ContainerReactDatePicker } from './ContainerReactDatePicker';
import { dateRangeOptions } from './utils';
import { getDateFilterToSend } from 'components/V2/DatePicker/utils';

export const RangePicker = ({ setDateCalendar, disabledInitialDate, loading }) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const [showInputCalendar, setShowInputCalendar] = useState(false);
    const [valueCalendar, setValueCalendar] = useState({ value: [], type: 'custom' });
    const [possibleValueCalendar, setPossibleValueCalendar] = useState([]);

    const [idInput] = useState(
        Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
    );

    const userDataLocalStorage = JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;

    const objectDateToSend = getDateFilterToSend(
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter,
        userDataLocalStorage?.orders_configuration?.default_date_orders_filter_custom
    );

    const handleChangeCalendar = (value) => {
        setDateCalendar({
            type: (value ?? valueCalendar)?.type,
            fromDate: (value ?? valueCalendar)?.value?.[0]?._d.toISOString(),
            toDate: (value ?? valueCalendar)?.value?.[1]?._d.toISOString(),
        });
        setShowCalendar(false);
    };

    const handleChangeDate = (type, handlerFilter) => {
        const baseTimeStart = moment(moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'));
        const baseTimeEnd = moment(moment().utc().format('YYYY-MM-DDTHH:mm:ssZ'));
        let start;
        let end;

        if (type === 'today') {
            start = baseTimeStart.startOf('day');
            end = baseTimeEnd.endOf('day');
        }
        if (type === 'last_24_hours') {
            start = baseTimeStart.subtract(1, 'day');
            end = baseTimeEnd;
        }
        if (type === 'last_7_days') {
            start = baseTimeStart.subtract(7, 'day');
            end = baseTimeEnd;
        }
        if (type === 'current_month') {
            start = baseTimeStart.startOf('month');
            end = baseTimeEnd.endOf('month');
        }
        if (type === 'last_month') {
            start = baseTimeStart.subtract(1, 'month').startOf('month');
            end = baseTimeEnd.subtract(1, 'month').endOf('month');
        }

        if (type === 'last_three_month') {
            start = baseTimeStart.subtract(3, 'month').startOf('month');
            end = baseTimeEnd.endOf('month');
        }

        if (type === 'custom') {
            setTimeout(() => document.getElementById(`calendar-input-${idInput}`)?.click(), 250);

            setValueCalendar({ ...valueCalendar, type, lastType: valueCalendar?.type });
            return setShowInputCalendar(true);
        }

        setPossibleValueCalendar([start?.toDate(), end?.toDate()]);
        setValueCalendar({
            value: [start, end],
            type,
        });

        setShowInputCalendar(false);
        setShowCalendar(false);

        if (handlerFilter)
            handleChangeCalendar({
                value: [start, end],
                type,
            });
    };

    const handleConfirmCalendar = () => {
        const value = {
            ...valueCalendar,
            lastType: valueCalendar?.type,
            value: [moment(possibleValueCalendar?.[0]), moment(possibleValueCalendar?.[1])],
        };

        setShowCalendar(false);
        setValueCalendar(value);
        handleChangeCalendar(value);
    };

    const handleCancelCalendar = () => {
        if (valueCalendar.lastType !== 'custom') setShowInputCalendar(false);

        setShowCalendar(false);
        setValueCalendar({ ...valueCalendar, type: valueCalendar?.lastType });

        setPossibleValueCalendar([
            valueCalendar?.value?.[0]?.toDate(),
            valueCalendar?.value?.[1]?.toDate(),
        ]);
    };

    useEffect(() => {
        if (disabledInitialDate) return;

        if (objectDateToSend?.date === 'custom') {
            setShowInputCalendar(true);
            setPossibleValueCalendar([
                moment(objectDateToSend?.start).utc().toDate(),
                moment(objectDateToSend?.end).utc().toDate(),
            ]);
            setValueCalendar({
                value: [moment(objectDateToSend?.start).utc(), moment(objectDateToSend?.end).utc()],
                type: 'custom',
                lastType: 'custom',
            });
        } else {
            handleChangeDate(objectDateToSend?.date, false);
        }
    }, [disabledInitialDate]);

    const value = useMemo(
        () =>
            valueCalendar?.type === 'today' ? (
                valueCalendar?.value?.[0]?.format('DD/MM/YYYY')
            ) : (
                <LabelSpanStyled>
                    {valueCalendar?.value?.length
                        ? `${valueCalendar?.value?.[0]?.format(
                              'DD/MM/YYYY'
                          )} | ${valueCalendar?.value?.[1]?.format('DD/MM/YYYY')}`
                        : ' | '}
                </LabelSpanStyled>
            ),
        [valueCalendar]
    );

    const CalendarInput = forwardRef(({ value, onClick }, ref) => (
        <ButtonCalendar onClick={onClick} id={`calendar-input-${idInput}`} ref={ref}>
            {value?.replace('-', '|')} <CalendarIcon size={18} />
        </ButtonCalendar>
    ));

    return (
        <div>
            <SearchSelectStyled
                placeholder={value}
                value={valueCalendar?.type}
                onChange={handleChangeDate}
                suffixIcon={<CalendarIcon size={18} />}
                disabled={loading}
                display={valueCalendar?.type === 'custom' ? 'none' : undefined}
            >
                {dateRangeOptions?.map(({ label, value }) => (
                    <SearchSelectStyled.Option
                        selected={value === valueCalendar?.type}
                        key={value}
                        value={value}
                    >
                        {label}
                    </SearchSelectStyled.Option>
                ))}
            </SearchSelectStyled>
            {showInputCalendar && (
                <ReactDatePicker
                    selectsRange
                    showPreviousMonths
                    locale="es"
                    dateFormat="dd/MM/yyyy"
                    monthsShown={2}
                    shouldCloseOnSelect={false}
                    onClickOutside={handleCancelCalendar}
                    onInputClick={() => setShowCalendar(!showCalendar)}
                    onChange={setPossibleValueCalendar}
                    startDate={possibleValueCalendar?.[0]}
                    endDate={possibleValueCalendar?.[1]}
                    calendarContainer={(props) => (
                        <ContainerReactDatePicker
                            {...props}
                            valueCalendar={valueCalendar}
                            loading={loading}
                            possibleValueCalendar={possibleValueCalendar}
                            handleChangeDate={handleChangeDate}
                            handleConfirmCalendar={handleConfirmCalendar}
                            handleCancelCalendar={handleCancelCalendar}
                        />
                    )}
                    open={showCalendar}
                    customInput={<CalendarInput />}
                    calendarClassName="react-datepicker-custom"
                    renderCustomHeader={HeaderReactDatePicker}
                />
            )}
        </div>
    );
};
