import styled from 'styled-components';
import { TimePicker, Typography } from 'antd';

export const PageLayout = styled.main`
    display: flex;
    flex-direction: row;
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        flex-direction: column;
    }
`;

export const StyleWrapperTimePicker = styled(TimePicker.RangePicker)``;

export const PageTitle = styled(Typography)`
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1.5rem 0px 1rem 0px;
`;
export const MenuWrapper = styled.div`
    position: sticky;
    top: calc(32px);
    width: 320px;
    height: fit-content;
    margin-right: 1rem;
    z-index: 9;
    user-select: none;

    .ant-card-body {
        padding: 0px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 1rem 0rem;
        font-size: 0.825rem;
        color: #2d3d76;
        letter-spacing: 0.5px;

        li {
            display: flex;
            flex-direction: row;
            font-weight: 500;
            padding: 0.625rem 1rem;
            align-items: center;
            transition: 0.1s;
            cursor: pointer;
            border-radius: 6px;
            margin: 0px 8px 8px 12px;
            &:hover,
            &.is-active {
                color: #142c4a;
                background-color: #037fb926;
            }

            svg {
                flex: 0 0 auto;
                margin-right: 1rem;
                width: 20px;
                height: 20px;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 1rem;
        top: 1rem;
        ul {
            display: flex;
            flex-direction: row;

            padding: 0;
            overflow-x: hidden;

            li {
                flex: 1;
                padding: 1rem;
                justify-content: center;

                svg {
                    margin-right: 0px;
                }

                span {
                    display: none;
                }
            }
        }
    }
`;

export const ViewContainer = styled.div`
    margin: 0px 32px;
`;

export const SectionContainer = styled.div`
    margin: 2rem;
`;

export const SectionTitleContainer = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-left: 16px;
`;

export const Title = styled.h3`
    font-size: 14px;
    color: #036896;
    font-weight: 600;
    margin-bottom: 16px;
    margin: ${({ mg }) => mg};
`;
export const SubTitle = styled.h4`
    font-size: 14px;
    color: #2d3d76;
    font-weight: 500;
    margin-bottom: 11px;
    margin: ${({ mg }) => mg};
`;

export const FormDivider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #dce5ec;
    margin: 2.25rem 0px;
`;
