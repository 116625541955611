import { Checkbox } from 'antd';

const ProblemProduct = ({ lineItem: { _id = '', name = '', sku = '' }, quantity }) => {
    return (
        <div
            className="flex no-wrap py-3 px-2 w-full"
            style={{ borderBottom: '1px solid #DCE5EC', backgroundColor: '#DCE5EC' }}
        >
            <Checkbox value={_id}></Checkbox>
            <div className='flex flex-col text-xs ml-3'>
                <span className="font-medium w-64" style={{ color: '#1F6373' }}>{name}</span>
                <span className='mt-1' style={{ color: '#6C808F', fontSize: '11px' }}>SKU: {sku}</span>
                <span className='mt-2' style={{ color: '#0A6A77'}}>
                    {`Un. solicitadas: ${quantity}`}
                </span>
            </div>
        </div>
    );
};

export default ProblemProduct;
