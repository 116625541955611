import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllIntegration } from 'redux/features/Integrations/getAllIntegration';

const useAllIntegrations = () => {
    const dispatch = useDispatch();
    const { loading, data, success, error } = useSelector((state) => state.getAllIntegrations);
    useEffect(() => {
        if (!data[0] && !success) {
            dispatch(getAllIntegration());
        }
    }, []);
    return [loading, data, error];
};

export default useAllIntegrations;
