import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';

export const getPreparersByCustom = createAsyncThunk(
    'users/getPreparersByCustom',
    async (body = {}, state) => {
        try {
            const res = await AndesClient.post(`/users/getPreparersByCustom`, body);
            console.log('res', res)
            return res.data;
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const getPreparersByCustomSlice = createSlice({
    name: 'getPreparersByCustom',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [getPreparersByCustom.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getPreparersByCustom.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                success: true,
            };
        },
        [getPreparersByCustom.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                success: false,
                error: payload,
            };
        },
    },
});
export const { addUserToStore, deleteUserFromStore, updateUserFromStore } =
getPreparersByCustomSlice.actions;
export default getPreparersByCustomSlice.reducer;
