const onLogout = () => {
    localStorage.removeItem('generalSettings');
    localStorage.removeItem('userLogged');
    localStorage.removeItem('userProfile');
    localStorage.removeItem('preparationSettings');
    localStorage.removeItem('masterStates');
    localStorage.removeItem('merchantLanguage');
    localStorage.removeItem('merchantCountry');
    localStorage.removeItem('merchantCurrency');
    localStorage.removeItem('merchantDecimalSupport');
};

export default onLogout;
