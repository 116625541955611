export const printText = (text) => {
    var iframe = document.createElement('iframe');
    iframe.style.display = 'none';

    document.body.appendChild(iframe);
    iframe.contentWindow.document.body.innerText = text;

    const style = document.createElement('style');
    style.innerHTML = `
      @media print {
          thead, tfoot {  
              display: none !important
            }
            @page {
              size: auto;
              margin: 0mm;
            }
      }
  `;
    iframe.contentWindow.document.head.appendChild(style);
    var script = document.createElement('script');
    script.innerHTML = 'window.print()';
    iframe.contentWindow.document.body.appendChild(script);
    iframe.contentWindow.document.close();
    // remove
    document.body.removeChild(iframe);
};
