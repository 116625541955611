import { createSlice } from '@reduxjs/toolkit';

const dataByAddress = createSlice({
    name: 'dataByAddress',
    initialState: {
        data: {},
    },
    reducers: {
        setSearchedAddress: (state, { payload }) => {
            return {
                data: payload,
            };
        },
        reInitDataByAddress: () => {
            return { data: {} };
        },
    },
});
export const { setSearchedAddress, reInitDataByAddress } = dataByAddress.actions;
export default dataByAddress.reducer;
