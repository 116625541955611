import useMasterLabels from 'hooks/useMasterLabels';
import { TagCircle, TagStyled } from './styles';

const Badge = ({ bgColor, label, style = {}, namespace }) => {
    const { getLabel } = useMasterLabels();
    return (
        <TagStyled style={style}>
            <TagCircle bgColor={bgColor} />
            {getLabel(label, namespace)}
        </TagStyled>
    );
};

export default Badge;
