import Container from 'components/Grid/Container';
import PageHeader from 'components/Layout/PageHeader';
import { useGetBatchDeliversQuery } from 'redux/features/BatchDelivers';
import React, { Suspense } from 'react';
const BatchDeliversTable = React.lazy(() => import('./components/BatchDeliversTable'));

const BatchDelivers = () => {
    const { data, isLoading } = useGetBatchDeliversQuery();
    return (
        <Container extraTitle="Entregas recientes">
            <PageHeader
                showBack
                title="Entregas recientes"
                subtitle=""
                breadcrumb={[{ name: 'Entregas recientes', path: '/BatchDelivers' }]}
            />
            <Suspense>
                <BatchDeliversTable batchDelivers={data ?? []} loading={isLoading} />
            </Suspense>
        </Container>
    );
};

export default BatchDelivers;
