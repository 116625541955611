import { Flex, Heading, Image, Radio, Text, View, useTheme } from '@aws-amplify/ui-react';
import { useMemo, useState } from 'react';
import beflowLogo from 'assets/logo-beflow.svg';
import LoginTopLeftImage from 'assets/login-top-left-figure.svg';
import LoginBottomRightImage from 'assets/login-bottom-right-figure.svg';
import { Checkbox, Input, Spin } from 'antd';
import { LoadingOutlined, LockOutlined, QrcodeOutlined, UserOutlined } from '@ant-design/icons';
import { Auth, I18n } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import { AmplifyContainer, AmplifyForm, Button } from './styles';
import { QRCodeSVG } from 'qrcode.react';
import { CheckIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/outline';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsCheckLg } from 'react-icons/bs';

const formComponents = {
    signIn: {
        title: 'Bienvenido/a',
        subTitle: 'Inicia sesión',
        buttonTitle: 'Iniciar sesión',
        buttonType: 'submit',
        principalInputLabel: 'Usuario o email',
        principalInputName: 'email',
    },
    recoveryPassword: {
        title: '¿Olvidaste tu contraseña?',
        subTitle:
            'Ingresa el correo con el que estas registrado y te enviaremos un código de verificación para recuperar la contraseña ',
        buttonTitle: 'Enviar código de verificación',
        buttonType: 'button',
        principalInputLabel: 'Email',
        principalInputName: 'email',
        legend: 'Si no ves el email en tu bandeja de entrada, mira en la carpeta de spam',
    },
    changePassword: {
        title: 'Nueva contraseña',
        subTitle: 'Comprueba si recibiste un correo electrónico con tu código de 6 dígitos',
        buttonTitle: 'Crear nueva contraseña',
        buttonType: 'button',
        principalInputLabel: 'Ingresa código de verificación',
        principalInputName: 'verificationCode',
    },
    completedNewPassword: {
        title: 'Nueva contraseña',
        subTitle: 'Ingresa la nueva contraseña ',
        buttonTitle: 'Crear nueva contraseña',
        buttonType: 'button',
        legend: 'Una vez que la contraseña sea cambiada, serás redirigido(a) a la pantalla principal',
    },
    setupTOTP: {
        title: 'Configura MFA',
        subTitle: 'Selecciona tipo de multifactor',
        buttonTitle: 'Verificar',
        buttonType: 'button',
    },
    configureTOTP: {
        title: 'Vincula tu dispositivo',
        subTitle: 'Escanea el QR con tu dispositivo',
        buttonTitle: 'Verificar',
        buttonType: 'button',
        principalInputLabel: 'Ingresa código generado por la app',
        principalInputName: 'totp_token',
    },
    AUTH_MFA: {
        title: 'Verificación en dos pasos',
        subTitle: '',
        buttonTitle: 'Verificar',
        buttonType: 'button',
        principalInputLabel: 'Código generado por la app',
        principalInputName: 'totp_token',
    },
    verifyEmail: {
        title: 'Verificación de email',
        subTitle: 'Hemos enviado un código de verificación al siguiente email: ',
        buttonTitle: 'Validar código',
        buttonType: 'button',
        principalInputLabel: 'Ingresa código de verificación',
        principalInputName: 'verificationCode',
        legend: 'Si no ves el email en tu bandeja de entrada, mira en la carpeta de spam',
    },
};

export const LoginPage = ({
    handleLogin,
    loading,
    step,
    setStep,
    handleCurrentSession,
    userLogged,
}) => {
    const theme = useTheme();
    const [fields, setFields] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [TOTPCode, setTOTPCode] = useState('');
    const emailSecretToVerify = useMemo(() => {
        return userLogged?.attributes?.email?.replace(
            /[a-z0-9\-_.]+@/gi,
            (c) =>
                c.substr(0, 3) +
                c
                    .split('')
                    .slice(3, -1)
                    .map(() => '*')
                    .join('') +
                '@'
        );
    }, [step, userLogged]);

    const onSubmit = (e) => {
        e.preventDefault();
        if (loading || step !== 'signIn') return false;

        if (!fields?.email || !fields?.password) return toast.error('Ingresa usuario y contraseña');

        handleLogin({ username: fields.email, password: fields.password });
    };

    const getVerificationCode = (resetPass) => {
        if (!fields.email) toast.error('Debes ingresar un email');
        toast('Código enviado al email ingresado. Revisa tu bandeja de entrada (o spam)', {
            icon: <AiOutlineInfoCircle color="#22505E" fontSize="20px" />,
            style: { fontSize: '12px', backgroundColor: '#D6F3FB', maxWidth: 'max-content' },
        });

        Auth.forgotPassword(fields.email).catch((err) => {
            if (err?.name !== 'UserNotFoundException') toast.error(I18n.get(err.message));
        });

        if (resetPass) setFields({ ...fields, password: '' });
        setStep('changePassword');
    };

    const handleChangePassword = () => {
        if (fields.password !== fields.confirmPassword)
            return toast.error('Las contraseñas no coinciden');

        setInProgress(true);
        Auth.forgotPasswordSubmit(fields.email, fields.verificationCode, fields.password)
            .then(() => {
                toast('La nueva contraseña fue actualizada. Puedes iniciar sesión nuevamente.', {
                    icon: <BsCheckLg fontSize="20px" color="#255035" />,
                    style: {
                        fontSize: '12px',
                        backgroundColor: '#D5E6DE',
                        maxWidth: 'max-content',
                    },
                });
                setFields({});
                setStep('signIn');

                setInProgress(false);
            })
            .catch((err) => {
                toast.error(I18n.get(err.message));
                setInProgress(false);
            });
    };

    async function completedNewPassword() {
        try {
            setInProgress(true);
            await Auth.completeNewPassword(userLogged, fields.password);
            handleCurrentSession();
            setInProgress(false);
        } catch (err) {
            setInProgress(false);
            console.log(err, 'error');
        }
    }

    const setupTOTP = () => {
        setInProgress(true);
        Auth.setPreferredMFA(userLogged, 'TOTP').catch(console.log);

        Auth.setupTOTP(userLogged)
            .then((code) => {
                const str = `otpauth://totp/AWSCognito:${fields.email}?secret=${code}&issuer=Be-flow`;
                setTOTPCode(str);
                setInProgress(false);
                setStep('configureTOTP');
            })
            .catch((err) => {
                console.log(err);
                setInProgress(false);
            });
    };

    const verificationTOPT = () => {
        setInProgress(true);
        Auth.verifyTotpToken(userLogged, fields.totp_token)
            .then(() => {
                Auth.setPreferredMFA(userLogged, 'TOTP');
                setInProgress(false);
                handleCurrentSession();
            })
            .catch((e) => {
                console.log(e);
                setInProgress(false);
                toast.error('El token introducido es inválido');
                // Token is not verified
            });
    };

    const verificationMFA = () => {
        setInProgress(true);
        Auth.confirmSignIn(userLogged, fields.totp_token, userLogged.challengeName)
            .then(() => {
                setInProgress(false);
                handleCurrentSession();
            })
            .catch((e) => {
                console.log(e);
                setInProgress(false);
                toast.error('El token introducido es inválido');
            });
    };

    const verificationEmail = () => {
        setInProgress(true);
        Auth.verifyCurrentUserAttributeSubmit('email', fields.verificationCode)
            .then(() => {
                setInProgress(false);
                handleCurrentSession();
            })
            .catch((e) => {
                console.log(e);
                setInProgress(false);
                toast.error('El código introducido es inválido');
            });
    };

    const handleChange = (e) => setFields({ ...fields, [e.target.name]: e.target.value });

    const handleClickButton = () => {
        switch (step) {
            case 'recoveryPassword':
                getVerificationCode(true);
                break;
            case 'changePassword':
                handleChangePassword();
                break;
            case 'completedNewPassword':
                completedNewPassword();
                break;
            case 'setupTOTP':
                setupTOTP();
                break;
            case 'configureTOTP':
                verificationTOPT();
                break;
            case 'AUTH_MFA':
                verificationMFA();
                break;
            case 'verifyEmail':
                verificationEmail();
                break;
            default:
                return null;
        }
    };

    return (
        <AmplifyContainer className="amplify-container">
            <View textAlign="center" padding={theme.tokens.space.large} marginBottom="20px">
                <Image alt="Beflow logo" src={beflowLogo} />
                <Image
                    style={{ position: 'absolute', top: 0, left: 0, zIndex: -1 }}
                    alt="Beflow figure"
                    src={LoginTopLeftImage}
                />
                <Image
                    style={{ position: 'absolute', bottom: 0, right: 0, zIndex: -1 }}
                    alt="Beflow figure"
                    src={LoginBottomRightImage}
                />
            </View>
            <View textAlign="center" padding={theme.tokens.space.large}>
                <Heading level={3} color="#142C4A" fontSize="48px" fontWeight="700">
                    {formComponents[step].title}
                </Heading>
                <Text fontSize="16px">
                    {formComponents[step].subTitle} {step === 'verifyEmail' && emailSecretToVerify}
                </Text>
            </View>
            <AmplifyForm onSubmit={onSubmit}>
                <View textAlign="center" padding={theme.tokens.space.large}>
                    {step === 'configureTOTP' && (
                        <Flex justifyContent="center">
                            <QRCodeSVG value={TOTPCode} />
                        </Flex>
                    )}
                    {step === 'setupTOTP' && <Radio>TOTP</Radio>}
                    {step !== 'completedNewPassword' && step !== 'setupTOTP' && (
                        <Input
                            name={formComponents[step].principalInputName}
                            placeholder={formComponents[step].principalInputLabel}
                            size="large"
                            value={fields[formComponents[step].principalInputName]}
                            onChange={handleChange}
                            prefix={
                                step === 'changePassword' || step === 'verifyEmail' ? (
                                    <QrcodeOutlined style={{ color: '#2D3D76' }} />
                                ) : (
                                    <UserOutlined style={{ color: '#2D3D76' }} />
                                )
                            }
                            className="amp-input-text"
                        />
                    )}
                    {step === 'changePassword' && (
                        <Flex
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            marginTop=".5rem"
                        >
                            <Text
                                display="inline-flex"
                                fontSize="14px"
                                color="#536D8F"
                                fontWeight="400"
                            >
                                ¿No recibiste el código?
                            </Text>
                            <Text
                                display="inline-flex"
                                style={{ cursor: 'pointer' }}
                                fontSize="14px"
                                textDecoration="underline"
                                color="#037FB9"
                                fontWeight="500"
                                onClick={() => getVerificationCode()}
                            >
                                Reenviar código
                            </Text>
                        </Flex>
                    )}

                    {step !== 'recoveryPassword' &&
                        step !== 'setupTOTP' &&
                        step !== 'configureTOTP' &&
                        step !== 'AUTH_MFA' &&
                        step !== 'verifyEmail' && (
                            <Input.Password
                                type="password"
                                size="large"
                                name="password"
                                placeholder={
                                    step === 'changePassword' ? 'Nueva contraseña' : 'Contraseña'
                                }
                                value={fields.password}
                                onChange={handleChange}
                                prefix={<LockOutlined style={{ color: '#2D3D76' }} />}
                                className="amp-input-text"
                            />
                        )}
                    {step === 'changePassword' && step !== 'setupTOTP' && (
                        <Input.Password
                            type="password"
                            size="large"
                            name="confirmPassword"
                            placeholder="Repita nueva contraseña"
                            value={fields.confirmPassword}
                            onChange={handleChange}
                            prefix={<LockOutlined style={{ color: '#2D3D76' }} />}
                            className="amp-input-text"
                        />
                    )}
                    {step === 'signIn' && (
                        <Flex
                            alignItems="center"
                            justifyContent="space-between"
                            padding="0 .7rem"
                            marginTop=".5rem"
                        >
                            <Checkbox name="remember_me" className="amp-checkbox">
                                Recordar datos
                            </Checkbox>
                            <Text
                                style={{ cursor: 'pointer' }}
                                fontSize="14px"
                                color="#037FB9"
                                fontWeight="500"
                                onClick={() => {
                                    setFields({});
                                    setStep('recoveryPassword');
                                }}
                            >
                                ¿Olvidaste tu contraseña?
                            </Text>
                        </Flex>
                    )}
                    {step === 'changePassword' &&
                        [
                            { title: 'Una letra minúscula', regex: /^(?=.*[a-z])/, id: 1 },
                            { title: 'Una letra mayúscula', regex: /[A-Z]/, id: 2 },
                            { title: 'Un número', regex: /[0-9]/, id: 3 },
                            {
                                title: 'Un carácter especial',
                                regex: /^(?=.*[@$?¡\-_^,.*!~#%&(+)=])/,
                                id: 4,
                            },
                            {
                                title: 'Entre 8 y 16 caracteres como mínimo',
                                regex: /^([^]){8,16}$/,
                                id: 5,
                            },
                        ].map((item, index) => (
                            <Flex
                                key={item.id}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                padding="0 .7rem"
                                marginTop={index === 0 ? '1rem' : 0}
                            >
                                {item.regex?.test(fields.password) ? (
                                    <>
                                        <CheckIcon width="16px" height="16px" color="#089460" />
                                        <Text fontSize="14px" color="#089460" fontWeight="400">
                                            {item.title}
                                        </Text>
                                    </>
                                ) : (
                                    <>
                                        <XIcon width="16px" height="16px" color="#B00303" />
                                        <Text fontSize="14px" color="#B00303" fontWeight="400">
                                            {item.title}
                                        </Text>
                                    </>
                                )}
                            </Flex>
                        ))}
                    <Button
                        type={formComponents[step].buttonType}
                        className="amplify-button secondary"
                        {...(step !== 'signIn'
                            ? {
                                  onClick: handleClickButton,
                              }
                            : {})}
                    >
                        {loading || inProgress ? (
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                        ) : (
                            formComponents[step].buttonTitle
                        )}
                    </Button>
                </View>
            </AmplifyForm>
            {formComponents[step]?.legend && (
                <Text fontSize="12px" color="#536D8F" fontWeight="400">
                    {formComponents[step]?.legend}
                </Text>
            )}
            {step !== 'signIn' && (
                <Text
                    style={{ cursor: 'pointer' }}
                    fontSize="16px"
                    textDecoration="underline"
                    color="#037FB9"
                    fontWeight="500"
                    onClick={() => {
                        setFields({});
                        setStep('signIn');
                    }}
                    marginTop="2rem"
                >
                    Volver al login
                </Text>
            )}
        </AmplifyContainer>
    );
};
