/* eslint-disable no-unused-vars */
import { Modal } from 'antd';
import JsonView from 'components/JsonView';

export const ModalWebhookDetail = ({ data, isOpen, onClose }) => {
    return (
        <Modal
            width={700}
            open={isOpen}
            onCancel={onClose}
            onClose={onClose}
            title="Detalle de respuesta de webhook"
            bodyStyle={{ overflow: 'auto' }}
        >
            <JsonView json={data} />
        </Modal>
    );
};
