import { Modal } from 'antd';
import JsonView from 'components/JsonView';
import { useMemo } from 'react';

export const ModalWebhookReport = ({ data, isOpen, onClose }) => {
    const jsonData = useMemo(() => {
        const requestData = data?.other?.request?.body;
        const isString = typeof requestData === 'string';
        const isValidObject = typeof requestData === 'object' && requestData !== null;
        if (isString) {
            try {
                return JSON.parse(requestData);
            } catch (e) {
                return requestData;
            }
        } else if (isValidObject) {
            return requestData;
        }
    }, [data]);

    return (
        <Modal
            width={700}
            open={isOpen}
            onCancel={onClose}
            onClose={onClose}
            title="Informe de webhook"
        >
            <JsonView json={jsonData} />
        </Modal>
    );
};
