import { createApi } from '@reduxjs/toolkit/query/react';
import MasterClient from 'services/MasterClient';

export const masterCouriersApi = createApi({
    reducerPath: 'masterCouriers',
    baseQuery: MasterClient,
    tagTypes: ['masterCouriers'],
    endpoints: (builder) => ({
        getMasterCouriers: builder.query({
            query: () => {
                return {
                    url: 'master/couriers',
                    method: 'get',
                };
            },
            providesTags: ['masterCouriers'],
        }),
        findMasterCouriers: builder.query({
            query: (platform) => {
                return {
                    url: `master/couriers/find?platform=${platform}`,
                    method: 'get',
                };
            },
            providesTags: ['findMasterCouriers'],
        }),
        findProductTypes: builder.query({
            query: () => {
                return {
                    url: `master/product-types`,
                    method: 'get',
                };
            },
            providesTags: ['findProductTypes'],
            transformResponse: (response, _, params) => {
                // console.log('findProductTypes response', response);

                if (!params?.parseByConfig) {
                    return response;
                }

                const userDataLocalStorage =
                    JSON.parse(localStorage.getItem('userLogged') ?? '{}') ?? null;

                if (userDataLocalStorage?.orders_configuration?.enable_product_type_support) {
                    const itemsEnabled =
                        userDataLocalStorage?.orders_configuration?.product_type_support ?? [];

                    const itemsCustomized =
                        userDataLocalStorage?.orders_configuration?.customized_product_types ?? [];

                    return response
                        .filter((item) => itemsEnabled.includes(item._id))
                        .map((item) => {
                            const itemCustomized = itemsCustomized.find(
                                (itemCustomized) => itemCustomized.product_type === item._id
                            );

                            return {
                                ...item,
                                label: userDataLocalStorage?.orders_configuration
                                    ?.enable_customize_product_types
                                    ? itemCustomized?.product_custom_name ?? item.label
                                    : item.label,
                            };
                        });
                }

                return response;
            },
        }),
    }),
});

export const {
    useGetMasterCouriersQuery,
    useLazyFindMasterCouriersQuery,
    useFindProductTypesQuery,
} = masterCouriersApi;
