import { Tooltip, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { unProccesPackage } from 'redux/features/Packages/unProcessPackage';
import Flex from 'components/Utils/Flex';
import Tag from 'components/Tag';
import { ReactComponent as PackageOutputIcon } from 'assets/package-output-icon.svg';
import { getPackagesTagColor } from 'helpers/constants/getPackagesTagColor';
import useMasterLabels from 'hooks/useMasterLabels';
import { ReactComponent as CopyIcon } from 'assets/new-copy-icon.svg';
import PackageDetailsDrawer from 'pages/DeliveriesV2/components/PackageDetailsDrawer';
import { useEffect, useMemo, useState } from 'react';
import { CircleIcon, PackagesTable, SubtableWrapper, colorPackages } from './styles';

function Subtable({
    fulfillment,
    isSelected,
    dataPickupsPreferences,
    dataPreparations,
    isScan,
    updateList,
    handleSelectRows,
    selectedRows,
    dataList,
    handleInsertFulfillments,
}) {
    const [packagesInfo, setPackagesStatus] = useState({
        processed: 0,
        pending_for_processing: 0,
        open: 0,
        received_pickup_location: 0,
        total_packages_processed: 0,
        color: '#089460',
    });
    const { getLabel } = useMasterLabels();
    const dispatch = useDispatch();
    const totalPackages = fulfillment?.packages?.length ?? 0;
    const activeSelected =
        dataPreparations?.preparations_preferences?.preparer_default_assignment &&
        ((dataPickupsPreferences?.delivery_options?.shipping
            .type_automatic_assignment_manager_fulfillment === 'not_delivery_validation' &&
            fulfillment?.delivery_method === 'shipping') ||
            (dataPickupsPreferences?.delivery_options?.shipping
                .type_automatic_assignment_manager_fulfillment === 'not_delivery_validation' &&
                fulfillment?.delivery_method === 'shipping'));

    const unProcessPackageHandle = (code) => {
        dispatch(
            unProccesPackage({
                code,
                fulfillment,
                totalPackages,
                updateList,
                handleInsertFulfillments,
                handleSelectRows,
                isScan,
                selectedRows,
            })
        );
    };

    useEffect(() => {
        const firstData = dataList.find((fulfillment) => fulfillment._id === selectedRows[0]);
        const valuesPackagesStatus = {
            processed: 0,
            pending_for_processing: 0,
            open: 0,
            received_pickup_location: 0,
            total_packages_processed: 0,
        };

        fulfillment?.packages?.forEach((pack) => {
            if (pack.status === 'processed' || pack.status === 're_processed') {
                valuesPackagesStatus.total_packages_processed += 1;
            }

            if (!valuesPackagesStatus[pack.status]) {
                valuesPackagesStatus[pack.status] = 0;
            }
            valuesPackagesStatus[pack.status] += 1;
        });

        const { preparer_default_assignment } = dataPreparations?.preparations_preferences || {};
        const {
            type_automatic_assignment_manager_fulfillment:
                type_automatic_assignment_manager_fulfillment_shipping,
        } = dataPickupsPreferences?.delivery_options?.shipping || {};
        const {
            type_automatic_assignment_manager_fulfillment:
                type_automatic_assignment_manager_fulfillment_pickup,
        } = dataPickupsPreferences?.delivery_options?.pickup || {};

        const conditionalDiffDeliveryMethod =
            (dataPickupsPreferences?.delivery_method_pickup ||
                dataPickupsPreferences?.delivery_method_shipping) &&
            dataPickupsPreferences?.delivery_options?.pickup?.enable_two_step_delivery !==
                dataPickupsPreferences?.delivery_options?.shipping?.enable_two_step_delivery &&
            firstData?.delivery_method !== fulfillment?.delivery_method;

        const prepareInfoUser =
            preparer_default_assignment &&
            !!(
                (type_automatic_assignment_manager_fulfillment_shipping ===
                    'not_delivery_validation' &&
                    fulfillment?.delivery_method === 'shipping') ||
                (type_automatic_assignment_manager_fulfillment_pickup ===
                    'not_delivery_validation' &&
                    fulfillment?.delivery_method === 'pickup')
            );

        if (
            ((prepareInfoUser && isSelected) || (isSelected && totalPackages === 1)) &&
            !conditionalDiffDeliveryMethod
        ) {
            valuesPackagesStatus.total_packages_processed = totalPackages;
        }

        setPackagesStatus({
            ...valuesPackagesStatus,
            color:
                ((isSelected && totalPackages === 1) ||
                    valuesPackagesStatus.total_packages_processed === totalPackages) &&
                !conditionalDiffDeliveryMethod
                    ? '#089460'
                    : getPackagesTagColor(valuesPackagesStatus, totalPackages),
        });
    }, [
        fulfillment?.packages,
        isSelected,
        totalPackages,
        dataPickupsPreferences,
        dataPreparations,
        dataList,
    ]);

    const columns = useMemo(
        () => [
            {
                dataIndex: 'code',
                title: 'Código Bulto',
                align: 'left',
                width: '140px',
                render: (value) => (
                    <Typography.Text
                        copyable={{
                            icon: <CopyIcon />,
                        }}
                        style={{
                            weight: 'bold',
                            color: '#037FB9',
                            textDecoration: 'underline',
                        }}
                    >
                        {value}
                    </Typography.Text>
                ),
            },
            {
                dataIndex: 'description',
                title: 'Descripción',
                align: 'left',
                width: '100px',
                render: (value, row) => <span>{row?.type?.name ?? 'Descripción'}</span>,
            },
            {
                dataIndex: 'line_items',
                title: () => <span style={{ textWrap: 'nowrap' }}>Unidades solicitadas</span>,
                align: 'left',
                width: '100px',
                render: (value) =>
                    value.reduce((acc, item) => {
                        return acc + item.quantity;
                    }, 0),
            },
            {
                dataIndex: 'status',
                title: 'Escaneado',
                width: '160px',
                align: 'left',
                render: (value) => {
                    const firstData = dataList.find(
                        (fulfillment) => fulfillment._id === selectedRows[0]
                    );

                    const conditionalDiffDeliveryMethod =
                        (dataPickupsPreferences?.delivery_method_pickup ||
                            dataPickupsPreferences?.delivery_method_shipping) &&
                        dataPickupsPreferences?.delivery_options?.pickup
                            ?.enable_two_step_delivery !==
                            dataPickupsPreferences?.delivery_options?.shipping
                                ?.enable_two_step_delivery &&
                        firstData?._id !== fulfillment?._id &&
                        firstData?.delivery_method !== fulfillment?.delivery_method;

                    let customValue = value;

                    if (
                        isSelected &&
                        (totalPackages === 1 || activeSelected) &&
                        value === 'pending_for_processing' &&
                        !conditionalDiffDeliveryMethod
                    )
                        customValue = 'selected';

                    return (
                        <Tag
                            borderColor="#2D3D76"
                            color="#536D8F"
                            style={{
                                backgroundColor: '#F5F5F5',
                            }}
                        >
                            <CircleIcon
                                backgroundColor={
                                    colorPackages[customValue] ?? colorPackages.nullable
                                }
                            />
                            {getLabel(customValue)}
                        </Tag>
                    );
                },
            },
            {
                dataIndex: 'options',
                title: 'Acciones',
                width: '100px',
                render: (value, row) => {
                    const customValue =
                        isSelected &&
                        (totalPackages === 1 || activeSelected) &&
                        row.status === 'pending_for_processing'
                            ? 'selected'
                            : row.status;

                    return (
                        <Tooltip
                            title={
                                customValue === 'selected' || customValue === 'processed'
                                    ? ''
                                    : 'No es posible des-procesar el bulto, es necesario primero escanearlo'
                            }
                        >
                            <Flex alignItems="center" justifyContent="center">
                                <PackageOutputIcon
                                    display="inline"
                                    cursor="pointer"
                                    fill="#2D3D76"
                                    width="22px"
                                    height="22px"
                                    onClick={() => {
                                        if (
                                            customValue === 'selected' ||
                                            customValue === 'processed'
                                        ) {
                                            unProcessPackageHandle(row.code);
                                        }
                                    }}
                                    disabled={
                                        customValue !== 'selected' && customValue !== 'processed'
                                    }
                                    opacity={
                                        customValue !== 'selected' && customValue !== 'processed'
                                            ? 0.5
                                            : 1
                                    }
                                />
                            </Flex>
                        </Tooltip>
                    );
                },
            },
        ],
        [totalPackages, isSelected, packagesInfo, isScan, dataList]
    );

    const statePackage = useMemo(() => {
        const firstData = dataList.find((fulfillment) => fulfillment._id === selectedRows[0]);

        const conditionalDiffDeliveryMethod =
            (dataPickupsPreferences?.delivery_method_pickup ||
                dataPickupsPreferences?.delivery_method_shipping) &&
            dataPickupsPreferences?.delivery_options?.pickup?.enable_two_step_delivery !==
                dataPickupsPreferences?.delivery_options?.shipping?.enable_two_step_delivery &&
            firstData?._id !== fulfillment?._id &&
            firstData?.delivery_method !== fulfillment?.delivery_method;

        return isSelected &&
            (totalPackages === 1 || activeSelected) &&
            !conditionalDiffDeliveryMethod &&
            packagesInfo.pending_for_processing > 0
            ? 'Procesados'
            : 'Escaneados';
    }, [isSelected, totalPackages, activeSelected, packagesInfo]);

    return (
        <SubtableWrapper>
            <Flex
                direction="row"
                columnGap="1rem"
                alignItems="center"
                width="100%"
                marginBottom="1rem"
                style={{ maxWidth: '1200px' }}
            >
                <Flex direction="row" gap="1rem" alignItems="center" justifyContent="center">
                    <p style={{ color: '#2D3D76', margin: 0 }}>
                        <b>ID Ecommerce:</b> {fulfillment.remote_order_id}
                    </p>
                </Flex>
                <Flex direction="row" gap="1rem" alignItems="center" justifyContent="center">
                    <Tag
                        borderColor="#2D3D76"
                        color="#536D8F"
                        style={{
                            backgroundColor: '#F5F5F5',
                        }}
                    >
                        <CircleIcon backgroundColor={packagesInfo.color} />
                        {statePackage} {packagesInfo.total_packages_processed}/{totalPackages}
                    </Tag>
                    {!!fulfillment && <PackageDetailsDrawer fulfillment={fulfillment} />}
                </Flex>
            </Flex>
            <div style={{ width: '100%', maxWidth: '1000px' }}>
                <PackagesTable
                    rowKey={'_id'}
                    columns={columns}
                    dataSource={fulfillment?.packages ?? []}
                    pagination={false}
                    rowClassName={(record) => {
                        return `packages-table-row-${record.code}`;
                    }}
                />
            </div>
        </SubtableWrapper>
    );
}

export default Subtable;
