import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getClickAndCollectStates = createAsyncThunk(
    'preparationPreference/getClickAndCollectStates',
    async (state) => {
        try {
            const response = await AndesClient.get(
                `/preparations-preferences/getClickAndCollectStates`
            );
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const getClickAndCollectStatesSlice = createSlice({
    name: 'getClickAndCollectStates',
    initialState: {
        loading: false,
        data: [],
        error: null,
        success: false,
    },
    reducers: {},
    extraReducers: {
        [getClickAndCollectStates.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
            };
        },
        [getClickAndCollectStates.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
                success: true,
            };
        },
        [getClickAndCollectStates.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
                success: false,
            };
        },
    },
});
export default getClickAndCollectStatesSlice.reducer;
