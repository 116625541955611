import { createSlice } from '@reduxjs/toolkit';

const initialStateSOreplaceProducts = {
    new_variants_ids_selected: [],
    data_to_fulfillment: {},
    variants_to_fulfillment: {},
};

const SOreplaceProducts = createSlice({
    name: 'SOreplaceProducts',
    initialState: initialStateSOreplaceProducts,
    reducers: {
        changeSOreplaceProducts: (state, { payload }) => {
            return {
                ...state,
                [payload[0]]: payload[1],
            };
        },
        changeVariantsToFulfillmentSORP: (state, { payload }) => {
            return {
                ...state,
                variants_to_fulfillment: {
                    ...state.variants_to_fulfillment,
                    [payload[0]]: payload[1],
                },
            };
        },
        reInitSOreplaceProducts: (state) => {
            return {
                ...state,
                ...initialStateSOreplaceProducts,
            };
        },
    },
});
export const { changeSOreplaceProducts, reInitSOreplaceProducts, changeVariantsToFulfillmentSORP } =
    SOreplaceProducts.actions;
export default SOreplaceProducts.reducer;
