import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';

export const getPreparationPreferenceByMerchant = createAsyncThunk(
    'preparationPreference/getOne',
    async (state) => {
        try {
            const response = await AndesClient.get(`/settings/preparation/merchant`);
            localStorage.setItem('preparationSettings', JSON.stringify(response.data));
            return response.data;
        } catch (error) {
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const getPreparationPreferenceByMerchantSlice = createSlice({
    name: 'getPreparationPreferenceByMerchant',
    initialState: {
        loading: false,
        data: {},
        error: null,
        success: false,
        finish: false,
    },
    reducers: {
        addPreparationPreferenceToStore: (state, action) => {
            const { payload } = action;
            return {
                ...state,
                data: { ...state.data, ...payload },
            };
        },
        updatePreparationPreferenceFromStore: (state, action) => {
            const { payload } = action;
            const { _id } = state.data;
            if (_id === payload._id) {
                return {
                    ...state,
                    data: { ...state.data, ...payload },
                };
            }
            return {
                ...state,
                data: { ...state.data },
            };
        },
    },
    extraReducers: {
        [getPreparationPreferenceByMerchant.pending]: (state) => {
            return {
                ...state,
                loading: true,
                success: false,
                finish: false,
            };
        },
        [getPreparationPreferenceByMerchant.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                data: payload,
                error: null,
                success: true,
                finish: true,
            };
        },
        [getPreparationPreferenceByMerchant.rejected]: (state, { payload }) => {
            return {
                ...state,
                loading: false,
                error: payload,
                success: false,
                finish: true,
            };
        },
    },
});
export const { addPreparationPreferenceToStore, updatePreparationPreferenceFromStore } =
    getPreparationPreferenceByMerchantSlice.actions;
export default getPreparationPreferenceByMerchantSlice.reducer;
