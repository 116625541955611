import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { CiWarning } from 'react-icons/ci';
import { TitleModalSameShippingService } from './styles';

function DialogCourierDiff({ selectedRows, dataList, handleSelectRows }) {
    const [fulfillmentsSelected, setFulfillmentsSelected] = useState();
    const [showModalDifferentShippingService, setShowModalDifferentShippingService] =
        useState(false);

    useEffect(() => {
        const fulfillmentsSelected = (dataList || [])?.filter((el) =>
            selectedRows.includes(el._id)
        );

        if (!fulfillmentsSelected?.length || fulfillmentsSelected?.length === 1) return;

        const firstCourierId = fulfillmentsSelected[0]?.courier?._id;

        const sameCourier = fulfillmentsSelected?.every((el) => {
            if (!firstCourierId || !el?.courier) return false;

            return el?.courier?._id === firstCourierId;
        });

        if (!sameCourier) return;

        const shippingServiceNames = Array.from(
            new Set(
                fulfillmentsSelected
                    .filter((el) => el.shipping_service !== null)
                    .map((el) => el?.shipping_service?.name ?? el.shipping_service)
            )
        );

        if (!shippingServiceNames.length) return;

        if (shippingServiceNames.length > 1) {
            setShowModalDifferentShippingService(true);
        } else {
            setShowModalDifferentShippingService(false);
        }

        const shippingServiceFirst =
            fulfillmentsSelected[0]?.shipping_service?.name ??
            fulfillmentsSelected[0]?.shipping_service;
        const shippingServiceLast =
            fulfillmentsSelected[fulfillmentsSelected.length - 1]?.shipping_service?.name ??
            fulfillmentsSelected[fulfillmentsSelected.length - 1]?.shipping_service;

        setFulfillmentsSelected({
            name: fulfillmentsSelected[0]?.courier?.public_name,
            shipping_service_first: shippingServiceFirst,
            shipping_service_last: shippingServiceLast,
        });
    }, [dataList, selectedRows]);

    const handleCancelModalDifferentShippingService = () => {
        const updatedSelectedRows = [...selectedRows];
        updatedSelectedRows.pop();
        handleSelectRows(updatedSelectedRows);
        setShowModalDifferentShippingService(false);
        setFulfillmentsSelected(null);
    };

    if (!fulfillmentsSelected) return null;

    console.log({ fulfillmentsSelected, showModalDifferentShippingService });

    return (
        <Modal
            title={
                <TitleModalSameShippingService>
                    <CiWarning className="icon-modal-different-shipping-service" />
                    <span>Distinto tipo de servicio</span>
                </TitleModalSameShippingService>
            }
            onOk={() => setShowModalDifferentShippingService(false)}
            okText="Continuar"
            onCancel={handleCancelModalDifferentShippingService}
            open={showModalDifferentShippingService}
        >
            <span>
                Alerta
                <br />
                Estás efectuando entrega al courier <b>{fulfillmentsSelected?.name}</b> con tipo de
                servicio: <b>{fulfillmentsSelected.shipping_service_first}</b>. El pedido recién
                escaneado / seleccionado tiene un tipo de servicio distinto:{' '}
                <b>{fulfillmentsSelected.shipping_service_last}</b>
                .
                <br />
                <br />
                ¿Desea continuar con el procesamiento del pedido para la entrega?
            </span>
        </Modal>
    );
}

export default DialogCourierDiff;
