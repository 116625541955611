import { useRef, useMemo } from 'react';
import { Form } from 'antd';

import { useDispatch } from 'react-redux';
import { toggleModal } from 'redux/features/Utils';
import { useUpdateUserPreferencesMutation } from 'redux/features/Users';
import List from 'components/V2/List';
import ListItem from 'components/V2/List/ListItem';
import PrimaryButton from 'components/V2/Buttons/PrimaryButton';
import SecondaryButton from 'components/V2/Buttons/SecondaryButton';
import Flex from 'components/Utils/Flex';
import { getLastNewViewIndex } from 'helpers/getLastViewIndex';
import { removeBasicTabs } from 'helpers/removeBasicTabs';
import { ReactComponent as ViewOn } from 'assets/view-on.svg';
import { ReactComponent as ViewOff } from 'assets/view-off.svg';
import { isML } from 'helpers/isML';
import useCurrentUser from 'hooks/useCurrentUser';

const sortTabColumns = (tabColumns) => {
    const disabledColumns = tabColumns.filter((column) => !column.status);
    const enabledColumns = tabColumns.filter((column) => column.status);

    const sortedColumns = [...disabledColumns, ...enabledColumns].map((el, i) => ({
        ...el,
        position: i + 1,
    }));

    return sortedColumns;
};

function ColumnsForm({
    modalName,
    userPreferences,
    selectedTab,
    tabColumns,
    handleChangeTabColumns,
    dispatchersType,
    filters,
}) {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const isApplyFetching = useRef(false);
    const isCreateViewFetching = useRef(false);
    const [updateUserPreferences] = useUpdateUserPreferencesMutation();
    const user = useCurrentUser();

    const memoizedColumns = useMemo(() => {
        return structuredClone(tabColumns);
    }, [userPreferences, selectedTab]);

    const updateUserColumnsPreferencesHandler = async (newColumnsPreferences) => {
        // Debemos verificar si el tab.typeOfTab es basic o custom
        const activeTabIndex = userPreferences?.preferences[dispatchersType]?.tabs?.findIndex(
            (tab) => tab.value === selectedTab
        );
        const newPreferences = structuredClone(userPreferences);
        newPreferences.preferences[dispatchersType].tabs[activeTabIndex].columns = [
            ...newColumnsPreferences,
        ];

        const userPreferencesNoBasicTabs = removeBasicTabs(newPreferences);

        await updateUserPreferences(userPreferencesNoBasicTabs);
    };

    const createTab = () => {
        const value = `Nueva vista ${getLastNewViewIndex(
            userPreferences?.preferences[dispatchersType]?.tabs
        )}`;
        const filtersToNewTab = structuredClone(filters);

        // Loop the filters to be able to delete all filters in the new tab
        filtersToNewTab.forEach((filter) => {
            filter.isRemovable = true;
        });

        const newTab = {
            name: value,
            value: value?.toLowerCase().replace(/ /g, '_') + Date.now(),
            typeOfTab: 'custom',
            filters: [...filtersToNewTab],
            columns: [...tabColumns],
        };
        return newTab;
    };

    const handleFormSubmit = async () => {
        isCreateViewFetching.current = true;
        const newUserPreferences = structuredClone(userPreferences);

        const userPreferencesNoBasicTabs = removeBasicTabs(newUserPreferences);

        const newCustomTab = createTab();
        await updateUserPreferences({
            ...userPreferencesNoBasicTabs,
            preferences: {
                ...userPreferencesNoBasicTabs.preferences,
                [dispatchersType]: {
                    ...userPreferencesNoBasicTabs.preferences[dispatchersType],
                    tabs: [
                        ...userPreferencesNoBasicTabs.preferences[dispatchersType].tabs,
                        newCustomTab,
                    ],
                },
            },
        });
        isCreateViewFetching.current = false;

        // Volvemos a las columnas iniciales
        handleChangeTabColumns(memoizedColumns);
        dispatch(toggleModal(modalName));
    };

    const handleApplyColumns = async () => {
        isApplyFetching.current = true;
        const sortedColumns = sortTabColumns(tabColumns);
        await updateUserColumnsPreferencesHandler(sortedColumns);
        isApplyFetching.current = false;
        dispatch(toggleModal(modalName));
    };

    const changeViewStatusHandler = (event, item) => {
        event.preventDefault();
        const tabColumnsAux = structuredClone(tabColumns);
        const key = tabColumns.findIndex((column) => column.label === item?.label);
        tabColumnsAux[key].status = !tabColumnsAux[key]?.status;
        handleChangeTabColumns(tabColumnsAux);
    };

    return (
        <>
            <Form form={form} onFinish={handleFormSubmit}>
                <List
                    style={{ maxHeight: 350, overflow: 'auto', marginBottom: '1rem' }}
                    size="small"
                    dataSource={tabColumns?.filter((column) => {
                        const isMascotasLatinas = isML(user?.userData?.merchant_id?._id);

                        if (isMascotasLatinas) {
                            return !['promised_date'].includes(column?.name);
                        } else {
                            return !['custom_promised_date', 'promised_date_range'].includes(
                                column?.name
                            );
                        }
                    })}
                    renderItem={(item) => (
                        <ListItem
                            style={{
                                display: 'flex',
                                paddingLeft: '1rem',
                                paddingRight: '1rem',
                                cursor: 'pointer',
                                height: '2.75rem',
                                color: item.status ? 'inherit' : '#9EBCC0',
                            }}
                        >
                            {item.label}{' '}
                            <button onClick={(event) => changeViewStatusHandler(event, item)}>
                                <div
                                    className="flex flex-col items-center"
                                    style={{
                                        fontSize: '10px',
                                        paddingRight: item.status ? '0.375rem' : '0',
                                    }}
                                >
                                    {item.status ? <ViewOn /> : <ViewOff />}
                                    {item.status ? 'Visible' : 'No visible'}
                                </div>
                            </button>
                        </ListItem>
                    )}
                />
            </Form>
            <Flex
                paddingTop="0.75rem"
                justifyContent="flex-end"
                gap="0.5rem"
                style={{ borderTop: '1px solid #e4e8ec' }}
            >
                <SecondaryButton
                    loading={isCreateViewFetching.current}
                    disabled={isApplyFetching.current || isCreateViewFetching.current}
                    onClick={() => {
                        form.submit();
                    }}
                    variation="link"
                >
                    Guardar en vista nueva
                </SecondaryButton>

                <PrimaryButton
                    loading={isApplyFetching.current}
                    disabled={isApplyFetching.current || isCreateViewFetching.current}
                    onClick={handleApplyColumns}
                >
                    Aplicar cambios
                </PrimaryButton>
            </Flex>
        </>
    );
}

export default ColumnsForm;
