import { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useDeliveriesScanPackageMutation } from 'redux/features/Fulfillments';
import Input from 'components/V2/Input';
import { dispatchers } from '../Filters/config';

import { Modal } from 'antd';
import Select from '../Select';
import SelectOption from '../Select/SelectOption';

const SearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    border-radius: 3px;
    height: 40px;
    transition: 0.2s;
    width: 330px;
`;

const DeliveriesScanInput = ({
    refetch,
    dispatchersType,
    scanMode = false,
    disabled = false,
    setExpandedKeys,
    expandedKeys,
    deliveryPreferences = {},
    dataList,
    updateList,
    handleSelectRows,
    dataPreparations,
    handleInsertFulfillments,
}) => {
    const [getFulfillmentStatus, scanStatus] = useDeliveriesScanPackageMutation();
    const [packageIdToScan, setPackageIdToScan] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [codeScanned, setCodeScanned] = useState('');
    const scannedInputRef = useRef(null);
    const dispatch = useDispatch();
    const [showMultiPackageModal, setShowMultiPackageModal] = useState(false);
    const [showNotAllPackagesProcessedModal, setShowNotAllPackagesProcessedModal] = useState(false);
    const [fulfillmentsWithNotAllPackagesProcessed, setFulfillmentsWithNotAllPackagesProcessed] =
        useState([]);
    const [fulfillmentsPendingForProcessing, setFulfillmentsPendingForProcessing] = useState(null);

    const { setFilters } = dispatchers[dispatchersType];
    const { preparationsFilters } = useSelector((state) => state.utils);

    const pickupTwoStep = deliveryPreferences?.delivery_options?.pickup?.enable_two_step_delivery;
    const shippingTwoStep =
        deliveryPreferences?.delivery_options?.shipping?.enable_two_step_delivery;
    const shippingTypeAutomatic =
        deliveryPreferences?.delivery_options?.shipping
            ?.type_automatic_assignment_manager_fulfillment;
    const pickupTypeAutomatic =
        deliveryPreferences?.delivery_options?.pickup
            ?.type_automatic_assignment_manager_fulfillment;
    const prepareDefaultAssignment =
        dataPreparations?.preparations_preferences?.preparer_default_assignment;

    useEffect(() => {
        if (!codeScanned && dataList?.length) {
            const code =
                dataList[0].packages?.find((p) => p.status === 'processed')?.code ||
                dataList[0].packages?.[0]?.code;
            setCodeScanned(code);
        }
    }, [codeScanned, dataList]);

    const scroll = (id) => {
        const section = document.querySelector(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
            section.classList.add('alerts-border');
            setTimeout(() => {
                section.classList.remove('alerts-border');
            }, 3000);
        }
    };

    const handleReplaceFulfillment = (fulfillments = [], havePendingFulfillments = false, code) => {
        setSearchTerm('');
        for (const fulfillment of fulfillments ?? []) {
            const last_fulfillment_scanned = dataList.find(
                (f) =>
                    f.packages.find((p) => p.code === codeScanned) ||
                    f.order.remote_order_id === codeScanned
            );
            let position = 'first';

            if (
                last_fulfillment_scanned?._id &&
                pickupTwoStep !== shippingTwoStep &&
                fulfillment?.delivery_method !== last_fulfillment_scanned?.delivery_method
            )
                return '';

            if (fulfillment?.packages?.length === 1 && havePendingFulfillments) position = 'last';
            else {
                setExpandedKeys((state) => {
                    const existsExpendedKey = state.includes(fulfillment._id);

                    const expandedKeys = dataList
                        .filter((f) =>
                            f.packages.find((p) => {
                                const validationConditionals =
                                    fulfillment?.packages?.length === 1 ||
                                    (prepareDefaultAssignment &&
                                        !!(
                                            (shippingTypeAutomatic === 'not_delivery_validation' &&
                                                fulfillment.delivery_method === 'shipping') ||
                                            (pickupTypeAutomatic === 'not_delivery_validation' &&
                                                fulfillment.delivery_method === 'pickup')
                                        ));
                                return p.status !== 'processed' && !validationConditionals;
                            })
                        )
                        .map((f) => f._id);

                    if (existsExpendedKey) {
                        const packageExistsPending = fulfillment.packages.find(
                            (p) => p.status !== 'processed' || p.code === code
                        );

                        if (packageExistsPending)
                            return [
                                fulfillment?._id,
                                ...expandedKeys.filter((id) => id !== fulfillment._id),
                            ];

                        return expandedKeys;
                    }

                    return [fulfillment?._id, ...expandedKeys];
                });
            }

            if (havePendingFulfillments) setExpandedKeys([...expandedKeys, fulfillment?._id]);

            const existingIndex = dataList.findIndex((item) => item._id === fulfillment._id);

            if (existingIndex !== -1) {
                updateList((state) => {
                    const fulfillments = state.map((f) =>
                        f._id === fulfillment._id ? fulfillment : f
                    );

                    handleSelectRows(fulfillments.map((f) => f._id));
                    handleInsertFulfillments(fulfillments);

                    return fulfillments;
                });
            } else {
                updateList((state) => {
                    const fulfillments =
                        position === 'first' ? [fulfillment, ...state] : [...state, fulfillment];

                    handleSelectRows(fulfillments.map((f) => f._id));
                    handleInsertFulfillments(fulfillments);

                    return fulfillments;
                });
            }

            setTimeout(() => {
                scroll(`.packages-table-row-${searchTerm.trim()}`);
            }, 500);
        }
    };

    const handleScanPackageOrFulfillment = async (value) => {
        const last_fulfillment_scanned = dataList.find(
            (f) =>
                f.packages.find((p) => p.code === codeScanned || p._id === codeScanned) ||
                f.order.remote_order_id === codeScanned
        );

        const response = await getFulfillmentStatus({
            code: value,
            last_fulfillment_scanned: last_fulfillment_scanned?._id,
            filters: preparationsFilters,
        });

        if (
            !response?.data?.package_scanned &&
            (response?.data?.not_found_packages ||
                response?.data?.multi_orders_match ||
                (codeScanned && response?.data?.not_allowed_different_delivery_method) ||
                response?.data?.fulfillment_is_delivered ||
                response?.data?.waiting_collect_manifest)
        ) {
            return;
        }

        let notAllProcessed = [];

        const newFulfillmentExist = dataList.some(
            (item) => item._id === response?.data?.fulfillments?.[0]?._id
        );

        if (!newFulfillmentExist) {
            for (const fulfillment of Array.from(dataList ?? [])) {
                const allPackagesProcessed = fulfillment?.packages?.every(
                    (packageItem) => packageItem?.status === 'processed'
                );

                const validationConditionals =
                    fulfillment?.packages?.length === 1 ||
                    (prepareDefaultAssignment &&
                        !!(
                            (shippingTypeAutomatic === 'not_delivery_validation' &&
                                fulfillment.delivery_method === 'shipping') ||
                            (pickupTypeAutomatic === 'not_delivery_validation' &&
                                fulfillment.delivery_method === 'pickup')
                        ));

                if (!allPackagesProcessed && !validationConditionals)
                    notAllProcessed.push(fulfillment);
            }
        }

        setCodeScanned(value);
        if (response?.data?.multiple_match) {
            setShowMultiPackageModal(true);
            return;
        }

        if (notAllProcessed.length > 0) {
            setFulfillmentsWithNotAllPackagesProcessed(notAllProcessed);
            setShowNotAllPackagesProcessedModal(true);
            setFulfillmentsPendingForProcessing(response?.data?.fulfillments);
            return;
        } else {
            setFulfillmentsWithNotAllPackagesProcessed([]);
            setShowNotAllPackagesProcessedModal(false);
        }

        if (
            response?.data?.not_allowed_different_delivery_method ||
            response?.data?.multi_orders_match ||
            response?.data?.fulfillment_is_delivered ||
            response?.data?.waiting_collect_manifest
        ) {
            return;
        }

        handleReplaceFulfillment(response?.data?.fulfillments ?? [], false, value);
    };

    const handleSearch = async (value = '', hard = false) => {
        value = value.trim();
        if (value === '' || !value) return;

        dispatch(setFilters({ searchTerm: value }));

        if (!scanMode) {
            return refetch(hard);
        }

        if (scannedInputRef?.current) scannedInputRef.current.value = '';

        await handleScanPackageOrFulfillment(value);
        setSearchTerm('');
        dispatch(setFilters({ searchTerm: undefined }));
    };

    const handleScan = () => {
        if (scannedInputRef.current) scannedInputRef.current.focus();
    };

    const enableScanMode = () => {
        if (scannedInputRef.current) scannedInputRef.current.focus();
        window.addEventListener('keydown', handleScan);
    };

    const disableScanMode = () => {
        window.removeEventListener('keydown', handleScan);
        updateList([]);
    };

    useEffect(() => {
        if (!scanMode) {
            const delayDebounceFn = setTimeout(() => {
                handleSearch(searchTerm);
            }, 1200);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [searchTerm]);

    useEffect(() => {
        if (scanMode) enableScanMode();
        else disableScanMode();

        return disableScanMode;
    }, [scanMode]);

    return (
        <>
            <SearchWrapper>
                <Input
                    placeholder="Escanea o ingresa el código de bulto"
                    ref={scannedInputRef}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    value={searchTerm}
                    disabled={disabled || scanStatus.isLoading || scanStatus.isFetching}
                    style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            setSearchTerm(e.target.value);
                            handleSearch(e.target.value);
                        }
                    }}
                />
            </SearchWrapper>

            <Modal
                title="Bultos pendientes de procesamiento"
                maskClosable={false}
                closable={false}
                cancelText="Cancelar"
                okText="Aceptar"
                open={showNotAllPackagesProcessedModal}
                onOk={() => {
                    setShowNotAllPackagesProcessedModal(false);
                    handleReplaceFulfillment(fulfillmentsPendingForProcessing, true, searchTerm);
                }}
                onCancel={() => {
                    setShowNotAllPackagesProcessedModal(false);
                    setFulfillmentsWithNotAllPackagesProcessed([]);
                }}
            >
                {fulfillmentsWithNotAllPackagesProcessed?.map((fulfillment = {}) => (
                    <span key={fulfillment?._id}>
                        <p>
                            El fulfillment <strong>{fulfillment.name}</strong> tiene{' '}
                            <strong>
                                {
                                    fulfillment?.packages?.filter(
                                        (pkg) => pkg?.status !== 'processed'
                                    )?.length
                                }
                            </strong>{' '}
                            bultos pendientes de procesamiento
                        </p>
                        <br />
                        <p>
                            <b>Desea continuar con el procesamiento del nuevo pedido?</b>
                        </p>
                    </span>
                ))}
            </Modal>

            <Modal
                title="Mismo código de bulto"
                maskClosable={false}
                closable={false}
                cancelText="Cancelar"
                okText="Aceptar"
                open={showMultiPackageModal && scanStatus?.data?.packages?.length > 0}
                onCancel={() => setShowMultiPackageModal(false)}
                onOk={() => {
                    setShowMultiPackageModal(false);
                    handleScanPackageOrFulfillment(packageIdToScan.trim());
                }}
            >
                <p>
                    <b>
                        Se ha detectado la existencia del mismo código de bulto para más de un
                        pedido pendiente de entrega.
                    </b>
                </p>

                <br />
                <p>Favor especifique a cual pedido pertenece el bulto que se está escaneando:</p>
                <br />

                <Select
                    placeholder="Seleccionar pedido"
                    style={{ width: '100%' }}
                    onChange={(e) => setPackageIdToScan(e)}
                >
                    {Array.from(scanStatus?.data?.packages ?? []).map((pkg) => (
                        <SelectOption key={pkg._id} value={pkg._id}>
                            {pkg?.fulfillment_id?.name}
                        </SelectOption>
                    ))}
                </Select>
            </Modal>
        </>
    );
};

export default DeliveriesScanInput;
