import { Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Label } from '../styled';

const AlertShareWarehouse = ({ setIsShareWarehouse }) => {
    return (
        <div className="px-2 py-10 flex flex-col justify-center items-center">
            <Label fontWeight={500} textAlign="center">
                ¿Los pedidos seleccionados comparten <br /> la misma incidencia?
            </Label>
            <div className="flex justify-center mt-4">
                <Button
                    onClick={() => setIsShareWarehouse('yes')}
                    style={{ border: '1px solid #1E2B5F', fontWeight: '600' }}
                >
                    <div className="flex items-center">
                        <span className="mr-3">Si</span>
                        <CheckOutlined style={{ color: 'green' }} />
                    </div>
                </Button>
                <Button
                    onClick={() => setIsShareWarehouse('no')}
                    className="ml-9"
                    style={{ border: '1px solid #1E2B5F', fontWeight: '600' }}
                >
                    <div className="flex items-center">
                        <span className="mr-3">No</span>
                        <CloseOutlined style={{ color: 'red' }} />
                    </div>
                </Button>
            </div>
        </div>
    );
};

export default AlertShareWarehouse;
