import { Menu } from 'antd';
import ActionsButton from 'components/Buttons/ActionsButton';
import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from 'redux/features/Utils';

const Actions = ({ fulfillment, blockPreparation }) => {
    const dispatch = useDispatch();
    const { selectedRows } = useSelector((state) => state.utils);
    const selectedLineItems = selectedRows['scanned-codes-table'] || [];

    const menu = (
        <Menu>
            <Menu.Item onClick={() => dispatch(toggleDrawer('manual-incidence-drawer'))}>
                Agregar Incidencia
            </Menu.Item>
        </Menu>
    );

    const disableButtons =
        blockPreparation ||
        selectedLineItems.length === 0 ||
        ['paused', 'success', 'cancelled', 'delivered', 'delivered_to_courier'].includes(
            fulfillment.status
        );

    return (
        <ActionsButton
            dropdownProps={{
                overlay: menu,
                disabled: disableButtons,
            }}
            buttonProps={{
                buttonText: `Acciones (${selectedLineItems.length})`,
            }}
        />
    );
};

export default Actions;
