import { Form, Select } from 'antd';
import useAllSalesChannel from 'hooks/useAllSalesChannel';

const SalesChannelSelector = ({ setSelectedTargetId }) => {
    const [loading, data] = useAllSalesChannel();
    return (
        <Form.Item
            name="target_id"
            label="Canal de venta"
            rules={[{ required: true, message: 'Este campo es requerido' }]}
        >
            <Select
                loading={loading}
                placeholder="Seleccione un item"
                onChange={(v) => setSelectedTargetId(v)}
            >
                {data.map((item) => (
                    <Select.Option value={item._id} key={item._id}>
                        {item?.alias ?? item?.integration?.api_name ?? ''}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );
};

export default SalesChannelSelector;
