import { createContext, useEffect, useState } from 'react';
import { useGetFeatureFlagQuery } from '../redux/features/FeatureFlag';

const FeatureFlagContext = createContext({
    featureFlag: null,
    getValueFeatureFlag: () => null,
});

const FeatureFlagProvider = ({ children }) => {
    const [featureFlag, setFeatureFlag] = useState(null);
    const { data: featureFlagResponse } = useGetFeatureFlagQuery();

    useEffect(() => {
        if (featureFlagResponse) {
            setFeatureFlag(featureFlagResponse);
        }
    }, [featureFlagResponse]);

    const getValueFeatureFlag = (keyPrimary, keySecondary) => {
        const valueKeyPrimary = featureFlag?.[keyPrimary];
        if (keySecondary) {
            return valueKeyPrimary?.enabled ? valueKeyPrimary[keySecondary] : null;
        }

        return valueKeyPrimary?.enabled ? valueKeyPrimary : null;
    };

    return (
        <FeatureFlagContext.Provider value={{ featureFlag, getValueFeatureFlag }}>
            {children}
        </FeatureFlagContext.Provider>
    );
};

export { FeatureFlagContext, FeatureFlagProvider };
