import styled from 'styled-components';

export const ToolBarStyled = styled.div`
    display: grid;
    grid-template-columns: repeat(28, 1fr);
    grid-gap: 0.5rem;

    .deliveriesSearchInput {
        grid-column: span 4 !important;
    }

    .ant-form-item {
        grid-column: span 3;
    }
`;
