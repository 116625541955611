import { createApi } from '@reduxjs/toolkit/query/react';
import toast from 'react-hot-toast';
import { baseQuery } from 'services/AndesClient';
import { toggleModal } from '../Utils';

const newWebhookModalName = 'new-webhook-modal';
const editWebhookModalName = 'edit-webhook-modal';

export const webhooksApi = createApi({
    reducerPath: 'webhooks',
    baseQuery,
    tagTypes: ['webhooks'],
    endpoints: (builder) => ({
        getWebhooks: builder.query({
            method: 'get',
            query: () => '/webhooks-v2',
            providesTags: ['webhooks'],
        }),
        createWebhook: builder.mutation({
            method: 'post',
            query: (body) => ({
                url: 'webhooks-v2',
                method: 'post',
                body,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Creando nuevo webhook...</b>,
                    success: <b>Webhook creado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
                api.dispatch(toggleModal(newWebhookModalName));
            },
            invalidatesTags: ['webhooks'],
        }),
        updateWebhook: builder.mutation({
            method: 'put',
            query: ({ _id, ...restBody }) => ({
                url: `/webhooks-v2/${_id}`,
                method: 'put',
                body: restBody,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Actualizando webhook...</b>,
                    success: <b>Webhook actualizado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
                api.dispatch(toggleModal(editWebhookModalName));
            },
            invalidatesTags: ['webhooks'],
        }),
        deleteWebhook: builder.mutation({
            method: 'delete',
            query: (id) => ({
                url: `/webhooks-v2/${id}`,
                method: 'delete',
                body: { _id: id },
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Eliminando webhook...</b>,
                    success: <b>Webhook eliminado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
            invalidatesTags: ['webhooks'],
        }),
        resendWebhook: builder.mutation({
            method: 'post',
            query: (data) => ({
                url: '/webhooks-v2/resend',
                method: 'post',
                body: data,
            }),
            onQueryStarted: (data, api) => {
                toast.promise(api.queryFulfilled, {
                    loading: <b>Reenviando informe...</b>,
                    success: <b>Informe reenviado con éxito</b>,
                    error: <b>Ocurrió un error.</b>,
                });
            },
        }),
    }),
});

export const {
    useCreateWebhookMutation,
    useDeleteWebhookMutation,
    useGetWebhooksQuery,
    useUpdateWebhookMutation,
    useResendWebhookMutation,
} = webhooksApi;
