import { Button } from 'antd';
import styled from 'styled-components';

export const ScannedInput = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    input::placeholder{
        color: #536D8F;
    }
    input {
        width: 400px;
        height: 40px;
        background: white;
        border: 1px solid #2D3D76;
        padding: 5px 10px;
        margin-right: 5px;
        font-size: 1rem;
        border-radius: 4px;
    }

    .ant-btn {
        border-radius: 4px;
        height: 40px;
        color: #536D8F !important;
        border: 1px solid #2D3D76 !important;
    }
`;

export const ProcessButton = styled(Button)`
    font-size: 1rem !important;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 44px;
`;

export const CollapseHeaderStyles = styled.div`
    padding: 0.5rem 0px 0.5rem 1rem;
    .mini-dot {
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background-color: black;
        margin-right: 0.5rem;
    }
`;

export const PackageDetailsDrawerStyles = styled.div`
    .ant-collapse-header {
        border-bottom: 1.5px solid #dce5ec;
    }
    .ant-collapse-content-box {
        padding: 0px !important;
    }
`;

export const PackageProductsDetails = styled.div`
    display: flex;
    flex-direction: column;

    padding: 0.5rem;
    &:nth-child(odd) {
        background-color: #fbfbfb;
    }
    span {
        margin-bottom: '1rem';
    }
`;

export const FulfillmentDeliveryDetails = styled.section`
    width: 100%;
    background: #fbfbfb;
    border: 1px solid #1f6373;
    border-radius: 5px;
    padding: 1.25rem;
    h1 {
        margin-bottom: 2rem;
        color: #043540;
    }
    span {
        display: flex;
        b {
            display: inline-block;
            flex: 0 0 120px;
            color: #1f6373;
        }
        span {
            color: #41515E;
        }
    }
`;
