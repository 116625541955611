import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'services/AndesClient';

export const getAllDocuments = createApi({
    reducerPath: 'documents',
    baseQuery,
    tagTypes: ['order_documents'],
    endpoints: (builder) => ({
        getAllOrderDocuments: builder.query({
            method: 'get',
            query: (order_id) => {
                return {
                    url: `/orders/allDocuments/${order_id}`,
                    method: 'get',
                    // params: params,
                };
            },
            providesTags: ['order_documents'],
        }),
    }),
});

export const { useGetAllOrderDocumentsQuery } = getAllDocuments;
