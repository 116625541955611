import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AndesClient from 'services/AndesClient';
import toast from 'react-hot-toast';
import { getOrderById } from '../Orders/getOrderById';
import { toggleDrawer } from '../Utils';
import { fulfillmentsApi } from './index';

export const replaceItemsIncidence = createAsyncThunk(
    'fulfillments/replaceItems',
    async (data, state) => {
        try {
            const { order_id, body } = data;
            const states = state.getState();
            const promise = AndesClient.post(
                `/fulfillments/orders/${order_id}/fulfillments/replaceItems`,
                body
            );

            toast.promise(promise, {
                loading: <b>Creando Fulfillment/s...</b>,
                success: <b>Fulfillment/s creado con éxito</b>,
                error: <b>Ocurrió un error.</b>,
            });

            const result = await promise;

            if (result.status === 200) {
                state.dispatch(getOrderById(order_id));
                state.dispatch(
                    fulfillmentsApi.endpoints.getInProgressFulfillments.initiate(undefined, {
                        forceRefetch: true,
                    })
                );
                if (states.utils?.drawer['incidence-order-drawer'] === true) {
                    state.dispatch(toggleDrawer('incidence-order-drawer'));
                }
            }
            return state.fulfillWithValue(true);
        } catch (error) {
            toast.error(`Error: ${error.response.data.message}`);
            return state.rejectWithValue(error.response.data.message);
        }
    }
);

const replaceItemsIncidenceSlice = createSlice({
    name: 'replaceItemsIncidence',
    initialState: {
        loading: false,
        success: false,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [replaceItemsIncidence.pending]: (state) => {
            return {
                ...state,
                loading: true,
            };
        },
        [replaceItemsIncidence.fulfilled]: (state, action) => {
            return {
                ...state,
                success: action.payload,
                loading: false,
            };
        },
        [replaceItemsIncidence.rejected]: (state, action) => {
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        },
    },
});

export default replaceItemsIncidenceSlice.reducer;
