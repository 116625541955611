import { PrimaryButtonStyled } from './styles';
import PropTypes from 'prop-types';

const PrimaryButton = ({ children, ...otherProps }) => {
    return (
        <PrimaryButtonStyled {...otherProps} type="primary" style={otherProps.disabled && {color: '#B5BCCB', backgroundColor: '#E4E8EC'} || {}}>
            <span>{children}</span>
        </PrimaryButtonStyled>
    );
};

PrimaryButton.propTypes = {
    children: PropTypes.node,
};

export default PrimaryButton;
