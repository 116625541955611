import { useState, useMemo } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronDownIcon } from 'assets/chevron-down.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/chevron-up.svg';

const CollapseButtonStyled = styled.button`
    height: 2rem;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease-in-out;
    border: 1px solid #e4e8ec;
    border-top: none;
`;

const CollapseContainerStyled = styled.div`
    padding: 0 0.5rem;
    max-height: ${({ showCollapsible }) => (showCollapsible ? '480px' : '0')};
    border: 1px solid #e4e8ec;
    transition: max-height 0.5s ease-in-out;
    overflow: hidden;
    margin-top: -14px;
`;

const CollapsibleContainer = ({ children }) => {
    const [showCollapsible, setShowCollapsible] = useState(false);

    const showLess = useMemo(
        () => (
            <>
                Contraer métricas <ChevronUpIcon style={{ marginLeft: 3 }} />
            </>
        ),
        []
    );

    const showMore = useMemo(
        () => (
            <>
                Expandir métricas <ChevronDownIcon style={{ marginLeft: 3 }} />
            </>
        ),
        []
    );

    return (
        <>
            <CollapseContainerStyled showCollapsible={showCollapsible}>
                {children}
            </CollapseContainerStyled>
            <CollapseButtonStyled onClick={() => setShowCollapsible(!showCollapsible)}>
                {showCollapsible ? showLess : showMore}
            </CollapseButtonStyled>
        </>
    );
};

export default CollapsibleContainer;
