import { Col, Form, Input, Row } from 'antd';
import FormStyled from 'components/Utils/Form';
import useCountryData from 'hooks/useCountryData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateAdressOrCourierMutation } from 'redux/features/Fulfillments';
import { updateOrder } from 'redux/features/Orders/updateOrder';

const EditAddressForm = ({
    otherProps,
    form,
    initialValues,
    formName,
    mainObject = '',
    refetch,
    setShowModalEditBillingAddress = () => {},
    setShowModalEditShippingAddress = () => {},
}) => {
    const [updateAdressOrCourier] = useUpdateAdressOrCourierMutation();
    const countryData = useCountryData();
    const dispatch = useDispatch();

    const handleSubmit = async (values) => {
        const { _id, ...otherInitialValues } = initialValues;
        values.country_code = otherInitialValues?.country_code ?? 'cl';

        const data = {
            _id,
            [mainObject]: {
                ...otherInitialValues,
                ...values,
            },
        };
        const changeFulfillmentStatus = 'open';

        dispatch(updateOrder(data));

        //en este caso address
        await updateAdressOrCourier({
            changeFulfillmentStatus,
            fulfillmentId: otherProps?.fulfillmentId,
            ...data,
            ...(otherProps?.shippingTypeData && {
                elementId: otherProps?.shippingTypeData?._id,
                type: otherProps?.shippingTypeData?.type,
            }),
        });

        refetch(true);

        setShowModalEditBillingAddress(false);
        setShowModalEditShippingAddress(false);
    };

    const { loading } = useSelector((state) => state.updateOrder);

    useEffect(() => {
        form.setFieldsValue({ ...initialValues });
        return () => {
            form.resetFields();
        };
    }, [initialValues]);

    return (
        <FormStyled
            form={form}
            name={formName}
            layout="vertical"
            onFinish={handleSubmit}
            disabled={loading}
            disableEverything={!!otherProps?.disableEverything}
        >
            <Row gutter={8}>
                <Col sm={12}>
                    <Form.Item
                        name="first_name"
                        label="Nombre"
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                    >
                        <Input disabled={!!otherProps?.disableEverything} placeholder="Nombre" />
                    </Form.Item>
                </Col>
                <Col sm={12}>
                    <Form.Item
                        name="last_name"
                        label="Apellido"
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                    >
                        <Input disabled={!!otherProps?.disableEverything} placeholder="Apellido" />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item
                name="address1"
                label="Dirección 1"
                rules={[{ required: true, message: 'Este campo es requerido' }]}
            >
                <Input disabled={!!otherProps?.disableEverything} placeholder="Dirección 1" />
            </Form.Item>
            <Form.Item name="address2" label="Depto / Oficina / Local">
                <Input
                    disabled={!!otherProps?.disableEverything}
                    placeholder="Departamento / Oficina / Local"
                />
            </Form.Item>
            <Form.Item name="complement" label="Complemento">
                <Input disabled={!!otherProps?.disableEverything} placeholder="Complemento" />
            </Form.Item>

            <Row gutter={8}>
                <Col sm={12}>
                    <Form.Item
                        name="city"
                        label={countryData.cityLabel}
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                    >
                        <Input disabled={!!otherProps?.disableEverything} placeholder="Ciudad" />
                    </Form.Item>
                </Col>
                <Col sm={12}>
                    <Form.Item
                        name="neighborhood"
                        label={countryData.neighborhoodLabel}
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                    >
                        <Input disabled={!!otherProps?.disableEverything} placeholder="Comuna" />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={8}>
                <Col sm={12}>
                    <Form.Item
                        name="province"
                        label={countryData.provinceLabel}
                        rules={[{ required: true, message: 'Este campo es requerido' }]}
                    >
                        <Input disabled={!!otherProps?.disableEverything} placeholder="Region" />
                    </Form.Item>
                </Col>
                <Col sm={12}>
                    <Form.Item name="zip" label="Código Postal">
                        <Input
                            disabled={!!otherProps?.disableEverything}
                            placeholder="Código Postal"
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FormStyled>
    );
};

export default EditAddressForm;
